const ValidateEmail = (() => {
  // From https://www.w3.org/TR/html50/forms.html#e-mail-state-(type=email) browser validation allows stuff Voyado rejects
  const HTML5EmailRegExp =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;

  return (email?: string): boolean =>
    typeof email !== "undefined" &&
    HTML5EmailRegExp.test(email) &&
    email.indexOf("..") === -1 &&
    email.lastIndexOf("@") <= 64 &&
    email.length - email.lastIndexOf("@") - 1 <= 255;
})();

if (typeof window !== "undefined") {
  window.ValidateEmail = ValidateEmail;
}

export { ValidateEmail };

export default {
  readDescriptionButton: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 12,
    lineHeight: 1.2,
    letterSpacing: -0.1,
  },
  price: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 20,
    lineHeight: 1,
    letterSpacing: -0.1,
  },
  serviceName: {
    fontSize: 12,
    lineHeight: 1.2,
    letterSpacing: -0.1,
  },
  addToCartButton: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: -0.1,
  },
};

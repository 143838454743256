import { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { resolutionSizes20 } from "../../config";

export const BREAKPOINTS = {
  mobile: "mobile",
  tablet: "tablet",
  laptop: "laptop",
  desktop: "desktop",
  bigDesktop: "bigDesktop",
};

export type BreakpointProps = keyof typeof BREAKPOINTS;

export const useBreakpoint = (): BreakpointProps => {
  const isMobile = useMediaQuery(
    `(max-width: ${resolutionSizes20.tablet - 1}px)`
  ); // max-width: 767
  const isTablet = useMediaQuery(
    `(max-width: ${resolutionSizes20.laptop - 1}px)`
  ); // max-width: 1024
  const isLaptop = useMediaQuery(
    `(max-width: ${resolutionSizes20.desktop - 1}px)`
  ); // max-width: 1279
  const isLaptopAndDesktop = useMediaQuery(
    `(min-width: ${resolutionSizes20.laptop}px)`
  ); // min-width: 1024
  const isDesktop = useMediaQuery(
    `(min-width: ${resolutionSizes20.desktop}px)`
  ); // min-width: 1280
  const isBigDesktop = useMediaQuery(
    `(min-width: ${resolutionSizes20.bigDesktop}px)`
  ); // min-width: 1440
  const [breakpoint, setBreakpoint] = useState(BREAKPOINTS.desktop);

  useEffect(() => {
    if (isMobile) {
      setBreakpoint(BREAKPOINTS.mobile);
    } else if (isTablet && !isLaptopAndDesktop) {
      setBreakpoint(BREAKPOINTS.tablet);
    } else if (isLaptop && !isDesktop) {
      setBreakpoint(BREAKPOINTS.laptop);
    } else if (isDesktop && !isBigDesktop) {
      setBreakpoint(BREAKPOINTS.desktop);
    } else {
      setBreakpoint(BREAKPOINTS.bigDesktop);
    }
  }, [
    isBigDesktop,
    isDesktop,
    isLaptop,
    isLaptopAndDesktop,
    isMobile,
    isTablet,
  ]);

  return breakpoint as BreakpointProps;
};

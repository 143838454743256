import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { xxlTheme } from "../../../styles/xxl-theme";
import { typographyToCss } from "../../../styles/helpers";

const { spaces, colors } = xxlTheme;

const typography = {
  regularText: {
    fontFamily: "var(--font-family-regular)",
  },
  boldText: {
    fontFamily: "var(--font-family-bold)",
  },
  giftCardsCommon: {
    fontSize: 15,
    letterSpacing: -0.15,
    lineHeight: 1,
  },
};

const { regularText, boldText, giftCardsCommon } = typography;

export const GiftCardNameTitle = styled.span`
  ${typographyToCss(regularText)};
  display: flex;
  align-items: center;

  & svg {
    width: 25px;
    height: 25px;
    margin-right: ${spaces.mini};
  }

  & > span {
    ${typographyToCss(boldText)};
  }
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spaces.smallRegular};
`;

type RowWrapperProps = {
  isColumn?: boolean;
};

export const RowWrapper = styled.div<RowWrapperProps>(
  ({ isColumn = true }) => css`
    display: flex;
    flex-direction: ${isColumn ? "column" : "row"};
    column-gap: ${spaces.mini};

    & label {
      margin-bottom: ${spaces.smallRegular};
      ${typographyToCss(giftCardsCommon)};
    }
  `
);

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.smallRegular};
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  & svg {
    width: 36px;
    height: 36px;
  }
`;

export const ListWrapper = styled.div`
  margin-bottom: ${spaces.smallRegular};
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 ${spaces.smallRegular};
  display: flex;
  flex-direction: column;
  gap: ${spaces.smallRegular};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-content: center;
  height: 40px;
  width: 100%;
`;

export const ListItemText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const GiftCardText = styled.span`
  ${typographyToCss(regularText)};
  ${typographyToCss(giftCardsCommon)};

  & strong {
    ${typographyToCss(boldText)};
  }
`;

export const GiftCardBalanceText = styled.span`
  ${typographyToCss(giftCardsCommon)};
  color: ${colors.xxlWebGreen};
`;

export const RemoveButton = styled.button`
  width: 40px;
  height: 100%;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    width: 18px;
    height: 18px;
  }

  &:hover {
    cursor: pointer;
  }

  &:focus-visible {
    outline-width: 2px;
    outline-style: solid;
    outline-color: ${colors.xxlGreen};
    outline-offset: 2px;
  }
`;

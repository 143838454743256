import * as React from "react";
import type { Store } from "../ClickAndCollect/api/api";
import type { ReviewsAndAggregatedRating } from "../RatingsAndReviews/Api/RatingsAndReviewsAPI";
import { DEFAULT_PICKUP_READY_HOURS } from "../../constants";

const UPDATE_STORES = "updateStores";
const UPDATE_REVIEWS = "updateReviews";
const IS_FETCHING_STORES = "isFetchingStores";

const UPDATE_PICKUP_READY_HOURS = "UPDATE_PICKUP_READY_HOURS";

type PdpState = {
  stores: Store[];
  reviews: ReviewsAndAggregatedRating | null;
  isFetchingStores: boolean;
  clickAndCollectPickupReadyHours: number;
};

export type Action =
  | {
      type: typeof UPDATE_PICKUP_READY_HOURS;
      payload: number;
    }
  | {
      type: typeof UPDATE_STORES;
      payload: Store[];
    }
  | {
      type: typeof UPDATE_REVIEWS;
      payload: ReviewsAndAggregatedRating;
    }
  | {
      type: typeof IS_FETCHING_STORES;
      payload: boolean;
    };

const initialState: PdpState = {
  stores: [],
  reviews: null,
  isFetchingStores: false,
  clickAndCollectPickupReadyHours: DEFAULT_PICKUP_READY_HOURS,
};

const PdpContext = React.createContext<{
  state: PdpState;
  dispatch: React.Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => {
    return;
  },
});

const pdpReducer: React.Reducer<PdpState, Action> = (
  state,
  { payload, type }
) => {
  switch (type) {
    case UPDATE_STORES:
      return {
        ...state,
        stores: payload,
      };
    case UPDATE_REVIEWS:
      return {
        ...state,
        reviews: payload,
      };
    case IS_FETCHING_STORES: {
      return {
        ...state,
        isFetchingStores: payload,
      };
    }
    case UPDATE_PICKUP_READY_HOURS: {
      return {
        ...state,
        clickAndCollectPickupReadyHours: payload,
      };
    }
    default:
      return state;
  }
};

const usePdpContext = () => React.useContext(PdpContext);
const usePdpReducer = (state = initialState) =>
  React.useReducer(pdpReducer, state);

export {
  usePdpContext,
  usePdpReducer,
  PdpContext,
  UPDATE_STORES,
  UPDATE_REVIEWS,
  IS_FETCHING_STORES,
  UPDATE_PICKUP_READY_HOURS,
};
export type { PdpState };

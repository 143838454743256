/*
    Comparator basing on java class: se.istone.hybris.xxl.core.comparators.XXLSizeAttributeComparator from istone-xxl project
    and javascript file: sizeAttributeComparator.js from xxl-micro-search-service project. Translated to TS.
 */

// all predefined known sizes
const sizeSystems = [
  "XXS",
  "XS",
  "S",
  "M",
  "L",
  "LXL",
  "XL",
  "XXL",
  "2XL",
  "XXXL",
  "3XL",
  "4XL",
  "5XL",
];

// handles whole numbers, decimal numbers, number ranges - separated with '/' or '-'
const numericPattern = /^(\d+[.]?\d*)[/-]?\d*[.]?\d*$/;

export const compare = (value1?: string, value2?: string): number => {
  if (checkNullValues(value1, value2)) {
    return 0;
  }

  // change commas to dots if applicable
  value1 = fixDotNumberStyle(value1!);
  value2 = fixDotNumberStyle(value2!);

  const value1IsNumeric = isNumeric(value1);
  const value2IsNumeric = isNumeric(value2);

  if (value1IsNumeric && value2IsNumeric) {
    return numericCompare(value1, value2);
  }

  // first sizes from XS to 5XL
  const internationalSystemResult = processInternationalSizeSystems(
    value1,
    value2
  );

  if (internationalSystemResult !== null) {
    return internationalSystemResult;
  }

  const complexResult = processComplexSizeSystems(
    value1,
    value2,
    value1IsNumeric,
    value2IsNumeric
  );

  if (complexResult !== null) {
    return complexResult;
  }

  // no luck - sort the rest alphabetically
  return processAlphabetically(value1, value2);
};

const checkNullValues = (
  value1?: string | null,
  value2?: string | null
): boolean =>
  value1 === undefined ||
  value1 === null ||
  value2 === undefined ||
  value2 === null;

const fixDotNumberStyle = (value: string): string => value.replace(/,/g, ".");

const isNumeric = (value: string): boolean => numericPattern.test(value);

const numericCompare = (number1: string, number2: string): number => {
  const isNumber1Simple = !Number.isNaN(number1);
  const isNumber2Simple = !Number.isNaN(number2);

  if (isNumber1Simple && isNumber2Simple) {
    return (number1 as unknown as number) - (number2 as unknown as number);
  } else {
    return processSizeRanges(number1, number2);
  }
};

const processInternationalSizeSystems = (
  value1: string,
  value2: string
): number | null => {
  // try compare within each size system
  const value1SizeSystemIndex = sizeSystems.indexOf(value1);
  const value2SizeSystemIndex = sizeSystems.indexOf(value2);

  if (value1SizeSystemIndex > -1 && value2SizeSystemIndex > -1) {
    return value1SizeSystemIndex - value2SizeSystemIndex;
  }

  return null;
};

const processComplexSizeSystems = (
  value1: string,
  value2: string,
  value1IsNumeric: boolean,
  value2IsNumeric: boolean
): number | null => {
  const value1SizeSystemIndex = sizeSystems.indexOf(value1);
  const value2SizeSystemIndex = sizeSystems.indexOf(value2);

  // values may come from distinct size systems - where one is numeric
  if (value1IsNumeric) {
    return processMinSizeSystems(value2SizeSystemIndex);
  } else if (value2IsNumeric) {
    return processMaxSizeSystems(value1SizeSystemIndex);
  }
  // values may come from distinct size systems - where both are not numeric
  else if (value1SizeSystemIndex !== -1) {
    return processNonNumericDistinctSystems(
      value1,
      value2,
      value2SizeSystemIndex
    );
  } else if (value2SizeSystemIndex !== -1) {
    // values out of size-systems are placed as last thus number > 0.
    return 1;
  }

  return null;
};

const processNonNumericDistinctSystems = (
  value1: string,
  value2: string,
  value2SizeSystemIndex: number
): number => {
  if (value2SizeSystemIndex === -1) {
    // values out of size-systems are placed as last thus number < 0, meaning value2 is always bigger.
    return -1;
  } else {
    const val1Index = sizeSystems.indexOf(value1);
    const val2Index = sizeSystems.indexOf(value2);

    return val1Index - val2Index;
  }
};

const processMaxSizeSystems = (value1SizeSystemIndex: number): number => {
  if (value1SizeSystemIndex === -1) {
    // value1 is out of size-systems -> should be placed as last thus number > 0.
    return 1;
  } else {
    // value2 is numeric and value1 is from sizeSystems so value1 is always first in order
    return -1;
  }
};

const processMinSizeSystems = (value2SizeSystemIndex: number): number => {
  if (value2SizeSystemIndex === -1) {
    // value2 is out of size-systems -> should be placed as last thus number < 0.
    return -1;
  } else {
    // value1 is numeric and value2 is from sizeSystems so value2 is always first in order
    return 1;
  }
};

const processSizeRanges = (value1: string, value2: string): number => {
  const firstRangeNumber1 = numericPattern.exec(value1);
  const firstRangeNumber2 = numericPattern.exec(value2);
  let firstRangeNumber1Value: number;
  let firstRangeNumber2Value: number;

  let compare = 0;

  if (firstRangeNumber1 !== null && firstRangeNumber2 !== null) {
    firstRangeNumber1Value = firstRangeNumber1[1] as unknown as number;
    firstRangeNumber2Value = firstRangeNumber2[1] as unknown as number;

    compare = firstRangeNumber1Value - firstRangeNumber2Value;
  }

  if (compare === 0) {
    // distinguish between ex. "41" and "41-41.5"
    return value1.length - value2.length;
  }

  return compare;
};

const processAlphabetically = (value1: string, value2: string): number =>
  value1.localeCompare(value2);

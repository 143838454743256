import styled from "@emotion/styled/macro";
import { xxlTheme } from "../../styles/xxl-theme";
import { css } from "@mui/material";

const { spaces } = xxlTheme;

export const Fieldset = styled.fieldset`
  margin: 0;
  padding: 0;
  border: 0;

  legend {
    margin-bottom: ${spaces.regular};
    font-size: 1rem;
    font-family: var(--font-family-medium);
  }

  && label {
    margin: 0 0 10px;
    font-size: 0.9rem;
  }
`;

type LegendProps = {
  isRequired?: boolean;
};

export const Legend = styled.legend<LegendProps>(
  ({ isRequired = false }) => css`
    ${isRequired
      ? `&:after {
          content: "*";
        }`
      : ""}
  `
);

export const ErrorMessageWrapper = styled.div`
  height: 20px;
`;

import React from "react";
import { Button } from "../../../../styled/Button.styled";
import { useTranslations } from "../../../../contexts/Translations/TranslationsContext";
import {
  CustomerFoundIconWrapper,
  FormContainer,
  FormHeaderInModal,
} from "../../SignUp/SignUpForm/SignupForm.styled";
import { DialogBoxHeaderWrapper } from "../../../DialogBox/DialogBox.styled";
import DialogCloseButton from "../../SharedComponents/DialogBoxCloseButton";
import ReactHtmlParser from "html-react-parser";
import { LoginSuccessIcon } from "../LoginForm/LoginForm.styled";

type OTPLoginSuccess = {
  handleDialogBoxClosing: () => void;
};

const OTPLoginSuccess = ({ handleDialogBoxClosing }: OTPLoginSuccess) => {
  const { t } = useTranslations();

  return (
    <FormContainer inProgress={false}>
      <div>
        <DialogBoxHeaderWrapper>
          <FormHeaderInModal>
            {t("login.form.otp.login.successful.title")}
          </FormHeaderInModal>

          <DialogCloseButton handleDialogBoxClosing={handleDialogBoxClosing} />
        </DialogBoxHeaderWrapper>
        <p>
          {ReactHtmlParser(t("login.form.otp.login.successful.description"))}
        </p>
      </div>
      <CustomerFoundIconWrapper>
        <LoginSuccessIcon />
      </CustomerFoundIconWrapper>
      <Button
        id="reward-signup-form-submit"
        type="submit"
        onClick={handleDialogBoxClosing}
        inline={true}
        className="button button--primary button--small button--full-width"
      >
        {t("reward.signup.close")}
      </Button>
    </FormContainer>
  );
};

export { OTPLoginSuccess };

import styled from "@emotion/styled/macro";
import { fadeInAnimation } from "../../../styled/mixins";
/**
FIXME:We should not be importing from next-app into react app.
This is a temporary solution to allow us to use the NextImage component in the react app,
since the stub NextImage component does not work.
XD-15933
*/
import isPropValid from "@emotion/is-prop-valid";
import { NextImage } from "../../../../../next-js-app/src/components/common/NextImage/NextImage";

type ImgProps = {
  isVisible: boolean;
  fetchPriority?: "auto" | "high" | "low";
};
export const ProductImageWithFetchPrio = styled.img<ImgProps>`
  opacity: ${({ isVisible }): number => (isVisible ? 1 : 0)};
  ${({ isVisible }) =>
    isVisible ? fadeInAnimation("productImageFadeIn", "200ms", 0.5) : ""};
`;

ProductImageWithFetchPrio.defaultProps = {
  fetchPriority: "auto",
};

export const NextProductImage = styled(NextImage, {
  shouldForwardProp: isPropValid,
})<{
  isVisible: boolean;
}>`
  opacity: ${({ isVisible }): number => (isVisible ? 1 : 0)};
  ${({ isVisible }) =>
    isVisible ? fadeInAnimation("productImageFadeIn", "200ms", 0.5) : ""};
`;

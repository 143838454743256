import React, { useState } from "react";
import { hasNoValue, hasValue } from "@xxl/common-utils";
import {
  ProductImageWithFetchPrio,
  NextProductImage,
} from "./ProductImage.styled";
import { useXxlMediaQuery } from "../../../hooks/useXxlMediaQuery";
import { useSharedData } from "../../../contexts/SharedData/SharedDataContext";
import { getFileSpinUrl } from "../../../utils/getFilespinUrl";

type Props = {
  alt?: string;
  columnAmount?: number;
  fetchPriority?: "auto" | "high" | "low";
  focusImageUrl?: string;
  imageUrl?: string;
  isFocused?: boolean;
  loading?: "eager" | "lazy";
  size?: number;
  addImageParameters?: boolean;
};

export const ProductImage = ({
  alt = "",
  focusImageUrl,
  imageUrl,
  isFocused = false,
  size = 292,
  fetchPriority = "auto",
  loading,
}: Props) => {
  const { isReactApp } = useSharedData();
  const isTabletOrDesktop = useXxlMediaQuery("TabletAndDesktopMediaQuery");
  const [isVisible, setIsVisible] = useState(false);

  if (hasNoValue(imageUrl)) {
    return null;
  }

  const imgUrl = isReactApp
    ? getFileSpinUrl({
        baseUrl: imageUrl,
        resize: size,
      })
    : imageUrl;
  const focusedImgUrl =
    isReactApp && focusImageUrl !== undefined
      ? getFileSpinUrl({
          baseUrl: focusImageUrl,
          resize: size,
        })
      : focusImageUrl;

  const shouldShowHoverImage =
    isTabletOrDesktop && isFocused && hasValue(focusedImgUrl);

  if (isReactApp) {
    return (
      <figure data-private={true}>
        <ProductImageWithFetchPrio
          data-private={true}
          key={shouldShowHoverImage ? focusedImgUrl : imgUrl}
          src={shouldShowHoverImage ? focusedImgUrl : imgUrl}
          alt={alt}
          height={size}
          width={size}
          isVisible={isVisible}
          onLoad={() => setIsVisible(true)}
          fetchPriority={fetchPriority}
          loading={loading}
        />
        {isTabletOrDesktop && hasValue(focusedImgUrl) && (
          <ProductImageWithFetchPrio
            data-private={true}
            alt={alt}
            height={size}
            width={size}
            isVisible={false}
            src={focusedImgUrl}
            fetchPriority={fetchPriority}
            loading="lazy"
            style={{ position: "absolute" }}
          />
        )}
      </figure>
    );
  }

  return (
    <figure data-private={true}>
      <NextProductImage
        key={shouldShowHoverImage ? focusedImgUrl : imgUrl}
        src={shouldShowHoverImage ? focusedImgUrl : imgUrl}
        alt={alt}
        height={size}
        width={size}
        isVisible={isVisible}
        onLoad={() => setIsVisible(true)}
        priority={fetchPriority === "high"}
      />
      {isTabletOrDesktop && hasValue(focusedImgUrl) && (
        <NextProductImage
          alt={alt}
          height={size}
          width={size}
          isVisible={false}
          src={focusedImgUrl}
          style={{ position: "absolute" }}
        />
      )}
    </figure>
  );
};

import isEmpty from "lodash/isEmpty";
import React from "react";
import { createRoot } from "react-dom/client";
import { getLastLevelOfUrl } from "../../utils/xxl-url";
import { Providers } from "../ContextProviders/AllProviders";
import { getInitialRenderingData } from "../MegaMenu/Desktop/mega-menu-helper";
import { Header } from "./Header";
import { CartContextProvider } from "../Cart/CartContextProvider";
import type { HeaderConfig } from "../../global";

const mountHeader = (headerConfig: HeaderConfig): void => {
  const headerElement = document.getElementById("js-react-header");
  if (headerElement === null) {
    return;
  }
  const root = createRoot(headerElement);
  const { headerCode = "", isTeamsales = "false" } = headerElement.dataset;
  if (isEmpty(headerCode)) {
    throw Error("Missing headerCode");
  }
  const megaMenuContent = {
    links: getInitialRenderingData(),
  };
  const isTeamsalesBoolean = isTeamsales === "true";

  root.render(
    <Providers>
      <CartContextProvider isTeamsales={isTeamsalesBoolean}>
        <Header
          currentCategoryCode={getLastLevelOfUrl(new URL(window.location.href))}
          headerCode={headerCode}
          megaMenuContent={megaMenuContent}
          headerConfig={headerConfig}
        />
      </CartContextProvider>
    </Providers>
  );
};

export { mountHeader };

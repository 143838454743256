import React, { useEffect } from "react";
import type { PropsWithChildren } from "react";
import * as cookies from "react-app/src/utils/Cookie";
import { useSessionSource } from "react-app/src/contexts/Session/SessionContext";
import type { XXLCookie } from "react-app/src/global";
import { cookieNames } from "react-app/src/utils/Cookie";
import { useSharedData } from "react-app/src/contexts/SharedData";

export const SessionProvider = ({
  isReactApp,
  children,
}: PropsWithChildren & { isReactApp: boolean }) => {
  const { cookieVersion } = useSharedData().data;

  useEffect(() => {
    void (async () => {
      let cookie: XXLCookie | null = null;
      if (isReactApp) {
        cookie = cookies.getCookieParsed<XXLCookie>(cookieNames.XXL);
      } else {
        cookie = await cookies.getXXLCookie(cookieVersion);
      }
      const { loggedIn = false } = cookie ?? {};
      useSessionSource.set(loggedIn);
    })();
  }, [cookieVersion, isReactApp]);

  return <>{children}</>;
};

import * as React from "react";
import styled from "@emotion/styled/macro";
import { xxlTheme } from "../../../../styles/xxl-theme";
import type { SxProps } from "@mui/material";

type StarSvgGroupProps = {
  gradientId: string;
  percentageFilled: number;
  unfilledColor: string;
  filledColor: string;
};

/**
 * Returns gradient url when percentage is between 0 and 100,
 * otherwise color to enable transitions
 */
const getFillValue = (props: StarSvgGroupProps): string => {
  switch (props.percentageFilled) {
    case 0:
      return props.unfilledColor;
    case 100:
      return props.filledColor;
    default:
      return `url(#${props.gradientId})`;
  }
};

const StarSvgGroup = styled.g`
  transition: fill 200ms ease;
  fill: ${getFillValue};
`;

type StarProps = {
  percentageFilled: number;
  size?: StarSize;
  sx?: SxProps;
};

const throwIfOutOfBounds = (percentageFilled: number): void => {
  if (percentageFilled < 0) {
    throw TypeError(
      `Expected property "percentageFilled" to be >= 0. Actual value: ${percentageFilled};`
    );
  }
  if (percentageFilled > 100) {
    throw TypeError(
      `Expected property "percentageFilled" to be <= 100. Actual value: ${percentageFilled};`
    );
  }
};

const starSize = {
  xxs: {
    width: "8px",
    height: "8px",
  },
  xs: {
    width: "12px",
    height: "12px",
  },
  sm: {
    width: "17px",
    height: "16px",
  },
  md: {
    width: "32px",
    height: "30px",
  },
};

type StarSize = keyof typeof starSize;

const Star = React.forwardRef<SVGSVGElement, StarProps>(
  ({ percentageFilled, size = "md", sx: _sx, ...props }, ref) => {
    const { xxlGrey, xxlAmber } = xxlTheme.colors;
    const filledColor = xxlAmber;
    throwIfOutOfBounds(percentageFilled);
    const { width, height } = starSize[size];
    const id = `star-gradient-${percentageFilled}`;

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 32 30"
        ref={ref}
        {...props}
      >
        <title>star for ratings summary</title>
        <defs>
          <linearGradient id={id} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop
              offset="0%"
              style={{ stopColor: filledColor, stopOpacity: 1 }}
            />
            <stop
              offset={`${percentageFilled}%`}
              style={{ stopColor: filledColor, stopOpacity: 1 }}
            />
            <stop
              offset={`${percentageFilled}%`}
              style={{ stopColor: xxlGrey, stopOpacity: 1 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: xxlGrey, stopOpacity: 1 }}
            />
          </linearGradient>
        </defs>
        <StarSvgGroup
          gradientId={id}
          percentageFilled={percentageFilled}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          unfilledColor={xxlGrey}
          {...{ filledColor }}
        >
          <g xmlns="http://www.w3.org/2000/svg" id="surface1">
            <path d="M 15.953125 21.699219 L 24.5 28 L 21.230469 17.828125 L 29.777344 11.667969 L 19.292969 11.667969 L 15.953125 1.167969 L 12.609375 11.667969 L 2.125 11.667969 L 10.675781 17.828125 L 7.402344 28 Z M 15.953125 21.699219 " />
          </g>
        </StarSvgGroup>
      </svg>
    );
  }
);

Star.displayName = "Star component";

export { Star };
export type { StarSize };

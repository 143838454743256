import React from "react";

import type { FunctionComponent, PropsWithChildren } from "react";
import { StyleProvider } from "../../StyleProvider";
import { ReactAppSharedDataProvider } from "./ReactAppSharedDataProvider";
import { TranslationsProvider } from "./TranslationsProvider";
import { ApiClientsProvider } from "./ApiClientsProvider";
import { TrackingContextProvider } from "./TrackingContextProvider";
import { SessionProvider } from "next-js-app/src/components/Providers/SessionProvider";

const Providers: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <ReactAppSharedDataProvider>
      <SessionProvider isReactApp={true}>
        <ApiClientsProvider>
          <TrackingContextProvider>
            <TranslationsProvider>
              <StyleProvider>{children}</StyleProvider>
            </TranslationsProvider>
          </TrackingContextProvider>
        </ApiClientsProvider>
      </SessionProvider>
    </ReactAppSharedDataProvider>
  );
};

export { Providers };

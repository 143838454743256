import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { WriteReviewLink } from "./WriteReview";
import { withErrorBoundary } from "react-app/src/utils/WithErrorBoundary/with-error-boundary";
import { useSharedData } from "react-app/src/contexts/SharedData";
import { StarRating } from "react-app/src/components/RatingsAndReviews/Reviews/StarRating";

type Props = {
  averageRating: number | null;
  onClick?: () => void;
  recommendationPercentage?: number | null;
  shouldShowWriteReviewLink: boolean;
};
const _AverageRating = ({
  averageRating,
  onClick = undefined,
  recommendationPercentage,
  shouldShowWriteReviewLink,
}: Props) => {
  const { toggle_rate_and_review_section } =
    useSharedData().data.featureToggles;
  if (averageRating === null) {
    return shouldShowWriteReviewLink && !toggle_rate_and_review_section ? (
      <WriteReviewLink />
    ) : null;
  }

  return (
    <StarRating
      onClick={onClick}
      data-testid="rr-average-rating-wrapper"
      value={averageRating}
      readOnly={true}
      recommendationPercentage={recommendationPercentage}
      sx={{
        fontSize: `${xxlTheme.typography.smallRegular.fontSize}px`,
      }}
    />
  );
};

export const AverageRating = withErrorBoundary(_AverageRating);

import styled from "@emotion/styled/macro";
import React from "react";
import colors from "./MegaMenu.colors";

type TabButtonLabelProps = {
  onHover: () => void;
  label: string;
  removeRightPadding?: boolean;
};

const StyledTabButtonLabel = styled.span<{ removeRightPadding?: boolean }>`
  &&& {
    cursor: pointer;
    opacity: 1;
    padding: 6px 16px;
    width: 100%;
    ${({ removeRightPadding }) =>
      removeRightPadding === true ? "padding-right: 0" : ""};

    &:focus,
    &:hover {
      outline: none;
      font-weight: bold;
      color: ${colors.activeColor};
    }
  }
`;

export const TabButtonLabel: React.FunctionComponent<TabButtonLabelProps> = ({
  onHover,
  label,
  removeRightPadding = false,
}) => (
  <StyledTabButtonLabel
    tabIndex={0}
    onMouseOver={onHover}
    onFocus={onHover}
    onKeyDown={onHover}
    removeRightPadding={removeRightPadding}
  >
    {label}
  </StyledTabButtonLabel>
);

import styled from "@emotion/styled/macro";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

const RIBBON_HEIGHT = "42px";
const RIBBON_WIDTH = "60px";
const RIBBON_ANGLE_WIDTH = "30px";

const RibbonWrapper = styled.div`
  display: flex;
`;

const RibbonBody = styled.h2`
  display: grid;
  place-items: center;
  height: ${RIBBON_HEIGHT};
  color: ${xxlTheme.colors.xxlWebBlack};
  background-color: ${xxlTheme.colors.xxlLightGreen};
  margin: 0;
  padding: 0 1rem;
  ${xxlTheme.typography.bigBold};
`;

const RibbonAngle = styled.div`
  right: ${RIBBON_WIDTH};
  border-top: ${RIBBON_HEIGHT} solid ${xxlTheme.colors.xxlLightGreen};
  border-right: ${RIBBON_ANGLE_WIDTH} solid transparent;
`;

export const RibbonHeading = ({ children }: { children: React.ReactNode }) => (
  <RibbonWrapper>
    <RibbonBody>{children}</RibbonBody>
    <RibbonAngle />
  </RibbonWrapper>
);

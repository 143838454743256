import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import {
  getAutomatedBadgeConfig,
  type BadgeConfig,
} from "../../../utils/ProductData/badges-helper";
import type { BadgeType } from "../../../utils/data-types";
import type { RibbonDirection } from "../NewRibbon/NewRibbon";
import { NewRibbon } from "../NewRibbon/NewRibbon";

type AutomatedRibbonProps = {
  badgeType: BadgeType;
  direction?: RibbonDirection;
};

export const AutomatedRibbon = ({
  badgeType,
  direction = "right",
}: AutomatedRibbonProps) => {
  const { t } = useTranslations();
  const badge: BadgeConfig = getAutomatedBadgeConfig(badgeType);
  return (
    <NewRibbon colorTheme={badge} label={t(badge.name)} direction={direction} />
  );
};

import { colorsAsHex } from "./theme/colors";
import typography from "./theme/typography";
import spaces from "./theme/spaces";
import iconSize from "./theme/iconSize";
import zIndex from "./theme/zIndex";
import alpha from "./theme/alpha";

/**
 * The XXL theme object.
 * @param {object} colors - The colors object for the XXL theme is deprecated
 * use import colors directly from `@xxl/theme` instead.
 */
const xxlTheme = {
  colors: colorsAsHex,
  ...{ iconSize, spaces, typography, zIndex, alpha },
};
const componentMaxWidth = 1440;
const containerMaxWidth = 1332;
const containerDesktopOffset = 100;
const containerMaxWidthPx = `${containerMaxWidth}px`;

// eslint-disable-next-line import/no-unused-modules
export {
  xxlTheme,
  componentMaxWidth,
  containerMaxWidth,
  containerMaxWidthPx,
  containerDesktopOffset,
};

import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { color } from "@xxl/theme";
import type * as CSSType from "csstype";
import { typographyToCss } from "../../../../styles/helpers";
import spaces from "../../../../styles/theme/spaces";
import { fontFamilies } from "../../../../styles/theme/typography";

export const Wrapper = styled.p(css`
  width: 60px;
  display: flex;
  flex-direction: column;
  margin: 0;
`);

export const Price = styled.span<{
  isDiscounted: boolean;
  textDecoration?: CSSType.Property.TextDecoration;
}>`
  ${({ isDiscounted, textDecoration }) => css`
    color: ${isDiscounted ? color.red.hex : color.black.hex};
    margin-bottom: ${spaces.line};
    text-decoration: ${textDecoration !== undefined ? textDecoration : "none"};

    ${typographyToCss({
      fontSize: 15,
      fontFamily: fontFamilies.medium,
      fontWeight: 700,
      letterSpacing: 0.15,
      lineHeight: 1,
    })}
  `}
`;

export const Label = styled.span(css`
  display: flex;
  display: column;
  margin: 0;
  word-break: break-word;

  ${typographyToCss({
    fontSize: 10,
    laptop: {
      fontSize: 15,
    },
  })}
`);

import * as React from "react";
import type { SkeletonProps, SkeletonTypeMap } from "@mui/material/Skeleton";
import { default as MUISkeleton } from "@mui/material/Skeleton";
import type { OverridableComponent } from "@mui/material/OverridableComponent";

export const SKELETON_TEST_ID = "loading-skeleton";

export const Skeleton: OverridableComponent<SkeletonTypeMap> = (
  props: SkeletonProps
) => <MUISkeleton {...{ "data-testid": SKELETON_TEST_ID, ...props }} />;

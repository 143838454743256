import { isNotNullOrUndefined } from "@xxl/common-utils";

/**
 * sums a list of numbers, ignores null and undefined, returns null on empty list or list with only null or undefined
 * @param toSum list to sum
 */
export const sumIfSet = (
  ...toSum: (number | null | undefined)[]
): number | null =>
  toSum
    .filter(isNotNullOrUndefined)
    .reduce(
      (sum, next) => (sum !== null ? sum + next : next),
      null as number | null
    );

import styled from "@emotion/styled/macro";
import { FormErrorMessage } from "../../styled";
import { MQ, typographyToCss } from "../../styles/helpers";
import typography from "../../styles/theme/typography";
import { xxlTheme } from "../../styles/xxl-theme";
import {
  Footer,
  MiniCartHeader,
  CloseButton as MiniCartCloseBtn,
} from "../Cart/Styles/MiniCartContent.styled";
import {
  Brand,
  Link,
  BrandAndName as ProductBrandAndName,
  ProductColorAndSize,
} from "../Cart/Styles/ProductItem.styled";
import { ToggleButton } from "../Common/Button/ToggleButton";
import type * as CSSType from "csstype";
import CartPageTypography from "../Cart/Styles/CartPage.typography";
import { css } from "@emotion/react";

const { miniCartHeader } = CartPageTypography;

const { spaces, colors } = xxlTheme;
const warningTypography = {
  fontSize: 12,
  lineHeight: 1.2,
  fontFamily: "var(--font-family-bold)",
};
const spacing = "12px";

export const CrossSalesHeader = styled(MiniCartHeader)`
  box-sizing: border-box;
  grid-row: header-row;
  background-color: ${colors.xxlLightGreen};
`;

export const CrossSalesWarning = styled.div`
  grid-row: warning-row;
  padding: ${spaces.smallRegular};
  background-color: ${colors.xxlLightAmber};
  color: ${colors.xxlWebBlack};
  display: flex;
  ${typographyToCss(warningTypography)};

  & svg {
    margin-right: ${spaces.mini};
    width: 15px;
    height: 15px;
  }
`;

export const CrossSalesSubheader = styled.h4`
  grid-row: subheader-row;
  border-top: 1px solid ${colors.xxlMediumGrey};
  padding: ${spaces.smallRegular};
  margin: 0;
  ${typographyToCss({
    fontSize: 20,
    lineHeight: 1.1,
  })}
`;

export const CrossSalesGridContent = styled.div`
  display: grid;
  align-content: start;
  grid-template-columns: 1fr;
  height: 100%;

  ${MQ("laptop")} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 14px;
    padding: 0 ${spacing};
  }
`;

const footerPadding = 12;
export const CrossSalesFooter = styled(Footer)`
  grid-row: footer-row;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: ${footerPadding}px;
  background-color: ${colors.xxlWhite};
  position: sticky;
  bottom: 0;

  ${MQ("laptop")} {
    justify-content: end;
  }

  .button {
    width: calc(50% - ${footerPadding / 2}px);

    ${MQ("laptop")} {
      width: fit-content;
      margin-left: ${spacing};
    }
  }
`;

export const CrossSalesAddToCartStyledButton = styled.button`
  margin: ${spaces.micro} 0 0 auto;
`;

export const CrossSalesProductWrapper = styled.article`
  display: flex;
  align-items: start;
  padding: ${spaces.smallRegular};
  border-bottom: 1px solid ${colors.xxlMediumGrey};
  box-sizing: border-box;

  ${MQ("laptop")} {
    border: 1px solid ${colors.xxlMediumGrey};
  }

  ${() => {
    if ("next" in window) {
      // https://xxlsports.atlassian.net/browse/XD-11583
      return "";
    }
    return css`
      ${FormErrorMessage} {
        display: block;
        height: ${spaces.regular};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        > svg {
          margin-right: ${spaces.micro};
          vertical-align: bottom;
        }
      }
    `;
  }}
`;

export const CrossSalesProductActionsWrapper = styled.div<{
  flexDirection: CSSType.Property.FlexDirection;
}>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  align-items: flex-end;
  padding: 0 0 0 ${spacing};
  margin-top: 20px;

  &.popup-open {
    padding-top: ${spaces.smallRegular};
  }
`;

export const CrossSalesSkeletonWrapper = styled.div`
  margin-bottom: ${spaces.smallLarge};
`;

export const CrossSalesProductOutOfStockMessage = styled.span`
  ${typographyToCss(typography.smallRegular)};
  color: ${colors.xxlError};
  margin-left: ${spaces.smallRegular};
`;

export const CrossSalesProductLowOfStockMessage = styled(
  CrossSalesProductOutOfStockMessage
)`
  color: ${colors.xxlWarning};
`;

export const CrossSalesOptionsToggleButton = styled(ToggleButton)`
  align-self: flex-start;
  margin: 0 0 10px;
`;

export const CrossSalesProductBrand = styled(Brand)`
  margin-right: ${spaces.micro};
`;

export const CrossSalesProductLink = styled(Link)`
  align-items: flex-start;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: ${spacing};
`;

export const BrandAndName = styled(ProductBrandAndName)`
  padding-right: 10px;
  margin-bottom: 0;
  -webkit-line-clamp: 3;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const SizeColorInfo = styled(ProductColorAndSize)`
  margin: 0;
`;

export const OptionsWrapper = styled.div`
  width: 100%;
`;

export const HeaderText = styled.h2`
  color: ${colors.xxlBlack};
  ${typographyToCss(miniCartHeader)};
`;

export const CloseButton = styled(MiniCartCloseBtn)`
  color: ${colors.xxlBlack};
`;

export const FreeDeliveryWrapper = styled.div`
  padding: ${spacing};
`;

export const InfoWrapper = styled.p`
  display: flex;
  justify-content: center;
`;

export const InfoMessage = styled.span`
  margin-left: ${spaces.mini};
`;

import isString from "lodash/isString";

const isDateWithinRange = ({
  fromDate,
  toDate,
}: {
  fromDate?: string;
  toDate?: string;
}) => {
  if (!isString(fromDate) && !isString(toDate)) {
    return true;
  }
  const currentDate = new Date();
  if (
    (!isString(fromDate) || new Date(fromDate) <= currentDate) &&
    (!isString(toDate) || new Date(toDate) > currentDate)
  ) {
    return true;
  }
  return false;
};

export { isDateWithinRange };

import styled from "@emotion/styled/macro";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { Icon } from "react-app/src/components/Icon";
import { XxlStack } from "../../Common/XxlStack";

const { typography, spaces } = xxlTheme;

const StyledTitle = styled.h3`
  font-size: ${typography.hugeBold.fontSize}px;
  font-family: ${typography.hugeBold.fontFamily};
  margin: 0;
`;

export const Title = ({ title }: { title: string }) => (
  <XxlStack
    alignItems={"center"}
    display={"flex"}
    flexDirection={"row"}
    gap={spaces.miniMicro}
  >
    <Icon name="Info" size={20} color={"black"} />
    <StyledTitle>{title}</StyledTitle>
  </XxlStack>
);

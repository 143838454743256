import React from "react";
import { useCartContext } from "../CartState";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { PageWarning } from "../Styles/CollectWarningOnPage.styled";
import { Info } from "@xxl/icons";

export const CollectWarningOnPage: React.FunctionComponent = () => {
  const { state } = useCartContext();
  const { t } = useTranslations();

  return state.isCollectWarningDisplayed === true ? (
    <PageWarning>
      <Info />
      {t("product.details.add.to.cart.warning.mixed.cart.stock")}
    </PageWarning>
  ) : null;
};

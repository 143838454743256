import styled from "@emotion/styled/macro";
import { color as themeColor } from "@xxl/theme";
import type { ReactNode } from "react";
import React from "react";
import { typographyToCss } from "../../../src/styles/helpers";
import { fontFamilies, fontSizes } from "../../../src/styles/theme/typography";

const LINE_HEIGHT = 1.2;
const { tiny, small, medium: base, big } = fontSizes;

const TextSizeMap = {
  tiny,
  small,
  base,
  big,
} as const;

const TypographyMap = {
  base: {
    fontSize: base,
    fontFamily: fontFamilies.regular,
  },
  baseMedium: {
    fontSize: base,
    fontFamily: fontFamilies.medium,
  },
  baseBold: {
    fontSize: base,
    fontFamily: fontFamilies.bold,
  },
  small: {
    fontSize: small,
    fontFamily: fontFamilies.regular,
  },
  smallMedium: {
    fontSize: small,
    fontFamily: fontFamilies.medium,
  },
  smallBold: {
    fontSize: small,
    fontFamily: fontFamilies.bold,
  },
  tiny: {
    fontSize: tiny,
    fontFamily: fontFamilies.regular,
  },
  tinyMedium: {
    fontSize: tiny,
    fontFamily: fontFamilies.medium,
  },
  tinyBold: {
    fontSize: tiny,
    fontFamily: fontFamilies.bold,
  },
} as const;

/**
 * Type-safe way to get a color from xxlTheme.colors
 * @param color a color key from xxlTheme.colors
 * @returns a hex color string for the corresponding color key
 * @example getColor("xxlWebBlack") // returns "#161616"
 */
const getColor = <TThemeColorKey extends keyof typeof themeColor>(
  color: TThemeColorKey
) => themeColor[color].hex;

const getFontSize = (size?: TextSize, typography?: Typography) => {
  if (size !== undefined) {
    return TextSizeMap[size];
  }
  if (typography !== undefined) {
    return TypographyMap[typography].fontSize;
  }
  return TextSizeMap.base;
};

const getFontFamily = (fontFamily?: FontFamily, typography?: Typography) => {
  if (fontFamily !== undefined) {
    return fontFamilies[fontFamily];
  }
  if (typography !== undefined) {
    return TypographyMap[typography].fontFamily;
  }
  return fontFamilies.regular;
};

type Color = keyof typeof themeColor;
type Typography = keyof typeof TypographyMap;
type TextSize = keyof typeof TextSizeMap;
type FontFamily = keyof typeof fontFamilies;

export type TextProps = {
  fontFamily?: FontFamily;
  size?: TextSize;
  typography?: Typography;
  color?: Color;
  sizeCustom?: "inherit" | number;
  as?: "p" | "span" | "div" | "h1";
  align?: "center" | "left" | "right";
  title?: string;
  className?: string;
  uppercase?: boolean;
  strikethrough?: boolean;
  underline?: boolean;
  padding?: string;
  children?: ReactNode;
  margin?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const TextBase = styled.div<TextProps>`
  ${({ typography = undefined }) =>
    typography !== undefined && typographyToCss(TypographyMap[typography])};
  font-size: ${({ size, typography }) => `${getFontSize(size, typography)}px`};
  font-family: ${({ typography, fontFamily }) =>
    getFontFamily(fontFamily, typography)};
  line-height: ${LINE_HEIGHT};
  color: ${({ color = "webBlack" }) => getColor(color)};
  text-align: ${({ align = "left" }) => align};
  text-transform: ${({ uppercase = false }) => uppercase && "uppercase"};
  text-decoration: ${({ strikethrough = false, underline = false }) =>
    strikethrough ? "line-through" : underline ? "underline" : "none"};
  font-size: ${({ sizeCustom = undefined }) =>
    sizeCustom !== undefined && typeof sizeCustom === "number"
      ? `${sizeCustom}px`
      : sizeCustom};
  ${({ padding }) => (padding !== undefined ? `padding: ${padding}` : "")};
  ${({ margin }) => (margin !== undefined ? `margin: ${margin}` : "")};
`;

/**
 * A component for displaying text
 * @param typography the typography of the text. The Size and Font Family will be determined by this prop
 * @param size the size of the text. It will override the typography prop's size
 * @param fontFamily the font family of the text. It will override the typography prop's font family
 *
 * @example ```jsx
 * <Text typography="smallBold">Hello World</Text>
 * <Text size="base" as="span" color="webBlack">Hello World</Text>
 * <Text sizeCustom={22} color="amber">Hello World</Text>
 * ```
 */
export const Text = ({ as, children, className, ...rest }: TextProps) => {
  return (
    <TextBase as={as} className={className} {...rest}>
      {children}
    </TextBase>
  );
};

import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import type { StrictClassification } from "src/components/ProductDetailsPage/types";

type FormattedClassification = [string, string];

export const useClassifications = (
  classifications: StrictClassification[],
  config: {
    limit: number;
    positiveOnly: boolean;
  }
) => {
  const { t } = useTranslations();
  const attributesSet = new Set<FormattedClassification>();

  for (const c of classifications) {
    if (attributesSet.size >= config.limit) break;
    if (c.valueType === "string" || c.valueType === "number") {
      attributesSet.add([c.name, `${c.value} ${c.unit ?? ""}`]);
    }
    if (
      c.valueType === "boolean" &&
      config.positiveOnly &&
      (c.value === false || c.value === "false")
    ) {
      continue;
    }
    if (c.valueType === "boolean") {
      if (c.value === true || c.value === "true") {
        const value = t("general.yes");
        attributesSet.add([c.name, value]);
      }
      if (c.value === false || c.value === "false") {
        const value = t("general.no");
        attributesSet.add([c.name, value]);
      }
    }
  }

  return Array.from(attributesSet);
};

import React from "react";
import { PRODUCT_IMAGE_HEIGHT, ThumbnailImage } from "./ProductImage.styles";
import type { KeenSliderInstance } from "keen-slider/react";
import { useKeenSlider } from "keen-slider/react";
import {
  ThumbnailPlugin,
  createThumbnailSrc,
} from "./ProductImageSlider.helper";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { KEEN_SLIDER_CLASSES } from "react-app/src/components/Common/Slider/Slider.helper";
import { IMAGE_SIZE } from "../constants";
export const ThumbnailSlider = ({
  productImages,
  instanceRef,
  currentSlide,
}: {
  productImages: string[];
  instanceRef: React.MutableRefObject<KeenSliderInstance | null>;
  currentSlide: number;
}) => {
  const { t } = useTranslations();

  const [thumbnailRef] = useKeenSlider<HTMLDivElement>(
    {
      vertical: true,
      slides: {
        spacing: 4,
        perView: 5.5,
      },
    },
    [ThumbnailPlugin(instanceRef)]
  );

  return (
    <div
      ref={thumbnailRef}
      className={KEEN_SLIDER_CLASSES.Slider}
      style={{ height: PRODUCT_IMAGE_HEIGHT }}
    >
      {productImages.map((productImage, index) => (
        <ThumbnailImage
          key={`${index} - ${productImage}`}
          src={createThumbnailSrc(productImage)}
          selected={currentSlide === index}
          className={KEEN_SLIDER_CLASSES.Slide}
          priority={true}
          width={IMAGE_SIZE.THUMBNAIL}
          height={IMAGE_SIZE.THUMBNAIL}
          alt={t("product.image")}
          onClick={() => instanceRef.current?.moveToIdx(index)}
        />
      ))}
    </div>
  );
};

import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import spaces from "../../../styles/theme/spaces";

type LinkContainerProps = {
  nrOfColumns?: number;
  nrOfRows?: number;
};

const columnGap = "20px";
const headerRowCount = 1;
const linkRowCount = 1;
const categoryRowsCount = 10;
const rowsPerColumn = categoryRowsCount + headerRowCount + linkRowCount;

export const Wrapper = styled.div<LinkContainerProps>(
  ({ nrOfColumns = 6 }) => css`
    display: grid;
    grid-template-columns: repeat(${nrOfColumns}, 1fr);
    column-gap: ${columnGap};
    row-gap: ${spaces.big};
  `
);

export const LinkContainer = styled.div<LinkContainerProps>(
  ({ nrOfColumns = 1, nrOfRows = rowsPerColumn }) => css`
    display: grid;
    grid-template-rows: repeat(${nrOfRows}, auto);
    grid-template-columns: ${`repeat(${nrOfColumns}, 1fr)`};
    grid-auto-flow: column;
    row-gap: var(--space-small-regular);
    column-gap: ${columnGap};
    grid-column: ${`span ${nrOfColumns}`};
    height: fit-content;
  `
);

export const LastGridChild = styled.div(css`
  grid-column: 6;
`);

type GridProps = {
  itemType?: string;
  theme?: any;
};

export const StyledGridTemporary = styled.div<GridProps>(
  ({ itemType }) => css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: var(--space-small-regular);
    ${itemType === "wrapper" &&
    css`
      display: grid;
      grid-auto-rows: minmax(min-content, max-content);
      grid-row-gap: var(--space-small-huge);
      grid-column-gap: var(--space-regular);
      grid-template-areas:
        "left-column-item left-column-item left-column-item brands"
        "left-column-item left-column-item left-column-item picture";
      grid-template-columns: repeat(4, minmax(0, 1fr));
    `}
    ${itemType === "picture" &&
    css`
      grid-area: picture;
    `}
      ${itemType === "brands" &&
    css`
      grid-area: brands;
    `}
  `
);

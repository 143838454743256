import styled from "@emotion/styled/macro";
import colors from "./MegaMenu.colors";

export const Root = styled.div<{ transparentBg: boolean }>`
  flex-grow: 1;
  width: 100%;
  & > header {
    color: ${colors.tabButtonTextColor};
    background: ${
      ({ transparentBg }) =>
        transparentBg
          ? "transparent;"
          : `${colors.tabButtonBackgroundColor} !important;` // must overwrite inline style
    }
`;

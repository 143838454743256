import { isNotNullOrUndefined } from "@xxl/common-utils";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useRef, useState } from "react";
import ReactHtmlParser from "html-react-parser";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { CaretIcon } from "../Icons/Caret";
import {
  Description,
  ReadDescriptionButton,
} from "./ExpandableDescription.styled";

type ExpandableDescriptionProps = {
  description: string;
  descriptionStyles?: React.CSSProperties;
  descriptionTestId?: string;
  descriptionWrapperStyles?: React.CSSProperties;
  fullDescriptionTestId?: string;
  /** Resolution in px. Above this width, text will not be clamped */
  minResolutionToStopClamping?: number;
  numberOfLinesToShow?: number;
  showMoreButtonStyles?: React.CSSProperties;
  showMoreButtonTestId?: string;
  trackingCb?: (trackingLabel: string) => void;
};

export const ExpandableDescription: React.FunctionComponent<
  ExpandableDescriptionProps
> = ({
  description,
  descriptionStyles,
  descriptionTestId,
  descriptionWrapperStyles,
  fullDescriptionTestId,
  minResolutionToStopClamping,
  numberOfLinesToShow = 4,
  showMoreButtonStyles,
  showMoreButtonTestId,
  trackingCb,
}) => {
  const { t } = useTranslations();
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const [showReadMoreButton, setShowReadMoreButton] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (isNotNullOrUndefined(descriptionRef.current) && !isExpanded) {
      const { scrollHeight, clientHeight } = descriptionRef.current;
      const isTruncated = scrollHeight > clientHeight;
      setShowReadMoreButton(isTruncated);
    }
  }, [description, descriptionRef, isExpanded]);

  if (isEmpty(description)) {
    return null;
  }

  const handleReadMoreButtonClick = () => {
    const trackingLabel = isExpanded ? "Read less" : "Read more";
    setIsExpanded(!isExpanded);
    isNotNullOrUndefined(trackingCb) && trackingCb(trackingLabel);
  };

  return (
    <section style={descriptionWrapperStyles}>
      <Description
        ref={descriptionRef}
        isExpanded={isExpanded}
        numberOfLinesToShow={numberOfLinesToShow}
        style={descriptionStyles}
        data-testid={isExpanded ? fullDescriptionTestId : descriptionTestId}
        minResolutionToStopClamping={minResolutionToStopClamping}
      >
        {ReactHtmlParser(description)}
      </Description>

      {showReadMoreButton && (
        <ReadDescriptionButton
          onClick={handleReadMoreButtonClick}
          style={showMoreButtonStyles}
          data-testid={showMoreButtonTestId}
        >
          {t(isExpanded ? "read.less" : "read.more")}
          <CaretIcon direction={isExpanded ? "up" : "down"} />
        </ReadDescriptionButton>
      )}
    </section>
  );
};

import type { ReactNode } from "react";
import React from "react";
import styled from "@emotion/styled/macro";
import MuiBox from "@mui/material/Box";
import { HOVER_DELAY_MS } from "./config";
import spaces from "../../../styles/theme/spaces";
import { colorsAsCssVariable } from "../../../styles/theme/colors";
import { MQ, noScrollbars } from "../../../styles/helpers";
import { componentMaxWidth } from "../../../styles/xxl-theme";
import { css } from "@emotion/react";
import {
  TABPANEL_HEIGHT_OFFSET_DESKTOP,
  TABPANEL_HEIGHT_OFFSET_TABLET,
} from "./constants";

const Overlay = styled.div<{ height: number }>`
  background: ${colorsAsCssVariable.xxlBlack};
  height: ${({ height }) => height}px;
  inset: 0;
  margin-top: var(--space-mini);
  opacity: 0.5;
  position: absolute;
  z-index: -1;
`;

type TabPanelStyledProps = {
  isSmall?: boolean;
  offsetLeft?: number;
};

const TabPanelStyled = styled.div<TabPanelStyledProps>(
  ({ isSmall = false, offsetLeft }) => css`
    background: ${colorsAsCssVariable.xxlWhite};
    box-sizing: border-box;
    padding: 0 ${spaces.smallRegular};
    margin: 0 auto;
    margin-left: ${isSmall && offsetLeft !== undefined
      ? `${offsetLeft}px`
      : undefined};
    max-width: ${componentMaxWidth}px;
    width: ${isSmall ? "260px" : "100%"};
    overflow-y: auto;
    ${noScrollbars()}

    ${MQ("tablet")} {
      max-height: calc(100vh - ${TABPANEL_HEIGHT_OFFSET_TABLET}px);
      padding: ${isSmall ? "0 24px" : "0 54px"};
    }
    ${MQ("bigDesktop")} {
      max-height: calc(100vh - ${TABPANEL_HEIGHT_OFFSET_DESKTOP}px);
    }
  `
);

const Box = styled(MuiBox)`
  display: flex;
  flex-direction: column;
  gap: var(--space-small-large);
`;

type TabPanelProps = {
  active: boolean;
  isTabChange: boolean;
  isSmall?: boolean;
  offsetLeft?: number;
  children?: ReactNode;
  closeMenu: () => void;
};

export const TabPanel: React.FunctionComponent<TabPanelProps> = ({
  children,
  active,
  isTabChange,
  isSmall = false,
  offsetLeft,
  closeMenu,
}) => {
  const [isActive, setIsActive] = React.useState<boolean>(false);
  React.useEffect(() => {
    const delay = isTabChange ? 0 : HOVER_DELAY_MS;
    const timeoutRef = setTimeout(() => {
      setIsActive(active);
    }, delay);
    return (): void => clearTimeout(timeoutRef);
  }, [active, isTabChange, isSmall]);

  return (
    <>
      {isActive && (
        <Overlay
          className="mega-menu-overlay"
          data-testid="mega-menu-overlay"
          height={document.documentElement.scrollHeight}
          onMouseEnter={closeMenu}
        />
      )}
      <TabPanelStyled
        role="tabpanel"
        hidden={!isActive}
        isSmall={isSmall}
        offsetLeft={offsetLeft}
      >
        {isActive && (
          <Box
            p={3}
            sx={{
              p: isSmall
                ? `${spaces.large} 0`
                : `${spaces.big} 0 ${spaces.huge}`,
            }}
          >
            {children}
          </Box>
        )}
      </TabPanelStyled>
    </>
  );
};

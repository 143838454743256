import type { ButtonHTMLAttributes, FunctionComponent } from "react";
import React from "react";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const Button: FunctionComponent<ButtonProps> = ({
  type = "button",
  ...props
}) => (
  // eslint-disable-next-line react/button-has-type
  <button {...props} type={type} />
);

const SubmitButton: FunctionComponent<Omit<ButtonProps, "type">> = ({
  ...props
}) => <button {...props} type="submit" />;

export { Button, SubmitButton };

import type { FunctionComponent, PropsWithChildren } from "react";
import React from "react";
import styled from "@emotion/styled/macro";
import { Error as AlertIcon, Wrongprice } from "@xxl/icons";
import { Fade } from "../../components/Common";

type PassedProps = {
  showIcon?: boolean;
};

export const AlignedAlertIcon = styled(Wrongprice)`
  position: relative;
  width: 1rem;
  height: 1rem;
  margin-right: var(--space-mini);
`;

export const FormErrorMessage = styled(
  ({ children, showIcon = true, ...props }: PropsWithChildren<PassedProps>) => (
    <Fade in={children !== null}>
      {children !== null ? (
        <div role="alert" {...props}>
          {showIcon && <AlertIcon />}
          {children}
        </div>
      ) : null}
    </Fade>
  )
)`
  display: flex;
  gap: var(--space-micro);
  align-items: center;
  margin: 7px 0 0;
  color: var(--color-xxl-web-black);
  font-size: 0.75rem;
  font-family: var(--font-family-regular);

  & svg {
    color: var(--color-xxl-red);
  }
`;

export type InputErrorMessageProps = {
  top?: string;
  color?: string;
};
export const InputErrorMessage = styled<
  FunctionComponent<PropsWithChildren<{ showIcon?: boolean }>>
>(({ children, ...props }) => (
  <FormErrorMessage {...props}>{children}</FormErrorMessage>
))<InputErrorMessageProps>`
  position: relative;
  top: ${({ top = "0" }) => top};
  display: flex;
  align-items: start;

  svg {
    min-width: 15px;
    max-width: 15px;
    height: 15px;
    margin-right: 5px;
    color: ${({ color = "currentColor" }) => color};
  }

  span {
    position: relative;
    top: 1px;
  }
`;

import { FONT_SIZE_TO_PREVENT_IOS_ZOOM_ON_FOCUS } from "react-app/src/constants";

export default {
  input: {
    fontFamily: "var(--font-family-regular)",
    fontSize: FONT_SIZE_TO_PREVENT_IOS_ZOOM_ON_FOCUS,
    lineHeight: 1.2,
    letterSpacing: -0.15,
  },
};

import { resolutionSizes } from "./config";
import { xxlTheme } from "./styles/xxl-theme";
import "@emotion/react";

const themeConfig = {
  breakpoints: {
    values: {
      xs: 0,
      sm: resolutionSizes.mobile,
      md: resolutionSizes.tablet,
      lg: resolutionSizes.laptop,
      xl: resolutionSizes.desktop,
    },
  },
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: "0",
        minHeight: "500px",
        minWidth: "320px",
      },
    },
  },
  palette: {
    primary: {
      main: xxlTheme.colors.xxlGreen,
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true,
    },
  },
};

export { themeConfig };

import type {
  AddBundledProductsToCartItemInput,
  AddConfigurationsToCartItemInput,
} from "../../../generated/graphql-code-generator";
import type { BundleConfigurationGroupData } from "../../../global";

export const SINGLE_QUANTITY_NUMBER = 1;

export const setBundledProducts = (
  groups: BundleConfigurationGroupData[]
): AddBundledProductsToCartItemInput[] =>
  groups
    .filter((group) => group.bundleType !== undefined)
    .map((group) => group.products)
    .flat(1)
    .filter(({ ean }) => ean !== undefined)
    .map(({ ean }) => ({
      ean: ean ?? "",
      quantity: SINGLE_QUANTITY_NUMBER,
    }));

export const setBundledProductsConfigurations = (
  groups: BundleConfigurationGroupData[]
): AddConfigurationsToCartItemInput[] =>
  groups
    .filter((group) => group.bundleType === undefined)
    .map((group) => group.products)
    .flat(1)
    .filter(({ ean }) => ean !== undefined)
    .map(({ ean }) => ({
      ean: ean ?? "",
    }));

import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { typographyToCss } from "../../../styles/helpers";
import { xxlTheme } from "../../../styles/xxl-theme";
import typography from "./CartPage.typography";

const { colors, spaces } = xxlTheme;
const { smallPrices, totalPrice, couponsRow } = typography;

export const PriceBox = styled.div`
  background-color: ${colors.xxlWhite};
  padding: ${spaces.smallRegular};
  margin-top: ${spaces.smallRegular};
`;

export const PricesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  &:not(:has(li)) {
    display: none;
  }
`;

type SmallPriceProps = {
  discountPrice?: boolean;
  giftCardPrice?: boolean;
};

export const SmallPriceRow = styled.li<SmallPriceProps>(
  ({ discountPrice = false, giftCardPrice = false }) => css`
    display: flex;
    justify-content: space-between;
    ${typographyToCss(smallPrices)};
    margin-bottom: ${spaces.smallRegular};

    ${discountPrice &&
    css`
      color: ${colors.xxlError};
    `}

    ${giftCardPrice &&
    css`
      color: ${colors.xxlWebGreen};
    `}
  `
);

export const TotalPriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  ${typographyToCss(totalPrice)};
  padding-bottom: ${spaces.smallRegular};

  &:last-child {
    padding-bottom: 0;
  }
`;

export const CouponsRow = styled.div`
  ${typographyToCss(couponsRow)};
  margin: ${spaces.smallRegular} 0;
`;

export const PromotionsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const PromotionsItem = styled.li`
  &:not(:first-of-type) {
    margin-top: ${spaces.mini};
  }
`;

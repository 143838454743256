import {
  SanityContent,
  type AutocompleteQuery,
  type ElevateApi,
  type ContentListSettingsSearch,
} from "@xxl/product-search-api";

export const CONTENT_NAMES = {
  [SanityContent.BRAND]: "brand",
  [SanityContent.CAMPAIGN]: "campaign",
  [SanityContent.CATEGORY]: "category",
  [SanityContent.FAQ]: "faq",
  [SanityContent.GUIDE]: "guide",
  [SanityContent.STORE]: "store",
  products: "products",
} as const;

export type ContentName = (typeof CONTENT_NAMES)[keyof typeof CONTENT_NAMES];

const DEFAULT_LIMIT = 5;

export const prepareContentLists = (
  limit: number = DEFAULT_LIMIT
): ContentListSettingsSearch[] => [
  {
    contentFilter: {
      type: [SanityContent.BRAND],
    },
    id: CONTENT_NAMES[SanityContent.BRAND],
    limit,
  },
  {
    contentFilter: {
      type: [SanityContent.CATEGORY],
    },
    id: CONTENT_NAMES[SanityContent.CATEGORY],
    limit,
  },
  {
    contentFilter: {
      type: [SanityContent.CAMPAIGN],
    },
    id: CONTENT_NAMES[SanityContent.CAMPAIGN],
    limit,
  },
  {
    contentFilter: {
      type: [SanityContent.FAQ],
    },
    id: CONTENT_NAMES[SanityContent.FAQ],
    limit,
  },
  {
    contentFilter: {
      type: [SanityContent.GUIDE],
    },
    id: CONTENT_NAMES[SanityContent.GUIDE],
    limit,
  },
  {
    contentFilter: {
      type: [SanityContent.STORE],
    },
    id: CONTENT_NAMES[SanityContent.STORE],
    limit,
  },
];

export const getAutoSuggestions = ({
  apiClient: {
    storefront: { autocompletePOST },
  },
  query,
}: {
  apiClient: ElevateApi<unknown>;
  query: AutocompleteQuery;
}) => {
  return autocompletePOST(query, {
    contentLists: prepareContentLists(DEFAULT_LIMIT),
  });
};

import React from "react";
import { BackButton as StyledBackButton } from "./MegaMenu.styled";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { ArrowLeft } from "@xxl/icons";

const BackButtonListItem: React.FunctionComponent<{
  callback: (value: undefined) => void;
}> = ({ callback }) => {
  const { t } = useTranslations();

  return (
    <li>
      <StyledBackButton onClick={() => callback(undefined)}>
        {<ArrowLeft fontSize={24} />}
        {t("mega.menu.back")}
      </StyledBackButton>
    </li>
  );
};

export { BackButtonListItem };

import { default as axios } from "axios";
import type { AxiosResponse } from "axios";
import type { ApiResponseWrapper } from "../ServiceProductsContext";

export const SERVICES_ADD_ON_SALES_PERSONALIZED_KEY = "services-add-on-sales";

export const getServices =
  ({ basePath, siteUid }: { basePath: string; siteUid: string }) =>
  async (
    product: string,
    category: string,
    price?: string
  ): Promise<AxiosResponse<ApiResponseWrapper>> => {
    const queryResponse = await axios({
      method: "post",
      url: `${basePath}/sites/${siteUid}/recommendations/personalize`,
      data: [
        {
          numResults: 15,
          strategy: "services",
          styleId: product,
          filters: {
            categories: [category],
          },
          key: SERVICES_ADD_ON_SALES_PERSONALIZED_KEY,
          price,
        },
      ],
    });

    return queryResponse;
  };

import styled from "@emotion/styled/macro";

export const InputAnimation = styled.div`
  position: relative;
  font-size: 0;

  &::before,
  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    border-bottom: 3px solid var(--color-xxl-amber);
    transition: width 0.2s linear;
    content: "";
  }

  &:hover {
    &::before {
      left: 0;
      width: 100%;
    }
  }
`;

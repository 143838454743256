export const DEFAULT_VARIATION_NAME = "Variant 1";
export const DEFAULT_VARIATION_2_NAME = "Variant 2";

export type TTestName = (typeof AB_TEST_NAMES)[keyof typeof AB_TEST_NAMES];
export const AB_TEST_NAMES = {
  ProductDesctiptionTest: "product-description-test",
  MoveUpsale: "move-upsale",
} as const;

export type TSanityTestName = keyof typeof SANITY_TESTS_MAP;
export const SANITY_TESTS_MAP = {
  HOMEPAGE_SANITY_TEST: {
    PROJECT_NAME: "homepage",
  },
  CAMPAIGN_PAGE_SANITY_TEST: {
    PROJECT_NAME: "campaign",
  },
} as const;

export const SERVICE_CODE = {
  IMBOX: "1122148_1_Style",
  BIKE: "1220069_1_Style",
} as const;

import React, { useMemo } from "react";
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled/macro";
import {
  mobileAndTabletMediaQuery,
  tabletMediaQuery,
} from "../../utils/xxl-screen";
import { useMediaQuery } from "@mui/material";
import { useSharedData } from "../../contexts/SharedData";
import { useSessionSource } from "../../contexts/Session";
import {
  DESKTOP_HEIGHT_WITH_HEADER_LINKS,
  MOBILE_HEIGHT_WITH_HEADER_LINKS,
} from "./constants";
import { useClient } from "../../hooks/useClient/useClient";
import { useStateValue } from "cotton-box-react";

// Styled component for snow container
const SnowContainer = styled.div<{ height: number }>`
  position: absolute;
  width: 100%;
  height: ${({ height }) => height}px;
  overflow: hidden;
  background-color: #161616; /* Replace with your color variable */
  z-index: -1;
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to top,
      rgba(22, 22, 22, 0) 70%,
      rgb(22, 22, 22) 100%
    );
    content: "";
  }
`;

const createFallAnimation = (translateX: number) => keyframes`
  0% {
    transform: translateY(0) translateX(0);
  }
  100% {
    transform: translateY(130px) translateX(${translateX}px);
  }
`;

const randomNum = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const Flake = styled.div<{ isMobileOrTablet: boolean }>(
  ({ isMobileOrTablet }) => {
    const size = randomNum(1, 8);
    const translateX = randomNum(1, 100) - 50;

    const blurLevel = size > 4 ? 0.5 : 1;
    return css`
      filter: blur(${blurLevel}px);
      left: ${randomNum(1, 100)}%;
      width: ${size}px;
      height: ${size}px;
      opacity: ${randomNum(3, 8) / 10};
      animation: ${createFallAnimation(translateX)} linear infinite;
      animation-duration: ${(isMobileOrTablet ? 4000 : 3000) +
      randomNum(1, 2000)}ms;
      animation-delay: ${randomNum(1, 5000)}ms;
      --x: ${randomNum(-50, 100) / 100};
    `;
  }
);

const Snow = () => {
  const isMobileOrTablet = useMediaQuery(mobileAndTabletMediaQuery);
  const isTabletOrDesktop = useMediaQuery(tabletMediaQuery);
  const flakesCount = isMobileOrTablet ? 70 : 100;
  const { isTeamsales } = useSharedData().data;
  const isLoggedIn = useStateValue(useSessionSource);

  const flakes = useMemo(
    () => Array.from(Array(flakesCount).keys()),
    [flakesCount]
  );

  const heights = {
    teamsales: {
      mobile: 60,
      desktop: 74,
    },
    xxl: {
      mobile: MOBILE_HEIGHT_WITH_HEADER_LINKS,
      desktop: DESKTOP_HEIGHT_WITH_HEADER_LINKS,
    },
  };

  // for logged in teamsales user header is has the same height as in xxl
  const isUnloggedTeamsales = !isLoggedIn && isTeamsales;

  const height =
    heights[isUnloggedTeamsales ? "teamsales" : "xxl"][
      isTabletOrDesktop ? "desktop" : "mobile"
    ];

  const isClient = useClient();

  return (
    <SnowContainer id="snow-container" height={height}>
      {isClient &&
        flakes.map((i) => (
          <Flake
            className="flake"
            isMobileOrTablet={isMobileOrTablet}
            key={i}
          />
        ))}
    </SnowContainer>
  );
};

export default React.memo(Snow);

import React, { useEffect, useState } from "react";
import { useSharedData } from "../../contexts/SharedData";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { Text } from "../Text";
import { GenerateStoreUrlPath } from "./StoreUrlPathGenerator";
import type { Store } from "@xxl/content-api";

const useStoreFinderLink = (store: Store) => {
  const { requestMapping } = useSharedData().data;
  const [storeFinderHref, setStoreFinderHref] = useState(
    requestMapping.storeFinder
  );
  useEffect(() => {
    const storeDetailUrlPart = GenerateStoreUrlPath(store);
    const storeFinderLink = `${requestMapping.storeFinder}/${requestMapping.store}${storeDetailUrlPart}`;
    const baseUrl = new URL(window.location.origin);
    setStoreFinderHref(new URL(storeFinderLink, baseUrl).href);
  }, [requestMapping.store, requestMapping.storeFinder, store]);
  return storeFinderHref;
};

type Props = {
  store: Store;
};
export const StoreFinderLink = ({ store }: Props) => {
  const { t } = useTranslations();
  const link = useStoreFinderLink(store);
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <Text typography="baseMedium" underline={true}>
        {t("product.details.storestock.open.see.all")}
      </Text>
    </a>
  );
};

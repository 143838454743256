import type { FunctionComponent, MouseEvent, PropsWithChildren } from "react";
import React from "react";
import { Link as StyledLink } from "./MegaMenu.styled";

const LinkListItem: FunctionComponent<
  PropsWithChildren<{
    href: string;
    onClick?: (event: MouseEvent) => void;
    linkType?: "default" | "secondary";
  }>
> = ({ children, href, onClick, linkType = "default" }) => (
  <li>
    <StyledLink href={href} onClick={onClick} linkType={linkType}>
      {children}
    </StyledLink>
  </li>
);

export { LinkListItem };

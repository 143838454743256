import styled from "@emotion/styled/macro";
import { interactionFeedback, typographyToCss } from "../../../styles/helpers";
import colors from "./MegaMenu.colors";
import text from "./MegaMenu.typography";
import spaces from "../../../styles/theme/spaces";

type HeaderProps = {
  nrOfColumns: number;
};

export const HeaderLink = styled.a<HeaderProps>`
  ${typographyToCss(text.sectionLink)};
  display: flex;
  align-items: center;
  overflow: hidden;
  color: ${colors.textColor};
  grid-column: ${({ nrOfColumns }) => `span ${nrOfColumns}`};
  margin-bottom: 10px;

  &:hover,
  &:focus {
    ${interactionFeedback(`
      text-decoration: underline;
    `)}
  }
`;

export const Header = styled.h2<HeaderProps>`
  ${typographyToCss({
    fontSize: 20,
  })}
  display: flex;
  align-items: center;
  margin: 0 0 3px 0;
  grid-column: ${({ nrOfColumns }) => `span ${nrOfColumns}`};

  svg {
    margin-right: ${spaces.mini};
    min-width: 30px;
    font-size: 30px;
  }
`;

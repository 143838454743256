import { isNotNullOrUndefined } from "@xxl/common-utils";
import groupBy from "lodash/groupBy";
import React, { type PropsWithChildren, useEffect, useState } from "react";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import type { ServiceObjectProps, ServicesEventData } from "../../global";
import { windowAccess } from "../../utils/Window";
import * as XxlEvent from "../../utils/xxl-event";
import { ServiceProductsWrapper } from "./ServiceProductsWrapper";
import {
  Button,
  CartPageButtonsWrapper,
  CloseButton,
  ProductPageButtonsWrapper,
} from "./ServiceProductsWrapper.styled";
import { isProductPage as isProductPageHelper } from "../../utils/xxl-page-type";
import type { CartItemIdInput } from "../../generated/graphql-code-generator";
import noop from "lodash/noop";
import { useTracking } from "../../contexts/Tracking";
import type { ServicesResponse } from "./ServiceProductsContext";
import {
  SERVICES,
  useServicesContext,
  ServicesContextProvider,
} from "./ServiceProductsContext";

type Props = {
  cartEntryItemId: CartItemIdInput;
  services: ServicesResponse;
  category?: string;
  onClose?: () => void;
  productSize?: string;
  serviceParentKey?: string;
  isSearchResult?: boolean;
  isOpen: boolean;
};

const ServiceProductsWithContext = ({
  cartEntryItemId,
  category,
  productSize,
  serviceParentKey,
  services,
  onClose = noop,
  isSearchResult = false,
  isOpen = false,
}: Props) => {
  const [actionInProgress, setActionInProgress] = useState(false);
  const { state, dispatch } = useServicesContext();
  const { t } = useTranslations();
  const tracking = useTracking();
  const { xxlServiceProducts } = windowAccess();
  const isProductPage = isProductPageHelper() || isSearchResult;

  useEffect(() => {
    dispatch({
      type: SERVICES.SET_PRODUCT_CART_ENTRY_ID,
      payload: cartEntryItemId,
    });
  }, [cartEntryItemId, dispatch]);

  useEffect(() => {
    dispatch({
      type: SERVICES.REQUEST_SUCCESS,
      payload: services,
    });
  }, [dispatch, services]);

  const handleDialogClose = () => {
    if (actionInProgress) {
      return;
    }
    dispatch({
      type: SERVICES.CLOSE,
    });
    onClose();
  };

  const handleContinueShoppingClick = () => {
    tracking.sendAddOnContinueShoppingEvent();
    handleDialogClose();
  };

  useEffect(() => {
    const eventHandler = (event: CustomEvent<ServicesEventData>) => {
      const { productSize: size, parentKey } = event.detail;

      if (productSize === size && serviceParentKey === parentKey) {
        const trackOpeningServices = (isPdp: boolean): void =>
          isPdp
            ? tracking.sendOpenServicesPDPEvent()
            : tracking.sendAddOnEditCartEvent();

        dispatch({
          type: SERVICES.OPEN,
        });

        trackOpeningServices(isProductPage);

        if (
          !isProductPage &&
          isNotNullOrUndefined(xxlServiceProducts) &&
          isNotNullOrUndefined(xxlServiceProducts.cartProducts) &&
          xxlServiceProducts.cartServices.length > 0
        ) {
          const cartServices = groupBy<ServiceObjectProps>(
            xxlServiceProducts.cartServices,
            (service) => service.parentSize
          )[productSize ?? ""];
          const productQuantity =
            xxlServiceProducts.cartProducts.find(
              (serviceProduct) => serviceProduct.productId === productSize
            )?.quantity ?? 0;

          dispatch({
            type: SERVICES.CART_INIT,
            payload: {
              cartServices,
              productQuantity: productQuantity,
              parentEntryNumber: String(cartServices[0].parentEntryNumber),
            },
          });
        }
      }
    };

    XxlEvent.addEventListener(
      XxlEvent.type.XXL_SERVICES_PRODUCT_OPEN,
      eventHandler as EventListener
    );

    return () => {
      XxlEvent.removeEventListener(
        XxlEvent.type.XXL_SERVICES_PRODUCT_OPEN,
        eventHandler as EventListener
      );
    };
  }, [
    isProductPage,
    productSize,
    serviceParentKey,
    dispatch,
    state.services,
    xxlServiceProducts,
    tracking,
  ]);

  return (
    <>
      <ServiceProductsWrapper
        category={category}
        onDialogBoxClose={handleDialogClose}
        dialogBoxTestId={"service-product-dialog"}
        dialogBoxHeaderText={t(
          `services.popup.header.${isProductPage ? "product" : "cart"}.page`
        )}
        isModalOpen={isOpen}
        actionInProgress={actionInProgress}
        setActionInProgress={setActionInProgress}
        renderFooterButtons={() => {
          return isProductPage ? (
            <ProductPageButtonsWrapper>
              <Button
                onClick={handleContinueShoppingClick}
                revertedColor={true}
                style={{ gridColumnStart: "right" }}
              >
                {t("product.details.clickcollect.continue.shopping")}
              </Button>
            </ProductPageButtonsWrapper>
          ) : (
            <CartPageButtonsWrapper>
              <CloseButton
                onClick={handleDialogClose}
                disabled={actionInProgress}
                revertedColor={true}
              >
                {t("sorts.close.button.text")}
              </CloseButton>
            </CartPageButtonsWrapper>
          );
        }}
      />
    </>
  );
};

export const ServiceProducts = (props: PropsWithChildren<Props>) => (
  <ServicesContextProvider>
    <ServiceProductsWithContext {...props}>
      {props.children}
    </ServiceProductsWithContext>
  </ServicesContextProvider>
);

import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { ButtonStyledAsLink } from "../../../styled";
import { typographyToCss } from "../../../styles/helpers";
import { xxlTheme } from "../../../styles/xxl-theme";

const { spaces, typography } = xxlTheme;

type DescriptionProps = {
  isExpanded: boolean;
  numberOfLinesToShow: number;
  minResolutionToStopClamping?: number;
};
export const Description = styled.div<DescriptionProps>(
  ({ isExpanded, numberOfLinesToShow, minResolutionToStopClamping }) => css`
    ${typographyToCss(typography.smallRegular)}
    line-height: normal;
    margin-bottom: ${spaces.mini};

    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${isExpanded ? "unset" : numberOfLinesToShow};
    @media (min-width: ${minResolutionToStopClamping}px) {
      -webkit-line-clamp: unset;
    }
  `
);

export const ReadDescriptionButton = styled(ButtonStyledAsLink)`
  ${typographyToCss(typography.smallBold)};
  text-decoration: none;
  display: flex;
  gap: ${spaces.micro};
`;

import styled from "@emotion/styled/macro";
import { css, keyframes } from "@emotion/react";
import {
  interactionFeedback,
  MQ,
  typographyToCss,
} from "../../../styles/helpers";
import { xxlTheme } from "../../../styles/xxl-theme";
import typography from "./CartPage.typography";
import { focusVisibleStyles } from "./ProductItem.styled";
import { CloseIconSlim } from "@xxl/icons";
import { Button } from "../../Common";

const { miniCartHeader, miniCartAmount, miniCartBigAmount } = typography;

const { spaces, colors } = xxlTheme;

export const MiniCartOverlay = styled.div`
  width: 100vw;
  height: var(--xxl-vh);
  background-color: rgba(22, 22, 22, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1300;

  & * {
    box-sizing: border-box;
  }
`;

type MiniCartWrapperProps = {
  isOpen: boolean;
  isClosed: boolean;
};

const openingAnimation = keyframes`
  0% { transform: translateX(100%) }
  100% { transform: translateX(0) }
`;

const closingAnimation = keyframes`
  0% { transform: translateX(0) }
  100% { transform: translateX(100%) }
`;

export const MiniCartWrapper = styled.div<MiniCartWrapperProps>(
  ({ isOpen, isClosed }) => css`
    width: 90%;
    min-width: 350px;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${colors.xxlWhite};
    color: ${colors.xxlWebBlack};
    position: fixed;
    right: 0;
    justify-content: space-between;

    ${MQ("tablet")} {
      width: 390px;
    }

    ${isOpen &&
    css`
      animation: ${openingAnimation} 0.3s forwards;
    `}

    ${isClosed &&
    css`
      animation: ${closingAnimation} 0.3s forwards;
    `}
  `
);

export const MiniCartHeader = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.xxlWebBlack};
  color: ${colors.xxlWhite};
  padding: 0 ${spaces.smallRegular};
`;

export const CloseButton = styled(Button)`
  width: 40px;
  height: 40px;
  background-color: transparent;
  color: ${colors.xxlWhite};
  border: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  font-size: 18px;
  ${interactionFeedback(`cursor: pointer;`)}

  & svg {
    max-width: 18px;
    max-height: 18px;
  }
`;

export const HeaderText = styled.h2`
  color: ${colors.xxlWhite};
  ${typographyToCss(miniCartHeader)};
`;

export const miniCartButtonCommonStyles = css`
  height: calc(100% - 8px);
  margin-top: ${spaces.mini};
  min-height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  color: ${colors.xxlLightGrey};
  ${interactionFeedback(`color: ${colors.xxlGreen};`)}

  & svg {
    width: 24px;
    height: 24px;
  }

  ${MQ("laptop")} {
    margin-top: 0;
    height: 100%;
  }
`;

export const MiniCartButton = styled(Button)`
  ${miniCartButtonCommonStyles};
  background-color: transparent;
  border: none;
  ${focusVisibleStyles};

  &:hover {
    cursor: pointer;
  }
`;

type MiniCartAmountProps = {
  isBigAmount?: boolean;
};

export const MiniCartAmount = styled.span<MiniCartAmountProps>(
  ({ isBigAmount = false }) => css`
    position: absolute;
    top: calc(50% - 24px);
    right: -8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    color: ${colors.xxlWebBlack};
    ${isBigAmount
      ? typographyToCss(miniCartBigAmount)
      : typographyToCss(miniCartAmount)};
    background-color: ${colors.xxlGreen};
    border-radius: 50%;
    box-sizing: border-box;

    ${MQ("tablet")} {
      right: -12px;
    }
  `
);

export const GoToCheckout = styled.a`
  margin-top: ${spaces.smallRegular};
`;

export const Footer = styled.div`
  align-self: flex-end;
  border-top: 1px solid ${colors.xxlLightGrey};
  padding: ${spaces.smallRegular};
  width: 100%;
  box-shadow: 0 1px 5px -2px ${colors.xxlGrey};
`;

export const MiniCartTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.xxlWhite};
`;

export const EmptyCartTopWrapper = styled(MiniCartTopWrapper)`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;

  & svg {
    margin-top: ${spaces.big};
  }
`;

export const EmptyCartWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
`;

const circleLoaderAnimation = keyframes`
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
`;

export const CircleLoader = styled.div`
  transform-origin: center center;
  border: 2px solid transparent;
  border-right-color: ${colors.xxlBlack};
  width: 15px;
  height: 15px;
  border-radius: 50%;
  animation: ${circleLoaderAnimation} 1s infinite ease-out;
`;

export const CloseIcon = styled(CloseIconSlim)`
  width: 18px;
  height: 18px;
`;

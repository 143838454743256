import React from "react";
import { DialogBoxHeaderCloseButton } from "../../DialogBox/DialogBox.styled";

const DialogCloseButton = ({
  handleDialogBoxClosing,
}: {
  handleDialogBoxClosing: () => void;
}) => (
  <DialogBoxHeaderCloseButton
    aria-label="close"
    onClick={handleDialogBoxClosing}
    src="/static/svg/close-icon-slim.svg"
    alt="close icon"
    data-inline-svg={true}
    data-testid="dialog-close-btn"
  />
);

export default DialogCloseButton;

import type { MutableRefObject } from "react";
import type { KeenSliderPlugin, KeenSliderInstance } from "keen-slider/react";

/**
 * Plugin from KeenSlider to sync the thumbnail slider with the main slider.
 *
 * @see {@link https://codesandbox.io/s/github/rcbyr/keen-slider-sandboxes/tree/v6/navigation-controls/thumbnails/react?file=/src/App.js}
 */
export function ThumbnailPlugin(
  mainRef: MutableRefObject<KeenSliderInstance | null>
): KeenSliderPlugin {
  return (slider) => {
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove("active");
      });
    }
    function addActive(idx: number) {
      slider.slides[idx].classList.add("active");
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener("click", () => {
          if (mainRef.current !== null) mainRef.current.moveToIdx(idx);
        });
      });
    }

    slider.on("created", () => {
      if (mainRef.current === null) return;
      addActive(slider.track.details.rel);
      addClickEvents();
      mainRef.current.on("animationStarted", (main) => {
        removeActive();
        const next = main.animator.targetIdx ?? 0;
        addActive(main.track.absToRel(next));
        slider.moveToIdx(Math.min(slider.track.details.maxIdx, next));
      });
    });
  };
}

export const createThumbnailSrc = (thumbnailSrc: string) =>
  thumbnailSrc.replace("filespin/video/", "filespin/");

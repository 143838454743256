import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { Translate } from "../contexts/Translations/TranslationsContext";
import type { TranslationKey } from "../translations";
import { windowAccess } from "../utils/Window";

interface TranslationOptions {
  /**
   * The translation key to resolve
   */
  key: TranslationKey;
  /**
   * Fallback value if no string found. Defaults to returning the passed key.
   */
  fallback?: string;
  /**
   * Values to replace {0} and {1} etc. with. Defaults to empty array.
   */
  messageArguments?: string[];
}
export type NextJsTranslations = Record<string, string>;

export type TranslateNextJs = (
  keyOrOptions: TranslationKey | TranslationOptions,
  nextJsTranslations: NextJsTranslations
) => string;

export const translate: Translate = (
  keyOrOptions: TranslationKey | TranslationOptions,
  nextJsTranslations?: NextJsTranslations
): string => {
  if (isNotNullOrUndefined(nextJsTranslations)) {
    return translateNextJs(keyOrOptions, nextJsTranslations);
  } else {
    return translateLegacy(keyOrOptions);
  }
};

const translateNextJsMessage = ({
  nextJsTranslations,
  key,
  fallback,
  messageArguments,
}: Required<TranslationOptions> & {
  nextJsTranslations: NextJsTranslations;
}): string => {
  const translation = nextJsTranslations[key] ?? fallback;

  if (messageArguments.length > 0) {
    return translation.replace(
      /\{(\d+)\}/gu,
      (_, pos: string) => messageArguments[parseInt(pos, 10)]
    );
  } else {
    return translation;
  }
};

const translateMessage = ({
  key,
  fallback,
  messageArguments,
}: Required<TranslationOptions>): string => {
  const { translations } = windowAccess()._sharedData;
  const translation = translations[key] ?? fallback;
  if (messageArguments.length > 0) {
    return translation.replace(
      /\{(\d+)\}/gu,
      (_, pos: string) => messageArguments[parseInt(pos, 10)]
    );
  } else {
    return translation;
  }
};

/**
 * Resolves a key from the `window._sharedData.translations` object
 * See `script.tag` for how to pass `messages.properties` onto this JS object.
 * @param {TranslationKey | TranslationOptions} keyOrOptions Translation key or options to resolve
 * @returns {string} Translated string
 * @deprecated
 */
const translateLegacy = (
  keyOrOptions: TranslationKey | TranslationOptions
): string =>
  typeof keyOrOptions === "string"
    ? translateMessage({
        key: keyOrOptions,
        fallback: keyOrOptions,
        messageArguments: [],
      })
    : translateMessage({
        fallback: keyOrOptions.key,
        messageArguments: [],
        ...keyOrOptions,
      });

type InferStem<
  Suffix extends string,
  T = TranslationKey,
> = T extends `${infer K}.${Suffix}` ? K : never;

type TranslateWithPluralization = (
  keyStem: InferStem<"one"> & InferStem<"other">,
  count: number
) => string;

/**
 * Resolves a pluralized key from the `window._sharedData.translations` object
 * Translation needs to have two entries, for example:
 * `<translation-key>.one = Store` and
 * `<translation-key>.other = Stores`
 * Given `count` parameter we choose `.one` or `.other` translation
 * @param {TranslationKey} key Translation key to resolve (without `.one` or `.other` suffix)
 * @param {number} count Decides whether to choose translation key with `.one` or `.other` suffix
 * @returns {string} Translated string
 * @deprecated
 */
const translateWithPluralization: TranslateWithPluralization = (
  keyStem,
  count
): string => translateLegacy(`${keyStem}.${count === 1 ? "one" : "other"}`);

const translateNextJs: TranslateNextJs = (
  keyOrOptions: TranslationKey | TranslationOptions,
  nextJsTranslations: NextJsTranslations
): string => {
  const translationArgs =
    typeof keyOrOptions === "string"
      ? {
          key: keyOrOptions,
          fallback: keyOrOptions,
        }
      : {
          fallback: keyOrOptions.key,
          ...keyOrOptions,
        };
  return translateNextJsMessage({
    nextJsTranslations,
    messageArguments: [],
    ...translationArgs,
  });
};

export {
  translateLegacy,
  translateWithPluralization,
  translateNextJs as TranslateNextJsText,
};
export type { TranslationOptions, TranslateWithPluralization };

import { Stack } from "@mui/material";
import styled from "@emotion/styled/macro";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { resolutionSizes } from "react-app/src/config";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { Drawer } from "react-app/src/components/Drawer/Drawer";

const DrawerContainer = styled.div`
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: ${resolutionSizes.tablet}px) {
    width: 390px;
  }
`;

const StyledDrawerPrice = styled.div`
  font-size: 1.3rem;
  font-weight: 700;
`;
const CurrentDrawerPrice = styled(StyledDrawerPrice)`
  color: ${xxlTheme.colors.xxlRed};
`;
const OriginalDrawerPrice = styled(StyledDrawerPrice)`
  color: ${xxlTheme.colors.xxlWebBlack};
  text-decoration: line-through;
`;
const LowestDrawerPrice = styled(StyledDrawerPrice)`
  color: ${xxlTheme.colors.xxlGrey};
  text-decoration: line-through;
`;
type Props = {
  drawerOpen: boolean;
  handleOnClose: () => void;
  salesPriceFormatted: string;
  previousPriceFormatted?: string;
  latestPriceFormatted?: string;
};
export const PriceDrawer = ({
  drawerOpen,
  handleOnClose: toggleDrawerOpen,
  salesPriceFormatted,
  previousPriceFormatted,
  latestPriceFormatted,
}: Props) => {
  const { t } = useTranslations();

  const body = (
    <DrawerContainer>
      <Stack spacing={2}>
        <div>{t("product.price.history.description")}</div>
        <Stack spacing={1}>
          <div>{t("product.price.history.sales.price.label")}</div>
          <CurrentDrawerPrice>{salesPriceFormatted}</CurrentDrawerPrice>
        </Stack>
        {previousPriceFormatted !== undefined && (
          <Stack spacing={1}>
            <div>{t("product.price.history.original.price.label")}</div>
            <OriginalDrawerPrice>{previousPriceFormatted}</OriginalDrawerPrice>
          </Stack>
        )}
        {latestPriceFormatted !== undefined && (
          <Stack spacing={1}>
            <span>{t("product.price.history.latest.price.label")}</span>
            <LowestDrawerPrice>{latestPriceFormatted}</LowestDrawerPrice>
          </Stack>
        )}
      </Stack>
    </DrawerContainer>
  );

  return (
    <Drawer
      open={drawerOpen}
      onClose={toggleDrawerOpen}
      isLoading={false}
      heading={t("product.price.history.title")}
    >
      {body}
    </Drawer>
  );
};

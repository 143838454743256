import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { StyledLastPlaceSlide } from "./ProductImage.styles";
import type { PropsWithChildren } from "react";
import { KEEN_SLIDER_CLASSES } from "react-app/src/components/Common/Slider/Slider.helper";

export const LastPlaceComponentWrapped = ({
  children,
  isLastItem,
}: PropsWithChildren<{ isLastItem: boolean }>) => (
  <StyledLastPlaceSlide
    key={"additional-slide"}
    className={KEEN_SLIDER_CLASSES.Slide}
    style={{
      zIndex: isLastItem ? xxlTheme.zIndex["cta-slider"] : "auto",
    }}
  >
    {children}
  </StyledLastPlaceSlide>
);

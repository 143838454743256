import styled from "@emotion/styled/macro";
import { Icon } from "react-app/src/components/Icon";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { Title } from "./HeaderTitle";
import { DisclaimerInfoButton } from "../DisclaimerInformation.styled";

const { spaces } = xxlTheme;

const Wrapper = styled.header`
  padding: ${spaces.large} 0;
  display: flex;
  justify-content: space-between;
`;

type DisclaimerInfoHeaderProps = {
  title: string;
  onClose: () => void;
};

export const DisclaimerInfoHeader = ({
  title,
  onClose,
}: DisclaimerInfoHeaderProps) => {
  return (
    <Wrapper>
      <Title title={title} />
      <DisclaimerInfoButton onClick={onClose}>
        <Icon name="CloseIconSlim" color={"black"} />
      </DisclaimerInfoButton>
    </Wrapper>
  );
};

import styled from "@emotion/styled/macro";
import { xxlTheme } from "../../../styles/xxl-theme";
import { typographyToCss } from "../../../styles/helpers";
import { css, keyframes } from "@emotion/react";
import typography from "./PersonalOffers.typography";
import { OfferBody as OfferBodyContent } from "../../Rewards/Offers.styled";

const { colors, spaces } = xxlTheme;
const { heading, error } = typography;

const CLICK_ANIMATION_TIMEOUT = 500;

type WrapperProps = {
  isLoading?: boolean;
};

export const Wrapper = styled.div<WrapperProps>(
  ({ isLoading = false }) => css`
    background-color: ${colors.xxlWhite};
    padding: 0;
    margin-top: ${spaces.smallRegular};
    position: relative;

    ${isLoading &&
    css`
      pointer-events: none;
      filter: blur(5px);
    `}
  `
);

const clickAnimation = keyframes`
  0% { transform: translateX(100%) }
  100% { transform: translateX(20%) }
`;

export const HeadingButton = styled.button`
  ${typographyToCss(heading)};
  padding: ${spaces.smallRegular};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
  background-color: transparent;
  border: none;
  position: relative;
  z-index: 1;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  &:focus-visible {
    outline-width: 2px;
    outline-style: solid;
    outline-color: ${colors.xxlGreen};
    outline-offset: 2px;
  }
`;

type AnimationProps = {
  isAnimating: boolean;
};

export const HeadingButtonAnimationWrapper = styled.span<AnimationProps>(
  ({ isAnimating }) => css`
    position: absolute;
    display: block;
    z-index: -1;
    background: linear-gradient(to left, transparent, ${colors.xxlLightGrey});
    width: 100%;
    height: 100%;
    opacity: 0.3;
    inset: 0;
    transform: translateX(100%);

    ${isAnimating &&
    css`
      animation: ${clickAnimation} ${CLICK_ANIMATION_TIMEOUT}ms forwards;
    `}
  `
);

export const ErrorWrapper = styled.p`
  display: block;
  width: 100%;
  margin: 0 0 ${spaces.smallRegular};
  padding: ${spaces.smallRegular};
  background-color: ${colors.xxlXmasRed};
  color: ${colors.xxlWhite};
  box-sizing: border-box;
  ${typographyToCss(error)}
`;

export const OffersList = styled.ul`
  list-style: none;
  padding: 0 ${spaces.smallRegular} ${spaces.smallRegular};
  margin: 0;
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  column-gap: ${spaces.mini};
  row-gap: ${spaces.smallRegular};
`;

const commonOfferItemStyles = css`
  background-color: colors.xxlWhite;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  gap: ${spaces.mini};
  overflow: hidden;

  & button {
    width: 100%;

    & svg {
      margin-right: ${spaces.micro};
      width: 24px;
      height: 24px;
    }
  }
`;

export const OfferItem = styled.li`
  ${commonOfferItemStyles};
  width: calc((100% - 2 * 8px) / 3);
`;

export const OfferItemWrapper = styled.div`
  ${commonOfferItemStyles};
  width: 100%;
`;

export const CarouselWrapper = styled.div`
  padding: 0 ${spaces.smallRegular} ${spaces.smallRegular};
`;

export const OfferBody = styled(OfferBodyContent)`
  position: relative;
  overflow: hidden;

  & > svg {
    position: absolute;
    bottom: -4px;
    left: 0;
    z-index: 2;
    width: 100%;
    color: ${colors.xxlWhite};
  }
`;

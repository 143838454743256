import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { MQ, typographyToCss } from "../../../styles/helpers";
import { colorsAsCssVariable as colors } from "../../../styles/theme/colors";
import spaces from "../../../styles/theme/spaces";
import { ribbonLeft, ribbonRight } from "../../../styled/mixins";
import type { RibbonDirection } from "./NewRibbon";
import { removeWhitespace } from "../../../utils/StringTransformations";
import { isNotEmpty } from "@xxl/common-utils";

type RibbonProps = {
  foregroundColor?: string;
  backgroundColor?: string;
  direction?: RibbonDirection;
};

const typography = {
  ribbonText: {
    fontSize: 12,
    lineHeight: 1.2,
    fontFamily: "var(--font-family-medium)",
  },
};

const ribbonHeight = "30px";

export const RibbonWrapper = styled.span<RibbonProps>(
  ({ backgroundColor = "", direction }) => {
    return `
    display: flex;
    height: ${ribbonHeight};
    margin-right: auto;
    background-color: transparent;

    ${direction === "right" ? "&::after" : "&::before"} {
      width: 0;
      height: 0;
      border-left: 1px solid ${isNotEmpty(removeWhitespace(backgroundColor)) ? backgroundColor : colors.xxlGreen};
      border-top: ${isNotEmpty(removeWhitespace(backgroundColor)) ? backgroundColor : colors.xxlGreen};
      border-top-style: solid;
      ${
        direction === "right"
          ? ribbonRight(ribbonHeight)
          : ribbonLeft(ribbonHeight)
      };
      content: "";
    }

    ${MQ("tabletHorizontal")} {
      ${direction === "right" ? "&::after" : "&::before"} {
        ${
          direction === "right"
            ? ribbonRight(ribbonHeight)
            : ribbonLeft(ribbonHeight)
        };
      }
    }
  `;
  }
);

export const RibbonContent = styled.div<RibbonProps>(
  ({ foregroundColor = "", backgroundColor = "", direction }) => css`
    display: flex;
    align-items: center;
    padding: ${direction === "right"
      ? `0 0 0 ${spaces.miniMicro}`
      : `0 ${spaces.miniMicro} 0 0`};
    color: ${isNotEmpty(removeWhitespace(foregroundColor))
      ? foregroundColor
      : colors.xxlBlack};
    white-space: nowrap;
    text-align: center;
    background-color: ${isNotEmpty(removeWhitespace(backgroundColor))
      ? backgroundColor
      : colors.xxlGreen};
    border-color: ${isNotEmpty(removeWhitespace(backgroundColor))
      ? backgroundColor
      : colors.xxlGreen};
  `
);

export const Label = styled.span`
  ${typographyToCss(typography.ribbonText)}
`;

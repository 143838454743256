import type { Store as StoreDetails } from "@xxl/content-api";
import {
  ContentApi,
  Configuration as ContentApiConfiguration,
} from "@xxl/content-api";
import { useEffect, useState } from "react";
import { useSharedData } from "../../contexts/SharedData";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import type { TranslationKey } from "../../translations";
import {
  getPreferredStoresCookie,
  setPreferredStoresCookie,
} from "../../utils/Cookie";
import { getCartDetailsCookie } from "../../utils/Cookie/XXLCookie";
import { legacySiteUidToSiteUid } from "../../utils/xxl-shared-data";
import { AVAILABILITY } from "../Search/SearchFetchProductsHelper.types";
import {
  getSortedStoresList,
  StoreStockFetchStoreDetails,
} from "../StoreStock/StoreStock.helper";
import type { StockLevel, Store } from "./api/api";
import { log } from "@xxl/logging-utils";

export const convertCollectableStockToStock = (stores: Store[]) =>
  stores.map((store) => ({
    ...store,
    stockLevel: store.collectableStockLevel,
  }));

export const getCurrentStoreIdFromCookie = (): string | undefined =>
  getCartDetailsCookie()?.collectStore;

export const useTranslationCCStockStatus = (stockLevel: StockLevel) => {
  const { t } = useTranslations();
  return t(
    {
      INSTOCK: "product.details.clickcollect.instock.collectable",
      LOWSTOCK: "product.details.clickcollect.fewstock.collectable",
      OUTOFSTOCK: "product.details.clickcollect.nostock.collectable",
    }[stockLevel] as TranslationKey
  );
};

export const useTranslationStoreStockStatus = (stockLevel: StockLevel) => {
  const { t } = useTranslations();
  return t(
    {
      INSTOCK: "product.details.clickcollect.instock.store",
      LOWSTOCK: "product.details.clickcollect.fewstock.store",
      OUTOFSTOCK: "product.details.clickcollect.nostock.store",
    }[stockLevel] as TranslationKey
  );
};

export const useStoreDetails = (store?: Store) => {
  const {
    data: { configuration, siteUid },
  } = useSharedData();
  const [storeDetails, setStoreDetails] = useState<StoreDetails>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getStore = async () => {
      setIsLoading(true);
      if (store === undefined) {
        return;
      }
      const { id } = store;
      try {
        const activeStore =
          (await StoreStockFetchStoreDetails(
            legacySiteUidToSiteUid(siteUid),
            new ContentApi(
              new ContentApiConfiguration(configuration.contentApi)
            ),
            id
          )) ?? undefined;
        setStoreDetails(activeStore);
      } catch (error) {
        log.error(
          `[StoreStockDialog] Could not fetch store with id ${id}.`,
          error
        );
      } finally {
        setIsLoading(false);
      }
    };

    void getStore();
  }, [siteUid, configuration, store]);

  return [storeDetails, isLoading] as const;
};

export const useSortedStores = (stores: Store[]) => {
  const [sortedStores, setSortedStores] = useState<Store[]>([]);
  useEffect(() => {
    const sortStores = async () => {
      const ss = await getSortedStoresList(stores);
      setSortedStores(ss);
    };

    void sortStores();
  }, [stores]);

  return sortedStores;
};

export const usePreferredStores = (stores: Store[]) => {
  const [preferredStores, setPreferredStores] = useState<Store[]>([]);
  const setPreferredStoreCookieIds = (ids: string[]) => {
    const oldAvailability = getPreferredStoresCookie()?.availability ?? [];
    setPreferredStoresCookie({
      ids,
      availability: oldAvailability.includes(AVAILABILITY.STORE)
        ? oldAvailability
        : [...oldAvailability, AVAILABILITY.STORE],
    });
    setPreferredStores(stores.filter(({ id }) => ids.includes(id)));
  };

  useEffect(() => {
    const preferredStoresCookie = getPreferredStoresCookie();
    if (preferredStoresCookie === null || stores.length === 0) {
      return;
    }
    const { ids } = preferredStoresCookie;
    const preferredStoresFromCookie = stores.filter(({ id }) =>
      ids.includes(id)
    );
    setPreferredStores(preferredStoresFromCookie);
  }, [stores]);

  return [preferredStores, setPreferredStoreCookieIds] as const;
};

export const useKlarnaClasses = () => {
  useEffect(() => {
    document
      .querySelector(".js-product-klarna-click-and-collect")
      ?.classList.add("product__klarna-click-and-collect--active");
    return () => {
      document
        .querySelector(".js-product-klarna-click-and-collect")
        ?.classList.remove("product__klarna-click-and-collect--active");
    };
  }, []);
};

import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { FavoritesType } from "../UserDetails/UserAPI";
import { setStorageItem } from "../../utils/LocalStorage/local-storage";
import type { ProductData } from "@xxl/search-api";

export type FavoritesArrayType = string[] | null;
const LOCAL_STORAGE_KEY = "favorites";
const LOCAL_STORAGE_COUNT_KEY = "favoritesCount";

export function saveFavoritesToLocalStorage(fullFavoritesData: FavoritesType) {
  const favoritesArray: FavoritesArrayType =
    fullFavoritesData === null
      ? null
      : fullFavoritesData.map((item) => item.styleId);

  setStorageItem(LOCAL_STORAGE_KEY, JSON.stringify(favoritesArray));
}

export function getFavoritesFromLocalStorage(): FavoritesArrayType | null {
  if (typeof window !== "undefined") {
    const localStorageFavorites = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (typeof localStorageFavorites === "string") {
      return JSON.parse(localStorageFavorites) as FavoritesArrayType;
    }
  }
  return null;
}

export const setFavoritesCountInLocalStorage = (
  favoritesCount: number
): void => {
  setStorageItem(LOCAL_STORAGE_COUNT_KEY, String(favoritesCount));
};

export const getFavoritesCountFromLocalStorage = (): number | null => {
  if (typeof window !== "undefined") {
    const localStorageFavoritesCount = localStorage.getItem(
      LOCAL_STORAGE_COUNT_KEY
    );

    if (isNotNullOrUndefined(localStorageFavoritesCount)) {
      return Number(localStorageFavoritesCount);
    }
  }
  return null;
};

export const removeFavoritesFromLocalStorage = (): void => {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
  localStorage.removeItem(LOCAL_STORAGE_COUNT_KEY);
};

export function isFavorite(styleId: string): boolean {
  return (getFavoritesFromLocalStorage() ?? []).includes(styleId);
}

export const sortByInputOrder = (
  ids: FavoritesArrayType,
  products: ProductData[] | undefined
) => {
  if (!isNotNullOrUndefined(ids) || !isNotNullOrUndefined(products)) {
    return undefined;
  }
  return [...new Set(ids)]
    .map((id) => products.find(({ code }) => code === id))
    .filter(isNotNullOrUndefined);
};

import Link from "next/link";
import React from "react";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { Icon } from "react-app/src/components/Icon";
import { Text } from "react-app/src/components/Text";
import { SECTION_NAMES } from "../constants";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { withErrorBoundary } from "react-app/src/utils/WithErrorBoundary/with-error-boundary";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

const SEE_FULL_DESCRIPTION_LINK = `#${SECTION_NAMES.description}`;

const _SeeDescription = () => {
  const { t } = useTranslations();

  return (
    <XxlStack mt="6px" direction="row">
      <Link
        href={SEE_FULL_DESCRIPTION_LINK}
        style={{
          fontSize: `${xxlTheme.typography.mediumRegular.fontSize}px`,
          textDecoration: "underline",
        }}
      >
        <Text fontFamily="medium" as="span">
          {t("product.details.see.full")}
        </Text>
        <Icon
          name="CaretRight"
          size={xxlTheme.typography.mediumRegular.fontSize}
          transform={{ translateX: 0, translateY: 2 }}
        />
      </Link>
    </XxlStack>
  );
};

export const SeeDescription = withErrorBoundary(_SeeDescription);

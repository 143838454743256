import type { BaseBrandData } from "@xxl/frontend-api";
import React from "react";
import { HeaderLink } from "./CategorySection.styled";
import { LastGridChild, StyledGridTemporary } from "./Grid.styled";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { DEFAULT_BRAND_LINKS } from "./config";
import type { Link } from "./LinkListTemporary";
import { Links } from "./LinkListTemporary";
import { SectionHeaderTemporary } from "./SectionHeaderTemporary.styled";
import { contentSectionStyles } from "./mega-menu-helper";

type BrandsSectionProps = {
  brandLinks?: BaseBrandData[];
  cutoff?: number;
  style?: React.CSSProperties;
};

const showMoreLink = "/b/brandindex";

const mapToLinks = (brandLinks: BaseBrandData[] = []): Link[] =>
  brandLinks.map(({ brandName: name = "", url = "" }) => ({ name, url }));

export const BrandsSection: React.FunctionComponent<BrandsSectionProps> = ({
  brandLinks = [],
  cutoff = DEFAULT_BRAND_LINKS,
}) => {
  const { t } = useTranslations();
  const title = t("mega.menu.brands");
  const links = mapToLinks(brandLinks);

  return (
    <LastGridChild>
      <HeaderLink
        href={showMoreLink}
        nrOfColumns={1}
        style={contentSectionStyles}
      >
        {title}
      </HeaderLink>
      <Links {...{ links, showMoreLink, cutoff }} />
    </LastGridChild>
  );
};

type BrandsSectionTemporaryProps = {
  brandLinks?: BaseBrandData[];
  cutoff?: number;
  style?: React.CSSProperties;
};

export const BrandsSectionTemporary: React.FunctionComponent<
  BrandsSectionTemporaryProps
> = ({ brandLinks = [], cutoff = DEFAULT_BRAND_LINKS }) => {
  const { t } = useTranslations();
  const title = t("mega.menu.brands");
  const links = mapToLinks(brandLinks);

  return (
    <StyledGridTemporary itemType="brands">
      <SectionHeaderTemporary href={showMoreLink}>
        {title}
      </SectionHeaderTemporary>
      <Links {...{ links, showMoreLink, cutoff }} />
    </StyledGridTemporary>
  );
};

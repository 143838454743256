import * as React from "react";
import type { ClickAndCollectInfo } from "../../StoreStock.helper";
import { StoreStockLevelElement } from "../../StoreStockStatus";
import { Messages } from "../../StoreStockStyles";
import { StoreStockListItemName } from "../../StoreStockListItemName";
import type { StockLevel } from "../../../ClickAndCollect/api/api";

type HeaderProps = {
  displayName: string;
  expandedStoreId: string;
  stockLevel: StockLevel;
  storeId: string;
  clickAndCollectInfo?: ClickAndCollectInfo;
};
const Header: React.FunctionComponent<HeaderProps> = ({
  clickAndCollectInfo,
  displayName,
  expandedStoreId,
  stockLevel,
  storeId,
}) => {
  return (
    <>
      <StoreStockListItemName
        id={storeId}
        checked={storeId === expandedStoreId}
        name={displayName}
      />
      <StoreStockLevelElement stockLevel={stockLevel} />
      {clickAndCollectInfo !== undefined && (
        <Messages isVisible={storeId === expandedStoreId}>
          <p>{clickAndCollectInfo.pickupTimeMessage}</p>
          <p>{clickAndCollectInfo.specialOpeningHoursMessage}</p>
        </Messages>
      )}
    </>
  );
};

export { Header };

import styled from "@emotion/styled/macro";
import { typographyToCss } from "../../../styles/helpers";
import { colorsAsCssVariable } from "../../../styles/theme/colors";
import { CircleWithBlackCheckmark } from "@xxl/icons";

export const Wrapper = styled.div`
  display: flex;
  height: 84px;
  padding: 12px;
  box-sizing: border-box;
  background-color: ${colorsAsCssVariable.xxlLightGreen};
`;

export const Size = styled.p`
  margin: 0;
  ${typographyToCss({
    fontSize: 12,
  })}
`;

export const Checkmark = styled(CircleWithBlackCheckmark)`
  position: relative;
  bottom: 8px;
`;

import React, { useState } from "react";
import { useKeenSlider } from "keen-slider/react";
import { IMAGE_SIZE } from "../constants";
import { createThumbnailSrc } from "./ProductImageSlider.helper";
import {
  Arrow,
  ThumbnailImageWrapper,
  ThumbnailImage,
  DesktopImageGrid,
} from "./ProductImage.styles";
import type { ProductImageSliderProps } from "../types";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { Icon } from "react-app/src/components/Icon/Icon";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { ProductImages } from "./ProductImages";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { LastPlaceComponentWrapped } from "./LastPlaceComponentWrapped";
import { ImageDialog } from "./ImageDialog";
import { ThumbnailArrows } from "./ThumbnailArrows";
import { ThumbnailSlider } from "./ThumbnailSlider";

export const ProductImageSliderDesktop = ({
  campaignComponent,
  productImages = [],
  children,
  LastPlaceComponent,
  shouldShowDesktopThumbnails = true,
  productName,
}: ProductImageSliderProps) => {
  const { t } = useTranslations();
  const initialSlide = 0;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [isLastItem, setIsLastItem] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: initialSlide,
    slideChanged(slider) {
      const { abs, maxIdx, rel } = slider.track.details;
      setCurrentSlide(rel);
      if (abs === maxIdx) {
        setIsLastItem(true);
      }
    },
    created() {
      setLoaded(true);
    },
  });

  const hasThumbnailSlider = productImages.length > 5;
  const hasMoreThanOneProductImage = productImages.length > 1;

  const [open, setOpen] = useState(false);
  const [initialSlideIndex, setInitialSlideIndex] = useState(0);
  const handleClose = () => setOpen(false);
  const handlePreviousClick = () => instanceRef.current?.prev();
  const handleNextClick = () => instanceRef.current?.next();

  const handleClickOpen = (img: string) => {
    setOpen(true);
    const index = productImages.findIndex((image) => image === img);
    setInitialSlideIndex(index);
  };

  const content = (
    <>
      {shouldShowDesktopThumbnails && (
        <>
          {hasThumbnailSlider && loaded ? (
            <>
              <ThumbnailArrows
                currentSlide={currentSlide}
                handleNextClick={handleNextClick}
                handlePreviousClick={handlePreviousClick}
                sliderLength={productImages.length - 1}
              >
                <ThumbnailSlider
                  currentSlide={currentSlide}
                  instanceRef={instanceRef}
                  productImages={productImages}
                />
              </ThumbnailArrows>
            </>
          ) : (
            <ThumbnailImageWrapper>
              {productImages.map((productImage, idx) => (
                <ThumbnailImage
                  key={`${idx} - ${productImage}`}
                  priority={true}
                  src={createThumbnailSrc(productImage)}
                  selected={currentSlide === idx}
                  width={IMAGE_SIZE.THUMBNAIL}
                  height={IMAGE_SIZE.THUMBNAIL}
                  alt={`${productName} - ${t(
                    "product.details.alt.image.thumbnail"
                  )} ${idx + 1}`}
                  onClick={() => instanceRef.current?.moveToIdx(idx)}
                />
              ))}
            </ThumbnailImageWrapper>
          )}
        </>
      )}

      {!shouldShowDesktopThumbnails && hasMoreThanOneProductImage ? (
        <XxlStack
          direction="row"
          gap={xxlTheme.spaces.mini}
          alignItems="center"
          boxSizing="border-box"
          overflow="hidden"
        >
          <Arrow
            aria-label={t("general.previous")}
            direction="left"
            onClick={handlePreviousClick}
            disabled={currentSlide === 0}
          >
            <Icon name="CaretUp" color="white" size={18} />
          </Arrow>
          <XxlStack overflow="hidden" width="100%">
            <ProductImages
              campaignComponent={campaignComponent}
              handleClickOpen={handleClickOpen}
              productImages={productImages}
              productName={productName}
              ref={sliderRef}
              LastPlaceComponent={
                LastPlaceComponent !== undefined ? (
                  <LastPlaceComponentWrapped isLastItem={isLastItem}>
                    {LastPlaceComponent}
                  </LastPlaceComponentWrapped>
                ) : undefined
              }
            >
              {children}
            </ProductImages>
          </XxlStack>
          <Arrow
            aria-label={t("general.next")}
            direction="right"
            onClick={handleNextClick}
            disabled={currentSlide === productImages.length - 1}
          >
            <Icon name="CaretUp" color="white" size={18} />
          </Arrow>
        </XxlStack>
      ) : (
        <ProductImages
          campaignComponent={campaignComponent}
          handleClickOpen={handleClickOpen}
          productImages={productImages}
          ref={sliderRef}
          productName={productName}
          LastPlaceComponent={
            LastPlaceComponent !== undefined ? (
              <LastPlaceComponentWrapped isLastItem={isLastItem}>
                {LastPlaceComponent}
              </LastPlaceComponentWrapped>
            ) : undefined
          }
        >
          {children}
        </ProductImages>
      )}

      <ImageDialog
        initialSlideIndex={initialSlideIndex}
        open={open}
        handleClose={handleClose}
        productImages={productImages}
      />
    </>
  );

  if (shouldShowDesktopThumbnails) {
    return <DesktopImageGrid className="sadf">{content}</DesktopImageGrid>;
  }

  return content;
};

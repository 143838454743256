import React, { useRef } from "react";
import { useInView } from "framer-motion";
import { ProductFormComponent } from "./ProductForm";
import { ProductFormOnlyAvailableInStore } from "./ProductFormOnlyAvailableInStore";
import { ProductFormPreSale } from "./ProductFormPreSale";
import type { TProductForm } from "./types";
import { SALES_METHODS } from "@/react-hooks/useProductData/constants";
import { ProductFormArchived } from "@/react-components/ProductFormArchived";

export const ProductFormContainer = (props: TProductForm) => {
  const ref = useRef(null);
  const isRefInView = useInView(ref, {
    margin: "-100px",
  });
  const {
    isDiscontinued,
    onlyAvailableInStoreNoClickAndCollect,
    productName,
    salesMethod,
    shouldShowArchivedForm,
  } = props;

  if (salesMethod === SALES_METHODS.PRE_SALE) {
    return (
      <ProductFormPreSale btnRef={ref} isRefInView={isRefInView} {...props} />
    );
  }

  if (shouldShowArchivedForm) {
    return (
      <ProductFormArchived
        btnRef={ref}
        isRefInView={isRefInView}
        productName={productName}
        isAddToCartBtnInView={isRefInView}
        isDiscontinued={isDiscontinued}
      />
    );
  }

  if (onlyAvailableInStoreNoClickAndCollect) {
    return (
      <ProductFormOnlyAvailableInStore
        formRef={ref}
        isRefInView={isRefInView}
        {...props}
      />
    );
  }

  return <ProductFormComponent {...props} />;
};

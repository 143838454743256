import { colorsAsCssVariable as colors } from "../../../styles/theme/colors";

export default {
  tabButtonBackgroundColor: colors.xxlWebBlack,
  tabButtonTextColor: colors.xxlLightGrey,
  backgroundColor: colors.xxlWhite,
  textColor: colors.xxlWebBlack,
  hoverColor: colors.xxlGreen,
  activeColor: colors.xxlGreen,
};

import React from "react";
import {
  SectionHeader,
  ItemList,
  SectionWrapper,
  LinkText,
} from "./AutoSuggestionSection.styled";
import { BoldedSpan } from "./BoldedSpan";
import type { SearchSuggestion } from "./SearchBoxState";
import { MAX_NUMBER_OF_SUGGESTIONS } from "./searchBoxHelper";

type AutoSuggestionSectionProps = {
  headerName: string;
  items: SearchSuggestion[];
  onClick: (ticket?: string) => void;
  foundPhrase?: string;
};

export const AutoSuggestionSection = ({
  headerName,
  items,
  foundPhrase,
  onClick,
}: AutoSuggestionSectionProps) => {
  return (
    <SectionWrapper>
      <SectionHeader data-testid="auto-suggest-section-header">
        {headerName}
      </SectionHeader>
      <ItemList>
        {items.slice(0, MAX_NUMBER_OF_SUGGESTIONS).map((item, index) => {
          const { queryResult, ticket } = item;

          return (
            <li key={index} data-testid="auto-suggest-section-item">
              <LinkText href={item.url} onClick={() => onClick(ticket)}>
                <BoldedSpan foundPhrase={foundPhrase} text={queryResult} />
              </LinkText>
            </li>
          );
        })}
      </ItemList>
    </SectionWrapper>
  );
};

export const formatDate = (rawDate: Date): string =>
  rawDate.toISOString().substr(0, 10);

export const getMonthDay = (isoString: string, locale = "default"): string => {
  return new Date(isoString).toLocaleString(locale, {
    day: "numeric",
    month: "short",
  });
};

export const getNumericMonthDay = (
  isoString: string,
  locale = "default"
): string => {
  return new Date(isoString).toLocaleString(locale, {
    day: "2-digit",
    month: "numeric",
  });
};

export const getLocalizedNumericDate = (
  dateString: string,
  locale = "default"
): string => {
  const date = new Date(dateString).toISOString();
  const formattedDate = date.replace(/(\d+)-(\d+)-(\d+)T.*/, "$3.$2.$1");
  const norwegianDate = formattedDate
    .slice(0, -4)
    .concat(formattedDate.slice(-2));

  if (locale === "sv" || locale === "da") {
    return date.substring(0, 10);
  }
  if (locale === "fi") {
    return formattedDate;
  }

  return norwegianDate;
};

import * as React from "react";
import {
  getBackgroundColorFromColorTheme,
  getForegroundColorFromColorTheme,
} from "./product-helper";
import type { ColorTheme } from "./product-helper";
import { isNotNullOrUndefined } from "@xxl/common-utils";

interface RibbonProps {
  identifier: string;
  label?: string;
  colorTheme?: ColorTheme;
}

const Ribbon: React.FunctionComponent<RibbonProps> = ({
  label,
  identifier,
  colorTheme,
}) => {
  const foregroundColor =
    isNotNullOrUndefined(colorTheme) &&
    getForegroundColorFromColorTheme(colorTheme);
  const backgroundColor =
    isNotNullOrUndefined(colorTheme) &&
    getBackgroundColorFromColorTheme(colorTheme);
  const css = `
    #ribbon_${identifier} .ribbon__content {
      ${foregroundColor ? `color: ${foregroundColor}` : ""};
      ${backgroundColor ? `background-color: ${backgroundColor}` : ""};
    }

    #ribbon_${identifier}::before {
      ${backgroundColor ? `border-top-color: ${backgroundColor}` : ""};
    }
  `;

  return (
    <>
      {label && (
        <>
          <style>{css}</style>
          <span className="ribbon" id={`ribbon_${identifier}`}>
            <div className="ribbon__content">
              <span>{label}</span>
            </div>
          </span>
        </>
      )}
    </>
  );
};

export { Ribbon };

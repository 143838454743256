import React, { useMemo } from "react";
import { Button } from "../../../../styled/Button.styled";
import { useTranslations } from "../../../../contexts/Translations/TranslationsContext";
import { Formik } from "formik";
import { LoadingCircle } from "../../../Common/LoadingCircle/LoadingCircle";
import { DialogBoxHeaderWrapper } from "../../../DialogBox/DialogBox.styled";
import {
  FormContainer,
  OTPCountdownResentWrapper,
  ResendButton,
} from "../LoginForm/LoginForm.styled";
import DialogCloseButton from "../../SharedComponents/DialogBoxCloseButton";
import {
  FormHeaderInModal,
  SignUpErrorMessage,
} from "../../SignUp/SignUpForm/SignupForm.styled";
import { FormInput } from "../../../Common/FormInput";
import { respondOTPChallenge } from "../../../UserDetails/UserAPI";
import {
  convertSiteUidToHost,
  legacySiteUidToSiteUid,
} from "../../../../utils/xxl-shared-data";
import { useSharedData } from "../../../../contexts/SharedData";
import type { GraphQLError } from "../../../../graphql/graphqlApi";
import type { TranslationKey } from "../../../../translations";
import {
  type OTPData,
  oneTimePasswordField,
  otpResponseSchema,
} from "./OTPLoginHelper";
import { OTPCountdownTimer } from "./OTPCountdownTimer";
import { useSessionSource } from "../../../../contexts/Session";
import { log } from "@xxl/logging-utils";

type OTPResponseFormData = {
  oneTimePassword: string;
};

type OTPRequestForm = {
  handleDialogBoxClosing: () => void;
  onSubmitSuccess: () => void;
  OTPData: OTPData;
  onOTPResend: () => void;
};

const OTPResponseForm = ({
  handleDialogBoxClosing,
  onSubmitSuccess,
  OTPData,
  onOTPResend,
}: OTPRequestForm) => {
  const { t } = useTranslations();
  const { siteUid, configuration } = useSharedData().data;
  const { aws_appsync_graphqlEndpoint, aws_appsync_apiKey } =
    configuration.amplifyConfig;
  const { session, phoneNumber, expires } = OTPData;
  const [inProgress, setInProgress] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const { fieldName, placeholder, inputMode, label } = oneTimePasswordField(t);
  const [hasTimePassed, setHasTimePassed] = React.useState(false);

  const setErrors = (errors: GraphQLError[]) => {
    const errorList = errors
      .map((error) => t(error.message as TranslationKey))
      .join(", ");
    setErrorMessage(errorList ?? t("general.error"));
  };

  const onClickSubmit = async (values: OTPResponseFormData) => {
    setInProgress(true);

    try {
      setInProgress(true);
      const { oneTimePassword } = values;

      const site = convertSiteUidToHost(legacySiteUidToSiteUid(siteUid));
      const { data, errors } = await respondOTPChallenge(
        {
          oneTimePassword,
          phoneNumber,
          site,
          session,
        },
        aws_appsync_graphqlEndpoint,
        aws_appsync_apiKey
      );

      if (errors !== undefined) {
        setHasTimePassed(true);
        setErrors(errors);
      }
      const { challengeResult } = data?.respondToOTPChallenge ?? {};
      if (challengeResult === true) {
        void fetch("/cookie/xxl");
        useSessionSource.set(true);
        onSubmitSuccess();
      }
    } catch (err) {
      setErrorMessage(t("general.error"));
      log.error(err);
    } finally {
      setInProgress(false);
    }
  };

  const OTPCountdownTimerMemo = useMemo(() => {
    if (expires === undefined) {
      return null;
    }
    return (
      <OTPCountdownTimer
        expires={expires}
        setHasTimePassed={() => setHasTimePassed(true)}
      />
    );
  }, [expires]);

  return (
    <>
      <Formik
        initialValues={{ oneTimePassword: "" }}
        onSubmit={onClickSubmit}
        validateOnBlur={true}
        validateOnChange={true}
        isInitialValid={false}
        validationSchema={otpResponseSchema(t)}
      >
        {({
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
          touched,
          values,
        }): JSX.Element => (
          <FormContainer inProgress={false} onSubmit={handleSubmit}>
            <DialogBoxHeaderWrapper>
              <FormHeaderInModal>{t("login.form.title")}</FormHeaderInModal>
              <DialogCloseButton
                handleDialogBoxClosing={handleDialogBoxClosing}
              />
            </DialogBoxHeaderWrapper>
            <p>{t("login.form.otp.submit.description")}</p>
            {errorMessage !== null && (
              <SignUpErrorMessage>{errorMessage}</SignUpErrorMessage>
            )}
            <FormInput
              fieldName={fieldName}
              id="otp-input"
              label={label}
              required={true}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={placeholder}
              type={inputMode}
              value={values[fieldName]}
              errors={errors}
              translatedServerErrors={{}}
              touched={touched[fieldName] ?? false}
              style={{ flexGrow: 1 }}
            />

            <OTPCountdownResentWrapper>
              {!hasTimePassed && OTPCountdownTimerMemo}
              <div>
                {hasTimePassed
                  ? t("login.form.otp.code.expired")
                  : t("login.form.otp.didnt.receive.code")}
                <ResendButton type="button" onClick={onOTPResend}>
                  &nbsp;{t("login.form.otp.resend.code")}
                </ResendButton>
              </div>
            </OTPCountdownResentWrapper>
            <Button
              type="submit"
              className="button button--primary button--small button--full-width"
              disabled={!isValid || hasTimePassed}
            >
              {inProgress ? (
                <LoadingCircle isLoading={inProgress} />
              ) : (
                t("login.form.otp.submit.button")
              )}
            </Button>
          </FormContainer>
        )}
      </Formik>
    </>
  );
};

export { OTPResponseForm };

import React from "react";
import type { PropsWithChildren } from "react";
import { ContentContainer } from "../ContentContainer/ContentContainer.styled";
import type * as CSSType from "csstype";
import { FlexContainer } from "./Message.styled";

type MessageProps = {
  backgroundColor: CSSType.Property.BackgroundColor;
};
const Message = ({
  backgroundColor,
  children,
}: PropsWithChildren<MessageProps>) => {
  return (
    <ContentContainer backgroundColor={backgroundColor}>
      <FlexContainer>{children}</FlexContainer>
    </ContentContainer>
  );
};

export { Message };

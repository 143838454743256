import React from "react";
import type { PersonalOffer } from "../Api/types";
import { Cuts, Check, Stopwatch } from "@xxl/icons";
import { MemberRewardType } from "../../../generated/graphql-code-generator";
import {
  OfferBackground,
  OfferType,
  BonusCheckLineWrapper,
  BonusCheckDiamond,
  OfferFooter,
  OfferExpiration,
  Heading,
  ExpirationWrapper,
  ExpirationText,
  InfoIcon,
} from "../../Rewards/Offers.styled";
import { OfferBody } from "../Styles/PersonalOffers.styled";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { getLocalizedNumericDate } from "../../../utils/xxl-format-date";
import { useSharedData } from "../../../contexts/SharedData";

type ItemProps = {
  item: PersonalOffer;
  isCartContentLocked: boolean;
  handleToggleOfferButtonClick: (offer: PersonalOffer) => Promise<void>;
};

export const PersonalOfferItem: React.FunctionComponent<ItemProps> = ({
  item,
  isCartContentLocked,
  handleToggleOfferButtonClick,
}) => {
  const { t } = useTranslations();
  const {
    data: { language },
  } = useSharedData();
  if (!item.enabled) {
    return null;
  }
  const formattedDate =
    item.expiryDate !== null
      ? getLocalizedNumericDate(item.expiryDate, language)
      : "";

  return (
    <>
      <OfferBody>
        <OfferBackground image={item.imageUrl}>
          <OfferType isBonusCheck={false}>
            {item.type === MemberRewardType.PERSONALOFFER &&
              t("personal.offers.type.personaloffer")}
            {item.type === MemberRewardType.BONUSCHECK &&
              t("personal.offers.type.bonuscheck")}
          </OfferType>
          <Heading isBonusCheck={item.type === MemberRewardType.BONUSCHECK}>
            {item.title}
          </Heading>
          {/* XD-12073 It must be hidden as long as EG doesn't return correct data */}
          {/* {item.description.length > 0 && (
            <Subheading
              isBonusCheck={
                item.type === MemberRewardType.BONUSCHECK
              }
            >
              {item.description}
            </Subheading>
          )} */}
          {item.type === MemberRewardType.BONUSCHECK && (
            <BonusCheckLineWrapper>
              <BonusCheckDiamond />
              <BonusCheckDiamond />
              <BonusCheckDiamond />
              <BonusCheckDiamond />
            </BonusCheckLineWrapper>
          )}
        </OfferBackground>
        <OfferFooter isVisible={false} isDescriptionText={false}>
          {item.expiryDate !== null && (
            <OfferExpiration isVisible={false} isDescriptionText={false}>
              <ExpirationWrapper>
                <Stopwatch />
                <ExpirationText isVisible={false} isDescriptionText={false}>
                  {formattedDate}
                </ExpirationText>
              </ExpirationWrapper>
              <InfoIcon />
            </OfferExpiration>
          )}
        </OfferFooter>
        {item.type === MemberRewardType.PERSONALOFFER && <Cuts />}
      </OfferBody>
      <button
        type="button"
        className={`button button--small ${
          item.active ? "button--accent" : "button--secondary button--outlined"
        }`}
        onClick={() => {
          void handleToggleOfferButtonClick(item);
        }}
        disabled={isCartContentLocked}
      >
        {item.active && <Check />}
        {item.active
          ? t("personal.offers.activated")
          : t("personal.offers.activate")}
      </button>
    </>
  );
};

import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { MQ, typographyToCss } from "../../../styles/helpers";
import { xxlTheme } from "../../../styles/xxl-theme";
import typography from "./CartPage.typography";
import { HeaderText, MiniCartHeader } from "./MiniCartContent.styled";

const { spaces, colors } = xxlTheme;
const { pageTitle } = typography;

export const cartProductColumnWidthLaptop = "500px";

type CartProductsColumnProps = {
  isLocked: boolean;
};

export const CartProductsColumn = styled.div<CartProductsColumnProps>(
  ({ isLocked }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    margin: ${spaces.smallLarge} 0 0;
    width: 100%;
    order: 1;

    ${isLocked &&
    css`
      pointer-events: none;
    `}

    ${MQ("laptop")} {
      width: ${cartProductColumnWidthLaptop};
      order: 2;
    }

    &:not(:first-of-type) {
      margin-top: 0;

      ${MQ("laptop")} {
        margin-top: ${spaces.smallLarge};
      }
    }
  `
);

export const Title = styled.h1`
  ${typographyToCss(pageTitle)};
  margin: 0 0 ${spaces.smallLarge};
`;

export const DialogHeader = styled(MiniCartHeader)`
  background-color: ${colors.xxlWhite};
  box-sizing: border-box;
  padding: 0;
  margin-top: ${spaces.smallRegular};
`;

export const DialogHeaderText = styled(HeaderText)`
  margin: 0;
  color: ${colors.xxlWebBlack};
`;

import type { SelectedSort } from "../../components/Search/SearchState";
import type { FitstationScan } from "../../components/UserDetails/UserAPI";
import type { Filter } from "../data-types";

export type HistoryState = {
  categoryId?: string;
  expandedFilterAccordions?: string[];
  isPreferredStoreActive?: boolean;
  openFilterAccordions?: string[];
  page?: number;
  scrollPosition?: number;
  selectedFilters?: Filter[];
  selectedSort?: SelectedSort;
  scan?: FitstationScan;
};

const replaceOrPushHistoryState =
  (replaceOrPushState: keyof History) =>
  (
    state: HistoryState,
    title = "",
    url: string | null = null // Need to be null when unset
  ): void => {
    if (typeof window !== "undefined") {
      const newState = {
        ...window.history.state,
        ...state,
      };
      window.history[replaceOrPushState](newState, title, url);
    }
  };

export const replaceHistoryState = replaceOrPushHistoryState("replaceState");

export const pushHistoryState = replaceOrPushHistoryState("pushState");

export const replaceUrlInHistoryState = (url: string | URL): void => {
  if (typeof window !== "undefined") {
    window.history.replaceState(window.history.state, "", url);
  }
};

export const getHistoryStateValue = <K extends keyof HistoryState>(
  key: K
): HistoryState[K] => {
  if (
    typeof window !== "undefined" &&
    window.history.state !== undefined &&
    window.history.state !== null
  ) {
    return window.history.state[key] as HistoryState[K];
  }

  return;
};

import noop from "lodash/noop";
import { ProductStyleSelectorComponent } from "./ProductStyleSelectorComponent";
import type { ProductData, StyleData } from "@xxl/pim-api";
import type { Style } from "./types";
import type { MouseEvent } from "react";
import { STOCK_STATUS } from "react-app/src/constants";

type Props = {
  onClick?: ({
    code,
    event,
  }: {
    code: string;
    event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>;
  }) => void;
  styleOptions: ProductData["styleOptions"];
};

const isStyle = ({
  code,
  primaryImage,
  name,
  colourData,
  pk,
  stockStatus,
  url,
  selected,
}: StyleData) => {
  const conditions = [
    code,
    primaryImage,
    name,
    colourData,
    colourData?.name,
    pk,
    stockStatus,
    url,
    stockStatus,
  ];

  if (conditions.some((condition) => condition === undefined)) {
    return null;
  }
  return {
    code,
    primaryImage,
    name,
    colourDataName: colourData?.name,
    pk,
    isInStock:
      stockStatus === STOCK_STATUS.INSTOCK ||
      stockStatus === STOCK_STATUS.LOWSTOCK,
    url,
    selected: selected ?? false,
  } as Style;
};

export const ProductStyleSelector = ({
  onClick = noop,
  styleOptions = [],
}: Props) => {
  if (styleOptions.length <= 1) return null;

  const inStockStyles = new Set<Style>();
  const outOfStockStyles = new Set<Style>();

  for (const s of styleOptions) {
    const style = isStyle(s);
    if (style === null) continue;
    if (!style.isInStock) {
      outOfStockStyles.add(style);
    } else {
      inStockStyles.add(style);
    }
  }

  return (
    <ProductStyleSelectorComponent
      onClick={onClick}
      styles={[...inStockStyles, ...outOfStockStyles]}
    />
  );
};

import type { FormikHelpers } from "formik";
import { Formik } from "formik";
import ReactHtmlParser from "html-react-parser";
import noop from "lodash/noop";
import * as React from "react";
import { createAccount } from "../../../../components/UserDetails/UserAPI";
import { useSessionSource } from "../../../../contexts/Session";
import { useSharedData } from "../../../../contexts/SharedData";
import { useTranslations } from "../../../../contexts/Translations/TranslationsContext";
import { Button, StyledCheckbox } from "../../../../styled";
import { xxlTheme } from "../../../../styles/xxl-theme";
import { getXXLCookie } from "../../../../utils/Cookie";
import { isCartPage } from "../../../../utils/xxl-page-type";
import { LoadingCircle } from "../../../Common";
import { DialogBoxHeaderWrapper } from "../../../DialogBox/DialogBox.styled";
import DialogCloseButton from "../../SharedComponents/DialogBoxCloseButton";
import {
  AlreadyMemberContainer,
  CheckboxContainer,
  FormContainer,
  FormHeader,
  FormHeaderInModal,
  InputContainer,
  SignUpErrorMessage,
  SignUpFormInput,
  SignUpFormLabel,
  SignUpInfoMessage,
  TermsLabel,
} from "./SignupForm.styled";
import {
  fields,
  formatGraphQLErrors,
  getInitialFormData,
  getReviewSchema,
  getTranslatedGeneralErrors,
  getTranslatedServerErrors,
  type FormError,
} from "./SignUpFormHelper";
import type {
  ErrorMessageProps,
  InitialFormDataType,
  RewardSignup,
  ServerErrorsTranslated,
} from "./types";
import { log } from "@xxl/logging-utils";

const { colors } = xxlTheme;

const getInputType = (fieldName: keyof InitialFormDataType): string => {
  switch (fieldName) {
    case "password":
      return "password";
    case "email":
      return "email";
    case "phoneNumber":
      return "tel";
    case "terms":
      return "checkbox";
    default:
      return "text";
  }
};

const INPUT_IDS_TO_HIDE = ["password", "username"];

export const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = ({
  errors,
  fieldName,
  isTouched,
  translatedServerErrors,
}) => {
  if (errors[fieldName] !== undefined && isTouched) {
    return <SignUpErrorMessage>{errors[fieldName]}</SignUpErrorMessage>;
  }
  // todo: edit types to avoid
  if (
    fieldName !== "consent" &&
    fieldName !== "subscribeToEmail" &&
    fieldName !== "subscribeToSms" &&
    fieldName !== "terms" &&
    fieldName !== "storeId" &&
    translatedServerErrors[fieldName] !== undefined &&
    !isTouched
  ) {
    return (
      <SignUpErrorMessage color={colors.xxlRed}>
        {translatedServerErrors[fieldName]}
      </SignUpErrorMessage>
    );
  }
  return null;
};

export const SignUpForm: React.FunctionComponent<RewardSignup> = ({
  descriptionTextComponent,
  isInModal = false,
  onClose,
  onSignupError = noop,
  successfulSignUpCallback,
  linkCode,
  isSelected,
  storeId,
  includePhone = true,
  shouldShowLoginInformation = true,
  userData = {},
  signUpTranslations,
  handleDialogBoxClosing,
  styles,
  shouldHideUsernameAndPasswordField = false,
  shouldShowFilledNameInputs = false,
}) => {
  const { t } = useTranslations();
  const { configuration, featureToggles, siteUid, isTeamsales } =
    useSharedData().data;
  const { toggle_social_security_number, toggle_triggerbee } = featureToggles;
  const initialValues = getInitialFormData(includePhone, userData);
  log.info({ initialValues, includePhone, userData });
  const formFields = fields(
    includePhone,
    toggle_social_security_number,
    t,
    siteUid,
    userData,
    shouldShowFilledNameInputs
  );
  const [inProgress, setInProgress] = React.useState(false);
  const [translatedGeneralErrors, setTranslatedGeneralErrors] = React.useState<
    string[]
  >([]);
  const [translatedServerErrors, setTranslatedServerErrors] =
    React.useState<ServerErrorsTranslated>({});
  const setErrors = (errors: FormError[]) => {
    setTranslatedGeneralErrors(getTranslatedGeneralErrors(errors, t));
    setTranslatedServerErrors(getTranslatedServerErrors(errors, t));
  };
  const [hasClickedSubmit, setHasClickedSubmit] = React.useState(false);
  const onClickSubmit = () => setHasClickedSubmit(true);
  const registerXxlReward = async (
    formData: InitialFormDataType
  ): Promise<void> => {
    try {
      setInProgress(true);
      setTranslatedGeneralErrors([]);
      setTranslatedServerErrors({});

      await fetch("/cookie/xxl"); // make sure there is a xxl session cookie before creating account
      const res = await createAccount(
        {
          email: formData.email as string,
          password: formData.password as string,
          firstName: formData.firstName as string,
          lastName: formData.lastName as string,
          mobilePhone: formData.phoneNumber as string,
          socialSecurityNumber: formData.socialSecurityNumber,
          storeId: formData.storeId,
          consent: "SIGN_UP",
        },
        configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
        configuration.amplifyConfig.aws_appsync_apiKey
      );
      const formattedErrors = formatGraphQLErrors(
        res.errors ?? [],
        t
      ) as FormError[];

      const isResponseOk = formattedErrors.length === 0;

      if (formattedErrors.length > 0) {
        onSignupError(formattedErrors);
        setErrors(formattedErrors);
        setInProgress(false);
        return;
      }

      if (isResponseOk) {
        if (
          toggle_triggerbee &&
          typeof window !== "undefined" &&
          window.mtr !== undefined
        ) {
          window.mtr_custom = {
            session: {
              email: formData.email,
              name: `${formData.firstName ?? ""} ${formData.lastName ?? ""}`,
            },
            async: 1,
          };
          window.mtr.goal("Account created");
        }

        await fetch("/cookie/xxl"); // sync existing xxl session cookie after creating account
        const xxlCookie = (await getXXLCookie()) ?? {};
        useSessionSource.set(xxlCookie.loggedIn === true);
      }
      if (
        isCartPage() &&
        !isTeamsales &&
        !window.XxlHelpers.isCypress &&
        isResponseOk
      ) {
        window.location.reload();
      }
      successfulSignUpCallback();
    } catch (data: unknown) {
      setInProgress(false);
      setErrors([{ message: "register.form.error.internal.failure" }]);
      onSignupError();
      try {
        throw Error(`Failed to create account. Error: ${JSON.stringify(data)}`);
      } catch (error) {
        throw Error("Failed to create account.");
      }
    }
  };
  const onSubmit = (
    formData: InitialFormDataType,
    formikHelpers: FormikHelpers<InitialFormDataType>
  ) => {
    formikHelpers.setTouched({});
    const signUpRequest = { ...formData, linkCode, storeId };
    delete signUpRequest.terms;
    void registerXxlReward(signUpRequest);
  };

  const onLoginClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (onClose !== undefined) {
      onClose();
    }

    const loginLink = document.getElementById("xxl-react-login-link");
    if (loginLink === null) {
      return;
    }

    loginLink.click();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getReviewSchema(
        includePhone,
        toggle_social_security_number,
        t,
        siteUid
      )}
      onSubmit={onSubmit}
      validateOnBlur={true}
      validateOnChange={true}
      isInitialValid={false}
    >
      {({
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        touched,
        values,
      }): JSX.Element => (
        <FormContainer
          id="reward-signup-form"
          data-testid="reward-signup-form"
          aria-busy={inProgress}
          onSubmit={handleSubmit}
          inProgress={inProgress}
          isInModal={isInModal}
          style={styles}
        >
          {isInModal ? (
            <DialogBoxHeaderWrapper>
              <FormHeaderInModal>
                {signUpTranslations.formHeader}
              </FormHeaderInModal>
              {handleDialogBoxClosing !== undefined && (
                <DialogCloseButton
                  handleDialogBoxClosing={handleDialogBoxClosing}
                />
              )}
            </DialogBoxHeaderWrapper>
          ) : (
            <FormHeader>{signUpTranslations.formHeader}</FormHeader>
          )}
          {descriptionTextComponent !== undefined && descriptionTextComponent}
          {formFields.map(
            ({
              autocompleteToken,
              disabled,
              fieldName,
              id,
              infoMessage,
              inputMode = "text",
              label,
              placeholder,
            }) => (
              <InputContainer
                key={fieldName}
                isHidden={
                  shouldHideUsernameAndPasswordField &&
                  INPUT_IDS_TO_HIDE.includes(id)
                }
              >
                <SignUpFormLabel htmlFor={id} required={true}>
                  {label}
                </SignUpFormLabel>
                <SignUpFormInput
                  autoComplete={autocompleteToken}
                  disabled={disabled}
                  id={id}
                  inputMode={inputMode}
                  name={fieldName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={placeholder}
                  type={getInputType(fieldName)}
                  value={values[fieldName]}
                  hasError={Object.keys(translatedServerErrors).includes(
                    fieldName
                  )}
                />
                <ErrorMessage
                  {...{ fieldName, errors, translatedServerErrors }}
                  isTouched={touched[fieldName] ?? false}
                />
                <SignUpInfoMessage showIcon={false}>
                  {infoMessage ?? null}
                </SignUpInfoMessage>
              </InputContainer>
            )
          )}
          <CheckboxContainer>
            <StyledCheckbox
              name="terms"
              handleChange={handleChange}
              testId="signup-checkbox"
              id="signup-checkbox-label"
              checked={isSelected}
              label={
                <TermsLabel>
                  {ReactHtmlParser(signUpTranslations.terms)}
                </TermsLabel>
              }
            />
            {hasClickedSubmit && errors.terms !== undefined && (
              <SignUpErrorMessage>{errors.terms}</SignUpErrorMessage>
            )}
          </CheckboxContainer>
          <Button
            id="reward-signup-form-submit"
            type="submit"
            onClick={onClickSubmit}
            inline={true}
            className="button button--primary button--small button--full-width"
            disabled={!isValid}
          >
            {inProgress ? (
              <LoadingCircle isLoading={inProgress} />
            ) : (
              signUpTranslations.register
            )}
          </Button>
          {translatedGeneralErrors.map((message, index) => (
            <SignUpErrorMessage key={index}>{message}</SignUpErrorMessage>
          ))}

          {shouldShowLoginInformation && (
            <AlreadyMemberContainer>
              <Button
                className="button button--secondary button--small button--outlined  button--full-width"
                onClick={(e) => onLoginClick(e)}
              >
                {signUpTranslations.existing} {signUpTranslations.buttonText}
              </Button>
            </AlreadyMemberContainer>
          )}
        </FormContainer>
      )}
    </Formik>
  );
};

import styled from "@emotion/styled/macro";
import { xxlTheme } from "../../../styles/xxl-theme";
import { Warning } from "./ProductItem.styled";

const { spaces } = xxlTheme;

export const PageWarning = styled(Warning)`
  padding: ${spaces.smallRegular};

  & svg {
    margin-right: ${spaces.mini};
  }
`;

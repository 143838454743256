import type { EcomSiteUidLegacy } from "@/react-app/global";
import {
  hasIdAndName,
  type StoreWithId,
} from "@/react-components/Sort/AvailabilitySelector/DialogStoreSelect";
import { legacySiteUidToSiteUid } from "@/react-utils/xxl-shared-data";
import { type DayOpeningSchedule, type Store } from "@xxl/content-api";
import { siteUidToLanguage } from "@/react-utils/xxl-language";
import { isNullOrUndefined } from "@xxl/common-utils";
import { coordinatesDistance } from "react-app/src/utils/xxl-geolocation";
import { log } from "@xxl/logging-utils";
import { Apis } from "@/utils/api-helper";

const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const getStores = async (siteUid: EcomSiteUidLegacy) => {
  try {
    const response = await Apis.getInstance().contentApi.getStores(
      legacySiteUidToSiteUid(siteUid)
    );

    const { result = [] } = response.data;

    return result
      .filter(hasIdAndName)
      .sort((s1: StoreWithId, s2: StoreWithId) =>
        s1.name.localeCompare(s2.name, siteUidToLanguage[siteUid])
      );
  } catch (error) {
    log.error("Could not fetch stores.", error);
    return [];
  }
};

export const getTodayOpeningHours = (store: Store): DayOpeningSchedule => {
  const now = new Date();
  const nowDay = weekday[now.getDay()];
  const nowDate = now.toISOString().split("T")[0];
  const regularHours = store.openingHours?.find(
    (opening) => opening.day === nowDay
  ) ?? { closed: true };
  const specialHours =
    store.specialOpeningHours?.find((opening) => opening.date === nowDate) ??
    {};
  return {
    ...regularHours,
    ...specialHours,
  };
};

const COUNT_OF_NEARBY_STORES = 3;

export const getNearbyStores = (
  { location: selectedStoreLocation }: Required<StoreWithId>,
  stores: Required<StoreWithId>[]
): Required<StoreWithId>[] => {
  if (isNullOrUndefined(selectedStoreLocation)) {
    return [];
  }
  return stores
    .sort((storeA, storeB) => {
      const locationA = storeA.location;
      const locationB = storeB.location;
      const distanceToA = coordinatesDistance.get(
        selectedStoreLocation.latitude,
        selectedStoreLocation.longitude,
        locationA.latitude,
        locationA.longitude
      );
      const distanceToB = coordinatesDistance.get(
        selectedStoreLocation.latitude,
        selectedStoreLocation.longitude,
        locationB.latitude,
        locationB.longitude
      );
      if (distanceToA === undefined || distanceToB === undefined) {
        return 0;
      }
      return distanceToA - distanceToB;
    })
    .slice(1, COUNT_OF_NEARBY_STORES + 1);
};

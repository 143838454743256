import React from "react";
import { ProgressBar as Progress } from "./ProgressBar.styled";
import { getPercentage } from "./ProgressBar.util";

type ProgressBarProps = {
  id: string;
  label: string | React.ReactNode;
  maxValue: number;
  value: number;
  icon?: React.ReactElement;
};

const ProgressBar = ({
  icon,
  id,
  label,
  maxValue,
  value,
}: ProgressBarProps) => (
  <Progress
    progressInPercent={getPercentage(maxValue, value)}
    data-testid={`${id}-wrapper`}
  >
    {icon !== undefined && icon}
    <label htmlFor={id}>{label}</label>
    <progress max={maxValue} value={value} id={id}></progress>
  </Progress>
);

export { ProgressBar };

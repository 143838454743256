import type { PriceData } from "../../utils/ProductData/product-card-data-helper";
import { type ContentData } from "./Api/SuggestsApi";
import { type SearchSuggestion } from "./SearchBoxState";

export type SearchResultV2 = {
  count: number;
  items: ContentData[];
};

export type SearchSuggestsGroup = {
  highlightedPhrase: string;
  type: SearchSuggestionsTypeEnum;
  queries: SearchSuggestion[];
  id?: string; // Used for search input in FAQ component
  ticket?: string;
};

export type Product = {
  name: string;
  price: {
    selling: PriceData;
    alternate?: PriceData;
  };
  primaryImage: string;
  ticket: string;
  url: string;
  averageRating?: number;
  brand?: { name: string };
};

const sectionHeadingHeight = 27.5;
const listSpacing = 8 + 20;
const listItemHeight = 18 + 2;
const INITIAL_COLUMN_HEIGHT = 449;
const MAX_NUMBER_OF_PRODUCTS = 8;
export const MINIMUM_QUERY_LENGTH = 2;
export const MAX_NUMBER_OF_SUGGESTIONS = 5;
export enum SearchSuggestionsTypeEnum {
  items,
  categories,
  brands,
  stores,
  guides,
  faq,
  other,
  products,
}

export const getSearchedValueFromUrl = (): string => {
  const url = new URL(window.location.href);
  const searchString = url.searchParams.get("query");

  if (searchString !== null && searchString.length > 0) {
    return searchString;
  }

  return "";
};

const setSectionHeight = (suggestionsArray: unknown[]): number =>
  sectionHeadingHeight + listSpacing + suggestionsArray.length * listItemHeight;

export const setContainerHeight = (height: number, suggestions: unknown[]) => {
  if (suggestions.length > 0) {
    const arrayHeight = setSectionHeight(suggestions);
    height = height + arrayHeight;
  }
};

export const pushResultsGroup = (
  searchResultItem: SearchResultV2,
  query: string,
  type: SearchSuggestionsTypeEnum,
  searchResultItems: SearchSuggestsGroup[],
  containerHeight: number
) => {
  const suggestions: SearchSuggestion[] = searchResultItem.items
    .filter((_item, index) => index < MAX_NUMBER_OF_SUGGESTIONS)
    .map(({ link, title, ticket }) => ({
      url: link,
      queryResult: title,
      ticket,
    }));

  if (suggestions.length > 0) {
    setContainerHeight(containerHeight, suggestions);

    searchResultItems.push({
      highlightedPhrase: query,
      type,
      queries: suggestions,
    });
  }
};

export const prepareColumnContent = (
  initialArray: SearchSuggestsGroup[],
  containerHeight: number
): SearchSuggestsGroup[] => {
  const columnOneArray: SearchSuggestsGroup[] = [];
  let columnOneHeight = 0;
  const columnTwoArray: SearchSuggestsGroup[] = [];
  let columnTwoHeight = 0;

  if (containerHeight / 2 > INITIAL_COLUMN_HEIGHT) {
    initialArray.forEach((item) => {
      item.queries = item.queries.slice(0, 4);
    });
  }

  initialArray.forEach((item) => {
    const itemHeight = setSectionHeight(item.queries);
    if (
      columnOneHeight <= INITIAL_COLUMN_HEIGHT &&
      columnOneHeight + itemHeight <= INITIAL_COLUMN_HEIGHT
    ) {
      columnOneHeight = columnOneHeight + itemHeight;
      columnOneArray.push(item);
    } else {
      columnTwoHeight = columnTwoHeight + itemHeight;
      columnTwoArray.push(item);
    }
  });

  if (columnTwoHeight > INITIAL_COLUMN_HEIGHT) {
    columnTwoArray.forEach((item) => {
      item.queries = item.queries.slice(0, 3);
    });
  }

  return [...columnOneArray, ...columnTwoArray];
};

export const createProductResultGroup = (
  query: string,
  products: Product[]
) => ({
  highlightedPhrase: query,
  type: SearchSuggestionsTypeEnum.products,
  items: products.length > 0 ? products.slice(0, MAX_NUMBER_OF_PRODUCTS) : [],
});

export const isTwoColumn = (containerHeight: number) =>
  containerHeight > INITIAL_COLUMN_HEIGHT;

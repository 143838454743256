import type { DigitalCampaignContentData } from "@xxl/content-api";

const getCampaignData = ({
  campaignDescription,
  landingPageBanner,
  pageTitle = "",
  slug = "",
}: DigitalCampaignContentData) => ({
  description: campaignDescription ?? null,
  title: pageTitle,
  timer: landingPageBanner.countdownTimerSettings ?? null,
  slug,
});

export { getCampaignData };

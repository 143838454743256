import styled from "@emotion/styled/macro";
import { color } from "@xxl/theme";
import { resolutionSizes } from "react-app/src/config";
import { colorsAsCssVariable } from "react-app/src/styles/theme/colors";
import { Text } from "react-app/src/components/Text";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import spaces from "react-app/src/styles/theme/spaces";
import {
  StockLevels,
  type StockLevel,
} from "react-app/src/components/ClickAndCollect/api/api";

export const DrawerUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
`;

export const DrawerLi = styled.li<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${xxlTheme.spaces.smallRegular} 0;
  cursor: pointer;
  border-bottom: 1px solid ${xxlTheme.colors.xxlMediumGrey};

  ${({ disabled = false }) =>
    disabled &&
    `
    cursor: not-allowed;
    pointer-events: none;
      `}

  @media (min-width: ${resolutionSizes.tablet}px) {
    padding: ${xxlTheme.spaces.large} 0;
  }
`;

export const SizeHeadingSection = styled.div`
  background-color: ${color.lightGray.hex};
  padding: ${xxlTheme.spaces.smallRegular};
  display: flex;
  gap: ${xxlTheme.spaces.micro};
`;

export const SizeContainer = styled.div`
  min-width: ${spaces.giant};
`;

export const RemindMe = styled.span`
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: ${spaces.micro};
  color: ${colorsAsCssVariable.xxlBlack};
  pointer-events: auto;
`;

export const LineDivider = styled.span`
  &::before {
    content: "|";
  }
  display: block;
  color: ${colorsAsCssVariable.xxlGrey};
  padding-left: ${spaces.micro};
`;

export const LowStockText = styled.div`
  color: ${xxlTheme.colors.xxlOrange};
`;

export const OutOfStockText = styled.div`
  color: ${xxlTheme.colors.xxlRed};
`;

export const OutOfStockWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StockStatusWrapper = styled.div`
  margin: 0;
`;

export const StockStatusIndicatorWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  gap: ${spaces.micro};
`;

export const StyledText = styled(Text)`
  button {
    background: none;
    border: none;
    padding: 0;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    text-decoration: underline;
    text-align: left;
    pointer-events: none;
    &:hover {
      text-decoration: none;
    }
  }
`;

const stockStatusMap = {
  [StockLevels.IN_STOCK]: colorsAsCssVariable.xxlWebGreen,
  [StockLevels.LOW_STOCK]: colorsAsCssVariable.xxlOrange,
  [StockLevels.OUT_OF_STOCK]: colorsAsCssVariable.xxlRed,
};

export const StockStatusIndicator = styled.span<{
  stockStatus: StockLevel;
}>`
  display: inline-flex;
  align-items: center;
  min-height: 10px;
  min-width: 10px;
  margin: 0;
  margin-top: ${spaces.line};
  margin-right: ${spaces.mini};
  background: ${({ stockStatus }): string => stockStatusMap[stockStatus]};
  border-radius: 50%;
`;

export const getStoresWithCollectableProductQuery = /* GraphQL */ `
  query GetStoresWithCollectableProduct($input: CollectableStockInput!) {
    collectableStock(input: $input) {
      onlineStock
      stores {
        clickAndCollectEnabled
        collectableStock
        stock
        store {
          id
          location {
            latitude
            longitude
          }
          name
        }
      }
    }
  }
`;

export const getProductSizesStockStatusQuery = /* GraphQL */ `
  query GetProductSizesStockStatus($input: ProductSizesStockStatusInput!) {
    productSizesStockStatus(input: $input) {
      ean
      onlineStockStatus
      storeStockStatus
      storesWithStockCount
    }
  }
`;

import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { Currency } from "../../generated/graphql-code-generator";
import type { OrderProperties } from "@xxl/deliverystream-api";
import type { TrackOrderConfirmationData } from "./types";

type ProductType = {
  name: string;
  id: string;
  brand: string;
  variant: string;
  price: number;
  quantity: number;
  category: string | null;
};

export type GTMOrderConfirmationProps = {
  purchase: {
    actionField: {
      id: string;
      affiliation: string;
      revenue: number;
      tax: number;
      shipping: string;
      currency: Currency;
    };
    products: ProductType[];
  };
  sha256_email_address?: string;
};

export type LocalStorageConfirmationTrackingData = {
  checkoutId: string;
  eventSentDate: number;
};

const LOCAL_STORAGE_KEY = "confirmation-tracking";
const LOCAL_STORAGE_ENTRY_TTL_2DAYS_IN_MILLIS = 172800000;

const AFFILIATION_NAME = "XXL";

const hashText = async (text: string): Promise<string> => {
  const normalizedText = text.trim().toLowerCase();
  const buffer = new TextEncoder().encode(normalizedText);
  const hashBuffer = await crypto.subtle.digest("SHA-256", buffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((byte) => byte.toString(16).padStart(2, "0")).join("");
};

export const getGTMOrderConfirmationEvent = async (
  confirmationTrackingData: TrackOrderConfirmationData
): Promise<GTMOrderConfirmationProps> => {
  const { checkoutId, currency, totalAmount, totalTaxAmount, items, customer } =
    confirmationTrackingData;
  const products = items.map((product) => ({
    name: product.baseProductName,
    id: product.styleId,
    brand: product.brandName,
    variant: `${product.colorName ?? "N/A"}; ${product.sizeText}`,
    price: product.unitPrice,
    quantity: product.quantity,
    category: product.googleCategory,
  }));

  const customerEmail = isNotNullOrUndefined(customer.email)
    ? await hashText(customer.email)
    : undefined; // here place for Klarna fallback when needed

  return {
    purchase: {
      actionField: {
        id: checkoutId,
        affiliation: AFFILIATION_NAME,
        revenue: totalAmount,
        tax: totalTaxAmount,
        shipping: "0.00",
        currency,
      },
      products,
    },
    ...(isNotNullOrUndefined(customerEmail)
      ? {
          sha256_email_address: customerEmail,
        }
      : {}),
  };
};

export const getEventStreamConfirmationEvent = (
  confirmationTrackingData: TrackOrderConfirmationData
): OrderProperties => {
  const { checkoutId, currency, totalAmount, items, customer } =
    confirmationTrackingData;
  return {
    orderId: checkoutId,
    totalPrice: {
      currency,
      value: Number(totalAmount),
    },
    lines: items.map((product, index) => ({
      bundle: product.bundleId ?? "",
      id: product.styleId,
      size: product.sizeText,
      ean: product.ean,
      brand: product.brandName,
      productCode: product.sizeId,
      amount: product.quantity,
      entryNumber: index,
    })),
    customer: {
      email: customer.email,
      firstName: customer.firstName,
      lastName: customer.lastName,
      postalCode: customer.postalCode,
      phoneNumber: customer.phone,
    },
  };
};

export const shouldSendEvent = (
  localStorageTrackingData: LocalStorageConfirmationTrackingData[],
  checkoutId: string
): boolean =>
  !localStorageTrackingData.some(
    (storage) => storage.checkoutId === checkoutId
  );

export const updateConfirmationLocalStorage = (
  localStorageTrackingData: LocalStorageConfirmationTrackingData[],
  checkoutId: string
): void => {
  if (shouldSendEvent(localStorageTrackingData, checkoutId)) {
    const currentDate = Date.now();
    const storageThresholdDate =
      currentDate - LOCAL_STORAGE_ENTRY_TTL_2DAYS_IN_MILLIS;
    localStorageTrackingData.push({
      checkoutId,
      eventSentDate: currentDate,
    });
    const { setStorageItem } = window.XxlHelpers;
    setStorageItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify(
        localStorageTrackingData.filter(
          ({ eventSentDate }) => eventSentDate > storageThresholdDate
        )
      )
    );
  }
};

export const getConfirmationLocalStorage = () =>
  JSON.parse(
    window.localStorage.getItem(LOCAL_STORAGE_KEY) ?? "[]"
  ) as LocalStorageConfirmationTrackingData[];

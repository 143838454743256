import type { ChangeEvent, Dispatch, SetStateAction } from "react";
import React, { useEffect, useState } from "react";
import type { RelyCallback } from "rely-use-callback";
import {
  SizeOptionsContainer,
  ProductFormNumberInput,
} from "./ProductForm.styles";
import type { SizeData, StockStatus } from "@xxl/pim-api";
import type { PreSelectSizeStrategy } from "../ProductSizeSelector/ProductSizeSelector";
import { ProductSizeSelector } from "../ProductSizeSelector/ProductSizeSelector";
import { MAX_ITEM_QUANTITY } from "../constants";
import type { ButtonSizeType } from "react-app/src/components/Common/XxlButton/XxlButton";
import { QUANTITY_ONE } from "react-app/src/constants";
import { parseNumber } from "react-app/src/utils/xxl-number";

const EMPTY_INPUT_VALUE = "";

type OnSelectSizeProps = {
  ean: string;
  quantity: number;
  stockStatus: StockStatus;
};

type Props = {
  buttonSize?: ButtonSizeType;
  hasError?: boolean;
  isPurchaseAllowed: boolean;
  isSizeSelectDrawerOpen?: boolean;
  onSelectSize:
    | RelyCallback<({ ean, quantity, stockStatus }: OnSelectSizeProps) => void>
    | Dispatch<SetStateAction<OnSelectSizeProps | null>>;
  preSelectedSizeCodeOrEan?: string;
  preSelectSizeStrategy?: PreSelectSizeStrategy;
  styleCode: string;
  quantityToggleEnabled: boolean;
  shouldBePossibleToSelectSize?: boolean;
  sizeOptions: SizeData[];
  shouldBePossibleToAddReminder?: boolean;
  shouldBePossibleToSelectOutOfStock?: boolean;
  toggleIsSizeSelectDrawerOpen?: () => void;
  unit?: string;
  isClickAndCollectEnabled: boolean;
};

const SizeOptions = ({
  buttonSize = "small",
  hasError,
  isPurchaseAllowed,
  isSizeSelectDrawerOpen,
  onSelectSize,
  preSelectedSizeCodeOrEan,
  preSelectSizeStrategy,
  styleCode,
  quantityToggleEnabled,
  shouldBePossibleToSelectOutOfStock,
  shouldBePossibleToAddReminder,
  shouldBePossibleToSelectSize = true,
  sizeOptions,
  toggleIsSizeSelectDrawerOpen,
  isClickAndCollectEnabled,
  unit,
}: Props) => {
  const [ean, setEan] = useState<string | null>(null);
  const [stockStatus, setStockStatus] = useState<StockStatus | null>(null);
  const [quantity, setQuantity] = useState<number | null>(QUANTITY_ONE);
  const handleOnChangeQuantity = ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    const value = parseNumber(target.value);
    setQuantity(
      value > MAX_ITEM_QUANTITY
        ? MAX_ITEM_QUANTITY
        : value >= QUANTITY_ONE
          ? value
          : null
    );
  };

  useEffect(() => {
    if (ean === null || quantity === null || stockStatus === null) {
      return;
    }
    onSelectSize({ ean, quantity, stockStatus });
  }, [ean, onSelectSize, quantity, stockStatus]);

  const handleOnSelect = (
    selectedEan: string | null,
    selectedStockStatus: StockStatus
  ) => {
    setEan(selectedEan);
    setStockStatus(selectedStockStatus);
  };

  return (
    <SizeOptionsContainer quantityToggleEnabled={quantityToggleEnabled}>
      {quantityToggleEnabled && (
        <ProductFormNumberInput
          type="number"
          inputMode="numeric"
          required={true}
          disabled={!isPurchaseAllowed || !shouldBePossibleToSelectSize}
          onBlur={() => setQuantity(quantity ?? QUANTITY_ONE)}
          onChange={handleOnChangeQuantity}
          value={quantity ?? EMPTY_INPUT_VALUE}
        />
      )}
      <ProductSizeSelector
        disabled={!shouldBePossibleToSelectSize || sizeOptions.length === 1}
        hasError={hasError}
        isDrawerOpen={isSizeSelectDrawerOpen}
        toggleIsSizeSelectDrawerOpen={toggleIsSizeSelectDrawerOpen}
        onSelect={handleOnSelect}
        preSelectSizeStrategy={preSelectSizeStrategy}
        styleCode={styleCode}
        shouldBePossibleToAddReminder={shouldBePossibleToAddReminder}
        shouldBePossibleToSelectOutOfStock={shouldBePossibleToSelectOutOfStock}
        sizeOptions={sizeOptions}
        size={buttonSize}
        preSelectedSizeCodeOrEan={preSelectedSizeCodeOrEan}
        unit={unit}
        isClickAndCollectEnabled={isClickAndCollectEnabled}
      />
    </SizeOptionsContainer>
  );
};

export { SizeOptions };
export type { OnSelectSizeProps };

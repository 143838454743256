import * as React from "react";
import {
  type ColorTheme,
  getForegroundColorFromColorTheme,
  getBackgroundColorFromColorTheme,
} from "../product-helper";
import { Label, RibbonContent, RibbonWrapper } from "./NewRibbon.styled";

export type RibbonDirection = "left" | "right";

interface NewRibbonProps {
  label?: string;
  colorTheme?: ColorTheme;
  direction?: RibbonDirection;
  className?: string;
  dataTestid?: string;
}

const NewRibbon = ({
  label,
  colorTheme,
  className = "",
  direction,
  dataTestid = "new-ribbon",
}: NewRibbonProps) => {
  const foregroundColor = getForegroundColorFromColorTheme(colorTheme ?? {});
  const backgroundColor = getBackgroundColorFromColorTheme(colorTheme ?? {});

  if (label === undefined) {
    return null;
  }

  return (
    <RibbonWrapper
      backgroundColor={backgroundColor}
      direction={direction}
      className={className}
      data-testid={dataTestid}
    >
      <RibbonContent
        foregroundColor={foregroundColor}
        backgroundColor={backgroundColor}
        direction={direction}
      >
        <Label>{label}</Label>
      </RibbonContent>
    </RibbonWrapper>
  );
};

export { NewRibbon };

import styled from "@emotion/styled/macro";
import {
  MOBILE_BUTTON_SIZE,
  XxlButton,
} from "react-app/src/components/Common/XxlButton/XxlButton";
import { typographyToCss } from "react-app/src/styles/helpers";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

const StickyButton = styled(XxlButton)<{
  isStickyBtnVisible: boolean;
}>`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: ${xxlTheme.zIndex.navigation};

  ${({ isStickyBtnVisible = false }) =>
    !isStickyBtnVisible && `bottom: -${MOBILE_BUTTON_SIZE.large};`}

  & > span {
    position: initial;
  }
`;

const SalesPrice = styled.span`
  ${typographyToCss({
    fontSize: 20,
    fontFamily: "var(--font-family-bold)",
  })}
  margin-left: ${xxlTheme.spaces.micro};
`;

type Props = {
  disabled: boolean;
  onClick: () => void;
  salesPrice?: string;
  loading?: boolean;
  isStickyBtnVisible: boolean;
  children: React.ReactNode;
};

export const StickyAddToCartButton = ({
  children,
  salesPrice,
  ...props
}: Props) => {
  return (
    <StickyButton icon="Plus" {...props}>
      {children}
      <SalesPrice>{salesPrice}</SalesPrice>
    </StickyButton>
  );
};

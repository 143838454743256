import React from "react";
import type { ErrorInfo, ComponentType } from "react";
// eslint-disable-next-line no-restricted-imports
import { withErrorBoundary as WEB } from "react-error-boundary";

// react-error-boundary expects the component props to extend Object, hence the elsint disable.
// eslint-disable-next-line @typescript-eslint/ban-types
function withErrorBoundary<Props extends Object>(
  component: ComponentType<Props>,
  errorProps?: {
    fallback?: JSX.Element;
    onError?: (error: Error, info: ErrorInfo) => void;
  }
) {
  const { fallback, onError } = errorProps ?? {};

  return WEB(component, {
    fallback: fallback ?? <></>,
    onError: onError,
  });
}

export { withErrorBoundary };

import React, { useEffect, useRef, useState } from "react";
import type { Store } from "@xxl/content-api";
import { PROD_SE } from "../../environments";
import { StoreStockSelectedDetailsMapImageStyled } from "../StoreStock/StoreStockStyles";

/**Hard code PROD_SE base url since we use same icon on all markets (will also work in DEV/TEST environments) */
const BASE_URL = PROD_SE;
/**API_KEY - currently used for dev/test/prod. Usage restricted by HTTP referrer.*/
const API_KEY = "AIzaSyBwtN9j-Y7x95PpSiWjP_UiVJL75Hdo9Wk";
const FALLBACK_MAP_URL = `${BASE_URL}/static/images/map-fallback.png`;
const MAP_MAKER_URL = `${BASE_URL}/static/images/map-xxl-icon.png`;
const BLOCK_GOOGLE_MAPS = "blockGoogleMaps";

const StoreStockMap = ({
  selectedStoreDetails,
  mapSize = "500x300",
  mapZoom = 15,
}: {
  selectedStoreDetails: Store;
  mapSize?: string;
  mapZoom?: number;
}) => {
  const selectedStoreDetailsRef = useRef<Store>({});
  const [isGoogleMapsBlocked, setIsGoogleMapsBlocked] = useState(false);
  const [useFallbackMap, setUseFallbackMap] = useState(isGoogleMapsBlocked);

  useEffect(() => {
    const url = new URL(window.location.href);
    setIsGoogleMapsBlocked(!url.searchParams.has(BLOCK_GOOGLE_MAPS));
  }, []);

  selectedStoreDetailsRef.current = selectedStoreDetails;
  const latitude = selectedStoreDetails.location?.latitude?.toString() ?? "";
  const longitude = selectedStoreDetails.location?.longitude?.toString() ?? "";
  const fullCoordinates = `${latitude},${longitude}`;

  const mapArguments = [
    `size=${mapSize}`,
    `zoom=${mapZoom}`,
    `scale=1`,
    `format=png`,
    `maptype=roadmap`,
    `key=${API_KEY}`,
    `markers=size:normal%7Cscale:1%7Cicon:${encodeURI(
      MAP_MAKER_URL
    )}%7C${fullCoordinates}`,
  ];
  const staticMapUrl = `https://maps.googleapis.com/maps/api/staticmap?${mapArguments.join(
    "&"
  )}`;

  return (
    <StoreStockSelectedDetailsMapImageStyled
      src={useFallbackMap ? FALLBACK_MAP_URL : staticMapUrl}
      alt="XXL Map"
      onError={(): void => setUseFallbackMap(true)}
    />
  );
};

export { StoreStockMap };

export default {
  header: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 18,
    lineHeight: 1,
    letterSpacing: -0.15,
  },
  item: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 15,
    lineHeight: 1.2,
    letterSpacing: -0.15,
  },
  price: {
    fontFamily: "var(--font-family-medium)",
    fontSize: 15,
    lineHeight: 1,
    letterSpacing: -0.15,
  },
  showAllLink: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 12,
    lineHeight: 1.2,
    letterSpacing: -0.15,
  },
};

import { userInputEvents } from "../hooks/useUserInteraction/helper";
import { log } from "@xxl/logging-utils";

export type InjectedScriptParam = {
  name: string;
  value: string;
};

export type InjectedScript = {
  src: string;
  onLoad?: () => void;
  async?: boolean;
  id?: string;
  dataParams?: InjectedScriptParam[];
};

type Handler = {
  src: string;
  handler: EventListener;
};

const handlers: Handler[] = [];

const isDocumentReady = (): boolean =>
  ["interactive", "complete"].includes(document.readyState);

const isInitialized = (src: string): boolean =>
  Boolean(document.querySelector(`script[src='${src}']`));

const removeScript = (src: string): void =>
  document.querySelector(`script[src='${src}']`)?.remove();

const hasHandler = (src: string): boolean =>
  Boolean(handlers.find((h) => h.src === src)?.handler);

const ensureExecution = (onDocumentReady: () => void): void =>
  isDocumentReady()
    ? onDocumentReady()
    : document.addEventListener("DOMContentLoaded", onDocumentReady);

const remove = (src: string): void => {
  const handler = handlers.find((h) => h.src === src);
  if (handler?.handler === undefined) {
    return;
  }

  userInputEvents.forEach((eventName) =>
    document.removeEventListener(eventName.toLowerCase(), handler.handler)
  );
};

const injectScript = (args: InjectedScript): void => {
  const { src, onLoad, async = true, id, dataParams } = args;

  if (isInitialized(src)) {
    log.debug(`<script src="${src}"> already exists in DOM`);
    remove(src);
    return;
  }

  const element = document.createElement("script");
  element.src = src;
  if (id !== undefined) {
    element.setAttribute("id", id);
  }
  if (dataParams !== undefined) {
    dataParams.forEach(({ name, value }) => {
      element.setAttribute(name, value);
    });
  }
  if (onLoad !== undefined) {
    element.onload = onLoad;
  }
  if (async) {
    element.setAttribute("async", "true");
  }
  document.body.appendChild(element);

  // Remove event listeners after first invokation
  remove(src);
};

const add = (args: InjectedScript): void => {
  const { src } = args;
  if (hasHandler(src)) {
    return;
  }

  const handler = injectScript.bind(undefined, args);
  handlers.push({
    src,
    handler,
  });
  userInputEvents.forEach((eventName) =>
    document.addEventListener(eventName.toLowerCase(), handler)
  );
};

const loadScript = (script: InjectedScript): void =>
  ensureExecution(add.bind(this, script));

export { injectScript, isInitialized, loadScript, removeScript };

const CACHE_MAX_AGE_IN_SECONDS = 300;
export const CommonCacheControlHeader = `public, s-maxage=${CACHE_MAX_AGE_IN_SECONDS}, max-age=${CACHE_MAX_AGE_IN_SECONDS}`;
export const markets = ["no", "at", "fi", "se", "dk"] as const;
export const XXL_CART_DETAILS_COOKIE_NAME = "cartDetails";
export const XXL_TOGGLE_COOKIE_NAME = "xxl-toggle";
export const FITSTATION_ENABLED_CATEGORY_CODES = new Set([
  "142000",
  "140200",
  "140400",
  "140600",
]);

/* variables used in page title rendering */
export const COMPANY_NAME_KEY = "meta.title.default";
export const DEFAULT_COMPANY_NAME = "XXL";
export const SYMPLIFY_ORIGINAL_KEY = "Original";

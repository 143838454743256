import React from "react";
import { CircleLoader, MiniCartAmount } from "../Styles/MiniCartContent.styled";

type MiniCartCountProps = {
  isLoading: boolean;
  count: number | null;
  testId?: string;
  classNameString?: string;
};

const MAX_TWO_DIGIT_NUMBER = 99;
const MORE_THAN_99_ITEMS_STRING = `${MAX_TWO_DIGIT_NUMBER}+`;

export const MiniCartCount: React.FunctionComponent<MiniCartCountProps> = ({
  isLoading,
  count,
  testId = "",
  classNameString = "",
}) => {
  if (count === null) {
    return <MiniCartAmount className={classNameString} />;
  }

  if (isLoading) {
    return (
      <MiniCartAmount className={classNameString}>
        <CircleLoader className={"test-mini-cart_basket-add-icon"} />
      </MiniCartAmount>
    );
  }

  const isThreeDigitNumber = count > MAX_TWO_DIGIT_NUMBER;
  if (isThreeDigitNumber) {
    return (
      <MiniCartAmount className={classNameString} isBigAmount={true}>
        <span data-testid={testId}>{MORE_THAN_99_ITEMS_STRING}</span>
      </MiniCartAmount>
    );
  }

  return (
    <MiniCartAmount className={classNameString}>
      <span data-testid={testId}>{count}</span>
    </MiniCartAmount>
  );
};

import * as React from "react";
import {
  GhostButtonWrapper,
  SuccessContainer,
  SuccessButton,
} from "./SignUpSuccess.styled";
import {
  DialogBoxHeaderWrapper,
  DialogBoxHeaderCloseButton,
} from "../../../DialogBox/DialogBox.styled";
import {
  FormHeaderInModal,
  SignUpErrorMessage,
} from "../SignUpForm/SignupForm.styled";

type SignUpSuccessData = {
  header: string;
  info: string;
  buttonText: string;
};

type SignUpSuccess = {
  signUpSuccessTranslations: SignUpSuccessData;
  failedLoginTranslation?: string;
  handleDialogBoxClosing?: () => void;
  styles?: React.CSSProperties;
};

const goToHomepage = (): void => {
  document.location.href = "/";
};

export const SignUpSuccess: React.FunctionComponent<SignUpSuccess> = ({
  signUpSuccessTranslations,
  handleDialogBoxClosing,
  failedLoginTranslation,
  styles,
}) => (
  <SuccessContainer style={styles} data-testid={"reward-success"}>
    <DialogBoxHeaderWrapper>
      <FormHeaderInModal>{signUpSuccessTranslations.header}</FormHeaderInModal>
      {handleDialogBoxClosing !== undefined && (
        <DialogBoxHeaderCloseButton
          aria-label="close"
          onClick={handleDialogBoxClosing}
          src="/static/svg/close-icon-slim.svg"
          alt="close icon"
          data-inline-svg={true}
          data-testid="dialog-close-btn"
        />
      )}
    </DialogBoxHeaderWrapper>
    <p>{signUpSuccessTranslations.info}</p>
    {failedLoginTranslation && (
      <SignUpErrorMessage>{failedLoginTranslation}</SignUpErrorMessage>
    )}
    <GhostButtonWrapper>
      <SuccessButton
        onClick={goToHomepage}
        className="button button--primary button--small"
        inline={false}
      >
        {signUpSuccessTranslations.buttonText}
      </SuccessButton>
    </GhostButtonWrapper>
  </SuccessContainer>
);

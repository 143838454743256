import styled from "@emotion/styled/macro";
import { xxlTheme } from "../../../styles/xxl-theme";
import { MQ } from "../../../styles/helpers";

const { spaces, colors } = xxlTheme;

export const VariantServiceProductsList = styled.ul`
  display: grid;
  padding: 0;
  }
`;

export const Ul = styled.ul`
  display: grid;
  grid-gap: ${spaces.miniMicro};
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const Li = styled.li`
  display: flex;
  flex-direction: row;
  paddingx: ${spaces.micro};
  gap: ${spaces.regular};
`;

export const DialogFooter = styled.div`
  display: inline;
  position: fixed;
  bottom: 0;
  left: 0;
  width: fill-available;
  background-color: ${colors.xxlWhite};
  border-top: 1px solid ${colors.xxlLightGrey};
  box-shadow: 0 -5px 5px -5px ${colors.xxlLightGrey};
  padding: ${spaces.smallRegular};

  ${MQ("tablet")} {
    display: none;
  }
`;

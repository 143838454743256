export const showStickyHeader = (): void => {
  if (typeof window === "undefined") {
    return;
  }

  const reactHeaderElement = document.getElementById("js-react-header");
  const stickyHeaderElement = document.getElementById("js-sticky-header");

  if (reactHeaderElement !== null) {
    reactHeaderElement.classList.remove("header-hidden");
  }
  if (stickyHeaderElement !== null) {
    stickyHeaderElement.classList.remove("header-hidden");
  }
};

export default {
  readDescriptionButton: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 12,
    lineHeight: 1.4,
    letterSpacing: -0.1,
  },
  price: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 34,
    lineHeight: 1,
    letterSpacing: -0.1,
  },
  serviceName: {
    fontSize: 15,
    lineHeight: 1.2,
    letterSpacing: -0.1,
  },
  error: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 12,
    lineHeight: 1.2,
    letterSpacing: -0.1,
  },
};

import React from "react";
import type { PropsWithChildren } from "react";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import type { TranslationKey } from "react-app/src/translations";
import {
  StockLevels,
  type StockLevel,
} from "react-app/src/components/ClickAndCollect/api/api";
import { StockStatusWrapper } from "./ProductSizeSelector.styled";
import { Availability } from "./Availability";
import { getStoreTranslationText } from "./ProductSizeSelector.helper";
import { usePdpContext } from "@/react-components/ProductDetailsPage/ProductDetailsPage.state";

const useOnlineTranslationKey = (stockStatus: StockLevel) => {
  const { t } = useTranslations();
  const translationKey = {
    [StockLevels.IN_STOCK]: "general.available.online.many",
    [StockLevels.LOW_STOCK]: "general.available.online.few",
    [StockLevels.OUT_OF_STOCK]: "general.available.online.none",
  }[stockStatus];

  return t(translationKey as TranslationKey);
};

type Props = PropsWithChildren<{
  onlineStockStatus: StockLevel;
  generalStockStatus: StockLevel;
  isClickAndCollectEnabled: boolean;
  isLoading: boolean;
  preferredStoreName?: string;
  preferredStoreIds: string[];
  storeStockStatus: StockLevel;
  storesWithStockCount?: number;
}>;

export const StockStatusComponent = ({
  children,
  generalStockStatus,
  isClickAndCollectEnabled,
  isLoading,
  onlineStockStatus,
  preferredStoreName,
  preferredStoreIds,
  storeStockStatus,
  storesWithStockCount = 0,
}: Props) => {
  const { t } = useTranslations();
  const { state } = usePdpContext();
  const onlineStockStatusText = useOnlineTranslationKey(onlineStockStatus);
  const generalStockStatusText = useOnlineTranslationKey(generalStockStatus);

  const hideStoreStockStatus = preferredStoreIds.length === 0;

  if (preferredStoreName === undefined) {
    return (
      <StockStatusWrapper>
        <XxlStack direction={"row"} alignItems={"center"}>
          <Availability
            isLoading={isLoading}
            stockStatus={generalStockStatus}
            text={generalStockStatusText}
          />
          {children}
        </XxlStack>
      </StockStatusWrapper>
    );
  }

  const storeStockStatusText = getStoreTranslationText(
    t,
    preferredStoreName,
    storesWithStockCount,
    storeStockStatus,
    preferredStoreIds.length,
    state
  );

  return (
    <StockStatusWrapper>
      <XxlStack direction={"row"} alignItems={"center"}>
        <Availability
          isLoading={isLoading}
          stockStatus={onlineStockStatus}
          text={onlineStockStatusText}
        />
        {children}
      </XxlStack>
      {isClickAndCollectEnabled && !hideStoreStockStatus && (
        <Availability
          isLoading={isLoading}
          stockStatus={storeStockStatus}
          text={storeStockStatusText}
        />
      )}
    </StockStatusWrapper>
  );
};

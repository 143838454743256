import styled from "@emotion/styled/macro";

/**
 * Visually hides the element by placing it off the screen.
 * The element will still be available to screen readers.
 * Courtesy of https://webaim.org/techniques/css/invisiblecontent/
 */
export const ScreenReaderOnly = styled.div`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

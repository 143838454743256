import React from "react";
import styled from "@emotion/styled/macro";
import { typographyToCss } from "../../../styles/helpers";
import text from "./MegaMenu.typography";

const SpanStyled = styled.span`
  ${typographyToCss(text.banner)};
  color: currentColor;
  display: block;
`;

type BannerProps = {
  text: string;
};

export const Banner: React.FunctionComponent<BannerProps> = ({ text }) => (
  <SpanStyled>{text}</SpanStyled>
);

import React, { useEffect, useState } from "react";
import type { MouseEvent } from "react";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { Line, Link, Hamburger } from "./MobileMenuButton.styled";
import {
  addXXLEventListener,
  dispatchEvent,
  removeXXLEventListener,
} from "../../../utils/xxl-event";

const MobileMenuButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslations();

  const handleClickMenuLink = (event: MouseEvent) => {
    event.preventDefault();
    setIsOpen(!isOpen);
  };

  const translationKey = isOpen ? "header.menu.close" : "header.menu.open";
  const ariaLabel = t(translationKey);

  useEffect(() => {
    dispatchEvent(isOpen ? "XXL_MEGAMENU_OPEN" : "XXL_MEGAMENU_CLOSE");
  }, [isOpen]);

  useEffect(() => {
    const closeMenuEventListener = () => setIsOpen(false);
    addXXLEventListener("XXL_MEGAMENU_CLOSE", closeMenuEventListener);
    return () =>
      removeXXLEventListener("XXL_MEGAMENU_CLOSE", closeMenuEventListener);
  });

  return (
    <nav>
      <Link aria-label={ariaLabel} href="#" onClick={handleClickMenuLink}>
        <Hamburger open={isOpen}>
          <Line />
          <Line />
          <Line />
        </Hamburger>
      </Link>
    </nav>
  );
};

export { MobileMenuButton };

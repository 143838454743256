import React, { useState, useEffect } from "react";
import type { ProductData } from "@xxl/frontend-api";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import {
  addServiceToEgCart,
  SUCCESS_RESPONSE_STATUS,
  updateProductsQuantity,
} from "../Cart/Api/CartAPI";
import {
  useCartContext,
  SET_CART_COUNT,
  UPDATE_CART_COUNT,
} from "../Cart/CartState";
import {
  getAddedServiceId,
  getAddedServiceItemId,
  getAddedServiceQuantity,
} from "./serviceProductsHelper";
import type { GenericGraphQLError } from "../Cart/Api/types";
import { useSharedData } from "../../contexts/SharedData";
import { StyledCheckbox } from "../../styled";
import { useServicesContext, SERVICES } from "./ServiceProductsContext";
import { log } from "@xxl/logging-utils";

type ToggleServiceProductInCartButtonProps = {
  item: ProductData;
  actionInProgress: boolean;
  onChange: (value: boolean) => void;
  onAddToCart: () => void;
  onRemoveFromCart: () => void;
  disabled?: boolean;
};

export const ToggleServiceProductInCartButton: React.FunctionComponent<
  ToggleServiceProductInCartButtonProps
> = ({
  item,
  disabled = false,
  actionInProgress,
  onChange,
  onAddToCart,
  onRemoveFromCart,
}) => {
  const { t } = useTranslations();
  const { state, dispatch } = useServicesContext();
  const cartDispatch = useCartContext().dispatch;
  const [isDisabled, setIsDisabled] = useState(disabled);
  const [isLoading, setIsLoading] = useState(false);
  const { configuration } = useSharedData().data;
  const [isChecked, setIsChecked] = useState(false);
  const { isProductPage } = state;

  const handleAddToCartClick = async () => {
    if (
      item.sizeOptions === undefined ||
      item.sizeOptions.length === 0 ||
      actionInProgress
    ) {
      return;
    }

    setIsLoading(true);
    onChange(true);

    const eanCode = item.ean !== undefined ? item.ean[0] : "";
    const response = await addServiceToEgCart(
      eanCode,
      "1",
      state.productCartEntryId,
      configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
      configuration.amplifyConfig.aws_appsync_apiKey
    );
    const { status, data } = response;

    if (status === SUCCESS_RESPONSE_STATUS) {
      setIsLoading(false);
      setIsChecked(true);
      cartDispatch({
        type: SET_CART_COUNT,
        payload: {
          count: data.data?.addServiceProductsToCartItems.totals
            .itemsCount as number,
        },
      });
      setTimeout(() => {
        onChange(false);
        if (isProductPage === true) {
          dispatch({
            type: SERVICES.ADDED,
            payload: {
              serviceAdded: eanCode,
              serviceEntryNumber: getAddedServiceId(
                data.data?.addServiceProductsToCartItems.items,
                item.ean,
                window.xxlServiceProducts.cartEntryItemId
              ),
              parentId: getAddedServiceItemId(
                data.data?.addServiceProductsToCartItems.items,
                item.ean,
                window.xxlServiceProducts.cartEntryItemId
              ),
              addedServiceQuantity: getAddedServiceQuantity(
                data.data?.addServiceProductsToCartItems.items,
                item.ean,
                window.xxlServiceProducts.cartEntryItemId
              ),
            },
          });
        }
      }, 1000);
      onAddToCart();
    } else if (response.data.errors !== undefined) {
      const { errors } = response.data;
      dispatch({
        type: SERVICES.HAS_ERROR,
        payload: errors as GenericGraphQLError[],
      });
      setIsLoading(false);
      onChange(false);
    }
  };

  const handleRemoveFromCartClick = async () => {
    if (actionInProgress) {
      return;
    }

    setIsLoading(true);
    const { serviceEntryNumber, addedServiceQuantity } = state;
    if (
      serviceEntryNumber !== undefined &&
      typeof serviceEntryNumber === "number"
    ) {
      const response = await updateProductsQuantity(
        addedServiceQuantity !== undefined && addedServiceQuantity > 0
          ? String(addedServiceQuantity - 1)
          : "0",
        {
          id: serviceEntryNumber,
          type: "SERVICE",
        },
        configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
        configuration.amplifyConfig.aws_appsync_apiKey
      );

      if (response.status === "SUCCESS") {
        dispatch({
          type: SERVICES.REMOVED,
        });
        setIsLoading(false);
        setIsChecked(false);

        if (!isProductPage) {
          dispatch({
            type: SERVICES.DECREASE_QUANTITY,
          });
        }
        onRemoveFromCart();
        cartDispatch({
          type: UPDATE_CART_COUNT,
        });
      } else {
        log.error("Cannot remove service from cart");
        setIsLoading(false);
        onRemoveFromCart();
        cartDispatch({
          type: UPDATE_CART_COUNT,
        });
      }
    }
  };

  useEffect(() => {
    if (
      state.serviceAdded !== undefined &&
      item.sizeOptions !== undefined &&
      (item.sizeOptions[0].code === state.serviceAdded ||
        (item.ean !== undefined && item.ean[0] === state.serviceAdded))
    ) {
      setIsDisabled(false);
    } else if (state.serviceAdded !== undefined || disabled) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }

    const isSelected =
      (item.sizeOptions !== undefined &&
        state.serviceAdded === item.sizeOptions[0].code) ||
      (item.sizeOptions !== undefined &&
        item.ean !== undefined &&
        state.serviceAdded === item.ean[0]);
    setIsChecked(isSelected);
  }, [item.sizeOptions, state.serviceAdded, disabled, item.ean]);

  return (
    <StyledCheckbox
      label={t("services.popup.add.service.label")}
      handleChange={(_ev, checked) => {
        checked
          ? void handleAddToCartClick()
          : void handleRemoveFromCartClick();
      }}
      isDisabled={isDisabled || isLoading}
      checked={isChecked}
      testId="add-to-cart-button"
      isLoading={isLoading}
    />
  );
};

import React from "react";
import styled from "@emotion/styled/macro";
import { CheckSvg } from "./CheckSvg";

const Container = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: inline;
  background: inherit;
  pointer-events: none;
`;

const Image = styled(CheckSvg)`
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  width: 20px;
  z-index: 1000;
`;

export const Checkmark: React.FunctionComponent = (): JSX.Element => (
  <Container>
    <Image />
  </Container>
);

import styled from "@emotion/styled/macro";
import { xxlTheme } from "../../styles/xxl-theme";

const { colors } = xxlTheme;

export const Wrapper = styled.div`
  display: grid;
  height: 74px;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
`;

export const BackButtonWrapper = styled.div`
  color: ${colors.xxlWhite};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BackButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${colors.xxlWhite};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: ${colors.xxlWhite};
  padding: 0;

  & svg {
    width: 20px;
    height: 20px;
  }

  &:hover,
  &:focus-visible {
    cursor: pointer;
  }
`;

import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { typographyToCss } from "../../../styles/helpers";
import { xxlTheme } from "../../../styles/xxl-theme";
import typography from "./CartPage.typography";

const { showMore } = typography;

const { colors, spaces } = xxlTheme;
export const DesktopListHeightInteger = 550;
const DesktopListHeight = `${DesktopListHeightInteger}px`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

type ListWrapperProps = {
  isEditing: boolean;
};

export const ListWrapper = styled.div<ListWrapperProps>(
  ({ isEditing }) => css`
    position: relative;

    ${isEditing &&
    css`
      pointer-events: none;
      filter: blur(5px);
    `}
  `
);

type HeightProps = {
  maxListHeight?: number;
};

export const ScrollWrapper = styled.div<HeightProps>(
  ({ maxListHeight }) => css`
    position: relative;
    max-height: ${maxListHeight !== undefined
      ? `${maxListHeight}px`
      : DesktopListHeight};
    overflow-x: hidden;
    overflow-y: auto;
    transition: all 300ms ease-in-out;
  `
);

export const ShadowsContainer = styled.div<HeightProps>(
  ({ maxListHeight }) => css`
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    max-height: ${maxListHeight !== undefined
      ? `${maxListHeight}px`
      : DesktopListHeight};
    pointer-events: none;
    transition: all 300ms ease-in-out;
  `
);

type ShowMoreButtonProps = {
  isToggled: boolean;
};

export const ShowMoreButton = styled.button<ShowMoreButtonProps>(
  ({ isToggled }) => css`
    width: 100%;
    height: 46px;
    padding: ${spaces.smallRegular};
    background-color: ${colors.xxlWebBlack};
    color: ${colors.xxlWhite};
    ${typographyToCss(showMore)};
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;

    &:hover {
      cursor: pointer;
    }

    & svg {
      width: 12px;
      height: 7px;
      transition: 0.2s ease;

      ${isToggled &&
      css`
        transform: rotate(180deg);
      `}
    }
  `
);

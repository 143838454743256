import styled from "@emotion/styled/macro";
import { xxlTheme } from "../../styles/xxl-theme";
import { MQ, typographyToCss } from "../../styles/helpers";
import { css } from "@emotion/react";
import { resolutionSizes } from "../../config";

const { colors, spaces, typography } = xxlTheme;
const serviceCardOuterPadding = spaces.large;
const ctaButtonWith = "240px";

type ButtonProps = {
  revertedColor?: boolean;
};

export const Header = styled.h3`
  ${typographyToCss(typography.hugeBold)}
  margin: ${spaces.smallRegular} ${spaces.large};
  line-height: 28px;
  padding: 0;
`;

export const Wrapper = styled.div`
  position: relative;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-bottom: 148px;

  ${MQ("muiTablet")} {
    max-height: 50vh;
    margin-bottom: 0;
  }
`;

export const Content = styled.div`
  border: 1px solid ${colors.xxlLightGrey};
  padding: 0 ${serviceCardOuterPadding};
`;

export const ProductPageButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: ${spaces.mini};
  padding: ${spaces.smallRegular};

  ${MQ("muiTablet")} {
    grid-template-columns: [left] minmax(0, 1fr) [right] minmax(0, 1fr);
    column-gap: ${spaces.smallRegular};
    padding: ${spaces.large};
  }
`;

export const CartPageButtonsWrapper = styled(ProductPageButtonsWrapper)`
  ${MQ("muiTablet")} {
    grid-template-columns: 1fr;
  }
`;

export const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: ${colors.xxlWhite};
  width: 100%;
  border-top: 1px solid ${colors.xxlLightGrey};

  padding: ${spaces.smallRegular};

  ${MQ("muiTablet")} {
    position: relative;
    bottom: auto;
    left: auto;
  }
`;

export const Button = styled.button<ButtonProps>(
  ({ revertedColor }) => css`
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${revertedColor === true
      ? colors.xxlWebBlack
      : colors.xxlWhite};
    border: 1px solid
      ${revertedColor === true ? colors.xxlWebBlack : colors.xxlLightGrey};
    color: ${revertedColor === true ? colors.xxlWhite : colors.xxlWebBlack};
    transition: 0.2s ease;
    flex-grow: 1;

    &:hover {
      cursor: pointer;
      background-color: ${revertedColor === true
        ? colors.xxlGreen
        : colors.xxlWebBlack};
      color: ${revertedColor === true ? colors.xxlWebBlack : colors.xxlWhite};
      border-color: ${revertedColor === true
        ? colors.xxlGreen
        : colors.xxlWebBlack};
    }
  `
);

export const CloseButton = styled(Button)`
  margin: 0 0 0 auto;
  width: 100%;

  ${MQ("muiTablet")} {
    width: ${ctaButtonWith};
  }

  &[disabled] {
    pointer-events: none;
    background-color: ${colors.xxlLightGrey};
    border-color: ${colors.xxlGrey};
    color: ${colors.xxlGrey};
  }
`;

export const Title = styled.h4`
  ${typographyToCss(typography.mediumBold)}
  margin-bottom: ${spaces.regular};

  @media (min-width: ${resolutionSizes.mobile}px) {
    margin-bottom: ${spaces.mini};
  }
`;

type ShadowProps = {
  isVisible: boolean;
};

const ShadowCommonStyles = () => css`
  position: absolute;
  width: 100%;
  height: ${spaces.mini};
  transition: opacity 0.3s;
`;

export const ShadowTop = styled.div<ShadowProps>(
  ({ isVisible }) => css`
    ${ShadowCommonStyles()};
    background: linear-gradient(0deg, transparent, rgba(0, 0, 0, 0.1));
    top: 0;
    opacity: ${isVisible ? 1 : 0};
  `
);

export const ShadowBottom = styled.div<ShadowProps>(
  ({ isVisible }) => css`
    ${ShadowCommonStyles()};
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), transparent);
    bottom: 0;
    opacity: ${isVisible ? 1 : 0};
  `
);

export const ShadowsContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  max-height: 100vh;
  pointer-events: none;

  ${MQ("muiTablet")} {
    max-height: 50vh;
  }
`;

export const ListWrapper = styled.div`
  position: relative;
`;

const numberOfColumnsOnSmallScreen = 1;
const numberOfColumnsOnMediumScreen = 2;
const numberOfColumnsOnBigScreen = 3;
type ServiceProductListProps = {
  numberOfItems: number;
};
export const ServiceProductsList = styled.ul<ServiceProductListProps>(
  ({ numberOfItems = 3 }) => css`
    display: grid;
    grid-template-columns: repeat(${numberOfColumnsOnSmallScreen}, 1fr);
    gap: ${spaces.smallRegular};
    padding: 0;

    @media (min-width: ${resolutionSizes.mobile}px) {
      grid-template-columns: repeat(
        ${numberOfItems <= numberOfColumnsOnBigScreen
          ? numberOfItems
          : numberOfColumnsOnBigScreen},
        ${numberOfItems === 1 ? "50%" : "1fr"}
      );

      @media (max-width: ${resolutionSizes.tablet}px) {
        grid-template-columns: repeat(
          ${numberOfItems <= numberOfColumnsOnMediumScreen
            ? numberOfItems
            : numberOfColumnsOnMediumScreen},
          1fr
        );
      }
    }
  `
);

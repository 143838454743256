import { isNotNullOrUndefined } from "@xxl/common-utils";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { Skeleton } from "../Common";
import type { SelectBoxOption } from "../Common/SelectBox";
import { SelectBox } from "../Common/SelectBox";
import { CrossSalesSkeletonWrapper, OptionsWrapper } from "./CrossSales.styled";
import { mapSizeToOption, mapStyleToOption } from "./utils";
import type { StockStatus } from "../../utils/data-types";

type Style = {
  code?: string;
  colorName?: string;
  name?: string;
  stockStatus?: StockStatus;
};

type Size = {
  code?: string;
  ean?: string;
  size?: string;
  stockStatus?: StockStatus;
};

type CrossSalesOptionsSelectorProps = {
  addToCartError?: string;
  handleSelectStyle: (style: Style) => void;
  isSizeLoading: boolean;
  selectedSize?: Size;
  selectedStyle?: Style;
  setSelectedSize: (size: Size) => void;
  sizeOptions: Size[];
  styleOptions: Style[];
};

export const CrossSalesOptionsSelector: React.FC<
  CrossSalesOptionsSelectorProps
> = ({
  addToCartError,
  handleSelectStyle,
  isSizeLoading,
  selectedSize,
  selectedStyle,
  setSelectedSize,
  sizeOptions,
  styleOptions,
}) => {
  const { t } = useTranslations();

  const initialSizeValue = isNotNullOrUndefined(selectedSize)
    ? mapSizeToOption(selectedSize)
    : undefined;
  const initialStyleValue = isNotNullOrUndefined(selectedStyle)
    ? mapStyleToOption(selectedStyle, 0)
    : undefined;
  const hasSizeOptions = sizeOptions.length > 1;

  const mappedSizeOptions = sizeOptions.map(mapSizeToOption);
  const mappedStyleOptions = styleOptions.map(mapStyleToOption);
  const hasStyleOptions = mappedStyleOptions.length > 1;

  const selectorHeight = 50;

  return (
    <OptionsWrapper>
      {isSizeLoading && (
        <CrossSalesSkeletonWrapper>
          <Skeleton height={selectorHeight} />
        </CrossSalesSkeletonWrapper>
      )}
      {hasStyleOptions && (
        <SelectBox
          id={"crossSales.label.style"}
          isMandatory={true}
          onSelect={({ value }: SelectBoxOption<Style>): void => {
            if (isNotNullOrUndefined(value)) {
              handleSelectStyle(value);
            }
          }}
          placeholder={t("product.details.select.style")}
          options={mappedStyleOptions}
          errorMessage={addToCartError}
          initialValue={initialStyleValue}
        />
      )}
      {hasSizeOptions && (
        <SelectBox
          id={"crossSales.label.size"}
          isMandatory={true}
          onSelect={({ value }): void => {
            if (isNotNullOrUndefined(value)) {
              setSelectedSize(value);
            }
          }}
          placeholder={t("product.details.select.size")}
          initialValue={initialSizeValue}
          options={mappedSizeOptions}
          errorMessage={addToCartError}
        />
      )}
    </OptionsWrapper>
  );
};

import styled from "@emotion/styled/macro";
import { xxlTheme } from "../../../../styles/xxl-theme";
import { MQ } from "../../../../styles/helpers";
import { Button } from "../../../../styled/Button.styled";

const { colors, spaces } = xxlTheme;

export const GhostButtonWrapper = styled.div`
  padding-top: 42px;
  margin-top: auto;
`;

export const SuccessContainer = styled.div`
  background-color: ${colors.xxlWhite};
  padding: ${spaces.large} ${spaces.smallRegular};
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  ${MQ("tablet")} {
    padding: ${spaces.huge};
  }

  ${MQ("tabletHorizontal")} {
    box-sizing: border-box;
    width: 50%;
    padding: ${spaces.large};
  }
`;

export const SuccessHeader = styled.h1`
  margin: 0 0 ${spaces.large};
  line-height: 0.7;
`;

export const SuccessButton = styled(Button)`
  width: 100%;

  ${MQ("tablet")} {
    width: 50%;
  }

  ${MQ("tabletHorizontal")} {
    width: 100%;
  }
`;

import { isNotNullOrUndefined } from "@xxl/common-utils";
import { CrossSalesProductDescriptionStockStatus } from "./CrossSalesProductDescriptionStockStatus";
import { SizeColorInfo } from "./CrossSales.styled";
import type { StockStatus } from "../../utils/data-types";

type CrossSalesProductColorAndSizeProps = {
  selectedSize?: {
    size?: string;
    stockStatus?: StockStatus;
  };
  selectedStyle?: {
    colorName?: string;
  };
};

export const CrossSalesProductColorAndSize: React.FC<
  CrossSalesProductColorAndSizeProps
> = ({ selectedSize, selectedStyle }) => {
  const hasSomeOptions =
    isNotNullOrUndefined(selectedStyle) || isNotNullOrUndefined(selectedSize);

  const colourName =
    isNotNullOrUndefined(selectedStyle) &&
    isNotNullOrUndefined(selectedStyle.colorName)
      ? selectedStyle.colorName
      : null;

  const separator = isNotNullOrUndefined(selectedStyle?.colorName) ? (
    <>&nbsp;|&nbsp;</>
  ) : null;

  const size = isNotNullOrUndefined(selectedSize) ? selectedSize.size : null;

  if (!hasSomeOptions) {
    return null;
  }

  return (
    <SizeColorInfo>
      {colourName}
      {separator}
      {size}
      <CrossSalesProductDescriptionStockStatus selectedSize={selectedSize} />
    </SizeColorInfo>
  );
};

/**
 * This is the module version of storefront/application/src/main/resources/frontend/scripts/toggle-scrollbar.ts
 */

const toggleScrollbar = (() => {
  const showScrollbar = function (): void {
    document.body.classList.remove("site__scroll-hidden");
    document.body.style.marginRight = "0";
  };

  const hideScrollbar = function (): void {
    const { left, right } = document.body.getBoundingClientRect();
    const marginWidth = window.innerWidth - (left + right);
    document.body.style.marginRight = `${marginWidth}px`;
    document.body.classList.add("site__scroll-hidden");
  };

  return {
    showScrollbar,
    hideScrollbar,
  };
})();

export { toggleScrollbar };

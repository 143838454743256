import { useStateValue } from "cotton-box-react";
import { useSessionSource } from "../../contexts/Session";
import { useSharedData } from "../../contexts/SharedData";
import {
  getCustomerKey,
  getFallbackCustomerKey,
  getFallbackSessionKey,
  getSessionKey,
} from "../../utils/Cookie";
import {
  getSiteHost,
  legacySiteUidToSiteUid,
} from "../../utils/xxl-shared-data";
import { useXxlMediaQuery } from "../useXxlMediaQuery";

const useElevateRequestData = () => {
  const isMobile = useXxlMediaQuery("MobileMediaQuery");
  const isLoggedIn = useStateValue(useSessionSource);
  const { siteUid } = useSharedData().data;

  const getBaseQuery = () => {
    return {
      customerKey: getCustomerKey() ?? getFallbackCustomerKey(),
      priceId: isLoggedIn ? ("anonymous" as const) : ("member" as const),
      sessionKey: getSessionKey() ?? getFallbackSessionKey(),
      site: getSiteHost(legacySiteUidToSiteUid(siteUid)),
      touchpoint: isMobile ? ("MOBILE" as const) : ("DESKTOP" as const),
    };
  };

  return {
    getBaseQuery,
  };
};

export { useElevateRequestData };

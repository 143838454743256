import styled from "@emotion/styled/macro";
import { ButtonStyledAsLink, Button } from "../../../../styled/Button.styled";
import { xxlTheme } from "../../../../styles/xxl-theme";
import { MQ, typographyToCss } from "../../../../styles/helpers";
import { PhoneCheck } from "@xxl/icons";
import { SpaceBetweenFlex } from "../../../../styled/Common.styled";
import ReCAPTCHA from "react-google-recaptcha";

const { colors, spaces } = xxlTheme;

const registerTypography = typographyToCss({
  fontSize: 20,
  lineHeight: 1,
  fontWeight: 900,
});

const RegisterContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: ${spaces.smallRegular};
`;

const RegisterCTA = styled.p`
  margin: 0;
  margin-right: ${spaces.micro};
  color: ${colors.xxlWebBlack};
  ${registerTypography}
`;

const RegisterLink = styled.a`
  text-decoration: underline;
  color: ${colors.xxlWebBlack};
  ${registerTypography}
`;

type FormContainerProps = {
  inProgress: boolean;
  fullWidth?: boolean;
};
const FormContainer = styled.form<FormContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  pointer-events: ${(props): string => (props.inProgress ? "none" : "auto")};
  width: 100%;
  padding: ${(props): string =>
    props.fullWidth === true ? "0" : `${spaces.large} ${spaces.smallRegular}`};
  box-sizing: border-box;
  background-color: ${colors.xxlWhite};

  ${typographyToCss({
    fontSize: 10,
    tablet: {
      fontSize: 12,
    },
  })}

  ${MQ("tablet")} {
    width: ${(props): string => (props.fullWidth === true ? "100%" : "50%")};
    padding: ${(props): string =>
      props.fullWidth === true ? "0" : spaces.large};
  }

  h2 {
    margin: 0;
    margin-top: ${(props): string =>
      props.fullWidth === true ? spaces.smallRegular : "0"};
  }
`;

const ForgotPasswordWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${spaces.mini} 0 ${spaces.regular};

  &&& button {
    ${typographyToCss(xxlTheme.typography.smallMedium)}
  }
`;

const LoginButton = styled(Button)`
  margin-top: 30px;
`;

const LoginSuccessIcon = styled(PhoneCheck)`
  width: 90px;
  height: auto;
`;

const ResendButton = styled(ButtonStyledAsLink)`
  text-transform: uppercase;
  text-decoration: none;
  ${typographyToCss(xxlTheme.typography.smallMedium)}
`;
const OTPCountdownResentWrapper = styled(SpaceBetweenFlex)`
  ${typographyToCss(xxlTheme.typography.smallRegular)}
  margin: ${spaces.smallLarge} 0;
`;

const OTPReCaptcha = styled(ReCAPTCHA)`
  margin-bottom: ${spaces.regular};
`;

const OTPText = styled.p`
  margin-bottom: 0;
`;

const ForgotPasswordText = styled.p`
  margin-bottom: 0;
`;

const ForgotPasswordSuccessText = styled.p`
  margin-top: 0;
  margin-bottom: ${spaces.regular};
`;

export {
  FormContainer,
  LoginButton,
  RegisterContainer,
  RegisterCTA,
  RegisterLink,
  ForgotPasswordText,
  ForgotPasswordSuccessText,
  ForgotPasswordWrapper,
  LoginSuccessIcon,
  ResendButton,
  OTPCountdownResentWrapper,
  OTPReCaptcha,
  OTPText,
};

import React, { useState, useEffect, useReducer } from "react";
import type { CartItem as CartItemData } from "../Api/types";
import { useCartContext } from "../CartState";
import { ListItem, Overlay } from "../Styles/ProductItem.styled";
import { XXLLoader } from "../../XXLLoader";
import { CartItem } from "./CartItem";
import { CartServiceProducts } from "./CartServiceProducts";
import {
  CartServiceProductsContext,
  getInitialState,
  reducer,
} from "../CartServiceProductsState";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { ServicesContextProvider } from "../../../components/Services/ServiceProductsContext";

type ProductItemProps = {
  item: CartItemData;
};

export const ProductItem: React.FunctionComponent<ProductItemProps> = ({
  item,
}) => {
  const [servicesState, dispatch] = useReducer(
    reducer,
    getInitialState(item.productServices)
  );
  const { state } = useCartContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isServicesModalOpen, setIsServicesModalOpen] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, [state]);

  return (
    <ListItem hasErrors={item.errors !== undefined && item.errors.length > 0}>
      {isLoading && (
        <Overlay>
          <XXLLoader />
        </Overlay>
      )}
      <CartItem item={item} onChange={setIsLoading} />
      {item.hasService === true &&
        isNotNullOrUndefined(item.productServices) && (
          <>
            <ServicesContextProvider>
              <CartServiceProductsContext.Provider
                value={{ state: servicesState.current, dispatch }}
              >
                <CartServiceProducts
                  product={item.productCode}
                  category={item.category}
                  productSize={item.selectedSize}
                  productEntryNumber={String(item.entryNumber)}
                  productQuantity={item.quantity ?? 1}
                  isOpen={isServicesModalOpen}
                  onClose={() => setIsServicesModalOpen(false)}
                  onOpen={setIsLoading}
                  cartServices={item.productServices}
                  parentProductItemId={{
                    id: item.entryNumber,
                    type: item.type,
                  }}
                />
              </CartServiceProductsContext.Provider>
            </ServicesContextProvider>
            {item.productServices.map((service) => (
              <CartItem
                key={service.entryNumber}
                item={service}
                onChange={setIsLoading}
                productQuantity={item.quantity}
                servicesQuantity={item.servicesQuantity}
                onServiceEditClick={() => setIsServicesModalOpen(true)}
              />
            ))}
          </>
        )}
    </ListItem>
  );
};

import { QUANTITY_ONE } from "../../constants";

export const DEFAULT_RETURN_QUANTITY = QUANTITY_ONE;

export const bundleLineImageSizeInPx = 40;
export const bundleLineGridAreas = {
  image: "image",
  info: "info",
  settings: "settings",
  price: "price",
} as const;

import React from "react";
import type { FunctionComponent } from "react";
import { Link } from "./Link.styled";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import type { TranslationKey } from "../../../translations";
import { MAX_LINKS_PER_SECTION } from "./config";
import { CaretRight } from "@xxl/icons";

export type Link = {
  name: string;
  url: string;
  iconLink?: boolean;
  iconCode?: string;
};
type LinkListProps = {
  links: Link[];
  cutoff?: number;
  showMoreLabel?: TranslationKey;
  showMoreLink?: string;
};

const LinkListTemporary: FunctionComponent<LinkListProps> = ({
  links = [],
  cutoff = MAX_LINKS_PER_SECTION,
  showMoreLabel = "mega.menu.see.more",
  showMoreLink = "#",
}) => {
  const { t } = useTranslations();
  const hasMore = links.length > cutoff;
  const actualCutoff = hasMore ? cutoff - 1 : cutoff;
  links = links.slice(0, actualCutoff);

  return (
    <>
      {links.map(({ name, url }) => (
        <Link key={`${name}-${url}`} href={url}>
          {name}
        </Link>
      ))}

      {hasMore && (
        <Link iconLink={true} href={showMoreLink}>
          {t(showMoreLabel)}
          <CaretRight fontSize={20} />
        </Link>
      )}
    </>
  );
};

export { LinkListTemporary as Links };

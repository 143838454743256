import styled from "@emotion/styled/macro";
import { css, keyframes } from "@emotion/react";
import { xxlTheme } from "../../styles/xxl-theme";
import { typographyToCss, MQ } from "../../styles/helpers";

export const BONUS_LINE_WIDTH = 100;
export const PROGRESS_STEP_SIZE = 22;
export const PERSON_SIZE = 32;
export const ONE_STEP_ANIMATION_TIME = 1000;
const PERSON_ICON_MINIMUM_POSITION = 16;

const { spaces, colors } = xxlTheme;

export const MOBILE_REWARDS_SECTION_WIDTH = `calc(100dvw - 2 * ${spaces.smallRegular})`; // Device screen width minus side spacing
export const TABLET_REWARDS_SECTION_WIDTH = `calc(100dvw - 2 * ${spaces.smallRegular} - 215px - ${spaces.big})`; // Device screen width minus side spacing minus aside section width minus spacing between content & aside
export const LAPTOP_REWARDS_SECTION_WIDTH = `calc(100dvw - 2 * 50px - 215px - ${spaces.big})`; // Device screen width minus side spacing minus aside section width minus spacing between content & aside
export const DESKTOP_REWARDS_SECTION_MAX_WIDTH = `calc(100% - 215px - 120px)`;

const typography = {
  points: {
    fontSize: 24,
    fontFamily: "var(--font-family-bold)",
    lineHeight: 1.2,
    letterSpacing: -0.15,
    laptop: {
      fontSize: 34,
    },
  },
  heading: {
    fontSize: 15,
    fontFamily: "var(--font-family-bold)",
    lineHeight: 1.2,
    letterSpacing: -0.15,
    laptop: {
      fontSize: 24,
    },
  },
  description: {
    fontSize: 12,
    fontFamily: "var(--font-family-medium)",
    lineHeight: 1.2,
    letterSpacing: -0.15,
    laptop: {
      fontSize: 15,
    },
  },
  additionalDescription: {
    fontSize: 10,
    fontFamily: "var(--font-family-medium)",
    lineHeight: 1.2,
    letterSpacing: -0.15,
  },
  listElement: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 15,
    lineHeight: 1.2,
    letterSpacing: -0.15,
  },
  listHeading: {
    fontSize: 15,
    fontFamily: "var(--font-family-bold)",
    lineHeight: 1.2,
    letterSpacing: -0.15,
  },
  readMoreLink: {
    fontSize: 12,
    fontFamily: "var(--font-family-bold)",
    lineHeight: 1.3,
    letterSpacing: -0.15,
  },
};

const {
  points,
  heading,
  description,
  additionalDescription,
  listElement,
  listHeading,
  readMoreLink,
} = typography;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.xxlLightGreen};
  max-width: ${MOBILE_REWARDS_SECTION_WIDTH};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  ${MQ("tablet")} {
    max-width: ${TABLET_REWARDS_SECTION_WIDTH};
  }

  ${MQ("laptop")} {
    max-width: ${LAPTOP_REWARDS_SECTION_WIDTH};
    flex-direction: row;
    padding: ${spaces.smallHuge};
    align-content: flex-start;
    border-radius: 0;
  }

  ${MQ("bigDesktop")} {
    max-width: none;
  }
`;

export const BonusPointsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.xxlWebBlack};
  color: ${colors.xxlWhite};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  overflow-x: hidden;
  padding: ${spaces.smallRegular} 0;
  width: ${MOBILE_REWARDS_SECTION_WIDTH};

  ${MQ("tablet")} {
    width: ${TABLET_REWARDS_SECTION_WIDTH};
  }

  ${MQ("laptop")} {
    width: 436px;
    min-width: 436px;
    align-self: flex-start;
    padding: ${spaces.smallLarge} 0;
    border-radius: 8px;
  }
`;

export const SliderWrapper = styled.div`
  width: auto;
  overflow-x: auto;
  min-width: 100%;
  padding: ${spaces.big} 0 ${spaces.large};
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Slider = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;

  &:hover {
    cursor: grab;
  }
`;

export const BonusStep = styled.li`
  display: flex;
  align-items: center;
`;

export const BaseLine = styled.div`
  width: ${BONUS_LINE_WIDTH}px;
  height: 6px;
  background-color: ${colors.xxlWhite};
`;

type ProgressLineProps = {
  progress: number;
  animationDelay?: number;
};

export const ProgressLine = styled.div<ProgressLineProps>(
  ({ progress, animationDelay }) => {
    const progressAnimation = keyframes`
      0% {
        width: 0;
      }

      100% {
        width: ${progress}%;
      }
    `;
    return css`
      width: 0;
      height: 100%;
      background-color: ${colors.xxlGreen};

      ${animationDelay !== undefined &&
      css`
        animation-name: ${progressAnimation};
        animation-duration: ${(ONE_STEP_ANIMATION_TIME / 100) * progress}ms;
        animation-timing-function: linear;
        animation-delay: ${animationDelay}ms;
        animation-fill-mode: forwards;
      `}
    `;
  }
);

type ProgressStepProps = {
  isCompleted: boolean;
  isInRange: boolean;
};

export const ProgressStep = styled.div<ProgressStepProps>(
  ({ isCompleted, isInRange }) => css`
    width: ${PROGRESS_STEP_SIZE}px;
    height: ${isInRange ? `${PROGRESS_STEP_SIZE}px` : "6px"};
    border-radius: ${isInRange ? "50%" : 0};
    background-color: ${isCompleted ? colors.xxlGreen : colors.xxlWhite};
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  `
);

export const PointsSpan = styled.span`
  ${typographyToCss(points)};
`;

export const PointsHeading = styled.h3`
  ${typographyToCss(heading)};
  padding: 0 ${spaces.smallRegular};
  margin: 0;
`;

export const PointsDescription = styled.p`
  ${typographyToCss(description)};
  margin: 0 0 ${spaces.mini};
  padding: 0 ${spaces.smallRegular};

  & strong {
    font-weight: 700;
    font-family: var(--font-family-bold);
  }
`;

export const PointsAdditionalDescription = styled.p`
  ${typographyToCss(additionalDescription)};
  margin: 0;
  padding: 0 ${spaces.smallRegular};
`;

export const MedalWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: -24px;
  left: 0;
  color: ${colors.xxlWhite};

  & svg {
    width: 100%;
    height: 100%;
  }
`;

export const CheckWrapper = styled.span`
  display: block;
  width: 18px;
  height: 18px;
  color: ${colors.xxlWebBlack};

  & svg {
    width: 100%;
    height: 100%;
  }
`;

type PersonAnimationProps = {
  completedSteps: number;
  animationTime: number;
  incompletedStepPercentage: number;
};

export const PersonWrapper = styled.span<PersonAnimationProps>(
  ({ completedSteps, animationTime, incompletedStepPercentage }) => {
    const progress =
      completedSteps * BONUS_LINE_WIDTH +
      completedSteps * PROGRESS_STEP_SIZE +
      (incompletedStepPercentage * BONUS_LINE_WIDTH) / 100 -
      PERSON_SIZE / 2;
    const progressAnimation = keyframes`
  0% {
    left: 0;
  }

  100% {
    left: ${
      completedSteps === 0 &&
      incompletedStepPercentage < PERSON_ICON_MINIMUM_POSITION
        ? "0"
        : `${progress}px`
    };
  }

  `;
    return css`
      width: ${PERSON_SIZE}px;
      height: ${PERSON_SIZE}px;
      color: ${colors.xxlWhite};
      position: absolute;
      top: 18px;
      left: 0;
      z-index: 2;
      animation-name: ${progressAnimation};
      animation-duration: ${animationTime}ms;
      animation-timing-function: linear;
      animation-fill-mode: forwards;

      & svg {
        width: 100%;
        height: 100%;
      }
    `;
  }
);

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spaces.smallRegular};

  ${MQ("tablet")} {
    padding: ${spaces.regular};
  }

  ${MQ("laptop")} {
    margin-left: ${spaces.big};
    padding: 0;

    ${MQ("desktop")} {
      margin-left: ${spaces.biggerGiant};
    }
  }
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: ${spaces.smallRegular} 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spaces.smallRegular};
`;

export const ListItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  ${typographyToCss(listElement)};
`;

export const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-top: ${spaces.micro};

  & svg {
    width: 20px;
    height: 20px;
    margin-right: ${spaces.smallLarge};
  }
`;

export const ListHeading = styled.h2`
  margin: 0;
  ${typographyToCss(listHeading)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ReadMoreLink = styled.a`
  ${typographyToCss(readMoreLink)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${spaces.hair};
  margin-top: ${spaces.smallRegular};

  & svg {
    width: 18px;
    height: 18px;
    transition: 0.2s ease;
  }

  &:hover svg {
    transform: translateX(4px);
  }
`;

export const ExpandButton = styled.button`
  background-color: transparent;
  border: none;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const PendingPoints = styled.a`
  ${typographyToCss(description)};
  margin: ${spaces.regular} 0 ${spaces.mini};
  padding: 0 ${spaces.smallRegular};
  text-decoration: none;
  color: currentColor;

  &:hover {
    color: ${colors.xxlGreen};
  }

  ${MQ("laptop")} {
    margin-top: ${spaces.smallLarge};
  }

  & strong {
    font-weight: 700;
    font-family: var(--font-family-bold);
  }
`;

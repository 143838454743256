import * as React from "react";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import {
  BulletList,
  CheckBullet,
  CopyHeader,
  CopyListItem,
  TextContainer,
  BulletText,
} from "../Reward.styled";
import { checkIfAbandonedCartMode } from "../Login/Login.helper";

type CallToActionProps = {
  backgroundUrl: string;
};
type VariantType = "abandoned-cart" | "default";
type CopyVariants = Record<
  VariantType,
  { headerText: string; bulletsCopy: string[] }
>;

const CallToAction: React.FunctionComponent<CallToActionProps> = ({
  backgroundUrl,
}) => {
  const isAbandonedCart = checkIfAbandonedCartMode();

  const { t } = useTranslations();

  const copyVariants: CopyVariants = {
    "abandoned-cart": {
      headerText: t("reward.login.abandoned.cart.header"),
      bulletsCopy: [t("reward.login.to.checkout")],
    },
    default: {
      headerText: t("reward.login.header"),
      bulletsCopy: [
        t("reward.bullet.1"),
        t("reward.bullet.2"),
        t("reward.bullet.3"),
        t("reward.bullet.4"),
      ],
    },
  };

  const variant = isAbandonedCart ? "abandoned-cart" : "default";

  return (
    <TextContainer backgroundUrl={backgroundUrl} isInModal={true}>
      <CopyHeader>{copyVariants[variant].headerText}</CopyHeader>
      <BulletList>
        {copyVariants[variant].bulletsCopy.map((item) => (
          <CopyListItem key={item}>
            {!isAbandonedCart && <CheckBullet />}

            <BulletText>{item}</BulletText>
          </CopyListItem>
        ))}
      </BulletList>
    </TextContainer>
  );
};

export { CallToAction };

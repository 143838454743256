import styled from "@emotion/styled/macro";
import { resolutionSizes } from "react-app/src/config";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { NextImage } from "../../common/NextImage/NextImage";
import { INDICATOR_HEIGHT } from "../../../../../react-app/src/components/Common/Slider/Slider.styled";
import { padding } from "../../common/SiteContainer/SiteContainer";
import { Button } from "react-app/src/components/Common";
import { color } from "@xxl/theme";

const PRODUCT_IMAGE_WRAPPER_WIDTH_TO_HEIGHT_RATIO = 0.868;
const SLIDE_MAX_WIDTH = 568;
export const PRODUCT_IMAGE_HEIGHT = 556;

export const DesktopImageGrid = styled.div`
  display: grid;
  grid-template-columns: 100px minmax(400px, 700px);
  grid-gap: ${xxlTheme.spaces.largeBig};
  overflow: hidden;
`;

export const PrimaryImageSlide = styled.div`
  cursor: pointer;
  display: grid;
  place-items: center;
  padding: ${xxlTheme.spaces.largeBig} ${xxlTheme.spaces.bigSmall};
  background-color: ${color.lightGray.hex};
  max-width: ${SLIDE_MAX_WIDTH}px;
  max-height: ${PRODUCT_IMAGE_HEIGHT}px;
`;

export const ProductImageSliderWrapperDesktop = styled.div`
  position: relative;
  height: fit-content;
  max-width: ${SLIDE_MAX_WIDTH}px;
  max-height: ${PRODUCT_IMAGE_HEIGHT}px;
`;

export const StyledLastPlaceSlide = styled.div`
  cursor: pointer;
  place-items: center;
  background-color: ${color.lightGray.hex};
`;

export const ProductImageWrapperMobile = styled.div`
  background-color: ${color.lightGray.hex};
  position: relative;
  min-height: 280px;
  width: 100%;
  height: calc(
    (
        (100vw - (${padding.mobile} * 2)) *
          ${PRODUCT_IMAGE_WRAPPER_WIDTH_TO_HEIGHT_RATIO}
      ) + ${INDICATOR_HEIGHT}
  );
  max-height: calc(
    (
        (100vw - (${padding.mobile} * 2)) *
          ${PRODUCT_IMAGE_WRAPPER_WIDTH_TO_HEIGHT_RATIO}
      ) + ${INDICATOR_HEIGHT}
  );
  overflow: hidden;
`;

export const ThumbnailImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${xxlTheme.spaces.micro};
`;

export const ThumbnailImage = styled(NextImage)<{ selected?: boolean }>`
  cursor: pointer;
  background-color: ${color.lightGray.hex};
  ${({ selected = false }) =>
    selected &&
    `outline: 1px solid ${xxlTheme.colors.xxlWebBlack};
    outline-offset: -1px;`}
`;

const ARROW_SIZE = 26;
const ARROW_OFFSET = 12;

export const Arrow = styled(Button)<{
  direction: "up" | "down" | "right" | "left";
  disabled: boolean;
}>`
  cursor: pointer;
  position: relative;
  outline: none;
  border: none;
  z-index: ${xxlTheme.zIndex["image-banner"]};
  background-color: ${xxlTheme.colors.xxlWebBlack};
  padding: ${xxlTheme.spaces.line} ${xxlTheme.spaces.micro};
  color: ${xxlTheme.colors.xxlWhite};

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
  `}

  transform: ${({ direction }) => {
    switch (direction) {
      case "right":
        return "rotate(90deg)";
      case "down":
        return "rotate(180deg)";
      case "left":
        return "rotate(-90deg)";
      default:
        return "";
    }
  }};

  ${({ direction }) =>
    direction === "up" &&
    `top: calc(-${PRODUCT_IMAGE_HEIGHT}px + ${ARROW_OFFSET}px);
    left: 37.5px;`}
  ${({ direction }) =>
    direction === "down" &&
    `top: calc(-${ARROW_SIZE}px + -${ARROW_OFFSET}px); 
    left: 10px;
    `}
`;

export const BadgeWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: ${xxlTheme.spaces.micro};
  z-index: ${xxlTheme.zIndex["image-banner"]};

  @media (min-width: ${resolutionSizes.laptop}px) {
    bottom: 12px;
  }
`;

export const DiscountPriceWrapper = styled.div`
  position: absolute;
  right: 0;
  top: ${xxlTheme.spaces.smallRegular};
  gap: ${xxlTheme.spaces.smallRegular};
  display: flex;
  flex-direction: column;
  gap: ${xxlTheme.spaces.micro};
  z-index: ${xxlTheme.zIndex["image-banner"]};
`;

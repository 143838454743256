const SPACE = " ";

/**
 * Extract plain text from a htmlString by decoding it via DOM parser
 *
 * @param htmlString String or htmlString
 * @returns {string | null} Plain textContent string as would-be readable in DOM, or empty string
 */
export const decodeHTML = (htmlString: string): string => {
  const div = document.createElement("div");
  div.innerHTML = htmlString;
  return div.textContent || "";
};

/**
 * Truncate a plain text string at a given maxLength, but keeping words intact
 *
 * @param plainText String containing sentence to truncate
 * @returns {string | null} The truncated sentence
 */
export const truncateByWordsAndMaxLength = (
  plainText: string,
  maxLength: number
): string => {
  if (plainText.length <= maxLength) {
    return plainText;
  }
  return plainText.substr(0, plainText.lastIndexOf(SPACE, maxLength));
};

/**
 * Get number of characters, words contained, in html string for the amount of wanted number of characters
 *
 * @param htmlString Html string
 * @param numberOfCharacters Number of characters to be counted
 * @returns {number} Number of characters in html string
 */
export const getNumberOfCharactersDividedByWordInHtmlString = (
  htmlString: string,
  numberOfCharacters: number
): number => {
  const html = [...htmlString];
  const { length } = html;
  const state = {
    in: false,
    count: 0,
    min: 0,
  };

  for (let i = 0; i < length; i++) {
    const char = html[i];
    if (char === "<") {
      state.in = true;
    } else if (char === ">") {
      state.in = false;
    } else if (!state.in) {
      state.count++;
      if (state.count > numberOfCharacters) {
        break;
      } else if (char === " ") {
        state.min = i;
      }
    }
  }
  return state.count > numberOfCharacters ? state.min : length;
};

export const removeTrailingSlash = (string: string) =>
  string.replace(/\/$/, "");

export const checkIfStringHasHtmlTags = (string: string) =>
  /(<|&lt;)\/?[a-z][\s\S]*(>|&gt;)/i.test(string);

export const formatForAnchorLink = (text: string) =>
  text.replace(/\s/g, "-").toLowerCase();

export const replaceNewLineCharactersWithBrElements = (text: string) =>
  text.replace(/\n+/g, "<br/>");

import type { MouseEventHandler } from "react";
import React from "react";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { BackButtonStyled } from "../Styles/CartContent.styled";

export type BackButtonProps = {
  isCentered?: boolean;
  className?: string;
  isMiniCart?: boolean;
  onClick?: MouseEventHandler;
};

const backButtonTrigger = (event: React.MouseEvent) => {
  event.preventDefault();

  document.referrer.length === 0
    ? window.location.assign(window.location.origin)
    : window.history.back();
};

export const BackButton: React.FunctionComponent<BackButtonProps> = ({
  isCentered,
  className,
  isMiniCart,
  onClick = backButtonTrigger,
}) => {
  const { t } = useTranslations();

  return (
    <BackButtonStyled
      type="button"
      className={
        className !== undefined
          ? className
          : "button button--primary button--small button--with-icon"
      }
      onClick={onClick}
      isCentered={isCentered}
      isMiniCart={isMiniCart}
    >
      {isCentered !== true && (
        <svg>
          <use href="#arrow-left-clean.1" xlinkHref="#arrow-left-clean.1" />
        </svg>
      )}
      {t("product.details.clickcollect.continue.shopping")}
    </BackButtonStyled>
  );
};

import { pagePaths } from "../../constants";
import { injectScript, loadScript } from "../xxl-load-script";
import type { InjectedScript, InjectedScriptParam } from "../xxl-load-script";

const isCookieScanner = (): boolean => {
  const { userAgent } = navigator;
  return userAgent.includes("CookieInformationScanner");
};

const getCultureParams = (lang = "EN"): InjectedScriptParam[] => [
  { name: "data-culture", value: lang },
];

const getGcmParams = (): InjectedScriptParam[] => [
  { name: "data-gcm-version", value: "2.0" },
];

const xxlCookieInit = (): void => {
  if (typeof window !== "undefined") {
    const src = "https://policy.app.cookieinformation.com/uc.js";
    const id = "CookieConsent";

    // for these paths we want to inject & load script immediately, not after user interaction
    const eagerLoadPaths = [
      pagePaths.orderConfirmation,
      pagePaths.paymentStatus,
    ];
    const { cookielang } = document.getElementsByTagName("html")[0].dataset;

    const scriptData: InjectedScript = {
      src,
      id,
      dataParams: [...getCultureParams(cookielang), ...getGcmParams()],
    };

    const isEagerLoadPath = eagerLoadPaths.some((path) =>
      window.location.pathname.includes(path)
    );

    if (isEagerLoadPath || isCookieScanner()) {
      injectScript(scriptData);
    } else {
      loadScript(scriptData);
    }
  }
};

export { xxlCookieInit };

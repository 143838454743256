import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { Workshop } from "@xxl/icons";
import { ButtonStyledAsLink } from "../../../styled";
import { MQ, typographyToCss } from "../../../styles/helpers";
import { xxlTheme } from "../../../styles/xxl-theme";
import { bundleLineGridAreas } from "../../Orders/constants";
import typography from "./CartPage.typography";

const { spaces, colors, typography: baseTypography } = xxlTheme;
const {
  productSplash,
  productBrand,
  productDescription,
  productQuantity,
  settingsDescriptionHeader,
  teamBundleStrong,
  teamBundleText,
  productOutOfStock,
  outOfStockRemove,
  collectWarning,
  depositTitle,
  depositPrice,
} = typography;

export const defaultProductPictureSize = 60;
export const quantityDisplayBlockSize = "44px";
export const quantityDisplayFullWidth = "132px";

export const focusVisibleStyles = css`
  &:focus-visible {
    outline-width: ${spaces.line};
    outline-style: solid;
    outline-color: ${colors.xxlGreen};
    outline-offset: ${spaces.line};
  }
`;

type ListItemProps = {
  hasErrors: boolean;
};

export const ListItem = styled.li<ListItemProps>(
  ({ hasErrors = false }) => css`
    width: 100%;
    margin-bottom: 0;
    border-bottom: ${spaces.hair} solid ${colors.xxlMediumGrey};
    background-color: ${colors.xxlWhite};
    position: relative;

    ${hasErrors &&
    css`
      pointer-events: none;
    `}

    & * {
      box-sizing: border-box;
    }
  `
);

type IsServiceProp = {
  isService?: boolean;
  isBundle?: boolean;
};
export const Article = styled.article<IsServiceProp>(
  ({ isService = false, isBundle = false }) => css`
    position: relative;
    padding-top: ${isService ? 0 : spaces.smallRegular};
    padding-bottom: ${spaces.smallRegular};
    padding-inline: ${isBundle ? 0 : spaces.smallRegular};

    width: 100%;

    background-clip: content-box;
    background-color: ${isService ? colors.xxlLightGreen : colors.xxlWhite};

    &:not(:last-child)::after {
      content: ${isService ? "none" : ""};
      display: block;
      width: 100%;
      height: ${spaces.hair};
      background-color: ${colors.xxlLightGrey};
      position: relative;
      bottom: 0;
      left: 0;
      margin-top: ${spaces.smallRegular};
    }
  `
);
export const RibbonWrapper = styled.div`
  position: relative;
  width: calc(100% + 2 * ${spaces.smallRegular});
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding-left: ${spaces.smallRegular};
  left: -${spaces.smallRegular};
  top: -${spaces.smallRegular};
`;

export const ProductSplash = styled.span`
  ${typographyToCss(productSplash)};
`;

export const ProductInfoAndPriceWrapper = styled.div<IsServiceProp>(
  ({ isService = false }) => css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding-left: ${isService ? spaces.smallRegular : 0};
    padding-right: ${isService ? spaces.smallRegular : 0};
  `
);

export const ProductInfoWrapper = styled.div`
  display: flex;
  gap: ${spaces.mini};
`;

export const ProductDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: ${spaces.micro};
`;

export const ExpandableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ExpandableButtonsWrapper = styled.div`
  display: flex;
`;

export const ExpandableDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const commonPriceStyles = css`
  line-height: 1;
  height: 20px;
  display: flex;
  align-items: flex-end;
  margin-bottom: ${spaces.micro};
`;

type PriceWrapperProps = {
  cancelled?: boolean;
};
export const PriceWrapper = styled.div<PriceWrapperProps>(
  ({ cancelled = false }) => css`
    text-decoration: ${cancelled ? "line-through" : ""};
    & .product-card__current-price {
      font-size: 20px;
      ${commonPriceStyles};
    }

    & .product-card__price-wrapper--logged-in {
      .product-card__current-price[data-price-type="PROMOTION"] {
        color: ${colors.xxlRed};
      }
    }

    & {
      .product-card__previous-price,
      .product-card__inverted-price {
        font-size: 15px;
        ${commonPriceStyles};
      }
    }

    & .product-card__current-price-wrapper,
    & .product-card__previous-price-wrapper {
      font-size: 12px;
      line-height: 1;
      min-height: 32px;
      max-width: min-content;

      ${MQ("tablet")} {
        min-height: ${spaces.big};
      }
    }
  `
);

export const BrandAndName = styled.h2`
  ${typographyToCss(productDescription)};
  display: -webkit-box;
  width: 100%;
  line-height: ${spaces.regular};
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  hyphens: auto;
  word-break: break-word;
  margin: 0 0 ${spaces.smallRegular};

  ${MQ("tablet")} {
    margin-bottom: ${spaces.mini};
    line-height: initial;
  }
`;

export const DepositInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 68px;
  margin: ${spaces.mini} 0;
`;

export const DepositTitle = styled.span`
  ${typographyToCss(depositTitle)};
  margin: 0 0 ${spaces.micro};
`;

export const DepositText = styled.span`
  ${typographyToCss(productDescription)};
  display: -webkit-box;
  width: 100%;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  hyphens: auto;
  word-break: break-word;
`;

export const DepositPrice = styled.span`
  ${typographyToCss(depositPrice)};
  margin: 0;
`;

export const Brand = styled.span`
  ${typographyToCss(productBrand)};
`;

type ProductImageProps = {
  size?: number;
  isDisabled?: boolean;
};
export const ProductImage = styled.img<ProductImageProps>(
  ({ size = defaultProductPictureSize, isDisabled = false }) => css`
    grid-area: ${bundleLineGridAreas.image};
    width: ${size}px;
    height: ${size}px;
    object-fit: contain;
    background-color: ${colors.xxlLightGrey};
    opacity: ${isDisabled ? 0.7 : 1};
  `
);

export const ProductColorAndSize = styled.p`
  ${typographyToCss(productDescription)};
  display: block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 0 ${spaces.mini};

  &:last-child {
    margin: 0;
  }
`;

export const ButtonsWrapper = styled.div<IsServiceProp>(
  ({ isService = false }) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ${spaces.micro};
    padding-left: ${isService ? spaces.smallRegular : 0};
    padding-right: ${isService ? spaces.smallRegular : 0};
    padding-bottom: ${isService ? spaces.smallRegular : 0};
  `
);

export const RemoveButton = styled.button`
  width: ${quantityDisplayBlockSize};
  height: ${quantityDisplayBlockSize};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  background: transparent;
  padding: 0;
  transition: 0.2s ease;

  &:hover {
    cursor: pointer;
    color: ${colors.xxlError};
  }

  ${focusVisibleStyles};

  & svg {
    width: ${spaces.small};
    height: 17px;
  }
`;

export const QuantityWrapper = styled.div`
  height: ${quantityDisplayBlockSize};
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const QuantityButton = styled.button`
  width: ${quantityDisplayBlockSize};
  height: ${quantityDisplayBlockSize};
  background-color: ${colors.xxlLightGrey};
  color: ${colors.xxlWebBlack};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: ${colors.xxlGreen};
  }

  ${focusVisibleStyles};

  &:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

export const QuantityDisplay = styled.input`
  box-sizing: border-box;
  ${typographyToCss(productQuantity)};
  width: ${quantityDisplayBlockSize};
  height: ${quantityDisplayBlockSize};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${colors.xxlWhite};
  color: ${colors.xxlWebBlack};
  border: ${spaces.hair} solid ${colors.xxlLightGrey};

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const Overlay = styled.div`
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export const SettingsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const SettingsListItem = styled.li`
  ${typographyToCss(productDescription)};
`;

export const SettingsListHeading = styled.h3`
  ${typographyToCss(settingsDescriptionHeader)};
  margin: ${spaces.mini} 0 ${spaces.micro};

  ${MQ("tablet")} {
    margin-top: ${spaces.smallRegular};
  }
`;

export const TeamBundleList = styled.ul`
  list-style: none;
  margin: ${spaces.mini} 0 0;
  padding: 0;
`;

export const EGTeamBundleList = styled(TeamBundleList)`
  margin: ${spaces.micro} 0 ${spaces.mini};
`;

export const TeamBundleListItem = styled.li`
  margin: 0;
  ${typographyToCss(teamBundleText)};
`;

export const TeamBundleStrong = styled.span`
  ${typographyToCss(teamBundleStrong)};
`;

export const EGTeamBundleStrong = styled(TeamBundleStrong)`
  margin-top: ${spaces.micro};
`;

export const TeamBundleText = styled.p`
  margin: 0;
  ${typographyToCss(teamBundleText)};
`;

export const ServiceHeader = styled.h5`
  ${typographyToCss(baseTypography.smallBold)};
  padding: ${spaces.smallRegular};
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ServiceHeaderIcon = styled(Workshop)`
  font-size: ${spaces.smallLarge};
  margin-right: ${spaces.micro};
`;

export const ServiceHeaderButton = styled(ButtonStyledAsLink)`
  flex-grow: 1;
  text-align: right;
`;

export const Link = styled.a`
  grid-area: ${bundleLineGridAreas.image};
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: 0;
  cursor: ${(props) => (props.href !== undefined ? "pointer" : "auto")};
  margin-bottom: auto;

  ${() => {
    if ("next" in window) {
      // https://xxlsports.atlassian.net/browse/XD-11583
      return "";
    }
    return css`
      & + ${ProductDescriptionWrapper} ${BrandAndName} {
        height: 32px;
        ${MQ("tablet")} {
          height: ${spaces.big};
        }
      }
    `;
  }}
`;

export const FullServiceDescription = styled.div`
  ${typographyToCss(productDescription)};
  margin: ${spaces.mini} 0;
`;

export const OutOfStockOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${spaces.smallRegular};
  color: ${colors.xxlWebBlack};
  z-index: 2;
  pointer-events: none;
  svg {
    font-size: ${spaces.large};
    margin-left: ${spaces.micro};
    margin-bottom: -${spaces.micro};
  }
`;

export const OutOfStockButton = styled.button`
  border: none;
  background-color: transparent;
  color: ${colors.xxlWebBlack};
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;

  &:hover {
    color: ${colors.xxlError};
    cursor: pointer;
  }

  & svg {
    width: 18px;
    height: 18px;
    margin-right: ${spaces.mini};
  }
`;

export const OutOfStockButtonText = styled.span`
  margin-left: ${spaces.mini};
  ${typographyToCss(outOfStockRemove)};
`;

export const OutOfStockImage = styled.div`
  width: ${spaces.small};
  height: ${spaces.small};
`;

export const OutOfStockListItem = styled.li`
  margin-bottom: ${spaces.smallRegular};
`;

export const OutOfStockHeading = styled.span`
  ${typographyToCss(productOutOfStock)};
  display: block;
`;

export const Warning = styled.span`
  ${typographyToCss(collectWarning)};
  background-color: ${colors.xxlLightAmber};
  padding: ${spaces.smallRegular};
  display: block;
  margin-top: ${spaces.smallRegular};
`;

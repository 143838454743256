import React, { useState, useEffect } from "react";
import {
  addXXLEventListener,
  XXL_SIGNUP_OPEN,
  XXL_SIGNUP_CLOSE,
  removeXXLEventListener,
} from "../../../utils/xxl-event";
import type { SignupViewStates } from "./SignUpModal";
import { SignUpModal } from "./SignUpModal";

type SignupModalWrapper = {
  initialViewState?: SignupViewStates;
};

export const SignupModalWrapper = ({
  initialViewState,
}: SignupModalWrapper) => {
  const [signupModalVisible, setSignupModalVisible] = useState(false);

  useEffect(() => {
    const openSignupModal = () => setSignupModalVisible(true);
    const closeSignupModal = () => setSignupModalVisible(false);

    addXXLEventListener(XXL_SIGNUP_OPEN, openSignupModal);
    addXXLEventListener(XXL_SIGNUP_CLOSE, closeSignupModal);

    return () => {
      removeXXLEventListener(XXL_SIGNUP_OPEN, openSignupModal);
      removeXXLEventListener(XXL_SIGNUP_CLOSE, closeSignupModal);
    };
  }, []);

  return signupModalVisible ? (
    <SignUpModal initialViewState={initialViewState} />
  ) : null;
};

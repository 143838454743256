import type { FunctionComponent } from "react";
import React from "react";

type ImageProps = {
  url: string;
  width?: number;
  height?: number;
};

const Image: FunctionComponent<ImageProps> = ({
  url,
  width = 69,
  height = 36,
}) => (
  <img
    loading="lazy"
    data-private={true}
    src={url}
    alt={"XXL logo"}
    width={width}
    height={height}
  />
);

export { Image };

import { useState, useEffect } from "react";

type Dimensions = {
  x?: number;
  y?: number;
};

type GetWindowDimensions = () => Dimensions;
const getWindowDimensions: GetWindowDimensions = () => {
  const { innerWidth: x, innerHeight: y } = window;
  return {
    x,
    y,
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState<Dimensions>({
    x: undefined,
    y: undefined,
  });
  useEffect(() => {
    if (windowDimensions.x === undefined || windowDimensions.y === undefined) {
      setWindowDimensions(getWindowDimensions());
    }
    const handleResize = () => setWindowDimensions(getWindowDimensions());

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowDimensions.x, windowDimensions.y]);

  return windowDimensions;
};

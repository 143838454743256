import type { TranslationKey } from "../../translations";

export * from "./SnackBarWrapper";
export * from "./SnackBar";
export * from "./SnackBarMessage";

declare interface ISnackBarMessage {
  open: boolean;
  text: string;
  icon?: string;
  url?: string;
  background?: string;
  textColor?: string;
}

declare interface IMessageEvent {
  key: TranslationKey;
  icon?: string;
  url?: string;
  background?: string;
  textColor?: string;
}

export type { ISnackBarMessage, IMessageEvent };

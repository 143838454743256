export default {
  averageRating: {
    reviewsNumber: {
      fontFamily: "var(--font-family-regular)",
      fontSize: 15,
      lineHeight: 1.3,
      textDecoration: "underline",
      whiteSpace: "nowrap",
      letterSpacing: -0.15,
    },
  },
  reviews: {
    recommendedText: {
      fontFamily: "var(--font-family-regular)",
      fontSize: 10,
      lineHeight: 1.2,
    },
    helpfulnessVote: {
      fontFamily: "var(--font-family-regular)",
      fontSize: 12,
      lineHeight: 1.3,
      letterSpacing: -0.15,
    },
    reviewAuthor: {
      fontSize: 12,
      lineHeight: 1.3,
    },
    reviewHeading: {
      fontSize: 15,
      lineHeight: 1.2,
      textDecoration: "none",
      fontFamily: "var(--font-family-bold)",
    },
    reviewText: {
      fontSize: 15,
      lineHeight: 1.2,
    },
    reviewTranslationText: {
      fontSize: 10,
      lineHeight: 1.2,
    },
  },
  reviewsSummary: {
    heading: {
      fontFamily: "var(--font-family-bold)",
      fontSize: 26,
      lineHeight: 1.2,
    },
    recommendation: {
      fontFamily: "var(--font-family-bold)",
      fontSize: 15,
      lineHeight: 1.2,
    },
    text: {
      fontSize: 15,
      lineHeight: 1.2,
    },
  },
  numbersSummary: {
    outOfText: {
      fontFamily: "var(--font-family-bold)",
      fontSize: 15,
      lineHeight: 1.2,
      laptop: {
        fontSize: 26,
        textAlign: "right",
      },
    },
    number: {
      fontSize: 15,
      lineHeight: 1.2,
    },
  },
  noReviews: {
    confirmationText: {
      fontSize: 15,
      lineHeight: 1.2,
    },
  },
};

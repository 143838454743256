import * as React from "react";
import type { FormikHelpers } from "formik";
import { Formik } from "formik";
import { LoadingCircle } from "../../Common";
import { DialogBoxHeaderWrapper } from "../../DialogBox/DialogBox.styled";
import DialogCloseButton from "../../Reward/SharedComponents/DialogBoxCloseButton";
import {
  SignUpErrorMessage,
  SignUpFormLabel,
  SignUpFormInput,
  FormContainer,
  CheckboxContainer,
  TermsLabel,
} from "../../Reward/SignUp/SignUpForm/SignupForm.styled";
import type { UpdateAccountInput } from "../../../generated/graphql-code-generator";
import { Button, InputContainer, StyledCheckbox } from "../../../styled";
import {
  StyledFormHeading,
  CloseButton,
} from "../ChangeModal/ChangeModal.styled";
import { fields, getPersonalInfoSchema } from "./PersonalInfoModalHelper";
import {
  checkTerms,
  formatGraphQLErrors,
  getTranslatedServerErrors,
  type FormError,
} from "../../Reward/SignUp/SignUpForm/SignUpFormHelper";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { ErrorMessage } from "../../Common/FormInput";
import { callGraphQL, type GraphQLError } from "../../../graphql/graphqlApi";
import type { AccountError, AccountResponse } from "../UserAPI";
import { accountQuery, updateAccount } from "../UserAPI";
import type { TranslationKey } from "../../../translations";
import { useSharedData } from "../../../contexts/SharedData";
import ReactHtmlParser from "html-react-parser";
import type { UserDataProps } from "../../Reward/Login";
import { MISSING_ACCOUNT_DATA } from "../../../constants";
import { XXLLoader } from "../../XXLLoader";
import * as yup from "yup";
import isEmpty from "lodash/isEmpty";
import { useModalsStatusSource } from "../../../contexts/Session";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { log } from "@xxl/logging-utils";

type MissingAccountData = UpdateAccountInput & {
  terms?: boolean;
};

const UpdateAccountForm = ({
  afterUpdate,
  handleDialogBoxClosing,
  userData,
}: {
  afterUpdate: () => void;
  handleDialogBoxClosing: () => void;
  userData?: UserDataProps;
}) => {
  const [inProgress, setInProgress] = React.useState(false);
  const [isAccountFetching, setIsAccountFetching] = React.useState(false);
  const [initialFormData, setInitialFormData] =
    React.useState<MissingAccountData>();
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [translatedServerErrors, setTranslatedServerErrors] = React.useState<
    Record<string, string>
  >({});
  const [hasClickedSubmit, setHasClickedSubmit] = React.useState(false);
  const { configuration, featureToggles, siteUid } = useSharedData().data;
  React.useEffect(() => {
    const _fetchMyAccount = async () => {
      try {
        setIsAccountFetching(true);
        const accountRes = await callGraphQL<AccountResponse>(
          { query: accountQuery },
          configuration.amplifyConfig.aws_appsync_graphqlEndpoint
        );
        const account = accountRes.data?.account ?? null;
        const { socialSecurityNumber, firstName, lastName, mobilePhone } =
          account ?? {};

        setInitialFormData({
          socialSecurityNumber,
          firstName,
          lastName,
          mobilePhone: mobilePhone ?? "",
          terms: false,
        });
      } catch (error) {
        setIsAccountFetching(false);
        log.error("Error while fetching account data", error);
      } finally {
        setIsAccountFetching(false);
      }
    };
    if (!isNotNullOrUndefined(userData)) {
      void _fetchMyAccount();
    } else {
      setInitialFormData({
        socialSecurityNumber: userData.socialSecurityNumber,
        firstName: userData.firstName,
        lastName: userData.lastName,
        mobilePhone: userData.mobilePhone,
        terms: false,
      });
    }
  }, [userData]);

  const { t } = useTranslations();

  const { toggle_social_security_number } = featureToggles;

  const setErrors = (errors: GraphQLError<unknown, AccountError>[]) => {
    const errorList = errors
      .map((error) => t(error.message as TranslationKey))
      .join(", ");
    setErrorMessage(errorList ?? t("general.error"));

    const formattedErrors = formatGraphQLErrors(errors ?? [], t) as FormError[];

    setTranslatedServerErrors(getTranslatedServerErrors(formattedErrors, t));
  };

  const requiredAccountFields = fields({
    toggle_social_security_number,
    t,
    uid: siteUid,
  }).filter((field) => {
    return field.required === true;
  });

  const onClickSubmit = async (
    values: MissingAccountData,
    { setTouched }: FormikHelpers<MissingAccountData>
  ) => {
    setInProgress(true);
    setTouched({});
    setHasClickedSubmit(true);

    try {
      delete values.terms;
      const res = await updateAccount(
        { ...values, consent: "ACCOUNT" },
        configuration.amplifyConfig.aws_appsync_graphqlEndpoint
      );

      const { errors } = res;
      if (errors !== undefined) {
        setErrors(errors);
        return;
      }
      localStorage.removeItem(MISSING_ACCOUNT_DATA);
      handleDialogBoxClosing();
      useModalsStatusSource.set((prevState) => ({
        ...prevState,
        isCompleteDataFormVisible: false,
      }));
      afterUpdate();
    } catch {
      setInProgress(false);
    } finally {
      setInProgress(false);
    }
  };
  const personalInfoSchema = getPersonalInfoSchema(
    toggle_social_security_number,
    t,
    siteUid
  );
  const consentValidation = yup.object({ terms: checkTerms(t) });
  const completeAccountSchema = personalInfoSchema.concat(consentValidation);

  if (isAccountFetching) {
    return <XXLLoader />;
  }
  return (
    <Formik
      initialValues={initialFormData ?? { terms: false }}
      onSubmit={onClickSubmit}
      validateOnBlur={true}
      validateOnChange={true}
      isInitialValid={false}
      enableReinitialize={true}
      validationSchema={completeAccountSchema}
    >
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        values,
        touched,
        errors,
        isValid,
      }): JSX.Element => (
        <FormContainer onSubmit={handleSubmit} inProgress={inProgress}>
          <DialogBoxHeaderWrapper>
            <StyledFormHeading>
              {t("missing.data.form.title")}
            </StyledFormHeading>
            <CloseButton
              style={{ position: "static" }}
              type="button"
              data-testid="close-button"
            >
              <DialogCloseButton
                handleDialogBoxClosing={handleDialogBoxClosing}
              />
            </CloseButton>
          </DialogBoxHeaderWrapper>
          {errorMessage !== null && (
            <SignUpErrorMessage>{errorMessage}</SignUpErrorMessage>
          )}
          {requiredAccountFields.map(
            ({
              fieldName,
              id,
              inputMode,
              label,
              autocompleteToken,
              placeholder,
              required,
            }) => (
              <InputContainer key={fieldName}>
                <SignUpFormLabel htmlFor={id} required={required}>
                  {label}
                </SignUpFormLabel>
                <SignUpFormInput
                  disabled={
                    isNotNullOrUndefined(initialFormData) &&
                    !isEmpty(initialFormData[fieldName]) // prevent user editing data at this point if field is already filled
                  }
                  autoComplete={autocompleteToken}
                  id={id}
                  name={fieldName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={placeholder}
                  type={inputMode}
                  value={values[fieldName]}
                  data-testid={`personal-info-${fieldName}`}
                />

                <ErrorMessage
                  {...{ fieldName, errors, translatedServerErrors }}
                  isTouched={touched[fieldName] ?? false}
                />
              </InputContainer>
            )
          )}

          <CheckboxContainer>
            <StyledCheckbox
              name="terms"
              handleChange={handleChange}
              testId="signup-checkbox"
              id="signup-checkbox-label"
              label={
                <TermsLabel>
                  {ReactHtmlParser(t("reward.signup.terms.label"))}
                </TermsLabel>
              }
            />
            {hasClickedSubmit && errors.terms !== undefined && (
              <SignUpErrorMessage>{errors.terms}</SignUpErrorMessage>
            )}
          </CheckboxContainer>

          <Button
            type="submit"
            className="button button--small button--primary"
            data-testid="personal-info-submit"
            disabled={inProgress || !isValid}
          >
            {inProgress ? (
              <LoadingCircle isLoading={inProgress} />
            ) : (
              t("account.form.save")
            )}
          </Button>
        </FormContainer>
      )}
    </Formik>
  );
};

export { UpdateAccountForm };

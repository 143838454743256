import React from "react";
import { useTranslations } from "../../../../contexts/Translations/TranslationsContext";
import { Formik } from "formik";
import { DialogBoxHeaderWrapper } from "../../../DialogBox/DialogBox.styled";
import {
  ForgotPasswordSuccessText,
  ForgotPasswordText,
  FormContainer,
  OTPReCaptcha,
} from "../LoginForm/LoginForm.styled";
import DialogCloseButton from "../../SharedComponents/DialogBoxCloseButton";
import {
  FormHeaderInModal,
  SignUpErrorMessage,
} from "../../SignUp/SignUpForm/SignupForm.styled";
import { FormInput } from "../../../Common/FormInput";
import { useSharedData } from "../../../../contexts/SharedData";
import spaces from "../../../../styles/theme/spaces";
import { XxlButton } from "../../../Common/XxlButton";
import type { ReCAPTCHA } from "react-google-recaptcha";
import { forgotPasswordSchema, emailField } from "./ForgotPasswordHelper";
import Axios from "axios";
import { Checkmark } from "../../../Services/Checkmark";
import { log } from "@xxl/logging-utils";

type ForgotPasswordFormData = {
  email: string;
};

type ForgotPasswordFormProps = {
  handleDialogBoxClosing: () => void;
};

const ForgotPasswordForm = ({
  handleDialogBoxClosing,
}: ForgotPasswordFormProps) => {
  const { t } = useTranslations();
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const [inProgress, setInProgress] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [captchaToken, setCaptchaToken] = React.useState<string | null>(
    "Cypress" in window ? "test-token" : null
  );
  const { captcha } = useSharedData().data;
  const { siteKey } = captcha;

  const onCaptchaChange = (value: string | null) => {
    setCaptchaToken(value);
  };

  const onCaptchaError = () => {
    setCaptchaToken(null);
    setErrorMessage(t("general.error"));
  };

  const resetCaptcha = () => {
    recaptchaRef.current?.reset();
    setCaptchaToken(null);
  };

  const onClickSubmit = async (values: ForgotPasswordFormData) => {
    setErrorMessage(null);
    try {
      setInProgress(true);
      const { email } = values;
      const form = new FormData();
      form.append("email", email);

      await Axios.post("/resetpasswordlink", form);

      setIsSuccess(true);
    } catch (err) {
      setErrorMessage(t("general.error"));
      resetCaptcha();
      log.error(err);
    } finally {
      setInProgress(false);
    }
  };

  const { placeholder, inputMode, autocompleteToken, id, label, fieldName } =
    emailField(t);

  return (
    <Formik
      initialValues={{ email: "" }}
      onSubmit={onClickSubmit}
      validateOnBlur={true}
      validateOnChange={true}
      isInitialValid={false}
      validationSchema={forgotPasswordSchema(t)}
    >
      {({
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        touched,
        values,
      }): JSX.Element => (
        <FormContainer inProgress={false} onSubmit={handleSubmit}>
          <DialogBoxHeaderWrapper>
            <FormHeaderInModal>
              {t("account.requestpassword.header")}
            </FormHeaderInModal>
            <DialogCloseButton
              handleDialogBoxClosing={handleDialogBoxClosing}
            />
          </DialogBoxHeaderWrapper>
          <ForgotPasswordText>
            {t("account.requestpassword.text")}
          </ForgotPasswordText>
          {errorMessage !== null && (
            <SignUpErrorMessage>{errorMessage}</SignUpErrorMessage>
          )}
          <FormInput
            fieldName={fieldName}
            id={id}
            label={label}
            required={true}
            autocompleteToken={autocompleteToken}
            inputMode={inputMode}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={placeholder}
            type="email"
            value={values[fieldName]}
            errors={errors}
            translatedServerErrors={{}}
            touched={touched[fieldName] ?? false}
            style={{ paddingBottom: spaces.smallLarge }}
          />
          {!isSuccess && (
            <OTPReCaptcha
              ref={recaptchaRef}
              onChange={onCaptchaChange}
              sitekey={siteKey}
              onExpired={() => setCaptchaToken(null)}
              onErrored={onCaptchaError}
            />
          )}
          {isSuccess && (
            <ForgotPasswordSuccessText>
              {t("account.requestpassword.success")}
            </ForgotPasswordSuccessText>
          )}
          <XxlButton
            type="submit"
            data-testid="forgot-pw-submit-button"
            variant="secondary"
            size="small"
            disabled={
              !isValid || inProgress || isSuccess || captchaToken === null
            }
            loading={inProgress}
          >
            {isSuccess ? <Checkmark /> : t("account.requestpassword.button")}
          </XxlButton>
        </FormContainer>
      )}
    </Formik>
  );
};

export { ForgotPasswordForm };

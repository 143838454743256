import styled from "@emotion/styled/macro";
import { typographyToCss } from "../../../styles/helpers";
import { xxlTheme } from "../../../styles/xxl-theme";
import text from "./CartServiceProduct.typography";
import { Button } from "./CartServiceProducts.styled";
import {
  quantityDisplayBlockSize,
  quantityDisplayFullWidth,
} from "./ProductItem.styled";

const { colors, spaces } = xxlTheme;

type AddToCartButtonProps = {
  marginAuto?: boolean;
};

export const AddToCartButton = styled(Button)<AddToCartButtonProps>`
  ${typographyToCss(text.addToCartButton)};
  width: ${quantityDisplayFullWidth};
  height: ${quantityDisplayBlockSize};
  background-color: ${colors.xxlGreen};
  border-color: ${colors.xxlGreen};
  gap: ${spaces.smallRegular};
  position: relative;

  &[disabled] {
    pointer-events: none;
    opacity: 0.4;
  }
`;

import { callGraphQL, type GraphQLResult } from "../../../graphql/graphqlApi";
import {
  getProductSizesStockStatusQuery,
  getStoresWithCollectableProductQuery,
} from "./queries";
import type {
  GetProductSizesStockStatusQuery,
  GetProductSizesStockStatusQueryVariables,
  GetStoresWithCollectableProductQuery,
} from "../../../generated/graphql-code-generator";
import { log } from "@xxl/logging-utils";

export const StockLevels = {
  IN_STOCK: "INSTOCK",
  LOW_STOCK: "LOWSTOCK",
  OUT_OF_STOCK: "OUTOFSTOCK",
} as const;

export type StockLevel = (typeof StockLevels)[keyof typeof StockLevels];

export const getStockLevel = (
  stockLevel: number,
  minimumStockeLevelForPackage?: number
): StockLevel => {
  const packageMultiplier =
    minimumStockeLevelForPackage === undefined
      ? 1
      : minimumStockeLevelForPackage;
  if (stockLevel > 3 * packageMultiplier) {
    return StockLevels.IN_STOCK;
  }
  if (stockLevel >= packageMultiplier) {
    return StockLevels.LOW_STOCK;
  }

  return StockLevels.OUT_OF_STOCK;
};

export type Store = {
  collectableStockLevel: StockLevel;
  id: string;
  location: { latitude: number; longitude: number } | null;
  name: string;
  onlineStockLevel: StockLevel;
  stockLevel: StockLevel;
};

export const convert = (
  { data, errors }: GraphQLResult<GetStoresWithCollectableProductQuery>,
  minimumStockLevelForPackage = 1
) => {
  if (Array.isArray(errors)) {
    log.debug(errors);
    return [];
  }

  if (data === undefined || data === null) {
    log.debug("Data property is not defined.");
    return [];
  }

  const stores = data.collectableStock?.stores ?? [];

  return stores.reduce((acc, { collectableStock, store, stock }) => {
    acc.push({
      collectableStockLevel: getStockLevel(
        collectableStock,
        minimumStockLevelForPackage
      ),
      id: store.id,
      location: store.location,
      name: store.name,
      onlineStockLevel: getStockLevel(data.collectableStock?.onlineStock ?? 0),
      stockLevel: getStockLevel(stock, minimumStockLevelForPackage),
    });

    return acc;
  }, [] as Store[]);
};

export const getStoresWithCollectableProduct = async (
  ean: string,
  graphQLEndpointUrl: string,
  graphQLApiKey: string,
  minimumStockLevelForPackage = 1
): Promise<Store[]> => {
  const response = await callGraphQL<GetStoresWithCollectableProductQuery>(
    {
      query: getStoresWithCollectableProductQuery,
      variables: {
        input: {
          ean,
        },
      },
      forceAnonymous: true,
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

  const { errors = [] } = response;
  if (errors.length > 0) {
    log.error(errors);
    throw Error(errors.map((err) => err.message).toString());
  }

  return convert(response, minimumStockLevelForPackage);
};

export const getProductSizesStockStatus = async ({
  productCode,
  storeIds,
  graphQLEndpointUrl,
  graphQLApiKey,
}: {
  productCode: string;
  storeIds?: string[];
  graphQLEndpointUrl: string;
  graphQLApiKey: string;
}) => {
  const productSizesStockStatusQueryVariables: GetProductSizesStockStatusQueryVariables =
    {
      input: {
        productCode,
        storeIds,
      },
    };

  const response = await callGraphQL<GetProductSizesStockStatusQuery>(
    {
      query: getProductSizesStockStatusQuery,
      variables: productSizesStockStatusQueryVariables,
      forceAnonymous: true,
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

  const { errors = [] } = response;
  if (errors.length > 0) {
    log.error(errors);
    throw Error(errors.map((err) => err.message).toString());
  }
  return response.data?.productSizesStockStatus ?? [];
};

import { xxlTheme } from "../../styles/xxl-theme";

export default {
  defaultBrandText: {
    fontSize: 12,
    lineHeight: 1.6,
    fontFamily: "var(--font-family-bold)",
  },
  bigBrandText: {
    fontSize: 15,
    lineHeight: 1,
    fontFamily: "var(--font-family-bold)",
  },
  defaultNameText: {
    fontSize: 12,
    lineHeight: 1.5,
    tablet: {
      fontSize: 15,
      lineHeight: 1.2,
    },
  },
  bigNameText: {
    fontSize: 15,
    lineHeight: 1.2,
  },
  smallOriginalPrice: {
    ...xxlTheme.typography.standardRegular,
    fontSize: 12,
    lineHeight: 1.5,
  },
  bigOriginalPrice: {
    ...xxlTheme.typography.standardRegular,
    fontSize: 15,
    lineHeight: 1.5,
  },
  smallDisclaimerPrice: {
    ...xxlTheme.typography.standardRegular,
    fontSize: 10,
    lineHeight: 1,
    letterSpacing: -0.1,
  },
  bigDisclaimerPrice: {
    ...xxlTheme.typography.standardRegular,
    fontSize: 12,
    lineHeight: 1,
    letterSpacing: -0.1,
  },
  smallCurrentPrice: {
    ...xxlTheme.typography.standardBold,
    fontSize: 20,
    lineHeight: 1.2,
  },
  bigCurrentPrice: {
    ...xxlTheme.typography.standardBold,
    fontSize: 30,
    lineHeight: 1.2,
  },
  extraInfoText: {
    fontSize: 12,
    lineHeight: 1.2,
    letterSpacing: -0.15,
  },
  highlightedLabel: {
    fontSize: 12,
    lineHeight: 1.15,
    letterSpacing: -0.15,
  },
  specifications: {
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 1.15,
    letterSpacing: -0.15,
  },
  stockStatus: {
    fontSize: 12,
    lineHeight: 1.3,
    letterSpacing: -0.12,
  },
  deliveryTime: {
    fontSize: 12,
    lineHeight: 1.2,
  },
};

import { CampaignIcon } from "./ProductDetailsPage.styles";
import { CampaignSplash } from "./ProductImageSlider/CampaignSplash/CampaignSplash";
import { NextImage } from "../common/NextImage/NextImage";
import type { CampaignInfo } from "./types";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { isNotEmpty } from "@xxl/common-utils";

type Props = {
  campaignInfo: CampaignInfo;
  iconUrl?: string;
};

const CampaignComponent = ({ campaignInfo, iconUrl }: Props) => {
  const { t } = useTranslations();

  return (
    <>
      {campaignInfo !== null && (
        <CampaignSplash
          colors={campaignInfo.colorTheme}
          countdown={campaignInfo.timer}
          name={campaignInfo.name}
        />
      )}
      {isNotEmpty(iconUrl) && iconUrl !== undefined && (
        <CampaignIcon withMargin={campaignInfo !== null}>
          <NextImage
            width={70}
            height={70}
            priority={true}
            src={iconUrl}
            alt={t("campaign.hub.card.link.text")}
          />
        </CampaignIcon>
      )}
    </>
  );
};

export { CampaignComponent };

import { createContext, useContext } from "react";
import type { ProductData } from "@xxl/frontend-api";
import type { CartItem, GenericGraphQLError } from "./Api/types";
import { setItemServices } from "./Services/setItemServices";
import { SERVICES_ADD_ON_SALES_PERSONALIZED_KEY } from "../Services/API/ServicesAPI";
import type { CartItemIdInput } from "../../generated/graphql-code-generator";

export const SERVICES_REQUEST_SUCCESS = "SERVICES_REQUEST_SUCCESS";
const SERVICES_ADDED = "SERVICES_ADDED";
export const SERVICES_REMOVED = "SERVICES_REMOVED";
export const SERVICES_DECREASE_QUANTITY = "SERVICES_DECREASE_QUANTITY";
export const SERVICES_INCREASE_QUANTITY = "SERVICES_INCREASE_QUANTITY";
export const SERVICES_HAS_ERROR = "SERVICES_HAS_ERROR";

export type CartServiceProductState = {
  chosenService?: string;
  services?: CartItem[];
  serviceAdded?: string;
  serviceEntryNumber?: number;
  productQuantity?: number;
  servicesQuantity?: number;
  parentEntryNumber?: string;
  parentId?: CartItemIdInput;
  cartServices?: CartItem[];
  generalErrors?: GenericGraphQLError[];
};

type StateStruct = {
  current: CartServiceProductState;
  prev?: CartServiceProductState;
};

type ApiResponseWrapper = {
  response: ServicesResponse[];
};

type ServicesResponse = {
  key: string;
  numFound: number;
  productList: ProductData[];
};

type ServiceAddedProps = {
  serviceAdded: string;
  serviceEntryNumber: number;
  parentId: CartItemIdInput;
};

type ServicesRequestSuccessProps = {
  data: ApiResponseWrapper;
  cartServices: CartItem[];
  productQuantity: number;
  parentEntryNumber: string;
  parentId: CartItemIdInput;
};

type Action =
  | {
      type: typeof SERVICES_REQUEST_SUCCESS;
      payload: ServicesRequestSuccessProps;
    }
  | {
      type: typeof SERVICES_ADDED;
      payload: ServiceAddedProps;
    }
  | { type: typeof SERVICES_REMOVED }
  | { type: typeof SERVICES_DECREASE_QUANTITY }
  | { type: typeof SERVICES_INCREASE_QUANTITY }
  | { type: typeof SERVICES_HAS_ERROR; payload: GenericGraphQLError[] };

type DefaultValueOfCartServicesProductsState = {
  state: CartServiceProductState;
  dispatch: (action: Action) => void;
};

export const getInitialState = (
  cartServices: CartItem[] | undefined
): StateStruct => {
  return {
    current: { cartServices },
  };
};

const reducerInternal = (
  state: CartServiceProductState,
  action: Action
): CartServiceProductState => {
  switch (action.type) {
    case SERVICES_REQUEST_SUCCESS: {
      const {
        cartServices,
        data,
        parentEntryNumber,
        parentId,
        productQuantity,
      } = action.payload;
      return {
        ...state,
        services: setItemServices(
          data.response.find(
            (item) => item.key === SERVICES_ADD_ON_SALES_PERSONALIZED_KEY
          )?.productList ?? []
        ),
        cartServices,
        servicesQuantity: cartServices
          .map((service) =>
            service.quantity === undefined ? 0 : service.quantity
          )
          .reduce((prev, curr) => prev + curr, 0),
        productQuantity,
        parentEntryNumber,
        parentId,
        generalErrors: undefined,
      };
    }
    case SERVICES_ADDED:
      return {
        ...state,
        serviceAdded: action.payload.serviceAdded,
        serviceEntryNumber: action.payload.serviceEntryNumber,
        parentId: action.payload.parentId,
        generalErrors: undefined,
      };
    case SERVICES_REMOVED:
      return {
        ...state,
        serviceAdded: undefined,
        serviceEntryNumber: undefined,
      };
    case SERVICES_DECREASE_QUANTITY:
      return {
        ...state,
        servicesQuantity: (state.servicesQuantity ?? 1) - 1,
      };
    case SERVICES_INCREASE_QUANTITY:
      return {
        ...state,
        servicesQuantity: (state.servicesQuantity ?? 0) + 1,
      };
    case SERVICES_HAS_ERROR:
      return {
        ...state,
        generalErrors: action.payload,
      };
    default:
      return state;
  }
};

export const reducer = (
  stateStruct: StateStruct,
  action: Action
): StateStruct => {
  return {
    prev: stateStruct.current,
    current: reducerInternal(stateStruct.current, action),
  };
};

const initialStaticState: CartServiceProductState = { cartServices: [] };

export const CartServiceProductsContext =
  createContext<DefaultValueOfCartServicesProductsState>({
    dispatch: (): void => {
      return;
    },
    state: initialStaticState,
  });

export const useServicesContext = (): DefaultValueOfCartServicesProductsState =>
  useContext(CartServiceProductsContext);

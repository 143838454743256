import styled from "@emotion/styled/macro";
import Link from "next/link";
import { Button } from "react-app/src/components/Common";
import { typographyToCss } from "react-app/src/styles/helpers";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

const { colors } = xxlTheme;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const SeeFullProductPage = styled(StyledLink)<{
  align: "start" | "end";
}>`
  ${typographyToCss(xxlTheme.typography.smallBold)};
  align-self: ${({ align }) => align};
  margin-right: 20%;
`;

const CloseButton = styled(Button)`
  color: ${colors.xxlWebBlack};
  border: none;
  background: none;
  align-self: end;
  padding: 27px;
`;

export { CloseButton, SeeFullProductPage };

const preventNonNumericKeyPress = (
  event: React.KeyboardEvent<HTMLInputElement>
): void => {
  if (event.key === "-" || event.key === "+" || event.key === ".") {
    event.preventDefault();
  }
};

/**
 * Handles integer numeric inputs
 *
 * @param {React.KeyboardEvent<HTMLInputElement>} event
 * @param {React.RefObject<HTMLInputElement>} inputRef? reference to input element that we want to focus out after pressing Enter
 */
const handleNumKeyDown = (
  event: React.KeyboardEvent<HTMLInputElement>,
  inputRef?: React.RefObject<HTMLInputElement>
): void => {
  preventNonNumericKeyPress(event);
  if (event.key === "Enter") {
    inputRef?.current?.blur();
  }
};

export { preventNonNumericKeyPress, handleNumKeyDown };

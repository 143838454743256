export default {
  form: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 12,
    lineHeight: 1.2,
    letterSpacing: -0.15,
  },
  heading: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 24,
    letterSpacing: -0.01,
  },
  description: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 15,
    letterSpacing: -0.01,
  },
};

import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { Link } from "@xxl/content-api";
import type { HeaderLink } from "../../../global";

const MEGA_MENU_BUTTON_CLASS_NAME = "js-mega-menu__button";

const isTargetMegaMenuButton = (target: EventTarget | null) => {
  const megaMenuButton = document.querySelector(
    `.${MEGA_MENU_BUTTON_CLASS_NAME}`
  );
  if (!(target instanceof HTMLElement)) {
    return false;
  }
  return megaMenuButton !== null && megaMenuButton.contains(target);
};

const getNonUndefinedHeaderLinks = (headerlinks: Link[]) =>
  headerlinks.filter(
    (link): link is HeaderLink =>
      isNotNullOrUndefined(link.displayName) && isNotNullOrUndefined(link.url)
  );

export { getNonUndefinedHeaderLinks, isTargetMegaMenuButton };

import styled from "@emotion/styled/macro";
import { typographyToCss } from "../../../styles/helpers";
import colors from "./MegaMenu.colors";
import text from "./MegaMenu.typography";

export const SectionHeaderTemporary = styled.a`
  ${typographyToCss(text.sectionHeaderTemporary)};
  overflow: hidden;
  color: ${colors.textColor};
  text-overflow: ellipsis;
  hyphens: auto;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  &:hover,
  &:focus {
    color: ${colors.hoverColor};
  }
`;

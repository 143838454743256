import { useRef, useState } from "react";
import { useInView } from "framer-motion";
import { Stack } from "@mui/material";
import { StickyAddToCartButton } from "./StickyAddToCartButton";
import { ProductFormButtonContainer } from "./ProductForm.styles";
import { AddToCartWarning } from "../AddToCartWarning";
import { SizeOptions } from "./SizeOptions";
import type { TProductForm } from "./types";
import { BUTTON_SIZE_FOR_SIZE_OPTIONS } from "./constants";
import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { useXxlMediaQuery } from "@/react-hooks/useXxlMediaQuery";
import { SALES_METHODS } from "@/react-hooks/useProductData/constants";
import { xxlTheme } from "@/react-app/styles/xxl-theme";
import { ErrorBoundary } from "@/react-components/Common/ErrorBoundary/ErrorBoundary";
import { ClickAndCollect } from "@/react-components/ClickAndCollect";
import { XxlButton } from "@/react-components/Common/XxlButton";
import { CLEAR_ERROR_TIMEOUT_MS } from "@/hooks/useAddToCartError";
import { VolumentalComponent } from "./VolumentalComponent";

const ADD_TO_CART_BUTTON_ID = "product-add-to-cart";

export const ProductFormComponent = ({
  busyState,
  children,
  isDkMarketInProd,
  isClickAndCollectEnabled,
  isPurchaseAllowed,
  isSizeSelectDrawerOpen,
  isCnCDialogOpen,
  toggleIsCnCDialogOpen,
  onAddToCart,
  onSelectSize,
  preSelectedSizeCode,
  quantityToggleEnabled,
  salesMethod,
  salesPrice,
  selectedOption,
  sizeOptions,
  styleCode,
  shouldShowfitstation,
  volumentalId,
  hasConfigurations,
  toggleIsSizeSelectDrawerOpen,
  unit,
}: TProductForm) => {
  const { t } = useTranslations();
  const isLaptopSize = useXxlMediaQuery("LaptopMediaQuery");
  const addToCartBtnRef = useRef(null);
  const isAddToCartBtnInView = useInView(addToCartBtnRef);
  const [activeError, setActiveError] = useState(false);

  const btnTxt = !hasConfigurations
    ? t("product.details.add.to.cart.label")
    : t("product.details.configure.product");

  const handleStickyAddToCartClick = () => {
    onAddToCart();
    const addToCartElement = document.getElementById(ADD_TO_CART_BUTTON_ID);
    if (addToCartElement !== null) {
      addToCartElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const isSalesMethodDisabled = salesMethod === SALES_METHODS.NOT_ON_INTERNET;

  const isAddToCartBusy = busyState === "LOADING_REGULAR_ADD_TO_CART";
  const isAddToCartDisabled =
    !isPurchaseAllowed ||
    isAddToCartBusy ||
    isSalesMethodDisabled ||
    (selectedOption !== undefined &&
      selectedOption.stockStatus === "OUTOFSTOCK") ||
    isDkMarketInProd;

  const volumentalEanItem = sizeOptions.find((item) => item.ean !== undefined);
  const volumentalEanCode =
    volumentalEanItem !== undefined && volumentalEanItem.ean !== undefined
      ? volumentalEanItem.ean
      : styleCode;

  return (
    <Stack spacing={xxlTheme.spaces.micro}>
      <ErrorBoundary>
        {shouldShowfitstation && (
          <VolumentalComponent
            productId={volumentalEanCode}
            volumentalId={volumentalId}
          />
        )}
        <SizeOptions
          buttonSize={BUTTON_SIZE_FOR_SIZE_OPTIONS}
          isPurchaseAllowed={isPurchaseAllowed}
          isSizeSelectDrawerOpen={isSizeSelectDrawerOpen}
          onSelectSize={onSelectSize}
          preSelectedSizeCodeOrEan={preSelectedSizeCode}
          styleCode={styleCode}
          quantityToggleEnabled={quantityToggleEnabled}
          sizeOptions={sizeOptions}
          toggleIsSizeSelectDrawerOpen={toggleIsSizeSelectDrawerOpen}
          unit={unit}
          isClickAndCollectEnabled={isClickAndCollectEnabled}
        />
      </ErrorBoundary>
      <ProductFormButtonContainer
        isClickAndCollectEnabled={isClickAndCollectEnabled}
      >
        <XxlButton
          data-testid={ADD_TO_CART_BUTTON_ID}
          disabled={isAddToCartDisabled}
          id={ADD_TO_CART_BUTTON_ID}
          loading={isAddToCartBusy}
          onClick={() => {
            setActiveError(true);
            setTimeout(() => {
              setActiveError(false);
            }, CLEAR_ERROR_TIMEOUT_MS);
            onAddToCart();
          }}
          type="submit"
          ref={addToCartBtnRef}
          icon={busyState === "ADD_TO_CART_SUCCESS" ? "Check" : "Plus"}
        >
          {busyState === "ADD_TO_CART_SUCCESS"
            ? t("product.details.clickcollect.title.added")
            : btnTxt}
        </XxlButton>
        {!isLaptopSize && (
          <ErrorBoundary>
            <StickyAddToCartButton
              disabled={isAddToCartDisabled}
              isStickyBtnVisible={!isAddToCartBtnInView}
              loading={isAddToCartBusy}
              onClick={handleStickyAddToCartClick}
              salesPrice={salesPrice}
            >
              {btnTxt}
            </StickyAddToCartButton>
          </ErrorBoundary>
        )}
        {isClickAndCollectEnabled && (
          <ErrorBoundary>
            <ClickAndCollect
              isDisabledExternal={isSalesMethodDisabled || !isPurchaseAllowed}
              isLoadingExternal={busyState === "LOADING_CC_ADD_TO_CART"}
              onAddToCart={(storeId?: string) => onAddToCart(storeId)}
              isSuccess={busyState === "COLLECT_SUCCESS"}
              isCnCDialogOpen={isCnCDialogOpen}
              toggleIsCnCDialogOpen={toggleIsCnCDialogOpen}
            />
          </ErrorBoundary>
        )}
      </ProductFormButtonContainer>

      {activeError && <AddToCartWarning />}

      {children}
    </Stack>
  );
};

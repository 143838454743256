import styled from "@emotion/styled/macro";
import { MQ } from "react-app/src/styles/helpers";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { resolutionSizes } from "react-app/src/config";
import { Text } from "react-app/src/components/Text";

const {
  spaces: { large, regular, smallRegular },
} = xxlTheme;

const sharedMargin = `
  margin-bottom: 0;
  margin-top: ${smallRegular};
  ${MQ("laptop")} {
    margin-top: ${large};
  }
`;

export const Ul = styled.ul`
  ${sharedMargin}
  padding-left: ${regular};
`;

export const Article = styled.article`
  > * {
    ${sharedMargin}
  }
`;

export const Description = styled(Text)`
  .pdp_description--highlight {
    // Is defined in https://bitbucket.org/xxldevelopment/master-products-service
    font-family: var(--font-family-bold);
  }
`;

export const LinkWrapper = styled.div`
  display: grid;
  grid-gap: ${xxlTheme.spaces.mini};
  grid-template-columns: 20px 1fr;
  margin-top: ${xxlTheme.spaces.smallRegular};
  @media (min-width: ${resolutionSizes.laptop}px) {
    margin-top: ${xxlTheme.spaces.large};
  }

  & > span {
    font-family: var(--font-family-bold);
    text-decoration: underline;
  }
`;

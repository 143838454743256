import { PaymentProvider } from "../../generated/graphql-code-generator";

export const INCREASE_ITEM_QUANTITY_BUTTON_TEST_ID =
  "increase-item-quantity-button";
export const DECREASE_ITEM_QUANTITY_BUTTON_TEST_ID =
  "decrease-item-quantity-button";
export const ITEM_QUANTITY_TEST_ID = "item-quantity";
export const REMOVE_ITEM_BUTTON_TEST_ID = "remove-item-button";
export const TOGGLE_DESCRIPTION_BUTTON_TEST_ID = "toggle-description-button";
export const FULL_DESCRIPTION_TEST_ID = "full-description";
export const BUNDLE_FALLBACK_IMAGE_URL =
  "/static/svg/bundle-fallback-image.svg";

export const ITEM_IMAGE_TEST_ID = "item-image";
export const ITEM_BRAND_TEST_ID = "item-brand";
export const ITEM_NAME_TEST_ID = "item-name";
export const DEFAULT_QUANTITY = 1;
export const DEFAULT_QUANTITY_STRING = DEFAULT_QUANTITY.toString();
export const DEFAULT_PAYMENT_PROVIDER = PaymentProvider.WALLEY;

/**
 * Walley client side events
 * See more: https://dev.walleypay.com/docs/checkout/optional/clientevents
 */
export const WalleyCheckoutEvents = {
  /**
   * Occurs when the purchase has been completed.
   */
  purchaseCompleted: "walleyCheckoutPurchaseCompleted",
  /**
   * Indicates that the delivery selection has changed.
   */
  shippingUpdated: "walleyCheckoutShippingUpdated",
  /**
   * Occurs when order validation returns a non-2XX response.
   * If a clientPayload object is returned in the validation response, it is sent as a payload parameter here.
   * This can be useful if the client-side code needs information about why the validation failed.
   */
  orderValidationFailed: "walleyCheckoutOrderValidationFailed",
  /**
   * Occurs when no user input should be accepted, for instance during processing of a purchase.
   */
  locked: "walleyCheckoutLocked",
  /**
   * Occurs after a locked event when it is safe to allow user input again.
   * For instance after a purchase has been processed (regardless of whether the purchase was successful or not).
   */
  unlocked: "walleyCheckoutUnlocked",
  /**
   * Occurs when the Checkout has loaded new data and is back in its normal state after suspend.
   */
  resumed: "walleyCheckoutResumed",
  /**
   * Occurs when the customer has changed any information for the CRM module, such as toggling to apply for membership.
   */
  crmUpdated: "walleyCheckoutCrmUpdated",
} as const;

import * as yup from "yup";
import type { Translate } from "../../../../contexts/Translations/TranslationsContext";
import type { TranslationKey } from "../../../../translations";
import type { GenericGraphQLError } from "../../../Cart/Api/types";
import type { GraphQLError } from "../../../../graphql/graphqlApi";
import type { UserAccountProps } from "../../../UserDetails/UserAPI";
import isEmpty from "lodash/isEmpty";

const initialValues = {
  username: "",
  password: "",
};

type FormData = typeof initialValues;

const validationSchema = (t: Translate) =>
  yup.object({
    username: yup.string().required(t("form.field.required")),
    password: yup.string().required(t("form.field.required")),
  });

type Field = {
  autoCompleteToken: "username" | "current-password";
  fieldName: keyof FormData;
  labelTranslationKey: TranslationKey;
  placeholderTranslationKey: TranslationKey;
  inputMode: "email" | "text";
  type: "email" | "password";
};

const fields: Field[] = [
  {
    autoCompleteToken: "username",
    fieldName: "username",
    labelTranslationKey: "form.email",
    placeholderTranslationKey: "form.email",
    inputMode: "email",
    type: "email",
  },
  {
    autoCompleteToken: "current-password",
    fieldName: "password",
    labelTranslationKey: "form.password",
    placeholderTranslationKey: "form.password",
    inputMode: "text",
    type: "password",
  },
];

const hasNotFoundError = (errors: GraphQLError[] = []) => {
  const notFoundError = errors.find((error) => {
    return (error as GenericGraphQLError).errorType === "NOT_FOUND";
  });
  return notFoundError !== undefined;
};

const getMissingFields = (
  account: UserAccountProps | null,
  toggle_social_security_number: boolean
) => {
  const {
    memberNumber,
    socialSecurityNumber,
    consents,
    email,
    firstName,
    lastName,
    mobilePhone,
  } = account ?? {};

  const hasSocialSecurityNumber = Boolean(socialSecurityNumber);

  const requireSocialSecurityNumber =
    !hasSocialSecurityNumber && toggle_social_security_number;

  const requiredFields = {
    memberNumber,
    email,
    firstName,
    lastName,
    mobilePhone,
    consents,
  };

  const missingFields = Object.keys(requiredFields).filter((key) => {
    const value = requiredFields[key as keyof typeof requiredFields];
    return isEmpty(value);
  });
  if (requireSocialSecurityNumber) {
    missingFields.push("socialSecurityNumber");
  }

  return missingFields;
};

export {
  fields,
  initialValues,
  validationSchema,
  hasNotFoundError,
  getMissingFields,
};
export type { FormData };

import type { AxiosResponse } from "axios";
import axios from "axios";
import type { SizeGuide } from "@xxl/content-api";

const nextFrontendApi = {
  getSizeGuide: async (
    brandCode: string,
    categoryCodes: string[]
  ): Promise<AxiosResponse<SizeGuide>> =>
    await axios.get(
      `/api/content/sizeguide/${brandCode}/${categoryCodes.toString()}`
    ),
};

export { nextFrontendApi };

import React from "react";
import {
  Wrapper,
  DialogBoxHeading,
  Description,
  SubmitButtonWrapper,
} from "../Styles/TeamAdminBox.styled";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { XxlButton } from "../../Common/XxlButton";

type TeamAdminBoxConfirmationProps = {
  closeTrigger: () => void;
};

export const TeamAdminBoxConfirmation: React.FunctionComponent<
  TeamAdminBoxConfirmationProps
> = ({ closeTrigger }) => {
  const { t } = useTranslations();
  return (
    <Wrapper hasPadding={false}>
      <DialogBoxHeading>
        {t("teamsales.requisition.cart.confirmation.heading")}
      </DialogBoxHeading>
      <Description>
        {t("teamsales.requisition.cart.confirmation.description")}
      </Description>
      <SubmitButtonWrapper>
        <XxlButton
          type="button"
          variant="secondary"
          size="large"
          style={{
            minWidth: "300px",
            width: "fit-content",
          }}
          onClick={closeTrigger}
        >
          {t("teamsales.requisitions.buttons.close")}
        </XxlButton>
      </SubmitButtonWrapper>
    </Wrapper>
  );
};

import { MiniPdp } from "@/components/MiniPdp/MiniPdp";
import type { AdditionalSales } from "@/react-utils/ProductData/product-card-data-helper";
import { log } from "@xxl/logging-utils";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import type { QuickShopData } from "react-app/src/global";
import * as XxlEvent from "react-app/src/utils/xxl-event";
import type { AddToCartButtonProps } from "src/components/MiniPdp/MiniPdpContent";
import { QuickShopAddToCart } from "./QuickShopAddToCart";

export const QuickShop = () => {
  const [productCode, setProductCode] = useState<string>("");
  const [ticket, setTicket] = useState<string | undefined>(undefined);
  const [additionalSales, setAdditionalSales] = useState<AdditionalSales>();

  useEffect(() => {
    const eventHandler = (event: CustomEvent<QuickShopData>) => {
      const {
        productCode: selectedProductCode,
        ticket: _ticket,
        additionalSales: _additionalSales,
      } = event.detail;
      setAdditionalSales(_additionalSales);
      setProductCode(selectedProductCode);
      setTicket(_ticket);
    };

    XxlEvent.addXXLEventListener(
      XxlEvent.type.XXL_SET_QUICKSHOP_PRODUCTCODE,
      eventHandler as EventListener
    );

    return () => {
      XxlEvent.removeXXLEventListener(
        XxlEvent.type.XXL_SET_QUICKSHOP_PRODUCTCODE,
        eventHandler as EventListener
      );
    };
  }, [productCode, setProductCode]);

  const onChangeProductStyle = ({
    code,
    event,
  }: {
    code: string;
    event: React.MouseEvent;
  }) => {
    event.preventDefault();
    if (isEmpty(code)) {
      log.error("Code is empty in onChangeProductStyle");
      return;
    }
    setProductCode(code);
  };

  const closeQuickShop = () => setProductCode("");

  return productCode !== "" ? (
    <div data-testid="quickshop-mini-pdp">
      <MiniPdp
        addToCartButton={(props: AddToCartButtonProps) => (
          <QuickShopAddToCart
            additionalSales={additionalSales}
            closeQuickShop={closeQuickShop}
            productData={props.productData}
            selectedProductCode={productCode}
            ticket={ticket}
          />
        )}
        handleClose={() => setProductCode("")}
        isOpen={!isEmpty(productCode)}
        isQuickShop={true}
        onChangeProductStyle={onChangeProductStyle}
        productCode={productCode}
        shouldEvaluateProductConfiguratorPrice={false}
        sizeOptionsComponent={null}
      />
    </div>
  ) : null;
};

import React from "react";
import type { ImageData } from "@xxl/frontend-api";
import { Image, IMAGE_WIDTH, PADDING } from "./Campaign.styled";
import { getImageUrl, handleImageError } from "../../../utils/xxl-image";

const HEIGHT = 119;
const WIDTH = 212;
const DEFAULT_SIZE = 400;
const sizes = [200, 300, DEFAULT_SIZE, 600, 800, 1000, 1200];
const slotWidth = `calc(${IMAGE_WIDTH}vw - ${PADDING} - ${PADDING})`;

const CampaignImage: React.FunctionComponent<{
  imageData: ImageData;
}> = ({ imageData }) => (
  <Image
    data-private={true}
    alt={imageData.alt}
    height={HEIGHT}
    width={WIDTH}
    loading="lazy"
    sizes={`${sizes
      .map((width) => `(max-width: ${width}px) ${slotWidth}`)
      .join()}, ${slotWidth}`}
    srcSet={sizes
      .map((width) => `${getImageUrl(imageData, width)}&w=${width} ${width}w`)
      .join()}
    src={getImageUrl(imageData, DEFAULT_SIZE)}
    onError={handleImageError}
  />
);

export { CampaignImage };

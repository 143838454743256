/**
 * Converts a hexadecimal color string into an RGB color string.
 *
 * Example:
 * hexToRgb("#99F500", 0.15)
 * rgb(255, 191, 0, 0.15)
 *
 * @param hex
 * @param alpha
 * @returns
 */
const hexToRGB = (hex: string, alpha?: number): string => {
  const hexWithoutHash = hex.replace("#", "");
  const createRGBColorString = (
    r: number,
    g: number,
    b: number,
    alpha?: number
  ): string => {
    return `rgb(${r},${g},${b}${alpha !== undefined ? `,${alpha}` : ""})`;
  };
  const createRgbValue = (hexValue: string) => parseInt(hexValue, 16);
  const short = 3;
  const long = 6;

  if (hexWithoutHash.length === short) {
    return createRGBColorString(
      createRgbValue(`${hexWithoutHash[0]}${hexWithoutHash[0]}`),
      createRgbValue(`${hexWithoutHash[1]}${hexWithoutHash[1]}`),
      createRgbValue(`${hexWithoutHash[2]}${hexWithoutHash[2]}`),
      alpha
    );
  }

  if (hexWithoutHash.length === long) {
    return createRGBColorString(
      createRgbValue(`${hexWithoutHash[0]}${hexWithoutHash[1]}`),
      createRgbValue(`${hexWithoutHash[2]}${hexWithoutHash[3]}`),
      createRgbValue(`${hexWithoutHash[4]}${hexWithoutHash[5]}`),
      alpha
    );
  }

  throw new Error(
    `[hexToRGB] The supplied hex value, exlcuding "#", must be ${short} or ${long} digits. The length of ${hexWithoutHash} is ${hexWithoutHash.length}.`
  );
};

export { hexToRGB };

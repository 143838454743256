import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { shrinkAnimation } from "../../styled/mixins";
import { xxlTheme } from "../../styles/xxl-theme";
import { typographyToCss, MQ, xxlHexa } from "../../styles/helpers";
import { BUTTON_WIDTH } from "../Search/FetchMoreDataButton/FetchMoreDataButton.styled";

const { colors, spaces, zIndex } = xxlTheme;

const CLICK_ANIMATION_DURATION = "200ms";
const iconColorInactive = colors.xxlDarkGrey;
const iconColorActive = colors.xxlWebBlack;

export const typography = {
  heading: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 20,
    lineHeight: 1.2,
    letterSpacing: -0.15,
    laptop: {
      fontSize: 24,
    },
  },
  emptyPageDescription: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 15,
    lineHeight: 1.2,
    letterSpacing: -0.15,
    textAlign: "center",
    laptop: {
      fontSize: 24,
    },
  },
  confirmationText: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 15,
    lineHeight: 1.2,
    letterSpacing: -0.15,
  },
  confirmationLinkText: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 12,
    lineHeight: 1.2,
    letterSpacing: -0.15,
  },
};

const {
  heading,
  emptyPageDescription,
  confirmationLinkText,
  confirmationText,
} = typography;

type FavoriteButtonProps = {
  isFavorite: boolean;
  isInPDP?: boolean;
};
export const FavoriteButton = styled.button<FavoriteButtonProps>(
  ({ isFavorite, isInPDP = false }) => css`
    height: 30px;
    padding: 0 ${spaces.miniMicro} 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    transition: transform ${CLICK_ANIMATION_DURATION};
    cursor: pointer;

    & svg {
      stroke-width: 2;
      stroke: ${iconColorInactive};
      fill: transparent;
      transition-property: stroke, fill;
      transition-duration: ${CLICK_ANIMATION_DURATION};
      width: 24px;
      height: auto;

      ${MQ("tablet")} {
        width: 24px;
      }
    }

    &:hover {
      & svg {
        transform: scale(1.1);
        stroke: ${iconColorActive};
      }
    }

    &:focus {
      ${shrinkAnimation({
        name: "favorite-button-shrink-on-click",
        duration: CLICK_ANIMATION_DURATION,
        initialScale: 0.9,
      })}
    }

    ${isFavorite &&
    css`
      ${shrinkAnimation({
        name: "favorite-button-shrink-initially",
        duration: CLICK_ANIMATION_DURATION,
        initialScale: 1,
      })};
      & svg {
        stroke: ${iconColorActive};
        fill: ${iconColorActive};
      }
    `}

    ${isInPDP &&
    css`
      width: 40px;
      height: 40px;
      padding: 0;
      margin-left: auto;
      align-items: start;
      z-index: ${zIndex.body};

      & svg {
        width: 30px;
      }
    `}
  `
);

export const FavoritesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50dvh;
`;

export const FavoritesHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: ${spaces.smallRegular};

  ${MQ("tablet")} {
    margin-bottom: ${spaces.large};
    align-items: center;
    flex-direction: row;
  }
`;

type HeadingProps = {
  isEmptyPage?: boolean;
};

export const FavoritesHeading = styled.h1<HeadingProps>(
  ({ isEmptyPage = false }) => css`
    ${typographyToCss(heading)};
    margin: 0 0 ${isEmptyPage ? spaces.giant : spaces.large};

    ${MQ("laptop")} {
      margin-bottom: ${isEmptyPage ? spaces.biggerGiant : spaces.big};
    }
  `
);

export const FavoritesEmptyPageContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${spaces.big};
`;

export const FavoritesEmptyIcon = styled.span`
  display: flex;
  width: 56px;
  height: 56px;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

export const FavoritesEmptyDescription = styled.p`
  ${typographyToCss(emptyPageDescription)};
  margin: 0;
`;

export const ShowMoreButton = styled.button`
  width: 100%;
  max-width: ${BUTTON_WIDTH};
`;

export const SharedOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: ${zIndex["image-banner"]};
  inset: 0;
  pointer-events: none;
  background-color: ${xxlHexa(colors.xxlWebBlack, 0.03)};
`;

type ButtonsWrapperProps = {
  isSticky: boolean;
};

export const ButtonsWrapper = styled.div<ButtonsWrapperProps>(
  ({ isSticky }) => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: ${spaces.smallRegular};
    box-sizing: border-box;

    ${isSticky &&
    css`
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: ${colors.xxlWhite};
      z-index: ${zIndex.modal};
      padding: ${spaces.smallRegular};

      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 4px;
        top: -4px;
        left: 0;
        background: linear-gradient(
          0deg,
          ${xxlHexa(colors.xxlWebBlack, 0.05)},
          transparent
        );
      }
    `}
  `
);

export const StyledButton = styled.button`
  width: 100%;
  margin: 0 auto;

  ${MQ("tablet")} {
    width: auto;
    max-width: none;
    margin: 0;
  }

  & > svg {
    width: 18px;
    height: 18px;
    margin-right: ${spaces.mini};
  }
`;

export const ConfirmationBar = styled.div`
  width: 100%;
  height: 46px;
  background-color: ${colors.xxlLightGreen};
  position: relative;
  z-index: ${zIndex.modal};
`;

export const ConfirmationContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ConfirmationLink = styled.a`
  ${typographyToCss(confirmationLinkText)};
  color: ${colors.xxlWebBlack};
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > svg {
    margin-left: ${spaces.mini};
    width: 18px;
    height: 18px;
  }
`;

export const ConfirmationText = styled.p`
  ${typographyToCss(confirmationText)};
  margin: 0 0 0 ${spaces.mini};
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;

  & > svg {
    width: 22px;
    height: 22px;
    margin-right: ${spaces.mini};
  }
`;

import type { PaperProps } from "@mui/material";
import { Dialog } from "@mui/material";
import type { TransitionHandlerProps } from "@mui/material/transitions/transition";
import type { PropsWithChildren } from "react";
import React from "react";
import { useXxlMediaQuery } from "../../../src/hooks/useXxlMediaQuery";
import { xxlTheme } from "../../styles/xxl-theme";
import { CloseButton } from "../Drawer/Drawer.styles";
import { Icon } from "../Icon";
import { Text } from "../Text";
import { DialogBoxContent, HeaderContainer } from "./DialogBox.styled";

type DialogBoxProps = {
  isDialogBoxOpen: boolean;
  isFullWidth?: boolean;
  isFullHeight?: boolean;
  handleDialogBoxClosing?: () => void;
  handleDialogBoxOpening?: TransitionHandlerProps["onEnter"];
  hasPadding?: boolean;
  headerId?: string;
  dialogBoxHeadline?: string;
  dialogBoxSize?: "xs" | "sm" | "md" | "lg" | "xl";
  dialogFooterContent?: React.ReactNode;
  keepMounted?: boolean;
  style?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  disableEnforceFocus?: boolean;
  testId?: string;
  paperProps?: PaperProps;
  hideDefaultCloseButton?: boolean;
  hideShadow?: boolean;
};

export const DIALOG_FULL_SCREEN_BREAK_POINT = "sm";

const DialogBox = ({
  children,
  contentStyle,
  dialogBoxHeadline,
  dialogBoxSize,
  dialogFooterContent = null,
  disableEnforceFocus,
  handleDialogBoxClosing,
  handleDialogBoxOpening,
  hasPadding = true,
  headerId,
  hideDefaultCloseButton = false,
  hideShadow = false,
  isDialogBoxOpen,
  isFullWidth = true,
  isFullHeight = false,
  keepMounted = false,
  paperProps,
  style,
  testId,
}: PropsWithChildren<DialogBoxProps>) => {
  const { spaces } = xxlTheme;
  const isDialogFullScreen = useXxlMediaQuery("MobileMediaQuery");

  const showHeadline =
    typeof dialogBoxHeadline === "string" ||
    typeof dialogBoxHeadline === "number";

  return (
    <Dialog
      fullWidth={isFullWidth}
      maxWidth={dialogBoxSize ?? false}
      fullScreen={isDialogFullScreen}
      keepMounted={keepMounted}
      onClose={handleDialogBoxClosing}
      open={isDialogBoxOpen}
      aria-labelledby={headerId}
      disableEnforceFocus={disableEnforceFocus}
      style={style}
      PaperProps={{
        square: true,
        ...(isFullHeight
          ? { sx: { minHeight: `calc(100% - ( 2 * ${spaces.bigSmall}))` } }
          : undefined),
        ...paperProps,
      }}
      TransitionProps={{
        onEnter: handleDialogBoxOpening,
      }}
      data-testid={testId}
    >
      {showHeadline && (
        <HeaderContainer role="heading" hideShadow={hideShadow}>
          <Text
            typography="baseBold"
            sizeCustom={!isDialogFullScreen ? 24 : 20}
            id={headerId}
            data-testid="dialog-header"
          >
            {dialogBoxHeadline}
          </Text>
          {!hideDefaultCloseButton && (
            <CloseButton
              onClick={handleDialogBoxClosing}
              aria-label="close"
              data-testid="dialog-close-btn"
            >
              <Icon size={18} name="CloseIconSlim" />
            </CloseButton>
          )}
        </HeaderContainer>
      )}

      <DialogBoxContent style={contentStyle} {...{ hasPadding, isFullHeight }}>
        {children}
      </DialogBoxContent>
      {dialogFooterContent}
    </Dialog>
  );
};

export { DialogBox };

import styled from "@emotion/styled/macro";
import { typographyToCss } from "../../../styles/helpers";
import spaces from "../../../styles/theme/spaces";
import typography from "./Campaign.typography";

const { micro, mini, smallRegular } = spaces;

const PADDING = smallRegular;
const IMAGE_WIDTH = 60;

const CampaignLink = styled.a`
  ${typographyToCss(typography.link)}
  display: block;
  margin: ${smallRegular} 0 -${smallRegular};
  padding: ${PADDING};
`;

const Date = styled.span`
  ${typographyToCss(typography.date)}
  display: block;
  margin-top: ${micro};
`;

const Heading = styled.span`
  ${typographyToCss(typography.heading)}
  display: block;
  margin-bottom: ${smallRegular};
  width: 80%;
`;

const Image = styled.img`
  display: block;
  margin-top: ${mini};
  object-fit: cover;
  width: ${IMAGE_WIDTH}%;
`;

export { CampaignLink, Date, Heading, Image, IMAGE_WIDTH, PADDING };

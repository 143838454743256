import type { EcomSiteUid } from "@/react-app/global";
import { getElevateApiConfiguration } from "@/utils/environment-variables";
import {
  ElevateApi,
  PriceId,
  AllCustomAttributes,
} from "@xxl/product-search-api";
import type {
  LandingPageQuery,
  LandingPageRequestSettings,
  LandingPageResultData,
} from "@xxl/product-search-api";
import { convertSiteUidToHost } from "@/react-utils/xxl-shared-data";
import type { AxiosResponse } from "axios";
const elevateApi = ElevateApi.getInstance(getElevateApiConfiguration(true));

const getProductDiscountCount = async (
  categoryCode: string,
  ecomSite: EcomSiteUid
) => {
  const query: LandingPageQuery = {
    sessionKey: "1",
    customerKey: "1",
    site: convertSiteUidToHost(ecomSite),
    touchpoint: "DESKTOP",
    priceId: PriceId.ANONYMOUS,
    presentCustom: AllCustomAttributes,
    pageReference: `/dynamic/c/${categoryCode}`,
    limit: 1,
  };

  const initialData: LandingPageRequestSettings = {
    primaryList: {
      include: true,
      productRules: `rule incl custom.categoryIds { "${categoryCode}" } excl custom.isOnCampaign { "false"}  rule  incl custom.categoryIds { "${categoryCode}" } excl custom.isOnPromotion { "false"}`,
    },
  };
  const result: AxiosResponse<LandingPageResultData> =
    await elevateApi.storefront.landingPagePOST(query, initialData);
  return result.data.primaryList.totalHits;
};

export { getProductDiscountCount };

import React from "react";
import { XxlButton } from "react-app/src/components/Common/XxlButton";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { SizeOptions } from "./SizeOptions";
import { BUTTON_SIZE_FOR_SIZE_OPTIONS } from "./constants";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import type { TProductForm } from "./types";
import { Message, StickyFormContent } from "./styles";
import { useXxlMediaQuery } from "react-app/src/hooks/useXxlMediaQuery";

export const ProductFormPreSale = (
  props: TProductForm & {
    btnRef: React.MutableRefObject<null>;
    isRefInView: boolean;
  }
) => {
  const {
    btnRef,
    isClickAndCollectEnabled,
    isPurchaseAllowed,
    isRefInView,
    isSizeSelectDrawerOpen,
    onSelectSize,
    preSelectedSizeCode,
    quantityToggleEnabled,
    sizeOptions,
    styleCode,
    toggleIsSizeSelectDrawerOpen,
    unit,
  } = props;
  const { t } = useTranslations();
  const isLaptopSize = useXxlMediaQuery("LaptopMediaQuery");
  const preSaleContent = (
    <>
      <XxlButton icon="Email" onClick={toggleIsSizeSelectDrawerOpen}>
        {t("product.details.presale.form.button.label")}
      </XxlButton>
      <Message text={t("product.details.delivery.message.presale")} />
    </>
  );

  return (
    <>
      <XxlStack spacing={xxlTheme.spaces.micro} ref={btnRef}>
        <SizeOptions
          buttonSize={BUTTON_SIZE_FOR_SIZE_OPTIONS}
          isPurchaseAllowed={isPurchaseAllowed}
          isSizeSelectDrawerOpen={isSizeSelectDrawerOpen}
          onSelectSize={onSelectSize}
          preSelectedSizeCodeOrEan={preSelectedSizeCode}
          styleCode={styleCode}
          quantityToggleEnabled={quantityToggleEnabled}
          sizeOptions={sizeOptions}
          toggleIsSizeSelectDrawerOpen={toggleIsSizeSelectDrawerOpen}
          unit={unit}
          isClickAndCollectEnabled={isClickAndCollectEnabled}
        />
        {preSaleContent}
      </XxlStack>

      <StickyFormContent
        shouldShowStickyContent={!isRefInView && !isLaptopSize}
      >
        {preSaleContent}
      </StickyFormContent>
    </>
  );
};

import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { CaretDown } from "@xxl/icons";
import React from "react";
import { interactionFeedback, typographyToCss } from "../../../styles/helpers";
import typography from "../../../styles/theme/typography";
import { xxlTheme } from "../../../styles/xxl-theme";
import { focusVisibleStyles } from "../../Cart/Styles/ProductItem.styled";

const { spaces, colors } = xxlTheme;

type ToggleButtonStyledProps = {
  isToggled: boolean;
};

const ToggleButtonStyled = styled.button<ToggleButtonStyledProps>(
  ({ isToggled }) => css`
    ${typographyToCss(typography.smallBold)}
    display: inline-block;
    border: none;
    background-color: transparent;
    color: ${colors.xxlWebBlack};
    padding: 0;
    margin-top: ${spaces.mini};
    ${focusVisibleStyles};

    ${interactionFeedback(
      `cursor: pointer;
        text-decoration: none;`
    )}

    & svg {
      transition: 0.2s ease;
      margin-left: ${spaces.micro};
      font-size: ${spaces.regular};
      vertical-align: sub;

      ${isToggled &&
      css`
        transform: rotate(180deg);
      `}
    }
  `
);

type ToggleButtonProps = {
  children: React.ReactNode;
  className?: string;
  isToggled: boolean;
  onClick: () => void;
  testid?: string;
};

export const ToggleButton: React.FC<ToggleButtonProps> = ({
  children,
  className,
  isToggled,
  onClick,
  testid,
}) => (
  <ToggleButtonStyled
    data-testid={testid}
    type="button"
    onClick={onClick}
    isToggled={isToggled}
    className={className}
  >
    {children}
    <CaretDown />
  </ToggleButtonStyled>
);

import Script from "next/script";
import {
  checkIfRequredConsentsSelected,
  invokeAfterConsent,
} from "../../utils/invoke-after-consent";
import { useEffect, useState } from "react";
import { cookieCategories } from "react-app/src/utils/Cookie";

export const Symplify = ({ id }: { id: string }) => {
  const cookieConsentList = [cookieCategories.MARKETING];
  const hasUserAlreadyGivenConsent =
    checkIfRequredConsentsSelected(cookieConsentList);
  const [userHasGivenConsent, setUserHasGivenConsent] = useState(
    hasUserAlreadyGivenConsent
  );

  useEffect(() => {
    if (typeof id !== "undefined")
      invokeAfterConsent({
        callback: () => setUserHasGivenConsent(true),
        cookieConsentList,
      });
  }, [id]);

  return userHasGivenConsent ? (
    <Script
      type="text/javascript"
      onLoad={() => window.symplify.optIn(1)}
      src={`https://cdn-sitegainer.com/${id}/es6/index.bundle.js`}
    ></Script>
  ) : null;
};

import * as React from "react";
import { Label, Price, Wrapper } from "./Price.styled";

type SellingPriceProps = {
  priceFormatted: string;
  isDiscounted: boolean;
  salesPriceDisclaimer?: string;
};
const SellingPrice = ({
  isDiscounted,
  priceFormatted,
  salesPriceDisclaimer,
}: SellingPriceProps) => {
  return (
    <Wrapper>
      <Price
        isDiscounted={isDiscounted}
        data-price={priceFormatted}
        data-testid="sales-price"
      >
        {priceFormatted}
      </Price>
      {salesPriceDisclaimer !== undefined && (
        <Label>{salesPriceDisclaimer}</Label>
      )}
    </Wrapper>
  );
};

export { SellingPrice };

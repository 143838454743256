import { createReviewServiceClient } from "./requests";

export const CreateClientApi = (
  aws_appsync_apiKey: string,
  aws_appsync_graphqlEndpoint: string
) => ({
  ReviewService: createReviewServiceClient(
    aws_appsync_apiKey,
    aws_appsync_graphqlEndpoint
  ),
});

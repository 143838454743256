export const scrollToFilterBar = (): void => {
  if (typeof window !== "undefined") {
    const filterBarContainer = document.getElementById(
      "js-filter-bar-scroll-target"
    );

    const filterBarContainerPosition =
      filterBarContainer !== null ? filterBarContainer.offsetTop : 0;
    window.scrollTo({
      top: filterBarContainerPosition,
      behavior: "smooth",
    });
  }
};

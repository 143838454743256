import * as React from "react";
import { StarsContainer, StarContainer } from "./AverageRatingWrapper.styled";
import type { StarSize } from "../common/Star/Star";
import { Star } from "../common/Star/Star";

/**
 * Calculate how much of current star that should be filled based on average rating
 * Will be rounded to closest integer or .5
 */
export const getPercentageOfAverageRating = (
  averageRating: number,
  currentStar: number
): number => {
  const roundedAverage = Math.round(averageRating * 2) / 2;
  return Math.min(1, Math.max(0, roundedAverage + 1 - currentStar)) * 100;
};

type StarsDisplayProps = {
  rating: number;
  size?: StarSize;
  useBottomMargin?: boolean;
  gap?: number;
};

export const StarsDisplay: React.FunctionComponent<StarsDisplayProps> = ({
  rating,
  size = "sm",
  useBottomMargin = false,
  gap,
}) => {
  return (
    <StarsContainer
      data-testid="rr-average-rating-stars"
      useBottomMargin={useBottomMargin}
    >
      {[1, 2, 3, 4, 5].map((currentStar: number) => (
        <StarContainer key={currentStar} gap={gap}>
          <Star
            percentageFilled={getPercentageOfAverageRating(rating, currentStar)}
            size={size}
          />
        </StarContainer>
      ))}
    </StarsContainer>
  );
};

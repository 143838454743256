import React from "react";
import styled from "@emotion/styled/macro";
import { color } from "@xxl/theme";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { Icon } from "react-app/src/components/Icon";
import { Text } from "react-app/src/components/Text";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

const MessageContainer = styled(XxlStack)`
  background-color: ${color.lightAmber.cssVariable};
  padding: ${xxlTheme.spaces.smallRegular};
`;
export const Message = ({ text }: { text: string }) => (
  <MessageContainer direction="row" spacing="6px">
    <span>
      <Icon name="Info" />
    </span>
    <Text as="span">{text}</Text>
  </MessageContainer>
);

export const StickyFormContent = styled.div<{
  shouldShowStickyContent: boolean;
}>`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: ${xxlTheme.zIndex.navigation};
  transition: all 200ms ease-in-out;
  width: 100%;

  ${({ shouldShowStickyContent = false }) =>
    !shouldShowStickyContent && "bottom: -100%;"}
`;

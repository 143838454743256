import type { ConfigurationParameters } from "@xxl/content-api";
import type { ConfigurationParameters as SolrConfigurationParameters } from "@xxl/solr-api";
import type { EcomSiteUid, EcomSiteUidLegacy } from "react-app/src/global";
import type { CountryCodeLowerCased } from "../global";
import { markets } from "./constants";
export type EnvironmentVariables = {
  CONFIG_SITE_CONTENT_HEADERCODE: string;
  CONFIG_SITE_COOKIECULTURELANGUAGE: string;
  CONFIG_SITE_COOKIEVERSION: string;
  CONFIG_SITE_COUNTRY: string;
  CONFIG_SITE_CURRENCY: string;
  CONFIG_SITE_GTMID: string;
  CONFIG_SITE_KINDLYCHATBOTKEY: string;
  CONFIG_SITE_KINDLYCHATBOTLANGUAGE: string;
  CONFIG_SITE_KLARNALANGUAGE: string;
  CONFIG_SITE_LANGUAGES: string;
  CONFIG_SITE_SYMPLIFYID: string;
  CONFIG_SITE_TRIGGERBEESITEID: string;
  CONFIG_SITE_UID: EcomSiteUidLegacy;
  CONFIG_SITE_VIDEOLYID: string;
  CONFIG_SITE_VOLUMENTALID: string;
  KLARNA_PLACEMENT_WIDGET_CLIENTID: string;
  KLARNA_PLACEMENT_WIDGET_LOCALE: string;
  KLARNA_PLACEMENT_WIDGET_SHOW: boolean;
  PRISJAKT_SHOPID: string | null;
  PRISJAKT_MARKET: string | null;
  QR_HOST_PREFIX: string;
  REQUEST_MAPPING_CAMPAIGNHUBPAGE: string;
  REQUEST_MAPPING_LOGIN: string;
  REQUEST_MAPPING_MY_ACCOUNT: string;
  REQUEST_MAPPING_BRAND_INDEX: string;
  REQUEST_MAPPING_BROKENCARTPAGE: string;
  REQUEST_MAPPING_BUYGIFTCARD: string;
  REQUEST_MAPPING_CUSTOMERSERVICE: string;
  REQUEST_MAPPING_FAQ: string;
  REQUEST_MAPPING_FAQGIFTCARD: string;
  REQUEST_MAPPING_GUIDES_INTERFIX: string;
  REQUEST_MAPPING_GUIDES: string;
  REQUEST_MAPPING_MY_ACCOUNT_FAVORITES: string;
  REQUEST_MAPPING_MY_ACCOUNT_FITSTATION: string;
  REQUEST_MAPPING_MY_ACCOUNT_ORDERS: string;
  REQUEST_MAPPING_MY_ACCOUNT_SETTINGS: string;
  REQUEST_MAPPING_MY_ACCOUNT_REWARDS: string;
  REQUEST_MAPPING_MY_ACCOUNT_RATE_AND_REVIEW: string;
  REQUEST_MAPPING_NEWSLETTER: string;
  REQUEST_MAPPING_OUTLET: string;
  REQUEST_MAPPING_RETURN_QR: string;
  REQUEST_MAPPING_REWARD_SIGNUP: string;
  REQUEST_MAPPING_REWARD: string;
  REQUEST_MAPPING_SEARCH: string;
  REQUEST_MAPPING_SHARED: string;
  REQUEST_MAPPING_SIZE_GUIDES: string;
  REQUEST_MAPPING_STORE_FINDER: string;
  REQUEST_MAPPING_STORE_PREFIX: string;
  REQUEST_MAPPING_WORKSHOP: string;
  REQUEST_MAPPING_XRS_VALIDATION: string;
  TOGGLE_FITSTATION: boolean;
  TOGGLE_NEW_GUIDES: boolean;
  TOGGLE_TEAMSALES: boolean;
  RECAPTCHA_SITEKEY: string;
  GIT_HASH: string;
};

type EnvironmentVariablesBySite = {
  [key in CountryCodeLowerCased]: EnvironmentVariables;
};

const TEAMSALES_ENV_NAME = "TEAMSALES";

export const getEnvNameSpecificEnvVar = ({
  site,
  varName,
  envType,
  envName,
}: {
  site: string;
  envType: string;
  varName: string;
  envName?: string;
}): string | undefined => {
  const variableName = envName ? `${envName}_${varName}` : varName;
  const envVar =
    // ex: LOCAL_PROD_VARIABLENAME
    process.env[`LOCAL_${envType}_${variableName}`] ??
    // ex: LOCAL_DEFAULT_VARIABLENAME
    process.env[`LOCAL_DEFAULT_${variableName}`] ??
    // ex: SE_PROD_VARIABLENAME
    process.env[`${site}_${envType}_${variableName}`] ??
    // ex: PROD_VARIABLENAME
    process.env[`${envType}_${variableName}`] ??
    // ex: SE_DEFAULT_VARIABLENAME
    process.env[`${site}_DEFAULT_${variableName}`] ??
    // ex: DEFAULT_VARIABLENAME
    process.env[`DEFAULT_${variableName}`];

  return envVar;
};

export const isTeamsales = () =>
  process.env.ENV_NAME?.toUpperCase() === TEAMSALES_ENV_NAME;

export const isNotProd = (): boolean => process.env.ENV_TYPE !== "prod";

export const getEnvVar = (varName: string, defaultValue?: string): string => {
  const envType = process.env.ENV_TYPE.toUpperCase();
  const site = process.env.ENV_SITE.toUpperCase();
  const envVar = isTeamsales()
    ? getEnvNameSpecificEnvVar({
        site,
        envType,
        envName: TEAMSALES_ENV_NAME,
        varName,
      }) ??
      getEnvNameSpecificEnvVar({
        site,
        envType,
        varName,
      })
    : getEnvNameSpecificEnvVar({
        site,
        envType,
        varName,
      });

  if (envVar === undefined) {
    if (typeof defaultValue === "string") {
      return defaultValue;
    }
    throw Error(
      `Environment variable "${varName}" not found. Site: ${site}, env: ${envType}, isTeamsales: ${isTeamsales}`
    );
  }

  return envVar;
};

const getOptionalEnvVar = (varName: string): string | null => {
  try {
    return getEnvVar(varName);
  } catch {
    return null;
  }
};

const getPublicEnvironmentVariables = (): EnvironmentVariablesBySite => {
  return markets.reduce((collection, siteName) => {
    collection[siteName] = {
      CONFIG_SITE_CONTENT_HEADERCODE: getEnvVar(
        "CONFIG_SITE_CONTENT_HEADERCODE"
      ),
      CONFIG_SITE_COOKIECULTURELANGUAGE: getEnvVar(
        "CONFIG_SITE_COOKIECULTURELANGUAGE"
      ),
      RECAPTCHA_SITEKEY: getEnvVar("RECAPTCHA_SITEKEY"),
      CONFIG_SITE_COOKIEVERSION: getEnvVar("CONFIG_SITE_COOKIEVERSION"),
      CONFIG_SITE_COUNTRY: getEnvVar("CONFIG_SITE_COUNTRY"),
      CONFIG_SITE_CURRENCY: getEnvVar("CONFIG_SITE_CURRENCY"),
      CONFIG_SITE_GTMID: getEnvVar("CONFIG_SITE_GTMID"),
      CONFIG_SITE_KLARNALANGUAGE: getEnvVar("CONFIG_SITE_KLARNALANGUAGE"),
      CONFIG_SITE_KINDLYCHATBOTKEY: getEnvVar("CONFIG_SITE_KINDLYCHATBOTKEY"),
      CONFIG_SITE_KINDLYCHATBOTLANGUAGE: getEnvVar(
        "CONFIG_SITE_KINDLYCHATBOTLANGUAGE"
      ),
      CONFIG_SITE_TRIGGERBEESITEID: getEnvVar(
        "CONFIG_SITE_TRIGGERBEESITEID",
        ""
      ),
      CONFIG_SITE_LANGUAGES: getEnvVar("CONFIG_SITE_LANGUAGES"),
      CONFIG_SITE_SYMPLIFYID: getEnvVar("CONFIG_SITE_SYMPLIFYID"),
      CONFIG_SITE_UID: getEnvVar("CONFIG_SITE_UID") as EcomSiteUidLegacy,
      CONFIG_SITE_VIDEOLYID: getEnvVar("CONFIG_SITE_VIDEOLYID"),
      CONFIG_SITE_VOLUMENTALID: getEnvVar("CONFIG_SITE_VOLUMENTALID"),
      KLARNA_PLACEMENT_WIDGET_CLIENTID: getEnvVar(
        "KLARNA_PLACEMENT_WIDGET_CLIENTID"
      ),
      KLARNA_PLACEMENT_WIDGET_LOCALE: getEnvVar(
        "KLARNA_PLACEMENT_WIDGET_LOCALE"
      ),
      KLARNA_PLACEMENT_WIDGET_SHOW:
        getEnvVar("KLARNA_PLACEMENT_WIDGET_SHOW") === "true",
      QR_HOST_PREFIX: getEnvVar("QR_HOST_PREFIX"),
      REQUEST_MAPPING_CAMPAIGNHUBPAGE: getEnvVar(
        "REQUEST_MAPPING_CAMPAIGNHUBPAGE"
      ),
      PRISJAKT_MARKET: getOptionalEnvVar("PRISJAKT_MARKET"),
      PRISJAKT_SHOPID: getOptionalEnvVar("PRISJAKT_SHOPID"),
      REQUEST_MAPPING_LOGIN: getEnvVar("REQUEST_MAPPING_LOGIN"),
      REQUEST_MAPPING_MY_ACCOUNT: getEnvVar("REQUEST_MAPPING_MY_ACCOUNT"),
      REQUEST_MAPPING_BRAND_INDEX: getEnvVar("REQUEST_MAPPING_BRAND_INDEX"),
      REQUEST_MAPPING_BROKENCARTPAGE: getEnvVar(
        "REQUEST_MAPPING_BROKENCARTPAGE"
      ),
      REQUEST_MAPPING_BUYGIFTCARD: getEnvVar("REQUEST_MAPPING_BUYGIFTCARD"),
      REQUEST_MAPPING_CUSTOMERSERVICE: getEnvVar(
        "REQUEST_MAPPING_CUSTOMERSERVICE"
      ),
      REQUEST_MAPPING_FAQ: getEnvVar("REQUEST_MAPPING_FAQ"),
      REQUEST_MAPPING_FAQGIFTCARD: getEnvVar("REQUEST_MAPPING_FAQGIFTCARD"),
      REQUEST_MAPPING_GUIDES_INTERFIX: getEnvVar(
        "REQUEST_MAPPING_GUIDES_INTERFIX"
      ),
      REQUEST_MAPPING_GUIDES: getEnvVar("REQUEST_MAPPING_GUIDES"),
      REQUEST_MAPPING_MY_ACCOUNT_FAVORITES: getEnvVar(
        "REQUEST_MAPPING_MY_ACCOUNT_FAVORITES"
      ),
      REQUEST_MAPPING_MY_ACCOUNT_FITSTATION: getEnvVar(
        "REQUEST_MAPPING_MY_ACCOUNT_FITSTATION"
      ),
      REQUEST_MAPPING_MY_ACCOUNT_ORDERS: getEnvVar(
        "REQUEST_MAPPING_MY_ACCOUNT_ORDERS"
      ),
      REQUEST_MAPPING_MY_ACCOUNT_REWARDS: getEnvVar(
        "REQUEST_MAPPING_MY_ACCOUNT_REWARDS"
      ),
      REQUEST_MAPPING_MY_ACCOUNT_SETTINGS: getEnvVar(
        "REQUEST_MAPPING_MY_ACCOUNT_SETTINGS"
      ),
      REQUEST_MAPPING_MY_ACCOUNT_RATE_AND_REVIEW: getEnvVar(
        "REQUEST_MAPPING_MY_ACCOUNT_RATE_AND_REVIEW"
      ),
      REQUEST_MAPPING_OUTLET: getEnvVar("REQUEST_MAPPING_OUTLET"),
      REQUEST_MAPPING_NEWSLETTER: getEnvVar("REQUEST_MAPPING_NEWSLETTER"),
      REQUEST_MAPPING_RETURN_QR: getEnvVar("REQUEST_MAPPING_RETURN_QR"),
      REQUEST_MAPPING_REWARD_SIGNUP: getEnvVar("REQUEST_MAPPING_REWARD_SIGNUP"),
      REQUEST_MAPPING_REWARD: getEnvVar("REQUEST_MAPPING_REWARD"),
      REQUEST_MAPPING_SEARCH: getEnvVar("REQUEST_MAPPING_SEARCH"),
      REQUEST_MAPPING_SHARED: getEnvVar("REQUEST_MAPPING_SHARED"),
      REQUEST_MAPPING_SIZE_GUIDES: getEnvVar("REQUEST_MAPPING_SIZE_GUIDES"),
      REQUEST_MAPPING_STORE_FINDER: getEnvVar("REQUEST_MAPPING_STORE_FINDER"),
      REQUEST_MAPPING_STORE_PREFIX: getEnvVar("REQUEST_MAPPING_STORE_PREFIX"),
      REQUEST_MAPPING_WORKSHOP: getEnvVar("REQUEST_MAPPING_WORKSHOP"),
      REQUEST_MAPPING_XRS_VALIDATION: getEnvVar(
        "REQUEST_MAPPING_XRS_VALIDATION"
      ),
      TOGGLE_FITSTATION: getEnvVar("TOGGLE_FITSTATION") === "true",
      TOGGLE_NEW_GUIDES: getEnvVar("TOGGLE_NEW_GUIDES") === "true",
      TOGGLE_TEAMSALES: getEnvVar("TOGGLE_TEAMSALES") === "true",
      GIT_HASH: getEnvVar("GIT_HASH"),
    };

    return collection;
  }, {} as EnvironmentVariablesBySite);
};

export const getEnvironmentVariables = (
  site: CountryCodeLowerCased
): EnvironmentVariables => getPublicEnvironmentVariables()[site];

export const getLegacySiteUid = (): EcomSiteUidLegacy =>
  getPublicEnvironmentVariables()[process.env.ENV_SITE].CONFIG_SITE_UID;

export const getSiteUid = (): EcomSiteUid => {
  const legacyUid = getLegacySiteUid();
  const uid = legacyUid === "xxl" ? "xxl-no" : legacyUid;

  return uid;
};

export const getContentApiConfiguration = (): ConfigurationParameters => {
  return {
    basePath: `${process.env.APIS_BASE_URL}/${process.env.NEXT_PUBLIC_API_PATH_CONTENT}`,
  };
};

export const getElevateApiConfiguration = (
  toggle_elevate_cluster: boolean
): { baseURL: string } => ({
  baseURL: toggle_elevate_cluster
    ? `${process.env.ELEVATE_BASE_URL}`
    : `${process.env.APIS_BASE_URL}/${process.env.NEXT_PUBLIC_API_PATH_ELEVATE}`,
});

const getApiKey = () =>
  process.env[`${process.env.ENV_TYPE}_APIS_TOKEN`.toUpperCase()];

export const getPimApiConfiguration = (): ConfigurationParameters => ({
  basePath: `${process.env.APIS_BASE_URL}/${process.env.NEXT_PUBLIC_API_PATH_PIM}`,
  apiKey: getApiKey(),
});

export const getSolrApiConfiguration = (): SolrConfigurationParameters => ({
  basePath: `${process.env.APIS_BASE_URL}/${process.env.NEXT_PUBLIC_API_PATH_SOLR}`,
  apiKey: getApiKey(),
});

export const getPricesApiConfiguration = (): ConfigurationParameters => ({
  basePath: `${process.env.APIS_BASE_URL}/${process.env.NEXT_PUBLIC_API_PATH_PRICES}`,
  apiKey: getApiKey(),
});

export const getSsmApiConfiguration = (): ConfigurationParameters => ({
  basePath: `${process.env.APIS_BASE_URL}/${process.env.NEXT_PUBLIC_API_PATH_SSM}`,
  apiKey: getApiKey(),
});

export const getAuthorizationApiConfiguration =
  (): ConfigurationParameters => ({
    basePath: `${process.env.APIS_BASE_URL}/${process.env.NEXT_PUBLIC_API_PATH_AUTHORIZATION}`,
    apiKey: getApiKey(),
  });

export const isTrue = (value: boolean | string = false) =>
  [true, "true"].includes(value);

import type { FunctionComponent } from "react";
import React from "react";
import { Image } from "./Image";

type LogoProps = {
  url: string;
  link: string | null;
};

const Logo: FunctionComponent<LogoProps> = ({ url, link }) => (
  <figure>
    {link !== null ? (
      <a href={link}>
        <Image {...{ url }} />
      </a>
    ) : (
      <Image {...{ url }} />
    )}
  </figure>
);

export { Logo };

import React, { type PropsWithChildren } from "react";
import { Dialog as MuiDialog, type DialogProps } from "@mui/material";
import { xxlTheme } from "../../../styles/xxl-theme";
import { Icon } from "../../../components/Icon";
import styled from "@emotion/styled/macro";
import { color } from "@xxl/theme";
import { MQ } from "../../../styles/helpers";
import { XxlStack } from "../../../components/Common/XxlStack";

const StickyHeading = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  box-shadow: 0 5px 5px -5px ${color.mediumGray.hex};
  background-color: ${color.white.hex};
  z-index: ${xxlTheme.zIndex.modal};
  padding: ${xxlTheme.spaces.large} ${xxlTheme.spaces.smallRegular};

  ${MQ("tablet")} {
    box-shadow: none;
    padding: ${xxlTheme.spaces.large} ${xxlTheme.spaces.smallRegular} 0
      ${xxlTheme.spaces.smallRegular};
  }
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
`;

const CloseContainer = styled.div`
  cursor: pointer;
`;

type Props = PropsWithChildren<DialogProps> & {
  heading: string;
  onClose: () => void;
};
export const Dialog = (props: Props) => {
  const { open, maxWidth, children, heading, onClose, fullScreen } = props;
  return (
    <MuiDialog
      {...props}
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
    >
      <StickyHeading>
        <Heading>{heading}</Heading>
        <CloseContainer onClick={onClose}>
          <Icon size={34} name="Close" />
        </CloseContainer>
      </StickyHeading>
      <XxlStack height={"790px"} width={"960"}>
        {children}
      </XxlStack>
    </MuiDialog>
  );
};

import styled from "@emotion/styled/macro";
import React, { useState } from "react";
import { Dialog } from "@mui/material";
import { Slider } from "react-app/src/components/Common/Slider";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { Icon } from "react-app/src/components/Icon/Icon";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { useXxlMediaQuery } from "react-app/src/hooks/useXxlMediaQuery";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { IMAGE_SIZE } from "../constants";
import ImageSliderItem from "./ImageSliderItem";

const MOBILE_ARROWS_MARGIN = 10;
const DESKTOP_ARROWS_MARGIN = 100;
const TOOLBAR_HEIGHT = 41;

const ToolBar = styled.div`
  height: ${TOOLBAR_HEIGHT}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: ${xxlTheme.colors.xxlWhite};
  background-color: ${xxlTheme.colors.xxlBlack};
  top: 0;
  z-index: ${xxlTheme.zIndex.navigation};
`;

const ToolbarItem = styled.div`
  padding: ${xxlTheme.spaces.regular};
`;

const SliderWrapper = styled.div`
  flex-grow: 1;

  &&& .keen-slider:not([data-keen-slider-disabled]) {
    -ms-touch-action: pan-y pinch-zoom;
    touch-action: pan-y pinch-zoom;
  }
`;

const CloseButton = styled.button`
  border: none;
  background: none;
  color: ${xxlTheme.colors.xxlWhite};
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

type Props = {
  handleClose: () => void;
  productImages: string[];
  open: boolean;
  initialSlideIndex: number;
};
export const ImageDialog = ({
  open,
  handleClose,
  productImages,
  initialSlideIndex,
}: Props) => {
  const { t } = useTranslations();

  const [isZoomOpen, setIsZoomOpen] = useState(false);

  const isLaptopSize = useXxlMediaQuery("LaptopMediaQuery");
  const imageItems = productImages.map((productImage) => (
    <ImageWrapper key={productImage}>
      <ImageSliderItem
        setIsOpen={setIsZoomOpen}
        isOpen={isZoomOpen}
        key={productImage}
        src={productImage}
        alt={t("product.image")}
        isLaptopSize={isLaptopSize}
        width={
          isLaptopSize
            ? IMAGE_SIZE.DESKTOP_FULLSCREEN
            : IMAGE_SIZE.MOBILE_FULLSCREEN
        }
        height={
          isLaptopSize
            ? IMAGE_SIZE.DESKTOP_FULLSCREEN
            : IMAGE_SIZE.MOBILE_FULLSCREEN
        }
      />
    </ImageWrapper>
  ));

  return (
    <Dialog fullScreen={true} open={open} onClose={handleClose}>
      <ToolBar>
        <ToolbarItem />
        <XxlStack
          direction={"row"}
          alignItems="center"
          justifyContent="flex-end"
        >
          <CloseButton type="button" onClick={handleClose}>
            <Icon name="Close" size={36} color={"white"} />
          </CloseButton>
        </XxlStack>
      </ToolBar>
      <SliderWrapper>
        <Slider
          disabled={isZoomOpen}
          initial={initialSlideIndex}
          items={imageItems}
          loop={{ shouldLoop: true }}
          slidesConfig={{ perView: 1, spacing: 5, origin: "center" }}
          navigation={imageItems.length > 1 && !isZoomOpen ? "ARROWS" : "NONE"}
          navigationMargin={
            isLaptopSize
              ? { left: DESKTOP_ARROWS_MARGIN, right: DESKTOP_ARROWS_MARGIN }
              : { left: MOBILE_ARROWS_MARGIN, right: MOBILE_ARROWS_MARGIN }
          }
          SliderListStyles={{
            boxSizing: "border-box",
            height: `calc(100vh - ${TOOLBAR_HEIGHT}px )`,
          }}
        />
      </SliderWrapper>
    </Dialog>
  );
};

import React from "react";

type BoldedSpanProps = {
  text?: string;
  foundPhrase?: string;
};

export const BoldedSpan: React.FunctionComponent<BoldedSpanProps> = ({
  text,
  foundPhrase,
}) => {
  if (text === undefined) {
    return null;
  }
  let regularParts: string[] = [];
  let boldedParts: RegExpMatchArray | null = null;
  if (
    foundPhrase !== undefined &&
    RegExp(foundPhrase, "i").exec(text) !== null
  ) {
    regularParts = text.split(RegExp(foundPhrase, "i"));
    boldedParts = text.match(RegExp(foundPhrase, "gi"));
  }
  const uniqueKeyToPreventCrashForUsersUsingTranslationExtension =
    JSON.stringify(regularParts);

  return (
    <span
      data-testid="bolded-span-wrapper"
      key={uniqueKeyToPreventCrashForUsersUsingTranslationExtension}
    >
      {regularParts.length < 2 || boldedParts === null ? (
        text
      ) : (
        <>
          {regularParts[0]}
          {regularParts.slice(1).map((item, index) => (
            <span key={index}>
              {boldedParts && (
                <b data-testid="bolded-part">{boldedParts[index]}</b>
              )}
              {item}
            </span>
          ))}
        </>
      )}
    </span>
  );
};

import type {
  AddBundledProductsToCartItemInput,
  PrintConfigurationInput,
} from "../../../generated/graphql-code-generator";
import type { BundleConfigurationMemberData } from "../../../global";
import { uuidv4 } from "../../../utils/xxl-uuid";
import groupBy from "lodash/groupBy";
import type { ArrayOfBundleData, BundleData } from "@xxl/pim-api";
import { default as Velocity } from "velocityjs";

type QuantityGroupProps = {
  members: BundleConfigurationMemberData[];
  sizeEan: number;
};

const setPrintConfigurations = (
  pimConfig: BundleData[],
  members: BundleConfigurationMemberData[],
  productEan: number
): PrintConfigurationInput[] => {
  const printConfigurations: PrintConfigurationInput[] = [];

  pimConfig.forEach((config) => {
    members.forEach((member) => {
      if (
        member.ean === productEan &&
        config.products !== undefined &&
        config.products[0].sizeOptions !== undefined
      ) {
        const configObj = {
          ean: String(config.products[0].sizeOptions[0].ean),
          user: member.userId as string,
        };
        if (config.products[0].configurations !== undefined) {
          config.products[0].configurations.forEach((item) => {
            if (
              config.products !== undefined &&
              config.products[0].sizeOptions !== undefined &&
              item.velocityMacro !== undefined
            ) {
              const Compile = Velocity.Compile;
              const velocityObject = Velocity.parse(item.velocityMacro);
              const printResult = new Compile(velocityObject).render(member);

              const extendedConfigObj = {
                ...configObj,
                erpLabel: item.qualifier as string,
                label: item.configurationLabel as string,
                print: printResult,
              };
              printConfigurations.push(extendedConfigObj);
            }
          });
        } else {
          printConfigurations.push(configObj);
        }
      }
    });
  });

  return printConfigurations;
};

export const setBundleProductsWithPrint = (
  members: BundleConfigurationMemberData[],
  pimData: ArrayOfBundleData
): AddBundledProductsToCartItemInput[] => {
  const pimConfigurations = (pimData[0].bundleTemplates as BundleData[]).filter(
    (item) => item.configurableVariantSource !== true
  );

  const membersWithId = members.map((member) => ({
    ...member,
    userId: member.userId ?? uuidv4(),
  }));

  const quantityGroupsObject = groupBy(membersWithId, "ean");
  const quantityGroups: QuantityGroupProps[] = [];
  const keys = Object.keys(quantityGroupsObject);
  keys.forEach((key) => {
    const obj = {
      sizeEan: parseInt(key),
      members: quantityGroupsObject[key],
    };
    quantityGroups.push(obj);
  });

  const returnedProducts: AddBundledProductsToCartItemInput[] =
    quantityGroups.map((group) => ({
      ean: String(group.sizeEan),
      quantity: group.members.length,
      printConfigurations: setPrintConfigurations(
        pimConfigurations,
        membersWithId,
        group.sizeEan
      ),
    }));

  return returnedProducts;
};

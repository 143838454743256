import type { IncomingHttpHeaders } from "http";

const CUSTOM_HEADER_NAMES = {
  agentType: "x-xxl-agent-type",
} as const;

export const isBot = (headers: IncomingHttpHeaders) => {
  const { [CUSTOM_HEADER_NAMES.agentType]: agentType } = headers;

  return agentType === "known-bot";
};

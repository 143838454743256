import React from "react";
import { ShowAllLink } from "./MegaMenu.styled";
import { getUrl } from "../Desktop/mega-menu-helper";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";

const ShowAllCategoryListItem: React.FunctionComponent<{
  code: string;
  displayName: string;
  link?: string;
}> = ({ code, displayName, link }) => {
  const { t } = useTranslations();

  return (
    <li>
      <ShowAllLink href={getUrl({ code, link })}>
        {`${t("mega.menu.categories.show.all")} ${displayName.toLowerCase()}`}
      </ShowAllLink>
    </li>
  );
};

export { ShowAllCategoryListItem };

import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { MQ } from "react-app/src/styles/helpers";
import { componentMaxWidth, xxlTheme } from "react-app/src/styles/xxl-theme";

export const padding = {
  mobile: xxlTheme.spaces.smallRegular,
  desktop: xxlTheme.spaces.huge,
};

export const SiteContainer = styled.div<{
  hasPadding?: boolean;
  hideBottomPadding?: boolean;
}>`
  max-width: ${componentMaxWidth}px;
  margin: auto;
  box-sizing: border-box;
  ${({ hasPadding = true }) => {
    return css`
      padding: ${hasPadding ? padding.mobile : 0};
      padding-top: 0px;

      ${MQ("tabletHorizontal")} {
        padding: ${hasPadding ? padding.desktop : 0};
        padding-top: 0px;
      }
    `;
  }}
  ${({ hideBottomPadding = false }) => {
    return css`
      padding-bottom: ${hideBottomPadding && 0};

      ${MQ("tabletHorizontal")} {
        padding-bottom: ${hideBottomPadding && 0};
      }
    `;
  }}
`;

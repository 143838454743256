import axios from "axios";
import * as yup from "yup";
import type { TranslationKey } from "../../../../translations";
import type {
  ErrorResponse,
  InitialFormDataType,
  SignupUserData,
} from "./types";
import type { Translate } from "../../../../contexts/Translations/TranslationsContext";
import { ValidateEmail } from "../../../../utils/xxl-email";
import { windowAccess } from "../../../../utils/Window";
import { ValidateSocialSecurityNumber } from "../../../../utils/xxl-security-number";
import {
  isNotNullOrUndefined,
  type EcomSiteUid,
  isNotEmpty,
} from "@xxl/common-utils";
import type { GraphQLError } from "../../../../graphql/graphqlApi";
import type {
  AccountDataValidationError,
  AccountError,
} from "../../../UserDetails/UserAPI";
import type { CreateAccountInput } from "../../../../generated/graphql-code-generator";

type CompleteSignupSchemaConfig = {
  message: string;
  includeSocialSecurityNumber: boolean;
  shouldShowFirstNameInput: boolean;
  shouldShowLastNameInput: boolean;
  shouldShowPhoneInput: boolean;
  uid: EcomSiteUid;
  t: Translate;
};

export type FormError = GlobalError | FieldError;
type GlobalError = {
  message: TranslationKey;
};
export type FieldError = {
  message: TranslationKey;
  subject: keyof CreateAccountInput;
};

const LOGIN_MAX_ATTEMPTS_COUNT = 3;

export const firstNameField = (t: Translate) =>
  ({
    autocompleteToken: "given-name",
    fieldName: "firstName",
    id: "firstName",
    label: t("reward.signup.first.name"),
    placeholder: t("reward.signup.first.name"),
    inputMode: "text",
    required: true,
  }) as const;

export const lastNameField = (t: Translate) =>
  ({
    autocompleteToken: "family-name",
    fieldName: "lastName",
    id: "lastName",
    label: t("reward.signup.last.name"),
    placeholder: t("reward.signup.last.name"),
    inputMode: "text",
    required: true,
  }) as const;

export const phoneField = (t: Translate) =>
  ({
    autocompleteToken: "tel",
    fieldName: "phoneNumber",
    id: "telephone",
    label: t("reward.signup.phone.number"),
    inputMode: "tel",
    placeholder: t("reward.signup.phone.number"),
  }) as const;

const passwordField = (t: Translate) =>
  ({
    autocompleteToken: "new-password",
    fieldName: "password",
    id: "password",
    label: t("account.login.form.password.placeholder"),
    placeholder: t("account.login.form.password.placeholder"),
    inputMode: "text",
    required: true,
  }) as const;

export const socialSecurityNumberField = (t: Translate) =>
  ({
    autocompleteToken: "social-security-number",
    fieldName: "socialSecurityNumber",
    id: "socialSecurityNumber",
    inputMode: "text",
    label: t("reward.signup.social.security.number"),
    placeholder: t("reward.signup.social.security.number"),
    required: true,
  }) as const;

export const commonUserFields = (t: Translate) => ({
  firstNameField: firstNameField(t),
  lastNameField: lastNameField(t),
  phoneField: phoneField(t),
  socialSecurityNumberField: socialSecurityNumberField(t),
});

export const checkTerms = (t: Translate) =>
  yup.boolean().oneOf([true], t("reward.signup.terms.required"));

const checkEmail = (t: Translate) =>
  yup
    .string()
    .test("check-email", t("form.email.invalid"), ValidateEmail)
    .required(t("form.field.required"));

export const checkSocialSecurityNumber = (t: Translate, uid: EcomSiteUid) =>
  yup
    .string()
    .required(t("form.field.required"))
    .test(
      "check-ssn",
      t("register.form.error.socialSecurityNumber.invalid"),
      (value) => ValidateSocialSecurityNumber(uid, value ?? "")
    );

export const checkPassword = (t: Translate) =>
  yup
    .string()
    .required(t("form.field.required"))
    .min(6, t("register.form.error.password.size.min"))
    .max(250, t("register.form.error.password.size.max"));

export const getInitialFormData = (
  includePhone: boolean,
  {
    firstName = "",
    lastName = "",
    email = "",
    phoneNumber = "",
    password = "",
  }: SignupUserData
): InitialFormDataType => ({
  firstName,
  lastName,
  email,
  ...(includePhone ? { mobilePhone: phoneNumber } : undefined),
  consent: "SIGN_UP",
  terms: false,
  password,
});

export const getCompleteSignupSchema = ({
  message,
  includeSocialSecurityNumber,
  shouldShowFirstNameInput,
  shouldShowLastNameInput,
  shouldShowPhoneInput,
  uid,
  t,
}: CompleteSignupSchemaConfig) => {
  return yup.object({
    password: checkPassword(t),
    ...(includeSocialSecurityNumber
      ? { socialSecurityNumber: checkSocialSecurityNumber(t, uid) }
      : undefined),
    ...(shouldShowFirstNameInput
      ? { firstName: yup.string().required(message) }
      : undefined),
    ...(shouldShowLastNameInput
      ? { lastName: yup.string().required(message) }
      : undefined),
    ...(shouldShowPhoneInput
      ? { phoneNumber: yup.string().required(message) }
      : undefined),
  });
};

export const getReviewSchema = (
  includePhone: boolean,
  includeSocialSecurityNumber: boolean,
  t: Translate,
  uid: EcomSiteUid
) => {
  const requiredMessage = t("form.field.required");

  return yup.object({
    firstName: yup.string().required(requiredMessage),
    lastName: yup.string().required(requiredMessage),
    email: checkEmail(t),
    ...(includeSocialSecurityNumber
      ? { socialSecurityNumber: checkSocialSecurityNumber(t, uid) }
      : undefined),
    ...(includePhone
      ? { phoneNumber: yup.string().required(requiredMessage) }
      : undefined),
    terms: checkTerms(t),
    password: checkPassword(t),
  });
};

export type Field = {
  autocompleteToken:
    | "given-name"
    | "family-name"
    | "tel"
    | "email"
    | "new-password"
    | "social-security-number";
  disabled?: boolean;
  fieldName: keyof InitialFormDataType;
  id: string;
  infoMessage?: string;
  inputMode?:
    | "email"
    | "tel"
    | "search"
    | "text"
    | "none"
    | "url"
    | "numeric"
    | "decimal"
    | undefined;
  label: string;
  placeholder: string;
};

export const getIsValidSecurityNumber = ({
  socialSecurityNumber,
  t,
  uid,
}: {
  socialSecurityNumber?: string;
  t: Translate;
  uid: EcomSiteUid;
}): boolean => {
  try {
    return Boolean(
      checkSocialSecurityNumber(t, uid).validateSync(socialSecurityNumber)
    );
  } catch (_error) {
    return false;
  }
};

export const fields = (
  includePhone: boolean,
  toggle_social_security_number: boolean,
  t: Translate,
  uid: EcomSiteUid,
  { firstName, lastName, email, socialSecurityNumber }: SignupUserData,
  shouldShowFilledNameInputs = false
): Field[] => {
  const getIsValidUserEmail = (userEmail?: string): boolean => {
    try {
      return Boolean(checkEmail(t).validateSync(userEmail));
    } catch (error) {
      return false;
    }
  };
  return [
    ...(firstName === undefined || shouldShowFilledNameInputs
      ? [firstNameField(t)]
      : []),
    ...(lastName === undefined || shouldShowFilledNameInputs
      ? [lastNameField(t)]
      : []),
    ...(includePhone ? [phoneField(t)] : []),
    ...(toggle_social_security_number
      ? [
          {
            ...socialSecurityNumberField(t),
            disabled: getIsValidSecurityNumber({
              socialSecurityNumber,
              t,
              uid,
            }),
          } as const,
        ]
      : []),
    {
      autocompleteToken: "email",
      disabled: getIsValidUserEmail(email),
      fieldName: "email",
      id: "username",
      infoMessage: isNotEmpty(email)
        ? t("reward.signup.email.prefilled")
        : undefined,
      inputMode: "email",
      label: t("reward.signup.email"),
      placeholder: t("reward.signup.email"),
    },
    { ...passwordField(t) },
  ];
};

export const doXxlLogin = async (
  formData: SignupUserData,
  setInProgress: (inProgress: boolean) => void,
  setGeneralErrors: React.Dispatch<React.SetStateAction<string[]>>,
  setIsLoggedIn: (loggedIn: boolean) => void
): Promise<void> => {
  const { email, password } = formData;
  if (email === undefined || password === undefined) {
    return Promise.reject("Missing create account data");
  }

  setInProgress(true);
  const postData = new FormData();
  postData.append("username", email);
  postData.append("password", password);

  let attemptNumber = 1;
  while (attemptNumber <= LOGIN_MAX_ATTEMPTS_COUNT) {
    try {
      await axios.post("/login", postData);
      setIsLoggedIn(true);
      windowAccess().Login.initialize();
      break;
    } catch (e) {
      const { status, data } = (e as ErrorResponse).response;
      if (status !== 200 && attemptNumber < LOGIN_MAX_ATTEMPTS_COUNT) {
        //it might happen that the account creation in 3rd party is delayed, try to retry the login with backoff
        await new Promise((resolve) =>
          setTimeout(resolve, 1000 * attemptNumber)
        );
        attemptNumber++;
      } else {
        //by any other error set the messages and break the loop
        if (data.errors !== undefined) {
          setGeneralErrors(
            data.errors.reduce((messages, err) => {
              if (isNotNullOrUndefined(err.message)) {
                messages.push(err.message);
              }
              return messages;
            }, [] as string[])
          );
        }
        break;
      }
    }
  }

  setInProgress(false);
};

export const getTranslatedServerErrors = (
  allErrors: FormError[],
  t: Translate
) => {
  const errors: FieldError[] = [];
  allErrors.forEach((error) => {
    if ("subject" in error) {
      errors.push(error);
    }
  });
  return errors.reduce<Record<string, string>>((allErrors, currentError) => {
    const { message, subject } = currentError;
    if (isNotNullOrUndefined(subject) && isNotNullOrUndefined(message)) {
      allErrors[subject] = t(message as TranslationKey);
    }
    return allErrors;
  }, {});
};

export const getTranslatedGeneralErrors = (
  allErrors: FormError[],
  t: Translate
) => {
  const errors: GlobalError[] = [];

  allErrors.forEach((error) => {
    if (!("subject" in error)) {
      errors.push(error);
    }
  });

  return errors.reduce((messages, { message }) => {
    if (isNotNullOrUndefined(message)) {
      messages.push(t(message));
    }

    return messages;
  }, [] as string[]);
};

const mapFieldName = (fieldName: string, t: Translate): string => {
  switch (fieldName) {
    case "mobilePhone":
      return phoneField(t).fieldName;
    default:
      return fieldName;
  }
};

type ErrorEntry = {
  subject?: string;
  message: string;
};

const mapValidationError = (
  error: AccountDataValidationError,
  t: Translate
): ErrorEntry => ({
  subject: mapFieldName(error.field, t),
  message: error.errorDescription ?? error.validationErrorCode,
});

export const formatGraphQLErrors = (
  errors: GraphQLError<unknown, AccountError>[],
  t: Translate
) => {
  const errorMap = new Map<string, ErrorEntry>();

  errors.forEach((error) => {
    if (
      isNotNullOrUndefined(error.errorInfo) &&
      "validationErrors" in error.errorInfo &&
      Array.isArray(error.errorInfo.validationErrors) &&
      error.errorInfo.validationErrors.length > 0
    ) {
      error.errorInfo.validationErrors.forEach((validationError) => {
        const mappedError = mapValidationError(validationError, t);
        const key = `${mappedError.subject ?? "N/A"}:${mappedError.message}`;
        errorMap.set(key, mappedError);
      });
    } else {
      errorMap.set("N/A", {
        message: error.message,
      });
    }
  });

  return Array.from(errorMap.values());
};

import { Check } from "@xxl/icons";
import React from "react";
import ReactHtmlParser from "html-react-parser";

import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";

const Fallback: React.FunctionComponent = () => {
  const { t } = useTranslations();

  return (
    <div className="top-usp">
      <ul className="container">
        <li>
          <Check />
          <span className="top-usp__top-message">
            {ReactHtmlParser(t("cart.usp1"))}
          </span>
          <span className="top-usp__bottom-message">
            {ReactHtmlParser(t("cart.usp1.more"))}
          </span>
        </li>
        <li className="top-usp__mobile">
          <Check />
          <span className="top-usp__top-message">
            {ReactHtmlParser(t("cart.usp2"))}
          </span>
          <span className="top-usp__bottom-message">
            {ReactHtmlParser(t("cart.usp2.more"))}
          </span>
        </li>
        <li className="top-usp__mobile">
          <Check />
          <span className="top-usp__top-message">
            {ReactHtmlParser(t("cart.usp3"))}
          </span>
          <span className="top-usp__bottom-message">
            {ReactHtmlParser(t("cart.usp3.more"))}
          </span>
        </li>
      </ul>
    </div>
  );
};

export { Fallback };

import React from "react";
import {
  StyledRewardBox,
  RewardText,
  RewardTrigger,
} from "../Styles/RewardBox.styled";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import * as XxlEvent from "../../../utils/xxl-event";
import { CaretRight } from "@xxl/icons";

export const RewardBox: React.FunctionComponent = () => {
  const { t } = useTranslations();
  return (
    <StyledRewardBox
      type="button"
      data-testid="reward-box-login-button"
      onClick={() => XxlEvent.dispatchOpenLoginEvent()}
      id="xxl-cart-login"
    >
      <RewardText>{t("cart.login.description")}</RewardText>
      <RewardTrigger>
        {t("login.form.login.button")}
        <CaretRight />
      </RewardTrigger>
    </StyledRewardBox>
  );
};

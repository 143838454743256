import { Skeleton, type SkeletonProps } from "@mui/material";
import type { PropsWithChildren } from "react";
import React from "react";

type SkeletonWrapperProps = {
  isLoading: boolean;
  style?: React.CSSProperties;
} & SkeletonProps;

const SkeletonWrapper = ({
  children,
  isLoading,
  style,
}: PropsWithChildren<SkeletonWrapperProps>) => (
  <>
    {isLoading ? (
      <Skeleton sx={{ maxWidth: "none", ...style }}>{children}</Skeleton>
    ) : (
      children
    )}
  </>
);

export { SkeletonWrapper };

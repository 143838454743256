import Keyv from "@keyvhq/core";
import memoize from "@keyvhq/memoize";
import type { TranslationKey } from "react-app/src/translations";
import * as fallbackTranslations from "./fallback.json";
import { getSiteUid } from "../environment-variables";
import { Apis } from "@/utils/api-helper";
import { isNotNullOrUndefined } from "@xxl/common-utils";

interface TranslationOptions {
  /**
   * The translation key to resolve
   */
  key: TranslationKey;
  /**
   * Fallback value if no string found. Defaults to returning the passed key.
   */
  fallback?: string;
  /**
   * Values to replace {0} and {1} etc. with. Defaults to empty array.
   */
  messageArguments?: string[];
}

export const getTranslations = memoize(
  async () => {
    const {
      data: { result },
    } = await Apis.getInstance().contentApi.getTranslations(
      getSiteUid(),
      "web"
    );
    return (result ?? []).filter(
      ({ key, value }) => typeof key === "string" && typeof value === "string"
    );
  },
  new Keyv({ namespace: "translations" }),
  {
    ttl: 5 * 60 * 1000,
    staleTtl: 60 * 1000,
  }
);

/**
 * Server-side only translation function
 */
const translateMessage = async (
  baseInput: TranslationOptions | TranslationKey
): Promise<string> => {
  const input = typeof baseInput === "string" ? { key: baseInput } : baseInput;
  const { key, fallback, messageArguments = [] } = input;
  const translation =
    (await getTranslations()).find(
      ({ key: translationKey }) => key === translationKey
    )?.value ??
    fallback ??
    (isNotNullOrUndefined(fallbackTranslations[key])
      ? fallbackTranslations[key]
      : key);
  if (messageArguments.length > 0) {
    return translation.replace(
      /\{(\d+)\}/gu,
      (_, pos: string) => messageArguments[parseInt(pos, 10)] ?? ""
    );
  } else {
    return translation;
  }
};

export { translateMessage };

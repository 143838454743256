import React from "react";
import {
  SectionHeader,
  ItemList,
  ProductsSectionWrapper,
} from "./AutoSuggestionSection.styled";
import type { ProductProps } from "./Product";
import { Product } from "./Product";
import { useMediaQuery } from "@mui/material";
import { mobileAndTabletMediaQuery } from "../../utils/xxl-screen";

export type ProductsSectionProps = {
  headerName: string;
  products: ProductProps["product"][];
  foundPhrase?: string;
  onClick: (ticket?: string) => void;
};

const MAX_NUMBER_OF_ITEMS = 5;
const MOBILE_MAX_NUMBER_OF_ITEMS = 8;

export const ProductsSection = ({
  headerName,
  products,
  foundPhrase,
  onClick,
}: ProductsSectionProps) => {
  const isMobileOrTablet = useMediaQuery(mobileAndTabletMediaQuery);
  return (
    <ProductsSectionWrapper>
      <SectionHeader>{headerName}</SectionHeader>
      <ItemList>
        {products
          .slice(
            0,
            isMobileOrTablet ? MOBILE_MAX_NUMBER_OF_ITEMS : MAX_NUMBER_OF_ITEMS
          )
          .map((product, index) => {
            const key = `${product.name as string}_${index}`;
            return (
              <Product
                key={key}
                product={product}
                foundPhrase={foundPhrase}
                onClick={onClick}
              />
            );
          })}
      </ItemList>
    </ProductsSectionWrapper>
  );
};

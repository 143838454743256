import * as React from "react";
import styled from "@emotion/styled/macro";
import stackable from "./mixins";
import type { ISnackBarMessage } from "./index";
import { xxlTheme } from "../../styles/xxl-theme";
import { frostyBackground } from "../../styles/theme/styling";

const {
  colors: { xxlGrey },
  spaces: { smallRegular },
} = xxlTheme;

export interface SnackBarMessageProps {
  message: ISnackBarMessage;
  height: number;
  handleClick: (url?: string) => void;
}

/**
 * SnackBarMessage is used as a child component via the `action` prop on `Snackbar`.
 * Refer to https://material-ui.com/api/snackbar/#props
 * This enables displaying several concurrent notifications even though a `SnackBar` is Singleton-ish by design.
 * Do not attempt to instantiate this directly, instead use the event driven `SnackBarWrapper`.
 *
 * @param {SnackBarMessageProps} SnackBarMessageProps
 * @param {ISnackBarMessage} SnackBarMessageProps.message
 * @param {number} [SnackBarMessageProps.height = 50] Height in pixels
 * @param {(url?: string) => void} [SnackBarMessageProps.handleClick = () => {}] Navigate to an URL on clicking a message
 * }
 * @returns `React.FunctionComponent<SnackBarMessageProps>`
 */
const SnackBarMessage: React.FunctionComponent<SnackBarMessageProps> = ({
  message,
  height,
  handleClick,
}: SnackBarMessageProps) => {
  const SnackBarMessage = styled.div<{ backgroundColor: string }>`
    &&& {
      ${stackable(5, "", height)}
      ${({ backgroundColor }) =>
        frostyBackground({ hexColor: backgroundColor })}
      align-items: center;
      box-sizing: border-box;
      display: flex;
      left: 0;
      min-height: ${height}px;
      padding: ${smallRegular};
      position: fixed;
      width: 100%;

      &:last-of-type {
        box-shadow: 0 1px 5px -2px ${xxlGrey};
      }

      .snackbar__message-text {
        color: ${message.textColor ?? "var(--color-xxl-web-white)"};
        display: inline-block;
        width: 100%;
        font-size: 1.1rem;
        font-family: var(--font-family-medium);
        text-align: center;
        background: transparent;
      }

      .snackbar__message-icon {
        position: relative;
        display: inline;
        margin-right: 18px;
        background: transparent;
      }
    }
  `;

  return (
    <SnackBarMessage
      backgroundColor={message.background ?? "#000"}
      className={`snackbar__message-wrapper ${
        message.icon ? `site__button-${message.icon} active` : ""
      }`}
      data-testid="snackbar-message"
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <span
        className="snackbar__message-text"
        role="button"
        tabIndex={-1}
        onClick={(): void => handleClick(message.url)}
        data-testid="snackbar-clickable"
      >
        {message.icon ? (
          <span
            className={`${message.icon} snackbar__message-icon draw`}
          ></span>
        ) : null}
        {message.text}
      </span>
    </SnackBarMessage>
  );
};

export { SnackBarMessage };

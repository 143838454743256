import {
  cookieCategories,
  type CookieCategory,
  getCookieInformationConsent,
} from "../Cookie";
import {
  ConsentType as ConsentTypes,
  type ConsentType,
} from "@xxl/deliverystream-api";

const consentMap = new Map<CookieCategory, ConsentType[]>([
  [cookieCategories.NECESSARY, [ConsentTypes.security_storage]],
  [
    cookieCategories.MARKETING,
    [
      ConsentTypes.ad_storage,
      ConsentTypes.ad_user_data,
      ConsentTypes.ad_personalization,
    ],
  ],
  [cookieCategories.STATISTIC, [ConsentTypes.analytics_storage]],
  [
    cookieCategories.FUNCTIONAL,
    [ConsentTypes.functionality_storage, ConsentTypes.personalization_storage],
  ],
]);

export const getApprovedConsents = (): ConsentType[] =>
  (getCookieInformationConsent()?.consents_approved ?? []).flatMap(
    (cookieCat) => consentMap.get(cookieCat) ?? []
  );

import { log } from "@xxl/logging-utils";
import React from "react";
import type { PropsWithChildren } from "react";
// eslint-disable-next-line no-restricted-imports
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { isNotNullOrUndefined } from "@xxl/common-utils";

type ErrorBoundaryProps = {
  fallback?: JSX.Element;
  onError?: (error: Error) => void;
};

const ErrorBoundary = (props: PropsWithChildren<ErrorBoundaryProps>) => {
  const { children, fallback, onError } = props;

  return (
    <ReactErrorBoundary
      fallback={fallback ?? <></>}
      onError={(error) =>
        isNotNullOrUndefined(onError) ? onError(error) : log.error(error)
      }
    >
      {children}
    </ReactErrorBoundary>
  );
};

export { ErrorBoundary };

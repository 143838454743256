import { Text } from "react-app/src/components/Text/Text";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { StyledLink } from "./ProductOverview.styles";
import { SECTION_NAMES } from "../constants";
import { Icon } from "react-app/src/components/Icon";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

const WRITE_REVIEW_LINK = `#${SECTION_NAMES.reviews}`;

export const WriteReviewLink = () => {
  const { t } = useTranslations();

  return (
    <StyledLink
      href={WRITE_REVIEW_LINK}
      data-testid="rr-rating-and-reviews-link"
    >
      <XxlStack direction="row" spacing="2px">
        <Icon
          name="Pencil"
          size={xxlTheme.typography.mediumRegular.fontSize}
          transform={{ translateX: 0, translateY: 2.5 }}
        />
        <Text
          size="small"
          style={{ fontSize: xxlTheme.typography.mediumRegular.fontSize }}
        >
          {t("product.ratings-and-reviews.write.a.review")}
        </Text>
      </XxlStack>
    </StyledLink>
  );
};

import React from "react";
import type { FunctionComponent } from "react";
import type { ContentSectionData } from "./content-section-helper";
import type { BaseBrandData } from "@xxl/frontend-api";
import { ContentSection } from "./ContentSection";
import { BrandsSection } from "./BrandsSection";
import { MAX_BRAND_LINKS } from "./config";

type OtherInfoProps = {
  contentData: ContentSectionData | null;
  brandLinks?: BaseBrandData[];
};

const OtherInfo: FunctionComponent<OtherInfoProps> = ({
  brandLinks,
  contentData,
}) => {
  if (contentData !== null) {
    return <ContentSection content={contentData} />;
  }

  if (brandLinks !== undefined && brandLinks.length > 0) {
    return <BrandsSection brandLinks={brandLinks} cutoff={MAX_BRAND_LINKS} />;
  }

  return null;
};

export { OtherInfo };

import { FONT_SIZE_TO_PREVENT_IOS_ZOOM_ON_FOCUS } from "react-app/src/constants";

const commonStyles = {
  lineHeight: 1.2,
  letterSpacing: -0.15,
};

export default {
  pageTitle: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 20,
    ...commonStyles,
    tablet: {
      fontSize: 24,
    },
  },
  productSplash: {
    fontFamily: "var(--font-family-medium)",
    fontSize: 12,
    ...commonStyles,
  },
  productDescription: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 12,
    fontWeight: 400,
    ...commonStyles,
    tablet: {
      fontSize: 15,
    },
  },
  productBrand: {
    fontFamily: "var(--font-family-bold)",
  },
  productQuantity: {
    fontFamily: "var(--font-family-regular)",
    ...commonStyles,
    fontSize: FONT_SIZE_TO_PREVENT_IOS_ZOOM_ON_FOCUS,
  },
  settingsDescriptionHeader: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 12,
    ...commonStyles,
    tablet: {
      fontSize: 15,
    },
  },
  rewardText: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 12,
    ...commonStyles,
    tablet: {
      fontSize: 15,
    },
  },
  rewardTrigger: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 12,
    ...commonStyles,
  },
  smallPrices: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 15,
    ...commonStyles,
  },
  totalPrice: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 20,
    ...commonStyles,
  },
  showMore: {
    fontFamily: "var(--font-family-medium)",
    fontSize: 15,
    ...commonStyles,
  },
  teamBundleText: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 15,
    ...commonStyles,
  },
  teamBundleStrong: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 15,
    ...commonStyles,
  },
  emptyPageHeading: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 20,
    ...commonStyles,
    laptop: {
      fontSize: 34,
    },
  },
  couponsRow: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 15,
    ...commonStyles,
  },
  miniCartHeader: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 20,
    ...commonStyles,
  },
  miniCartAmount: {
    fontFamily: "var(--font-family-medium)",
    fontSize: 12,
    textAlign: "center",
    ...commonStyles,
  },
  miniCartBigAmount: {
    fontFamily: "var(--font-family-medium)",
    fontSize: 10,
    textAlign: "center",
    lineHeight: 1,
    letterSpacing: -0.15,
  },
  errorMessage: {
    fontFamily: "var(--font-family-medium)",
    fontSize: 26,
    textAlign: "center",
    ...commonStyles,
    laptop: {
      fontSize: 34,
    },
  },
  productOutOfStock: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 20,
    textAlign: "center",
    ...commonStyles,
  },
  outOfStockRemove: {
    fontFamily: "var(--font-family-medium)",
    fontSize: 15,
    textAlign: "center",
    ...commonStyles,
  },
  requisitionHeading: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 26,
    lineHeight: 1,
    letterSpacing: -0.15,
  },
  requisitionsFormError: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 12,
    ...commonStyles,
  },
  requisitionsFormLabel: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 14,
    ...commonStyles,
  },
  collectWarning: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 12,
    ...commonStyles,
    tablet: {
      fontSize: 15,
    },
  },
  depositTitle: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 12,
    ...commonStyles,
    tablet: {
      fontSize: 15,
    },
  },
  depositPrice: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 12,
    fontWeight: 400,
    ...commonStyles,
    tablet: {
      fontSize: 15,
    },
  },
};

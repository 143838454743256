const fontSizeScale = {
  0: 10,
  1: 12,
  2: 15,
  3: 20,
  4: 24,
  5: 26,
  6: 34,
  7: 36,
  8: 60,
} as const;

const fontSizes = {
  tiny: fontSizeScale[0],
  small: fontSizeScale[1],
  medium: fontSizeScale[2],
  standard: fontSizeScale[2],
  big: fontSizeScale[3],
  huge: fontSizeScale[4],
  colossal: fontSizeScale[5],
  enormous: fontSizeScale[6],
  immense: fontSizeScale[7],
  vast: fontSizeScale[8],
} as const;

const fontFamilies = {
  regular: "var(--font-family-regular)",
  medium: "var(--font-family-medium)",
  bold: "var(--font-family-bold)",
} as const;

const typography = {
  tinyRegular: {
    fontSize: fontSizes.tiny,
    lineHeight: 1.2,
    letterSpacing: -0.15,
    fontFamily: fontFamilies.regular,
  },
  smallRegular: {
    fontSize: fontSizes.small,
    lineHeight: 1,
    letterSpacing: -0.15,
    fontFamily: fontFamilies.regular,
  },
  mediumRegular: {
    fontSize: fontSizes.medium,
    lineHeight: 1.1,
    letterSpacing: -0.15,
    fontFamily: fontFamilies.regular,
  },
  standardRegular: {
    fontSize: fontSizes.standard,
    lineHeight: 1.2,
    letterSpacing: -0.15,
    fontFamily: fontFamilies.regular,
  },
  bigRegular: {
    fontSize: fontSizes.big,
    lineHeight: 1.4,
    fontFamily: fontFamilies.regular,
  },
  tinyMedium: {
    fontSize: fontSizes.tiny,
    lineHeight: 1.2,
    fontFamily: fontFamilies.medium,
  },
  smallMedium: {
    fontSize: fontSizes.small,
    lineHeight: 1,
    fontFamily: fontFamilies.medium,
  },
  mediumMedium: {
    fontSize: fontSizes.medium,
    lineHeight: 1.1,
    fontFamily: fontFamilies.medium,
  },
  standardMedium: {
    fontSize: fontSizes.standard,
    lineHeight: 1.2,
    fontFamily: fontFamilies.medium,
  },
  tinyBold: {
    fontSize: fontSizes.tiny,
    lineHeight: 1.2,
    fontFamily: fontFamilies.bold,
  },
  smallBold: {
    fontSize: fontSizes.small,
    lineHeight: 1,
    fontFamily: fontFamilies.bold,
  },
  mediumBold: {
    fontSize: fontSizes.medium,
    lineHeight: 1.1,
    fontFamily: fontFamilies.bold,
  },
  standardBold: {
    fontSize: fontSizes.standard,
    lineHeight: 1.2,
    fontFamily: fontFamilies.bold,
  },
  bigBold: {
    fontSize: fontSizes.big,
    lineHeight: 1.4,
    fontFamily: fontFamilies.bold,
  },
  hugeBold: {
    fontSize: fontSizes.huge,
    lineHeight: 1.5,
    fontFamily: fontFamilies.bold,
  },
  colossalBold: {
    fontSize: fontSizes.colossal,
    lineHeight: 1,
    fontFamily: fontFamilies.bold,
    laptop: {
      fontSize: fontSizes.enormous,
    },
  },
} as const;

const h3Styles = {
  fontSize: fontSizes.big,
  lineHeight: 1.25,
  laptop: {
    fontSize: fontSizes.huge,
  },
} as const;

const h4Styles = {
  fontSize: fontSizes.medium,
  lineHeight: 1.2,
} as const;

export { fontFamilies, fontSizeScale, fontSizes, h3Styles, h4Styles };
export default typography;

import type {
  SimpleProductData,
  StrictPriceDisplay,
} from "../../components/ProductDetailsPage/types";
import type { ReviewsAndAggregatedRating } from "react-app/src/components/RatingsAndReviews/Api/RatingsAndReviewsAPI";
import type { SizeData, V3CategoryData } from "@xxl/pim-api";
import type {
  ItemAvailability,
  Organization,
  Product,
  WithContext,
} from "schema-dts";
import { getPriceDisplay } from "@xxl/prices-utils";
import { StockStatus } from "@xxl/pim-api";
import { isNotEmpty, isNotNullOrUndefined } from "@xxl/common-utils";
import parse from "html-react-parser";

type JsonLd = {
  product: WithContext<Product>;
  organization: WithContext<Organization>;
};

const sameAsMap: Record<string, string[]> = {
  "xxl.se": [
    "https://twitter.com/xxlsportse",
    "https://www.facebook.com/xxlsverige",
    "https://www.youtube.com/c/xxlallsportsunited",
  ],
  "xxl.no": [],
  "xxl.fi": [
    "https://twitter.com/XXLfi",
    "https://www.facebook.com/xxlsuomi",
    "https://instagram.com/xxlfi/",
    "https://fi.wikipedia.org/wiki/XXL_(urheilukauppaketju)",
    "https://www.youtube.com/c/xxlallsportsunited",
  ],
  "xxl.dk": [
    "https://www.facebook.com/xxldanmark",
    "https://www.youtube.com/c/xxlallsportsunited",
  ],
};

const removeHtmlAndNewlines = (input: string): string =>
  input.replace(/<[^>]*>|\n/g, "");

const getHighestStockStatus = (sizes: SizeData[]): StockStatus =>
  sizes.reduce<StockStatus>((highestStock, { stockStatus }) => {
    if (
      highestStock === StockStatus.INSTOCK ||
      stockStatus === StockStatus.INSTOCK
    ) {
      return StockStatus.INSTOCK;
    }
    if (
      highestStock === StockStatus.OUTOFSTOCK &&
      stockStatus === StockStatus.LOWSTOCK
    ) {
      return StockStatus.LOWSTOCK;
    }
    return highestStock;
  }, StockStatus.OUTOFSTOCK);

const mapStockStatus = (stockStatus: StockStatus): ItemAvailability => {
  if (stockStatus === StockStatus.INSTOCK) {
    return "https://schema.org/InStock";
  }
  if (stockStatus === StockStatus.LOWSTOCK) {
    return "https://schema.org/LimitedAvailability";
  }
  return "https://schema.org/OutOfStock";
};

const getOrganization = (
  frontEndServerUrl: string
): WithContext<Organization> & { "@id": string } => ({
  "@context": "https://schema.org",
  "@type": "Organization",
  "@id": `${frontEndServerUrl}/#organization`,
  url: `${frontEndServerUrl}/`,
  name: "XXL Sports & Outdoor",
  logo: `${frontEndServerUrl}/content/images/6279c5f6843d72f0b61c08b6ec70bc80b64795d4-770x162.svg`,
  sameAs: sameAsMap[frontEndServerUrl.split(".").slice(-2).join(".")],
});

const getJsonLdData = (
  frontEndServerUrl: string,
  simpleProduct: SimpleProductData,
  brand: V3CategoryData,
  productImages: string[],
  priceDisplays: StrictPriceDisplay[],
  sizeOptions: SizeData[]
): JsonLd => {
  const organization = getOrganization(frontEndServerUrl);
  const priceDisplay = getPriceDisplay({ isLoggedIn: false, priceDisplays });
  const product: WithContext<Product> = {
    "@context": "https://schema.org",
    "@type": "Product",
    sku: simpleProduct.styleCode,
    name: simpleProduct.name,
    image: productImages.map((img) => frontEndServerUrl + img),
    description: removeHtmlAndNewlines(simpleProduct.description ?? ""),
    brand: {
      "@type": "Brand",
      name: brand.name,
      image: brand.logo,
    },
    color: simpleProduct.styleColorCode,
    offers: {
      "@type": "Offer",
      url: frontEndServerUrl + (simpleProduct.url ?? ""),
      itemCondition: "https://schema.org/NewCondition",
      availability: mapStockStatus(getHighestStockStatus(sizeOptions)),
      price: priceDisplay?.salesPrice,
      priceCurrency: priceDisplay?.currencyIso,
      priceValidUntil: priceDisplay?.endDate,
      seller: {
        "@id": organization["@id"],
      },
    },
  };

  return {
    product,
    organization,
  };
};

const addReviews = (
  jsonLd: JsonLd,
  { reviews, aggregatedRating }: ReviewsAndAggregatedRating
): JsonLd => ({
  ...jsonLd,
  product: {
    ...jsonLd.product,
    ...(isNotEmpty(reviews)
      ? {
          review: reviews.map((review) => ({
            "@type": "Review",
            reviewRating: {
              "@type": "Rating",
              ratingValue: review.rating,
            },
            author: {
              "@type": "Person",
              name: review.displayName,
            },
            datePublished: new Date(review.creationDate).toISOString(),
          })),
        }
      : {}),
    ...(isNotNullOrUndefined(aggregatedRating)
      ? {
          aggregateRating: {
            "@type": "AggregateRating",
            ratingValue: aggregatedRating.average,
            reviewCount: aggregatedRating.quantity,
          },
        }
      : {}),
  },
});

const getJsonLdScript = (jsonLd: JsonLd) =>
  parse(JSON.stringify([jsonLd.product, jsonLd.organization]));

export { getJsonLdData, getJsonLdScript, addReviews };

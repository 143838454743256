import styled from "@emotion/styled/macro";
import { xxlTheme } from "../styles/xxl-theme";

const { colors } = xxlTheme;

export const FullSizeFlex = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const SpaceBetweenFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FullWidthDiv = styled.div`
  width: 100%;
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  > main {
    flex-grow: 1;
  }
`;

export const borderCss = `1px solid ${colors.xxlMediumGrey}`;

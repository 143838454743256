import styled from "@emotion/styled/macro";
import { typographyToCss } from "react-app/src/styles/helpers";
import spaces from "react-app/src/styles/theme/spaces";

export const Wrapper = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: 26px;
  padding: 0 ${spaces.miniMicro};
  display: flex;
  gap: ${spaces.miniMicro};
  align-items: center;
`;

export const CampaignName = styled.p`
  ${typographyToCss({
    lineHeight: 0.9,
    fontFamily: "var(--font-family-medium)",
    letterSpacing: 0.5,
  })}
`;

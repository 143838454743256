import type { Store, Location as StoreLocation } from "@xxl/content-api";
import {
  coordinatesDistance,
  requestUserCoordinates,
} from "../../../../utils/xxl-geolocation";
import typography from "../../../../styles/theme/typography";

export type TRegionalStore = {
  regionIds: string[];
  id: string;
  name: string;
};

export type StoreWithId = Store & TRegionalStore;

const hasIdAndName = (store: Store): store is Required<StoreWithId> => {
  return store.id !== undefined && store.name !== undefined;
};

const sortDataByGeolocation = (
  stores: Store[],
  userCoordinates: GeolocationPosition
): Store[] => {
  const getLocation = (store: Store): StoreLocation | undefined => {
    return store.location;
  };

  const getDistanceFromUserLocation = (
    userCoordinates: GeolocationPosition,
    location: StoreLocation
  ) => {
    const { latitude, longitude } = location;
    const distanceFromUserLocation = coordinatesDistance.get(
      userCoordinates.coords.latitude,
      userCoordinates.coords.longitude,
      latitude,
      longitude
    );
    return distanceFromUserLocation;
  };

  return stores.sort((storeA, storeB) => {
    const locationA = storeA.location;
    const locationB = getLocation(storeB);

    if (locationA === undefined || locationB === undefined) {
      return 0;
    }

    const distanceToA = getDistanceFromUserLocation(userCoordinates, locationA);
    const distanceToB = getDistanceFromUserLocation(userCoordinates, locationB);

    if (distanceToA === undefined || distanceToB === undefined) {
      return 0;
    }

    return distanceToA - distanceToB;
  });
};

const sortDataAlphabetically = (stores: Store[]) =>
  stores.sort((a, b) => {
    const first = a.name;
    const second = b.name;
    return first !== undefined && second !== undefined
      ? first.localeCompare(second)
      : 0;
  });

const getSortedStoresList = async (stores: Store[]): Promise<Store[]> => {
  try {
    const userCoordinates = await requestUserCoordinates();
    return sortDataByGeolocation(stores, userCoordinates);
  } catch (_error) {
    return sortDataAlphabetically(stores);
  }
};

const getFontFamily = (isBold: boolean) =>
  isBold
    ? typography.standardBold.fontFamily
    : typography.standardRegular.fontFamily;

export { hasIdAndName, getSortedStoresList, getFontFamily };

export const getPercentage = (
  freeShippingAmount: number,
  cartAmount: number
) => {
  if (freeShippingAmount < 0 || cartAmount < 0) {
    throw Error(
      `Amounts cannot be negative. ${freeShippingAmount}, ${cartAmount}`
    );
  }

  if (cartAmount === 0) {
    return 0;
  }

  const percentage = Math.floor((cartAmount / freeShippingAmount) * 100);

  return percentage > 100 ? 100 : percentage;
};

import { Label, Price, Wrapper } from "./Price.styled";

type OtherPricesInfoProps = {
  hasUndeductedDiscount: boolean;
  isSellingPriceDiscounted: boolean;
  priceFormatted: string;
  label?: string;
};

export const AlternatePrice = ({
  hasUndeductedDiscount,
  isSellingPriceDiscounted,
  label,
  priceFormatted,
}: OtherPricesInfoProps) => (
  <Wrapper>
    <Price
      isDiscounted={hasUndeductedDiscount}
      textDecoration={isSellingPriceDiscounted ? "line-through" : "none"}
    >
      {priceFormatted}
    </Price>
    {label !== undefined && <Label>{label}</Label>}
  </Wrapper>
);

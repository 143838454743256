import { getLegacySiteUid } from "@/utils/environment-variables";
import { type CategoryData } from "@xxl/pim-api";
import { getDiscountCategoryForCategoryData } from "@/utils/category-data-helper";
import { Apis } from "@/utils/api-helper";

export const fetchCategoryData = async (
  categoryCode: string | undefined
): Promise<CategoryData | undefined> => {
  if (categoryCode === undefined) {
    return;
  }
  const { data } = await Apis.getInstance().pimApi.getCategories(
    getLegacySiteUid(),
    categoryCode
  );
  if (data.length === 0) {
    return;
  }
  const [categoryData] = data;
  const discountCategory =
    await getDiscountCategoryForCategoryData(categoryData);
  if (discountCategory !== null) {
    categoryData.subCategories?.push(discountCategory);
  }

  const subCategories = categoryData.subCategories
    ?.filter(({ productCount }) => (productCount ?? 0) > 0)
    .map(({ name, url }) => ({ name, url }));
  return {
    code: categoryCode,
    url: categoryData.url,
    subCategories: subCategories,
    categoryLevel: categoryData.categoryLevel,
    pageTitle: categoryData.pageTitle,
    breadcrumbs: categoryData.breadcrumbs,
  };
};

const spaces = {
  hair: "1px",
  line: "2px",
  micro: "4px",
  miniMicro: "6px",
  mini: "8px",
  miniRegular: "10px",
  smallRegular: "12px",
  regular: "16px",
  small: "18px",
  smallLarge: "20px",
  large: "24px",
  largeBig: "26px",
  extraLarge: "30px",
  bigSmall: "32px",
  big: "36px",
  biggerBig: "40px",
  giantBig: "42px",
  miniHuge: "46px",
  smallHuge: "48px",
  mediumHuge: "50px",
  almostHuge: "54px",
  huge: "56px",
  smallGiant: "72px",
  giant: "96px",
  biggerGiant: "120px",
  letterSpaceNormal: "-0.01em",
  letterSpaceWide: ".125rem;",
} as const;

export default spaces;

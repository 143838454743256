import React from "react";
import { Text } from "./HiddenText.styled";

type HiddenTextProps = {
  text: string;
};
const HiddenText: React.FunctionComponent<HiddenTextProps> = ({ text }) => (
  <Text>{text}</Text>
);

export { HiddenText };

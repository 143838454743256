import type { MegaMenuContentData } from "@xxl/frontend-api";
import { fetchMegaMenu } from "@/utils/apis/content-mega-menu";

const getMegaMenuContent = async (): Promise<MegaMenuContentData> => {
  const megaMenuContentData = await fetchMegaMenu();
  if (megaMenuContentData === undefined) {
    throw new Error("Mega menu content not found");
  }
  return megaMenuContentData;
};

export { getMegaMenuContent };

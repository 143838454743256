import axios, { type AxiosResponse } from "axios";
import type { EcoResponseProps } from "../../components/Pdp/types";

export const fetchEcoOnlineData =
  ({ baseUrl, apiKey }: { baseUrl: string; apiKey: string }) =>
  async (
    productId: string,
    siteHost: string
  ): Promise<AxiosResponse<EcoResponseProps>> => {
    const queryResponse = await axios({
      url: `${baseUrl}?limit=25&
    page=0&
    orderBy=product_name&
    direction=asc&
    selectedColumns=
      product_name,
      supplier,
      internal_no,
      product_group,
      language,
      date,
      area_of_use,
      hazard_statements,
      synonyms,
      classification,
      article_no,
      hazard_symbols,
      description,
      sds,
      sps,
      external_documents
    &
    advancedSearch=1&
    languages[]=2&
    markets[]=2&
    article_no=${productId}&
    allowed_domains[]=${siteHost}`
        .split("\n")
        .map((s) => s.trim())
        .filter(Boolean)
        .join(""),
      headers: {
        "X-SEARCH-CONFIGURATION-API-KEY": apiKey,
        Accept: "application/json",
      },
    });

    return queryResponse;
  };

import type { Translate } from "../../../../contexts/Translations/TranslationsContext";
import * as yup from "yup";

const forgotPasswordSchema = (t: Translate) => {
  const requiredMessage = t("form.field.required");
  const invalidEmailMessage = t("form.email.invalid");

  return yup.object({
    email: yup.string().required(requiredMessage).email(invalidEmailMessage),
  });
};

const emailField = (t: Translate) =>
  ({
    autocompleteToken: "email",
    fieldName: "email",
    id: "email",
    label: t("form.email"),
    inputMode: "email",
    placeholder: t("reward.signup.placeholder.email"),
  }) as const;

export { forgotPasswordSchema, emailField };

import { pagePaths } from "../constants";
import { windowAccess } from "./Window";

export enum PageType {
  Account = "account",
  Brand = "brand",
  Bundle = "bundle",
  Campaign = "campaign",
  Category = "category",
  Checkout = "checkout",
  Confirmation = "confirmation",
  FaqPage = "faq",
  Guide = "guide",
  Home = "home",
  ProductConfigurator = "product-configurator",
  ProductPage = "product",
  Search = "search",
  Shared = "shared",
  Store = "store",
  StoreFinder = "storeFinder",
  Cart = "cart",
  TeamsalesClub = "club",
}

export enum ProductListPages {
  Brand = PageType.Brand,
  Campaign = PageType.Campaign,
  Category = PageType.Category,
  ProductConfigurator = PageType.ProductConfigurator,
  Search = PageType.Search,
}

export const isBrandPage = (): boolean =>
  windowAccess()._sharedData.pageType === PageType.Brand;

export const isBundlePage = (): boolean =>
  windowAccess()._sharedData.pageType === PageType.Bundle;

export const isCampaignPage = (): boolean =>
  windowAccess()._sharedData.pageType === PageType.Campaign;

export const isCategoryPage = (): boolean =>
  windowAccess()._sharedData.pageType === PageType.Category;

export const isProductPage = (): boolean =>
  windowAccess()._sharedData.pageType === PageType.ProductPage;

export const isSearchPage = (): boolean =>
  windowAccess()._sharedData.pageType === PageType.Search;

export const isConfirmationPage = (): boolean =>
  windowAccess()._sharedData.pageType === PageType.Confirmation;

export const isProductListPage = (): boolean =>
  Object.values(ProductListPages).some(
    (pageType) => windowAccess()._sharedData.pageType === pageType
  );

export const isFaqPage = (): boolean =>
  windowAccess()._sharedData.pageType === PageType.FaqPage;

/**
 * Cart page is the old checkout page, hence checking both types
 */
export const isCartPage = (): boolean => {
  const {
    location,
    _sharedData: { pageType },
  } = windowAccess();

  return (
    pageType === PageType.Cart ||
    pageType === PageType.Checkout ||
    (location !== undefined &&
      "href" in location &&
      location.href.includes(pagePaths.checkout))
  );
};

export const isTeamsalesClubPage = (): boolean =>
  windowAccess()._sharedData.pageType === PageType.TeamsalesClub;

export const isSharedPage = (): boolean =>
  windowAccess()._sharedData.pageType === PageType.Shared;

import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { xxlTheme } from "../../styles/xxl-theme";
import { typographyToCss, MQ } from "../../styles/helpers";
import typography from "./AutoSuggestion.typography";

const { colors, spaces } = xxlTheme;

export const SectionHeader = styled.h3`
  width: 240px;
  ${typographyToCss(typography.header)};
  border-bottom: 1.5px solid ${colors.xxlGreen};
  color: ${colors.xxlWebBlack};
  padding-bottom: ${spaces.mini};
  margin: 0;

  ${MQ("laptop")} {
    width: 210px;
  }

  ${MQ("desktop")} {
    width: 240px;
  }
`;

export const ItemList = styled.ul`
  list-style-type: none;
  margin: ${spaces.mini} 0 ${spaces.smallLarge};
  padding: 0;
`;

export const LinkText = styled.a`
  color: ${colors.xxlWebBlack};
  ${typographyToCss(typography.item)};
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  margin: 0 0 ${spaces.line};

  & b {
    font-family: var(--font-family-bold);
    font-weight: 700;
  }

  &:hover {
    font-family: var(--font-family-bold);
    font-weight: 700;
  }
`;

export const SectionWrapper = styled.div`
  width: 240px;
  break-inside: avoid-column;
  padding: 0 ${spaces.smallRegular};

  ${MQ("tablet")} {
    padding: 0;
  }

  ${MQ("laptop")} {
    width: 210px;
  }

  ${MQ("desktop")} {
    width: 240px;
  }
`;

export const ProductsSectionWrapper = styled.div(css`
  width: auto;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0 ${spaces.smallRegular};
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  align-items: flex-end;

  ${MQ("tablet")} {
    max-width: calc(100% - 210px - 36px);
    padding: 0;
    max-height: none;
    overflow: visible;
    flex-grow: 1;
  }
`);

import type { EntitySortingParameter } from "@xxl/search-api";
import {
  EntitySortingParameterCustomNameEnum,
  EntitySortingParameterTypeEnum,
} from "@xxl/search-api";
import type { TranslationKey } from "../../../translations";

export class SortDataImpl implements SortData {
  private readonly type: EntitySortingParameterTypeEnum;
  private readonly customName?: EntitySortingParameterCustomNameEnum;
  private readonly attributeName?: string;

  readonly displayName: EntitySortingParameterCustomNameEnum;

  readonly name: TranslationKey;

  private constructor(
    sortType: EntitySortingParameterCustomNameEnum,
    type: EntitySortingParameterTypeEnum = EntitySortingParameterTypeEnum.custom,
    attribute?: string
  ) {
    this.type = type;

    switch (this.type) {
      case EntitySortingParameterTypeEnum.custom:
        this.customName = sortType;
        break;
      case EntitySortingParameterTypeEnum.attribute:
        if (attribute !== undefined) {
          this.attributeName = attribute;
        }
        break;
      default:
        throw new Error("Unsupported sort exception");
    }

    this.displayName = sortType;
    this.name = `solr.sorts.names.${sortType.replace(
      /[A-Z]/g,
      (letter) => `.${letter.toLowerCase()}`
    )}` as TranslationKey;
  }

  asEntitySortingParameter = (): EntitySortingParameter => ({
    type: this.type,
    customName: this.customName,
    attributeName: this.attributeName,
  });

  static customSort = (customName: EntitySortingParameterCustomNameEnum) =>
    new SortDataImpl(customName, EntitySortingParameterTypeEnum.custom);

  static attributeSort = (
    attributeName: string,
    displayName: EntitySortingParameterCustomNameEnum
  ) =>
    new SortDataImpl(
      displayName,
      EntitySortingParameterTypeEnum.attribute,
      attributeName
    );

  equals = (other: EntitySortingParameter) => {
    const current = this.asEntitySortingParameter();
    return (
      current.type === other.type &&
      current.customName === other.customName &&
      current.attributeName === other.attributeName
    );
  };

  get sortType(): EntitySortingParameterCustomNameEnum {
    return this.displayName;
  }
}

export const SORTS_FOR_SEARCH: SortData[] = [
  SortDataImpl.customSort(EntitySortingParameterCustomNameEnum.relevance),
  SortDataImpl.customSort(EntitySortingParameterCustomNameEnum.cheapest),
  SortDataImpl.customSort(EntitySortingParameterCustomNameEnum.expensive),
  SortDataImpl.customSort(EntitySortingParameterCustomNameEnum.highest_rating),
];

export const SORTS_FOR_CATEGORY_LOOP_SEARCH: SortData[] = [
  SortDataImpl.attributeSort(
    "bigProduct_boolean",
    EntitySortingParameterCustomNameEnum.relevance
  ),

  SortDataImpl.customSort(EntitySortingParameterCustomNameEnum.cheapest),
  SortDataImpl.customSort(EntitySortingParameterCustomNameEnum.expensive),
  SortDataImpl.customSort(EntitySortingParameterCustomNameEnum.highest_rating),
];

export const SORTS_FOR_CAMPAIGN_LOOP_SEARCH: SortData[] = [
  SortDataImpl.attributeSort(
    "bigProduct_boolean",
    EntitySortingParameterCustomNameEnum.relevance
  ),

  SortDataImpl.customSort(EntitySortingParameterCustomNameEnum.cheapest),
  SortDataImpl.customSort(EntitySortingParameterCustomNameEnum.expensive),
  SortDataImpl.customSort(EntitySortingParameterCustomNameEnum.highest_rating),
];

export const SORTS_FOR_CAMPAIGN: SortData[] = [
  SortDataImpl.customSort(EntitySortingParameterCustomNameEnum.cheapest),
  SortDataImpl.customSort(EntitySortingParameterCustomNameEnum.expensive),
  SortDataImpl.customSort(EntitySortingParameterCustomNameEnum.highest_rating),
];

export const SORTS_FOR_REGISTERED: SortData[] = [
  SortDataImpl.customSort(
    EntitySortingParameterCustomNameEnum.registered_highest_discount
  ),
];
export const SORTS_FOR_NON_REGISTERED: SortData[] = [
  SortDataImpl.customSort(
    EntitySortingParameterCustomNameEnum.highest_discount
  ),
];

export interface SortData {
  sortType: EntitySortingParameterCustomNameEnum;
  name: TranslationKey;

  asEntitySortingParameter: () => EntitySortingParameter;

  equals: (_: EntitySortingParameter) => boolean;
}

import {
  FI_SOCIAL_SECURITY_NUMBER_REGEXP,
  SE_SOCIAL_SECURITY_NUMBER_REGEXP,
} from "@xxl/common-utils";
import type { EcomSiteUid } from "@xxl/common-utils";
import _ from "lodash";

type SocialSecurityNumberMarket = Extract<EcomSiteUid, "xxl-fi" | "xxl-se">;

const regexps: Record<SocialSecurityNumberMarket, RegExp> = {
  "xxl-fi": FI_SOCIAL_SECURITY_NUMBER_REGEXP,
  "xxl-se": SE_SOCIAL_SECURITY_NUMBER_REGEXP,
};

const ValidateSocialSecurityNumber = (
  siteUid: EcomSiteUid,
  number: string
): boolean => {
  if (siteUid === "xxl-se" || siteUid === "xxl-fi") {
    const regexp = regexps[siteUid];
    return regexp.test(number);
  } else {
    return true;
  }
};

export { ValidateSocialSecurityNumber };

import { useEffect, useState } from "react";
import { getProductSizesStockStatus } from "react-app/src/components/ClickAndCollect/api/api";
import { useSharedData } from "react-app/src/contexts/SharedData";
import type { StockStatus } from "react-app/src/generated/graphql-code-generator";
import { log } from "@xxl/logging-utils";

export type ProductSizesStockStatus = {
  ean: string;
  onlineStockStatus: StockStatus;
  storeStockStatus: StockStatus | null;
  storesWithStockCount: number;
};

export const useProductSizesStockStatus = (
  productId?: string,
  storeIds?: string[]
) => {
  const [productSizesStockStatus, setProductSizesStockStatus] = useState<
    ProductSizesStockStatus[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    configuration: {
      amplifyConfig: { aws_appsync_graphqlEndpoint, aws_appsync_apiKey },
    },
  } = useSharedData().data;

  useEffect(() => {
    if (productId !== undefined) {
      setIsLoading(true);
      const fetchProductSizesStockStatus = async () => {
        try {
          const res = await getProductSizesStockStatus({
            productCode: productId.toString(),
            storeIds: storeIds?.map((id) => id.toString()) ?? undefined,
            graphQLEndpointUrl: aws_appsync_graphqlEndpoint,
            graphQLApiKey: aws_appsync_apiKey,
          });

          setProductSizesStockStatus(res);
        } catch (e) {
          log.error("error", e);
        } finally {
          setIsLoading(false);
        }
      };
      void fetchProductSizesStockStatus();
    }
  }, [storeIds]);

  return {
    productSizesStockStatus,
    isLoadingProductSizesStockStatus: isLoading,
  };
};

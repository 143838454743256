import type { Ref } from "react";
import React from "react";
import { XxlButton } from "react-app/src/components/Common/XxlButton";
import { Icon } from "react-app/src/components/Icon";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { dispatchEvent } from "react-app/src/utils/xxl-event";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { ErrorBoundary } from "react-app/src/components/Common/ErrorBoundary/ErrorBoundary";
import { SizeOptions } from "./SizeOptions";
import { BUTTON_SIZE_FOR_SIZE_OPTIONS } from "./constants";
import type { TProductForm } from "./types";
import { usePdpContext } from "react-app/src/components/ProductDetailsPage/ProductDetailsPage.state";
import { Message, StickyFormContent } from "./styles";
import { useXxlMediaQuery } from "react-app/src/hooks/useXxlMediaQuery";
import stringFormat from "string-format";

const FormButton = ({ isFetchingStores }: { isFetchingStores: boolean }) => {
  const { t } = useTranslations();
  const handleClick = () => dispatchEvent("OPEN_STORE_STOCK_MODAL");
  return (
    <XxlButton
      disabled={isFetchingStores}
      loading={isFetchingStores}
      variant="secondary"
      onClick={handleClick}
    >
      {t("product.details.check.store.availability.button.label")}
      <Icon name="CaretRight" />
    </XxlButton>
  );
};

export const ProductFormOnlyAvailableInStore = ({
  formRef,
  isClickAndCollectEnabled,
  isPurchaseAllowed,
  isRefInView,
  isSizeSelectDrawerOpen,
  onSelectSize,
  preSelectedSizeCode,
  productName = "",
  quantityToggleEnabled,
  sizeOptions,
  styleCode,
  toggleIsSizeSelectDrawerOpen,
  unit,
}: TProductForm & {
  formRef: Ref<HTMLDivElement>;
  isRefInView: boolean;
}) => {
  const { t } = useTranslations();
  const isLaptopSize = useXxlMediaQuery("LaptopMediaQuery");
  const {
    state: { isFetchingStores },
  } = usePdpContext();
  const msgText = stringFormat(
    t("product.details.only.available.in.store.product"),
    productName
  );

  const formContent = (
    <>
      <FormButton isFetchingStores={isFetchingStores} />
      <Message text={msgText} />
    </>
  );

  return (
    <XxlStack ref={formRef} spacing={xxlTheme.spaces.micro}>
      <ErrorBoundary>
        <SizeOptions
          buttonSize={BUTTON_SIZE_FOR_SIZE_OPTIONS}
          isPurchaseAllowed={isPurchaseAllowed}
          isSizeSelectDrawerOpen={isSizeSelectDrawerOpen}
          onSelectSize={onSelectSize}
          preSelectedSizeCodeOrEan={preSelectedSizeCode}
          styleCode={styleCode}
          quantityToggleEnabled={quantityToggleEnabled}
          sizeOptions={sizeOptions}
          toggleIsSizeSelectDrawerOpen={toggleIsSizeSelectDrawerOpen}
          unit={unit}
          isClickAndCollectEnabled={isClickAndCollectEnabled}
        />
      </ErrorBoundary>
      {formContent}
      <StickyFormContent
        shouldShowStickyContent={!isRefInView && !isLaptopSize}
      >
        {formContent}
      </StickyFormContent>
    </XxlStack>
  );
};

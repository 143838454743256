import { useState } from "react";
import { Stack } from "@mui/material";
import { Info } from "@xxl/icons";
import { PriceDrawer } from "./PriceDrawer";
import { REGISTERED_CUSTOMERS_USER_GROUP } from "react-app/src/constants";
import { useSharedData } from "react-app/src/contexts/SharedData";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import {
  Label,
  LowestPrice,
  LowestPriceLabel,
  PriceButton,
  SalesPrice,
  SalesPriceWrapper,
  SecondaryPrice,
} from "./Price.styled";
import { XxlStack } from "react-app/src/components/Common/XxlStack";

export type PriceProps = {
  hasUndeductedRewardDiscount: boolean;
  isDiscountedMainPrice: boolean;
  isInvertedPrice: boolean;
  salesPriceFormatted: string;
  latestPriceFormatted?: string;
  secondaryPriceFormatted?: string;
  secondaryPriceDisclaimer?: string;
  priceDisclaimer?: string;
  widget?: JSX.Element;
  userGroupId?: string;
};

export const Price = ({
  hasUndeductedRewardDiscount,
  isDiscountedMainPrice,
  isInvertedPrice,
  latestPriceFormatted,
  secondaryPriceDisclaimer,
  secondaryPriceFormatted,
  priceDisclaimer,
  salesPriceFormatted,
  widget,
  userGroupId,
}: PriceProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const {
    featureToggles: { toggle_lowest_price },
    siteUid,
  } = useSharedData().data;
  const { t } = useTranslations();
  const toggleIsDrawerOpen = () => setIsDrawerOpen(!isDrawerOpen);
  const isRegisteredUserPrice = userGroupId === REGISTERED_CUSTOMERS_USER_GROUP;
  const shouldShowDisclaimer =
    (priceDisclaimer !== undefined && !isInvertedPrice) || siteUid === "xxl-fi";

  return (
    <>
      <XxlStack
        spacing="2px"
        direction="column"
        justifyContent="flex-start"
        alignItems={"start"}
      >
        <SalesPriceWrapper>
          <SalesPrice
            isDiscounted={
              isDiscountedMainPrice ||
              (isInvertedPrice && isRegisteredUserPrice)
            }
            data-testid="sales-price"
          >
            {salesPriceFormatted}
          </SalesPrice>
          {widget ?? null}
        </SalesPriceWrapper>
        {shouldShowDisclaimer && (
          <LowestPriceLabel>{priceDisclaimer}</LowestPriceLabel>
        )}
        {secondaryPriceFormatted !== undefined &&
          salesPriceFormatted !== secondaryPriceFormatted &&
          secondaryPriceDisclaimer !== undefined && (
            <Stack direction="row" gap="4px">
              <Label>{secondaryPriceDisclaimer}</Label>
              <SecondaryPrice
                hasUndeductedRewardDiscount={hasUndeductedRewardDiscount}
                isInvertedPrice={isInvertedPrice}
              >
                {secondaryPriceFormatted}
              </SecondaryPrice>
            </Stack>
          )}
        {latestPriceFormatted !== undefined && toggle_lowest_price && (
          <Stack
            spacing={0.5}
            direction="row"
            sx={{ alignItems: "center", position: "relative", top: "-6px" }}
          >
            <LowestPriceLabel>
              {t("product.price.display.latest.price")}
            </LowestPriceLabel>
            <LowestPrice>{latestPriceFormatted}</LowestPrice>
            <PriceButton aria-label={t("product.price.display.latest.price")}>
              <Info fontSize={16} onClick={toggleIsDrawerOpen} />
            </PriceButton>
          </Stack>
        )}
      </XxlStack>
      <PriceDrawer
        drawerOpen={isDrawerOpen}
        handleOnClose={toggleIsDrawerOpen}
        salesPriceFormatted={salesPriceFormatted}
        previousPriceFormatted={secondaryPriceFormatted}
        latestPriceFormatted={latestPriceFormatted}
      />
    </>
  );
};

import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";

type ImageProps = {
  withDescription?: boolean;
};

export const StyledImage = styled.img<ImageProps>`
  width: 100%;
  padding-top: 6px;

  ${({ withDescription }) =>
    withDescription &&
    css`
      max-width: 212px;
    `}
`;

import type { Store } from "@xxl/content-api";
import * as React from "react";
import type { DeviceProps } from "../../global";
import { StoreStockSelectedDetailsMapLinkStyled } from "./StoreStockStyles";

const GOOGLE_MAPS_SEARCH_URL = "https://www.google.com/maps/search/?";

type Props = React.PropsWithChildren<
  DeviceProps & {
    selectedStoreDetails?: Store;
  }
>;

const StoreStockSelectedDetailsMapLink = ({
  selectedStoreDetails,
  children,
}: Props) => {
  const searchParams = new URLSearchParams();
  searchParams.append("api", "1");

  searchParams.append(
    "query",
    `${selectedStoreDetails?.contact?.address ?? ""}
    ${selectedStoreDetails?.contact?.postalCode ?? ""}
    ${selectedStoreDetails?.contact?.region ?? ""}`
  );
  searchParams.append(
    "query_place_id",
    `${selectedStoreDetails?.googlePlaceId ?? ""}`
  );
  searchParams.toString();
  return (
    <StoreStockSelectedDetailsMapLinkStyled
      href={`${GOOGLE_MAPS_SEARCH_URL}${searchParams.toString()}}`}
      target="_blank"
    >
      {children}
    </StoreStockSelectedDetailsMapLinkStyled>
  );
};

export { StoreStockSelectedDetailsMapLink };

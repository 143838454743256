import type { SerializedStyles } from "@emotion/react";
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { underlineLinear } from "../../../styled/mixins";
import { typographyToCss } from "../../../styles/helpers";
import { xxlTheme } from "../../../styles/xxl-theme";
import { CaretIcon } from "../Icons/Caret";
import { borderCss } from "../../../styled/Common.styled";

const { spaces, colors, typography } = xxlTheme;

const caretIconSize = spaces.smallRegular;
const underlineColorValid = "var(--color-xxl-green)";
const underlineColorInvalid = "var(--color-xxl-red)";
const underlineColorPending = colors.xxlGrey;
const selectBoxSidePadding = "14px";

export enum Validity {
  PENDING,
  PENDING_ACTIVE,
  INVALID,
  VALID,
}

type WrapperProps = {
  isLoading?: boolean;
  isOpen: boolean;
  validity?: Validity;
  isDisabled: boolean;
};

type ContentProps = {
  isOpen: boolean;
  maxHeight?: string;
  separateNthFromBottom?: number;
  separateNthFromTop?: number;
};

type LabelProps = {
  isMandatory?: boolean;
  isDisabled?: boolean;
};

const pendingStateAnimation = keyframes`
  0% { transform: translateX(-100%) }
  100% { transform: translateX(100%) }
`;

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  background-color: ${colors.xxlWhite};
  cursor: pointer;
  margin: 0;
  width: 100%;
  height: 100%;

  ${({ validity }): string =>
    validity === Validity.VALID
      ? `${underlineLinear(underlineColorValid)}`
      : ""};

  ${({ validity }): string =>
    validity === Validity.INVALID
      ? `${underlineLinear(underlineColorInvalid)}`
      : ""};

  ${({ isLoading = false }) =>
    isLoading
      ? css`
          overflow-x: hidden;
          ${underlineLinear(underlineColorPending)};
          &::after {
            animation: ${pendingStateAnimation} 1s infinite;
          }
        `
      : ""};

  ${({ validity }) =>
    validity === Validity.PENDING_ACTIVE
      ? css`
          ${underlineLinear(underlineColorPending)};
        `
      : ""};

  ${({ isDisabled }): string => (isDisabled ? "pointer-events: none;" : "")}
`;

type TextBoxProps = {
  hasError?: boolean;
  isDisabled: boolean;
};

export const TextBox = styled.p<TextBoxProps>(
  ({ hasError = false, isDisabled = false }) => css`
    ${typographyToCss(typography.mediumRegular)}
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: ${spaces.smallRegular} ${selectBoxSidePadding};
    color: var(--font-color-dark-gray);
    border: 1px solid
      ${hasError ? "var(--color-xxl-red)" : "var(--color-xxl-gray)"};
    line-height: ${spaces.large};
    color: ${isDisabled ? colors.xxlGrey : "inherit"};
    pointer-events: ${isDisabled ? "none" : "initial"};
    outline-color: ${colors.xxlDarkGreen};
  `
);

export const Arrow = styled(CaretIcon)`
  font-size: ${caretIconSize};
`;

export const UlAsListBox = styled.ul<ContentProps>`
  position: absolute;
  left: 0;
  z-index: 101;
  display: ${(props): string => (props.isOpen ? "block" : "none")};
  box-sizing: border-box;
  width: 100%;
  max-height: ${({ maxHeight = "none" }): string => maxHeight};
  margin: 0 0 40px;
  padding: 0;
  overflow: auto;
  list-style-type: none;
  background-color: var(--color-xxl-white);
  border-right: ${borderCss};
  border-bottom: ${borderCss};
  border-left: ${borderCss};
  -webkit-overflow-scrolling: touch;
  ${({ separateNthFromTop }): string | SerializedStyles =>
    separateNthFromTop === undefined
      ? ""
      : css`
          & > li:nth-of-type(${separateNthFromTop}) {
            border-bottom: ${borderCss};
          }
        `};
  ${({ separateNthFromBottom }): string | SerializedStyles =>
    separateNthFromBottom === undefined
      ? ""
      : css`
          & > li:nth-last-of-type(${separateNthFromBottom}) {
            border-top: ${borderCss};
          }
        `};
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px ${selectBoxSidePadding};
  color: #000;

  font-size: 15px;
  line-height: 1.2;
  text-overflow: ellipsis;
  letter-spacing: -0.15;
  font-family: var(--font-family-regular);
  background-color: var(--color-xxl-white);
  cursor: pointer;
  transition: background-color 50ms;

  &:hover,
  &:focus {
    background-color: var(--color-xxl-light-gray);
    outline: none;
    transition: background-color 0ms;
  }
`;

export const Label = styled.label<LabelProps>`
  ${typographyToCss(typography.standardRegular)};
  letter-spacing: -0.15px;
  display: inline-block;
  margin-bottom: ${spaces.smallRegular};

  ${(props): string => {
    return props.isMandatory === true
      ? `&:after {
          content: "*";
        }`
      : "";
  }}

  ${({ isDisabled = false }): string =>
    isDisabled ? `color: ${colors.xxlGrey};` : "inherit;"}
`;

export const SelectedItem = styled.span`
  display: inline-block;
  width: 95%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

import { Text } from "react-app/src/components/Text";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { SizeHeadingSection } from "./ProductSizeSelector.styled";
import { Icon } from "react-app/src/components/Icon/Icon";
import { useSizeRecommendation } from "./useSizeRecommendation";
import type { TranslationKey } from "react-app/src/translations";
import type { SizeFitness } from "src/generated/graphql-code-generator";
import { motion } from "framer-motion";

const TRANSITION = { duration: 0.2, ease: [0.65, 0.05, 0.36, 1] };

const sizeFitnessTranslationKeyMap: Record<SizeFitness, TranslationKey> = {
  SMALLER_FIT: "product.details.customer.size.rating.small",
  BIGGER_FIT: "product.details.customer.size.rating.big",
  TRUE_TO_SIZE: "product.details.customer.size.rating.true",
};

export const SizeRecommendationComponent = ({
  productId = "",
}: {
  productId?: string;
}) => {
  const { t } = useTranslations();
  const sizeRecommendation = useSizeRecommendation(productId);
  if (sizeRecommendation === undefined) return null;

  const translationString =
    sizeFitnessTranslationKeyMap[sizeRecommendation.sizeFitness];

  return (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      transition={TRANSITION}
    >
      <SizeHeadingSection>
        <Icon name="Star" size={12} />
        <Text as="span" fontFamily="medium" size="small">
          {t({
            key: translationString,
            messageArguments: [sizeRecommendation.percentage.toString()],
          })}
        </Text>
      </SizeHeadingSection>
    </motion.div>
  );
};

import { fetchCategoryData } from "@/utils/apis/category-data";
import { CACHE_TTL_15_MIN, STALE_TTL_3_MIN } from "@/utils/apis/constants";
import Keyv from "@keyvhq/core";
import memoize from "@keyvhq/memoize";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import type {
  BaseCampaignData,
  BrandData,
  CategoryLevelOneLink,
  CategoryLevelTwoLink,
  Image,
  MegaMenuContentData,
} from "@xxl/content-api";
import type {
  BaseBrandData,
  BaseCampaignData as CampaignData,
  ImageData,
  MegaMenuContentData as MegaMenuContent,
  MegaMenuLevelOneLink,
  MegaMenuLevelTwoLink,
} from "@xxl/frontend-api";
import { isDateWithinRange } from "../date-helper";
import { getEnvVar, getSiteUid } from "../environment-variables";
import { Apis } from "@/utils/api-helper";

const mapImage = (image: Image | undefined): ImageData | undefined =>
  image?.url !== undefined
    ? {
        ...{ ...image, url: undefined },
        baseUrl: image.url,
      }
    : undefined;

const mapCampaign = (
  content: Image | undefined,
  campaigns: BaseCampaignData[] | undefined
): CampaignData | undefined => {
  const campaign =
    content === undefined && isNotNullOrUndefined(campaigns)
      ? campaigns.find(isDateWithinRange)
      : undefined;
  return isNotNullOrUndefined(campaign)
    ? {
        ...{ ...campaign, fromDate: undefined, toDate: undefined },
        bannerImage: mapImage(campaign.bannerImage),
        ...(isNotNullOrUndefined(campaign.slug)
          ? {
              url: `${getEnvVar(
                "REQUEST_MAPPING_CAMPAIGNHUBPAGE"
              )}/${encodeURIComponent(campaign.slug)}`,
            }
          : undefined),
      }
    : undefined;
};

const mapLink = async (
  link: CategoryLevelTwoLink
): Promise<MegaMenuLevelTwoLink> => ({
  displayName: link.displayName,
  category:
    link.categoryCode !== undefined
      ? await fetchCategoryData(link.categoryCode)
      : undefined,
  content: mapImage(link.content),
});

const mapBrandLinks = (brandLinks: BrandData[]): BaseBrandData[] => {
  return brandLinks.map(({ brandCode, brandName }) => ({
    brandCode,
    brandName,
    url: `/b/${brandCode ?? ""}/${brandName ?? ""}`,
  }));
};

const mapLinks = async (
  rawLinks: CategoryLevelOneLink[]
): Promise<MegaMenuLevelOneLink[]> => {
  const links = await Promise.all(
    rawLinks.map(
      async (link): Promise<MegaMenuLevelOneLink> => ({
        ...(await mapLink(link)),
        links: await Promise.all(link.links?.map(mapLink) ?? []),
        campaign: mapCampaign(link.content, link.campaigns),
        brandLinks: mapBrandLinks(link.brandLinks ?? []),
        buttonText: link.buttonText,
        contentText: link.contentText,
        link: link.link,
      })
    )
  );
  return links;
};

const mapToContent = async (
  menuContent: MegaMenuContentData
): Promise<MegaMenuContent> => ({
  campaignHubLink: menuContent.campaignHubLink,
  links: await mapLinks(menuContent.categoryLinks ?? []),
  content: mapImage(menuContent.content),
});

/**
 * Server-side only fetching of mega menu content
 */
const fetchMegaMenu = memoize(
  async () => {
    const {
      data: { result: [menuContent] = [] },
    } = await Apis.getInstance().contentApi.getMegaMenu(getSiteUid());
    if (!menuContent) {
      return;
    }
    return await mapToContent(menuContent);
  },
  new Keyv({ namespace: "mega-menu" }),
  {
    ttl: CACHE_TTL_15_MIN,
    staleTtl: STALE_TTL_3_MIN,
  }
);

export { fetchMegaMenu };
export type { MegaMenuContent };

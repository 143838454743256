import { ContentApi } from "@xxl/content-api";
import type { DisclaimerInformation as DisclaimerInformationData } from "@xxl/content-api";
import { isDateWithinRange } from "../date-helper";
import {
  getContentApiConfiguration,
  getSiteUid,
} from "../environment-variables";

const contentApi = new ContentApi(getContentApiConfiguration());

export type InfoMessage = {
  linkText: string;
  linkType: string | null;
  message: string;
  url: string;
  type: string;
  disclaimerInformation?: DisclaimerInformationData;
};

const getInfoMessage = async (): Promise<InfoMessage | null> => {
  const { data } = await contentApi.getInfoMessages(getSiteUid());
  const { result = [] } = data;
  const firstInfoMessageWithinDateRange = result.find(isDateWithinRange) ?? {};
  const { linkText, linkType, message, url, type, disclaimerInformation } =
    firstInfoMessageWithinDateRange;

  if (
    linkText === undefined ||
    message === undefined ||
    url === undefined ||
    type === undefined
  ) {
    return null;
  }

  return {
    linkText,
    linkType: linkType ?? null,
    message,
    url,
    type,
    disclaimerInformation,
  };
};

export { getInfoMessage };

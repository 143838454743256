import { hasValue, isNotEmpty } from "@xxl/common-utils";
import { log } from "@xxl/logging-utils";
import { default as axios } from "axios";
import type { XXLCookie } from "../global";

const getCookies = (): string | undefined =>
  (typeof window === "undefined" ? null : window)?.document.cookie;

const getCookieValue = (name: string) =>
  getCookies()
    ?.split("; ")
    .find((line) => line.startsWith(name))
    ?.substring(name.length);

const isLoggedIn = () => {
  const xxlCookie = getCookieValue("xxl=");
  const authExpires = getCookieValue("xxl_auth_expires=");

  try {
    const { loggedIn } = isNotEmpty(xxlCookie)
      ? (JSON.parse(JSON.parse(xxlCookie) as string) as XXLCookie)
      : ({} as Partial<XXLCookie>);
    return {
      loggedIn: loggedIn === true,
      authExpires: isNotEmpty(authExpires) ? Number(authExpires) : undefined,
    };
  } catch {
    return { loggedIn: false };
  }
};

/**
 * Get a new auth token if expired
 * @returns true if user is logged in false if not
 */
export const refreshAuthCookie = async (): Promise<boolean> => {
  try {
    const { loggedIn, authExpires } = isLoggedIn();
    if (loggedIn === false) {
      return false;
    }
    if (hasValue(authExpires) && authExpires > Date.now()) {
      return true;
    }
    const { status } = await axios.request({
      method: "GET",
      url: "/api/user/token/refresh",
      validateStatus: () => true,
    });
    if (200 === status) {
      return true;
    } else if (204 === status) {
      return false;
    } else if (401 === status) {
      // access and refresh tokens are expired, make sure cartId cookie is deleted
      await axios.request({
        method: "GET",
        url: "/logout",
        validateStatus: () => true,
      });
      return false;
    } else {
      const errMsg = `Failed to refreshAuthCookie, got status: ${status}`;
      log.error(errMsg);
      throw new Error(errMsg);
    }
  } catch (err) {
    log.error("Failed to refreshAuthCookie", err);
    throw err;
  }
};

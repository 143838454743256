export const resolutionSizes = {
  smallMobile: 499,
  mobile: 767,
  tablet: 1024,
  laptop: 1080,
  smallDesktop: 1280,
  desktop: 1395,
  bigDesktop: 1440,
} as const;

export const resolutionSizes20 = {
  smallMobile: 400,
  tablet: 768,
  laptop: 1024,
  desktop: 1280,
  bigDesktop: 1440,
} as const;

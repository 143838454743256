import type { PaymentReference, PaymentsApi } from "@xxl/payments-api";
import { PaymentReferencePaymentStatusEnum } from "@xxl/payments-api";
import stringFormat from "string-format";
import type { Translate } from "../../contexts/Translations/TranslationsContext";
import { CART_BASE_PATH } from "../../constants";
import type { OrderAndPaymentData } from "../../global";
import { PaymentProvider } from "../../generated/graphql-code-generator";
import { getCheckoutConfirmationSnippet } from "../Cart/Api/CartAPI";
import { log } from "@xxl/logging-utils";

const API_TIMEOUT = 60000;
const API_INTERVAL = 500;
const CART_PAYMENT_STATUS = {
  parameter: "paymentStatus",
  value: {
    cancelled: "cancelled",
    error: "error",
  },
};
const { CANCELLED, COMPLETED, FAILED, STARTED } =
  PaymentReferencePaymentStatusEnum;

const ErrorCodes = {
  [CANCELLED]: 1,
  [FAILED]: 2,
  FAILED_GET_ORDER_DATA: 3,
  TIMEOUT: 4,
  UNKNOWN: 0,
};

type PollForSuccessfulPaymentStatusProps = {
  api: PaymentsApi;
  interval?: number;
  paymentId: string;
  timeout?: number;
};

const pollForSuccessfulPaymentStatus = async ({
  api,
  interval = API_INTERVAL,
  paymentId,
  timeout = API_TIMEOUT,
}: PollForSuccessfulPaymentStatusProps): Promise<PaymentReference> =>
  new Promise(
    (
      resolve,
      reject: (
        errorCode: PaymentReferencePaymentStatusEnum | keyof typeof ErrorCodes
      ) => void
    ) => {
      void (async function fetchData() {
        try {
          const { data } = await api.getPaymentDetails(undefined, paymentId);
          const { paymentStatus } = data;
          switch (paymentStatus) {
            case STARTED:
              setTimeout(() => void fetchData(), interval);
              break;
            case COMPLETED:
              resolve(data);
              break;
            default:
              reject(paymentStatus);
          }
        } catch (error) {
          log.error("Failed fetching payment status", error);
          reject("UNKNOWN");
        }
      })();
      // Create timeout that will reject promise if something goes wrong
      setTimeout(() => reject("TIMEOUT"), timeout);
    }
  );

const getOrderData = async (
  paymentData: PaymentReference,
  graphQLEndpointUrl: string,
  graphQLApiKey: string
): Promise<OrderAndPaymentData> => {
  try {
    const response = await getCheckoutConfirmationSnippet(
      graphQLEndpointUrl,
      graphQLApiKey,
      PaymentProvider.KLARNA,
      paymentData.cartGuid
    );
    return {
      orderData: response.data.data?.confirmationSnippet,
      paymentData,
    };
  } catch (error) {
    log.error("Cannot get checkout order data", error);
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw "FAILED_GET_ORDER_DATA";
  }
};
const isErrorCode = (value: unknown): value is keyof typeof ErrorCodes =>
  typeof value === "string" && value in ErrorCodes;

const getErrorRedirectUrl = (
  inputError: keyof typeof ErrorCodes,
  t: Translate
) => {
  switch (inputError) {
    case CANCELLED:
      return `${CART_BASE_PATH}?enableCheckoutButton=true&${CART_PAYMENT_STATUS.parameter}=${CART_PAYMENT_STATUS.value.cancelled}`;
    default: {
      const error: unknown = inputError; // don't trust that we get a proper error
      const errorCode = isErrorCode(error)
        ? ErrorCodes[error]
        : ErrorCodes.UNKNOWN;
      const errorMessage = stringFormat(
        t("checkout.processing.payment.error"),
        String(errorCode)
      );
      return `${CART_BASE_PATH}?errorMessages=${encodeURIComponent(
        errorMessage
      )}&${CART_PAYMENT_STATUS.parameter}=${CART_PAYMENT_STATUS.value.error}`;
    }
  }
};

const handleError = (
  inputError: keyof typeof ErrorCodes,
  t: Translate
): void => {
  log.error(inputError);
  const url = getErrorRedirectUrl(inputError, t);
  window.location.assign(url);
};

export type { OrderAndPaymentData };
export {
  CART_PAYMENT_STATUS,
  handleError,
  pollForSuccessfulPaymentStatus,
  getOrderData,
};

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import isEmpty from "lodash/isEmpty";
import { loadScript } from "../xxl-load-script";
import { parseNumber } from "../xxl-number";
import { log } from "@xxl/logging-utils";

/**
 * Docs
 * V1 https://docs.giosg.com/getting_started/how_giosg_script_works/
 * V2 https://docs.giosg.com/getting_started/how_giosg_script_works_v2/
 */

export const BASE_SRC = "https://service.giosg.com";
export const VERSION_ONE = 1;
export const VERSION_TWO = 2;
export type AVAILABLE_VERSIONS = typeof VERSION_ONE | typeof VERSION_TWO;

const getGiosgVersion = (giosgId: string): AVAILABLE_VERSIONS => {
  // Giosg id is a number for v1 and a GUID for v2
  const onlyDigits = new RegExp(/^\d+$/);
  return onlyDigits.test(giosgId) ? VERSION_ONE : VERSION_TWO;
};

const getScriptSrc = ({
  giosgId,
  version,
}: {
  giosgId: string;
  version: AVAILABLE_VERSIONS;
}): string =>
  ({
    1: `${BASE_SRC}/live/`,
    2: `${BASE_SRC}/live2/${giosgId}`,
  })[version];

const init = (): void => {
  const { giosgEnabled = false, giosgId } = window._sharedData.giosg;
  const isKindlyChatbotVisible = window.KindlyChatbot.isKindlyChatbotVisible;

  if (!giosgEnabled || window._sharedData.skipTrackConfirmation) {
    return;
  }
  if (isKindlyChatbotVisible) {
    log.debug("KindlyChatBot is enabled in this page instead of Giosg");
    return;
  }
  if (isEmpty(giosgId)) {
    log.debug(
      "Giosg settings are not valid, please check: ",
      window._sharedData.giosg
    );
    return;
  }
  const version = getGiosgVersion(giosgId);
  const src = getScriptSrc({ giosgId, version });
  const giosgIdAsStringOrNumber =
    version === VERSION_ONE ? parseNumber(giosgId) : giosgId;
  window._giosg =
    window._giosg ||
    ((...args: unknown[]): void =>
      (window._giosg._e = window._giosg._e || []).push(args));
  (window._giosg._c = giosgIdAsStringOrNumber), (window._giosg._h = BASE_SRC);
  loadScript({ src });
};

export { init, getScriptSrc, getGiosgVersion };

import { hasValue } from "@xxl/common-utils";
import { log } from "@xxl/logging-utils";
import { RecommendationPayloadBuilder } from "@xxl/product-search-api";
import { useApiClients } from "../../contexts/ApiClients";
import { productKeysToLandingPageQuery } from "../../utils/ElevateApi/elevate-api.util";
import type {
  AdditionalSales,
  ProductCardDataV2,
} from "../../utils/ProductData/product-card-data-helper";
import {
  getAdditionalSalesProductIds,
  productCardDataToAdditionalSalesProducts,
  toProductCardDataFromBase,
} from "../../utils/ProductData/product-card-data-helper";
import { useElevateRequestData } from "../useElevateRequestData/useElevateRequestData";

const useProductRecommendations = () => {
  const { elevateApi } = useApiClients();
  const { getBaseQuery } = useElevateRequestData();

  const getAdditionalSalesProducts = async (
    additionalSales?: AdditionalSales
  ): Promise<{
    accessoryProducts: ProductCardDataV2[];
    crossSalesProducts: ProductCardDataV2[];
    serviceProducts: ProductCardDataV2[];
  }> => {
    const fallback = {
      accessoryProducts: [],
      crossSalesProducts: [],
      serviceProducts: [],
    };

    if (!hasValue(additionalSales)) {
      return fallback;
    }

    const productKeys = hasValue(additionalSales)
      ? getAdditionalSalesProductIds(additionalSales)
      : [];

    if (productKeys.length === 0) {
      log.error("No products keys found.", additionalSales);
      return fallback;
    }

    const q = productKeysToLandingPageQuery(productKeys);

    if (q === null) {
      log.error("Query could not be created.", q);
      return fallback;
    }

    const response = await elevateApi.storefront.landingPagePOST(
      {
        ...getBaseQuery(),
        q,
        pageReference: `/p/getAdditionalSalesProducts/${q}`,
      },
      {}
    );

    const products = response.data.primaryList.baseProducts.map(
      toProductCardDataFromBase
    );

    return productCardDataToAdditionalSalesProducts(products, additionalSales);
  };

  const getAddToCartRecs = async (ean: string) => {
    const builder = new RecommendationPayloadBuilder();
    const recommendationLists = builder
      .addAddToCartRecs({
        id: "addToCartRecs1",
        limit: 4,
      })
      .build();
    const response = await elevateApi.storefront.addToCartPopup(
      {
        ...getBaseQuery(),
        variantKey: ean,
      },
      {
        recommendationLists,
      }
    );

    return response.data.recommendationLists[0];
  };

  return {
    getAddToCartRecs,
    getAdditionalSalesProducts,
  };
};

export { useProductRecommendations };

import type { ProductData } from "@xxl/recommendations-api";
import isEmpty from "lodash/isEmpty";
import type { Dispatch } from "react";
import type {
  GenericGraphQLError,
  OutOfStockInCartError,
} from "react-app/src/components/Cart/Api/types";
import type { CrossSalesProductWithFixedProductType } from "react-app/src/components/CrossSales";
import {
  type CartQuery,
  CustomerType,
} from "../../generated/graphql-code-generator";
import { getCartDetailsCookie } from "../../utils/Cookie";
import type { GetProductsProps } from "../CrossSales/API/CrossSalesAPI";
import { getCrossSalesProducts as getProducts } from "../CrossSales/API/CrossSalesAPI";
import {
  SERVICES_ADD_ON_SALES_PERSONALIZED_KEY,
  getServices,
} from "../Services/API/ServicesAPI";
import type { Action } from "./CartState";
import { log } from "@xxl/logging-utils";

const filterOutNonBuyableProducts = (products: ProductData[]) =>
  products.filter(({ isPossibleToBuy }) => isPossibleToBuy !== false);

const setMiniCartCountClientSide = (dispatch: Dispatch<Action>) => {
  const cookieValue = getCartDetailsCookie() ?? {};
  const { totalItems = 0 } = cookieValue;
  dispatch({
    payload: {
      count: totalItems,
    },
    type: "SET_CART_COUNT",
  });
};

const getCrossSalesProducts = async (crossSalesData: GetProductsProps) => {
  if (crossSalesData.product === "") {
    return null;
  }

  try {
    const apiResponse = await getProducts(crossSalesData);
    const {
      data: { response },
    } = apiResponse;

    if (isEmpty(response)) {
      return null;
    }

    const { productList = [] } = response[0];
    const buyableProducts = filterOutNonBuyableProducts(productList);
    return isEmpty(buyableProducts)
      ? null
      : (buyableProducts as CrossSalesProductWithFixedProductType[]);
  } catch (err) {
    log.error("Cannot get Cross Sales suggestions", err);
    return null;
  }
};

const getServiceProducts = async ({
  basePath,
  siteUid,
  product,
  category,
  price,
}: GetProductsProps) => {
  try {
    const {
      data: { response },
    } = await getServices({
      basePath,
      siteUid,
    })(product, category, price);

    const servicesAndAddOnSalesData = response.find(
      (item) => item.key === SERVICES_ADD_ON_SALES_PERSONALIZED_KEY
    );

    if (
      servicesAndAddOnSalesData === undefined ||
      servicesAndAddOnSalesData.numFound === 0
    ) {
      return null;
    }
    return servicesAndAddOnSalesData;
  } catch (err) {
    log.error("Cannot get services", err);
    return null;
  }
};

function getCartBuyableForCnC4GoodsOnlyInStore(cartData?: CartQuery): boolean {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    cartData?.cart?.collectStore?.error?.some(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (error) => error?.type === "NOT_BUYABLE"
    ) ?? false
  );
}

const isOutOfStockError = (
  error: GenericGraphQLError
): error is OutOfStockInCartError => error.errorType === "OUT_OF_STOCK";

const hasOutOfStockError = (errors: GenericGraphQLError[] = []) =>
  errors.some(isOutOfStockError);

const getDefaultCustomerType = (isTeamsalesAdmin?: boolean): CustomerType =>
  isTeamsalesAdmin === true ? CustomerType.ORGANIZATION : CustomerType.PERSON;

export {
  getCartBuyableForCnC4GoodsOnlyInStore,
  getCrossSalesProducts,
  getDefaultCustomerType,
  getServiceProducts,
  hasOutOfStockError,
  isOutOfStockError,
  setMiniCartCountClientSide,
};

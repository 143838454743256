import styled from "@emotion/styled/macro";
import type { SnackbarProps } from "@mui/material";
import { Snackbar } from "@mui/material";
import * as React from "react";
import type { ReactComponentElement } from "react";
import type { ISnackBarMessage } from ".";
import { SnackBarMessage } from "./SnackBarMessage";

export interface SnackBarProps {
  messages?: ISnackBarMessage[];
  height?: number;
  autoHideDuration?: SnackbarProps["autoHideDuration"];
  onEntered?: (node: HTMLElement, isAppearing: boolean) => void;
}

/**
 * Single SnackBar, extending from Material-UI `Snackbar`.
 * Displays one or more `SnackBarMessages` on mobile devices as a temporary, dismissable notification.
 * Do not attempt to instantiate this directly, instead use the event driven `SnackBarWrapper`.
 *
 * @param {SnackBarProps} SnackBarProps
 * @param SnackBarProps.messages = [],
 * @param SnackBarProps.height = 50,
 * @param SnackBarProps.autoHideDuration = 5000,
 * @param SnackBarProps.onEntered = () => {}
 * }
 * @returns `React.FunctionComponent<SnackBarProps>`
 */
const SnackBar = ({
  messages = [],
  height = 50,
  onEntered = (): void => {
    return;
  },
}: SnackBarProps): ReactComponentElement<any> => {
  const [open, setOpen] = React.useState(true);

  const handleClose = (): void => setOpen(false);
  const handleClick = (url?: string): void => {
    handleClose();
    if (url) {
      window.location.href = url;
    }
  };

  const StyledSnackbar = styled(Snackbar)`
    &&& {
      top: 0;
      right: 0;
      left: 0;
      transform: initial;
    }
  `;

  return (
    <StyledSnackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={open}
      onClose={handleClose}
      TransitionProps={{
        onEntered,
      }}
      action={
        <>
          {messages.map((msg, index) => (
            <SnackBarMessage
              message={msg}
              key={index}
              height={height}
              handleClick={handleClick}
            />
          ))}
        </>
      }
    />
  );
};

export { SnackBar };

import { createContext, useContext } from "react";
import type { TranslationKey } from "../../translations";
import type {
  NextJsTranslations,
  TranslateWithPluralization,
  TranslationOptions,
} from "../../utils/xxl-translate";
import {
  translateLegacy,
  translateWithPluralization,
} from "../../utils/xxl-translate";

export type Translate = (
  keyOrOptions: TranslationKey | TranslationOptions,
  nextJsTranslations?: NextJsTranslations
) => string;

export type TranslationsContext = {
  t: Translate;
  twp: TranslateWithPluralization;
};

export const TranslationsContext = createContext<TranslationsContext>({
  t: translateLegacy,
  twp: translateWithPluralization,
});

export const useTranslations = (): TranslationsContext =>
  useContext(TranslationsContext);

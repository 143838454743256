import { callGraphQL } from "../../../graphql/graphqlApi";
import type { AddBundleToCartResponse, AddToCartResponse } from "./types";
import type {
  AddBundledProductsToCartItemInput,
  AddBundleProductsToCartMutation,
  AddConfigurationsToCartItemInput,
  AddCollectableProductsToCartMutation,
  AddBundleProductsToCartItemInput,
} from "../../../generated/graphql-code-generator";
import {
  addBundleProductsToCartMutation,
  addCollectableProductsToCartMutation,
} from "./graphqlQueries";

const addBundleToCart = async (
  item: AddBundleProductsToCartItemInput,
  graphQLEndpointUrl: string,
  graphQLApiKey: string
): Promise<AddBundleToCartResponse> => {
  const queryResponse = await callGraphQL<AddBundleProductsToCartMutation>(
    {
      query: addBundleProductsToCartMutation,
      variables: {
        input: {
          cart: {
            items: [item],
          },
        },
      },
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

  return {
    status:
      queryResponse.data?.addBundleProductsToCart !== undefined
        ? "SUCCESS"
        : "ERROR",
    data: queryResponse,
  };
};

const addBundleWithEanToCart = async (
  ean: string,
  bundledProducts: AddBundledProductsToCartItemInput[],
  configurations: AddConfigurationsToCartItemInput[],
  graphQLEndpointUrl: string,
  graphQLApiKey: string,
  unit?: string
): Promise<AddBundleToCartResponse> =>
  addBundleToCart(
    {
      bundledProducts,
      configurations,
      ean,
      unit,
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

const addBundleForProductConfiguratorToCart = async (
  templateId: string,
  bundledProducts: AddBundledProductsToCartItemInput[],
  configurations: AddConfigurationsToCartItemInput[],
  graphQLEndpointUrl: string,
  graphQLApiKey: string
): Promise<AddBundleToCartResponse> =>
  addBundleToCart(
    {
      bundledProducts,
      configurations,
      templateId,
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

export const addCollectableProductsToCart = async (
  ean: string,
  quantity: string,
  storeId: string,
  graphQLEndpointUrl: string,
  graphQLApiKey: string
): Promise<AddToCartResponse> => {
  const queryResponse = await callGraphQL<AddCollectableProductsToCartMutation>(
    {
      query: addCollectableProductsToCartMutation,
      variables: {
        input: {
          cart: {
            items: [
              {
                ean,
                quantity: {
                  quantity: parseFloat(quantity),
                },
              },
            ],
            collectStore: {
              store: { id: storeId },
            },
          },
        },
      },
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

  return {
    status:
      queryResponse.data?.addCollectableProductsToCart !== undefined
        ? "SUCCESS"
        : "ERROR",
    data: queryResponse,
  };
};

export { addBundleWithEanToCart, addBundleForProductConfiguratorToCart };

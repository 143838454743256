import React from "react";
import { Message } from "../Message/Message";
import { colorsAsCssVariable } from "react-app/src/styles/theme/colors";
import { Text } from "./AddToCartWarning.styled";
import { Icon } from "react-app/src/components/Icon/Icon";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { useAddToCartError } from "../../../hooks/useAddToCartError";
import { withErrorBoundary } from "react-app/src/utils/WithErrorBoundary/with-error-boundary";

const _AddToCartWarning = () => {
  const { t } = useTranslations();
  const { addToCartError } = useAddToCartError();

  if (addToCartError === null) {
    return null;
  }

  return (
    <Message backgroundColor={colorsAsCssVariable.xxlWarning}>
      <Icon name="Info" />
      <Text data-testid="product-add-to-cart-error">{t(addToCartError)}</Text>
    </Message>
  );
};

export const AddToCartWarning = withErrorBoundary(_AddToCartWarning);

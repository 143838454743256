import { type InitiateOTPData } from "../../../UserDetails/UserAPI";
import type { Translate } from "../../../../contexts/Translations/TranslationsContext";
import * as yup from "yup";

export type OTPData = InitiateOTPData & {
  phoneNumber: string;
};

const otpInitializeSchema = (t: Translate) => {
  const requiredMessage = t("form.field.required");

  return yup.object({
    phoneNumber: yup.string().required(requiredMessage),
  });
};

const oneTimePasswordField = (t: Translate) =>
  ({
    fieldName: "oneTimePassword",
    id: "one-time-password",
    label: t("login.form.otp.verification.code"),
    inputMode: "text",
    placeholder: t("login.form.otp.verification.code"),
  }) as const;

const otpResponseSchema = (t: Translate) => {
  const requiredMessage = t("form.field.required");

  return yup.object({
    oneTimePassword: yup.string().required(requiredMessage),
  });
};

export { otpInitializeSchema, oneTimePasswordField, otpResponseSchema };

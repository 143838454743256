import { Cart, Plus } from "@xxl/icons";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import spaces from "../../styles/theme/spaces";
import { Spinner } from "../Common";
import { CrossSalesAddToCartStyledButton } from "./CrossSales.styled";

type CrossSalesAddToCartButtonProps = {
  isDisabled: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  onClick: () => void;
  onSuccessAnimationEnd: () => void;
};

export const CrossSalesAddToCartButton: React.FC<
  CrossSalesAddToCartButtonProps
> = ({
  isDisabled = false,
  isLoading = false,
  isSuccess = false,
  onClick,
  onSuccessAnimationEnd,
}) => {
  const { t } = useTranslations();
  return (
    <CrossSalesAddToCartStyledButton
      className={`button button--accent button--small-action ${
        isDisabled ? " button--disabled" : ""
      }${isSuccess ? " site__button-checkmark active" : ""}`}
      onClick={onClick}
      onAnimationEnd={onSuccessAnimationEnd}
      title={t("product.details.add.to.cart.label")}
    >
      {isLoading ? (
        <Spinner className="button__icon--small" />
      ) : isSuccess ? (
        <span className="checkmark draw" />
      ) : (
        <>
          <Plus style={{ fontSize: spaces.smallRegular }} />
          <Cart style={{ fontSize: spaces.regular }} />
        </>
      )}
    </CrossSalesAddToCartStyledButton>
  );
};

import type { FunctionComponent, PropsWithChildren } from "react";
import React, { useMemo } from "react";
import type { Translate } from "../../../contexts/Translations/TranslationsContext";
import { TranslationsContext } from "../../../contexts/Translations/TranslationsContext";
import type { NextJsTranslations } from "../../../utils/xxl-translate";
import {
  translate,
  translateWithPluralization,
} from "../../../utils/xxl-translate";

type TranslationsProviderProps = {
  nextJsTranslations?: NextJsTranslations;
};

const TranslationsProvider: FunctionComponent<
  PropsWithChildren<TranslationsProviderProps>
> = ({ nextJsTranslations, children }) => {
  const composeNextJsTranslate = useMemo(
    () =>
      (nextJSTranslations: NextJsTranslations): Translate =>
      (keyOrOption) => {
        return translate(keyOrOption, nextJSTranslations);
      },
    []
  );

  const translations = useMemo(
    () => ({
      t:
        nextJsTranslations !== undefined
          ? composeNextJsTranslate(nextJsTranslations)
          : translate,
      twp: translateWithPluralization,
    }),
    [composeNextJsTranslate, nextJsTranslations]
  );

  return (
    <TranslationsContext.Provider value={translations}>
      {children}
    </TranslationsContext.Provider>
  );
};

export { TranslationsProvider };

import { useEffect, useState } from "react";
import { useSharedData } from "../../contexts/SharedData";
import { PaymentProvider } from "../../generated/graphql-code-generator";
import { DEFAULT_PAYMENT_PROVIDER } from "../../components/Cart/constants";
import { PaymentType } from "../../components/Cart/Api/CartAPI";
import { isNotNullOrUndefined } from "@xxl/common-utils";

type PaymentTypeKeys = keyof typeof PaymentType;
type PaymentTypeData = (typeof PaymentType)[PaymentTypeKeys];

const setProvider = (isWalley: boolean): PaymentProvider =>
  isWalley ? PaymentProvider.WALLEY : PaymentProvider.KLARNA;

export const usePaymentProvider = (
  paymentType: PaymentTypeData
): PaymentProvider => {
  const [defaultPaymentProvider, setDefaultPaymentProvider] =
    useState<PaymentProvider>(DEFAULT_PAYMENT_PROVIDER);
  const {
    featureToggles: {
      checkout_psp_walley,
      giftcards_psp_walley,
      return_replacement_psp_walley,
    },
  } = useSharedData().data;

  useEffect(() => {
    if (
      paymentType === PaymentType.CHECKOUT &&
      isNotNullOrUndefined(checkout_psp_walley)
    ) {
      const provider = setProvider(checkout_psp_walley);
      setDefaultPaymentProvider(provider);
    }
    if (
      paymentType === PaymentType.GIFTCARDS &&
      isNotNullOrUndefined(giftcards_psp_walley)
    ) {
      const provider = setProvider(giftcards_psp_walley);
      setDefaultPaymentProvider(provider);
    }
    if (
      paymentType === PaymentType.REPLACE &&
      isNotNullOrUndefined(return_replacement_psp_walley)
    ) {
      const provider = setProvider(return_replacement_psp_walley);
      setDefaultPaymentProvider(provider);
    }
  }, [
    checkout_psp_walley,
    giftcards_psp_walley,
    paymentType,
    return_replacement_psp_walley,
  ]);

  return defaultPaymentProvider;
};

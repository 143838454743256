import styled from "@emotion/styled/macro";
import { miniCartButtonCommonStyles } from "../Cart/Styles/MiniCartContent.styled";
import { focusVisibleStyles } from "../Cart/Styles/ProductItem.styled";
import { MQ } from "../../styles/helpers";

export const MiniFavoritesLink = styled.a`
  ${miniCartButtonCommonStyles};
  background-color: transparent;
  ${focusVisibleStyles};

  & > span {
    ${MQ("tablet")} {
      right: -8px;
    }
  }
`;

import type {
  EcomSiteUid,
  EcomSiteUidLegacy,
  RequestMapping,
  EcomSiteHost,
  EventstreamDataProduct,
} from "../global";
import { windowAccess } from "./Window";

const EcomSiteUidToHost: Record<EcomSiteUid, EcomSiteHost> = {
  "xxl-no": "xxl.no",
  "xxl-se": "xxl.se",
  "xxl-fi": "xxl.fi",
};

export const getSiteCountry = (): string =>
  windowAccess()._sharedData.siteCountry;
export const getSiteCurrency = (): string =>
  windowAccess()._sharedData.siteCurrency;
export const convertSiteUidToHost = (id: EcomSiteUid): EcomSiteHost => {
  return EcomSiteUidToHost[id];
};
export const getSiteHost = (siteUid: EcomSiteUid): EcomSiteHost =>
  convertSiteUidToHost(siteUid);
export const legacySiteUidToSiteUid = (id: EcomSiteUidLegacy): EcomSiteUid =>
  id === "xxl" ? "xxl-no" : id;

export const getSiteDefaultLanguage = (): string =>
  windowAccess()._sharedData.siteDefaultLanguage;
export const getBaseProductCodeWithoutSuffix = (
  baseProductCode: string
): string => baseProductCode.split("_").shift() ?? baseProductCode;

export const getSignUpImageUrl = () =>
  windowAccess()._sharedData.signUpImageUrl;
export const getLoginImageUrl = () => getSignUpImageUrl();

export const getRequestMapping = (): RequestMapping =>
  windowAccess()._sharedData.requestMapping;

export const getProductData = (): EventstreamDataProduct | undefined =>
  windowAccess()._sharedData.products[0];

/**
 * Used to retrieve a product code on PDP.
 */
export const getProductCode = () => getProductData()?.id;

export const getNumberOfProductsPerPage = (): number =>
  windowAccess()._sharedData.numberOfProductsPerPage;

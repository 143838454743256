import React from "react";
import type { FunctionComponent, ReactNode, MouseEvent } from "react";
import {
  ArrowRight,
  SubCategory as StyledSubCategory,
} from "./MegaMenu.styled";
import { ICON_SIZE_PX } from "./constants";
import { isNotNullOrUndefined } from "@xxl/common-utils";

const SubCategoryListItem: FunctionComponent<{
  displayName: string;
  href?: string;
  icon: ReactNode | null;
  id: string;
  isActive: boolean;
  onClick: (event: MouseEvent) => void;
}> = ({ displayName, href, icon, id, isActive, onClick }) => {
  const LinkOrButton = isNotNullOrUndefined(href)
    ? StyledSubCategory.withComponent("a")
    : StyledSubCategory;
  return (
    <li>
      <LinkOrButton
        aria-haspopup="true"
        aria-expanded={isActive}
        role={isNotNullOrUndefined(href) ? "link" : "button"}
        href={href}
        id={id}
        isActive={isActive}
        onClick={onClick}
      >
        {icon}
        {displayName}
        <ArrowRight fontSize={ICON_SIZE_PX} />
      </LinkOrButton>
    </li>
  );
};

export { SubCategoryListItem };

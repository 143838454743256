import React from "react";
// used to add ip:s that try and scrape our site to a blacklist

const HoneyPot: React.FunctionComponent = () => {
  return (
    <a
      href="/awesomeproduct"
      rel="nofollow"
      style={{ display: "none" }}
      aria-hidden="true"
    >
      The path to awesomeness
    </a>
  );
};

export default HoneyPot;

import type { Translate } from "../../contexts/Translations/TranslationsContext";
import * as yup from "yup";
import type { EcomSiteUidLegacy } from "../../global";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import stringFormat from "string-format";
import { StateManager } from "cotton-box";
import type { GiftCardEntry } from "../../generated/graphql-code-generator";

export type BuyGiftCardFormProps = {
  title?: string;
  description?: string;
  predefinedAmounts?: number[];
};

export type BuyGiftCardFormData = {
  amount?: number;
  custom_amount?: string;
  quantity: number;
};

type Interval = {
  min: number;
  max: number;
};

export type BuyGiftCardLimits = {
  amount: Interval;
  quantity: Interval;
};

export type FormKey = keyof BuyGiftCardFormData;

export const AMOUNT_FIELD: FormKey = "amount";
export const OTHER_AMOUNT_FIELD: FormKey = "custom_amount";
export const QUANTITY_FIELD: FormKey = "quantity";
export const QUANTITY_MIN = 1;
export const QUANTITY_MAX = 99;

export const giftCardEntries = new StateManager<GiftCardEntry[]>([]);

const validateMinMax = ({ min, max }: Interval, t: Translate) =>
  yup
    .string()
    .test(
      "test-min",
      stringFormat(t("giftcard.buy.min.amount.error"), min.toString()),
      (value) => {
        const parsedValue = value !== undefined ? parseInt(value) : 0;
        const isNumber = !isNaN(parsedValue);
        return (isNumber && parsedValue >= min) || value === undefined;
      }
    )
    .test(
      "test-max",
      stringFormat(t("giftcard.buy.max.amount.error"), max.toString()),
      (value) => {
        const parsedValue = value !== undefined ? parseInt(value) : 0;
        const isNumber = !isNaN(parsedValue);
        return (isNumber && parsedValue <= max) || value === undefined;
      }
    );

export const BuyGiftCardFormSchema = (
  t: Translate,
  giftCardLimits: BuyGiftCardLimits
) =>
  yup.object({
    [AMOUNT_FIELD]: yup.number().when(OTHER_AMOUNT_FIELD, {
      is: undefined,
      then: yup.number().required(t("form.field.required")),
    }),
    [OTHER_AMOUNT_FIELD]: validateMinMax(giftCardLimits.amount, t),
    [QUANTITY_FIELD]: validateMinMax(giftCardLimits.quantity, t),
  });

const GetGiftCardMinValue = (siteUID: EcomSiteUidLegacy): number => {
  return siteUID === "xxl-fi" ? 5 : 50;
};

const GetGiftCardMaxValue = (): number => {
  return 99999;
};

export const GetBuyGiftCardLimits = (
  siteUID: EcomSiteUidLegacy
): BuyGiftCardLimits => ({
  amount: {
    min: GetGiftCardMinValue(siteUID),
    max: GetGiftCardMaxValue(),
  },
  quantity: {
    min: QUANTITY_MIN,
    max: QUANTITY_MAX,
  },
});

export const Validator = () => {
  const { values, validateForm } = useFormikContext();
  useEffect(() => {
    void validateForm(values);
  }, [values, validateForm]);
  return null;
};

import { caching, type MemoryCache } from "cache-manager";
import { log } from "@xxl/logging-utils";

let xxlCache: MemoryCache | null | undefined;

const getXxlCache = async (): Promise<MemoryCache | null> => {
  if (xxlCache === undefined) {
    xxlCache = await caching("memory").catch((error: unknown) => {
      log.error({
        error,
        message: "Failed to create server side cache.",
      });
      return null;
    });
  }
  return xxlCache;
};

export default getXxlCache;

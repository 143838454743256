import styled from "@emotion/styled/macro";
import Link from "next/link";
import { typographyToCss } from "react-app/src/styles/helpers";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

const StyledLink = styled(Link)`
  text-decoration: underline;
`;
const BrandLink = styled(StyledLink)`
  ${typographyToCss(xxlTheme.typography.mediumBold)};
`;
const StyledHeading = styled.h1`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${xxlTheme.spaces.miniMicro};
  ${typographyToCss(xxlTheme.typography.mediumBold)};
`;

export { BrandLink, StyledLink, StyledHeading };

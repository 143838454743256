import React from "react";
import {
  SettingsList as List,
  SettingsListItem,
  SettingsListHeading,
} from "../Styles/ProductItem.styled";

type SettingsListProps = {
  list: string[];
  title: string;
};

export const SettingsList: React.FunctionComponent<SettingsListProps> = ({
  list,
  title,
}) => {
  if (list.length <= 0) {
    return null;
  }
  return (
    <>
      <SettingsListHeading>{title}</SettingsListHeading>
      <List>
        {list.map((item, index) => (
          <SettingsListItem key={`${item}${index}`}>{item}</SettingsListItem>
        ))}
      </List>
    </>
  );
};

/**
 * JS mixin for creating an underline
 *
 * @param {string} [underlineColor] Underline color
 * @returns {string} CSSString to inject in inline styling blocks
 */
export const underlineLinear = (
  underlineColor: string,
  initialWidth = "100%"
): string =>
  `position: relative;

&::before {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  border-bottom: 3px solid ${underlineColor};
  transition: width 0.2s linear;
  content: "";
}

&::after {
  position: absolute;
  right: 0;
  bottom: 0;
  width: ${initialWidth};
  border-bottom: 3px solid ${underlineColor};
  transition: width 0.2s linear;
  content: "";
}`;

export const fadeInAnimation = (
  name: string,
  duration = "200ms",
  from = 0
): string =>
  `animation: ${name} ${duration};
    @keyframes ${name} {
      from {
        opacity: ${from};
      }
      to {
        opacity: 1;
      }
    }`;

type ShrinkAnimationProps = {
  name: string;
  duration?: string;
  initialScale?: number;
};
export const shrinkAnimation = ({
  name,
  duration = "200ms",
  initialScale = 1,
}: ShrinkAnimationProps): string =>
  `animation: ${name} ${duration};
        @keyframes ${name} {
          from {
            transform: scale(${initialScale});
          }
          25% {
            transform: scale(0.8);
          }
          to {
            transform: scale(1);
          }
        }`;

export const gradientImageOverlay = (zIndex = 0): string => `
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: ${zIndex};
      background: linear-gradient(
        270deg,
        rgba(0, 0, 0, 0) 65.93%,
        rgba(0, 0, 0, 0.32) 100%
      );
      content: "";
    }
`;

// sqrt() is only supported by safari, so we need to use approximate value of sqrt(3)
const sqrt3 = 1.73;

export const ribbonLeft = (height: string): string => `
  border-top-width: ${height};
  border-left: calc(${height} / ${sqrt3}) solid transparent;
`;

export const ribbonRight = (height: string): string => `
  border-top-width: ${height};
  border-right: calc(${height} / ${sqrt3}) solid transparent;
`;

import styled from "@emotion/styled/macro";
import spaces from "../../../styles/theme/spaces";

export const BUTTON_WIDTH = "440px";

type TextProps = {
  isFetchingMoreProducts: boolean;
};

export const Link = styled.a`
  &.button--large {
    width: 100%;
    max-width: ${BUTTON_WIDTH};
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: ${spaces.smallRegular};
  cursor: pointer;
`;

export const Text = styled.span<TextProps>`
  ${({ isFetchingMoreProducts }): string =>
    isFetchingMoreProducts ? "color: transparent !important;" : ""}
`;

import type { AssortmentLinkData } from "@xxl/frontend-api";
import { ClubApi } from "@xxl/frontend-api";
import { Configuration } from "@xxl/search-api";
import React, { useEffect, useState } from "react";
import { useSharedData } from "../../../contexts/SharedData";

const useClubAssortmentLinks = ({
  isLoggedIn,
  isTeamsales,
}: {
  isLoggedIn: boolean;
  isTeamsales: boolean;
}) => {
  const [clubAssortmentLinks, setClubAssortmentLinks] = React.useState<
    AssortmentLinkData[] | null
  >(null);
  const { configuration } = useSharedData().data;
  const [clubApi] = useState(
    new ClubApi(new Configuration(configuration.frontendApi))
  );

  useEffect(() => {
    if (!isTeamsales || !isLoggedIn) {
      return;
    }
    void (async () => {
      const { data } = await clubApi.clubControllerGetClubAssortmentLinksGET();
      const { assortmentLinks = [] } = data;
      setClubAssortmentLinks(assortmentLinks);
    })();
  }, [isLoggedIn, isTeamsales]);

  return clubAssortmentLinks;
};

export { useClubAssortmentLinks };

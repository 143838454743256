import parse from "html-react-parser";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { useEffect, useState } from "react";
import { Drawer } from "react-app/src/components/Drawer";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { Button, SectionHeading } from "./styles";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { Text } from "react-app/src/components/Text";
import type { Photoshoot } from "@xxl/pim-api";
import stringFormat from "string-format";
import { Container } from "./SizeGuide.styled";
import { isNullOrUndefined, hasValue } from "@xxl/common-utils";
import { StatusCodes } from "http-status-codes";
import type { SizeGuide as TSizeGuide } from "@xxl/content-api";
import { useApiClients } from "react-app/src/contexts/ApiClients";
import { log } from "@xxl/logging-utils";
const { NO_CONTENT } = StatusCodes;

const useSizeGuides = (brandCode: string, categoryCodes: string[]) => {
  const [data, setData] = useState<TSizeGuide | undefined>(undefined);
  const {
    nextFrontendApi: { getSizeGuide },
  } = useApiClients();

  useEffect(() => {
    const fetchSizeGuides = async () => {
      try {
        const { data: sizeGuideData, status } = await getSizeGuide(
          brandCode,
          categoryCodes
        );
        if (status === NO_CONTENT) {
          return;
        }
        setData(sizeGuideData);
        return;
      } catch (error) {
        log.error(error);
        return undefined;
      }
    };
    void fetchSizeGuides();
  }, []);

  return data;
};

type Props = {
  brandCode: string;
  categoryCode: string[];
  photoshoot?: Photoshoot | null;
};
export const SizeGuide = ({
  brandCode = "",
  categoryCode = [""],
  photoshoot,
}: Props) => {
  const { t } = useTranslations();
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = () => setIsOpen(!isOpen);
  const { modelHeight, modelSize } = photoshoot ?? {};
  const hasModelInfo = hasValue(modelHeight) && hasValue(modelSize);

  const sizeGuide = useSizeGuides(brandCode, categoryCode);

  if (isNullOrUndefined(sizeGuide) && isNullOrUndefined(photoshoot))
    return null;

  return (
    <>
      <Container>
        <XxlStack direction="row" justifyContent="flex-end">
          <Button
            onClick={toggleIsOpen}
            data-testid="size-guide-button"
            style={{ padding: 0 }}
          >
            <Text fontFamily="medium">{t("product.details.size.guide")}</Text>
          </Button>
        </XxlStack>
        {hasModelInfo && (
          <Text>
            {stringFormat(
              t("product.details.model.size"),
              modelHeight.toString(),
              modelSize.toUpperCase()
            )}
          </Text>
        )}
      </Container>
      <Drawer
        open={isOpen}
        heading={t("product.details.size.guide")}
        onClose={toggleIsOpen}
        customPadding={xxlTheme.spaces.smallRegular}
      >
        <SectionHeading>{parse(sizeGuide?.content ?? "")}</SectionHeading>
      </Drawer>
    </>
  );
};

import { isFunctionalCookieEnabled } from "react-app/src/utils/Cookie/XXLCookie";
import { loadScript } from "react-app/src/utils/xxl-load-script";
import type { InjectedScript } from "react-app/src/utils//xxl-load-script";
import zIndex from "@/react-app/styles/theme/zIndex";
import { CustomWindowEvents } from "react-app/src/components/Common/util";

const initKindlyChatbot = (kindlyChatbotKey: string): void => {
  window.kindlyOptions = {
    zIndex: zIndex["kindly-chat"],
  };

  const scriptData: InjectedScript = {
    src: "https://chat.kindlycdn.com/kindly-chat.js",
    id: "kindly-chat",
    dataParams: [
      { name: "data-shadow-dom", value: "1" },
      {
        name: "data-bot-key",
        value: kindlyChatbotKey,
      },
    ],
  };

  if (typeof window !== "undefined") {
    window.addEventListener(
      CustomWindowEvents.COOKIE_INFORMATION_CONSENT_GIVEN,
      () => {
        if (isFunctionalCookieEnabled()) {
          loadScript(scriptData);
        }
      }
    );
  }
};

export { initKindlyChatbot };

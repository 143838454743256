import styled from "@emotion/styled/macro";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { PriceType as RibbonVariant } from "../../../generated/graphql-code-generator";

type RibbonType = (typeof RibbonVariant)[keyof typeof RibbonVariant];

const ribbonColorMap = {
  [RibbonVariant.PROMOTION]: xxlTheme.colors.xxlGreen,
  [RibbonVariant.CAMPAIGN]: xxlTheme.colors.xxlPurple,
  [RibbonVariant.PREVIOUS_PRICE]: xxlTheme.colors.xxlGrey,
  [RibbonVariant.REGULAR]: xxlTheme.colors.xxlAmber,
  [RibbonVariant.ONLY_AVAILABLE_ONLINE]: xxlTheme.colors.xxlGrey,
  [RibbonVariant.PREVIOUS_PRICE_BUNDLE_SAVING]: xxlTheme.colors.xxlGrey,
} as const;

const RIBBON_HEIGHT = "34px";
const RIBBON_WIDTH = "60px";
const RIBBON_ANGLE_WIDTH = "19px";

const RibbonBody = styled.div<{ variant: RibbonType }>`
  display: grid;
  place-items: center;
  min-width: ${RIBBON_WIDTH};
  height: ${RIBBON_HEIGHT};
  padding: 0 ${xxlTheme.spaces.micro};
  color: black;
  font-size: larger;
  background-color: ${({ variant }) => ribbonColorMap[variant]};
`;

const RibbonAngle = styled.div<{ variant: RibbonType }>`
  right: ${RIBBON_WIDTH};
  border-top: ${RIBBON_HEIGHT} solid ${({ variant }) => ribbonColorMap[variant]};
  border-right: ${RIBBON_ANGLE_WIDTH} solid transparent;
`;

const RibbonWrapper = styled.div`
  display: flex;
`;

const ribbonTypePredict = (variant: string): RibbonType =>
  RibbonVariant[variant as keyof typeof RibbonVariant];

type RibbonProps = {
  text: string;
  variant: keyof typeof RibbonVariant;
};

const Ribbon = ({ text, variant }: RibbonProps) => {
  const ribbonVariant = ribbonTypePredict(variant);
  return (
    <RibbonWrapper>
      <RibbonBody variant={ribbonVariant}> {text} </RibbonBody>
      <RibbonAngle variant={ribbonVariant} />
    </RibbonWrapper>
  );
};

const spacing = xxlTheme.spaces.smallLarge;

const Banner = styled.div`
  width: 100%;
  display: flex;
  gap: ${xxlTheme.spaces.mini};
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  bottom: ${spacing};
  left: ${spacing};
  line-height: 1.2;
  white-space: nowrap;
  z-index: ${xxlTheme.zIndex["image-banner"]};
`;

const Splash = styled.div`
  position: absolute;
  top: ${spacing};
  left: ${spacing};
  z-index: ${xxlTheme.zIndex["image-banner"]};
`;

export const SliderBanner = {
  Banner,
  Ribbon,
  Splash,
};

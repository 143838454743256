import React from "react";
import { NextImage } from "../../common/NextImage/NextImage";
import styled from "@emotion/styled/macro";

const Overlay = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: start;
  z-index: 1000;
  overflow: scroll;
`;

const FullSizeImage = styled.img`
  width: auto;
  height: auto;
  position: absolute;
  inset: 0;
  margin: auto;
`;

type ImageSliderItemProps = {
  src: string;
  alt: string;
  width: number;
  height: number;
  isLaptopSize: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const ImageSliderItem = ({
  src,
  alt,
  width,
  height,
  isOpen,
  setIsOpen,
  isLaptopSize,
}: ImageSliderItemProps) => {
  return (
    <>
      <NextImage
        onClick={() => setIsOpen(true)}
        src={src}
        alt={alt}
        width={width}
        height={height}
        style={
          isLaptopSize
            ? { width: "auto", height: "100%" }
            : { width: "100%", height: "auto" }
        }
        sizes="100vw"
      />

      {isOpen && (
        <Overlay onClick={() => setIsOpen(false)}>
          <FullSizeImage src={src} alt={alt} width="auto" height="auto" />
        </Overlay>
      )}
    </>
  );
};

export default ImageSliderItem;

import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { ProductData } from "@xxl/frontend-api";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { convertProduct } from "../../utils/Tracking";
import { PurchaseDetails } from "./ServiceProduct.styled";
import { ServiceProductDumb } from "./ServiceProductDumb";
import { useServicesContext } from "./ServiceProductsContext";
import { ToggleServiceProductInCartButton } from "./ToggleServiceProductInCartButton";
import { windowAccess } from "../../utils/Window";
import { useTracking } from "../../contexts/Tracking";

type ServiceProductProps = {
  item: ProductData;
  category?: string;
  actionInProgress: boolean;
  handleChange: (value: boolean) => void;
  isRecommended: boolean;
};

export const ServiceProduct: React.FunctionComponent<ServiceProductProps> = ({
  item,
  category,
  handleChange,
  actionInProgress,
  isRecommended,
}) => {
  const [productListName] = useState(
    windowAccess()._sharedData.gtmData.productListName ?? ""
  );
  const { state } = useServicesContext();
  const { isProductPage } = state;
  const tracking = useTracking();
  const serviceDetailsRef = useRef<HTMLLIElement>(null);
  const [isLoading, _setIsLoading] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const { description, name, price, brand, code } = item;

  const handleTrackingAddToCart = useCallback((): void => {
    tracking.sendAddServiceToCartEvent({
      product: convertProduct(item),
      list: productListName,
    });
  }, [item, productListName, tracking]);

  const handleTrackingRemoveFromCart = useCallback((): void => {
    if (serviceDetailsRef.current === null) {
      return;
    }

    if (isProductPage === true) {
      tracking.sendRemoveServiceOnPDPEvent(convertProduct(item));
    } else {
      tracking.sendRemoveServiceOnCartEvent({
        product: convertProduct(item),
        list: "lightbox",
      });
    }
  }, [isProductPage, item, tracking]);

  useEffect(() => {
    setIsSelected(
      isNotNullOrUndefined(
        item.sizeOptions?.find(
          ({ code: sizeCode }) => sizeCode === state.serviceAdded
        )
      )
    );
  }, [item.sizeOptions, state.serviceAdded]);

  return (
    <ServiceProductDumb
      brand={brand}
      category={category}
      code={code}
      description={description}
      isLoading={isLoading}
      isProductPage={isProductPage}
      isRecommended={isRecommended}
      isSelected={isSelected}
      name={name}
      price={price}
      serviceDetailsRef={serviceDetailsRef}
      productType={item.productType}
      PurchaseDetails={
        <PurchaseDetails>
          <ToggleServiceProductInCartButton
            item={item}
            actionInProgress={actionInProgress}
            onChange={handleChange}
            onAddToCart={handleTrackingAddToCart}
            onRemoveFromCart={handleTrackingRemoveFromCart}
          />
        </PurchaseDetails>
      }
    />
  );
};

import * as React from "react";
import {
  Confirmation,
  ConfirmationHeading,
  ConfirmationFooter,
  ConfirmationStoreInfo,
  ConfirmationButtonStyled,
} from "./ClickAndCollect.styled";
import type { Store } from "@xxl/content-api";
import { StoreStockSelectedDetailsMapLink } from "../StoreStock";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { StoreStockSelectedDetails } from "../StoreStock/StoreStockStyles";
import { StoreStockLinkIcon } from "../StoreStock/StoreStockLinkIcon";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { ClickAndCollectOpeningHours } from "./ClickAndCollectOpeningHours";
import { StoreStockMap } from "../StoreFinder/StoreStockMap";

type ClickAndCollectConfirmationProps = {
  store: Store;
  setIsDialogBoxOpen: (arg0: boolean) => void;
  setDialogHeader: (arg0: string) => void;
};

const ClickAndCollectConfirmation: React.FunctionComponent<
  ClickAndCollectConfirmationProps
> = ({ store, setIsDialogBoxOpen, setDialogHeader }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslations();
  setDialogHeader(
    t("product.details.clickcollect.title") +
      " " +
      t("product.details.clickcollect.order.placed")
  );

  const handleDialogBoxClose = (): void => {
    setIsDialogBoxOpen(false);
  };

  return (
    <Confirmation>
      <ConfirmationHeading>
        <p>{t("product.details.clickcollect.order.info")}</p>
      </ConfirmationHeading>
      <ConfirmationStoreInfo isMobile={isMobile}>
        <article>
          <StoreStockSelectedDetailsMapLink
            selectedStoreDetails={store}
            {...{ isMobile }}
          >
            <StoreStockMap selectedStoreDetails={store} mapSize="580x346" />
          </StoreStockSelectedDetailsMapLink>
          <StoreStockSelectedDetails>
            <h3>{store.name}</h3>
            <StoreStockSelectedDetailsMapLink
              selectedStoreDetails={store}
              {...{ isMobile }}
            >
              <StoreStockLinkIcon isMobile={false} />
              <p>{t("product.details.storestock.status.google.maps.link")}</p>
            </StoreStockSelectedDetailsMapLink>
          </StoreStockSelectedDetails>
        </article>
        <article>
          <ClickAndCollectOpeningHours selectedStoreDetails={store} />
        </article>
      </ConfirmationStoreInfo>
      <ConfirmationFooter isMobile={isMobile}>
        <ConfirmationButtonStyled
          className="button button--small button--primary"
          onClick={handleDialogBoxClose}
          isMobile={isMobile}
        >
          {t("product.details.clickcollect.continue.shopping")}
        </ConfirmationButtonStyled>
      </ConfirmationFooter>
    </Confirmation>
  );
};

export { ClickAndCollectConfirmation };

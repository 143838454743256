import styled from "@emotion/styled/macro";
import { color } from "@xxl/theme";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

export const SectionHeading = styled.section`
  h2 {
    font-size: ${xxlTheme.typography.mediumRegular.fontSize}px;
  }
`;

export const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  outline-color: ${color.green.hex};
  outline-offset: ${xxlTheme.spaces.micro};
`;

import React from "react";
import { Button } from "../../../styled/Button.styled";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { ErrorMessage } from "./SignUpForm/SignUpForm";
import { phoneField } from "./SignUpForm/SignUpFormHelper";
import {
  FormButtonAsLink,
  FormContainer,
  FormHeaderInModal,
  InputContainer,
  SignUpErrorMessage,
  SignUpFormInput,
  SignUpFormLabel,
} from "./SignUpForm/SignupForm.styled";
import type { FormikHelpers } from "formik";
import { Formik } from "formik";
import { LoadingCircle } from "../../../components/Common/LoadingCircle/LoadingCircle";
import { DialogBoxHeaderWrapper } from "../../../components/DialogBox/DialogBox.styled";
import DialogCloseButton from "../SharedComponents/DialogBoxCloseButton";
import { SignupViewStates } from "./SignUpModal";
import { requestMembership } from "../../../components/UserDetails/UserAPI";
import { useSharedData } from "../../../contexts/SharedData";
import isEmpty from "lodash/isEmpty";
import type { TranslationKey } from "../../../translations";
import type { ServerErrorsTranslated } from "./SignUpForm/types";
import { xxlTheme } from "../../../styles/xxl-theme";
import { dispatchEvent } from "../../../utils/xxl-event";
import { legacySiteUidToSiteUid } from "../../../utils/xxl-shared-data";
import { hasNotFoundError } from "../Login";
import { log } from "@xxl/logging-utils";

enum SignupPhoneFormErrors {
  NOT_FOUND = "NOT_FOUND",
  ALREADY_IN_USE = "ALREADY_IN_USE",
  INVALID_PHONE = "INVALID_PHONE",
  INVALID_REGION = "INVALID_REGION",
}

type SignupPhoneFormData = {
  phoneNumber: string;
};

type SignupPhone = {
  handleDialogBoxClosing: () => void;
  setViewState: React.Dispatch<React.SetStateAction<SignupViewStates>>;
  setMobilePhone: React.Dispatch<React.SetStateAction<string>>;
};

const SignupPhone = ({
  handleDialogBoxClosing,
  setViewState,
  setMobilePhone,
}: SignupPhone) => {
  const { t } = useTranslations();
  const { configuration, siteUid } = useSharedData().data;

  const signUpTranslations = {
    formHeader: t("reward.success.account.create"),
    terms: t("reward.signup.terms.label"),
    register: t("reward.signup.register"),
    existing: t("reward.success.account.existing"),
    buttonText: t("login.form.login.button"),
  };
  const [inProgress, setInProgress] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [translatedServerErrors, setTranslatedServerErrors] =
    React.useState<ServerErrorsTranslated>({});
  const [userExists, setUserExists] = React.useState(false);
  const { aws_appsync_apiKey, aws_appsync_graphqlEndpoint } =
    configuration.amplifyConfig;

  const goBackToLogin = () => {
    dispatchEvent("xxl-login-click");
    handleDialogBoxClosing();
  };

  const onClickSubmit = async (
    values: SignupPhoneFormData,
    formikHelpers: FormikHelpers<SignupPhoneFormData>
  ) => {
    const { phoneNumber } = values;
    formikHelpers.setTouched({});
    setTranslatedServerErrors({});
    setUserExists(false);
    try {
      setInProgress(true);

      const res = await requestMembership(
        { phoneNumber, siteId: legacySiteUidToSiteUid(siteUid) },
        aws_appsync_graphqlEndpoint,
        aws_appsync_apiKey
      );
      const { data, errors } = res;

      if (hasNotFoundError(errors)) {
        setMobilePhone(phoneNumber);
        setViewState(SignupViewStates.SIGN_UP_USER_NOT_FOUND);
        return;
      }

      const inputError = errors?.find(({ errorType }) =>
        [
          SignupPhoneFormErrors.INVALID_PHONE,
          SignupPhoneFormErrors.INVALID_REGION,
        ].includes(errorType as SignupPhoneFormErrors)
      );
      if (inputError !== undefined) {
        setTranslatedServerErrors({
          phoneNumber: t(inputError.message as TranslationKey),
        });
        return;
      }

      const accountExistsError = errors?.find(
        ({ errorType }) => errorType === SignupPhoneFormErrors.ALREADY_IN_USE
      );
      if (accountExistsError !== undefined) {
        setUserExists(true);
        setTranslatedServerErrors({
          phoneNumber: t(accountExistsError.message as TranslationKey),
        });
        return;
      }

      if (!isEmpty(errors)) {
        const errorList = errors
          ?.map((error) => t(error.message as TranslationKey))
          .join(", ");
        setErrorMessage(errorList ?? t("general.error"));
        return;
      }
      const createSuccess = data?.requestMembership?.success;

      if (createSuccess === true) {
        setViewState(SignupViewStates.SIGN_UP_USER_FOUND);
      }
      if (createSuccess === false) {
        setMobilePhone(phoneNumber);
        setViewState(SignupViewStates.SIGN_UP_USER_NOT_FOUND);
      }
    } catch (error) {
      setErrorMessage(t("general.error"));
      log.error(error);
    } finally {
      setInProgress(false);
    }

    // TODO -  https://columbus-dc.atlassian.net/wiki/spaces/xxl/pages/180158474/Reward+2.0+Bonus+program#1.5.2.1-Initiation-step
  };

  const { fieldName, placeholder, inputMode, autocompleteToken, id, label } =
    phoneField(t);

  return (
    <Formik
      initialValues={{ phoneNumber: "" }}
      onSubmit={onClickSubmit}
      validateOnBlur={true}
      validateOnChange={true}
      isInitialValid={false}
    >
      {({
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        touched,
        values,
      }): JSX.Element => (
        <FormContainer inProgress={false} onSubmit={handleSubmit}>
          <div>
            <DialogBoxHeaderWrapper>
              <FormHeaderInModal>
                {signUpTranslations.formHeader}
              </FormHeaderInModal>

              <DialogCloseButton
                handleDialogBoxClosing={handleDialogBoxClosing}
              />
            </DialogBoxHeaderWrapper>
            <p>{t("reward.signup.phone.description")}</p>
            {errorMessage !== null && (
              <SignUpErrorMessage>{errorMessage}</SignUpErrorMessage>
            )}
            <InputContainer key={fieldName}>
              <SignUpFormLabel htmlFor={id} required={true}>
                {label}
              </SignUpFormLabel>
              <SignUpFormInput
                autoComplete={autocompleteToken}
                id={id}
                inputMode={inputMode}
                name={fieldName}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={placeholder}
                type="tel"
                /* eslint-disable  @typescript-eslint/no-explicit-any */
                /* eslint-disable  @typescript-eslint/no-unsafe-assignment */
                value={values[fieldName] as any} // TODO: Fix type in api.
              />
              {userExists ? (
                <SignUpErrorMessage color={xxlTheme.colors.xxlRed}>
                  <div>
                    {translatedServerErrors[fieldName]}
                    <FormButtonAsLink onClick={goBackToLogin}>
                      {t("account.phone.form.error.phone.link")}
                    </FormButtonAsLink>
                  </div>
                </SignUpErrorMessage>
              ) : (
                <ErrorMessage
                  {...{ fieldName, errors, translatedServerErrors }}
                  isTouched={touched[fieldName] ?? false}
                />
              )}
            </InputContainer>
          </div>
          <Button
            id="reward-signup-form-submit"
            type="submit"
            inline={true}
            className="button button--primary button--small button--full-width"
            disabled={!isValid}
          >
            {inProgress ? (
              <LoadingCircle isLoading={inProgress} />
            ) : (
              t("general.next")
            )}
          </Button>
        </FormContainer>
      )}
    </Formik>
  );
};

export { SignupPhone };

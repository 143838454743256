import { useEffect, useState } from "react";
import type { XXLAddToCartErrorPayload } from "react-app/src/global";
import type { TranslationKey } from "react-app/src/translations";
import * as XxlEvent from "react-app/src/utils/xxl-event";

export const CLEAR_ERROR_TIMEOUT_MS = 10_000;

const useAddToCartError = () => {
  const [addToCartError, setAddToCartError] = useState<TranslationKey | null>(
    null
  );

  const clearAddToCartError = () => {
    XxlEvent.dispatchEvent<XXLAddToCartErrorPayload>(
      XxlEvent.XXL_ADD_TO_CART_ERROR,
      { errorTranslationKey: null }
    );
  };

  useEffect(() => {
    if (addToCartError !== null) {
      const timeout = setTimeout(() => {
        setAddToCartError(null);
      }, CLEAR_ERROR_TIMEOUT_MS);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [addToCartError]);

  useEffect(() => {
    const handleError = ({
      detail,
    }: CustomEvent<XXLAddToCartErrorPayload>): void => {
      setAddToCartError(detail.errorTranslationKey as TranslationKey);
    };
    document.body.addEventListener("xxl-add-to-cart-error", handleError);

    return () =>
      document.body.removeEventListener("xxl-add-to-cart-error", handleError);
  }, []);

  return { addToCartError, clearAddToCartError };
};

export { useAddToCartError };

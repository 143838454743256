import * as React from "react";
import type { DayOpeningSchedule, Store } from "@xxl/content-api";
import { OpeningHours, OpeningHoursRow } from "./ClickAndCollect.styled";
import type { TranslationKey } from "../../translations";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { isNotNullOrUndefined } from "@xxl/common-utils";

type ClickAndCollectOpeningHoursProps = {
  selectedStoreDetails: Store;
};

const daysOfTheWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const daysOfTheWeekTranslations = new Map<string, TranslationKey>([
  ["Monday", "store.opening.hours.monday"],
  ["Tuesday", "store.opening.hours.tuesday"],
  ["Wednesday", "store.opening.hours.wednesday"],
  ["Thursday", "store.opening.hours.thursday"],
  ["Friday", "store.opening.hours.friday"],
  ["Saturday", "store.opening.hours.saturday"],
  ["Sunday", "store.opening.hours.sunday"],
]);

const getOpeningHoursForDay = (selectedStoreDetails: Store, day: string) =>
  selectedStoreDetails.openingHours?.find((e) => e.day === day);

const getCncOpeningHoursForDay = (selectedStoreDetails: Store, day: string) =>
  selectedStoreDetails.cncOpeningHours?.find((e) => e.day === day);

const getOpeningHours = (selectedStoreDetails: Store): DayOpeningSchedule[] => {
  return daysOfTheWeek.map((day) => {
    const cncHours = getCncOpeningHoursForDay(selectedStoreDetails, day);
    if (cncHours === undefined || Boolean(cncHours.closed)) {
      return getOpeningHoursForDay(
        selectedStoreDetails,
        day
      ) as DayOpeningSchedule;
    } else {
      return cncHours;
    }
  });
};

const formatDate = (date: Date) => {
  const dateISOString = date.toISOString();
  return dateISOString.replace(/\d+-(\d+)-(\d+)T.*/, "$2.$1");
};

const ClickAndCollectOpeningHours: React.FunctionComponent<
  ClickAndCollectOpeningHoursProps
> = ({ selectedStoreDetails }) => {
  const { t } = useTranslations();

  return (
    <OpeningHours>
      <h3>{t("product.details.clickcollect.opening.hours")}:</h3>
      {isNotNullOrUndefined(selectedStoreDetails.openingHours) && (
        <ul>
          {getOpeningHours(selectedStoreDetails).map(
            ({ closed, opensAt, closesAt, day }) => {
              if (day === undefined) {
                return null;
              }

              const dayKey = daysOfTheWeekTranslations.get(day);

              return (
                <OpeningHoursRow key={day}>
                  <span>{dayKey !== undefined ? t(dayKey) : day}</span>
                  <span>
                    {closed ?? true ? (
                      t("product.details.clickcollect.closed")
                    ) : (
                      <>
                        {opensAt} &ndash; {closesAt}
                      </>
                    )}
                  </span>
                </OpeningHoursRow>
              );
            }
          )}
        </ul>
      )}
      {isNotNullOrUndefined(selectedStoreDetails.specialOpeningHours) && (
        <>
          <h3>{t("product.details.clickcollect.special.opening.hours")}:</h3>
          <ul>
            {selectedStoreDetails.specialOpeningHours.map(
              ({ closed, opensAt, closesAt, date }) => {
                const specialOpeningDate = new Date(date as string);
                return (
                  <OpeningHoursRow key={date}>
                    <span>{formatDate(specialOpeningDate)}</span>
                    <span>
                      {closed ? (
                        t("product.details.clickcollect.closed")
                      ) : (
                        <>
                          {opensAt} &ndash; {closesAt}
                        </>
                      )}
                    </span>
                  </OpeningHoursRow>
                );
              }
            )}
          </ul>
        </>
      )}
    </OpeningHours>
  );
};

export { ClickAndCollectOpeningHours };

import { hasValue, isNotNullOrUndefined } from "@xxl/common-utils";
import { log } from "@xxl/logging-utils";
import type {
  CampaignBadgeData,
  ImageData,
  PriceInfoData,
  PriceTranslationKeyType,
  PriceType,
  ProductData,
  USPData,
  VariantData as VariantDataElevate,
} from "@xxl/product-search-api";
import type { BadgeType, ProductType, StockStatus } from "..//data-types";
import {
  getAutomatedBadges,
  getCampaignHighlightedLabel,
  getCampaignRibbon,
} from "./badges-helper";

/**
 * Used to pass along product IDs for aditional sales recommendations.
 */
export type AdditionalSales = {
  accessoryProductIds: string[];
  crossSalesProductIds: string[];
  serviceProductsIds: string[];
};

type VariantData = {
  code: string;
  label: string;
  sizeCode: string;
  stockStatus: StockStatus;
  ticket: string;
  url: string;
};

type StyleOption = {
  baseColor?: string;
  code: string;
  ean: string;
  primaryImageURL: string;
  stockStatus: StockStatus;
  title: string;
  url: string;
  variants: VariantData[];
};

type PriceDataBase = {
  value: number;
  valueFormatted: string;
};

export type PriceData =
  | (PriceDataBase & {
      labelType: "TranslatedLabel";
      label: string;
    })
  | (PriceDataBase & {
      labelType: "TranslationKey";
      translationKey: PriceTranslationKeyType;
    })
  | (PriceDataBase & {
      labelType: "None";
    });

type ProductCardBase = {
  badges: BadgeType[];
  code: string;
  name: string;
  primaryImage: string;
  productImages: string[];
  styleOptions: StyleOption[];
  ticket: string;
  url: string;
  stockStatus: StockStatus;
  averageRating?: number;
  brandName?: string;
};

export type ProductCardDataV2 = ProductCardBase & {
  version: 2;
  additionalSales?: AdditionalSales;
  baseColor?: string;
  campaignHighlightedLabel?: CampaignBadgeData;
  campaignRibbon?: CampaignBadgeData;
  categoryBreadcrumbs: {
    code: string;
    name: string;
  }[];
  ean: string;
  price: { selling: PriceData; alternate?: PriceData; type: PriceType };
  selectedStoreStockStatus: StockStatus;
  type: ProductType;
  variants: VariantData[];
  usps?: USPData[];
};

type StockStatusType = {
  stockStatus: StockStatus;
  selectedStoreStockStatus: StockStatus;
};

const statusReduceFunction = (
  prevStatus: StockStatus,
  { stockStatus }: { stockStatus: StockStatus }
): StockStatus =>
  stockStatus === "INSTOCK" || prevStatus === "INSTOCK"
    ? "INSTOCK"
    : stockStatus === "LOWSTOCK" || prevStatus === "LOWSTOCK"
      ? "LOWSTOCK"
      : "OUTOFSTOCK";

export const getStockStatus = (
  variants: VariantDataElevate[]
): StockStatusType =>
  variants.reduce(
    (status, variant) => {
      if (hasValue(variant.availability)) {
        return {
          stockStatus: variant.availability
            .filter(({ channel }) => channel === "ONLINE")
            .reduce(statusReduceFunction, status.stockStatus),
          selectedStoreStockStatus: variant.availability
            .filter(({ channel }) => channel === "STORE")
            .reduce(statusReduceFunction, status.selectedStoreStockStatus),
        };
      }
      const fallbackStatus = statusReduceFunction(status.stockStatus, {
        stockStatus: variant.stockStatus,
      });
      return {
        stockStatus: fallbackStatus,
        selectedStoreStockStatus: fallbackStatus,
      };
    },
    {
      stockStatus: "OUTOFSTOCK",
      selectedStoreStockStatus: "OUTOFSTOCK",
    } as StockStatusType
  );

export const getPrimaryImageUrl = (
  images: ImageData[],
  productCode: string
) => {
  const primaryImage = images.find(({ tags }) => tags.includes("primary"));

  if (primaryImage === undefined) {
    log.info(`Product with code ${productCode} is missing a primary image.`);
    return "/not-found"; // fallback to non existing route to avoid calling existing base route.
  }

  const [firstAvailable] = primaryImage.sources;

  return firstAvailable.url;
};

export const getSecondaryImageUrl = (
  images: ImageData[],
  productCode: string
) => {
  const secondaryImage = images.find(({ tags }) => tags.includes("secondary"));

  if (secondaryImage === undefined) {
    log.info(`Product with code ${productCode} is missing a secondary image.`);
    return getPrimaryImageUrl(images, productCode);
  }

  const [firstAvailable] = secondaryImage.sources;

  return firstAvailable.url;
};

const toStyleOptions = ({
  baseColor,
  code,
  imageInfo,
  stockStatus,
  title,
  url,
  variants,
}: {
  code: string;
  imageInfo: {
    images: ImageData[];
  };
  stockStatus: StockStatus;
  title: string;
  url: string;
  variants: VariantData[];
  baseColor?: string;
}): StyleOption => ({
  ...(baseColor !== undefined && { baseColor }),
  code,
  ean: variants[0].code,
  primaryImageURL: getPrimaryImageUrl(imageInfo.images, code),
  stockStatus,
  title,
  url,
  variants,
});

const getPriceData = (price: PriceInfoData): PriceData => {
  const { labelType, range } = price;

  const baseData = {
    value: range.min.value,
    valueFormatted: range.min.formatted,
  };

  if (labelType === "TranslatedLabel") {
    return {
      ...baseData,
      ...{
        label: price.label,
        labelType,
      },
    };
  }

  if (labelType === "TranslationKey") {
    return {
      ...baseData,
      ...{
        translationKey: price.translationKey,
        labelType,
      },
    };
  }

  return {
    ...baseData,
    ...{
      labelType: "None",
    },
  };
};

type ToProductCardDataFromProductArgs = {
  product: ProductData;
  styleOptions: StyleOption[];
};

const toVariant = ({
  code,
  label,
  stockStatus,
  sizeCode,
  ticket,
  url,
}: VariantDataElevate): VariantData => ({
  code,
  label,
  sizeCode,
  stockStatus,
  ticket,
  url,
});

const toProductCardDataFromProduct = ({
  product,
  styleOptions,
}: ToProductCardDataFromProductArgs): ProductCardDataV2 => {
  const {
    additionalSales,
    badges,
    baseColor,
    brand,
    categoryBreadcrumbs,
    code,
    imageInfo: { images },
    price,
    rating,
    ticket,
    title,
    type,
    url,
    usps,
    variants,
  } = product;

  const { alternate, selling, type: priceType } = price;

  const { stockStatus, selectedStoreStockStatus } = getStockStatus(variants);
  const primaryImage = getPrimaryImageUrl(images, code);
  const secondaryImage = getSecondaryImageUrl(images, code);
  const automatedBadges = getAutomatedBadges(badges);
  const campaignRibbon = getCampaignRibbon(badges);
  const campaignHighlightedLabel = getCampaignHighlightedLabel(badges);
  const ean = variants[0].code;

  return {
    ...(additionalSales !== undefined && { additionalSales }),
    ...(rating !== undefined && { averageRating: rating }),
    badges: automatedBadges,
    ...(baseColor !== undefined && { baseColor }),
    ...(brand !== undefined && { brandName: brand.name }),
    ...(campaignHighlightedLabel !== null && { campaignHighlightedLabel }),
    ...(campaignRibbon !== null && { campaignRibbon }),
    categoryBreadcrumbs,
    code,
    ean,
    name: title,
    price: {
      selling: getPriceData(selling),
      type: priceType,
      ...(isNotNullOrUndefined(alternate) && {
        alternate: getPriceData(alternate),
      }),
    },
    primaryImage,
    productImages: [primaryImage, secondaryImage],
    selectedStoreStockStatus,
    stockStatus,
    styleOptions,
    ticket,
    type: type === "UNKNOWN" ? "NORMAL" : type,
    url,
    usps,
    variants: variants.map(toVariant),
    version: 2,
  };
};

export const toProductCardDataFromBase = (baseProduct: {
  products: ToProductCardDataFromProductArgs["product"][];
}): ProductCardDataV2 => {
  const { products } = baseProduct;
  const [primaryProduct] = products;
  const shouldIncludeStyleOptions = products.length > 1;
  const styleOptions = shouldIncludeStyleOptions
    ? products.map(toStyleOptions)
    : [];

  return toProductCardDataFromProduct({
    product: primaryProduct,
    styleOptions,
  });
};

export const getAdditionalSalesProductIds = (
  additionalSales?: AdditionalSales
) => {
  const {
    accessoryProductIds = [],
    crossSalesProductIds = [],
    serviceProductsIds = [],
  } = additionalSales ?? {};
  return [accessoryProductIds, crossSalesProductIds, serviceProductsIds].flat();
};

const createSortingFunction = (priority: string[]) => {
  const priorityMap = new Map(priority.map((id, index) => [id, index]));

  return (a: ProductCardDataV2, b: ProductCardDataV2) => {
    const [codeA] = a.code.split("_");
    const [codeB] = b.code.split("_");
    const priorityA = priorityMap.get(codeA) ?? Infinity;
    const priorityB = priorityMap.get(codeB) ?? Infinity;
    return priorityA - priorityB;
  };
};

export const productCardDataToAdditionalSalesProducts = (
  products: ProductCardDataV2[],
  additionalSales: AdditionalSales
): {
  accessoryProducts: ProductCardDataV2[];
  crossSalesProducts: ProductCardDataV2[];
  serviceProducts: ProductCardDataV2[];
} => {
  const accessorySort = createSortingFunction(
    additionalSales.accessoryProductIds
  );
  const crossSalesSort = createSortingFunction(
    additionalSales.crossSalesProductIds
  );
  const serviceSort = createSortingFunction(additionalSales.serviceProductsIds);

  return {
    accessoryProducts: products
      .filter(({ code }) =>
        additionalSales.accessoryProductIds.find((id) => code.startsWith(id))
      )
      .toSorted(accessorySort),
    crossSalesProducts: products
      .filter(({ code }) =>
        additionalSales.crossSalesProductIds.find((id) => code.startsWith(id))
      )
      .toSorted(crossSalesSort),
    serviceProducts: products
      .filter(({ code }) =>
        additionalSales.serviceProductsIds.find((id) => code.startsWith(id))
      )
      .toSorted(serviceSort),
  };
};

import type { PropsWithChildren } from "react";
import React, { useReducer } from "react";
import { FilterContext, getInitialState, reducer } from "../FilterState";

export const FilterContextProvider = ({ children }: PropsWithChildren) => {
  const [stateStruct, dispatch] = useReducer(reducer, getInitialState());

  return (
    <FilterContext.Provider value={{ state: stateStruct.current, dispatch }}>
      {children}
    </FilterContext.Provider>
  );
};

import React from "react";
import {
  RewardsBannerSection,
  Wrapper,
  ButtonsWrapper,
  TextWrapper,
  Heading,
  Description,
} from "./RewardsBanner.styled";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { XxlButton } from "../Common/XxlButton";
import { useSharedData } from "../../contexts/SharedData";
import * as XxlEvent from "../../utils/xxl-event";
import { useMediaQuery } from "@mui/material";
import { mobileMediaQuery } from "../../utils/xxl-screen";

// It's used in next-app, but eslint is not able to recognize it
// eslint-disable-next-line import/no-unused-modules
export const RewardsBanner: React.FunctionComponent = () => {
  const { t } = useTranslations();
  const {
    requestMapping: { reward },
  } = useSharedData().data;
  const isMobile = useMediaQuery(mobileMediaQuery);

  const openRewardsPageTrigger = () => {
    location.href = reward;
  };

  return (
    <RewardsBannerSection>
      <Wrapper className="container">
        <TextWrapper>
          <Heading>{t("footer.reward.title")}</Heading>
          <Description>{t("footer.reward.subtitle")}</Description>
        </TextWrapper>
        <ButtonsWrapper>
          <XxlButton
            type="button"
            size={isMobile ? "large" : "small"}
            variant="rewardsBannerTransparent"
            onClick={() => XxlEvent.dispatchOpenLoginEvent()}
          >
            {t("footer.reward.button.login.title")}
          </XxlButton>
          <XxlButton
            type="button"
            size={isMobile ? "large" : "small"}
            variant="rewardsBannerSecondary"
            onClick={openRewardsPageTrigger}
          >
            {t("footer.reward.button.title")}
          </XxlButton>
        </ButtonsWrapper>
      </Wrapper>
    </RewardsBannerSection>
  );
};

import styled from "@emotion/styled/macro";
import { MQ } from "../../../styles/helpers";

export const Wrapper = styled.div`
  display: none;

  ${MQ("tabletHorizontal")} {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;
    padding-top: var(--space-mini);
  }
`;

import type { SiteIdentifier } from "@xxl/common-utils";
import { getEnvVar, isNotProd, isTrue } from "../utils/environment-variables";
import { type BooleanToggles, type StringToggles } from "@/react-app/global";
import { Apis } from "@/utils/api-helper";

interface GetSsmParametersInput {
  siteId: SiteIdentifier;
  parameters: {
    parameterName: string;
    fallback: string;
  }[];
}

interface GetDynamicTogglesKeyValuePairsInput {
  siteUid: SiteIdentifier;
  namesOfBooleanToggles: (keyof BooleanToggles)[];
  namesOfStringToggles: (keyof StringToggles)[];
}

const getSsmParameters = async ({
  siteId,
  parameters,
}: GetSsmParametersInput): Promise<
  Record<(typeof parameters)[number]["parameterName"], string>
> => {
  let names;
  try {
    names = parameters
      .map((parameter) => parameter.parameterName)
      .filter((parameter) => parameter)
      .sort()
      .join(",");
    const { data } = await Apis.getInstance().ssmApi.getParameters(
      names,
      siteId
    );
    const parametersFromSsmApiResponse = data.data;
    const returnValueAsEntries: [string, string][] = [];
    for (const parameter of parameters) {
      const foundParameterFromSsmApiRespone = parametersFromSsmApiResponse.find(
        (parameterFromSsmApiResponse) =>
          parameterFromSsmApiResponse.queryName === parameter.parameterName
      );
      if (
        foundParameterFromSsmApiRespone !== undefined &&
        foundParameterFromSsmApiRespone.value !== null
      ) {
        returnValueAsEntries.push([
          parameter.parameterName,
          foundParameterFromSsmApiRespone.value,
        ]);
      } else {
        returnValueAsEntries.push([
          parameter.parameterName,
          parameter.fallback,
        ]);
      }
    }
    return Object.fromEntries(returnValueAsEntries);
  } catch (error) {
    if (isNotProd()) {
      throw Error(`Failed to get ${names ?? ""} from ssm-api`);
    }
    throw error as Error;
  }
};

const mapSsmParametersToBooleanToggles = (
  parameters: Record<string, string>
): BooleanToggles =>
  Object.fromEntries(
    Object.entries(parameters).map(([key, value]) => [
      key,
      value.toLowerCase().trim() === true.toString(),
    ])
  ) as BooleanToggles;

const getDynamicTogglesKeyValuePairs = async ({
  namesOfBooleanToggles,
  namesOfStringToggles,
  siteUid,
}: GetDynamicTogglesKeyValuePairsInput): Promise<
  | Required<Record<(typeof namesOfBooleanToggles)[number], boolean>>
  | Required<Record<(typeof namesOfStringToggles)[number], string>>
> => {
  const methodReturnAsEntries: (
    | [keyof BooleanToggles, boolean]
    | [keyof StringToggles, string]
  )[] = [];

  const ssmParameters = await getSsmParameters({
    siteId: siteUid,
    parameters: [
      ...namesOfBooleanToggles.map((parameter) => ({
        parameterName: parameter,
        fallback: false.toString(),
      })),
      ...namesOfStringToggles.map((parameter) => ({
        parameterName: parameter,
        fallback: "",
      })),
    ],
  });
  const booleanToggles: BooleanToggles = mapSsmParametersToBooleanToggles(
    Object.fromEntries(
      Object.entries(ssmParameters).filter(([key]) =>
        namesOfBooleanToggles.includes(key as keyof BooleanToggles)
      )
    )
  );
  const stringToggles: StringToggles = Object.fromEntries(
    Object.entries(ssmParameters).filter(([key]) =>
      namesOfStringToggles.includes(key as keyof StringToggles)
    )
  ) as StringToggles;
  methodReturnAsEntries.push(
    ...(Object.entries(booleanToggles) as [keyof BooleanToggles, boolean][])
  );
  methodReturnAsEntries.push(
    ...(Object.entries(stringToggles) as [keyof StringToggles, string][])
  );

  return Object.fromEntries(methodReturnAsEntries) as Record<
    (typeof namesOfBooleanToggles)[number],
    boolean
  > &
    Record<(typeof namesOfStringToggles)[number], string>;
};

const getSsmConfigParameters = async (siteId: SiteIdentifier) => {
  const getSsmParamtersResponse = await getSsmParameters({
    siteId,
    parameters: [
      {
        parameterName: "config.site.cookieVersion",
        fallback: getEnvVar("CONFIG_SITE_COOKIEVERSION"),
      },
      { parameterName: "config.site.giosgEnabled", fallback: "false" },
      { parameterName: "config.site.giosgId", fallback: "" },
      { parameterName: "config.site.logRocketApiId", fallback: "" },
      { parameterName: "config.site.serverGtmScriptUrl", fallback: "" },
      { parameterName: "config_ecoonline_api_key", fallback: "" },
      { parameterName: "config_ecoonline_url", fallback: "" },
    ],
  });

  return {
    cookieVersion: getSsmParamtersResponse["config.site.cookieVersion"],
    giosg: {
      giosgEnabled: isTrue(getSsmParamtersResponse["config.site.giosgEnabled"]),
      giosgId: getSsmParamtersResponse["config.site.giosgId"],
    },
    logRocketApiId: getSsmParamtersResponse["config.site.logRocketApiId"],
    serverGtmScriptUrl:
      getSsmParamtersResponse["config.site.serverGtmScriptUrl"],
    ecoOnlineUrl: getSsmParamtersResponse["config_ecoonline_url"],
    ecoOnlineApiKey: getSsmParamtersResponse["config_ecoonline_api_key"],
  };
};

export {
  getDynamicTogglesKeyValuePairs,
  getSsmConfigParameters,
  // eslint-disable-next-line import/no-unused-modules
  getSsmParameters,
};

export enum LongTailFacetType {
  size,
  color,
  brand,
  category,
}

export type LongTailTitleFacet = {
  value: string;
  url?: string;
  type: LongTailFacetType;
};

export type LongTailTitleProps = {
  size: LongTailTitleFacet | null;
  color: LongTailTitleFacet | null;
  brand: LongTailTitleFacet | null;
  category: LongTailTitleFacet | null;
  [key: string]: LongTailTitleFacet | null;
};

import type { CookieCategory } from "react-app/src/utils/Cookie";
import { getCookieInformationConsent } from "react-app/src/utils/Cookie";
import { CustomWindowEvents } from "react-app/src/components/Common/util";
import { log } from "@xxl/logging-utils";

const checkIfIncluded = (consent: CookieCategory) =>
  typeof window !== "undefined" &&
  window.CookieInformation !== undefined &&
  window.CookieInformation.getConsentGivenFor(consent);

const checkIfRequredConsentsSelected = (
  requiredConsentList: CookieCategory[]
) => {
  const { consents_approved = [] } = getCookieInformationConsent() ?? {};

  const hasRequiredConsents = requiredConsentList.every((consent) =>
    consents_approved.includes(consent)
  );

  return hasRequiredConsents === false
    ? requiredConsentList.every(checkIfIncluded)
    : hasRequiredConsents;
};

const invokeAfterConsent = ({
  callback,
  cookieConsentList,
}: {
  callback: () => void;
  cookieConsentList: CookieCategory[];
  allRequired?: boolean;
}) => {
  if (cookieConsentList.length === 0) {
    log.error(
      "Error: Tried to call 'invokeAfterConsent' without a consent list!"
    );
    return;
  }

  window.addEventListener(
    CustomWindowEvents.COOKIE_INFORMATION_CONSENT_GIVEN,
    function () {
      const areConsentsSelected =
        checkIfRequredConsentsSelected(cookieConsentList);
      if (window.CookieInformation !== undefined && areConsentsSelected) {
        callback();
      }
    },
    false
  );
};

export { invokeAfterConsent, checkIfRequredConsentsSelected };

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { isFunctionalCookieEnabled } from "react-app/src/utils/Cookie/XXLCookie";
import {
  getGiosgVersion,
  getScriptSrc,
  VERSION_ONE,
  BASE_SRC,
} from "react-app/src/utils/Giosg/giosg";
import { loadScript } from "react-app/src/utils/xxl-load-script";
import { parseNumber } from "react-app/src/utils/xxl-number";
import { CustomWindowEvents } from "react-app/src/components/Common/util";

const initNextJsGiosg = (giosgId: string): void => {
  if (typeof window !== "undefined") {
    window.addEventListener(
      CustomWindowEvents.COOKIE_INFORMATION_CONSENT_GIVEN,
      () => {
        if (isFunctionalCookieEnabled()) {
          const version = getGiosgVersion(giosgId);
          const src = getScriptSrc({ giosgId, version });
          const giosgIdAsStringOrNumber =
            version === VERSION_ONE ? parseNumber(giosgId) : giosgId;
          window._giosg =
            window._giosg ||
            ((...args: unknown[]): void =>
              (window._giosg._e = window._giosg._e || []).push(args));
          (window._giosg._c = giosgIdAsStringOrNumber),
            (window._giosg._h = BASE_SRC);
          loadScript({ src });
        }
      }
    );
  }
};

export { initNextJsGiosg };

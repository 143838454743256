import type { PropsWithChildren } from "react";
import React, { useReducer } from "react";
import { CartContext, getInitialState, reducer } from "./CartState";

type CartContextProps = {
  children: Pick<PropsWithChildren, "children">["children"];
  isTeamsales: boolean;
};

export const CartContextProvider = ({
  children,
  isTeamsales,
}: CartContextProps) => {
  const [stateStruct, dispatch] = useReducer(
    reducer,
    getInitialState(isTeamsales)
  );

  return (
    <CartContext.Provider value={{ state: stateStruct.current, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

import { isNotNullOrUndefined } from "@xxl/common-utils";
import { ShootingEquipment } from "../../../generated/graphql-code-generator";
import type { CartResponseData } from "../Api/types";

export type CartItemData = Pick<
  NonNullable<
    Pick<
      NonNullable<Pick<NonNullable<CartResponseData>, "data">["data"]>,
      "cart"
    >["cart"]
  >,
  "items"
>["items"];

export const isShootingEquipmentInCartItems = (
  cartItems: CartItemData | undefined
): boolean =>
  cartItems !== undefined
    ? cartItems.find(
        (item) =>
          isNotNullOrUndefined(item.article.shootingEquipment) &&
          (item.article.shootingEquipment === ShootingEquipment.AMMUNITION ||
            item.article.shootingEquipment === ShootingEquipment.WEAPON)
      ) !== undefined
    : false;

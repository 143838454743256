import type { CartItem, TypeProps } from "../Api/types";
import type { ProductData, SizeData } from "@xxl/frontend-api";

const filterSizeOptions = (sizeOptions: SizeData[]): string => {
  const filteredOptions = sizeOptions.filter(
    (item) =>
      item.ean !== undefined &&
      item.isInStock !== undefined &&
      Object.values(item.isInStock)[0]
  );

  return filteredOptions[0].code ?? "";
};

export const setItemServices = (services: ProductData[]): CartItem[] =>
  services.map(
    ({
      price: displayPrice,
      description,
      brand,
      primaryImage,
      name,
      sizeOptions = [],
      style,
      code,
      ean,
    }) => ({
      displayPrice,
      title: name as string,
      primaryImage: primaryImage as string,
      brand: brand !== undefined ? brand.name : undefined,
      description,
      productCode:
        code !== undefined
          ? code
          : sizeOptions.length > 0
            ? filterSizeOptions(sizeOptions)
            : "",
      entryNumber: -1,
      type: "SERVICE" as TypeProps,
      selectedStyle: style,
      ean,
    })
  );

const fontFamily = "var(--font-family-bold)";

export default {
  backButton: {
    fontFamily,
    fontSize: 20,
  },
  link: {
    fontFamily,
    fontSize: 15,
  },
  showAllLink: {
    fontFamily,
    fontSize: 18,
  },
};

import spaces from "../../styles/theme/spaces";

/**
 * Common object for component CSS gap properties.
 */
export const gap = {
  mobile: spaces.mini,
  laptop: spaces.smallRegular,
};

export const CustomWindowEvents = {
  COOKIE_INFORMATION_CONSENT_GIVEN: "CookieInformationConsentGiven",
};

import type { BundledProducts, CartItem } from "../Api/types";
import type { PriceData, PriceDisplayData } from "@xxl/frontend-api";
import type { AddToCartEventData } from "../../../global";

export const isEgAddToCartEvent = (
  item: CartItem | AddToCartEventData
): item is AddToCartEventData => {
  return ["eanCode", "quantity"].every((key) => Object.hasOwn(item, key));
};

export const isEgPriceData = (
  price: PriceData | PriceDisplayData | undefined
): price is PriceData => price !== undefined && "value" in price;

export const setBundledProductsArray = (
  bundledProducts: BundledProducts[]
): string[] => {
  const productsArray: string[] = [];
  bundledProducts.forEach((item) => {
    if (item?.article.name !== undefined) {
      productsArray.push(
        [item.article.name, item.article.size?.text].filter(Boolean).join(" ")
      );
    }
  });

  return productsArray;
};

export const parseCartId = (inputCartId?: string): string | undefined => {
  const cartId =
    inputCartId ?? new URLSearchParams(window.location.search).get("cartid");
  if (cartId === "" || cartId === null) {
    return;
  }
  try {
    return (
      JSON.parse(
        window.atob(cartId.replaceAll("-", "+").replaceAll("_", "/"))
      ) as {
        id?: string;
      }
    ).id;
  } catch (_) {
    return;
  }
};

import { useEffect, useState } from "react";
import { useClient } from "../../../hooks/useClient/useClient";
import { isNotNullOrUndefined } from "@xxl/common-utils";

type CountdownTime = {
  hasExpired: boolean;
  sec: number;
  min: number;
  hours: number;
  days: number;
};

const useCountdownTimer = ({
  endDate,
  isOnlyHours = false,
  startDate,
  isOnClient,
}: {
  endDate?: Date;
  isOnlyHours?: boolean;
  startDate?: Date;
  isOnClient?: boolean;
}): CountdownTime => {
  const [dateNow, setDateNow] = useState<Date>(new Date());
  let isClient = useClient();
  useEffect(() => {
    const interval = setInterval(() => setDateNow(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);
  isClient = isOnClient === undefined ? isClient : isOnClient;
  if (
    !isClient ||
    !isNotNullOrUndefined(endDate) ||
    dateNow > endDate ||
    (startDate !== undefined && startDate > dateNow)
  ) {
    return {
      days: 0,
      hasExpired: true,
      hours: 0,
      min: 0,
      sec: 0,
    };
  }

  const delta = endDate.getTime() - dateNow.getTime();
  const sec = Math.floor((delta % (1000 * 60)) / 1000);
  const min = Math.floor((delta % (1000 * 60 * 60)) / (1000 * 60));
  const hours = Math.floor((delta % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const days = Math.floor(delta / (1000 * 60 * 60 * 24));
  const includeDays = !isOnlyHours;
  const hasExpired = [sec, min, hours, days].every((time) => time === 0);

  return {
    days: includeDays ? days : 0,
    hasExpired,
    hours: includeDays ? hours : days * 24 + hours,
    min,
    sec,
  };
};

export { useCountdownTimer };

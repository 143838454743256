import { DeliveryTruck } from "@xxl/icons";
import React, { useEffect, useState } from "react";
import { ProgressBar } from "../Common/ProgressBar";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import stringFormat from "string-format";

type FreeDeliveryProgressProps = {
  cartPrice: number;
  freeShippingThreshold: number;
};

export const FreeDeliveryProgress = ({
  cartPrice,
  freeShippingThreshold,
}: FreeDeliveryProgressProps) => {
  const [label, setLabel] = useState("");
  const { t } = useTranslations();

  useEffect(() => {
    const delta = (freeShippingThreshold - cartPrice)
      .toFixed(2)
      .replace(".00", "");

    setLabel(
      cartPrice < freeShippingThreshold
        ? stringFormat(t("free.delivery.label.paid"), delta)
        : t("free.delivery.label.free")
    );
  }, [freeShippingThreshold, cartPrice, t]);

  return (
    <ProgressBar
      icon={<DeliveryTruck fontSize={16} />}
      id="free-delivery-progress"
      label={<span dangerouslySetInnerHTML={{ __html: label }}></span>}
      maxValue={freeShippingThreshold}
      value={cartPrice}
    />
  );
};

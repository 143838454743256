import type { StockStatusEnum } from "../../../enums";
import * as React from "react";
import { OptionNodeStockStatus } from "./OptionNodeStockStatus";

type OptionNodeProps = {
  itemName: string;
  productCode?: string;
  productEan?: string;
  stockStatus?: StockStatusEnum;
};

export const OptionNode: React.FC<OptionNodeProps> = ({
  itemName = "",
  productCode,
  productEan,
  stockStatus,
}) => (
  <>
    <span
      data-select-name={itemName}
      data-product-code={productCode}
      data-product-ean={productEan}
    >
      {itemName}
    </span>
    <OptionNodeStockStatus stockStatus={stockStatus} />
  </>
);

import type { Dispatch } from "react";
import { useEffect } from "react";
import type { Action } from "../CartState";
import { SET_CART_COUNT } from "../CartState";
import type { XxlSetCartCountEventPayload } from "../../../global";
import {
  addXXLEventListener,
  removeXXLEventListener,
} from "../../../utils/xxl-event";

const useSetCartCountEventListener = (dispatch: Dispatch<Action>) => {
  useEffect(() => {
    const callback = ({ detail }: CustomEvent<XxlSetCartCountEventPayload>) => {
      const { count } = detail;
      dispatch({
        type: SET_CART_COUNT,
        payload: { count },
      });
    };
    addXXLEventListener("XXL_SET_CART_COUNT", callback);
    return () => removeXXLEventListener("XXL_SET_CART_COUNT", callback);
  }, [dispatch]);
};

export { useSetCartCountEventListener };

import React from "react";
import { useTranslations } from "../../../../contexts/Translations/TranslationsContext";
import { Formik } from "formik";
import { DialogBoxHeaderWrapper } from "../../../DialogBox/DialogBox.styled";
import {
  FormContainer,
  OTPReCaptcha,
  OTPText,
} from "../LoginForm/LoginForm.styled";
import DialogCloseButton from "../../SharedComponents/DialogBoxCloseButton";
import { phoneField } from "../../SignUp/SignUpForm/SignUpFormHelper";
import {
  FormButtonAsLink,
  FormHeaderInModal,
  SignUpErrorMessage,
} from "../../SignUp/SignUpForm/SignupForm.styled";
import { FormInput } from "../../../Common/FormInput";
import { initiateOTPChallenge } from "../../../UserDetails/UserAPI";
import { useSharedData } from "../../../../contexts/SharedData";
import {
  convertSiteUidToHost,
  legacySiteUidToSiteUid,
} from "../../../../utils/xxl-shared-data";
import type { GraphQLError } from "../../../../graphql/graphqlApi";
import type { TranslationKey } from "../../../../translations";
import { type OTPData, otpInitializeSchema } from "./OTPLoginHelper";
import spaces from "../../../../styles/theme/spaces";
import type { MouseEvent } from "react";
import { hasNotFoundError } from "../LoginForm/LoginFormHelper";
import { XxlButton } from "../../../Common/XxlButton/XxlButton";
import type { ReCAPTCHA } from "react-google-recaptcha";
import { log } from "@xxl/logging-utils";

type OTPInitializeFormData = {
  phoneNumber: string;
};

type OTPRequestFormProps = {
  initialPhoneNumber?: string;
  handleDialogBoxClosing: () => void;
  onSubmitSuccess: (arg: OTPData) => void;
  goToSignup: (e: MouseEvent) => void;
};

const OTPInitializeForm = ({
  onSubmitSuccess,
  initialPhoneNumber,
  handleDialogBoxClosing,
  goToSignup,
}: OTPRequestFormProps) => {
  const { t } = useTranslations();
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const [inProgress, setInProgress] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [captchaToken, setCaptchaToken] = React.useState<string | null>(
    "Cypress" in window ? "test-token" : null
  );
  const { siteUid, configuration, captcha } = useSharedData().data;
  const { aws_appsync_graphqlEndpoint, aws_appsync_apiKey } =
    configuration.amplifyConfig;
  const { siteKey } = captcha;
  const [isUserNotFound, setIsUserNotFound] = React.useState(false);
  const setErrors = (errors: GraphQLError[]) => {
    const errorList = errors
      .map((error) => t(error.message as TranslationKey))
      .join(", ");

    if (hasNotFoundError(errors)) {
      setIsUserNotFound(true);
    } else {
      setErrorMessage(errorList ?? t("general.error"));
    }
  };

  const onCaptchaChange = (value: string | null) => {
    setCaptchaToken(value);
  };

  const onCaptchaError = () => {
    setCaptchaToken(null);
    setErrorMessage(t("general.error"));
  };

  const resetCaptcha = () => {
    recaptchaRef.current?.reset();
    setCaptchaToken(null);
  };

  const onClickSubmit = async (values: OTPInitializeFormData) => {
    setErrorMessage(null);
    try {
      setInProgress(true);
      const { phoneNumber } = values;

      const site = convertSiteUidToHost(legacySiteUidToSiteUid(siteUid));
      const { data, errors } = await initiateOTPChallenge(
        { phoneNumber, reCaptchaV3Token: captchaToken ?? "", site },
        aws_appsync_graphqlEndpoint,
        aws_appsync_apiKey
      );
      if (errors !== undefined) {
        resetCaptcha();
        setErrors(errors);
      }
      const { session, timestamp, expires } = data?.initiateOTPChallenge ?? {};
      if (session) {
        onSubmitSuccess({ session, timestamp, phoneNumber, expires });
      }
    } catch (err) {
      setErrorMessage(t("general.error"));
      resetCaptcha();
      log.error(err);
    } finally {
      setInProgress(false);
    }
  };

  const { placeholder, inputMode, autocompleteToken, id, label, fieldName } =
    phoneField(t);

  return (
    <>
      <Formik
        initialValues={{ phoneNumber: initialPhoneNumber ?? "" }}
        onSubmit={onClickSubmit}
        validateOnBlur={true}
        validateOnChange={true}
        isInitialValid={initialPhoneNumber ? true : false}
        validationSchema={otpInitializeSchema(t)}
      >
        {({
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
          touched,
          values,
        }): JSX.Element => (
          <FormContainer inProgress={false} onSubmit={handleSubmit}>
            <DialogBoxHeaderWrapper>
              <FormHeaderInModal>{t("login.form.title")}</FormHeaderInModal>
              <DialogCloseButton
                handleDialogBoxClosing={handleDialogBoxClosing}
              />
            </DialogBoxHeaderWrapper>
            <OTPText>{t("login.form.otp.request.description")}</OTPText>
            {isUserNotFound && (
              <SignUpErrorMessage>
                {t("login.form.otp.phone.not.found")}
                <FormButtonAsLink onClick={goToSignup} style={{ marginTop: 0 }}>
                  {t("login.form.otp.become.member")}
                </FormButtonAsLink>
              </SignUpErrorMessage>
            )}
            {errorMessage !== null && (
              <SignUpErrorMessage>{errorMessage}</SignUpErrorMessage>
            )}
            <FormInput
              fieldName={fieldName}
              id={id}
              data-testid="phone-number-input"
              label={label}
              required={true}
              autocompleteToken={autocompleteToken}
              inputMode={inputMode}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={placeholder}
              type="tel"
              value={values[fieldName]}
              errors={errors}
              translatedServerErrors={{}}
              touched={touched[fieldName] ?? false}
              style={{ paddingBottom: spaces.smallLarge }}
            />

            <OTPReCaptcha
              ref={recaptchaRef}
              onChange={onCaptchaChange}
              sitekey={siteKey}
              onExpired={() => setCaptchaToken(null)}
              onErrored={onCaptchaError}
            />
            <XxlButton
              type="submit"
              data-testId="otp-phone-submit-button"
              variant="secondary"
              size="small"
              disabled={!isValid || captchaToken === null}
              loading={inProgress}
            >
              {t("general.next")}
            </XxlButton>
          </FormContainer>
        )}
      </Formik>
    </>
  );
};

export { OTPInitializeForm };

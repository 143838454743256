import styled from "@emotion/styled/macro";
import { typographyToCss } from "../../../styles/helpers";
import { xxlTheme } from "../../../styles/xxl-theme";
import typography from "./CartPage.typography";

const { colors, spaces } = xxlTheme;
const { rewardText, rewardTrigger } = typography;

export const RewardText = styled.p`
  margin: 0;
  ${typographyToCss(rewardText)};
`;

export const RewardTrigger = styled.span`
  ${typographyToCss(rewardTrigger)};
  display: inline-flex;
  align-items: center;
  height: 100%;

  & svg {
    margin-left: ${spaces.mini};
    transition: 0.2s ease;
  }
`;

export const StyledRewardBox = styled.button`
  margin-top: ${spaces.smallRegular};
  background-color: ${colors.xxlGreen};
  color: ${colors.xxlBlack};
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  padding: 0 ${spaces.smallRegular};

  &:hover {
    cursor: pointer;

    & ${RewardTrigger} svg {
      transform: translateX(2px);
    }
  }

  &:focus-visible {
    outline-width: 2px;
    outline-style: solid;
    outline-color: ${colors.xxlGreen};
    outline-offset: 2px;
  }

  &:first-child {
    margin-top: 0;
  }
`;

import Link from "next/link";
import { Text } from "react-app/src/components/Text/Text";
import { Icon } from "react-app/src/components/Icon";
import { LinkWrapper } from "./ProductDescriptions.styled";
import type { ProductLink } from "../types";

export const ProductLinkComponent = ({
  productLink,
}: {
  productLink: ProductLink | null;
}) => {
  if (productLink === null) return null;
  return (
    <Link
      href={productLink.url}
      target="_blank"
      rel="noopener nofollow noreferrer"
    >
      <LinkWrapper>
        <Icon name="LinkV2" size={20} />
        <Text as="span">{productLink.title}</Text>
      </LinkWrapper>
    </Link>
  );
};

import { createContext, useContext } from "react";
import type { SharedData } from "../../global";
import type { EnvironmentData } from "next-js-app/src/global";

export type AppBasedSharedData =
  | {
      isReactApp: true;
      data: SharedData;
    }
  | {
      isReactApp: false;
      data: EnvironmentData;
    };

export const SharedDataContext = createContext<AppBasedSharedData | undefined>(
  undefined
);

export const useSharedData = () => {
  const context = useContext(SharedDataContext);

  if (context === undefined) {
    throw Error("useSharedData must be used within its provider.");
  }

  return context;
};

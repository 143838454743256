import { color } from "@xxl/theme";

const colorsAsCssVariable = {
  xxlAmber: color.amber.cssVariable,
  xxlBlack: color.black.cssVariable,
  xxlBlue: color.blue.cssVariable,
  xxlDarkAmber: color.darkAmber.cssVariable,
  xxlDarkBlue: color.darkBlue.cssVariable,
  xxlDarkGreen: color.darkGreen.cssVariable,
  xxlDarkGrey: color.darkGray.cssVariable,
  xxlDarkOrange: color.darkOrange.cssVariable,
  xxlDarkPurple: color.darkPurple.cssVariable,
  xxlDarkTurquoise: color.darkTurquoise.cssVariable,
  xxlError: color.red.cssVariable,
  xxlGreen: color.green.cssVariable,
  xxlGrey: color.gray.cssVariable,
  xxlLightAmber: color.lightAmber.cssVariable,
  xxlLightBlue: color.lightBlue.cssVariable,
  xxlLightGreen: color.lightGreen.cssVariable,
  xxlLightGrey: color.lightGray.cssVariable,
  xxlLightOrange: color.lightOrange.cssVariable,
  xxlLightRed: color.lightRed.cssVariable,
  xxlLightTurquoise: color.lightTurquoise.cssVariable,
  xxlMediumGrey: color.mediumGray.cssVariable,
  xxlOrange: color.orange.cssVariable,
  xxlPurple: color.purple.cssVariable,
  xxlRed: color.red.cssVariable,
  xxlWarning: color.amber.cssVariable,
  xxlWebBlack: color.webBlack.cssVariable,
  xxlWebGreen: color.webGreen.cssVariable,
  xxlWebGrey: color.webGray.cssVariable,
  xxlWhite: color.white.cssVariable,
  xxlXmasRed: color.xmasRed.cssVariable,
  xxlShadowGradient: "var(--color-xxl-shadow-gradient)",
  xxlLightFuchsia: color.lightFuchsia.cssVariable,
  xxlDarkFuchsia: color.darkFuchsia.cssVariable,
} as const;

/**
 * * The colors object for the XXL theme.
 * @deprecated Use colorsAsCssVariable or get colors from the `@xxl/theme`.
 * @example
 * import { color } from "@xxl/theme";
 * `color.amber.hex` or `color.amber.cssVariable`
 */

const colorsAsHex = {
  xxlAmber: color.amber.hex,
  xxlBlack: color.black.hex,
  xxlBlue: color.blue.hex,
  xxlDarkAmber: color.darkAmber.hex,
  xxlDarkBlue: color.darkBlue.hex,
  xxlDarkGreen: color.darkGreen.hex,
  xxlDarkGrey: color.darkGray.hex,
  xxlDarkOrange: color.darkOrange.hex,
  xxlDarkPurple: color.darkPurple.hex,
  xxlDarkTurquoise: color.darkTurquoise.hex,
  xxlError: color.red.hex,
  xxlGreen: color.green.hex,
  xxlGrey: color.gray.hex,
  xxlLightAmber: color.lightAmber.hex,
  xxlLightBlue: color.lightBlue.hex,
  xxlLightGreen: color.lightGreen.hex,
  xxlLightGrey: color.lightGray.hex,
  xxlLightOrange: color.lightOrange.hex,
  xxlLightRed: color.lightRed.hex,
  xxlLightTurquoise: color.lightTurquoise.hex,
  xxlMediumGrey: color.mediumGray.hex,
  xxlOrange: color.orange.hex,
  xxlPurple: color.purple.hex,
  xxlRed: color.red.hex,
  xxlTurquoise: color.turquoise.hex,
  xxlWarning: color.amber.hex,
  xxlWebBlack: color.webBlack.hex,
  xxlWebGreen: color.webGreen.hex,
  xxlWebGrey: color.webGray.hex,
  xxlWhite: color.white.hex,
  xxlXmasRed: color.xmasRed.hex,
  xxlShadowGradient: "#000000b3",
  xxlLightFuchsia: color.lightFuchsia.hex,
  xxlDarkFuchsia: color.darkFuchsia.hex,
} as const;

export { colorsAsCssVariable, colorsAsHex };

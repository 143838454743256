import { useSharedData } from "../contexts/SharedData";
import type { FeatureToggles } from "../global";

/**
@description This hook is used to get the value of a feature toggle from the shared data context.
@example const { myFeatureToggle } = useFeatureToggles("myFeatureToggle");
@param flag The feature toggle key.
@returns The value of the feature toggle.
*/
export const useFeatureToggles = <T extends keyof FeatureToggles>(flag: T) => {
  const {
    data: { featureToggles },
  } = useSharedData();
  const value = featureToggles[flag];
  return { [flag]: value } as { [K in T]: FeatureToggles[K] };
};

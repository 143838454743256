import type {
  MegaMenuLevelTwoLink,
  MenuSubCategoryData,
} from "@xxl/frontend-api";
import { CaretRight } from "@xxl/icons";
import React from "react";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { Header, HeaderLink } from "./CategorySection.styled";
import { LinkContainer } from "./Grid.styled";
import type { Link as LinkType } from "./LinkListTemporary";
import { getUrl } from "./mega-menu-helper";
import {
  CUSTOM_CATEGORY_CODE,
  SHOWMORE_CATEGORY_CODE,
} from "../../Search/Constants";
import { Link } from "./Link.styled";
import { CategoryIcon } from "../Mobile/CategoryIcon";
import { MAX_NR_OF_LINKS_IN_COLUMN } from "./constants";

type CategorySectionProps = {
  c2: MegaMenuLevelTwoLink;
  limitNrOfLinks: boolean;
};

const mapToLinks = (
  subCategories: (MenuSubCategoryData & { iconCode?: string })[] = [],
  limitNrOfLinks: boolean
): LinkType[] =>
  subCategories
    .filter((_link, index) =>
      limitNrOfLinks ? index < MAX_NR_OF_LINKS_IN_COLUMN : true
    )
    .map(({ iconCode = "", name = "", url = "" }) => ({ iconCode, name, url }));

export const CategorySection: React.FunctionComponent<CategorySectionProps> = ({
  c2,
  limitNrOfLinks: limitLinks,
}) => {
  const { t } = useTranslations();
  const { displayName, category } = c2;

  if (category === undefined) {
    throw Error("Missing category.");
  }

  if (displayName === undefined) {
    throw Error("Missing displayName.");
  }

  const { code, url: link, subCategories } = category;

  if (typeof code !== "string") {
    throw Error("Missing category code.");
  }

  const showMoreLink = getUrl({ code, link });
  const links = mapToLinks(subCategories, limitLinks);
  const nrOfColumns = links.length <= MAX_NR_OF_LINKS_IN_COLUMN ? 1 : 2;
  const isMoreCatyegory = code === SHOWMORE_CATEGORY_CODE;

  return (
    <LinkContainer
      nrOfColumns={nrOfColumns}
      nrOfRows={isMoreCatyegory ? links.length : undefined}
    >
      {isMoreCatyegory ? (
        links.map(({ iconCode = "", name, url }) => (
          <Link
            key={`${name}-${url}`}
            href={url}
            iconLink={typeof iconCode === "string"}
          >
            <CategoryIcon code={iconCode} size={24} /> {name}
          </Link>
        ))
      ) : (
        <>
          <Header nrOfColumns={nrOfColumns}>
            <CategoryIcon code={code} size={18} />
            {displayName}
          </Header>
          {code !== CUSTOM_CATEGORY_CODE && (
            <HeaderLink href={showMoreLink} nrOfColumns={nrOfColumns}>
              {`${t(
                "mega.menu.categories.show.all"
              )} ${displayName.toLowerCase()}`}
              <CaretRight fontSize={18} />
            </HeaderLink>
          )}
          {links.map(({ iconCode, name, url }) => (
            <Link
              key={`${name}-${url}`}
              href={url}
              iconLink={typeof iconCode === "string"}
            >
              {name}
            </Link>
          ))}
        </>
      )}
    </LinkContainer>
  );
};

/**
 * @link https://columbus-dc.atlassian.net/wiki/spaces/xxl/pages/148078667/ERP+Sales+Methods+definition
 */
export const SALES_METHODS = {
  // ORDINARY: "0",
  // OBSOLETE: "1",
  NOT_ON_INTERNET: "2",
  // WEAPONS: "3",
  DANGEROUS_GOODS: "4",
  // INTERNET_STOCK: "5",
  // _420_SL: "6",
  // NO_STOCK_CONTROL: "7",
  // LOCALE_STORE: "8",
  // AMMUNITION: "9",
  ON_ORDER: "10",
  VENDOR_STOCK: "11",
  PRE_SALE: "12",
  // DIGITAL_PRODUCT: "13",
};

import isEmpty from "lodash/isEmpty";
import { getUrlParameter } from "../../../utils/xxl-url";

const needToCompleteProfile = () =>
  window.Cookie.getCookie().teamSalesData?.needToCompleteProfile ?? false;
const getClubName = () => {
  const clubLoginElement = document.getElementById("xxl-club-login-link");

  if (clubLoginElement === null) {
    return "";
  }

  const { clubId } = clubLoginElement.dataset;

  return clubId ?? "";
};

const checkIfAbandonedCartMode = () => {
  if (typeof window !== "undefined") {
    const param = getUrlParameter("eclub");
    return !isEmpty(param);
  }
  return false;
};

export { needToCompleteProfile, getClubName, checkIfAbandonedCartMode };

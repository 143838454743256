import styled from "@emotion/styled/macro";
import { fadeInAnimation } from "../../styled/mixins";
import { MQ } from "../../styles/helpers";
import { xxlTheme } from "../../styles/xxl-theme";
import { css } from "@emotion/react";
import { CheckCircle } from "@xxl/icons";
import typography from "../../styles/theme/typography";

const { colors, spaces } = xxlTheme;
const sectionWidthInPx = 550;
const diagonalBgInPx = 1000;
export const white = "var(--color-xxl-light-gray)";

const RewardHeader = {
  fontSize: 26,
  lineHeight: 1,
  fontFamily: "var(--font-family-bold)",
};

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-self: baseline;
  width: 100%;
  height: 100%;
  max-width: ${sectionWidthInPx * 2}px;
  ${fadeInAnimation("rewardsSignupAppear", "200ms")}

  ${MQ("tablet")} {
    flex-direction: column-reverse;
  }

  ${MQ("tabletHorizontal")} {
    flex-direction: row;
  }
`;

type TextContainerProps = {
  backgroundUrl?: string;
  isInModal?: boolean;
};
export const TextContainer = styled.div<TextContainerProps>`
  position: relative;
  z-index: 0;
  padding: ${spaces.large};
  width: 100%;
  overflow: hidden;

  ${({ backgroundUrl }) =>
    backgroundUrl !== undefined
      ? css`
          background: url(${backgroundUrl});
          background-repeat: no-repeat;
          background-size: cover;
          background-position-x: -50%;
        `
      : null}

  ${({ isInModal }) => {
    if (isInModal) {
      return css`
        &::after {
          height: ${diagonalBgInPx}px;
          width: ${diagonalBgInPx}px;
          background-color: black;
          ${MQ("tablet")} {
            clip-path: polygon(0% 0%, 57% 0%, 0% 100%);
          }
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          content: "";
          z-index: -1;
        }

        box-sizing: border-box;

        ${MQ("tablet")} {
          width: 50%;
          display: block;
          background-color: ${colors.xxlWebBlack};
        }
      `;
    } else {
      return css`
        ${MQ("tablet")} {
          padding: ${spaces.biggerBig} ${spaces.biggerBig} ${spaces.biggerBig}
            ${spaces.huge};
          display: block;
          width: 45vw;
          min-height: 200px;
        }

        ${MQ("tabletHorizontal")} {
          padding: ${spaces.huge} 160px ${spaces.huge} ${spaces.huge};
          background: transparent;
          box-sizing: border-box;
          width: 50%;
        }

        ${MQ("desktop")} {
          padding: 0 180px 0 0;
        }
      `;
    }
  }}
`;

export const CopyHeader = styled.h3`
  color: ${colors.xxlGreen};
  ${RewardHeader};
  margin-top: 0;
  margin-bottom: ${spaces.large};

  ${MQ("tablet")} {
    max-width: 70%;
  }
`;

const copyStyle = `
color: ${colors.xxlWhite};
margin-bottom: 16px;

`;
export const CopyText = styled.p`
  ${copyStyle}
`;

export const BulletText = styled.p`
  margin-top: 1px;
  margin-bottom: 0;
`;

export const CopyListItem = styled.li`
  ${copyStyle};
  ${typography.mediumRegular};
  font-size: 15px;
  display: flex;
  vertical-align: middle;
  margin-bottom: 1.6em;
`;

export const BulletList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  position: relative;
  z-index: 1;
  ${MQ("tablet")} {
    width: 50%;
  }
`;

export const CheckBullet = styled(CheckCircle)`
  margin-right: 12px;
  height: 24px;
  min-width: 24px;
  path {
    fill: ${xxlTheme.colors.xxlGreen};
  }
`;

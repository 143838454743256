import type { FunctionComponent, PropsWithChildren } from "react";
import React from "react";
import type { FeatureToggles } from "../../../global";
import { useSharedData } from "../../../contexts/SharedData";

type ToggleFeatureProps = {
  activeToggleValue?: boolean | string;
  flag: keyof FeatureToggles;
  untoggledComponent?: React.ReactNode;
};

/**
 * <ToggleFeature
 *   flag={flagsNames.THE_FEATURE_TOGGLE}
 *   untoggledComponent={UntoggledComponent}
 * >
 *   <h3>I might be gone or there!</h3>
 * </ToggleFeature>
 */
export const ToggleFeature: FunctionComponent<
  PropsWithChildren<ToggleFeatureProps>
> = ({ activeToggleValue = true, children, flag, untoggledComponent }) => {
  const { featureToggles } = useSharedData().data;
  const isFeatureEnabled = featureToggles[flag];

  return (
    <>
      {isFeatureEnabled === activeToggleValue
        ? children
        : untoggledComponent ?? null}
    </>
  );
};

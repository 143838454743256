import React from "react";
import { ICON_SIZE_PX } from "./constants";
import * as XxlIcons from "@xxl/icons";
import {
  BRANDS_CATEGORY_CODE,
  CUSTOM_CATEGORY_CODE,
  CUSTOMER_SERVICE_CATEGORY_CODE,
  GUIDE_CATEGORY_CODE,
  GIFTCARD_CATEGORY_CODE,
  NAVIGATE_BACK_CODE,
  NEWS_CATEGORY_CODE,
  REWARD_CATEGORY_CODE,
  SHOW_ALL_CATEGORIES_CODE,
  TEAMSALES_CATEGORY_CODE,
  WORKSHOP_CATEGORY_CODE,
} from "../../../components/Search/Constants";

type XxlIconName = keyof typeof XxlIcons;
type CategoryCode =
  | "100000"
  | "100500"
  | "120200"
  | "120400"
  | "120600"
  | "120627"
  | "120700"
  | "140000"
  | "140200"
  | "140400"
  | "140402"
  | "140600"
  | "160000"
  | "160200"
  | "160400"
  | "160600"
  | "160800"
  | "180000"
  | "200000"
  | "220000"
  | "220200"
  | "240000"
  | "2402022"
  | "241000"
  | "290000"
  | "800000"
  | "820000"
  | "9100000"
  | "9200000"
  | "9300000"
  | "9400000"
  | "14000000"
  | typeof BRANDS_CATEGORY_CODE
  | typeof CUSTOM_CATEGORY_CODE
  | typeof CUSTOMER_SERVICE_CATEGORY_CODE
  | typeof GUIDE_CATEGORY_CODE
  | typeof GIFTCARD_CATEGORY_CODE
  | typeof NAVIGATE_BACK_CODE
  | typeof NEWS_CATEGORY_CODE
  | typeof REWARD_CATEGORY_CODE
  | typeof SHOW_ALL_CATEGORIES_CODE
  | typeof TEAMSALES_CATEGORY_CODE
  | typeof WORKSHOP_CATEGORY_CODE;

const categoryCodeToIconName: Record<CategoryCode, XxlIconName> = {
  "100000": "Bikes",
  "120200": "Clothes",
  "120400": "Clothes",
  "120600": "Clothes",
  "120627": "Accessories",
  "120700": "Accessories",
  "140000": "Shoes",
  "140200": "Shoes",
  "140400": "Shoes",
  "140600": "Shoes",
  "100500": "Shoes",
  "140402": "Shoes",
  "160000": "Sports",
  "160200": "Football",
  "160400": "Golf",
  "160600": "RacketSports",
  "160800": "Sports",
  "180000": "Training",
  "200000": "Outdoor",
  "220000": "Fishing",
  "220200": "Hunting",
  "240000": "WinterSports",
  "2402022": "WinterSports",
  "241000": "Hockey",
  "290000": "Running",
  "800000": "Electronics",
  "820000": "Watersports",
  "9100000": "Men",
  "9200000": "Women",
  "9300000": "Kids",
  "9400000": "Outdoor",
  "14000000": "Teamsports",
  [BRANDS_CATEGORY_CODE]: "NewIn",
  [CUSTOM_CATEGORY_CODE]: "Plus",
  [CUSTOMER_SERVICE_CATEGORY_CODE]: "CustomerService",
  [GUIDE_CATEGORY_CODE]: "Guide",
  [GIFTCARD_CATEGORY_CODE]: "Giftcard",
  [NAVIGATE_BACK_CODE]: "ArrowLeft",
  [NEWS_CATEGORY_CODE]: "Email",
  [REWARD_CATEGORY_CODE]: "Reward",
  [SHOW_ALL_CATEGORIES_CODE]: "All",
  [TEAMSALES_CATEGORY_CODE]: "Football",
  [WORKSHOP_CATEGORY_CODE]: "Workshop",
};

const FALLBACK_CODE = "160800";

const isCategoryCode = (code: string): code is CategoryCode => {
  const codes = Object.keys(categoryCodeToIconName);
  return codes.includes(code);
};

const CategoryIcon: React.FunctionComponent<{
  code: string;
  size?: number;
  showFallback?: boolean;
}> = ({ code, size = ICON_SIZE_PX, showFallback = false, ...props }) => {
  if (!isCategoryCode(code) && !showFallback) {
    return null;
  }
  const categoryCode = isCategoryCode(code)
    ? code
    : isCategoryCode(`${code.substring(0, 2)}0000`)
      ? (`${code.substring(0, 2)}0000` as CategoryCode)
      : FALLBACK_CODE;

  const iconName = categoryCodeToIconName[categoryCode];

  const XxlIcon = XxlIcons[iconName];
  return <XxlIcon fontSize={size} {...props} />;
};

export { CategoryIcon };

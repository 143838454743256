import React, { useEffect, useState } from "react";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { useSessionSource } from "react-app/src/contexts/Session";
import { SizeSelectButton } from "react-app/src/components/SizeSelectButton/SizeSelectButton";
import { useSharedData } from "react-app/src/contexts/SharedData";
import { postNotificationRequest } from "./ProductSizeSelector.helper";
import { useApiClients } from "react-app/src/contexts/ApiClients";
import type { SizeData, StockStatus } from "@xxl/pim-api";
import type { ButtonSizeType } from "react-app/src/components/Common/XxlButton/XxlButton";
import { SizeSelectDrawer } from "./SizeSelectDrawer";
import { assertNever } from "react-app/src/utils/xxl-assert-never";
import { isNotEmpty, isNotNullOrUndefined } from "@xxl/common-utils";
import type { TranslationKey } from "react-app/src/translations";
import { useStateValue } from "cotton-box-react";
import { dispatchOpenLoginEvent } from "react-app/src/utils/xxl-event";
import { log } from "@xxl/logging-utils";

type PreSelectSizeStrategy =
  | "PRESELECT_FIRST_IN_STOCK"
  | "PRESELECT_ONE_SIZE"
  | "DO_NOT_PRESELECT";

type Props = {
  hasError?: boolean;
  onSelect: (ean: string, stockStatus: StockStatus) => void;
  size: ButtonSizeType;
  sizeOptions: SizeData[];
  styleCode: string;
  isDrawerOpen?: boolean;
  toggleIsSizeSelectDrawerOpen?: () => void;
  disabled?: boolean;
  unit?: string;
  /**
   * Strategy to use for auto selecting a size when the component is first rendered and no preSelectedSizeCode is set.
   * When using an option other than "DO_NOT_PRESELECT" and there are no products in stock
   * the first size in the sizeOptions array will be selected.
   */
  preSelectSizeStrategy?: PreSelectSizeStrategy;
  /**
   * Use this prop to preselect a size. It takes precedence over preSelectSizeStrategy.
   * Example value: 1152554_1_Size_1
   * Example value: 1000000137972
   */
  preSelectedSizeCodeOrEan?: string;
  shouldBePossibleToAddReminder?: boolean;
  shouldBePossibleToSelectOutOfStock?: boolean;
  isClickAndCollectEnabled: boolean;
};

export const ProductSizeSelector = ({
  disabled = false,
  hasError,
  isClickAndCollectEnabled,
  onSelect,
  preSelectSizeStrategy = "PRESELECT_ONE_SIZE",
  preSelectedSizeCodeOrEan,
  shouldBePossibleToAddReminder,
  shouldBePossibleToSelectOutOfStock = true,
  size,
  sizeOptions,
  styleCode,
  isDrawerOpen,
  toggleIsSizeSelectDrawerOpen,
  unit,
}: Props) => {
  const { t } = useTranslations();
  const [productsWithReminder, setProductsWithReminder] = useState<SizeData[]>(
    []
  );
  const [pendingRemindersToAdd, setPendingRemindersToAdd] = useState<
    SizeData[]
  >([]);
  const isLoggedIn = useStateValue(useSessionSource);
  const { siteUid } = useSharedData().data;
  const { authApi, customersApi } = useApiClients();
  const [selectedSize, setSelectedSize] = useState<SizeData["size"] | null>();
  const [internalIsDrawerOpen, setInternalIsDrawerOpen] = useState(false);
  const internalToggleIsSizeSelectDrawerOpen = () =>
    setInternalIsDrawerOpen(!internalIsDrawerOpen);
  const isOpen =
    isDrawerOpen !== undefined ? isDrawerOpen : internalIsDrawerOpen;
  const toggleOpen =
    toggleIsSizeSelectDrawerOpen !== undefined
      ? toggleIsSizeSelectDrawerOpen
      : internalToggleIsSizeSelectDrawerOpen;

  const handleSizeOptionSelection = (
    sizeOption: SizeData,
    shouldToggleOpenState = true
  ) => {
    const { ean, stockStatus } = sizeOption;

    if (ean === undefined) {
      throw Error(`EAN is missing.`);
    }
    if (stockStatus === undefined) {
      throw Error(`StockStatus is missing.`);
    }

    onSelect(ean, stockStatus);
    setSelectedSize(
      isNotNullOrUndefined(unit)
        ? t(`product.unit.amount.${unit}` as TranslationKey)
        : sizeOption.size
    );
    if (shouldToggleOpenState) {
      toggleOpen();
    }
  };

  const displaySize = selectedSize ?? t("product.details.select.size");

  const hasReminder = (ean: string) =>
    productsWithReminder.some(({ ean: eanToMtach }) => eanToMtach === ean);

  const onRemindMeClick = (
    evt: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    sizeOption: SizeData
  ) => {
    evt.stopPropagation();

    const { itemNumber, ean } = sizeOption;
    if (itemNumber === undefined || ean === undefined) {
      throw Error(
        `Properties are missing. ${typeof itemNumber}, ${typeof ean}`
      );
    }

    if (!isLoggedIn) {
      dispatchOpenLoginEvent();
      setPendingRemindersToAdd([...pendingRemindersToAdd, ...[sizeOption]]);
      return;
    }

    setProductsWithReminder([...productsWithReminder, ...[sizeOption]]);
    void postNotificationRequest(
      authApi,
      customersApi,
      siteUid,
      itemNumber,
      ean
    );

    return;
  };

  useEffect(() => {
    const preSelectSizeIfExisting = () => {
      if (isNotEmpty(preSelectedSizeCodeOrEan)) {
        const preselectedSizeOption = sizeOptions.find(({ code, ean }) =>
          [code, ean].includes(preSelectedSizeCodeOrEan)
        );
        if (preselectedSizeOption === undefined) {
          log.debug(
            `Size ${preSelectedSizeCodeOrEan} could not be pre selected as it's missing from sizeOptions.`
          );
          return false;
        } else {
          handleSizeOptionSelection(preselectedSizeOption, false);
          return true;
        }
      }

      return false;
    };

    const preSelectSizeByStrategy = () => {
      const sizesInStock = sizeOptions.filter(
        ({ stockStatus }) => stockStatus !== "OUTOFSTOCK"
      );
      const isOneSizeProduct = sizeOptions.length === 1;

      switch (preSelectSizeStrategy) {
        case "DO_NOT_PRESELECT":
          return;
        case "PRESELECT_ONE_SIZE":
          if (!isOneSizeProduct) {
            return;
          }
          handleSizeOptionSelection(sizeOptions[0], false);
          return;
        case "PRESELECT_FIRST_IN_STOCK":
          if (sizesInStock.length === 0) {
            handleSizeOptionSelection(sizeOptions[0], false);
            return;
          }

          handleSizeOptionSelection(sizesInStock[0], false);
          return;
        default:
          assertNever(preSelectSizeStrategy);
      }
    };

    const sizeWasSelected = preSelectSizeIfExisting();
    if (sizeWasSelected) {
      return;
    }

    preSelectSizeByStrategy();
  }, [preSelectedSizeCodeOrEan]);

  useEffect(() => {
    if (isLoggedIn === true && pendingRemindersToAdd.length > 0) {
      pendingRemindersToAdd.forEach((sizeOption) => {
        const { itemNumber, ean } = sizeOption;

        if (itemNumber === undefined || ean === undefined) {
          throw Error(
            `Properties are missing. ${typeof itemNumber}, ${typeof ean}`
          );
        }

        setProductsWithReminder([...productsWithReminder, ...[sizeOption]]);
        void postNotificationRequest(
          authApi,
          customersApi,
          siteUid,
          itemNumber,
          ean.toString()
        );
      });

      setPendingRemindersToAdd([]);
    }
  }, [
    authApi,
    customersApi,
    isLoggedIn,
    pendingRemindersToAdd,
    productsWithReminder,
    siteUid,
  ]);

  return (
    <>
      <SizeSelectButton
        disabled={disabled}
        displayText={displaySize}
        hasError={hasError}
        onClick={toggleOpen}
        open={isOpen}
        size={size}
      />
      <SizeSelectDrawer
        handleSizeOptionClick={handleSizeOptionSelection}
        hasReminder={hasReminder}
        isClickAndCollectEnabled={isClickAndCollectEnabled}
        isOpen={isOpen}
        onClose={toggleOpen}
        onRemindMeClick={onRemindMeClick}
        shouldBePossibleToAddReminder={shouldBePossibleToAddReminder}
        shouldBePossibleToSelectOutOfStock={shouldBePossibleToSelectOutOfStock}
        sizeOptions={sizeOptions}
        styleCode={styleCode}
        unit={isNotNullOrUndefined(unit) ? unit : undefined}
      />
    </>
  );
};

export type { PreSelectSizeStrategy };

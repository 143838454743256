import type { PropsWithChildren } from "react";
import { QueryClient, QueryClientProvider as QCP } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const FIVE_MINS = 5 * 60 * 1000;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: FIVE_MINS,
    },
  },
});

export const QueryClientProvider = ({ children }: PropsWithChildren) => (
  <QCP client={queryClient}>
    {children}
    <ReactQueryDevtools initialIsOpen={false} />
  </QCP>
);

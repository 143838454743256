import { isEmpty, isNotNullOrUndefined } from "@xxl/common-utils";
import React, { useRef, useState } from "react";
import { resolutionSizes } from "../../config";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { windowAccess } from "../../utils/Window";
import { CartServiceProduct } from "../Cart/Components/CartServiceProduct";
import { Overlay } from "../Cart/Styles/ProductItem.styled";
import { DialogBox } from "../DialogBox";
import { XXLLoader } from "../XXLLoader";
import { ExpandableDescription } from "../Common/ExpandableDescription";
import { GeneralErrorsList } from "./GeneralErrorsList";
import { ServiceProduct } from "./ServiceProduct";

import {
  Content,
  Footer,
  Header,
  ListWrapper,
  ServiceProductsList,
  ShadowBottom,
  ShadowTop,
  ShadowsContainer,
  Title,
  Wrapper,
} from "./ServiceProductsWrapper.styled";
import { UspBar } from "./UspBar";
import { INDEX_OF_RECOMMENDED_SERVICE } from "./constants";
import { useListShadowOnScroll } from "./hooks/useListShadowOnScroll";
import { DialogFooter, VariantServiceProductsList } from "./Variant/styles";
import { VariantServiceProduct } from "./Variant/VariantServiceProduct";
import { Dialog } from "./Variant/Dialog";
import { XxlStack } from "../Common/XxlStack";
import { Text } from "../Text";
import { XxlButton } from "../Common/XxlButton";
import { useServicesContext } from "./ServiceProductsContext";
import { SERVICE_CODE } from "../../utils/Symplify/constants";
import { useBreakpoint } from "../../hooks/useBreakpoint/useBreakpoint";
import type { TranslationKey } from "../../translations";

const SERVICE_ITEMS: { [key: string]: TranslationKey[] } = {
  [SERVICE_CODE.IMBOX]: [
    "services.popup.imbox.bullet.1",
    "services.popup.imbox.bullet.2",
    "services.popup.imbox.bullet.3",
    "services.popup.imbox.bullet.4",
    "services.popup.imbox.bullet.5",
    "services.popup.imbox.bullet.6",
  ],
  [SERVICE_CODE.BIKE]: [
    "services.popup.bike.bullet.1",
    "services.popup.bike.bullet.2",
    "services.popup.bike.bullet.3",
    "services.popup.bike.bullet.4",
    "services.popup.bike.bullet.5",
  ],
};

const DESCRTIPTION_TEXT = {
  [SERVICE_CODE.IMBOX]: "services.popup.imbox.description",
  [SERVICE_CODE.BIKE]: "services.popup.bike.description",
};

type BaseServiceProductWrapperProps = {
  actionInProgress: boolean;
  dialogBoxHeaderText: string;
  isModalOpen: boolean;
  onDialogBoxClose: () => void;
  renderFooterButtons: () => JSX.Element;
  setActionInProgress: (value: boolean) => void;
  category?: string;
  dialogBoxTestId?: string;
};

type ServiceProductsWrapperProps = BaseServiceProductWrapperProps & {
  showOverlayOnActionInProgress?: undefined;
};

type ServiceCartProductsWrapperProps = BaseServiceProductWrapperProps & {
  showOverlayOnActionInProgress?: boolean;
};

const ServiceProductsWrapper = ({
  category,
  onDialogBoxClose,
  renderFooterButtons,
  dialogBoxTestId,
  dialogBoxHeaderText,
  isModalOpen,
  actionInProgress,
  setActionInProgress,
  showOverlayOnActionInProgress,
}: ServiceProductsWrapperProps | ServiceCartProductsWrapperProps) => {
  const {
    _sharedData: { serviceDescriptions },
  } = windowAccess();
  const { t } = useTranslations();
  const bp = useBreakpoint();
  const isMobile = bp === "mobile";
  const listViewportRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { state } = useServicesContext();
  const { isBottomShadowVisible, isTopShadowVisible } = useListShadowOnScroll({
    listRef,
    listViewportRef,
    scrollPosition,
  });
  const descriptionObject = serviceDescriptions?.find(({ categories }) =>
    categories.includes(category ?? "")
  );
  const serviceGroupTitle =
    descriptionObject?.title ?? t("services.popup.category.title.default");
  const serviceGroupDescription =
    descriptionObject?.description ??
    t("services.popup.category.description.default");

  const SERVICE_CODES_ARR = Object.values(SERVICE_CODE) as string[];
  const productCodes = state.services?.productList.map((item) => item.code);
  const isImboxOrBikeServiceItem = SERVICE_CODES_ARR.some((code) =>
    productCodes?.includes(code)
  );

  if (
    isImboxOrBikeServiceItem &&
    state.services !== undefined &&
    !isEmpty(state.services.productList)
  ) {
    const item = state.services.productList[0];
    return (
      <Dialog
        open={isModalOpen}
        fullScreen={isMobile}
        maxWidth="xs"
        onClose={onDialogBoxClose}
        heading={dialogBoxHeaderText}
      >
        <XxlStack
          sx={{
            height: "100%",
            maxWidth: isMobile ? "100%" : "495px",
          }}
        >
          {isNotNullOrUndefined(state.generalErrors) && (
            <GeneralErrorsList errors={state.generalErrors} />
          )}
          <ListWrapper>
            {showOverlayOnActionInProgress === true && actionInProgress && (
              <Overlay>
                <XXLLoader />
              </Overlay>
            )}
            <XxlStack px={"12px"} py={"24px"}>
              <Text typography="baseBold">{serviceGroupTitle}</Text>
              <Text>
                {t(
                  DESCRTIPTION_TEXT[
                    item.code as keyof typeof DESCRTIPTION_TEXT
                  ] as TranslationKey
                )}
              </Text>
              {isNotNullOrUndefined(state.services) &&
                !Array.isArray(state.services) &&
                isNotNullOrUndefined(state.services.productList) && (
                  <VariantServiceProductsList>
                    <VariantServiceProduct
                      item={item}
                      actionInProgress={actionInProgress}
                      setActionInProgress={setActionInProgress}
                      onClose={onDialogBoxClose}
                      serviceTextList={
                        SERVICE_ITEMS[item.code as keyof typeof SERVICE_ITEMS]
                      }
                    />
                  </VariantServiceProductsList>
                )}
            </XxlStack>
          </ListWrapper>
        </XxlStack>

        <DialogFooter>
          <XxlButton
            variant="outlined"
            onClick={onDialogBoxClose}
            disabled={actionInProgress}
          >
            {t("sorts.close.button.text")}
          </XxlButton>
        </DialogFooter>
      </Dialog>
    );
  }

  return (
    <DialogBox
      isDialogBoxOpen={isModalOpen}
      hasPadding={false}
      dialogBoxSize={"md"}
      handleDialogBoxClosing={onDialogBoxClose}
      contentStyle={{ overflow: "hidden" }}
      testId={dialogBoxTestId}
    >
      <Header>{dialogBoxHeaderText}</Header>
      {isNotNullOrUndefined(state.generalErrors) && (
        <GeneralErrorsList errors={state.generalErrors} />
      )}
      <ListWrapper ref={listViewportRef}>
        {showOverlayOnActionInProgress === true && actionInProgress && (
          <Overlay>
            <XXLLoader />
          </Overlay>
        )}
        <ShadowsContainer>
          <ShadowTop isVisible={isTopShadowVisible} />
          <ShadowBottom isVisible={isBottomShadowVisible} />
        </ShadowsContainer>
        <Wrapper
          onScroll={({ target }) => {
            const { scrollTop } = target as HTMLDivElement;
            setScrollPosition(scrollTop);
          }}
        >
          <Content ref={listRef}>
            <Title>{serviceGroupTitle}</Title>

            <ExpandableDescription
              description={serviceGroupDescription}
              numberOfLinesToShow={2}
              minResolutionToStopClamping={resolutionSizes.mobile}
            />
            {isNotNullOrUndefined(state.services) &&
              !Array.isArray(state.services) &&
              isNotNullOrUndefined(state.services.productList) &&
              state.services.productList.length > 0 && (
                <ServiceProductsList
                  numberOfItems={state.services.productList.length}
                >
                  {state.services.productList.map((item, index) => (
                    <ServiceProduct
                      key={index}
                      item={item}
                      handleChange={setActionInProgress}
                      actionInProgress={actionInProgress}
                      isRecommended={index === INDEX_OF_RECOMMENDED_SERVICE}
                      category={category}
                    />
                  ))}
                </ServiceProductsList>
              )}
            {Array.isArray(state.services) && (
              <ServiceProductsList numberOfItems={state.services.length}>
                {state.services.map((item, index) => (
                  <CartServiceProduct
                    key={`${item.title}-${index}`}
                    item={item}
                    handleChange={setActionInProgress}
                    actionInProgress={actionInProgress}
                    isRecommended={index === INDEX_OF_RECOMMENDED_SERVICE}
                    category={category}
                  />
                ))}
              </ServiceProductsList>
            )}
          </Content>
        </Wrapper>
      </ListWrapper>
      <Footer>
        {renderFooterButtons()}
        <UspBar />
      </Footer>
    </DialogBox>
  );
};

export { ServiceProductsWrapper };

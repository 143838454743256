import React from "react";
import styled from "@emotion/styled/macro";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";

const { div, img } = styled;

const Wrapper = div`
  text-align: center;
`;

const Img = img`
  margin: 0 auto;
  max-width: 200px;
  height: auto;
`;

const XXLLoader: React.FunctionComponent = () => {
  const { t } = useTranslations();

  return (
    <Wrapper>
      <picture>
        <Img
          src="/static/images/XXL-loader.gif"
          alt={t("page.loading")}
          width={200}
          height={160}
        />
      </picture>
    </Wrapper>
  );
};

export { XXLLoader };

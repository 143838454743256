import type { FunctionComponent, PropsWithChildren } from "react";
import { MegaMenu as MobileMegaMenu } from "../MegaMenu/Mobile";
import styled from "@emotion/styled/macro";
import { xxlTheme } from "../../styles/xxl-theme";
import type { WrapperProps } from "./types";
import {
  DESKTOP_HEIGHT_WITH_HEADER_LINKS,
  MOBILE_HEIGHT_WITH_HEADER_LINKS,
} from "./constants";
import { css, useMediaQuery } from "@mui/material";
import { mobileAndTabletMediaQuery } from "../../utils/xxl-screen";
const { colors, zIndex } = xxlTheme;

type StyledHeaderProps = {
  isMobile: boolean;
};

export const StyledHeader = styled.header<StyledHeaderProps>(
  ({ isMobile }) => css`
    background-color: ${colors.xxlWebBlack};
    position: sticky;
    inset: 0;
    min-height: auto;
    max-height: ${isMobile
      ? MOBILE_HEIGHT_WITH_HEADER_LINKS
      : DESKTOP_HEIGHT_WITH_HEADER_LINKS}px;
    z-index: ${zIndex.header};
    transition: transform 0.15s ease-in-out;
    -webkit-transition: transform 0.15s ease-in-out;
    overflow: visible;

    &.header-hidden {
      pointer-events: none;
      transform: translateY(-100%);
    }

    &.header--non-sticky {
      position: relative;
    }

    &.header--fixed {
      position: fixed;
    }
  `
);

const Wrapper: FunctionComponent<
  PropsWithChildren & WrapperProps & { className: string }
> = ({
  children,
  campaignHubUrl,
  headerCode,
  headerLinks,
  megaMenuContent,
  className,
  isTeamsales,
}) => {
  const isMobile = useMediaQuery(mobileAndTabletMediaQuery);

  return (
    <StyledHeader
      className={className}
      id="js-sticky-header"
      data-testid="sticky-header"
      isMobile={isMobile}
    >
      {children}
      <MobileMegaMenu
        headerCode={headerCode}
        initialHeaderLinks={headerLinks}
        initialMenuData={megaMenuContent}
        isTeamsales={isTeamsales}
        campaignHubUrl={campaignHubUrl}
      />
    </StyledHeader>
  );
};

export { Wrapper };

import React from "react";
import { Button } from "../../../styled/Button.styled";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import {
  CustomerFoundIconWrapper,
  FormContainer,
  FormHeaderInModal,
  MailWithCheckIcon,
} from "./SignUpForm/SignupForm.styled";
import { DialogBoxHeaderWrapper } from "../../../components/DialogBox/DialogBox.styled";
import DialogCloseButton from "../SharedComponents/DialogBoxCloseButton";
import ReactHtmlParser from "html-react-parser";

type SignupPhone = {
  handleDialogBoxClosing: () => void;
};

const CustomerFound = ({ handleDialogBoxClosing }: SignupPhone) => {
  const { t } = useTranslations();

  return (
    <FormContainer inProgress={false}>
      <div>
        <DialogBoxHeaderWrapper>
          <FormHeaderInModal>
            {t("reward.success.account.create")}
          </FormHeaderInModal>

          <DialogCloseButton handleDialogBoxClosing={handleDialogBoxClosing} />
        </DialogBoxHeaderWrapper>
        <p>{ReactHtmlParser(t("reward.signup.customer.found"))}</p>
      </div>
      <CustomerFoundIconWrapper>
        <MailWithCheckIcon />
      </CustomerFoundIconWrapper>
      <Button
        id="reward-signup-form-submit"
        type="submit"
        onClick={handleDialogBoxClosing}
        inline={true}
        className="button button--primary button--small button--full-width"
      >
        {t("reward.signup.close")}
      </Button>
    </FormContainer>
  );
};

export { CustomerFound };

import styled from "@emotion/styled/macro";
import {
  interactionFeedback,
  typographyToCss,
} from "react-app/src/styles/helpers";
import { color } from "@xxl/theme";
import spaces from "react-app/src/styles/theme/spaces";

export const Link = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 100%;
  padding: 24px;
  height: 100%;
  box-sizing: border-box;
  background-color: ${color.lightGray.hex};

  ${interactionFeedback(`
    background-color: ${color.mediumGray.hex};
  `)}

  ${typographyToCss({
    fontSize: 26,
    lineHeight: 1,
    fontFamily: "var(--font-family-bold)",
    laptop: {
      fontSize: 34,
    },
  })}
`;

export const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: ${spaces.mini};
`;

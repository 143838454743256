import type { Trackers } from "../../contexts/Tracking";
import type { ProductImpressionTrackingData } from "../../utils/Tracking";

export type TrackProductImpressionProps = {
  listName: string;
  position: number;
  product: {
    brandName?: string;
    code?: string;
    googleCategory?: string;
    name?: string;
    style?: string;
    salesPrice?: number;
  };
  trackers: Trackers;
};

const trackProductImpression = ({
  listName: list,
  position,
  product,
  trackers,
}: TrackProductImpressionProps): void => {
  const variant = "style" in product ? product.style : undefined;
  const gtmProduct: ProductImpressionTrackingData = {
    name: product.name ?? "",
    id: product.code,
    price: product.salesPrice?.toString() ?? "",
    brand: product.brandName ?? "",
    category: product.googleCategory,
    variant,
    list,
    position,
  };
  trackers.sendProductImpressionsEvent({
    products: [gtmProduct],
  });
};

export { trackProductImpression };

import React, { useState, useEffect } from "react";
import { useStateValue } from "cotton-box-react";
import { FavoritesConfirmationSource } from "./MiniFavoritesState";
import {
  ConfirmationBar,
  ConfirmationContent,
  ConfirmationLink,
  ConfirmationText,
} from "./Favorites.styled";
import { CaretRight, Favorites } from "@xxl/icons";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { useSharedData } from "../../contexts/SharedData";
import {
  FAVORITES_TRACKING_KEY,
  FavoritesConfirmationEnum,
} from "./FavoritesState";
import { useTracking } from "../../contexts/Tracking";

export const FavoritesConfirmation: React.FunctionComponent = () => {
  const confirmationStatus = useStateValue(FavoritesConfirmationSource);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const { t } = useTranslations();
  const {
    data: {
      requestMapping: {
        account,
        myAccount: { favorites },
      },
      pageType,
    },
  } = useSharedData();
  const tracking = useTracking();
  const list = `${pageType}_confirmation_banner_${FAVORITES_TRACKING_KEY}`;

  const trackBannerClickHandler = () => {
    tracking.sendFavoritesConfirmationClickEvent({
      list,
    });
  };

  useEffect(() => {
    setShowConfirmation(
      confirmationStatus === FavoritesConfirmationEnum.Added ||
        confirmationStatus === FavoritesConfirmationEnum.Removed
    );
  }, [confirmationStatus]);

  return showConfirmation ? (
    <ConfirmationBar>
      <ConfirmationContent className="container">
        <ConfirmationText>
          <Favorites />
          {confirmationStatus === FavoritesConfirmationEnum.Added
            ? t("favorites.added.to")
            : t("favorites.removed.from")}
        </ConfirmationText>
        <ConfirmationLink
          href={`${account}${favorites}`}
          aria-label={t("account.my.favorites")}
          onClick={trackBannerClickHandler}
        >
          {t("favorites.see.favorites")}
          <CaretRight />
        </ConfirmationLink>
      </ConfirmationContent>
    </ConfirmationBar>
  ) : null;
};

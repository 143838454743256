import type { FormikErrors } from "formik";
import {
  SignUpFormLabel,
  SignUpFormInput,
  SignUpErrorMessage,
  InputContainer,
} from "../../Reward/SignUp/SignUpForm/SignupForm.styled";
import { xxlTheme } from "../../../styles/xxl-theme";
import React from "react";

type FormInputProps = {
  fieldName: string;
  id?: string;
  label?: string;
  required: boolean;
  autocompleteToken?: string;
  inputMode?: "email" | "tel" | "text" | "date" | "select";
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  type: string;
  value: string;
  errors: Record<string, string>;
  translatedServerErrors: Record<string, string>;
  touched?: boolean;
  style?: React.CSSProperties;
};

type ErrorMessageProps = {
  errors: FormikErrors<Record<string, unknown>>;
  fieldName: string;
  isTouched: boolean;
  translatedServerErrors: Record<string, string>;
};

const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = ({
  errors,
  fieldName,
  isTouched,
  translatedServerErrors,
}) => {
  if (errors[fieldName] !== undefined && isTouched) {
    return <SignUpErrorMessage>{errors[fieldName]}</SignUpErrorMessage>;
  }

  if (translatedServerErrors[fieldName] !== undefined && !isTouched) {
    return (
      <SignUpErrorMessage color={xxlTheme.colors.xxlRed}>
        {translatedServerErrors[fieldName]}
      </SignUpErrorMessage>
    );
  }
  return null;
};

const FormInput = ({
  fieldName,
  id,
  label,
  required,
  autocompleteToken,
  inputMode,
  onBlur,
  onChange,
  placeholder,
  value,
  errors,
  translatedServerErrors,
  touched = false,
  style,
}: FormInputProps) => {
  return (
    <InputContainer style={style} key={fieldName}>
      <SignUpFormLabel htmlFor={id} required={required}>
        {label}
      </SignUpFormLabel>
      <SignUpFormInput
        autoComplete={autocompleteToken}
        id={id}
        data-testid={id}
        inputMode={inputMode}
        name={fieldName}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        type={inputMode}
        value={value}
      />
      {
        <ErrorMessage
          {...{ fieldName, errors, translatedServerErrors }}
          isTouched={touched}
        />
      }
    </InputContainer>
  );
};

export { FormInput, ErrorMessage };

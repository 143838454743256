import { type MouseEvent } from "react";
import { Text } from "react-app/src/components/Text";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import {
  SlideLink,
  StyledImage,
  ImageContainer,
  ImageStrikeThrough,
} from "./ProductStyleSelector.styles";
import { IMAGE_SIZE } from "../constants";
import { Slider } from "react-app/src/components/Common/Slider/Slider";
import type { Style } from "./types";
import { useWindowDimensions } from "react-app/src/hooks/useWindowDimensions";

const ZERO = 0;

const toFixedNumber = (n: number, fractionDigits = 2) =>
  Number(n.toFixed(fractionDigits));

const createStylesConfig = (viewportWidth: number, count: number) => {
  const ITEM_WIDTH = 50;
  const ITEM_GAP = 10;
  const PAGE_PADDING_X = 12 * 2;
  const GAP_OFFSET = 1;
  // calculating is slider needed
  const totalGapNeeded = ITEM_GAP * (count - GAP_OFFSET);
  const totalItemSpaceNeeded = count * ITEM_WIDTH;
  const totalUsableSpace = viewportWidth - PAGE_PADDING_X;
  const calculatedSpaceNeeded = totalItemSpaceNeeded + totalGapNeeded;
  const isSliderNeeded = calculatedSpaceNeeded > totalUsableSpace;

  // calculating how many items can fit per view
  const calculatedCount = toFixedNumber(totalUsableSpace / ITEM_WIDTH);
  const calculatedInViewGapTotal = toFixedNumber(
    (calculatedCount - GAP_OFFSET) * ITEM_GAP
  );
  const perViewTotal = toFixedNumber(
    (totalUsableSpace - calculatedInViewGapTotal) / ITEM_WIDTH
  );

  return [isSliderNeeded, perViewTotal] as const;
};

type Props = {
  styles: Style[];
  onClick: ({
    code,
    event,
  }: {
    code: string;
    event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>;
  }) => void;
};

const StyleItem = ({
  style,
  onClick,
}: {
  style: Style;
  onClick: Props["onClick"];
}) => {
  const {
    code,
    primaryImage,
    name,
    colourDataName,
    isInStock,
    url,
    selected = false,
  } = style;
  return (
    <SlideLink
      href={url}
      disabled={!isInStock}
      onClick={(event) => {
        onClick({ code, event });
      }}
    >
      <ImageContainer isSelected={selected} isInStock={isInStock}>
        <StyledImage
          src={primaryImage}
          priority={true}
          height={IMAGE_SIZE.STYLE}
          width={IMAGE_SIZE.STYLE}
          alt={`${colourDataName}-${name}`}
        />
        {!isInStock && <ImageStrikeThrough />}
      </ImageContainer>
      <Text
        typography={selected ? "tinyBold" : "tinyMedium"}
        color={!isInStock ? "darkGray" : "webBlack"}
        strikethrough={!isInStock}
      >
        {colourDataName}
      </Text>
    </SlideLink>
  );
};

export const ProductStyleSelectorComponent = ({ onClick, styles }: Props) => {
  const wd = useWindowDimensions();
  if (wd.x === undefined) {
    return null;
  }

  const [shouldShowSlider, perView] = createStylesConfig(wd.x, styles.length);

  if (!shouldShowSlider) {
    return (
      <XxlStack direction="row" flexWrap={"wrap"} gap={"10px"} rowGap={"10px"}>
        {styles.map((style) => (
          <StyleItem key={style.pk} style={style} onClick={onClick} />
        ))}
      </XxlStack>
    );
  }

  return (
    <Slider
      items={styles.map((style) => (
        <StyleItem key={style.pk} style={style} onClick={onClick} />
      ))}
      slidesConfig={{
        perView,
        spacing: ZERO,
      }}
    />
  );
};

import { AutomatedRibbon } from "@/react-components/Product/ProductBadge/AutomatedRibbon";
import { NewRibbon } from "react-app/src/components/Product/NewRibbon/NewRibbon";
import { useXxlMediaQuery } from "react-app/src/hooks/useXxlMediaQuery";
import type {
  ProductImageSliderContainerProps,
  ProductImageSliderProps,
} from "../types";
import { BadgeWrapper, DiscountPriceWrapper } from "./ProductImage.styles";
import { ProductImageSliderDesktop } from "./ProductImageSliderDesktop";
import { ProductImageSliderMobile } from "./ProductImageSliderMobile";

const ImageSliderMap = {
  desktop: ProductImageSliderDesktop,
  mobile: ProductImageSliderMobile,
} as const;

const ImageSliderComponent = (props: ProductImageSliderProps) => {
  const isDesktopSize = useXxlMediaQuery("LaptopMediaQuery");
  const Component = ImageSliderMap[isDesktopSize ? "desktop" : "mobile"];
  return <Component {...props}>{props.children}</Component>;
};

export const ProductImageSlider = ({
  campaignComponent,
  productImages = [],
  bannerData,
  badges,
  shouldShowDesktopThumbnails,
  LastPlaceComponent,
  productName,
}: ProductImageSliderContainerProps) => {
  const hasDiscountBanner = bannerData.ribbonPrice !== "";

  return (
    <ImageSliderComponent
      campaignComponent={campaignComponent}
      productImages={productImages}
      productName={productName}
      shouldShowDesktopThumbnails={shouldShowDesktopThumbnails}
      LastPlaceComponent={LastPlaceComponent}
    >
      {badges.length > 0 && (
        <BadgeWrapper>
          {badges.map((badge) => (
            <AutomatedRibbon badgeType={badge} key={badge} />
          ))}
        </BadgeWrapper>
      )}

      {hasDiscountBanner && (
        <DiscountPriceWrapper>
          <NewRibbon
            label={bannerData.ribbonPrice}
            direction="left"
            colorTheme={{
              backgroundColour: bannerData.colorTheme?.backgroundColor,
              foregroundColour: bannerData.colorTheme?.foregroundColor,
              name: bannerData.colorTheme?.name,
            }}
          />
        </DiscountPriceWrapper>
      )}
    </ImageSliderComponent>
  );
};

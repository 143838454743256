import { log } from "@xxl/logging-utils";

export const productKeysToLandingPageQuery = (productKeys: string[]) => {
  try {
    productKeys.forEach((key) => {
      if (key.length !== 7) {
        throw Error(`Product key must be 7 digits. Key: ${key}`);
      }
    });

    return productKeys.toString().replaceAll(",", "+");
  } catch (error) {
    log.error(error);
    return null;
  }
};

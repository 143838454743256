import styled from "@emotion/styled/macro";
import { xxlTheme } from "../../styles/xxl-theme";
import { Button } from "../../styled";

const { colors, typography, spaces } = xxlTheme;
import { MQ } from "../../styles/helpers";

export const DescriptionContentWrapper = styled.div`
  margin-bottom: ${spaces.big};
`;

export const FieldSet = styled.fieldset`
  margin: 0;
  padding: 0;
  border: 0;

  display: grid;
  grid-gap: 0px;
  grid-template-rows: auto auto;
  grid-template-columns: auto;

  ${MQ("tablet")} {
    grid-template-columns: 45% 30% 1fr;
    grid-gap: ${spaces.smallLarge};
  }
`;

export const ButtonWrapper = styled.div`
  padding-top: ${spaces.regular};
`;

export const StyledButton = styled(Button)`
  white-space: nowrap;
  justify-content: center;

  padding-top: ${spaces.smallRegular};
  padding-bottom: ${spaces.smallRegular};
`;

export const GiftCard = styled.div`
  display: grid;
  background: ${colors.xxlBlack};
  padding: ${spaces.large};
  width: auto;
  height: 225px;
  border-radius: ${spaces.smallLarge};
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;

  &&& * {
    color: ${colors.xxlWhite};
  }

  ${MQ("smallTablet")} {
    width: 330px;
  }
`;

export const CardName = styled.div`
  ${typography.mediumRegular};
`;

export const CardLogo = styled.div`
  ${typography.bigBold};
  display: flex;
  justify-content: end;
  &&& {
    color: ${colors.xxlGreen};
  }
  font-size: ${spaces.huge};
  line-height: ${spaces.huge};
`;

export const CardBalance = styled.div`
  ${typography.mediumRegular};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const CardAmount = styled.span`
  ${typography.bigBold};
  font-size: ${spaces.big};
  height: ${spaces.big};
`;

export const CardExpDate = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  ${typography.mediumRegular};
`;

export const CardExpDescription = styled.span`
  ${typography.mediumRegular};
  &&& {
    color: ${colors.xxlGrey};
  }
  padding-right: ${spaces.micro};
`;

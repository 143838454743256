import React from "react";
import { StyledHeader } from "./Wrapper";
import { useMediaQuery } from "@mui/material";
import { mobileAndTabletMediaQuery } from "../../utils/xxl-screen";
import { XxlLogoLink } from "./XxlLogoLink";
import type { HeaderConfig } from "../../global";
import { useClient } from "../../hooks/useClient/useClient";
import {
  BackButton,
  BackButtonWrapper,
  LogoWrapper,
  Wrapper,
} from "./LimitedHeader.styled";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { ArrowLeft } from "@xxl/icons";

type LimitedHeaderProps = {
  headerConfig: HeaderConfig;
};

// It's used in next-app
// eslint-disable-next-line import/no-unused-modules
export const LimitedHeader = ({ headerConfig }: LimitedHeaderProps) => {
  const isMobile = useMediaQuery(mobileAndTabletMediaQuery);
  const isClient = useClient();
  const { t } = useTranslations();

  const toggleClick = () => {
    history.back();
  };

  const { logoUrl, logoAlt } = headerConfig;

  return (
    <StyledHeader isMobile={isMobile}>
      <Wrapper className="container">
        {isClient && (
          <BackButtonWrapper>
            <BackButton onClick={toggleClick} aria-label={t("general.back")}>
              <ArrowLeft />
            </BackButton>
          </BackButtonWrapper>
        )}
        <LogoWrapper>
          <XxlLogoLink logoUrl={logoUrl} logoAlt={logoAlt} />
        </LogoWrapper>
      </Wrapper>
    </StyledHeader>
  );
};

import styled from "@emotion/styled/macro";
import type { FunctionComponent } from "react";
import type { HeaderLink } from "../../global";
import { xxlTheme } from "../../styles/xxl-theme";
import { Link } from "./Header.styled";

const { spaces } = xxlTheme;

const List = styled.ul`
  display: flex;
  gap: ${spaces.regular};
  margin: 0;
  padding: 0;
`;

const HeaderLinks: FunctionComponent<{ links: HeaderLink[] }> = ({ links }) => (
  <List>
    {links.map(({ displayName, url }) => (
      <li key={url}>
        <Link href={url}>{displayName}</Link>
      </li>
    ))}
  </List>
);

export { HeaderLinks };

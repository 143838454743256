import * as React from "react";
import { Loader } from "@xxl/icons";

type LoadingCircleProps = {
  isLoading?: boolean;
  color?: string;
  size?: string;
};
export const LoadingCircle: React.FunctionComponent<LoadingCircleProps> = ({
  color = "var(--xxl-color-black)",
  isLoading = true,
  size = 18,
}): JSX.Element | null => {
  return isLoading ? (
    <Loader fontSize={size} color={color} data-testid="loader-icon" />
  ) : null;
};

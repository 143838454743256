import type { FunctionComponent, PropsWithChildren, ReactNode } from "react";
import React from "react";
import { CSSTransition } from "react-transition-group";

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
  unmounted: null,
};

const Fade: FunctionComponent<PropsWithChildren<{ in: boolean }>> = ({
  in: inProp,
  children,
}) => (
  <CSSTransition in={inProp} timeout={duration} mountOnEnter={true}>
    {(state): ReactNode => (
      <div
        style={{
          ...defaultStyle,
          ...transitionStyles[state],
        }}
      >
        {children}
      </div>
    )}
  </CSSTransition>
);

export { Fade };

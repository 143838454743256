import type { CartItemIdInput } from "../../generated/graphql-code-generator";
import isEqual from "lodash/isEqual";

export const normalizeProductName = (inputString: string): string => {
  const illegalCharsRegex = /["'#]/g;
  return inputString.replace(illegalCharsRegex, "");
};

export type CartItemProps = {
  itemId: CartItemIdInput;
  ean: string;
  serviceProducts:
    | {
        ean: string;
        itemId: CartItemIdInput;
        quantity: { quantity: number };
      }[]
    | null;
};

export const getAddedServiceId = (
  items: CartItemProps[] | undefined,
  ean: string[] | undefined,
  parentItemId: CartItemIdInput | undefined
): number | false => {
  if (items !== undefined && ean !== undefined && parentItemId !== undefined) {
    const parentItem = items.find((item) => isEqual(item.itemId, parentItemId));
    if (parentItem !== undefined && parentItem.serviceProducts !== null) {
      const addedService = parentItem.serviceProducts.find(
        (item) => item.ean === ean[0]
      );

      return addedService?.itemId.id ?? false;
    }
  }
  return false;
};

export const getAddedServiceItemId = (
  items: CartItemProps[] | undefined,
  ean: string[] | undefined,
  parentItemId: CartItemIdInput | undefined
): CartItemIdInput | false => {
  if (items !== undefined && ean !== undefined && parentItemId !== undefined) {
    const parentItem = items.find((item) => isEqual(item.itemId, parentItemId));
    if (parentItem !== undefined && parentItem.serviceProducts !== null) {
      const addedService = parentItem.serviceProducts.find(
        (item) => item.ean === ean[0]
      );

      return addedService?.itemId ?? false;
    }
  }
  return false;
};

export const getAddedServiceQuantity = (
  items: CartItemProps[] | undefined,
  ean: string[] | undefined,
  parentItemId: CartItemIdInput | undefined
): number => {
  if (items !== undefined && ean !== undefined && parentItemId !== undefined) {
    const parentItem = items.find((item) => isEqual(item.itemId, parentItemId));
    if (parentItem !== undefined && parentItem.serviceProducts !== null) {
      const addedService = parentItem.serviceProducts.find(
        (item) => item.ean === ean[0]
      );

      return addedService?.quantity.quantity ?? 0;
    }
  }
  return 0;
};

import React from "react";
import type { FooterLinkList } from "../../../utils/apis/content-footer-api";
import { List, ListItem } from "./LinkList.styled";
import { FooterColumnHeading } from "../SiteFooter.styled";

type LinkListProps = {
  linkList: FooterLinkList;
};
const LinkList: React.FunctionComponent<LinkListProps> = ({ linkList }) => {
  return (
    <>
      <FooterColumnHeading fontFamily="bold">
        {linkList.title}
      </FooterColumnHeading>
      <List>
        {linkList.list.map((listItem, index) => {
          const { url } = listItem;

          if (url === undefined) {
            return null;
          }

          return (
            <ListItem key={index}>
              <a href={listItem.url}>{listItem.displayName}</a>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export { LinkList };

import React from "react";
import { SkeletonWrapper } from "react-app/src/components/Common/SkeletonWrapper";
import {
  StockStatusIndicator,
  StockStatusIndicatorWrapper,
  StyledText,
} from "./ProductSizeSelector.styled";
import { type StockLevel } from "react-app/src/components/ClickAndCollect/api/api";

const ID = "size-select-drawer-availability-online";

export const Availability = ({
  isLoading,
  stockStatus,
  text,
}: {
  isLoading: boolean;
  stockStatus: StockLevel;
  text: string;
}) => (
  <SkeletonWrapper isLoading={isLoading}>
    <StockStatusIndicatorWrapper>
      <StockStatusIndicator stockStatus={stockStatus} />
      <StyledText
        size={"small"}
        data-testid={ID}
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </StockStatusIndicatorWrapper>
  </SkeletonWrapper>
);

import type { StackProps as MuiStackProps } from "@mui/material";
import { Stack as MuiStack } from "@mui/material";
import React, { forwardRef } from "react";
import { xxlTheme } from "../../../../src/styles/xxl-theme";

const { spaces } = xxlTheme;
type XxlSpaces = (typeof spaces)[keyof typeof spaces];
type Props = MuiStackProps & {
  spacing?: XxlSpaces;
  margin?: XxlSpaces;
  padding?: XxlSpaces;
  mt?: XxlSpaces;
  mb?: XxlSpaces;
  ml?: XxlSpaces;
  mr?: XxlSpaces;
  mx?: XxlSpaces;
  my?: XxlSpaces;
  px?: XxlSpaces;
  py?: XxlSpaces;
  pt?: XxlSpaces;
  pb?: XxlSpaces;
  pl?: XxlSpaces;
  pr?: XxlSpaces;
};

/**
 * A custom Stack component that extends MuiStack from Material-UI.
 * It allows for additional spacing, margin, and padding props using XXL theme values.
 * @return {JSX.Element} - The JSX element representing the XxlStack component.
 * @example
 * <XxlStack spacing="2px">items</XxlStack>
 * <XxlStack margin={xxlTheme.spaces.line}>items</XxlStack>
 */
const XxlStack = forwardRef<HTMLDivElement, Props>(
  ({ children, ...props }, ref) => {
    return (
      <MuiStack ref={ref} {...props}>
        {children}
      </MuiStack>
    );
  }
);

XxlStack.displayName = "XxlStack";

export { XxlStack };

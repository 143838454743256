import styled from "@emotion/styled/macro";
import { OtherPrice } from "react-app/src/components/PriceDisplays/PriceDisplays.styled";
import type { MediaQueryTypography } from "react-app/src/styles/helpers";
import {
  MQ,
  interactionFeedback,
  typographyToCss,
} from "react-app/src/styles/helpers";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

const defaultFontColor = xxlTheme.colors.xxlWebBlack;
const discountFontColor = xxlTheme.colors.xxlRed;
const labelText: MediaQueryTypography = {
  fontSize: 15,
  lineHeight: 1,
};
const lowestPricelabelText: MediaQueryTypography = {
  fontSize: 12,
  lineHeight: 1,
};

const StyledDisplayPrice = styled.span`
  ${typographyToCss({
    fontSize: 26,
    lineHeight: 1,
    fontFamily: "var(--font-family-bold)",
  })}

  ${MQ("laptop")} {
    ${typographyToCss({
      fontSize: 34,
      lineHeight: 1,
      fontFamily: "var(--font-family-bold)",
    })}
  }
`;

export const SalesPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const SalesPrice = styled(StyledDisplayPrice)<{
  isDiscounted: boolean;
  hasLineThrough?: boolean;
}>`
  margin: 0;
  color: ${({ isDiscounted }) =>
    isDiscounted ? discountFontColor : defaultFontColor};
`;

export const Label = styled.p`
  margin: 0;
  ${typographyToCss(labelText)}
`;

export const LowestPriceLabel = styled.p`
  margin: 0;
  ${typographyToCss(lowestPricelabelText)}
`;

export const LowestPrice = styled.span`
  text-decoration: line-through;
  ${typographyToCss(lowestPricelabelText)}
`;

export const SecondaryPrice = styled(OtherPrice)`
  ${typographyToCss(labelText)}
`;

export const PriceButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  background: none;

  ${interactionFeedback(`
    cursor: pointer;
  `)};
`;

import styled from "@emotion/styled/macro";
import { hasValue, isNotNullOrUndefined } from "@xxl/common-utils";
import { log } from "@xxl/logging-utils";
import { useStateValue } from "cotton-box-react";
import isEmpty from "lodash/isEmpty";
import noop from "lodash/noop";
import type { MouseEvent } from "react";
import React, { useEffect, useState } from "react";
import { DialogBox } from "../../../components/DialogBox";
import type { AccountResponse } from "../../../components/UserDetails/UserAPI";
import { accountQuery } from "../../../components/UserDetails/UserAPI";
import { ACCOUNT_BASE_PATH, MISSING_ACCOUNT_DATA } from "../../../constants";
import {
  useModalsStatusSource,
  useSessionSource,
} from "../../../contexts/Session";
import { useSharedData } from "../../../contexts/SharedData";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { callGraphQL } from "../../../graphql/graphqlApi";
import { MQ } from "../../../styles/helpers";
import * as cookies from "../../../utils/Cookie";
import { setStorageItem } from "../../../utils/LocalStorage/local-storage";
import { logout } from "../../../utils/Logout/logout";
import * as XxlEvent from "../../../utils/xxl-event";
import {
  ViewState,
  XXL_SIGNUP_OPEN,
  dispatchLoginEvent,
} from "../../../utils/xxl-event";
import {
  isCartPage,
  isProductPage,
  isTeamsalesClubPage,
} from "../../../utils/xxl-page-type";
import { getLoginImageUrl } from "../../../utils/xxl-shared-data";
import { removeQueryParamFromUrl } from "../../../utils/xxl-url";
import { saveFavoritesToLocalStorage } from "../../Favorites/Favorites.helper";
import { UpdateAccountForm } from "../../UserDetails/PersonalInfoModal/updateAccountForm";
import { CallToAction } from "../SharedComponents/CallToAction";
import { SignUpForm } from "../SignUp/SignUpForm/SignUpForm";
import { SignUpSuccess } from "../SignUp/SignUpSuccess/SignUpSuccess";
import { ForgotPasswordForm } from "./ForgotPassword/ForgotPasswordForm";
import { needToCompleteProfile } from "./Login.helper";
import { LoginForm, getMissingFields } from "./LoginForm";
import { OTPInitializeForm } from "./OTPLogin/OTPInitializeForm";
import type { OTPData } from "./OTPLogin/OTPLoginHelper";
import { OTPLoginSuccess } from "./OTPLogin/OTPLoginSuccess";
import { OTPResponseForm } from "./OTPLogin/OTPResponseForm";
import type { CallToActionProps } from "./ReviewLoginCallToAction";

const maxWidthInPx = 960;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${maxWidthInPx}px;
  ${MQ("tablet")} {
    flex-direction: row;
  }
`;

export type LoginProps = {
  setShowLogin?:
    | React.Dispatch<React.SetStateAction<boolean>>
    | ((value: boolean) => void);
  initialViewState?: ViewState;
  afterLoginComplete?: () => void;
  afterMissingDataComplete?: () => void;
  CustomCallToAction?: React.FunctionComponent<CallToActionProps>;
};

export type UserDataProps = {
  email: string;
  password: string;
  firstName?: string;
  lastName?: string;
  mobilePhone?: string;
  socialSecurityNumber?: string;
  consents?:
    | {
        value: boolean;
      }[]
    | null;
};

export const Login = ({
  setShowLogin = noop,
  initialViewState = ViewState.LOGIN,
  afterLoginComplete,
  afterMissingDataComplete = noop,
  CustomCallToAction,
}: LoginProps) => {
  const [state, setState] = useState(initialViewState);
  const [isOpen, setIsOpen] = useState(true);
  const backgroundUrl = getLoginImageUrl() ?? "";
  const { isReactApp, data } = useSharedData();
  const isLoggedIn = useStateValue(useSessionSource);
  const { isTeamsales, configuration, featureToggles } = data;
  const { toggle_social_security_number, toggle_triggerbee } = featureToggles;
  const { t } = useTranslations();
  const [userData, setUserData] = useState<UserDataProps | undefined>(
    undefined
  );
  const [OTPData, setOTPData] = useState<OTPData | null>(null);

  const onOTPChallengeRequest = (values: OTPData) => {
    setOTPData(values);
    setState(ViewState.OTP_RESPONSE);
  };

  const setModalVisible = (newIsOpen: boolean) => {
    setIsOpen(newIsOpen);
    if (typeof setShowLogin === "function") {
      setShowLogin(newIsOpen);
    }
  };

  const setUserLoggedIn = async () => {
    await fetch("/cookie/xxl");
    useSessionSource.set(true);
  };

  const handleDialogBoxClosing = () => {
    if (hasValue(localStorage.getItem(MISSING_ACCOUNT_DATA))) {
      log.info("logout on close (MISSING_ACCOUNT_DATA in localStorage)");
      logout();
    }
    setModalVisible(false);
  };
  const afterSuccessfulLogin = async (keepModalVisible = false) => {
    await setUserLoggedIn();
    if (isTeamsales) {
      isTeamsalesClubPage() && needToCompleteProfile()
        ? window.Account.getMoreUserInfo()
        : window.location.replace(ACCOUNT_BASE_PATH);
    } else {
      if (
        (isCartPage() || isProductPage()) &&
        window.Cypress === undefined &&
        typeof afterLoginComplete === "undefined"
      ) {
        window.location.reload();
      }
      if (isReactApp) {
        window.Login.userLoginCheck(true);
      } else {
        const cookie = await cookies.getXXLCookie();
        const { loggedIn, username = "", userGroups = "" } = cookie ?? {};
        if (loggedIn === true) {
          dispatchLoginEvent(username, userGroups);
        }
      }
      removeQueryParamFromUrl("login");
    }
    setModalVisible(keepModalVisible);
    isNotNullOrUndefined(afterLoginComplete) && afterLoginComplete();
  };

  const onSuccessfulLogin = async () => {
    try {
      const accountRes = await callGraphQL<AccountResponse>(
        { query: accountQuery },
        configuration.amplifyConfig.aws_appsync_graphqlEndpoint
      );
      const account = accountRes.data?.account ?? null;
      const usersFavorites = account?.favorites ?? null;
      const {
        memberNumber,
        socialSecurityNumber,
        email,
        firstName,
        lastName,
        mobilePhone,
      } = account ?? {};

      const missingFields = getMissingFields(
        account,
        toggle_social_security_number
      );

      if (
        toggle_triggerbee &&
        typeof window !== "undefined" &&
        window.mtr !== undefined &&
        email !== undefined
      ) {
        window.mtr_custom = { session: { email }, async: 1 };
        window.mtr.goal("Logged in");
      }

      if (
        typeof window !== "undefined" &&
        window.mtr !== undefined &&
        email !== undefined
      ) {
        window.addEventListener("afterTriggerbeeReady", () => {
          window.mtr_custom.session.email = email;
          window.mtr?.goal("Logget inn");
        });
      }

      if ((isEmpty(memberNumber) || !isEmpty(missingFields)) && !isTeamsales) {
        setUserData({
          email: email ?? "",
          password: "",
          firstName,
          lastName,
          socialSecurityNumber,
          mobilePhone,
        });
        setState(ViewState.MISSING_USER_DATA);
        setStorageItem(MISSING_ACCOUNT_DATA, "true");
        await setUserLoggedIn();

        // set hadCompleteData: false to prevent onboarding modal from showing until data is completed
        useModalsStatusSource.set((prevState) => ({
          ...prevState,
          isCompleteDataFormVisible: true,
        }));
        if (isReactApp) {
          window.Login.userLoginCheck(true);
        } else {
          const cookie = await cookies.getXXLCookie();
          const { username, userGroups } = cookie ?? {};
          if (username !== undefined && userGroups !== undefined) {
            dispatchLoginEvent(username, userGroups);
          }
        }
        return;
      }

      saveFavoritesToLocalStorage(usersFavorites);

      void afterSuccessfulLogin();
    } catch (err) {
      log.error(err);
    }
  };

  const onMissingMemberDataError = ({
    email,
    password,
    firstName,
    lastName,
  }: UserDataProps) => {
    setUserData({
      email,
      password,
      firstName,
      lastName,
    });
    setState(ViewState.MISSING_VOYADO_ACCOUNT);
    useModalsStatusSource.set((prevState) => ({
      ...prevState,
      isCompleteDataFormVisible: true,
    }));
  };

  const handleSuccessSignUp = () => {
    setState(ViewState.SUCCESS_SIGN_UP);
    useModalsStatusSource.set((prevState) => ({
      ...prevState,
      isCompleteDataFormVisible: false,
    }));
  };

  useEffect(() => {
    const activateLoginState = () => {
      setModalVisible(false);
    };

    if (state === ViewState.REGISTER) {
      activateLoginState();
      XxlEvent.dispatchEvent(XXL_SIGNUP_OPEN);
      setState(ViewState.LOGIN);
    }
  }, [state]);

  useEffect(() => {
    const onLoginClick = () => setIsOpen(true);
    XxlEvent.addEventListener(XxlEvent.type.XXL_LOGIN_CLICK, onLoginClick);

    return () => {
      XxlEvent.removeEventListener(XxlEvent.type.XXL_LOGIN_CLICK, onLoginClick);
    };
  }, []);

  const handleClickRegister = (event: MouseEvent) => {
    event.preventDefault();
    setState(ViewState.REGISTER);
  };

  const CallToActionComponent = CustomCallToAction ?? CallToAction;
  return (
    <DialogBox
      dialogBoxSize={"md"}
      isDialogBoxOpen={isOpen}
      hideDefaultCloseButton={true}
      handleDialogBoxClosing={handleDialogBoxClosing}
      hasPadding={false}
      paperProps={{
        sx: {
          maxWidth: maxWidthInPx,
        },
      }}
    >
      <Container>
        <CallToActionComponent {...{ backgroundUrl }} />

        {state === ViewState.LOGIN && (
          <LoginForm
            onSuccessfulLogin={onSuccessfulLogin}
            onClickForgotPassword={() => setState(ViewState.FORGOT_PASSWORD)}
            onClickRegister={handleClickRegister}
            handleDialogBoxClosing={handleDialogBoxClosing}
            onIncompleteAccount={onMissingMemberDataError}
            goToOTP={() => setState(ViewState.OTP_INITIALIZE)}
          />
        )}
        {state === ViewState.MISSING_VOYADO_ACCOUNT && (
          <SignUpForm
            isInModal={true}
            signUpTranslations={{
              formHeader: t("reward.success.account.create"),
              terms: t("reward.signup.terms.label"),
              register: t("reward.signup.register"),
              existing: t("reward.success.account.existing"),
              buttonText: t("login.form.login.button"),
            }}
            successfulSignUpCallback={handleSuccessSignUp}
            descriptionTextComponent={
              <div>{t("login.form.error.account.not.exists")}</div>
            }
            userData={userData}
            shouldHideUsernameAndPasswordField={true}
            shouldShowLoginInformation={false}
            shouldShowFilledNameInputs={true}
          />
        )}
        {state === ViewState.MISSING_USER_DATA && (
          <UpdateAccountForm
            userData={userData}
            afterUpdate={afterMissingDataComplete}
            handleDialogBoxClosing={handleDialogBoxClosing}
          />
        )}
        {state === ViewState.OTP_INITIALIZE && (
          <OTPInitializeForm
            initialPhoneNumber={OTPData?.phoneNumber}
            handleDialogBoxClosing={handleDialogBoxClosing}
            onSubmitSuccess={onOTPChallengeRequest}
            goToSignup={handleClickRegister}
          />
        )}
        {state === ViewState.OTP_RESPONSE && isNotNullOrUndefined(OTPData) && (
          <OTPResponseForm
            handleDialogBoxClosing={handleDialogBoxClosing}
            onSubmitSuccess={() => setState(ViewState.SUCCESS_OTP)}
            onOTPResend={() => setState(ViewState.OTP_INITIALIZE)}
            OTPData={OTPData}
          />
        )}
        {state === ViewState.SUCCESS_OTP && (
          <OTPLoginSuccess handleDialogBoxClosing={handleDialogBoxClosing} />
        )}
        {state === ViewState.SUCCESS_SIGN_UP && (
          <SignUpSuccess
            signUpSuccessTranslations={{
              header: t("reward.success.header"),
              info: t("reward.success.info"),
              buttonText: t("club.register.form.button.xxlpage"),
            }}
            failedLoginTranslation={
              isLoggedIn ? undefined : t("reward.signup.login.error")
            }
          />
        )}
        {state === ViewState.FORGOT_PASSWORD && (
          <ForgotPasswordForm handleDialogBoxClosing={handleDialogBoxClosing} />
        )}
      </Container>
    </DialogBox>
  );
};

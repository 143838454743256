import { useEffect, useMemo } from "react";
import { useCountdownTimer } from "../../../CountdownTimer/hooks/useCountdownTimer";

const formatTimeUnit = (timeUnit: number) => {
  if (timeUnit < 10) {
    return `0${timeUnit}`;
  }
  return timeUnit;
};

const OTPCountdownTimer = ({
  expires,
  setHasTimePassed,
}: {
  expires: number;
  setHasTimePassed: () => void;
}) => {
  const startDate = useMemo(() => new Date(), []);
  const endDate = useMemo(() => new Date(expires * 1000), [expires]);
  const { hasExpired, min, sec } = useCountdownTimer({
    endDate,
    startDate,
    isOnClient: typeof window !== "undefined",
  });

  useEffect(() => {
    if (hasExpired === false) {
      return;
    }
    setHasTimePassed();
  }, [hasExpired, setHasTimePassed]);

  return !hasExpired ? (
    <>{`${formatTimeUnit(min)}:${formatTimeUnit(sec)}`}</>
  ) : null;
};

export { OTPCountdownTimer };

import type { CartItem, DisplayCart } from "../Api/types";
import { getProductTrackingData } from "./getProductTrackingData";
import { isEgPriceData } from "./utils";
import type { AddToCartEventData } from "../../../global";
import type { Trackers } from "../../../contexts/Tracking";
import type { ProductImpressionTrackingData } from "../../../utils/Tracking";

export const handleTrackingAddProductToCart = (
  item: AddToCartEventData,
  salesPrice: string,
  priceType: string,
  productListName: string,
  trackers: Trackers,
  ticket?: string
): void => {
  const productTrackingData = getProductTrackingData(
    item,
    salesPrice,
    priceType
  );

  trackers.sendAddToCartEvent({
    product: productTrackingData,
    list: productListName,
    deliveryStreamProduct: {
      id: item.styleCode,
      size: item.sizeCode,
      ean: item.eanCode,
      entryNumber: 0,
      brand: item.brandName,
      productPrice: Number(salesPrice),
    },
    ticket,
  });
};

export const handleTrackingIncreaseQuantity = (
  item: CartItem,
  salesPrice: string,
  priceType: string,
  productListName: string,
  trackers: Trackers
): void => {
  const productTrackingData = getProductTrackingData(
    item,
    salesPrice,
    priceType
  );
  trackers.sendAddToCartEvent({
    product: productTrackingData,
    list: productListName,
  });
};

export const handleTrackingRemoveProductFromCart = (
  item: CartItem,
  salesPrice: string,
  priceType: string,
  trackers: Trackers
): void => {
  const productTrackingData = getProductTrackingData(
    item,
    salesPrice,
    priceType
  );
  trackers.sendRemoveFromCartEvent(productTrackingData);
};

export const handleTrackingClickProductInCart = (
  item: CartItem,
  salesPrice: string,
  priceType: string,
  list: string,
  trackers: Trackers
): void => {
  const product = getProductTrackingData(item, salesPrice, priceType);
  trackers.sendProductClickInCartEvent({
    product,
    list,
  });
};

const _isLineValid = (
  line: Record<string, unknown>
): line is { amount: number; productCode: string; price: number } =>
  line.amount !== undefined &&
  line.productCode !== undefined &&
  line.price !== undefined;

const getPrice = (item: CartItem): number | undefined =>
  isEgPriceData(item.displayPrice)
    ? item.displayPrice.value
    : item.displayPrice?.salesPrice;

export const handleTrackingCartUpdatedInCheckout = (
  cartData: DisplayCart,
  trackers: Trackers
) => {
  if (cartData.totalAmount > 0) {
    trackers.sendCheckoutEvent({
      products: getCheckoutProducts(cartData),
      step: 1,
      deliveryStreamCheckout: {
        totalPrice: {
          value: cartData.totalAmount,
        },
        lines: cartData.items
          .map((line) => ({
            amount: line.quantity,
            productCode: line.productCode,
            price: getPrice(line),
          }))
          .filter(_isLineValid),
      },
    });
  }
};

const getCheckoutProducts = (
  cartData: DisplayCart
): ProductImpressionTrackingData[] => {
  return cartData.items.map((item, index) => ({
    name: item.title,
    id: String(item.selectedStyle),
    price: String(getPrice(item)),
    brand: String(item.brand),
    variant: String(item.selectedStyle),
    list: "Checkout Summary",
    quantity: item.quantity ?? 1,
    position: index,
  }));
};

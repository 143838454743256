import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { NextImage } from "../../common/NextImage/NextImage";
import { color } from "@xxl/theme";
import { IMAGE_SIZE } from "../constants";
const { spaces } = xxlTheme;

const imgBorderColor = color.mediumGray.cssVariable;

export const SlideLink = styled.a<{ disabled: boolean }>`
  touch-action: manipulation;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: ${xxlTheme.spaces.micro};
  word-wrap: break-word;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

export const ImageContainer = styled.div<{
  isSelected: boolean;
  isInStock: boolean;
}>`
  position: relative;
  width: ${IMAGE_SIZE.STYLE}px;
  height: ${IMAGE_SIZE.STYLE}px;
  border: ${spaces.hair} solid
    ${({ isSelected }) =>
      isSelected ? color.webBlack.cssVariable : imgBorderColor};
  ${({ isInStock }) =>
    !isInStock &&
    css`
      opacity: 0.7;
    `}
`;

export const StyledImage = styled(NextImage)`
  background-color: ${color.lightGray.cssVariable};
`;

const DiagonalLine = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Ensures the SVG does not interfere with image interactions */
`;

export const ImageStrikeThrough = () => (
  <DiagonalLine>
    <line
      x1="0"
      y1="0"
      x2="50"
      y2="50"
      stroke={imgBorderColor}
      strokeWidth="1"
    />
  </DiagonalLine>
);

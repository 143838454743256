import React from "react";
import styled from "@emotion/styled/macro";
import { resolutionSizes } from "../../config";
import { xxlTheme } from "../../styles/xxl-theme";
import { CaretUp } from "@xxl/icons";
import {
  DESKTOP_BUTTON_SIZE,
  MOBILE_BUTTON_SIZE,
} from "../Common/XxlButton/XxlButton";
import type { ButtonSizeType } from "../Common/XxlButton/XxlButton";

const {
  colors: { xxlMediumGrey, xxlRed },
} = xxlTheme;

const StyledSelectButton = styled.button<{
  disabled: boolean;
  hasError: boolean;
  size: ButtonSizeType;
}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${xxlTheme.typography.mediumRegular.fontSize}px;
  color: ${xxlTheme.colors.xxlBlack};
  background-color: ${xxlTheme.colors.xxlWhite};
  padding: 0 1rem;
  border: 1px solid ${({ hasError }) => (hasError ? xxlRed : xxlMediumGrey)};

  ${xxlTheme.typography.standardRegular};

  outline-color: ${xxlTheme.colors.xxlGreen};

  @media (min-width: ${resolutionSizes.laptop}px) {
    height: ${({ size }) => DESKTOP_BUTTON_SIZE[size]};
  }
  height: ${({ size }) => MOBILE_BUTTON_SIZE[size]};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;

const SelectCaret = styled(CaretUp)<{ disabled: boolean; open: boolean }>`
  display: ${({ disabled }) => (disabled ? "none" : "block")};
  transform: ${({ open }) => (open ? "rotate(0deg)" : "rotate(180deg)")};
  transition: transform 0.2s ease-in-out;
`;

type Props = {
  disabled?: boolean;
  hasError?: boolean;
  onClick: () => void;
  open: boolean;
  displayText: string;
  size: ButtonSizeType;
};
export const SizeSelectButton = ({
  disabled = false,
  hasError = false,
  onClick,
  displayText,
  open,
  size,
}: Props) => (
  <StyledSelectButton
    disabled={disabled}
    hasError={hasError}
    onClick={onClick}
    size={size}
    data-testid="size-options-button"
  >
    <p data-testid="selected-size">{displayText}</p>
    <SelectCaret open={open} fontSize={24} disabled={disabled} />
  </StyledSelectButton>
);

import React, { useEffect, useState } from "react";
import { SocialList, SocialListItem } from "../SiteFooter.styled";
import { Facebook, Instagram, YouTube } from "@xxl/icons";
import type { SocialMediaUrls } from "../../../utils/apis/content-footer-api";
import Link from "next/link";

type SocialMediaLink = {
  url: string;
  icon: React.ReactElement;
  name: string;
};

type SocialMediaListProps = {
  urls: SocialMediaUrls;
};
const SocialMediaList: React.FunctionComponent<SocialMediaListProps> = ({
  urls,
}) => {
  const [links, setLinks] = useState<SocialMediaLink[]>([]);

  useEffect(() => {
    const linkList: SocialMediaLink[] = [];
    const { facebookUrl, instagramUrl, youtubeUrl } = urls;

    if (facebookUrl !== null) {
      linkList.push({
        url: facebookUrl,
        icon: <Facebook />,
        name: "facebook",
      });
    }

    if (instagramUrl !== null) {
      linkList.push({
        url: instagramUrl,
        icon: <Instagram />,
        name: "instagram",
      });
    }

    if (youtubeUrl !== null) {
      linkList.push({
        url: youtubeUrl,
        icon: <YouTube />,
        name: "youtube",
      });
    }

    setLinks(linkList);
  }, [urls]);

  return (
    <SocialList>
      {links.map(({ icon, url, name }, index) => (
        <SocialListItem key={index}>
          <Link href={url} aria-label={name}>
            {icon}
          </Link>
        </SocialListItem>
      ))}
    </SocialList>
  );
};

export { SocialMediaList };

import React from "react";
import type { ChangeEvent } from "react";
import { styled } from "@mui/material/styles";
import { Checkbox, FormControlLabel } from "@mui/material";
import type { SxProps } from "@mui/material/styles";
import { LoadingCircle } from "../../components/Common";
import { xxlTheme } from "../../styles/xxl-theme";
import spaces from "../../../src/styles/theme/spaces";
import { IconCheckmark } from "./IconCheckmark";

const { colors } = xxlTheme;

type StyleProps = {
  backgroundColor?: string;
  borderColor?: string;
  top?: string;
  fontFamily?: string;
};

export type CheckboxProps = {
  checked?: boolean;
  handleChange?: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  id?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  label: React.ReactNode;
  name?: string;
  style?: StyleProps;
  testId?: string;
  value?: unknown;
  isBigSize?: boolean;
  hideLabel?: boolean;
  isRegion?: boolean;
};

const PREFIX = "checkbox";
const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
};

const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== "style",
})(({ style }) => {
  const shadowColor =
    style?.borderColor !== undefined ? style.borderColor : colors.xxlGrey;
  return {
    display: "flex",
    alignItems: "center",
    color: colors.xxlWebBlack,
    pointerEvents: "all",

    "& .MuiFormControlLabel-label": {
      marginLeft: "0",
      fontSize: "15px",
      fontFamily: style?.fontFamily ?? "var(--font-family-regular)",
      lineHeight: "1",
      letterSpacing: "-0.1px",
    },

    [`& .${classes.root}`]: {
      padding: "0 7px 0 11px",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    [`& .${classes.icon}`]: {
      width: spaces.small,
      height: spaces.small,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: style?.backgroundColor ?? colors.xxlWhite,
      borderRadius: 2,
      boxShadow: `inset 0 0 0 1px ${shadowColor}, inset 0 -1px 0 ${shadowColor}`,
      "input:disabled ~ &": {
        background: "rgba(206,217,224,.5)",
      },
    },
  };
});

const getInteractiveStyles = (isChecked = false): SxProps => ({
  accentColor: colors.xxlGreen,
  "&:hover, &:active, &:focus-visible, &.Mui-focusVisible": !isChecked
    ? {
        "& .checkbox-icon": {
          transition: "box-shadow 300ms",
          boxShadow: `inset 0 0 0 2px ${colors.xxlDarkGreen}, inset 0 -2px 0 ${colors.xxlDarkGreen}`,
        },
      }
    : {},
});

const StyledCheckbox = ({
  isLoading = false,
  style,
  handleChange,
  checked,
  hideLabel,
  label,
  id,
  testId,
  isBigSize,
  name,
  value,
  isDisabled,
}: CheckboxProps) => (
  <StyledFormControlLabel
    style={style}
    control={
      <Checkbox
        className={classes.root}
        onChange={handleChange}
        checked={checked}
        size="medium"
        checkedIcon={
          <span className={classes.icon}>
            {!isLoading ? (
              <IconCheckmark />
            ) : (
              <LoadingCircle isLoading={true} />
            )}
          </span>
        }
        icon={
          <span className={classes.icon}>
            <LoadingCircle isLoading={isLoading} />
          </span>
        }
        inputProps={{
          "aria-label": hideLabel === true ? String(label) : "custom checkbox",
          name: name,
          id: id,
        }}
        data-testid={testId}
        disableRipple={true}
        value={value}
        style={{
          color: colors.xxlGreen,
        }}
        sx={
          isBigSize === true
            ? {
                "& .MuiSvgIcon-root": {
                  fontSize: spaces.small,
                  color: colors.xxlGreen,
                },
                "& .checkbox-/icon": {
                  width: spaces.small,
                  height: spaces.small,
                },
                ...getInteractiveStyles(checked),
              }
            : getInteractiveStyles(checked)
        }
      />
    }
    label={hideLabel !== true ? label : <></>}
    disabled={isDisabled}
  />
);

export { StyledCheckbox, StyledFormControlLabel };

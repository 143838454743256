import React from "react";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { LineDivider, RemindMe } from "./ProductSizeSelector.styled";
import { Icon } from "react-app/src/components/Icon/Icon";
import { Text } from "react-app/src/components/Text";

export const RemindMeComponent = ({
  hasReminderBoolean,
  onClick,
  shouldBePossibleToAddReminder,
  shouldShowRemindMe,
}: {
  hasReminderBoolean: boolean;
  onClick: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  shouldBePossibleToAddReminder: boolean;
  shouldShowRemindMe: boolean;
}) => {
  const { t } = useTranslations();
  if (!shouldBePossibleToAddReminder || !shouldShowRemindMe) return null;
  return (
    <RemindMe onClick={onClick}>
      <LineDivider />
      {hasReminderBoolean && <Icon name="Check" />}
      <Icon name="Email" size={12} />
      <Text size="small" underline={true}>
        {t(hasReminderBoolean ? "general.reminder.added" : "general.remind.me")}
      </Text>
    </RemindMe>
  );
};

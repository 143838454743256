import type { DisplayCart, CartItem } from "../Api/types";

export const getCartErrorMessages = (
  displayCart: DisplayCart,
  errorMessageKeys: string[]
): Pick<DisplayCart, "items" | "errors"> => {
  const errors: string[] = displayCart.errors ?? [];
  let items: CartItem[] = displayCart.items;

  const generalErrors = errorMessageKeys.filter((item) =>
    [
      "checkout.cart.coupon.validation.failure",
      "checkout.cart.failure",
      "checkout.cart.not.found",
      "checkout.cart.total.mismatch",
      "cart.some.products.out.of.stock",
      "checkout.deliveryMethod.notSelected",
      "checkout.deliveryMethod.mismatch",
      "coupon.message.invalid",
      "checkout.blacklist.error",
    ].includes(item)
  );

  if (generalErrors.length > 0) {
    errors.push(...generalErrors);
  }

  if (
    errorMessageKeys.some((item) => item.includes("cart.entry.out.of.stock"))
  ) {
    const outOfStockEans = errorMessageKeys
      .filter((item) => item.includes("cart.entry.out.of.stock"))
      .map((errorMessageKey) => errorMessageKey.split("-")[1]);

    items = items.map((cartItem) => {
      if (
        (cartItem.ean ?? []).some((cartItemEan) =>
          outOfStockEans.includes(cartItemEan)
        ) &&
        !(cartItem.errors ?? []).includes("cart.entry.out.of.stock")
      ) {
        return {
          ...cartItem,
          errors: [...(cartItem.errors ?? []), "cart.entry.out.of.stock"],
        };
      } else {
        return cartItem;
      }
    });
  }

  return { errors, items };
};

import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import type { MouseEventHandler } from "react";
export interface ButtonProps {
  disabled?: boolean;
  inline?: boolean;
  type?: "button" | "submit" | "reset";
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const Button = styled.button<ButtonProps>(
  ({ inline, disabled = false }) => css`
    width: ${inline === true ? "auto" : "100%"};
    position: relative;
    cursor: ${disabled ? "not-allowed" : "pointer"};

    &:hover {
      background-position: 0 100%;
    }
  `
);

export const ButtonStyledAsLink = styled.button`
  padding: 0;
  text-decoration: underline;
  background: none;
  border: 0;
  outline: none;
  cursor: pointer;
  text-align: left;
`;

export const OutlinedWhiteButton = styled(Button)`
  &&& {
    background-color: transparent;
    border: 1px solid var(--color-xxl-white);
    color: var(--color-xxl-white);
    cursor: pointer;
  }
`;

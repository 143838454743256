import styled from "@emotion/styled/macro";
import type { ArrowDirections } from "./CaretIcon";

const getRotationDegrees = (direction: ArrowDirections): number => {
  switch (direction) {
    case "down":
      return 90;
    case "left":
      return 180;
    case "up":
      return 270;
    default:
      return 0;
  }
};

export const SvgContainer = styled.span<{ direction: ArrowDirections }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  & svg {
    transform: rotate(${({ direction }) => getRotationDegrees(direction)}deg);
    transition: rotate 0.2s;
  }
`;

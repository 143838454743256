import type {
  BaseCampaignData,
  ImageData,
  MegaMenuLevelOneLink,
} from "@xxl/frontend-api";
import type { Translate } from "../../../contexts/Translations/TranslationsContext";
import { getMonthDay } from "../../../utils/xxl-format-date";
import { isNotNullOrUndefined } from "@xxl/common-utils";

export type ContentSectionData = {
  title?: string;
  description: string | null;
  href?: string;
  alt?: string;
  image?: ImageData;
  banner: string | null;
};

const getCampaignUrl = (
  campaign: BaseCampaignData,
  displayName: string
): string => {
  if (campaign.url !== undefined && campaign.url !== "") {
    return `${campaign.url}?sort=campaignPage&categoryName=${encodeURIComponent(
      displayName
    )}`;
  }
  return "";
};

const getContent = (
  c1: MegaMenuLevelOneLink,
  t: Translate
): ContentSectionData | null => {
  const { content, campaign, contentText, link, displayName = "" } = c1;
  if (isNotNullOrUndefined(content)) {
    return {
      title: contentText,
      description: null,
      href: link,
      alt: content.alt,
      image: content,
      banner: null,
    } as ContentSectionData;
  } else if (isNotNullOrUndefined(campaign)) {
    return {
      title: t("mega.menu.campaign.header"),
      description: campaign.bannerHeader,
      href: getCampaignUrl(campaign, displayName),
      alt: campaign.bannerImage?.alt,
      image: campaign.bannerImage,
      banner: isNotNullOrUndefined(campaign.toDate)
        ? `${t("mega.menu.campaign.until")} ${getMonthDay(campaign.toDate)}`
        : null,
    } as ContentSectionData;
  } else {
    // TODO: XD-3732 will add Service offerings as another type of Content
    return null;
  }
};

export { getContent };

import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { usePdpContext } from "../ProductDetailsPage/ProductDetailsPage.state";
import type { XxlStoreSelectedEventPayload } from "../../global";
import * as XxlEvent from "../../utils/xxl-event";
import React, { useEffect } from "react";
import { storeHasStock } from "../StoreStock/StoreStock.helper";
import type { Store } from "./api/api";
import { useKlarnaClasses, usePreferredStores } from "./ClickAndCollect.helper";
import { XxlButton } from "../Common/XxlButton/XxlButton";
import { ClickAndCollectDialog } from "./ClickAndCollectDialog";

type ClickAndCollectProps = {
  isDisabledExternal: boolean;
  isLoadingExternal: boolean;
  onAddToCart: (storeId: string) => void;
  isSuccess?: boolean;
  isCnCDialogOpen: boolean;
  toggleIsCnCDialogOpen: () => void;
};
const ClickAndCollect = ({
  isLoadingExternal,
  isDisabledExternal,
  onAddToCart,
  isSuccess = false,
  isCnCDialogOpen,
  toggleIsCnCDialogOpen,
}: ClickAndCollectProps) => {
  useKlarnaClasses();
  const { t } = useTranslations();
  const { stores: unsortedStores, isFetchingStores } = usePdpContext().state;
  const [preferredStores, setPreferredStores] =
    usePreferredStores(unsortedStores);

  useEffect(() => {
    const listener = (event: Event) => {
      const newSelectedStore = (
        event as CustomEvent<XxlStoreSelectedEventPayload>
      ).detail.selectedStore as Store;
      setPreferredStores([
        ...preferredStores.map((s) => s.id),
        newSelectedStore.id,
      ]);
    };
    XxlEvent.addEventListener(XxlEvent.type.XXL_STORE_SELECTED, listener);
    return () => {
      XxlEvent.removeEventListener(XxlEvent.type.XXL_STORE_SELECTED, listener);
    };
  }, [preferredStores]);

  const handleAddToCartClick = (storeId: string) => {
    onAddToCart(storeId);
    toggleIsCnCDialogOpen();
  };

  const storesWithCollectableStock = unsortedStores.filter(storeHasStock);
  const isLoading = isLoadingExternal || isFetchingStores;
  const isBtnDisabled =
    unsortedStores.length === 0 ||
    storesWithCollectableStock.length === 0 ||
    isDisabledExternal;

  return (
    <>
      <XxlButton
        variant={isSuccess ? "primary" : "secondary"}
        loading={isLoading}
        onClick={toggleIsCnCDialogOpen}
        disabled={isBtnDisabled || isLoading}
        data-testid="ccBtn"
        icon={isSuccess ? "Check" : undefined}
      >
        {isSuccess
          ? t("product.details.clickcollect.title.added")
          : t("product.details.clickcollect.title")}
      </XxlButton>
      <ClickAndCollectDialog
        onAddToCartClick={handleAddToCartClick}
        isDialogOpen={isCnCDialogOpen}
        stores={unsortedStores}
        toggleDialog={toggleIsCnCDialogOpen}
      />
    </>
  );
};

export { ClickAndCollect };

import { useMediaQuery } from "@mui/material";
import { hasValue } from "@xxl/common-utils";
import sum from "lodash/sumBy";
import React, { useEffect, useRef, useState } from "react";
import { useTracking } from "../../contexts/Tracking";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import type { TranslationKey } from "../../translations";
import {
  mobileAndTabletMediaQuery,
  mobileMediaQuery,
} from "../../utils/xxl-screen";
import {
  AutoSuggestionsWrapper,
  MobileProductsList,
  ShowAllLink,
  SuggestionsColumn,
  Tab,
  TabsList,
} from "./AutoSuggestion.styled";
import { AutoSuggestionSection } from "./AutoSuggestionSection";
import { ProductsSectionWrapper } from "./AutoSuggestionSection.styled";
import { Product } from "./Product";
import type { ProductsSectionProps } from "./ProductsSection";
import { ProductsSection } from "./ProductsSection";
import {
  SearchSuggestionsTypeEnum,
  type SearchSuggestsGroup,
} from "./searchBoxHelper";

enum Tabs {
  others,
  products,
}

type SuggestionsProps = {
  showSuggestions: boolean;
  isTwoColumns?: boolean;
  items?: SearchSuggestsGroup[];
  products?: {
    highlightedPhrase?: string;
    items: ProductsSectionProps["products"];
  };
  query?: string;
};

const ITEMS_AMOUNT_TO_REDUCE = 15;
const ITEMS_LENGTH_TO_BREAK = 3;
const ITEMS_LENGTH_TO_REDUCE_MORE = 5;

export const AutoSuggestions = ({
  showSuggestions,
  isTwoColumns = false,
  items,
  products,
  query,
}: SuggestionsProps) => {
  const { t } = useTranslations();
  const isMobile = useMediaQuery(mobileMediaQuery);
  const isMobileOrTablet = useMediaQuery(mobileAndTabletMediaQuery);
  const [activeTab, setActiveTab] = useState(Tabs.others);
  const columnRef = useRef<HTMLDivElement>(null);
  const [columnHeight, setColumnHeight] = useState(0);
  const [itemsAmount, setItemsAmount] = useState(0);
  const [renderedItems, setRenderedItems] = useState<SearchSuggestsGroup[]>([]);
  const tracking = useTracking();

  const trackClick = (ticket?: string) => {
    if (ticket !== undefined) {
      tracking.sendClickEvent({ ticket });
    }
  };

  useEffect(() => {
    setColumnHeight(
      columnRef.current !== null ? columnRef.current.clientHeight : 0
    );
  }, [columnRef, showSuggestions]);

  useEffect(() => {
    const itemsCount = sum(items, (section) => section.queries.length);
    setItemsAmount(itemsCount);
  }, [columnHeight, items]);

  useEffect(() => {
    if (items !== undefined) {
      if (
        items.length > ITEMS_LENGTH_TO_BREAK &&
        itemsAmount > ITEMS_AMOUNT_TO_REDUCE
      ) {
        const sliceEnd = items.length > ITEMS_LENGTH_TO_REDUCE_MORE ? 2 : 3;
        const mobileItems = items.map((item) => {
          const mobileItem = {
            ...item,
            queries: item.queries.slice(0, sliceEnd),
          };
          return mobileItem;
        });
        setRenderedItems(isMobileOrTablet ? mobileItems : items);
      } else {
        setRenderedItems(items);
      }
    }
  }, [itemsAmount, isMobileOrTablet, items]);

  useEffect(
    () => setActiveTab(hasValue(products?.items) ? Tabs.products : Tabs.others),
    [products]
  );

  return (
    <AutoSuggestionsWrapper ref={columnRef} data-testid="search-suggestions">
      {isMobile ? (
        <>
          {items !== undefined &&
            items.length > 0 &&
            products !== undefined &&
            products.items.length > 0 && (
              <TabsList>
                <Tab
                  isActive={activeTab === Tabs.others}
                  onClick={() => setActiveTab(Tabs.others)}
                >
                  {t("header.search.suggestions.results")}
                </Tab>
                <Tab
                  isActive={activeTab === Tabs.products}
                  onClick={() => setActiveTab(Tabs.products)}
                >
                  {t("header.search.suggestions.products")}
                </Tab>
              </TabsList>
            )}
          {activeTab === Tabs.products && products !== undefined && (
            <ProductsSectionWrapper>
              <MobileProductsList>
                {products.items.map((item, index) => (
                  <Product
                    key={`${item.name as string}_${index}`}
                    foundPhrase={products.highlightedPhrase}
                    product={item}
                    onClick={trackClick}
                  />
                ))}
              </MobileProductsList>
            </ProductsSectionWrapper>
          )}

          {activeTab === Tabs.others && renderedItems.length > 0 && (
            <SuggestionsColumn>
              {renderedItems.map((item) => {
                const type = SearchSuggestionsTypeEnum[item.type];
                return (
                  <AutoSuggestionSection
                    key={item.type}
                    headerName={t(
                      `header.search.suggestions.${type}` as TranslationKey
                    )}
                    foundPhrase={item.highlightedPhrase}
                    items={item.queries}
                    onClick={trackClick}
                  />
                );
              })}
            </SuggestionsColumn>
          )}
        </>
      ) : (
        <>
          {renderedItems.length > 0 && (
            <SuggestionsColumn twoColumns={!isMobileOrTablet && isTwoColumns}>
              {renderedItems.map((item) => {
                const type = SearchSuggestionsTypeEnum[item.type];
                return (
                  <AutoSuggestionSection
                    key={item.type}
                    headerName={t(
                      `header.search.suggestions.${type}` as TranslationKey
                    )}
                    foundPhrase={item.highlightedPhrase}
                    items={item.queries}
                    onClick={trackClick}
                  />
                );
              })}
            </SuggestionsColumn>
          )}
          {products !== undefined && products.items.length > 0 && (
            <ProductsSection
              headerName={t("header.search.suggestions.products")}
              foundPhrase={products.highlightedPhrase}
              products={products.items}
              onClick={trackClick}
            />
          )}
        </>
      )}
      {query !== undefined && (
        <ShowAllLink
          href={`/search?query=${query}`}
          data-testid="search-auto-suggestions-show-all-link"
        >
          {t("header.search.suggestions.all")}
        </ShowAllLink>
      )}
    </AutoSuggestionsWrapper>
  );
};

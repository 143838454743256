import styled from "@emotion/styled/macro";
import { typographyToCss } from "react-app/src/styles/helpers";

export const Wrapper = styled.div<{ fontColor: string }>`
  display: flex;
  align-items: center;
  gap: 1px;
  color: ${({ fontColor }) => fontColor};
  white-space: nowrap;
`;

export const TimeItem = styled.span`
  ${typographyToCss({
    fontSize: 12,
    lineHeight: 1,

    tablet: {
      fontSize: 14,
    },
  })}
`;

export const Colon = styled(TimeItem)`
  margin: 0 3px;
`;

export const Seconds = styled(TimeItem)`
  min-width: 21px;
`;

export const SecondsName = styled(TimeItem)`
  position: relative;
  left: -2px;
`;

import styled from "@emotion/styled/macro";
import { MQ, typographyToCss, interactionFeedback } from "../../styles/helpers";
import { xxlTheme } from "../../styles/xxl-theme";
import { color } from "@xxl/theme";
import { XxlButton } from "../../components/Common/XxlButton";
import { Button } from "@mui/material";

type DeviceProps = {
  isMobile: boolean;
};

const { colors, spaces } = xxlTheme;

export const Confirmation = styled.div`
  padding: ${spaces.smallRegular};
  margin-bottom: ${spaces.smallLarge};
  padding-top: ${spaces.mini};

  ${MQ("tablet")} {
    padding: ${spaces.large};
  }
`;

export const ConfirmationHeading = styled.div`
  width: 100%;

  p {
    margin-top: 0;
  }
`;

export const ConfirmationStoreInfo = styled.section<DeviceProps>`
  display: ${(props): string => (props.isMobile ? "block" : "grid")};
  grid-template-columns: 65% calc(35% - 24px);
  grid-gap: 10px;
`;

export const ConfirmationFooter = styled.div<DeviceProps>`
  width: 100%;
  margin-top: ${spaces.regular};
  text-align: ${(props): string => (props.isMobile ? `center;` : "right;")};

  button {
    padding-right: ${spaces.smallLarge};
    padding-left: ${spaces.smallLarge};
  }
`;

export const OpeningHours = styled.div`
  border: 1px solid ${colors.xxlLightGrey};

  h3 {
    margin: ${spaces.smallRegular};

    ${typographyToCss({
      fontSize: 12,
      fontWeight: 700,
    })}
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }

  li {
    border-bottom: 1px solid ${colors.xxlLightGrey};
    padding: ${spaces.mini} ${spaces.regular};
    font-size: 15px;
    display: flex;
    justify-content: space-between;

    &:nth-child(odd) {
      background-color: ${colors.xxlLightGrey};
    }
  }
`;

export const OpeningHoursRow = styled.li`
  padding-right: ${spaces.micro};
  white-space: nowrap;
`;

export const ConfirmationButtonStyled = styled.button<DeviceProps>`
  width: ${(props): string => (props.isMobile ? `100%` : "224px")};
`;

export const DialogFooter = styled.div`
  padding: ${spaces.smallRegular};
  background: ${color.white.hex};
  box-shadow: 0 -5px 5px -5px ${color.mediumGray.hex};
  position: sticky;
  bottom: 0;
  ${MQ("tablet")} {
    display: flex;
    justify-content: flex-end;
    gap: ${spaces.smallRegular};
    padding: ${spaces.smallRegular} ${spaces.large};
  }
`;

export const DialogBtn = styled(XxlButton)`
  ${MQ("tablet")} {
    width: fit-content;
  }
`;

export const ACCORDION_BORDER = `1px solid ${color.mediumGray.cssVariable}`;
export const ACCORDION_STYLES = {
  borderTop: ACCORDION_BORDER,
  boxShadow: "none",
  ".MuiAccordionDetails-root": {
    padding: `0px ${spaces.extraLarge}`,
    height: "fit-content",
  },
  ".MuiAccordionSummary-root": {
    height: "fit-content",
  },
  "&:first-of-type": {
    borderTop: 0,
  },
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  "&.expanded": {
    margin: 0,
  },
  "&.Mui-disabled": {
    backgroundColor: "transparent",
  },
};

export const CloseButton = styled(Button)`
  cursor: pointer;
  color: ${xxlTheme.colors.xxlWebBlack};
  border: none;
  background: none;
  padding-top: 0;
  ${interactionFeedback(`background-color: ${xxlTheme.colors.xxlLightGrey};`)};
`;

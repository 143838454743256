import type { SizeData } from "@xxl/frontend-api";
import type { RecommendationsResponse } from "@xxl/recommendations-api";
import type { AxiosResponse } from "axios";
import axios from "axios";
import { CROSS_SALES_PERSONALIZED_KEY } from "../constants";

export type GetProductsProps = {
  basePath: string;
  category: string;
  price?: string;
  product: string;
  siteUid: string;
};
export async function getCrossSalesProducts({
  basePath,
  category,
  price,
  siteUid,
  product,
}: GetProductsProps): Promise<AxiosResponse<RecommendationsResponse>> {
  const queryResponse = await axios({
    method: "post",
    url: `${basePath}/sites/${siteUid}/recommendations/personalize`,
    data: [
      {
        numResults: 4,
        strategy: "cross-sales",
        styleId: product,
        filters: {
          categories: [category],
        },
        key: CROSS_SALES_PERSONALIZED_KEY,
        price: price,
      },
    ],
  });

  return queryResponse;
}

type GetSizesProps = {
  styleCode: string;
};
export async function getSizes({
  styleCode,
}: GetSizesProps): Promise<AxiosResponse<SizeData[]>> {
  const queryResponse = await axios({
    url: `/p/${styleCode}/sizes.json`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
  });

  return queryResponse;
}

export default {
  link: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 15,
    lineHeight: 1.3,
    textDecoration: "none",
  },
  showMoreLink: {
    fontFamily: "var(--font-family-medium)",
    fontSize: 12,
    lineHeight: 1.2,
    textDecoration: "none",
    whiteSpace: "nowrap",
  },
  banner: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 12,
    lineHeight: 1.2,
    textDecoration: "none",
  },
  sectionLink: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 12,
    lineHeight: 1.33,
    textDecoration: "none",
    whiteSpace: "normal",
  },
  headerLink: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 24,
  },
  tab: {
    fontFamily: "var(--font-family-regular)",
    fontWeight: 400,
    fontSize: 15,
    lineHeight: 1.2,
    letterSpacing: -0.15,
    textTransform: "none",
  },
  sectionHeaderTemporary: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 15,
    lineHeight: 1.2,
    textDecoration: "none",
    whiteSpace: "normal",
  },
};

import { Stack, useMediaQuery } from "@mui/material";
import MuiRating from "@mui/material/Rating";
import type { RatingProps } from "@mui/material/Rating";
import {
  InfoBox,
  RatingNumber,
  RatingContainer,
  ReviewersLink,
  StarRatingLink,
} from "./StarRating.styled";
import { xxlTheme } from "../../../../src/styles/xxl-theme";
import { mobileAndTabletMediaQuery } from "../../../utils/xxl-screen";
import { hasValue } from "@xxl/common-utils";
import stringFormat from "string-format";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";

const { typography, colors } = xxlTheme;
const MINIMUN_DISPLAY_THRESHOLD = 75;

type Props = {
  fullWidth?: boolean;
  onClick?: () => void;
  recommendationPercentage?: number | null;
  showRatingNumber?: boolean;
} & RatingProps;

const StarRating = ({
  onClick,
  fullWidth = false,
  showRatingNumber = true,
  recommendationPercentage,
  ...props
}: Props) => {
  const isMobile = useMediaQuery(mobileAndTabletMediaQuery);
  const { t } = useTranslations();

  return (
    <RatingContainer>
      <StarRatingLink onClick={onClick} isLink={onClick !== undefined}>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          data-testid="rating"
          width={fullWidth ? "100%" : "auto"}
        >
          <MuiRating
            {...props}
            sx={{
              fontSize:
                onClick !== undefined
                  ? typography.mediumRegular.fontSize
                  : typography.smallRegular.fontSize,
            }}
          />
        </Stack>
        {showRatingNumber && (
          <RatingNumber isLink={onClick !== undefined}>
            {props.value}/5
          </RatingNumber>
        )}
      </StarRatingLink>
      {hasValue(recommendationPercentage) &&
        recommendationPercentage >= MINIMUN_DISPLAY_THRESHOLD && (
          <InfoBox backgroundColor={colors.xxlLightAmber} isMobile={isMobile}>
            <span>
              {`${stringFormat(
                t("product.ratings-and-reviews.summary.percent.of"),
                recommendationPercentage.toString()
              )} `}
              <ReviewersLink onClick={onClick} isLink={onClick !== undefined}>
                {t("product.ratings-and-reviews.summary.reviewers")}
              </ReviewersLink>
              {` ${t("product.ratings-and-reviews.summary.recommend.this.product")}`}
            </span>
          </InfoBox>
        )}
    </RatingContainer>
  );
};

export { StarRating };

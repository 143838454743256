import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { MQ, typographyToCss } from "../../../styles/helpers";
import { xxlTheme } from "../../../styles/xxl-theme";
import { cartProductColumnWidthLaptop } from "./CartProducts.styled";
import type { BackButtonProps } from "../Components/BackButton";
import typography from "./CartPage.typography";

const { emptyPageHeading, errorMessage } = typography;

const { spaces, colors } = xxlTheme;

export const BackButtonWrapper = styled.div`
  position: relative;
`;

export const ReloadButtonWrapper = styled.div`
  position: relative;
  margin-top: ${spaces.smallRegular};
`;

export const EmptyPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${spaces.big};
  color: ${colors.xxlWebBlack};
  padding-bottom: ${spaces.big};

  ${MQ("laptop")} {
    padding-bottom: ${spaces.smallGiant};
  }
`;

export const EmptyPageHeading = styled.h1`
  text-align: center;
  ${typographyToCss(emptyPageHeading)};
  margin: ${spaces.big} 0 0;
  padding: 0 ${spaces.smallRegular};

  ${MQ("laptop")} {
    margin-top: ${spaces.huge};
  }
`;

export const EmptyPageImage = styled.svg`
  width: 60px;
  height: 60px;
`;

export const BackButtonStyled = styled.button<BackButtonProps>(
  ({ isCentered, isMiniCart }) => css`
    width: 100%;

    ${MQ("tablet")} {
      width: ${isMiniCart === true ? "100%" : "fit-content"};
    }

    ${isCentered === true &&
    css`
      margin: 0 auto;
    `}
  `
);

export const PageSection = styled.section`
  background-color: ${colors.xxlLightGrey};
  padding-top: ${spaces.regular};
  position: relative;

  ${MQ("laptop")} {
    padding-top: ${spaces.large};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.big};
  padding-bottom: ${spaces.big};

  ${MQ("laptop")} {
    flex-direction: row;
    gap: ${spaces.huge};
  }
`;

type KlarnaWrapperProps = {
  isEditing: boolean;
};

export const KlarnaWrapper = styled.div<KlarnaWrapperProps>(
  ({ isEditing }) => css`
    width: 100%;
    order: 2;
    box-sizing: border-box;

    ${MQ("laptop")} {
      margin: ${spaces.smallLarge} 0 0;
      width: calc(100% - ${cartProductColumnWidthLaptop} - ${spaces.huge});
      order: 1;
    }

    ${isEditing === true &&
    css`
      pointer-events: none;
    `}
  `
);

export const CheckoutWrapper = styled.div`
  background-color: ${colors.xxlWhite};
`;

export const SliderWrapper = styled.div`
  background-color: ${colors.xxlWhite};
  padding: ${spaces.large} 0;
  &:has(> .container:only-child:empty),
  &:has(ul.keen-slider:empty) {
    display: none;
  }
`;

export const OutOfStockWrapper = styled(KlarnaWrapper)`
  order: 1;
  background-color: ${colors.xxlWhite};
  padding: ${spaces.large} ${spaces.mini};
  margin-top: ${spaces.big};

  ${MQ("laptop")} {
    padding: ${spaces.big} ${spaces.regular};
    margin-top: 70px;
    align-self: flex-start;
  }
`;

export const ErrorsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const ErrorItem = styled.li`
  color: ${colors.xxlError};
  ${typographyToCss(errorMessage)};
  padding: ${spaces.smallRegular} 0;
`;

export const CartErrorCollectStoreNotBuyable = styled.div`
  ${typographyToCss(typography.collectWarning)};
  background-color: ${colors.xxlError};
  color: ${colors.xxlWhite};
  padding: ${spaces.smallRegular};
`;

export const ConfirmationError = styled.div`
  ${typographyToCss(typography.collectWarning)};
  background-color: ${colors.xxlError};
  color: ${colors.xxlWhite};
  padding: ${spaces.smallRegular};
  margin-top: ${spaces.smallRegular};
  width: 100%;

  & a {
    color: ${colors.xxlWhite};
    ${typographyToCss(typography.collectWarning)};
    text-decoration: underline;
  }
`;

import Head from "next/head";
import React from "react";

const HeadMetaTags = () => (
  <Head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  </Head>
);

export default HeadMetaTags;

import type {
  BundledProducts,
  CartItem,
  EgCartItem,
  Ribbon,
  EgCartItemCollectWarning,
  DisplayBundledProducts,
} from "../Api/types";
import { setEGBundleWithPrintConfigurations } from "./setTeamBundleSettings";
import type {
  PriceDisplayData,
  PriceDisplayDataTypeEnum,
} from "@xxl/frontend-api";
import { MISSING_PRODUCT_IMAGE_URL } from "../../../constants";
import { translate } from "../../../utils/xxl-translate";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { setBundledProductsArray } from "./utils";
import { BUNDLE_FALLBACK_IMAGE_URL } from "../constants";

const countItemsQuantity = (items: EgCartItem[] = []) =>
  items
    .map(({ quantity }) => quantity.quantity)
    .reduce((sum, quantity) => sum + quantity, 0);

const getValueOrUndefinedIfNull = <T>(
  value: T | null | undefined
): T | undefined => value ?? undefined;

const convertCampaignInformation = (
  campaignInformation: EgCartItem["campaignInformation"]
): PriceDisplayData => {
  return {
    currencyIso: getValueOrUndefinedIfNull(campaignInformation.currencyIso),
    invertedPrice: getValueOrUndefinedIfNull(campaignInformation.invertedPrice),
    invertedPriceFormatted: getValueOrUndefinedIfNull(
      campaignInformation.invertedPriceFormatted
    ),
    label: campaignInformation.label ?? undefined,
    previousPrice: getValueOrUndefinedIfNull(campaignInformation.previousPrice),
    previousPriceFormatted: getValueOrUndefinedIfNull(
      campaignInformation.previousPriceFormatted
    ),
    priceDisclaimer: getValueOrUndefinedIfNull(
      campaignInformation.priceDisclaimer
    ),
    priceSplash: getValueOrUndefinedIfNull(campaignInformation.priceSplash),
    salesPrice: getValueOrUndefinedIfNull(campaignInformation.salesPrice),
    salesPriceFormatted: getValueOrUndefinedIfNull(
      campaignInformation.salesPriceFormatted
    ),
    type: getValueOrUndefinedIfNull(
      campaignInformation.type
    ) as PriceDisplayDataTypeEnum,
    userGroupId: getValueOrUndefinedIfNull(campaignInformation.userGroupId),
    colourTheme: {
      name: getValueOrUndefinedIfNull(campaignInformation.colorTheme?.name),
      backgroundColour: getValueOrUndefinedIfNull(
        campaignInformation.colorTheme?.backgroundColor
      ),
      foregroundColour: getValueOrUndefinedIfNull(
        campaignInformation.colorTheme?.foregroundColor
      ),
    },
  };
};

const convertConfigurationsToStrings = (
  configurations: EgCartItem["configurationProducts"]
): string[] | undefined =>
  configurations?.map(
    ({ article }) =>
      `${article.name} ${getValueOrUndefinedIfNull(article.size?.text) ?? ""}`
  );

export const getCollectWarning = (
  collectWarning: EgCartItemCollectWarning,
  quantity: number,
  collectStoreName: string | undefined
): string | undefined => {
  if (collectWarning === null) {
    return;
  }
  return translate({
    key: `cart.collect.warning.${collectWarning.type}` as const,
    messageArguments: [
      collectStoreName ?? translate("cart.collect.warning.store.fallback"),
      ...[collectWarning.stock, quantity].map((num) => String(num)),
    ],
  });
};

const mapEgCartItemToDisplayCartItem =
  (collectStoreName?: string, hasParentBundle?: boolean) =>
  (item: EgCartItem): CartItem => {
    const displayPrice = convertCampaignInformation(item.campaignInformation);
    let ribbon: Ribbon | undefined;
    if (displayPrice.priceSplash !== undefined) {
      ribbon = {
        label: displayPrice.priceSplash,
        colorTheme: {
          backgroundColor: displayPrice.colourTheme?.backgroundColour ?? "",
          name: getValueOrUndefinedIfNull(displayPrice.colourTheme?.name),
          foregroundColor: displayPrice.colourTheme?.foregroundColour ?? "",
        },
        identifier: item.article.color?.code ?? "",
      };
    }
    const colorName = getValueOrUndefinedIfNull(item.article.color?.text);
    const accessories = item.accessories?.map(
      mapEgCartItemToDisplayCartItem(collectStoreName)
    );

    const commonProperties = {
      accessories,
      ean: [item.ean],
      unit: getValueOrUndefinedIfNull(item.unit),
      type: item.itemId.type,
      title: item.article.name,
      primaryImage:
        item.article.image?.path ??
        (item.itemId.type === "BUNDLE"
          ? BUNDLE_FALLBACK_IMAGE_URL
          : MISSING_PRODUCT_IMAGE_URL),
      displayPrice,
      ribbon,
      splashInfo: displayPrice.label,
      url:
        item.article.path?.localizedPath ??
        item.article.path?.shortPath ??
        undefined,
      entryNumber: item.itemId.id,
      isBundleService:
        item.itemId.type === "SERVICE" && hasParentBundle === true,
      productSettings: convertConfigurationsToStrings(
        item.configurationProducts
      ),
      hasService: (item.serviceProducts?.length ?? 0) > 0,
      productServices: setEgCartItems(
        getValueOrUndefinedIfNull(item.serviceProducts),
        undefined,
        item.itemId.type === "BUNDLE"
      ),
      servicesQuantity: countItemsQuantity(
        getValueOrUndefinedIfNull(item.serviceProducts)
      ),
      productCode: item.article.color?.code,
      category: item.article.category?.code,
      collectWarning:
        "collectStore" in item
          ? getCollectWarning(
              item.collectStore?.warning ?? null,
              item.quantity.quantity,
              collectStoreName
            )
          : undefined,
      collectStock: item.collectStore?.warning?.stock,
    };
    if (
      item.itemId.type === "BUNDLE" &&
      isNotNullOrUndefined(item.bundledProducts)
    ) {
      if (
        item.bundledProducts.find(({ itemId }) => itemId.type === "PRINT") !==
        undefined
      ) {
        return {
          ...commonProperties,
          bundleNumber: item.itemId.id,
          bundleProducts: [],
          bundleIds: undefined,
          teamBundleSettings: setEGBundleWithPrintConfigurations(item),
        };
      } else {
        const bundleProducts = setBundledProductsArray(
          item.bundledProducts as BundledProducts[]
        );

        const bundleItemId =
          isNotNullOrUndefined(item.bundledProducts) &&
          isNotNullOrUndefined(item.bundledProducts[0])
            ? item.bundledProducts[0].itemId
            : undefined;

        const displayBundledProducts: DisplayBundledProducts[] | undefined =
          isNotNullOrUndefined(item.bundledProducts)
            ? item.bundledProducts.map(
                (product): DisplayBundledProducts => ({
                  name: product.article.name,
                  price: product.total ?? undefined,
                  quantity: product.quantity.quantity,
                })
              )
            : undefined;

        return {
          ...commonProperties,
          bundleNumber: item.itemId.id,
          bundleProducts,
          bundleIds: undefined,
          quantity: item.quantity.quantity,
          bundleItemId,
          bundledProducts: displayBundledProducts,
        };
      }
    } else {
      return {
        ...commonProperties,
        brand: getValueOrUndefinedIfNull(item.article.brand?.name),
        selectedSize: getValueOrUndefinedIfNull(item.article.size?.text),
        selectedStyle: item.article.color?.code,
        quantity: item.quantity.quantity,
        selectedColor:
          colorName !== undefined
            ? {
                name: colorName,
              }
            : undefined,
        teamBundleSettings: undefined,
      };
    }
  };

export const setEgCartItems = (
  responseItems: EgCartItem[] = [],
  collectStoreName?: string,
  hasParentBundle?: boolean
): CartItem[] =>
  responseItems.map(
    mapEgCartItemToDisplayCartItem(collectStoreName, hasParentBundle)
  );

import React from "react";
import { TabHeader } from "./TabHeader";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { useSharedData } from "../../../contexts/SharedData";
import type { AssortmentLinkData } from "@xxl/frontend-api";
import stringFormat from "string-format";
import isEmpty from "lodash/isEmpty";
import noop from "lodash/noop";

const ONE_CLUB_SIZE = 1;

const ClubAssortmentLink: React.FunctionComponent<{
  clubAssortmentLinks: AssortmentLinkData[] | null;
  index: number;
  onHover: () => void;
}> = ({ clubAssortmentLinks, index, onHover }) => {
  const { t } = useTranslations();
  const { requestMapping } = useSharedData().data;
  const { clubAssortment } = requestMapping;

  if (clubAssortmentLinks === null) {
    return (
      <TabHeader
        disabled={true}
        index={index}
        isSelected={false}
        label={t("teamsales.account.nav.assortment")}
        onHover={noop}
      />
    );
  }

  if (isEmpty(clubAssortmentLinks)) {
    return null;
  }

  if (clubAssortmentLinks.length > ONE_CLUB_SIZE) {
    return (
      <TabHeader
        index={index}
        isSelected={false}
        label={t("teamsales.account.nav.assortment")}
        onHover={onHover}
      />
    );
  }

  const [firstClubAssortmentLink] = clubAssortmentLinks;
  const { uid } = firstClubAssortmentLink;
  const href = stringFormat(clubAssortment, uid);

  return (
    <TabHeader
      href={href}
      index={index}
      isSelected={false}
      label={t("teamsales.account.nav.assortment")}
      onHover={noop}
    />
  );
};

export { ClubAssortmentLink };

import type { ProductData } from "@xxl/frontend-api";
import React, { useContext, useReducer, createContext } from "react";
import type { CartItemIdInput } from "../../generated/graphql-code-generator";
import type { ServiceObjectProps } from "../../global";
import type { GenericGraphQLError } from "../Cart/Api/types";

type ServiceProductState = {
  openedService: boolean;
  productCartEntryId: CartItemIdInput;
  isProductPage: boolean;
  chosenService?: string;
  services?: ServicesResponse;
  serviceAdded?: string;
  serviceEntryNumber?: number | false;
  addedServiceQuantity?: number;
  productQuantity?: number;
  servicesQuantity?: number;
  parentEntryNumber?: string;
  cartServices?: ServiceObjectProps[];
  generalErrors?: GenericGraphQLError[];
};

export type ApiResponseWrapper = {
  response: ServicesResponse[];
};

export type ServicesResponse = {
  key: string;
  numFound: number;
  productList: ProductData[];
};

type ServiceAddedProps = {
  serviceAdded: string;
  serviceEntryNumber: number | false;
  addedServiceQuantity?: number;
  parentId?: CartItemIdInput | false;
};

type ServicesCartInitProps = {
  cartServices: ServiceObjectProps[];
  productQuantity: number;
  parentEntryNumber: string;
};

export const SERVICES = {
  OPEN: "OPEN",
  CLOSE: "CLOSE",
  REQUEST_SUCCESS: "REQUEST_SUCCESS",
  ADDED: "ADDED",
  REMOVED: "REMOVED",
  DECREASE_QUANTITY: "DECREASE_QUANTITY",
  INCREASE_QUANTITY: "INCREASE_QUANTITY",
  CART_INIT: "CART_INIT",
  HAS_ERROR: "HAS_ERROR",
  SET_PRODUCT_CART_ENTRY_ID: "SET_PRODUCT_CART_ENTRY_ID",
} as const;

export type Actions =
  | { type: typeof SERVICES.OPEN }
  | { type: typeof SERVICES.CLOSE }
  | {
      type: typeof SERVICES.REQUEST_SUCCESS;
      payload: ServicesResponse;
    }
  | {
      type: typeof SERVICES.ADDED;
      payload: ServiceAddedProps;
    }
  | { type: typeof SERVICES.REMOVED }
  | { type: typeof SERVICES.DECREASE_QUANTITY }
  | { type: typeof SERVICES.INCREASE_QUANTITY }
  | { type: typeof SERVICES.CART_INIT; payload: ServicesCartInitProps }
  | { type: typeof SERVICES.HAS_ERROR; payload: GenericGraphQLError[] }
  | { type: "SET_PRODUCT_CART_ENTRY_ID"; payload: CartItemIdInput };

type Dispatch = (action: Actions) => void;

export const servicesReducer = (
  state: ServiceProductState,
  action: Actions
): ServiceProductState => {
  switch (action.type) {
    case SERVICES.OPEN:
      return {
        ...state,
        openedService: true,
      };
    case SERVICES.CLOSE:
      return {
        ...state,
        openedService: false,
        serviceAdded: undefined,
        serviceEntryNumber: undefined,
        generalErrors: undefined,
      };
    case SERVICES.REQUEST_SUCCESS:
      return {
        ...state,
        services: action.payload,
        generalErrors: undefined,
      };
    case SERVICES.ADDED:
      return {
        ...state,
        serviceAdded: action.payload.serviceAdded,
        serviceEntryNumber: action.payload.serviceEntryNumber,
        addedServiceQuantity: action.payload.addedServiceQuantity,
        generalErrors: undefined,
      };
    case SERVICES.REMOVED:
      return {
        ...state,
        serviceAdded: undefined,
        serviceEntryNumber: undefined,
        addedServiceQuantity: undefined,
      };
    case SERVICES.DECREASE_QUANTITY:
      return {
        ...state,
        servicesQuantity: (state.servicesQuantity ?? 1) - 1,
      };
    case SERVICES.INCREASE_QUANTITY:
      return {
        ...state,
        servicesQuantity: (state.servicesQuantity ?? 0) + 1,
      };
    case SERVICES.CART_INIT:
      return {
        ...state,
        cartServices: action.payload.cartServices,
        servicesQuantity: action.payload.cartServices
          .map((service) => service.quantity)
          .reduce((prev, curr) => prev + curr, 0),
        productQuantity: action.payload.productQuantity,
        parentEntryNumber: action.payload.parentEntryNumber,
      };
    case SERVICES.HAS_ERROR:
      return {
        ...state,
        generalErrors: action.payload,
      };
    case SERVICES.SET_PRODUCT_CART_ENTRY_ID:
      return {
        ...state,
        productCartEntryId: action.payload,
      };
    default:
      return state;
  }
};

type ServicesProviderProps = { children: React.ReactNode };

const ServicesContext = createContext<
  { state: ServiceProductState; dispatch: Dispatch } | undefined
>(undefined);

ServicesContext.displayName = "ServicesContext";

export const ServicesContextProvider = ({
  children,
}: ServicesProviderProps) => {
  const [state, dispatch] = useReducer(servicesReducer, {
    openedService: false,
    productCartEntryId: {
      id: 0,
      type: "SINGLE",
    },
    isProductPage: false,
  });

  return (
    <ServicesContext.Provider value={{ state, dispatch }}>
      {children}
    </ServicesContext.Provider>
  );
};

export const useServicesContext = () => {
  const ctx = useContext(ServicesContext);
  if (ctx === undefined) {
    throw new Error(
      "useServicesContext must be used within a ServicesContext provider"
    );
  }
  return ctx;
};

import type { CartItem } from "../Api/types";
import type { AddToCartEventData } from "../../../global";
import { isEgAddToCartEvent } from "./utils";
import type { ProductTrackingData } from "../../../utils/Tracking";

export const getProductTrackingData = (
  item: CartItem | AddToCartEventData,
  salesPrice: string,
  priceType: string
): ProductTrackingData =>
  isEgAddToCartEvent(item)
    ? getProductTrackingDataForAddToCart(item, salesPrice, priceType)
    : getProductTrackingDataForCartItem(item, salesPrice, priceType);

const getProductTrackingDataForCartItem = (
  item: CartItem,
  salesPrice: string,
  priceType: string
): ProductTrackingData => ({
  name: item.title,
  id: item.selectedStyle,
  brand: item.brand,
  category: item.category,
  price: salesPrice,
  priceType,
});

const getProductTrackingDataForAddToCart = (
  item: AddToCartEventData,
  salesPrice: string,
  priceType: string
): ProductTrackingData => ({
  name: item.productName ?? "",
  id: item.sizeCode,
  brand: item.brandName,
  category: item.categoryCode,
  price: salesPrice,
  priceType,
});

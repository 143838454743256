import * as React from "react";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { StockStatusEnum } from "../../../enums";

type OptionNodeStockStatusProps = {
  stockStatus?: StockStatusEnum;
};
export const OptionNodeStockStatus: React.FC<OptionNodeStockStatusProps> = ({
  stockStatus,
}) => {
  const { t } = useTranslations();

  switch (stockStatus) {
    case StockStatusEnum.LOWSTOCK:
      return (
        <span className="select-box-items__item-low-qty">
          {t("product.details.size.low.stock")}
        </span>
      );
    case StockStatusEnum.OUTOFSTOCK:
      return (
        <span className="select-box__item-out-of-stock">
          {t("product.details.size.out.of.stock")}
        </span>
      );

    default:
      return null;
  }
};

import isEmpty from "lodash/isEmpty";
import React from "react";
import type { FunctionComponent, PropsWithChildren } from "react";
import type { AppBasedSharedData } from "react-app/src/contexts/SharedData";
import { SharedDataContext } from "react-app/src/contexts/SharedData";
import type { EnvironmentData } from "src/global";

const EnvironmentDataProvider: FunctionComponent<
  PropsWithChildren<{ environmentData?: EnvironmentData }>
> = ({ children, environmentData }) => {
  if (isEmpty(environmentData)) {
    return <>{children}</>;
  }

  const contextValue: AppBasedSharedData = {
    isReactApp: false,
    data: environmentData,
  };

  return (
    <SharedDataContext.Provider value={contextValue}>
      {children}
    </SharedDataContext.Provider>
  );
};

export { EnvironmentDataProvider };

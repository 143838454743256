import { useEffect, useState } from "react";
import { useApiClients } from "../../../../contexts/ApiClients";
import { useSharedData } from "../../../../contexts/SharedData";
import { legacySiteUidToSiteUid } from "../../../../utils/xxl-shared-data";
import type { StoreWithId } from "./helpers";
import { getSortedStoresList, hasIdAndName } from "./helpers";
import { log } from "@xxl/logging-utils";

const useStores = () => {
  const { siteUid } = useSharedData().data;
  const [isStoresLoading, setIsLoading] = useState(false);
  const [storesData, setStores] = useState<StoreWithId[]>([]);
  const { contentApi } = useApiClients();

  useEffect(() => {
    const getStores = async () => {
      setIsLoading(true);
      try {
        const response = await contentApi.getStores(
          legacySiteUidToSiteUid(siteUid)
        );
        const storeList = await getSortedStoresList(response.data.result ?? []);
        const storeWithIdAndName = [];
        for (const store of storeList) {
          if (hasIdAndName(store)) {
            storeWithIdAndName.push(store);
          }
        }
        setStores(storeWithIdAndName);
      } catch (error) {
        log.error("Could not fetch stores.", error);
      } finally {
        setIsLoading(false);
      }
    };
    void getStores();
  }, []);

  return { storesData, isStoresLoading };
};

export { useStores };

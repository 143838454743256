import { XxlStack } from "@/react-components/Common/XxlStack";
import Script from "next/script";
import ReactHtmlParser from "html-react-parser";
import { xxlTheme } from "@/react-app/styles/xxl-theme";
import styled from "@emotion/styled/macro";
import { color } from "@xxl/theme";
import { MQ } from "@/react-app/styles/helpers";
import { Icon } from "@/react-components/Icon";
import { isNotNullOrUndefined } from "@xxl/common-utils";

const VOLUMENTAL_WIDGET_IDENTIFIER = "<volumental-widget-identifier>";
const VOLUMENTAL_SKD_URL = "https://js.volumental.com/sdk/v1/volumental.js";

const VOLUMENTAL_WIDGET_HEIGHT = {
  SMALL: xxlTheme.spaces.giantBig,
  LARGE: xxlTheme.spaces.smallHuge,
};

const VolumentalWrapper = styled(XxlStack)`
  min-height: ${VOLUMENTAL_WIDGET_HEIGHT.SMALL};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${xxlTheme.spaces.regular};
  background-color: ${color.white.hex};
  border: 1px solid ${color.mediumGray.hex};
  color: ${color.webBlack.hex};
  cursor: pointer;

  ${MQ("laptop")} {
    min-height: ${VOLUMENTAL_WIDGET_HEIGHT.LARGE};
  }
`;

const Btn = styled.div`
  cursor: pointer;
  border: none;
  background-color: transparent;
`;

const VOLUMENTAL_SIZE_LOCALE = {
  EU: "EU",
} as const;

type Props = {
  productId?: string;
  sizeLocale?: keyof typeof VOLUMENTAL_SIZE_LOCALE;
  volumentalId?: string;
};

export const VolumentalComponent = ({
  productId,
  sizeLocale = VOLUMENTAL_SIZE_LOCALE.EU,
  volumentalId,
}: Props) => {
  const volumentalElement = `<volumental-recommendation
    product-id="${productId}"
    size-locale="${sizeLocale}"
    id="${VOLUMENTAL_WIDGET_IDENTIFIER}"
    variant="link"
  />`;

  const handleClick = () => {
    const volumentalWidget = document.getElementById(
      VOLUMENTAL_WIDGET_IDENTIFIER
    );
    if (volumentalWidget !== null) {
      volumentalWidget.click();
    }
  };
  return (
    <>
      {isNotNullOrUndefined(productId) && (
        <VolumentalWrapper direction="row" onClick={handleClick}>
          <Btn role="button" onClick={(e) => e.stopPropagation()} tabIndex={0}>
            {ReactHtmlParser(volumentalElement)}
          </Btn>
          <Icon name="CaretRight" size={24} />
        </VolumentalWrapper>
      )}
      <Script
        src={VOLUMENTAL_SKD_URL}
        strategy="lazyOnload"
        data-client-id={volumentalId}
      ></Script>
    </>
  );
};

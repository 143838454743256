import type { ConfigurationParameters } from "@xxl/frontend-api";
import { default as axios } from "axios";
import type { GraphQLError as RawGraphQLError } from "graphql";
import { windowAccess } from "../utils/Window";
import { refreshAuthCookie } from "./refreshAuthCookie";
import { log } from "@xxl/logging-utils";

export type GraphQLError<T = object, E = unknown> = Partial<{
  [K in keyof RawGraphQLError]: RawGraphQLError[K];
}> & {
  message: string;
  data?: T | null;
  errorType?: string | null;
  errorInfo?: E | null;
};

export interface GraphQLResult<T = object, E = unknown> {
  data: T | null;
  errors?: GraphQLError<T, E>[];
  extensions?: {
    [key: string]: unknown;
  };
}

export type GraphQLCallInput = {
  query: string; //query, mutation or subscription
  variables?: Record<string, unknown>;
  forceAnonymous?: boolean;
};

export type GraphQlCallConfig = {
  frontendApi: ConfigurationParameters;
  amplifyConfig?: {
    aws_appsync_graphqlEndpoint: string;
  };
};

const getApiKey = (graphQLApiKey?: string): string =>
  graphQLApiKey ??
  windowAccess()._sharedData.configuration.amplifyConfig.aws_appsync_apiKey;

export const callGraphQL = async <T, E = unknown>(
  { query, variables = {}, forceAnonymous = false }: GraphQLCallInput,
  graphQLEndpointUrl: string,
  graphQLApiKey?: string
): Promise<GraphQLResult<T, E>> => {
  const isSSR = typeof window === "undefined";
  const isLoggedIn =
    isSSR || forceAnonymous ? false : await refreshAuthCookie();
  const queryResponse = await axios.post<GraphQLResult<T, E> | "">(
    graphQLEndpointUrl,
    {
      query,
      variables,
    },
    {
      headers: {
        "content-type": "application/json; charset=UTF-8",
        ...(isLoggedIn ? undefined : { "x-api-key": getApiKey(graphQLApiKey) }),
      },
      withCredentials: true, // required to automatically append cookies in cross domain requests
      validateStatus: () => true,
    }
  );
  // add data: null to response if it is missing, on eg 403/502 errors
  const result =
    typeof queryResponse.data === "object" && "data" in queryResponse.data
      ? queryResponse.data
      : {
          data: null,
          errors: [
            {
              message: `Unknown error while calling GraphQL, http status: ${queryResponse.status}`,
            },
          ],
        };
  result.errors?.length &&
    log.error("Error while calling GraphQL", result.errors);
  return result;
};

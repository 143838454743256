import { Stack } from "@mui/material";
import { hasValue } from "@xxl/common-utils";
import type { UspItem } from "@xxl/pim-api";
import { ErrorBoundary } from "react-app/src/components/Common/ErrorBoundary/ErrorBoundary";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { useSharedData } from "react-app/src/contexts/SharedData";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { FavoriteProductHeart } from "../../../../../react-app/src/components/Favorites/FavoriteProductHeart";
import { useClient } from "../../../../../react-app/src/hooks/useClient/useClient";
import type { PriceProps } from "../Price/Price";
import { Price } from "../Price/Price";
import { OverviewGridArea } from "../ProductDetailsPage.styles";
import type { StrictClassification } from "../types";
import { Attributes } from "./Attributes";
import { AverageRating } from "./AverageRating";
import { ProductHeading } from "./ProductHeading";
import { SeeDescription } from "./SeeDescription";

type Props = {
  averageRating: number | null;
  baseProductName: string;
  brandName: string;
  brandUrl: string;
  brandLogoUrl: string;
  classifications: StrictClassification[];
  priceData: PriceProps;
  shouldDisplaySeeFullDescriptionLink?: boolean;
  widget?: JSX.Element;
  onRatingsClick?: () => void;
  productStyle?: string;
  recommendationPercentage?: number | null;
  shouldShowWriteReviewLink?: boolean;
  usp?: UspItem[];
};

export const ProductOverview = ({
  brandName,
  brandUrl,
  brandLogoUrl,
  baseProductName,
  onRatingsClick,
  priceData,
  classifications,
  averageRating,
  shouldDisplaySeeFullDescriptionLink = true,
  widget,
  productStyle,
  recommendationPercentage,
  shouldShowWriteReviewLink = true,
  usp = [],
}: Props) => {
  const isClient = useClient();
  const {
    featureToggles: { toggle_videoly },
  } = useSharedData().data;

  return (
    <OverviewGridArea
      spacing={xxlTheme.spaces.regular}
      className="pdp-overview"
    >
      <XxlStack flexDirection={"row"} justifyContent={"space-between"}>
        <ProductHeading
          baseProductName={baseProductName}
          brandName={brandName}
          brandLogoUrl={brandLogoUrl}
          brandUrl={brandUrl}
        />
        <ErrorBoundary>
          {hasValue(productStyle) && isClient && (
            <FavoriteProductHeart productStyle={productStyle} isInPDP={true} />
          )}
        </ErrorBoundary>
      </XxlStack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={"start"}
        gap={xxlTheme.spaces.extraLarge}
      >
        <Attributes usps={usp} classifications={classifications} />
        <AverageRating
          onClick={onRatingsClick}
          averageRating={averageRating}
          shouldShowWriteReviewLink={shouldShowWriteReviewLink}
          recommendationPercentage={recommendationPercentage}
        />
      </Stack>
      {shouldDisplaySeeFullDescriptionLink && <SeeDescription />}
      {toggle_videoly && <div id="videoly-videobox-placeholder"></div>}
      <ErrorBoundary>
        <Price
          hasUndeductedRewardDiscount={priceData.hasUndeductedRewardDiscount}
          isDiscountedMainPrice={priceData.isDiscountedMainPrice}
          isInvertedPrice={priceData.isInvertedPrice}
          latestPriceFormatted={priceData.latestPriceFormatted}
          secondaryPriceFormatted={priceData.secondaryPriceFormatted}
          salesPriceFormatted={priceData.salesPriceFormatted}
          secondaryPriceDisclaimer={priceData.secondaryPriceDisclaimer}
          priceDisclaimer={priceData.priceDisclaimer}
          userGroupId={priceData.userGroupId}
          widget={widget}
        />
      </ErrorBoundary>
    </OverviewGridArea>
  );
};

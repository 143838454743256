import type { NextJsTranslations } from "react-app/src/utils/xxl-translate";
import { getSiteUid } from "../environment-variables";
import {
  getCachedNextJsTranslations,
  setCachedNextJsTranslations,
} from "../server-side-cache/server-side-cache";
import { Apis } from "@/utils/api-helper";

export const fetchTranslations = async (): Promise<NextJsTranslations> => {
  let nextJsTranslations: NextJsTranslations | null =
    await getCachedNextJsTranslations();
  if (nextJsTranslations === null) {
    const { data } = await Apis.getInstance().contentApi.getTranslations(
      getSiteUid(),
      "web"
    );
    const { result = [] } = data;
    nextJsTranslations = result.reduce<NextJsTranslations>(
      (acc, { key, value }) => {
        if (typeof key !== "string" || typeof value !== "string") {
          return acc;
        }
        return {
          ...acc,
          [key]: value,
        };
      },
      {}
    );
    void setCachedNextJsTranslations(nextJsTranslations);
  }
  return nextJsTranslations;
};

import type { ReactNode } from "react";
import { Text } from "../../Text";

const MainContent = ({ children }: { children: ReactNode }) => {
  return (
    <main style={{ marginBottom: 0 }}>
      <Text
        as="p"
        style={{ whiteSpace: "pre-line", margin: "0" }}
        color="black"
      >
        {children}
      </Text>
    </main>
  );
};

export default MainContent;

import React from "react";
import type { SVGProps } from "react";

const CheckSvg: React.FunctionComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.04 19.185 2 12.145l1.41-1.42 5.63 5.63 11.54-11.54L22 6.225 9.04 19.185Z"
      fill="currentColor"
    />
  </svg>
);

export { CheckSvg };

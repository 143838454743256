import styled from "@emotion/styled/macro";
import { color } from "@xxl/theme";
import { xxlTheme } from "../../../src/styles/xxl-theme";
import { XxlStack } from "../Common/XxlStack";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${xxlTheme.spaces.micro};
`;

export const StickyFormContent = styled.div<{
  shouldShowStickyContent: boolean;
}>`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: ${xxlTheme.zIndex.navigation};
  transition: all 200ms ease-in-out;
  width: 100%;

  ${({ shouldShowStickyContent = false }) =>
    !shouldShowStickyContent && "bottom: -100%;"}
`;

export const MessageContainer = styled(XxlStack)`
  background-color: ${color.lightAmber.cssVariable};
  padding: ${xxlTheme.spaces.smallRegular};
`;

import type { Dispatch } from "react";
import {
  SUCCESS_RESPONSE_STATUS,
  updateCheckoutSnippet as updateCheckoutCart,
  updateGiftCardsCheckoutSnippet,
  updateProductsQuantity,
  updateReplacementCheckoutSnippet,
} from "../Api/CartAPI";
import type { Action } from "../CartState";
import {
  CART_CONTENT_EDITING_LOCK,
  CART_CONTENT_EDITING_UNLOCK,
  CART_EDITION_START,
  CART_EDITION_STOP,
  isKlarnaCheckoutSnippetLoaded,
  isWalley,
  PRODUCT_NOT_ENOUGH_QUANTITY,
  SET_SHOULD_RELOAD_ON_ERROR,
  UPDATE_CART_CONTENT,
  UPDATE_CART_ID,
  UPDATE_CUSTOMER_TYPE,
  UPDATE_CUSTOMER_TYPE_OPTIONS,
  UPDATE_PAYMENT_SNIPPET_HTML,
} from "../CartState";
import type { UpdateQuantityGraphQLError } from "../Api/types";
import { DEFAULT_QUANTITY } from "../constants";
import type {
  CustomerType,
  PaymentProvider,
  CartItemType,
  GiftCardEntry,
} from "../../../generated/graphql-code-generator";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { ExchangeInputProps } from "../../Cart/Api/CartAPI";
import { log } from "@xxl/logging-utils";

export const CHECKOUT_SNIPPET_UPDATE_TIMEOUT = 1500;

export const updateCheckoutSnippet = async (
  dispatch: Dispatch<Action>,
  graphQLEndpointUrl: string,
  graphQLApiKey: string,
  customerTypeOptions: CustomerType[] = [],
  customerType?: CustomerType
): Promise<void> => {
  try {
    const response = await updateCheckoutCart(
      graphQLEndpointUrl,
      graphQLApiKey,
      customerType
    );

    if (response.status === SUCCESS_RESPONSE_STATUS) {
      if (isWalley(response.paymentProvider)) {
        if (isNotNullOrUndefined(response.customerType)) {
          dispatch({
            type: UPDATE_CUSTOMER_TYPE,
            payload: response.customerType,
          });
        }
        if (
          isNotNullOrUndefined(response.customerTypeOptions) &&
          response.customerTypeOptions.length !== customerTypeOptions.length
        ) {
          dispatch({
            type: UPDATE_CUSTOMER_TYPE_OPTIONS,
            payload: response.customerTypeOptions,
          });
        }
        if (isNotNullOrUndefined(response.snippet)) {
          dispatch({
            type: UPDATE_PAYMENT_SNIPPET_HTML,
            payload: response.snippet,
          });
        }
        dispatch({
          type: UPDATE_CART_ID,
          payload: response.cartId,
        });
        window.walley?.checkout.api.resume();
      } else if (isKlarnaCheckoutSnippetLoaded()) {
        window._klarnaCheckout((api) => {
          api.resume();
        });
      }
      setTimeout(() => {
        dispatch({
          type: CART_CONTENT_EDITING_UNLOCK,
        });
      }, CHECKOUT_SNIPPET_UPDATE_TIMEOUT);
    } else {
      dispatch({
        type: SET_SHOULD_RELOAD_ON_ERROR,
        payload: true,
      });
    }
  } catch (err) {
    log.error("Cannot update checkout snippet", err);
  }
};

export const updateGiftCardsSnippet = async (
  dispatch: Dispatch<Action>,
  graphQLEndpointUrl: string,
  graphQLApiKey: string,
  giftCardEntries: GiftCardEntry[],
  customerType?: CustomerType
): Promise<void> => {
  try {
    const response = await updateGiftCardsCheckoutSnippet(
      graphQLEndpointUrl,
      graphQLApiKey,
      giftCardEntries,
      customerType
    );

    if (response.status === SUCCESS_RESPONSE_STATUS) {
      if (
        isNotNullOrUndefined(
          response.data.data?.initiateGiftCardPurchase.customerType
        )
      ) {
        dispatch({
          type: UPDATE_CUSTOMER_TYPE,
          payload: response.data.data.initiateGiftCardPurchase.customerType,
        });
      }
      if (
        isNotNullOrUndefined(
          response.data.data?.initiateGiftCardPurchase.htmlSnippet
        )
      ) {
        dispatch({
          type: UPDATE_PAYMENT_SNIPPET_HTML,
          payload: response.data.data.initiateGiftCardPurchase.htmlSnippet,
        });
      }
      dispatch({
        type: UPDATE_CART_ID,
        payload: response.data.data?.initiateGiftCardPurchase.cartId,
      });
      window.walley?.checkout.api.resume();
      setTimeout(() => {
        dispatch({
          type: CART_CONTENT_EDITING_UNLOCK,
        });
      }, CHECKOUT_SNIPPET_UPDATE_TIMEOUT);
    }
  } catch (err) {
    log.error("Cannot update checkout snippet", err);
  }
};
export const updateReplacementSnippet = async (
  dispatch: Dispatch<Action>,
  graphQLEndpointUrl: string,
  graphQLApiKey: string,
  replacementInput: ExchangeInputProps,
  customerType?: CustomerType
): Promise<void> => {
  try {
    const response = await updateReplacementCheckoutSnippet(
      graphQLEndpointUrl,
      graphQLApiKey,
      replacementInput,
      customerType
    );

    if (response.status === SUCCESS_RESPONSE_STATUS) {
      if (
        isNotNullOrUndefined(response.data.data?.getReplacement?.customerType)
      ) {
        dispatch({
          type: UPDATE_CUSTOMER_TYPE,
          payload: response.data.data.getReplacement.customerType,
        });
      }
      if (
        isNotNullOrUndefined(response.data.data?.getReplacement?.htmlSnippet)
      ) {
        dispatch({
          type: UPDATE_PAYMENT_SNIPPET_HTML,
          payload: response.data.data.getReplacement.htmlSnippet,
        });
      }
      dispatch({
        type: UPDATE_CART_ID,
        payload: response.data.data?.getReplacement?.cartId,
      });
      window.walley?.checkout.api.resume();
      setTimeout(() => {
        dispatch({
          type: CART_CONTENT_EDITING_UNLOCK,
        });
      }, CHECKOUT_SNIPPET_UPDATE_TIMEOUT);
    }
  } catch (err) {
    log.error("Cannot update checkout snippet", err);
  }
};

type UpdateCartQuantityProps = {
  quantity: number;
  entryNumber: number;
  entryType: string;
  dispatch: Dispatch<Action>;
  hasMiniCart: boolean;
  hasErrors: boolean;
  isServicesModalOpened: boolean;
  graphQLEndpointUrl: string;
  graphQLApiKey: string;
  deliveryPointOfServiceName?: string;
  ean?: string;
  unit?: string;
  paymentProvider: PaymentProvider;
};
export const _updateCartQuantity = async ({
  quantity,
  entryNumber,
  entryType,
  dispatch,
  hasMiniCart,
  hasErrors = false,
  isServicesModalOpened = false,
  graphQLEndpointUrl,
  graphQLApiKey,
  ean,
  unit,
  paymentProvider,
}: UpdateCartQuantityProps): Promise<void | number> => {
  try {
    if (
      (!hasMiniCart && !hasErrors) ||
      (!hasMiniCart && isWalley(paymentProvider) && hasErrors)
    ) {
      dispatch({
        type: CART_CONTENT_EDITING_LOCK,
      });
      if (isKlarnaCheckoutSnippetLoaded()) {
        window._klarnaCheckout((api) => {
          api.suspend({
            autoResume: {
              enabled: false,
            },
          });
        });
      }
      if (isWalley(paymentProvider)) {
        window.walley?.checkout.api.suspend();
      }
    }
    dispatch({
      type: CART_EDITION_START,
    });
    const ApiResponse = await updateProductsQuantity(
      String(quantity),
      {
        id: entryNumber,
        type: entryType as CartItemType,
      },
      graphQLEndpointUrl,
      graphQLApiKey,
      ean,
      unit
    );

    if (ApiResponse.status === SUCCESS_RESPONSE_STATUS) {
      if (!isServicesModalOpened) {
        dispatch({
          type: UPDATE_CART_CONTENT,
        });
      }
      dispatch({
        type: CART_EDITION_STOP,
      });
    } else if (ApiResponse.data.errors !== undefined) {
      dispatch({
        type: UPDATE_CART_CONTENT,
      });
      dispatch({
        type: CART_EDITION_STOP,
      });

      if (
        (ApiResponse.data.errors as UpdateQuantityGraphQLError[]).find(
          (error) => error.errorType === "OUT_OF_STOCK"
        ) !== undefined
      ) {
        dispatch({
          type: PRODUCT_NOT_ENOUGH_QUANTITY,
          payload: entryNumber,
        });
      }
    }
  } catch (err) {
    log.error("Cannot update cart quantity", err);
  }
};

export const parseQuantity = (quantityStr: string): number => {
  const quantity = parseInt(quantityStr);
  return isNaN(quantity) ? DEFAULT_QUANTITY : quantity;
};

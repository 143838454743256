import type { CouponDataProps } from "../Api/types";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { isNotNullOrUndefined } from "@xxl/common-utils";

export type PromotionResultData = {
  description: string;
  promotion: {
    code: string;
  };
};

export const setPromotionsData = (
  coupons?: (CouponDataProps | null)[]
): PromotionResultData[] | undefined => {
  if (coupons === undefined) {
    return undefined;
  }
  const { t } = useTranslations();
  return coupons
    .filter(isNotNullOrUndefined)
    .map(({ code, couponFormattedAmount }) => ({
      description: t(
        isNotNullOrUndefined(couponFormattedAmount)
          ? {
              key: "checkout.coupon.applied.message",
              messageArguments: [String(couponFormattedAmount), code],
            }
          : {
              key: "checkout.coupon.applied.restricted.message",
              messageArguments: [code],
            }
      ),
      promotion: { code },
    }));
};

import type { FunctionComponent } from "react";
import React from "react";
import { MiniCartButton as Button } from "../Styles/MiniCartContent.styled";
import { MiniCartCount } from "./MiniCartCount";
import { ShoppingCart } from "@xxl/icons";
import type { MiniCartButtonProps } from "../types";

export const TEST_ID = "mini-cart-count";
export const CLASS_NAME = "test-mini-cart__number";

export const MiniCartButton: FunctionComponent<MiniCartButtonProps> = ({
  isLoading,
  numberOfItemsInCart,
  onClick,
}) => (
  <Button
    className="mini-cart-button"
    onClick={onClick}
    data-testid="open-mini-cart"
  >
    <ShoppingCart fontSize={24} />
    <MiniCartCount
      isLoading={isLoading}
      count={numberOfItemsInCart}
      testId={TEST_ID}
      classNameString={CLASS_NAME}
    />
  </Button>
);

import Tab from "@mui/material/Tab";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { FunctionComponent, MouseEvent } from "react";
import React from "react";
import colors from "./MegaMenu.colors";
import { TabButtonLabel } from "./TabButtonLabel.styled";

type TabHeaderProps = {
  disabled?: boolean;
  href?: string;
  index: number;
  label: string;
  isSelected: boolean;
  onClick?: (event: MouseEvent) => void;
  onHover: () => void;
  removeRightPadding?: boolean;
};

const TabHeader: FunctionComponent<TabHeaderProps> = ({
  disabled,
  href,
  index,
  isSelected,
  label,
  onClick,
  onHover,
  removeRightPadding = false,
}) => {
  return (
    <Tab
      disabled={disabled}
      {...(isNotNullOrUndefined(href) ? { href } : {})}
      key={`tab-${index}`}
      id={`tab-${index}`}
      label={
        <TabButtonLabel
          label={label}
          onHover={onHover}
          removeRightPadding={removeRightPadding}
        />
      }
      onClick={onClick}
      sx={{
        cursor: "pointer",
        opacity: 1,
        color: isSelected ? colors.activeColor : colors.tabButtonTextColor,
      }}
    />
  );
};

export { TabHeader };

const fontFamily = "var(--font-family-bold)";

export default {
  date: {
    fontSize: 10,
  },
  heading: {
    fontFamily,
    fontSize: 26,
  },
  link: {
    fontSize: 15,
  },
};

import styled from "@emotion/styled/macro";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { typographyToCss, MQ } from "react-app/src/styles/helpers";

const { spaces, colors } = xxlTheme;

const commonStyles = {
  lineHeight: 1.2,
  letterSpecing: -0.15,
  fontSize: 15,
};

const typography = {
  heading: {
    ...commonStyles,
    fontFamily: "var(--font-family-bold)",
  },
  link: {
    ...commonStyles,
    fontFamily: "var(--font-family-bold)",
    textDecoration: "underline",
  },
  hazardousStatement: {
    ...commonStyles,
    fontFamily: "var(--font-family-regular)",
  },
};

const { heading, link, hazardousStatement } = typography;

export const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: ${spaces.smallRegular};
`;

export const ListItem = styled.li`
  padding: ${spaces.smallRegular};
  background-color: ${colors.xxlLightGreen};
  display: flex;
  flex-direction: column;
  gap: ${spaces.smallRegular};

  ${MQ("laptop")} {
    padding: ${spaces.large};
  }
`;

export const Heading = styled.h3`
  margin: 0;
  color: ${colors.xxlWebBlack};
  ${typographyToCss(heading)};
`;

export const Link = styled.a`
  margin: 0;
  ${typographyToCss(link)};
  color: ${colors.xxlWebBlack};
  display: inline-block;
  width: fit-content;

  &:hover,
  &:focus-visible {
    cursor: pointer;
  }
`;

export const HazardousDataList = styled.ul`
  list-style: square;
  padding: 0 ${spaces.smallLarge};
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: ${spaces.mini};
`;

export const HazardousDataListItem = styled.li`
  ${typographyToCss(hazardousStatement)};
`;

export const HazardousDataDescription = styled.p`
  margin: 0;
  ${typographyToCss(hazardousStatement)};
`;

export const SymbolsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: ${spaces.mini};
`;

export const SymbolsItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
`;

export const SymbolsImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;

import type { PropsWithChildren } from "react";
import React, { forwardRef } from "react";
import { SliderBanner } from "./SliderBanner";
import { NextImage } from "../../common/NextImage/NextImage";
import {
  PrimaryImageSlide,
  ProductImageSliderWrapperDesktop,
} from "./ProductImage.styles";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import type { ProductData } from "@xxl/product-configurator-api";
import { KEEN_SLIDER_CLASSES } from "react-app/src/components/Common/Slider/Slider.helper";
import { IMAGE_SIZE } from "../constants";
import { mobileTabletAndLaptopMediaQuery } from "react-app/src/utils/xxl-screen";

type Props = {
  campaignComponent: JSX.Element | null;
  handleClickOpen: (img: string) => void;
  productImages: ProductData["productImages"];
  productName: string;
  LastPlaceComponent?: JSX.Element;
};

const ProductImages = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  (
    {
      campaignComponent,
      children,
      handleClickOpen,
      productImages = [],
      LastPlaceComponent,
      productName,
    },
    sliderRef
  ) => {
    const { t } = useTranslations();
    return (
      <ProductImageSliderWrapperDesktop>
        {children}
        <div ref={sliderRef} className={KEEN_SLIDER_CLASSES.Slider}>
          {campaignComponent !== null && (
            <SliderBanner.Splash>{campaignComponent}</SliderBanner.Splash>
          )}

          {productImages.map((productImage, idx) => (
            <div key={productImage} className={KEEN_SLIDER_CLASSES.Slide}>
              <PrimaryImageSlide>
                <NextImage
                  src={productImage}
                  width={IMAGE_SIZE.DESKTOP}
                  height={IMAGE_SIZE.DESKTOP}
                  onClick={() => handleClickOpen(productImage)}
                  priority={idx === 0}
                  alt={`${productName} - ${t(
                    "product.details.alt.image.full"
                  )} ${idx + 1}`}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                  sizes={`${mobileTabletAndLaptopMediaQuery} 75vw, 500px`}
                />
              </PrimaryImageSlide>
            </div>
          ))}
          {LastPlaceComponent !== undefined && LastPlaceComponent}
        </div>
      </ProductImageSliderWrapperDesktop>
    );
  }
);

export { ProductImages };

ProductImages.displayName = "ProductImages";

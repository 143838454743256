import React from "react";

import type { FunctionComponent, PropsWithChildren } from "react";
import type { AppBasedSharedData } from "../../../contexts/SharedData/SharedDataContext";
import { SharedDataContext } from "../../../contexts/SharedData/SharedDataContext";
import type { SharedData } from "../../../global";

const ReactAppSharedDataProvider: FunctionComponent<
  PropsWithChildren<{ sharedData?: SharedData }>
> = ({ children, sharedData = window._sharedData }) => {
  const data: AppBasedSharedData = {
    isReactApp: true,
    data: sharedData,
  };

  return (
    <SharedDataContext.Provider value={data}>
      {children}
    </SharedDataContext.Provider>
  );
};

export { ReactAppSharedDataProvider };

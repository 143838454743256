import type {
  TeamBundleSettings,
  TeamBundleDescription,
  TeamMemberSettings,
  EgCartItem,
  ExtendedTeamBundleQuantity,
  PrintConfigurationsObject,
} from "../Api/types";
import { isNotNullOrUndefined } from "@xxl/common-utils";

export const setEGBundleWithPrintConfigurations = (
  cartItem: EgCartItem
): TeamBundleSettings | undefined => {
  if (isNotNullOrUndefined(cartItem.bundledProducts)) {
    const printProducts = cartItem.bundledProducts.filter(
      ({ itemId }) => itemId.type === "PRINT"
    );
    const sizeProducts = cartItem.bundledProducts.filter(
      ({ itemId }) => itemId.type !== "PRINT"
    );
    const bundleSettings: TeamBundleDescription[] = [];
    const teamMembers: TeamMemberSettings[] = [];
    const bundleQuantity: ExtendedTeamBundleQuantity[] = [];

    sizeProducts.forEach((size) => {
      const printGroups = size.printConfigurations?.reduce(
        (result: PrintConfigurationsObject[][], value) => {
          const correctArray = result.find((arr) => arr[0].ean === value.ean);

          if (typeof value.print === "string" && value.print.length > 0) {
            if (correctArray !== undefined) {
              correctArray.push(value);
            } else {
              result.push([value]);
            }
          }
          return result;
        },
        []
      );
      const sizeObject: ExtendedTeamBundleQuantity = {
        quantity: size.quantity.quantity,
        size: size.article.size?.text ?? "",
        printConfigurations: printGroups,
      };

      bundleQuantity.push(sizeObject);
    });

    printProducts.forEach((product) => {
      const setting: TeamBundleDescription = {
        name: product.article.category?.name as string,
        text: product.article.name,
      };

      bundleSettings.push(setting);
    });

    const returnedSettings = {
      bundleSettings,
      teamMembers,
      bundleQuantity,
    };
    return returnedSettings;
  }

  return undefined;
};

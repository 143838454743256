import { containerMaxWidth } from "../styles/xxl-theme";
import { resolutionSizes } from "../config";

export const isTouch = (): boolean =>
  typeof window !== "undefined" &&
  window.matchMedia("(pointer: coarse)").matches;

export const smallMobileMediaQuery =
  `(max-width: ${resolutionSizes.smallMobile}px)` as const;

export const mobileMediaQuery =
  `(max-width: ${resolutionSizes.mobile}px)` as const;

export const mobileAndTabletMediaQuery =
  `(max-width: ${resolutionSizes.tablet}px)` as const;

export const mobileTabletAndLaptopMediaQuery = `(max-width: ${
  resolutionSizes.laptop - 1
}px)` as const;

export const mobileTabletLaptopAndSmallDesktopMediaQuery = `(max-width: ${
  resolutionSizes.smallDesktop - 1
}px)` as const;

export const mobileAndTabletNotHorizontalMediaQuery = `(max-width: ${
  resolutionSizes.tablet - 1
}px)` as const;

export const tabletAndDesktopMediaQuery = `(min-width: ${
  resolutionSizes.mobile + 1
}px)` as const;

export const laptopAndDesktopMediaQuery =
  `(min-width: ${resolutionSizes.laptop}px)` as const;

export const tabletMediaQuery =
  `(min-width: ${resolutionSizes.tablet}px)` as const;

export const desktopMediaQuery =
  `(min-width: ${resolutionSizes.smallDesktop}px)` as const;

export const laptopMediaQuery =
  `(min-width: ${resolutionSizes.laptop}px)` as const;

export const siteContainerMediaQuery =
  `(min-width: ${containerMaxWidth}px)` as const;
export const bigDesktopMediaQuery =
  `(min-width: ${resolutionSizes.bigDesktop}px)` as const;

export const MediaQuery = {
  SmallMobileMediaQuery: smallMobileMediaQuery,
  MobileMediaQuery: mobileMediaQuery,
  MobileAndTabletMediaQuery: mobileAndTabletMediaQuery,
  MobileTabletAndLaptopMediaQuery: mobileTabletAndLaptopMediaQuery,
  MobileTabletLaptopAndSmallDesktopMediaQuery:
    mobileTabletLaptopAndSmallDesktopMediaQuery,
  MobileAndTabletNotHorizontalMediaQuery:
    mobileAndTabletNotHorizontalMediaQuery,
  TabletAndDesktopMediaQuery: tabletAndDesktopMediaQuery,
  TabletMediaQuery: tabletMediaQuery,
  DesktopMediaQuery: desktopMediaQuery,
  LaptopMediaQuery: laptopMediaQuery,
  SiteContainerMediaQuery: siteContainerMediaQuery,
} as const;

export type MediaQueryKeys = keyof typeof MediaQuery;
export type MediaQueryValue = (typeof MediaQuery)[MediaQueryKeys];

import { ErrorBoundary } from "react-app/src/components/Common/ErrorBoundary/ErrorBoundary";
import type { EcoProduct } from "../../../Pdp/types";
import { List } from "./HazardousData.styled";
import { HazardousDataItem } from "./HazardousDataItem";

type HazardousDataProps = {
  ecoProducts: EcoProduct[];
};

export const HazardousData = ({ ecoProducts }: HazardousDataProps) => (
  <ErrorBoundary>
    <List>
      {ecoProducts.map((item, index) => {
        if (index > 4) {
          return null;
        }
        return <HazardousDataItem key={index} {...item} />;
      })}
    </List>
  </ErrorBoundary>
);

import styled from "@emotion/styled/macro";
import typography from "../styles/theme/typography";
import { typographyToCss } from "../styles/helpers";

// TODO: Use general styling. The styling below is copied from Input in AnonymousReturnsForm.tsx
export const Textarea = styled.textarea`
  ${typographyToCss(typography.mediumRegular)}
  width: calc(100% - 30px);
  padding: 12px 14px;
  color: #000;
  font-family: var(--font-family-regular);
  line-height: 19px;
  background-color: var(--color-xxl-white);
  border: 1px solid var(--color-xxl-medium-gray);
  outline: none;
  transform: border-color 200ms;
`;

import { Arrow } from "./ProductImage.styles";
import { Icon } from "react-app/src/components/Icon";
import type { PropsWithChildren } from "react";

export const ThumbnailArrows = ({
  children,
  currentSlide,
  handlePreviousClick,
  handleNextClick,
  sliderLength,
}: PropsWithChildren<{
  handleNextClick: () => void;
  handlePreviousClick: () => void;
  currentSlide: number;
  sliderLength: number;
}>) => {
  const isUpArrowDisabled = currentSlide === 0;
  const lastPlaceComponentIndex = sliderLength + 1;
  const isDownArrowDisabled =
    currentSlide === sliderLength || currentSlide === lastPlaceComponentIndex;

  return (
    <div>
      {children}
      <Arrow
        direction="up"
        onClick={handlePreviousClick}
        disabled={isUpArrowDisabled}
      >
        <Icon name="CaretUp" color="white" size={18} />
      </Arrow>
      <Arrow
        direction="down"
        onClick={handleNextClick}
        disabled={isDownArrowDisabled}
      >
        <Icon name="CaretUp" color="white" size={18} />
      </Arrow>
    </div>
  );
};

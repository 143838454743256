import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { typographyToCss, MQ } from "../../../styles/helpers";
import { xxlTheme } from "../../../styles/xxl-theme";
import typography from "./CartPage.typography";

const { requisitionHeading, requisitionsFormLabel } = typography;

const { spaces, colors } = xxlTheme;

type WrapperProps = {
  hasPadding?: boolean;
};

export const Wrapper = styled.div<WrapperProps>(
  ({ hasPadding = true }) => css`
    background-color: ${colors.xxlWhite};
    padding: ${hasPadding ? spaces.smallRegular : 0};
    margin-top: ${hasPadding ? spaces.smallRegular : 0};
    display: flex;
    flex-direction: column;
  `
);

export const Heading = styled.h2`
  margin: 0 0 ${spaces.smallRegular};
  ${typographyToCss(requisitionHeading)};
  color: ${colors.xxlWebBlack};
`;

export const Description = styled.p`
  margin: 0 0 ${spaces.large};
`;

export const Label = styled.label`
  display: block;
  margin: ${spaces.smallLarge} 0 ${spaces.micro};
  color: ${colors.xxlWebBlack};
  ${typographyToCss(requisitionsFormLabel)};
`;

export const Fieldset = styled.fieldset`
  margin: 0;
  padding: 0;
  border: 0;
`;

export const SubmitButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: ${spaces.smallLarge} 0 0;

  ${MQ("tablet")} {
    margin-top: ${spaces.large};
  }
`;

export const DialogBoxHeading = styled(Heading)`
  margin-top: ${spaces.smallRegular};

  ${MQ("tablet")} {
    margin-top: ${spaces.large};
  }
`;

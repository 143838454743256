import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import React from "react";
import { interactionFeedback, typographyToCss } from "../../../styles/helpers";
import colors from "./MegaMenu.colors";
import text from "./MegaMenu.typography";
import spaces from "../../../styles/theme/spaces";
import typography from "../../../styles/theme/typography";

type LinkProps = {
  href?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  iconLink?: boolean;
  theme?: any;
};

const LinkStyled = styled.a<LinkProps>(
  ({ iconLink = false }) => css`
    ${typographyToCss(text.link)};
    align-items: ${iconLink ? "center" : "flex-start"};
    color: ${colors.textColor};
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${iconLink ? "row" : "column"};
    gap: ${iconLink ? `0 ${spaces.mini}` : 0};
    overflow: hidden;
    text-overflow: ellipsis;
    transition: gap 200ms;

    &:hover,
    &:focus {
      ${interactionFeedback(`
        font-weight: bold;
      `)}
    }

    ${iconLink && typographyToCss(text.showMoreLink)}
  `
);

export const Link: React.FunctionComponent<LinkProps> = ({
  children,
  href,
  iconLink,
  style = {
    ...typography.mediumRegular,
    lineHeight: "18px",
  },
}) => <LinkStyled {...{ iconLink, style, href }}>{children}</LinkStyled>;

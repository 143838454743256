import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { xxlTheme } from "../../../../src/styles/xxl-theme";
const { typography, spaces, colors } = xxlTheme;

export const RatingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${xxlTheme.spaces.mini};
`;

export const StarRatingLink = styled.a<{ isLink: boolean }>(
  ({ isLink }) => css`
    display: flex;
    justify-content: flex-end;
    gap: ${spaces.micro};
    font-size: ${isLink
      ? typography.mediumRegular.fontSize
      : typography.smallRegular.fontSize}px;
    cursor: ${isLink ? "pointer" : "default"};
  `
);

export const ReviewersLink = styled.a<{ isLink: boolean }>(
  ({ isLink }) => css`
    cursor: ${isLink ? "pointer" : "default"};
    text-decoration: ${isLink ? "underline" : "none"};
    font: inherit;
  `
);

export const RatingNumber = styled.span<{ isLink: boolean }>(
  ({ isLink }) => css`
    font-size: ${isLink
      ? typography.mediumRegular.fontSize
      : typography.smallRegular.fontSize}px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: ${isLink ? "underline" : "none"};
  `
);

export const InfoBox = styled.div<{
  backgroundColor?: string;
  isMobile: boolean;
}>(
  ({ backgroundColor = colors.xxlWhite, isMobile }) => css`
    display: flex;
    background-color: ${backgroundColor};
    padding: ${spaces.miniMicro};
    line-height: 16px;
    text-align: right;
    letter-spacing: -0.12px;

    ${isMobile
      ? css`
          ${typography.tinyMedium};
        `
      : css`
          ${typography.smallMedium};
        `}
  `
);

import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { Button } from "../Common";

const unsetButtonStyles = css`
  background-color: unset;
  border: none;
  box-sizing: border-box;
  padding-block: 0;
  padding-inline: 0;
  padding: 0;
`;

export const DisclaimerInfoButton = styled(Button)`
  ${unsetButtonStyles}
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-width: 24px;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: end;
`;

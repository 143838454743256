import styled from "@emotion/styled/macro";
import { typographyToCss } from "../../../styles/helpers";
import colors from "./MegaMenu.colors";
import text from "./MegaMenu.typography";

const HeaderLink = styled.a`
  ${typographyToCss(text.headerLink)};
  -webkit-line-clamp: 1;
  align-items: center;
  color: ${colors.textColor};
  display: flex;
  gap: var(--space-mini);
  margin-right: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;

  &:hover,
  &:focus {
    color: ${colors.hoverColor};
  }
`;

const Separator = styled.div`
  &&& {
    font-size: 18px;
    margin-top: 13px;
    margin-right: 14px;
  }

  ::after {
    content: "|";
  }
`;

const RightAligned = styled.div`
  margin-left: auto;
`;

export { HeaderLink, Separator, RightAligned };

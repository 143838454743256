import { log } from "@xxl/logging-utils";
import type { AddToCartEventData, QuickShopData } from "../global";

export const XXL_CONFIG_PRODUCT_OPEN = "xxl-config-product-open";
export const XXL_CONFIG_PRODUCT_SUBMIT = "xxl-config-product-submit";
export const XXL_FORGOT_PASSWORD = "xxl-forgot-password";
export const XXL_GUIDE_PDP_CLOSE = "xxl-pdp-guide-close";
export const XXL_GUIDE_PDP_OPEN = "xxl-pdp-guide-open";
export const XXL_LOGIN_CLICK = "xxl-login-click";
export const XXL_LOGIN_EVENT = "xxl-login-event";
export const XXL_SOCIAL_SECURITY_NUMBER_OPEN =
  "xxl-social-security-number-open";
export const XXL_MESSAGE = "xxl-show-message";
export const XXL_PRODUCTS_LOADED = "xxl-product-loaded";
export const XXL_UPDATE_PRICE_DISPLAY = "xxl-update-price-display";
export const XXL_SIZE_SELECTED = "xxl-size-product-selected";
export const XXL_QUANTITY_CHANGED = "xxl-quantity-product-changed";
export const XXL_RENDER_RICH_RELEVANCE = "xxl-render-rich-relevance";
export const XXL_SERVICES_PRODUCT_OPEN = "xxl-services-open";
export const XXL_ORDER_RETURN_OPEN = "xxl-order-return-open";
export const XXL_ORDER_RETURN_CLOSE = "xxl-order-return-close";
export const XXL_CART_UPDATE = "xxl-cart-update";
export const XXL_SHIPPING_UPDATE = "xxl-shipping-update";
export const XXL_ADD_TO_CART = "xxl-add-to-cart";
export const XXL_ADD_CONFIGURABLE_PRODUCT_TO_CART =
  "xxl-add-configurable-product-to-cart";
export const XXL_SERVICES_PRODUCT_EXIST = "xxl-services-product-exist";
export const XXL_SIGNUP_OPEN = "xxl-signup-open";
export const XXL_SIGNUP_CLOSE = "xxl-signup-close";
export const XXL_CROSS_SALES_PRODUCT_EXIST = "xxl-cross-sales-product-exist";
export const XXL_ADD_BUNDLE_TO_CART = "xxl-add-bundle-to-cart";
export const XXL_STORE_STOCK_RECEIVED = "xxl-store-stock-received";
export const XXL_STORE_SELECTED = "xxl-store-selected";
export const XXL_CATEGORY_PATH_CHANGED = "xxl-category-path-changed";
export const XXL_ADD_TO_CART_ERROR = "xxl-add-to-cart-error";
export const XXL_SET_QUICKSHOP_PRODUCTCODE = "xxl-set-quickshop-productcode";

export const type = {
  XXL_CONFIG_PRODUCT_OPEN,
  XXL_CONFIG_PRODUCT_SUBMIT,
  XXL_FORGOT_PASSWORD,
  XXL_GUIDE_PDP_CLOSE,
  XXL_GUIDE_PDP_OPEN,
  XXL_LOGIN_CLICK,
  XXL_SOCIAL_SECURITY_NUMBER_OPEN,
  XXL_LOGIN_EVENT,
  XXL_MESSAGE,
  XXL_PRODUCTS_LOADED,
  XXL_UPDATE_PRICE_DISPLAY,
  XXL_SIZE_SELECTED,
  XXL_QUANTITY_CHANGED,
  XXL_RENDER_RICH_RELEVANCE,
  XXL_SERVICES_PRODUCT_OPEN,
  XXL_ORDER_RETURN_OPEN,
  XXL_ORDER_RETURN_CLOSE,
  XXL_CART_UPDATE,
  XXL_SHIPPING_UPDATE,
  XXL_ADD_TO_CART,
  XXL_ADD_CONFIGURABLE_PRODUCT_TO_CART,
  XXL_SERVICES_PRODUCT_EXIST,
  XXL_SIGNUP_OPEN,
  XXL_SIGNUP_CLOSE,
  XXL_CROSS_SALES_PRODUCT_EXIST,
  XXL_ADD_BUNDLE_TO_CART,
  XXL_STORE_STOCK_RECEIVED,
  XXL_STORE_SELECTED,
  XXL_CATEGORY_PATH_CHANGED,
  XXL_ADD_TO_CART_ERROR,
  XXL_SET_QUICKSHOP_PRODUCTCODE,
} as const;

type EventType =
  | (typeof type)[keyof typeof type]
  | "XXL_MEGAMENU_CLOSE"
  | "XXL_MEGAMENU_OPEN"
  | "MOVE_SLIDER_TO_INDEX"
  | "OPEN_MINI_CART"
  | "OPEN_STORE_STOCK_MODAL"
  | "XXL_SET_CART_COUNT"
  | "XXL_SET_SELECTED_COLUMNS_NUMBER";

/**
 * Event wrapper - addEventListener
 * @param {string} eventType Event key to listen to
 * @param {EventListener} listener Handler function getting invoked on received event with the passed `key`
 * @param {HTMLElement} [element=document.body] `document.body` by default
 */
export const addEventListener = (
  eventType: EventType,
  listener: EventListener,
  element: HTMLElement = document.body
): void => {
  element.addEventListener(eventType, listener);
};

export const removeEventListener = (
  eventType: EventType,
  listener: EventListener,
  element: HTMLElement = document.body
): void => {
  element.removeEventListener(eventType, listener);
};

/**
 * Event wrapper - dispatchEvent
 * @param {string} eventType Event key to fire event for
 * @param {object} props Properties
 * @param {HTMLElement} [element=document.body] `document.body` by default
 */
export const dispatchEvent = <T>(
  eventType: EventType,
  props?: T,
  element: HTMLElement = document.body
): void => {
  element.dispatchEvent(new CustomEvent<T>(eventType, { detail: props }));
};

export const addXXLEventListener = (
  eventType: EventType,
  listener: (eventProperties: any) => void
): void => {
  addEventListener(eventType, listener);
};

export const removeXXLEventListener = (
  eventType: EventType,
  listener: (eventProperties: any) => void
): void => {
  removeEventListener(eventType, listener);
};

export const addLoginEventListener = (
  listener: (userId: string, userGroups: string) => void | Promise<void>
): void => {
  addXXLEventListener(
    type.XXL_LOGIN_EVENT,
    ({ detail: { userId, userGroups } }) => {
      if (typeof userId !== "string" || typeof userGroups !== "string") {
        log.error(
          `Invalid properties. Expectings strings but got ${typeof userId} and ${typeof userGroups}`
        );
        return;
      }

      void listener(userId, userGroups);
    }
  );
};

export const dispatchLoginEvent = (userId: string, userGroups: string): void =>
  dispatchEvent(XXL_LOGIN_EVENT, { userId, userGroups });

export const dispatchAddToCartEvent = (data: AddToCartEventData) =>
  dispatchEvent<AddToCartEventData>(XXL_ADD_TO_CART, data);

export const dispatchSetQuickShopProductCode = (data: QuickShopData): void =>
  dispatchEvent<QuickShopData>(XXL_SET_QUICKSHOP_PRODUCTCODE, data);

export enum ViewState {
  LOGIN,
  FORGOT_PASSWORD,
  REGISTER,
  CONSENT,
  MISSING_SOCIAL_SECURITY_NUMBER,
  MISSING_ACCOUNT_DATA,
  SUCCESS_SIGN_UP,
  OTP_INITIALIZE,
  OTP_RESPONSE,
  SUCCESS_OTP,
  MISSING_USER_DATA,
  MISSING_VOYADO_ACCOUNT,
}

export type OpenLoginEventData = {
  viewState?: ViewState;
};

export const dispatchOpenLoginEvent = (state: ViewState = ViewState.LOGIN) =>
  dispatchEvent<OpenLoginEventData>(XXL_LOGIN_CLICK, { viewState: state });

import React, { useEffect, useState } from "react";
import { useSharedData } from "../../contexts/SharedData";
import {
  type GetReplacementQuery,
  type GetReplacementQueryVariables,
  type ReplacementEntry,
} from "../../generated/graphql-code-generator";
import { callGraphQL, type GraphQLResult } from "../../graphql/graphqlApi";
import { setInnerHtml } from "../../utils/xxl-set-inner-html";
import { log } from "@xxl/logging-utils";

type InstantShoppingProps = {
  text?: string;
  orderCode?: string;
  anonymousUserId?: string;
};

export const getReplacement = /* GraphQL */ `
  query GetReplacement($input: GetReplacementInput!) {
    getReplacement(input: $input) {
      cartId
      htmlSnippet
      customerType
      customerTypeOptions
      paymentProvider
    }
  }
`;

const InstantShopping: React.FunctionComponent<InstantShoppingProps> = ({
  text,
  orderCode,
  anonymousUserId,
}) => {
  const { configuration } = useSharedData().data;
  const [showLink, setShowLink] = useState(false);
  // @ts-expect-error Argument of type '{}' is not assignable to parameter of type 'Exact<{ input: GetReplacementInput; }> | (() => Exact<{ input: GetReplacementInput; }>)'.
  const [returns, setReturns] = useState<GetReplacementQueryVariables>({});
  const [disableClass, setDisableClass] = useState("");

  useEffect(() => {
    const replaceOrder = document.querySelector(
      ".js-order-replace__product--selected .js-order-replace__add-product"
    );

    if (replaceOrder !== null) {
      setShowLink(true);
    }
  }, [showLink]);

  useEffect(() => {
    // @ts-expect-error Type 'string' is not assignable to type 'number'.
    const getEntries: ReplacementEntry[] =
      window.OrderReplace.getReturnsForReact();

    const returnObject: GetReplacementQueryVariables = {
      input: {
        email: anonymousUserId,
        entries: getEntries,
        // @ts-expect-error Type 'undefined' is not assignable to type 'string'.
        orderCode: orderCode,
      },
    };

    setReturns(returnObject);
  }, [orderCode, anonymousUserId]);

  const getReplacementCheckoutSnippet = async (): Promise<
    GraphQLResult<GetReplacementQuery>
  > => {
    return callGraphQL<GetReplacementQuery>(
      {
        query: getReplacement,
        variables: returns,
      },
      configuration.amplifyConfig.aws_appsync_graphqlEndpoint
    );
  };

  const showKlarnaSnippet = async () => {
    const loader = document.getElementById(
      "js-instant-shopping-populate-klarna-loader"
    );
    if (loader !== null) {
      loader.style.display = "block";
    }
    setDisableClass("button---disabled");

    const klarnaElement = document.getElementById(
      "js-instant-shopping-populate-klarna"
    );

    try {
      if (klarnaElement !== null) {
        const result = await getReplacementCheckoutSnippet();
        const klarnaHtmlSnippet =
          result.data?.getReplacement?.htmlSnippet ?? "";
        setInnerHtml(klarnaElement, klarnaHtmlSnippet, true);
        setDisableClass("");
        if (result.errors !== undefined && result.errors.length > 0) {
          let errorList = "<ul class='order-return__general-errors-list'>";
          result.errors.forEach((error, index) => {
            errorList = errorList.concat(
              `<li class="order-return__general-error"><svg class="icons"><use href="#alert-icon" xlink:href="#alert-icon" /></svg>${
                error.errorType as string
              }</li>`
            );

            if (
              result.errors !== undefined &&
              index === result.errors.length - 1
            ) {
              errorList = errorList.concat("</ul>");
            }
          });

          klarnaElement.innerHTML = errorList;
        }
      }
    } catch (error) {
      log.error(error);
      if (klarnaElement !== null) {
        klarnaElement.innerHTML =
          "<p>Something went wrong, try again later</p>";
      }
      setDisableClass("");
    }
  };

  return showLink === true ? (
    <a
      href="#returns"
      role="button"
      className={`button button--primary button--small form__button order-replace__klarna-instant-shopping-button ${disableClass}`}
      onClick={showKlarnaSnippet}
    >
      {text}
    </a>
  ) : null;
};

export { InstantShopping };

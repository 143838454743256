import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { XxlButton } from "../Common/XxlButton";
import { RecommendationsParametersStrategyEnum } from "@xxl/recommendations-api";
import { Icon } from "../Icon";

export const FormButton = () => {
  const { t } = useTranslations();
  const handleClick = () =>
    document
      .getElementById(RecommendationsParametersStrategyEnum.upsale)
      ?.scrollIntoView({ behavior: "smooth", block: "start" });
  return (
    <XxlButton variant="secondary" onClick={handleClick}>
      {t("product.details.archive.form.button.label")}
      <Icon name="CaretRight" />
    </XxlButton>
  );
};

import { motion } from "framer-motion";
import type { PropsWithChildren } from "react";
import { TRANSITION } from "../../../constants";

export const FadeInAndOut = ({ children }: PropsWithChildren) => {
  return (
    <motion.span
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={TRANSITION}
    >
      {children}
    </motion.span>
  );
};

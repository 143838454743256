import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { xxlTheme } from "../../../styles/xxl-theme";
import { typographyToCss, MQ } from "../../../styles/helpers";
import { RadiobuttonWrapper } from "../../../styled";

const { spaces, colors } = xxlTheme;

const commonFontStyles = {
  lineHeight: 1.2,
  letterSpacing: -0.15,
};

const typography = {
  errorMessage: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 12,
    ...commonFontStyles,
  },
  label: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 15,
    ...commonFontStyles,
  },
};

const { errorMessage } = typography;

type WrapperProps = {
  hideTopSpacing?: boolean;
  isLocked?: boolean;
};

export const Wrapper = styled.div<WrapperProps>(
  ({ hideTopSpacing = false, isLocked = false }) => css`
    background-color: ${colors.xxlWhite};
    padding: ${hideTopSpacing
      ? `0 ${spaces.smallRegular} ${spaces.smallRegular}`
      : spaces.smallRegular};
    margin-bottom: ${spaces.mini};
    display: flex;
    flex-direction: column;

    ${isLocked &&
    css`
      pointer-events: none;
      filter: blur(5px);
    `};

    ${MQ("tablet")} {
      padding: ${hideTopSpacing
        ? `0 ${spaces.large} ${spaces.large}`
        : spaces.large};
      margin-bottom: ${spaces.smallRegular};
    }
  `
);

export const Description = styled.p<WrapperProps>(
  ({ hideTopSpacing = false }) => css`
    ${hideTopSpacing &&
    css`
      margin-top: 0;
    `}
  `
);

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputsWrapper = styled.div`
  display: flex;
  gap: ${spaces.smallRegular};
`;

export const RadioWrapper = styled(RadiobuttonWrapper)(
  ({ isDisabled = false }) => css`
    ${isDisabled &&
    css`
      color: ${colors.xxlGrey};
    `}

    &:first-of-type label {
      margin-left: 0;
    }

    && label {
      font-size: 15px;
      line-height: 1.2;
    }
  `
);

export const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${spaces.smallRegular};
  margin-top: ${spaces.smallRegular};
  background-color: ${colors.xxlLightGrey};
  color: ${colors.xxlWebBlack};
  ${typographyToCss(errorMessage)};
  box-sizing: border-box;

  & svg {
    width: 20px;
    height: 20px;
    margin-right: ${spaces.mini};
  }
`;

import { MediaQuery } from "../../utils/xxl-screen";
import { useMediaQuery as muiUseMediaQuery } from "@mui/material";
import type { MediaQueryKeys, MediaQueryValue } from "../../utils/xxl-screen";

/**
 *
 * @param query
 * @returns boolean
 *
 * @example
 * ```tsx
 * const isDesktopSize = useXxlMediaQuery("DesktopMediaQuery");
 * const isDesktopSize = useXxlMediaQuery(MediaQuery.DesktopMediaQuery);
 * ```
 */
export const useXxlMediaQuery = (query: MediaQueryKeys | MediaQueryValue) => {
  if (query in MediaQuery) {
    return muiUseMediaQuery(MediaQuery[query as keyof typeof MediaQuery]);
  }

  return muiUseMediaQuery(query);
};

/**
 * JS mixin for making several elements of the same type stackable on top of each other, by calculating top offsets
 *
 * @param {number} [n=5]
 * @param {string} [selector=""]
 * @param {number} offset How much top distance between each sibling. Equal to the element height if no margin desired.
 * @returns {string} CSSString to inject in inline styling blocks
 */
export default function stackable(n = 5, selector = "", offset: number) {
  let calculatedStyle = "";
  for (let i = 1; i < n; i++) {
    calculatedStyle += `
      ${selector}:nth-of-type(${i}) {
        top: ${offset * (i - 1)}px !important;
      }
    `;
  }
  return calculatedStyle;
}

import type {
  AssortmentLinkData,
  MegaMenuContentData,
  MegaMenuLevelOneLink,
  MegaMenuLevelTwoLink,
  MegamenucontentApi,
} from "@xxl/frontend-api";
import { MAX_CATEGORY_SECTIONS } from "./config";
import type { HeaderLink, RequestMapping } from "../../../global";
import stringFormat from "string-format";
import {
  BRANDS_CATEGORY_CODE,
  CUSTOM_CATEGORY_CODE,
  CUSTOMER_SERVICE_CATEGORY_CODE,
  GIFTCARD_CATEGORY_CODE,
  GUIDE_CATEGORY_CODE,
  NEWS_CATEGORY_CODE,
  REWARD_CATEGORY_CODE,
  SHOWMORE_CATEGORY_CODE,
  TEAMSALES_CATEGORY_CODE,
  TEAMSALES_HOST_NAME,
  WORKSHOP_CATEGORY_CODE,
} from "../../Search/Constants";
import { isNotNullOrUndefined } from "@xxl/common-utils";

export type tabIndex = number | boolean;
export const contentSectionStyles = { fontSize: 20, lineHeight: 1 };

let cachedPromise: null | Promise<MegaMenuContentData> = null;
const getData = async (
  megaMenuApi: MegamenucontentApi
): Promise<MegaMenuContentData> =>
  cachedPromise ??
  (cachedPromise = new Promise((resolve, reject) => {
    megaMenuApi
      .megaMenuContentControllerGetMegaMenuContentGET()
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  }));

const getUrl = ({ code, link }: { code?: string; link?: string }) =>
  link ?? (code !== undefined ? `/c/${code}` : "#");

const createRestSection = (
  links: MegaMenuLevelTwoLink[],
  moreText: string,
  maxCategorySections: number
): MegaMenuLevelTwoLink => {
  const subCategories = links.slice(maxCategorySections - 1).map((c2) => ({
    name: c2.displayName,
    url: c2.category?.url,
  }));
  return {
    displayName: moreText,
    category: {
      subCategories,
    },
  } as MegaMenuLevelTwoLink;
};

const prepareSections = (
  level1categories: MegaMenuLevelOneLink[] = [],
  moreText: string
): MegaMenuLevelOneLink[] => {
  const maxCategorySections = MAX_CATEGORY_SECTIONS;
  return level1categories.map((c1) => {
    const links = [...(c1.links ?? [])];
    const needsGrouping = links.length > maxCategorySections;
    if (needsGrouping) {
      links[maxCategorySections - 1] = createRestSection(
        links,
        moreText,
        maxCategorySections
      );
    }
    const slicedLinks = links.slice(0, maxCategorySections);
    return {
      ...c1,
      links: slicedLinks,
    };
  });
};

const getInitialRenderingData = (): MegaMenuLevelOneLink[] =>
  window._sharedData.megaMenuLevel1Categories.map((displayName, index) => ({
    displayName,
    category: {
      code: index.toString(),
    },
  }));

const getParentCategoryCode = (
  menuTree: MegaMenuLevelOneLink[],
  currentCategoryCode: string | undefined
): string | undefined => {
  let parentCategoryCode: string | undefined = undefined;
  menuTree.forEach(function (c1: MegaMenuLevelOneLink) {
    if (c1.links !== undefined && currentCategoryCode !== undefined) {
      c1.links.forEach(function (c2) {
        if (c2.category?.code === currentCategoryCode) {
          parentCategoryCode = c1.category?.code;
        }
        if (parentCategoryCode === undefined) {
          c2.category?.subCategories?.forEach((c3) => {
            if (c3.url !== undefined && c3.url.includes(currentCategoryCode)) {
              parentCategoryCode = c1.category?.code;
            }
          });
        }
      });
    }
  });
  return parentCategoryCode;
};

const isCurrentCategory = (
  categoryCode?: string,
  currentCategoryCode?: string,
  currentParentCategoryCode?: string,
  menuTree?: MegaMenuLevelOneLink[]
): boolean => {
  if (currentCategoryCode === undefined || currentCategoryCode.length === 0)
    return false;

  const isLevelOneCategory =
    menuTree !== undefined &&
    menuTree.find((c1) => c1.category?.code === currentCategoryCode) !==
      undefined;

  return (
    (!isLevelOneCategory && currentParentCategoryCode === categoryCode) ||
    currentCategoryCode === categoryCode
  );
};

const isSelectedLink = (linkUrl: string): boolean => {
  if (typeof window === "undefined") {
    return false;
  }
  return window.location.pathname.includes(linkUrl);
};

const hasSubLinks = (categoryLinks: MegaMenuLevelOneLink[]) =>
  categoryLinks.some(({ links = [] }) => links.length > 0);

const createClubAssortmentLinksCategoryData = (
  translatedName: string,
  clubIds: AssortmentLinkData[],
  clubAssortmentUrl: string
) => {
  const subCategories = clubIds.map(({ name, uid }) => {
    const url = stringFormat(clubAssortmentUrl, uid);
    return {
      name,
      url,
    };
  });

  return {
    displayName: translatedName,
    categoryCode: CUSTOM_CATEGORY_CODE,
    level2Link: {
      category: {
        subCategories,
        code: CUSTOM_CATEGORY_CODE,
      },
      displayName: translatedName,
    },
  };
};

export const getActiveTabLeftOffset = (index: number) => {
  const megaMenu = document.getElementById("desktop-mega-menu")?.offsetLeft;
  const activeTab = document.getElementById(`tab-${index}`)?.offsetLeft;
  const offsetLeft =
    activeTab !== undefined && megaMenu !== undefined
      ? activeTab + megaMenu
      : undefined;

  return offsetLeft;
};

type urlToCategoryIconProps = {
  requestMapping: RequestMapping;
  url: string;
};

const mapUrlToCategoryIcon = ({
  requestMapping,
  url,
}: urlToCategoryIconProps) => {
  const parsedUrl = url.endsWith("/") ? url.slice(0, -1) : url;

  if (parsedUrl.endsWith(requestMapping.customerService))
    return CUSTOMER_SERVICE_CATEGORY_CODE;
  if (parsedUrl.endsWith(requestMapping.guides)) return GUIDE_CATEGORY_CODE;
  if (parsedUrl.endsWith(requestMapping.giftCard))
    return GIFTCARD_CATEGORY_CODE;
  if (parsedUrl.endsWith(requestMapping.newsletter)) return NEWS_CATEGORY_CODE;
  if (parsedUrl.endsWith(requestMapping.reward)) return REWARD_CATEGORY_CODE;
  if (parsedUrl.endsWith(requestMapping.workshop))
    return WORKSHOP_CATEGORY_CODE;
  if (parsedUrl.indexOf(TEAMSALES_HOST_NAME) > 0)
    return TEAMSALES_CATEGORY_CODE;

  return undefined;
};

const createMoreCategoryData = (
  translatedName: string,
  headerLinks: HeaderLink[],
  requestMapping: RequestMapping
) => {
  const subCategories = headerLinks.map(({ displayName, url }) => {
    return {
      name: displayName,
      url,
      iconCode: mapUrlToCategoryIcon({ requestMapping, url }),
    };
  });

  return {
    displayName: translatedName,
    categoryCode: SHOWMORE_CATEGORY_CODE,
    level2Link: {
      category: {
        subCategories,
        code: SHOWMORE_CATEGORY_CODE,
      },
      displayName: translatedName,
    },
  };
};

const getFilteredHeaderLinksData = ({
  headerLinks,
  requestMapping,
}: {
  headerLinks: HeaderLink[];
  requestMapping: RequestMapping;
}) => {
  const brandsLink = headerLinks.find(({ url }) =>
    url.endsWith(requestMapping.brands)
  );
  const customerServiceLink = headerLinks.find(({ url }) =>
    url.endsWith(requestMapping.customerService)
  );
  const storeFinderLink = headerLinks.find(({ url }) =>
    url.endsWith(requestMapping.storeFinder)
  );
  const outletLink = headerLinks.find(({ url }) =>
    url.endsWith(requestMapping.outlet)
  );

  const moreLinks = headerLinks.map(({ displayName, url }) => {
    return {
      displayName,
      url,
      iconCode: mapUrlToCategoryIcon({ requestMapping, url }),
    };
  });

  const urlRemoveList = [
    brandsLink?.url,
    customerServiceLink?.url,
    outletLink?.url,
    storeFinderLink?.url,
  ].filter((url) => url);

  return {
    ...(isNotNullOrUndefined(outletLink) && {
      outletLink: {
        displayName: outletLink.displayName,
        url: outletLink.url,
        code: GUIDE_CATEGORY_CODE,
      },
    }),
    ...(isNotNullOrUndefined(brandsLink) && {
      brandsLink: {
        displayName: brandsLink.displayName,
        url: brandsLink.url,
        code: BRANDS_CATEGORY_CODE,
      },
    }),
    ...(isNotNullOrUndefined(customerServiceLink) && {
      customerServiceLink: {
        displayName: customerServiceLink.displayName,
        url: customerServiceLink.url,
      },
    }),

    filteredHeaderLinks: moreLinks.filter(
      ({ url }) => !urlRemoveList.includes(url)
    ),
    ...(isNotNullOrUndefined(storeFinderLink) && {
      storeFinderLink: {
        displayName: storeFinderLink.displayName,
        url: storeFinderLink.url,
      },
    }),
  };
};

export {
  getData,
  getFilteredHeaderLinksData,
  getInitialRenderingData,
  createClubAssortmentLinksCategoryData,
  createMoreCategoryData,
  hasSubLinks,
  prepareSections,
  getParentCategoryCode,
  getUrl,
  isCurrentCategory,
  isSelectedLink,
};

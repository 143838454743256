import styled from "@emotion/styled/macro";
import * as React from "react";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import type { TranslationKey } from "../../../translations";

const GhostButtonStyled = styled.span`
  display: inline-block;
  box-sizing: border-box;
  padding: 14px 21px;
  color: var(--font-color-primary-invert);
  font-weight: 500;
  font-size: 1rem;
  font-family: var(--font-family-medium);
  line-height: 19px;
  letter-spacing: 1.48px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  border: 2px solid #fff;
  cursor: pointer;
`;

type GhostButtonProps = {
  onclick: () => void;
  label: TranslationKey;
};

export const GhostButton: React.FunctionComponent<GhostButtonProps> = ({
  label,
  onclick,
}) => {
  const { t } = useTranslations();

  return (
    <GhostButtonStyled
      role="button"
      aria-label={t(label)}
      onClick={onclick}
      onKeyDown={onclick}
    >
      {t(label)}
    </GhostButtonStyled>
  );
};

import React from "react";
import Image from "next/image";
import { imageLoader } from "../../../utils/image-loader/image-loader";
import type { NextImageProps } from "./NextImage.types";

export const NextImage = ({ hotspot, padding, ...props }: NextImageProps) => {
  return (
    <Image
      data-private={true}
      loader={imageLoader(hotspot, padding)}
      {...props}
    />
  );
};

import styled from "@emotion/styled/macro";
import { xxlTheme } from "../../styles/xxl-theme";
import { typographyToCss, MQ, interactionFeedback } from "../../styles/helpers";
import typography from "./AutoSuggestion.typography";

const { colors, spaces } = xxlTheme;

export const Image = styled.img`
  width: 44px;
  height: 44px;
  margin-right: 10px;

  ${MQ("tablet")} {
    margin-right: 24px;
  }
`;

export const ProductDetails = styled.div`
  display: flex;
  justify-content: flex-start;
  max-width: calc(100% - 130px);
  align-items: flex-end;
`;

export const ProductInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: ${colors.xxlWebBlack};
  max-width: calc(100% - 56px);
  height: 44px;
  gap: 1px;
`;

export const ProductWrapper = styled.li`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  margin-top: ${spaces.regular};
  padding: 0;

  ${MQ("tablet")} {
    width: 100%;
    margin-top: ${spaces.smallLarge};
  }

  ${MQ("desktop")} {
    margin-top: ${spaces.big};
  }

  &:first-of-type {
    margin-top: 0;
  }
`;

export const ProductBrand = styled.p`
  line-height: 1.1;
  margin: 0;
`;

export const ProductName = styled.p`
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.1;
  margin: 0;

  ${MQ("tablet")} {
    max-width: 216px;
  }
`;

export const PriceDetails = styled.div`
  display: flex;
  gap: ${spaces.micro};
  align-items: flex-start;
  justify-content: flex-end;
  flex-wrap: wrap;

  .product-card__current-price,
  .product-card__inverted-price,
  .product-card__previous-price {
    ${typographyToCss(typography.price)};
    font-weight: 700;
    margin-bottom: 2px;
    overflow: hidden;
  }

  .product-card__previous-price-wrapper,
  .product-card__current-price-wrapper {
    width: 60px;
  }
`;

export const ProductLink = styled.a`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  width: 100%;

  & b {
    font-family: var(--font-family-bold);
  }
  ${interactionFeedback(`
    & .auto-suggest__brand,
    & .auto-suggest__name {
      font-family: var(--font-family-bold);
    }
  `)}
`;

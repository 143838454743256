import styled from "@emotion/styled/macro";
import spaces from "react-app/src/styles/theme/spaces";
import type * as CSSType from "csstype";
import { color } from "@xxl/theme";

export const ContentContainer = styled.div<{
  backgroundColor?: CSSType.Property.BackgroundColor;
}>`
  background-color: ${({ backgroundColor = color.white }) => backgroundColor};
  padding: ${spaces.smallRegular};
`;

import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { containerMaxWidth, xxlTheme } from "../../styles/xxl-theme";
import { typographyToCss, MQ } from "../../styles/helpers";
import typography from "./AutoSuggestion.typography";

const { colors, spaces } = xxlTheme;

export const AutoSuggestionsWrapper = styled.div(css`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-between;
  position: relative;
  max-height: fit-content;
  width: 100%;
  max-width: calc(100vw - 24px);
  padding: 0;
  z-index: 102;
  background-color: ${colors.xxlWhite};
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.13);
  box-sizing: border-box;
  overflow: hidden;
  align-content: flex-end;

  ${MQ("tablet")} {
    flex-wrap: wrap;
    flex-direction: row;
    max-width: ${containerMaxWidth}px;
    padding: ${spaces.large} ${spaces.smallRegular} ${spaces.smallRegular};
    justify-content: flex-start;
  }

  ${MQ("laptop")} {
    padding: ${spaces.large};
  }
`);

type SuggestionsColumnProps = {
  twoColumns?: boolean;
  hasTabs?: boolean;
};

export const SuggestionsColumn = styled.div<SuggestionsColumnProps>(
  ({ twoColumns }) => css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
    box-sizing: border-box;
    max-width: 264px;
    width: 100%;

    ${MQ("tablet")} {
      max-width: calc(100% - 420px - 36px);
      margin-right: ${spaces.big};
      max-height: none;
    }

    ${MQ("laptop")} {
      height: 100%;
      column-gap: ${spaces.big};
      flex-direction: row;
      max-width: calc(100% - 450px);
      align-content: flex-start;
      min-width: ${twoColumns === true
        ? `calc(2 * 210px + 2 * 36px)`
        : `calc(210px + 36px)`};
      margin-right: 0;
    }

    ${MQ("desktop")} {
      column-gap: ${spaces.giant};
      min-width: ${twoColumns === true
        ? `calc(2 * 240px + 2 * 96px)`
        : `calc(240px + 96px)`};
    }
  `
);

export const TabsList = styled.ul`
  padding: 0;
  margin: 0 0 ${spaces.smallRegular};
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  box-sizing: border-box;
  border-top: 1px solid ${colors.xxlLightGrey};
  border-bottom: 1px solid ${colors.xxlLightGrey};
  width: max-content;
  min-width: 100%;
`;

type ItemProps = {
  isActive: boolean;
};

export const Tab = styled.li<ItemProps>(
  ({ isActive }) => css`
    font-weight: ${isActive ? "700" : "400"};
    font-family: ${isActive && "var(--font-family-bold)"};
    min-height: 28px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: ${isActive ? "none" : "all"};
    padding: ${spaces.mini} 0;
    position: relative;
    transition: 0.1s ease;

    &:hover {
      cursor: ${isActive ? "initial" : "pointer"};
      color: ${!isActive && colors.xxlGreen};
      font-weight: ${!isActive && "700"};

      ${!isActive &&
      css`
        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 2px;
          background-color: ${colors.xxlGreen};
          position: absolute;
          bottom: -1px;
          left: 0;
        }
      `}
    }

    ${isActive &&
    css`
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background-color: ${colors.xxlWebBlack};
        position: absolute;
        bottom: -1px;
        left: 0;
      }
    `}
  `
);

export const ShowAllLink = styled.a`
  align-items: center;
  ${typographyToCss(typography.showAllLink)};
  display: flex;
  width: 100%;
  margin-top: auto;
  color: ${colors.xxlWebBlack};
  padding: ${spaces.smallLarge} ${spaces.smallRegular} ${spaces.smallRegular};

  ${MQ("tablet")} {
    padding: 0;
  }

  &:after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-top: 1px solid ${colors.xxlWebBlack};
    border-right: 1px solid ${colors.xxlWebBlack};
    transform: rotate(45deg);
    transition: 0.2s ease-in-out;
    margin-left: ${spaces.line};
  }

  &:hover {
    &:after {
      margin-left: ${spaces.micro};
    }
  }
`;

export const MobileProductsList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-wrap: wrap;
  max-height: 100%;
`;

import styled from "@emotion/styled/macro";
import { colorsAsCssVariable } from "../../../styles/theme/colors";
import spaces from "../../../styles/theme/spaces";
import zIndex from "../../../styles/theme/zIndex";
import { ANIMATION_DURATION, ICON_SIZE_PX } from "./constants";

const TIMING_FUNCTION_ENTER = "cubic-bezier(0, 1, .85, 1)";
const TIMING_FUNCTION_LEAVE = "cubic-bezier(1, 0, 1, 0)";

const { xxlWhite } = colorsAsCssVariable;

const ClubLogo = styled.img`
  height: ${ICON_SIZE_PX}px;
  object-fit: contain;
  width: ${ICON_SIZE_PX}px;
`;

const Nav = styled.nav<{
  distanceFromWindowTop: number;
  isOpen: boolean;
}>`
  background-color: ${xxlWhite};
  bottom: 0;
  height: ${({ distanceFromWindowTop, isOpen }) =>
    isOpen ? `calc(100% - ${distanceFromWindowTop}px)` : 0};
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: ${({ distanceFromWindowTop }) => distanceFromWindowTop}px;
  transition: height ${ANIMATION_DURATION}ms;
  z-index: ${zIndex.navigation};
`;

const historyStates = {
  active: `
    transform: translateX(0) translateZ(0);
    transition: transform ${ANIMATION_DURATION}ms ${TIMING_FUNCTION_ENTER};
  `,
  past: `
    transform: translateX(-100%) translateZ(0);
    transition: transform ${ANIMATION_DURATION}ms ${TIMING_FUNCTION_LEAVE};
  `,
};

const List = styled.ul<{
  historyState: "active" | "past";
}>`
  -webkit-overflow-scrolling: touch;
  ${({ historyState }) => historyStates[historyState]};
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden auto;
  position: absolute;
  background-color: ${xxlWhite};
  inset: 0;
  will-change: transform;
  animation: megaMenuListEnter ${ANIMATION_DURATION}ms ${TIMING_FUNCTION_ENTER};

  > li:last-child {
    margin-bottom: ${spaces.smallGiant};
  }

  /* Force list to have scroll (nice feature on mobile for devices with rubber band effect) */
  &:after {
    content: "";
    height: 100%;
    margin-bottom: -1px;
  }

  @keyframes megaMenuListEnter {
    from {
      transform: translateX(100%) translateZ(0);
    }
    to {
      transform: translateX(0) translateZ(0);
    }
  }
`;

const MainList = styled(List)<{ isVisible: boolean }>`
  background: ${xxlWhite};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};

  > li:not(:last-child) {
    background-color: ${xxlWhite};
  }
`;

export { ClubLogo, List, MainList, Nav };

import {
  checkSocialSecurityNumber,
  commonUserFields,
  getIsValidSecurityNumber,
} from "../../Reward/SignUp/SignUpForm/SignUpFormHelper";
import type { Translate } from "../../../contexts/Translations/TranslationsContext";
import type { EcomSiteUid } from "@xxl/common-utils";
import * as yup from "yup";
import type { SelectBoxOption } from "../../../components/Common/SelectBox";
import type { Gender } from "../../../generated/graphql-code-generator";

export type PersonalInfoData = {
  firstName?: string;
  lastName?: string;
  socialSecurityNumber?: string;
  street?: string;
  zipCode?: string;
  birthDay?: string;
  gender?: Gender;
  city?: string;
  mobilePhone?: string;
};

type Field = {
  autocompleteToken:
    | "given-name"
    | "family-name"
    | "tel"
    | "social-security-number"
    | "birth-date"
    | "gender"
    | "street-address"
    | "zip-code"
    | "city"
    | "mobile-phone";
  disabled?: boolean;
  fieldName: keyof PersonalInfoData;
  id: string;
  infoMessage?: string;
  inputMode?: "email" | "tel" | "text" | "date" | "select" | undefined;
  label: string;
  placeholder: string;
  required?: boolean;
  options?: SelectBoxOption<unknown>[];
};

const getGenderOptions = (t: Translate): SelectBoxOption<Gender>[] => [
  { key: "MALE", value: "MALE", name: t("account.personal.info.male") },
  { key: "FEMALE", value: "FEMALE", name: t("account.personal.info.female") },
  { key: "OTHER", value: "OTHER", name: t("account.personal.info.other") },
];

export const getGenderTranslation = (value: Gender, t: Translate): string => {
  const options = getGenderOptions(t);
  const translatedName = options.find((opt) => opt.value === value)?.name ?? "";
  return translatedName.toString();
};

export const getPersonalInfoSchema = (
  includeSocialSecurityNumber: boolean,
  t: Translate,
  uid: EcomSiteUid
) => {
  const requiredMessage = t("form.field.required");

  return yup.object({
    firstName: yup.string().required(requiredMessage),
    lastName: yup.string().required(requiredMessage),
    mobilePhone: yup.string().required(requiredMessage),

    ...(includeSocialSecurityNumber
      ? { socialSecurityNumber: checkSocialSecurityNumber(t, uid) }
      : undefined),
  });
};

export const fields = ({
  toggle_social_security_number,
  t,
  uid,
  socialSecurityNumber,
}: {
  toggle_social_security_number: boolean;
  t: Translate;
  uid: EcomSiteUid;
  socialSecurityNumber?: string;
}): Field[] => {
  const { firstNameField, lastNameField, socialSecurityNumberField } =
    commonUserFields(t);
  return [
    firstNameField,
    lastNameField,

    {
      autocompleteToken: "mobile-phone",
      fieldName: "mobilePhone",
      id: "mobilePhone",
      label: t("account.personal.info.phone.number"),
      placeholder: t("account.personal.info.phone.number"),
      required: true,
      inputMode: "tel",
    } as const,

    ...(toggle_social_security_number
      ? [
          {
            ...socialSecurityNumberField,
            disabled: getIsValidSecurityNumber({
              socialSecurityNumber,
              t,
              uid,
            }),
          } as const,
        ]
      : [
          {
            autocompleteToken: "birth-date",
            fieldName: "birthDay",
            id: "birthDate",
            label: t("account.personal.info.birthday"),
            placeholder: t("account.personal.info.birthday"),
            inputMode: "date",
          } as const,
          {
            autocompleteToken: "gender",
            fieldName: "gender",
            id: "gender",
            label: t("account.personal.info.gender"),
            placeholder: t("account.personal.info.gender"),
            inputMode: "select",
            options: getGenderOptions(t),
          } as Field,
        ]),
    {
      autocompleteToken: "street-address",
      fieldName: "street",
      id: "streetAddress",
      label: t("account.personal.info.street"),
      placeholder: t("account.personal.info.street"),
    } as const,
    {
      autocompleteToken: "zip-code",
      fieldName: "zipCode",
      id: "zipCode",
      label: t("account.personal.info.zip.code"),
      placeholder: t("account.personal.info.zip.code"),
    } as const,
    {
      inputMode: "text",
      autocompleteToken: "city",
      fieldName: "city",
      id: "city",
      label: t("account.personal.info.city"),
      placeholder: t("account.personal.info.city"),
    } as const,
  ];
};

export const getInitialSelectValue = (
  fieldName: string,
  options: SelectBoxOption<unknown>[]
) => {
  return options.find((option) => option.value === fieldName);
};

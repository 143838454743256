import React from "react";

export const IconCheckmark = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x={0.5}
      y={0.5}
      width={17}
      height={17}
      rx={1.5}
      fill="#99F500"
      stroke="#99F500"
    />
    <path
      d="m7.398 13-3.81-3.92.763-.79 3.047 3.135L13.644 5l.768.785L7.398 13Z"
      fill="#161616"
    />
  </svg>
);

import React from "react";
import type { CountdownTimerSettings } from "@xxl/content-api";
import { CampaignName, Wrapper } from "./CampaignSplash.styled";
import { CampaignCountdown } from "./CampaignCountdown/CampaignCountdown";
import { withErrorBoundary } from "react-app/src/utils/WithErrorBoundary/with-error-boundary";

type CampaignInfoProps = {
  colors: {
    background: string;
    foreground: string;
  };
  name: string;
  countdown: CountdownTimerSettings | null;
};

const _CampaignSplash = ({ colors, countdown, name }: CampaignInfoProps) => {
  const { background, foreground } = colors;
  const { isOnlyHours = false, date, startDate } = countdown ?? {};
  const startDateObject =
    startDate !== undefined ? new Date(startDate) : undefined;

  return (
    <Wrapper backgroundColor={background}>
      <CampaignName style={{ color: foreground }}>{name}</CampaignName>
      {countdown !== null && date !== undefined && (
        <CampaignCountdown
          convertDaysToHours={isOnlyHours}
          endDate={new Date(date)}
          startDate={startDateObject}
          fontColor={foreground}
        />
      )}
    </Wrapper>
  );
};

export const CampaignSplash = withErrorBoundary(_CampaignSplash);

import React from "react";
import ReactHtmlParser from "html-react-parser";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { Item, Text, MoreText } from "./UspBar.styled";

type UspItemProps = {
  index: 1 | 2 | 3;
};

export const UspItem: React.FunctionComponent<UspItemProps> = ({ index }) => {
  const { t } = useTranslations();
  return (
    <Item data-testid="service-usp-item">
      <svg>
        <use href="#check-usp" xlinkHref="#check-usp" />
      </svg>
      <Text>{ReactHtmlParser(t(`cart.usp${index}`))}</Text>
      <MoreText>{ReactHtmlParser(t(`cart.usp${index}.more`))}</MoreText>
    </Item>
  );
};

import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import {
  CrossSalesProductLowOfStockMessage,
  CrossSalesProductOutOfStockMessage,
} from "./CrossSales.styled";
import { getSizeStockStatus } from "./utils";
import { StockStatusEnum } from "../../enums";
import type { StockStatus } from "../../utils/data-types";

type CrossSalesProductDescriptionStockStatusProps = {
  selectedSize?: {
    stockStatus?: StockStatus;
  };
};

export const CrossSalesProductDescriptionStockStatus: React.FC<
  CrossSalesProductDescriptionStockStatusProps
> = ({ selectedSize }) => {
  const { t } = useTranslations();
  const sizeStockStatus = getSizeStockStatus(selectedSize);

  switch (sizeStockStatus) {
    case StockStatusEnum.LOWSTOCK:
      return (
        <CrossSalesProductLowOfStockMessage>
          {t("product.details.size.low.stock")}
        </CrossSalesProductLowOfStockMessage>
      );
    case StockStatusEnum.OUTOFSTOCK:
      return (
        <CrossSalesProductOutOfStockMessage>
          {t("product.details.size.out.of.stock")}
        </CrossSalesProductOutOfStockMessage>
      );
    default:
      return null;
  }
};

import { getLegacySiteUid, getSiteUid } from "@/utils/environment-variables";
import { translateMessage } from "@/utils/translations/translateMessage";
import type { CategoryData } from "@xxl/frontend-api";
import Keyv from "@keyvhq/core";
import memoize from "@keyvhq/memoize";
import { CACHE_TTL_15_MIN, STALE_TTL_3_MIN } from "@/utils/apis/constants";
import {
  DISCOUNT_CATEGORY_SUFFIX,
  isDiscountCategoryCode,
} from "@/react-utils/xxl-category";
import { Apis } from "@/utils/api-helper";
export const discountSubCategorySlugTranslationKey =
  "category.page.discounts.subcategory.slug";
const discountsSubcategoryTitleKey =
  "category.page.discounts.subcategory.title";
import { getProductDiscountCount } from "@/utils/strategies/product-count";

type DiscountCategoryData = {
  code: string;
  name: string;
  url: string | undefined;
  categoryLevel: number | undefined;
  productCount: number | undefined;
  breadcrumbs: CategoryData["breadcrumbs"];
  subCategories: CategoryData["subCategories"];
  pageTitle?: string;
};

const getDiscountCategoryPageTitle = async (
  categoryData: CategoryData
): Promise<string> => {
  const currentDate = new Date();
  const currentMonthName = currentDate
    .toLocaleString("default", {
      month: "long",
    })
    .toLowerCase();
  const currentYear = currentDate.getFullYear().toString();
  return await translateMessage({
    key: discountsSubcategoryTitleKey,
    messageArguments: [categoryData.name ?? "", currentMonthName, currentYear],
    fallback: categoryData.code ?? "" + String(DISCOUNT_CATEGORY_SUFFIX),
  });
};

async function getDiscountedProductCount(categoryCode: string) {
  return await getProductDiscountCount(categoryCode, getSiteUid());
}

export const getDiscountCategoryForCategoryData = async (
  categoryData: CategoryData
): Promise<DiscountCategoryData | null> => {
  if (categoryData.code === undefined) {
    return null;
  }
  const productCount = await getDiscountedProductCount(categoryData.code);
  if (productCount === 0) {
    return null;
  }

  const slugName = await translateMessage(
    discountSubCategorySlugTranslationKey
  );
  const name = slugName.charAt(0).toUpperCase() + slugName.slice(1);
  const url = categoryData.url?.replace("/c/", `/${slugName}/c/`);
  const code = categoryData.code + DISCOUNT_CATEGORY_SUFFIX;
  const categoryLevel = (categoryData.categoryLevel ?? 0) + 1;

  return {
    code,
    name,
    url,
    categoryLevel,
    productCount,
    breadcrumbs: [
      ...(categoryData.breadcrumbs ?? []),
      {
        code,
        name,
        url,
        categoryLevel,
      },
    ],
    subCategories: [],
    pageTitle: await getDiscountCategoryPageTitle(categoryData),
  };
};

const fetchCategoryData = async (
  categoryCode: string | undefined
): Promise<CategoryData | undefined> => {
  if (categoryCode === undefined) {
    return;
  }
  const isDiscountCategory = isDiscountCategoryCode(categoryCode);
  const { data } = await Apis.getInstance().pimApi.getCategories(
    getLegacySiteUid(),
    categoryCode.replace(DISCOUNT_CATEGORY_SUFFIX, "")
  );
  if (data.length === 0) {
    return;
  }
  const [categoryData] = data;
  const discountCategory =
    await getDiscountCategoryForCategoryData(categoryData);
  if (discountCategory !== null) {
    categoryData.subCategories?.push(discountCategory);
  }
  const pageTitle = isDiscountCategory
    ? discountCategory?.pageTitle
    : categoryData.pageTitle ?? categoryData.pageTitle;
  const subCategories = categoryData.subCategories
    ?.filter(({ productCount }) => (productCount ?? 0) > 0)
    .map(({ code, name, url }) => ({ code, name, url }));
  return {
    code: categoryCode,
    url:
      isDiscountCategory && discountCategory !== null
        ? discountCategory.url
        : categoryData.url,
    subCategories: subCategories,
    categoryLevel: categoryData.categoryLevel,
    breadcrumbs: categoryData.breadcrumbs,
    discountsSubcategory: discountCategory !== null,
    header: categoryData.header,
    pageTitle,
  };
};

export const fetchCategoryDataMemoized = memoize(
  fetchCategoryData,
  new Keyv(),
  {
    key: (categoryCode: string | undefined) =>
      `category-data-${categoryCode ?? ""}`,
    ttl: CACHE_TTL_15_MIN,
    staleTtl: STALE_TTL_3_MIN,
  }
);

export const getSuperCategoryCodes = (category: CategoryData): string[] => {
  const breadcrumbs = category.breadcrumbs ?? [];
  return breadcrumbs
    .slice(1, Math.max(0, breadcrumbs.length - 2))
    .map((breadcrumbCategory: CategoryData) => breadcrumbCategory.code ?? "")
    .filter(Boolean);
};

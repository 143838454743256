import * as React from "react";
import type { Direction, Variant } from "./ArrowSmall.styled";
import { Button } from "./ArrowSmall.styled";
import { CaretRight, CaretLeft } from "@xxl/icons";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";

type PublicArrowProps = {
  disabled: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  variant?: Variant;
  margin?: number;
};

type PrivateArrowProps = PublicArrowProps & {
  ariaLabel?: string;
  direction: Direction;
};

const Arrow: React.FunctionComponent<PrivateArrowProps> = ({
  ariaLabel,
  direction,
  disabled,
  onClick,
  variant = "default",
  margin = 0,
}): React.ReactElement => (
  <Button
    type="button"
    className={`arrow-${direction}`}
    aria-label={ariaLabel}
    direction={direction}
    disabled={disabled}
    onClick={onClick}
    variant={variant}
    margin={margin}
  >
    {direction === "right" ? <CaretRight /> : <CaretLeft />}
  </Button>
);

export const ArrowRightSmall: React.FunctionComponent<PublicArrowProps> = (
  props
): React.ReactElement => {
  const { t } = useTranslations();

  const ariaLabel = t("general.next");
  const direction = "right";
  return <Arrow {...{ ariaLabel, direction, ...props }} />;
};

export const ArrowLeftSmall: React.FunctionComponent<PublicArrowProps> = (
  props
): React.ReactElement => {
  const { t } = useTranslations();

  const ariaLabel = t("general.previous");
  const direction = "left";
  return <Arrow {...{ ariaLabel, direction, ...props }} />;
};

import { ArrowUp, CaretRight } from "@xxl/icons";
import React from "react";
import { SvgContainer } from "./CaretIcon.styled";
import { colorsAsCssVariable as colors } from "../../../../styles/theme/colors";
import type * as CSSType from "csstype";

type CaretDirections = "down" | "left" | "right" | "up";

type CaretIconProps = {
  direction: CaretDirections;
  style?: React.CSSProperties;
};

const CaretIcon: React.FunctionComponent<CaretIconProps> = ({
  direction,
  style,
}): JSX.Element => (
  <SvgContainer direction={direction} style={style}>
    <CaretRight />
  </SvgContainer>
);

type ArrowWithLineIconProps = {
  color: CSSType.Property.Color;
};

const ArrowWithLineIcon: React.FunctionComponent<ArrowWithLineIconProps> = ({
  color = colors.xxlBlack,
}): JSX.Element => <ArrowUp fontSize={15} color={color} />;

export type { CaretDirections as ArrowDirections };
export { CaretIcon, ArrowWithLineIcon };

export const createUrl = (
  url: string,
  linkType: string | null,
  guidesUrl: string,
  faqUrl: string
) =>
  linkType === "guide"
    ? `/${guidesUrl}/${url}`
    : linkType === "faqEntry"
      ? `${faqUrl}/${url}`
      : `${url}`;

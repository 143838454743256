import { useState } from "react";
import { Slider } from "react-app/src/components/Common/Slider";
import { SliderBanner } from "./SliderBanner";
import { NextImage } from "../../common/NextImage/NextImage";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { ProductImageWrapperMobile } from "./ProductImage.styles";
import { IMAGE_SIZE } from "../constants";
import type { ProductImageSliderProps } from "../types";
import { LinkInCarousel } from "./LinkCarousel/LinkInCarousel";
import { ImageDialog } from "./ImageDialog";

/**
 * Creates at least 3 images for the slider
 */
const getSliderImages = (imageUrls: string[]) => {
  switch (imageUrls.length) {
    case 1:
      return [imageUrls[0], imageUrls[0], imageUrls[0]];
    case 2:
      return [imageUrls[0], imageUrls[1], imageUrls[0]];
    default:
      return imageUrls;
  }
};

export const ProductImageSliderMobile = ({
  campaignComponent,
  productImages = [],
  productName,
  children,
}: ProductImageSliderProps) => {
  const [initialSlideIndex, setInitialSlideIndex] = useState(0);
  const { t } = useTranslations();
  const [open, setOpen] = useState(false);

  const handleClickOpen = (img: string) => {
    setOpen(true);
    const index = productImages.findIndex((image) => image === img);
    setInitialSlideIndex(index);
  };
  const handleClose = () => setOpen(false);

  const images = getSliderImages(productImages);
  const priorityImages = new Set([images.length - 1, 0, 1]);

  const sliderItems = images.map((productImage, idx) => (
    <NextImage
      key={`${idx}-${productImage}`}
      src={productImage}
      alt={`${productName} - ${t("product.details.alt.image.full")} ${idx + 1}`}
      height={IMAGE_SIZE.MOBILE}
      width={IMAGE_SIZE.MOBILE}
      style={{ width: "100%", height: "auto" }}
      onClick={() => handleClickOpen(productImage)}
      priority={priorityImages.has(idx)}
      sizes="100vw"
    />
  ));

  sliderItems.push(<LinkInCarousel />);

  return (
    <>
      <ProductImageWrapperMobile>
        {campaignComponent !== null && (
          <SliderBanner.Splash>{campaignComponent}</SliderBanner.Splash>
        )}
        {children}
        <Slider
          items={sliderItems}
          slidesConfig={{ perView: 1.2, spacing: 5, origin: "center" }}
          loop={{ shouldLoop: true }}
        />
      </ProductImageWrapperMobile>

      <ImageDialog
        initialSlideIndex={initialSlideIndex}
        handleClose={handleClose}
        productImages={productImages}
        open={open}
      />
    </>
  );
};

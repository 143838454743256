import React from "react";
import { ArrowButton, IconContainer } from "./Slider.styled";
import { Icon } from "../../Icon";
import noop from "lodash/noop";

type Props = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  horizontalMargin?: number;
  verticalMargin?: number;
};
const Left = ({
  disabled = false,
  onClick = noop,
  horizontalMargin = -44,
  verticalMargin,
}: Props) => (
  <ArrowButton
    data-testid="slider-arrow-left"
    disabled={disabled}
    onClick={onClick}
    aria-disabled={disabled}
    style={{
      left: `${horizontalMargin}px`,
      bottom: `${
        verticalMargin !== undefined ? `${verticalMargin}px` : "auto"
      }`,
      top: `${verticalMargin !== undefined ? "auto" : "50%"}`,
    }}
  >
    <IconContainer>
      <Icon name="CaretLeft" />
    </IconContainer>
  </ArrowButton>
);
const Right = ({
  disabled = false,
  onClick = noop,
  horizontalMargin = -44,
  verticalMargin,
}: Props) => (
  <ArrowButton
    data-testid="slider-arrow-right"
    disabled={disabled}
    onClick={onClick}
    aria-disabled={disabled}
    style={{
      right: `${horizontalMargin}px`,
      left: "auto",
      bottom: `${
        verticalMargin !== undefined ? `${verticalMargin}px` : "auto"
      }`,
      top: `${verticalMargin !== undefined ? "auto" : "50%"}`,
    }}
  >
    <IconContainer>
      <Icon name="CaretRight" />
    </IconContainer>
  </ArrowButton>
);

export const Arrow = {
  Left,
  Right,
};

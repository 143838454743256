import styled from "@emotion/styled/macro";
import { colorsAsCssVariable } from "../../../styles/theme/colors";
import { typographyToCss } from "../../../styles/helpers";

const ProgressBar = styled.div<{
  progressInPercent: number; // 0-100
}>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  min-height: 28px;
  width: 100%;
  background: ${colorsAsCssVariable.xxlLightGrey};
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ progressInPercent }) => progressInPercent}%;
    height: 100%;
    background: ${colorsAsCssVariable.xxlGreen};
  }

  label,
  svg {
    z-index: 2;
  }

  label {
    line-height: 1;
    margin-left: 4px;
    ${typographyToCss({
      fontSize: 14,
    })}
  }

  progress {
    opacity: 0;
    width: 0;
  }
`;
export { ProgressBar };

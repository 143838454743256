import { containerMaxWidth } from "./xxl-theme";

const Breakpoints = {
  min: 0,
  smallMobile: 320,
  mobile: 375,
  smallTablet: 500,
  muiTablet: 600,
  tablet: 768,
  tabletHorizontal: 1024,
  laptop: 1080,
  desktop: 1280,
  siteContainer: containerMaxWidth,
  bigDesktop: 1440,
  fullHD: 1920,
};
type Breakpoint = keyof typeof Breakpoints;

export default Breakpoints;
export type { Breakpoint };

import { createContext, useContext } from "react";

export const UPDATE_DRAWER_FILTER_SELECTED = "UPDATE_DRAWER_FILTER_SELECTED";
export const UPDATE_STICKY_FILTER = "UPDATE_STICKY_FILTER";
export const SET_EXPANDED_TOP_FILTER = "SET_EXPANDED_TOP_FILTER";
export const SET_EXPANDED_FILTER_DRAWER = "SET_EXPANDED_FILTER_DRAWER";
export const TOGGLE_FILTER_DRAWER = "TOGGLE_FILTER_DRAWER";

type State = {
  filterSelected: boolean;
  isFilterDrawerOpen: boolean;
  isStickyFilterBar: boolean;
  expandedTopFilter: string;
  expandedFilterDrawer: string;
};

type StateStruct = {
  current: State;
  prev?: State;
};

export type Action =
  | {
      type: typeof UPDATE_DRAWER_FILTER_SELECTED;
      payload: boolean;
    }
  | {
      type: typeof UPDATE_STICKY_FILTER;
      payload: boolean;
    }
  | {
      type: typeof SET_EXPANDED_TOP_FILTER;
      payload: string;
    }
  | {
      type: typeof SET_EXPANDED_FILTER_DRAWER;
      payload: string;
    }
  | {
      type: typeof TOGGLE_FILTER_DRAWER;
      payload: boolean;
    };

type DefaultValue = {
  state: State;
  dispatch: (action: Action) => void;
};

const initialStaticState: State = {
  filterSelected: false,
  isFilterDrawerOpen: false,
  isStickyFilterBar: false,
  expandedTopFilter: "",
  expandedFilterDrawer: "",
} as const;

export const getInitialState = (): StateStruct => {
  return {
    current: initialStaticState,
  };
};

const reducerInternal = (state: State, action: Action): State => {
  switch (action.type) {
    case UPDATE_DRAWER_FILTER_SELECTED:
      return {
        ...state,
        filterSelected: action.payload,
      };
    case UPDATE_STICKY_FILTER:
      return {
        ...state,
        isStickyFilterBar: action.payload,
      };
    case SET_EXPANDED_TOP_FILTER:
      return {
        ...state,
        expandedTopFilter: action.payload,
      };
    case SET_EXPANDED_FILTER_DRAWER:
      return {
        ...state,
        expandedFilterDrawer: action.payload,
      };
    case TOGGLE_FILTER_DRAWER:
      return {
        ...state,
        ...{
          isFilterDrawerOpen: action.payload,
          expandedTopFilter: "",
        },
      };
    default:
      return state;
  }
};

export const reducer = (
  stateStruct: StateStruct,
  action: Action
): StateStruct => {
  return {
    prev: stateStruct.current,
    current: reducerInternal(stateStruct.current, action),
  };
};

export const FilterContext = createContext<DefaultValue>({
  dispatch: (): void => {
    return;
  },
  state: initialStaticState,
});

export const useFilterContext = (): DefaultValue => useContext(FilterContext);

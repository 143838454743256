import styled from "@emotion/styled/macro";
import { CaretRight } from "@xxl/icons";
import { Button } from "../../Common";
import { typographyToCss } from "../../../styles/helpers";
import { colorsAsCssVariable } from "../../../styles/theme/colors";
import spaces from "../../../styles/theme/spaces";
import { ANIMATION_DURATION } from "./constants";
import typography from "./MegaMenu.typography";
import { css } from "@emotion/react";

const { xxlGreen, xxlGrey, xxlLightGrey, xxlWebBlack } = colorsAsCssVariable;

const menuItemStyling = (linkType?: "secondary" | "default") => `
  align-items: center;
  background: ${linkType === "secondary" ? xxlLightGrey : "none"};
  border: 0;
  box-sizing: border-box;
  color: ${xxlWebBlack};
  display: flex;
  gap: ${spaces.mini};
  margin: 0;
  padding: ${spaces.regular} ${spaces.smallRegular};
  position: relative;
  text-align: left;
  width: 100%;

  &:after {
    border-bottom: 1px solid ${
      linkType === "secondary" ? xxlGrey : xxlLightGrey
    };
    bottom: 0;
    box-sizing: border-box;
    content: "";
    left: ${spaces.smallRegular};
    position: absolute;
    right: ${spaces.smallRegular};
  }
`;

const SubCategory = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<{ isActive: boolean }>`
  ${typographyToCss(typography.link)}
  ${menuItemStyling()}
  ${({ isActive }) =>
    isActive
      ? `color: ${xxlGreen}`
      : `transition: color ${ANIMATION_DURATION}ms 100ms ease-out`};
  -webkit-tap-highlight-color: transparent;
`;

type LinkProps = {
  linkType?: "default" | "secondary";
};

const Link = styled.a<LinkProps>(
  ({ linkType = "default" }) => css`
    ${typographyToCss(typography.link)}
    ${menuItemStyling(linkType)}
  `
);

const BackButton = styled(Button)`
  ${typographyToCss(typography.backButton)}
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  background: none;
  border: 0;
  color: ${xxlWebBlack};
  display: flex;
  gap: 5px;
  margin: 0;
  padding: ${spaces.smallLarge} ${spaces.smallRegular} ${spaces.micro};
  text-align: left;
  width: 100%;
`;

const ShowAllLink = styled.a`
  ${typographyToCss(typography.showAllLink)}
  ${menuItemStyling()}
`;
const ArrowRight = styled(CaretRight)`
  margin-left: auto;
  /* Align icon to the right */
  transform: translateX(0.3em);
`;

export { ArrowRight, BackButton, Link, ShowAllLink, SubCategory };

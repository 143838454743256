import * as React from "react";
import { flushSync } from "react-dom";
import type { TranslationKey } from "../../../translations";
import { isNotNullOrUndefined } from "@xxl/common-utils";

export type SizeFitnessOption = {
  value: number | string;
  labelTranslationKey: TranslationKey;
  ref?: React.RefObject<HTMLDivElement>;
};

export type OnChangeCallback = (item?: number | string) => void;

type TouchOrMouseEvent =
  | React.TouchEvent<Element>
  | React.MouseEvent<HTMLDivElement, MouseEvent>;

type SliderInputHelper = {
  activeHoverItem: SizeFitnessOption | null;
  handleMouseEnter: (activeItem: SizeFitnessOption) => void;
  handleMouseLeave: () => void;
  handleTouchEnd: (
    isPossibleToToggleSelection: boolean
  ) => (event: TouchOrMouseEvent) => void;
  handleMouseUp: (
    isPossibleToToggleSelection: boolean
  ) => (
    clickedItem: SizeFitnessOption
  ) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  handleTouchMove: (event: React.TouchEvent<Element>) => void;
  isSelected: (item: SizeFitnessOption) => boolean;
  isHover: (item: SizeFitnessOption) => boolean;
  selectedItem: SizeFitnessOption | null;
};

export const isItemBeingHovered = (
  item: SizeFitnessOption,
  pointerPosition: number
): boolean => {
  if (isNotNullOrUndefined(item.ref?.current)) {
    const { left, right } = item.ref.current.getBoundingClientRect();
    return pointerPosition >= left && pointerPosition <= right;
  }
  return false;
};

const SliderInputHelper = (
  items: readonly SizeFitnessOption[],
  onChange?: OnChangeCallback
): SliderInputHelper => {
  const [activeHoverItem, setActiveHoverItem] =
    React.useState<SizeFitnessOption | null>(null);
  const [selectedItem, setSelectedItem] =
    React.useState<SizeFitnessOption | null>(null);
  const handleHover = (pointerPosition: number): void => {
    const foundItem = items.find((item) =>
      isItemBeingHovered(item, pointerPosition)
    );
    if (foundItem !== undefined && activeHoverItem?.value !== foundItem.value) {
      flushSync(() => {
        setActiveHoverItem(foundItem);
      });
    }
  };
  const handleMouseEnter = (activeItem: SizeFitnessOption): void => {
    setActiveHoverItem(activeItem);
  };
  const handleMouseLeave = (): void => {
    setActiveHoverItem(null);
  };
  const handleTouchMove = (event: React.TouchEvent): void => {
    event.preventDefault();
    handleHover(event.changedTouches[0].pageX);
  };
  const isSelected = (item: SizeFitnessOption): boolean => {
    return Boolean(selectedItem !== null && selectedItem.value === item.value);
  };
  const sharedPointerEndFunctionality = (
    item: SizeFitnessOption,
    isPossibleToToggleSelection: boolean
  ): void => {
    if (isSelected(item) && isPossibleToToggleSelection) {
      setSelectedItem(null);
      onChange !== undefined && onChange(undefined);
    } else {
      setSelectedItem(item);
      onChange !== undefined && onChange(item.value);
    }
  };
  const handleTouchEnd =
    (isPossibleToToggleSelection: boolean) =>
    (event: TouchOrMouseEvent): void => {
      event.preventDefault();
      if (activeHoverItem !== null) {
        sharedPointerEndFunctionality(
          activeHoverItem,
          isPossibleToToggleSelection
        );
      }
      setActiveHoverItem(null);
    };
  const handleMouseUp =
    (isPossibleToToggleSelection: boolean) =>
    (clickedItem: SizeFitnessOption) =>
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      event.preventDefault();
      sharedPointerEndFunctionality(clickedItem, isPossibleToToggleSelection);
    };
  const isHover = (item: SizeFitnessOption): boolean => {
    return Boolean(
      activeHoverItem !== null && activeHoverItem.value === item.value
    );
  };

  return {
    activeHoverItem,
    handleMouseEnter,
    handleMouseLeave,
    handleTouchEnd,
    handleMouseUp,
    handleTouchMove,
    isSelected,
    isHover,
    selectedItem,
  };
};

export { SliderInputHelper };

import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { resolutionSizes } from "../../../../config";
import {
  FormikField,
  Label,
  InputErrorMessage,
  ButtonStyledAsLink,
} from "../../../../styled";
import { MQ, typographyToCss } from "../../../../styles/helpers";
import { xxlTheme } from "../../../../styles/xxl-theme";
import { MailWithCheck } from "@xxl/icons";
import { Button } from "../../../../components/Common/Button";
import typography from "../../../../styles/theme/typography";

const { colors, spaces } = xxlTheme;

export const white = "var(--color-xxl-light-gray)";

const sectionWidthInPx = 550;

type FormContainerProps = {
  inProgress: boolean;
  isInModal?: boolean;
};
const formContainerStyles = {
  tabletSidePadding: "130px",
  basePadding: spaces.large,
};
export const FormContainer = styled.form<FormContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  pointer-events: ${(props): string => (props.inProgress ? "none" : "auto")};
  width: 100%;
  padding: ${spaces.large} ${spaces.smallRegular};
  box-sizing: border-box;

  background-color: ${colors.xxlWhite};

  ${typographyToCss({
    fontSize: 10,
    tablet: {
      fontSize: 12,
    },
  })}

  ${({ isInModal = false }) => {
    if (isInModal) {
      return css`
        padding: 30px ${spaces.smallRegular} ${spaces.smallRegular};
        ${MQ("mobile")} {
          padding: ${spaces.large} ${spaces.smallRegular};
        }
        ${MQ("tablet")} {
          padding: ${formContainerStyles.basePadding};
          width: 50%;
        }
      `;
    }

    return css`
      ${MQ("laptop")} {
        padding: ${formContainerStyles.basePadding}
          ${formContainerStyles.tabletSidePadding};
      }

      ${MQ("tabletHorizontal")} {
        box-sizing: border-box;
        width: 50%;
        padding: ${formContainerStyles.basePadding} ${spaces.smallGiant};
      }

      ${MQ("laptop")} {
        max-width: ${sectionWidthInPx}px;
        padding: ${formContainerStyles.basePadding};
      }
    `;
  }}
`;

export const FormHeader = styled.h3`
  margin: 0 0 ${spaces.regular};
  ${typographyToCss({
    tablet: {
      lineHeight: 0.7,
    },
  })}
`;

export const FormHeaderInModal = styled.h3`
  margin: 0;
  flex-grow: 1;
`;

const BaseContainer = styled.div`
  padding-bottom: 10px;
`;

export const CheckboxContainer = styled(BaseContainer)`
  padding-bottom: 0;
  margin: ${spaces.smallRegular} 0 ${spaces.big};
`;

type InputContainerProps = {
  isHidden?: boolean;
};

export const InputContainer = styled(BaseContainer)<InputContainerProps>(
  ({ isHidden = false }) => css`
    width: 100%;
    padding-bottom: 0;

    ${isHidden &&
    css`
      visibility: hidden;
      height: 0;
    `}
  `
);

export const ButtonContainer = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 66px;
  min-width: 212px;
  padding-right: 25%;
  padding-left: 25%;

  @media (max-width: ${resolutionSizes.mobile}px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

type RewardLabelProps = {
  required?: boolean;
};

export const SignUpFormLabel = styled(Label)`
  display: inline-block;
  margin: ${spaces.smallLarge} 0 ${spaces.mini};
  color: ${colors.xxlWebBlack};
  ${typographyToCss({
    fontSize: 12,
  })}

  ${(props: RewardLabelProps): string =>
    props.required
      ? `
    &:after {
      content: "*";
    }
  `
      : ""}
`;

type SignUpFormInputProps = {
  hasError: boolean;
};

export const SignUpFormInput = styled(FormikField)<SignUpFormInputProps>(
  ({ hasError = false }) => css`
    height: 50px;
    box-sizing: border-box;
    width: 100%;
    color: ${colors.xxlDarkGrey};

    &::placeholder {
      opacity: 0.4;
    }

    ${hasError &&
    css`
      border-color: ${colors.xxlRed};
    `}
  `
);

export const TermsLabel = styled.label`
  &,
  a {
    color: ${colors.xxlDarkGrey};
  }
  a {
    border-bottom: 1px solid ${colors.xxlDarkGrey};
  }
`;

export const SignUpErrorMessage = styled(InputErrorMessage)`
  color: ${colors.xxlWebBlack};
`;

export const SignUpInfoMessage = styled(InputErrorMessage)`
  color: ${colors.xxlWebBlack};
`;

export const AlreadyMemberContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin: ${spaces.smallRegular} 0 0;
`;

export const MailWithCheckIcon = styled(MailWithCheck)`
  width: 90px;
  height: auto;
`;

export const CustomerFoundIconWrapper = styled.div`
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const SignupFormValue = styled.p`
  margin-top: 0;
`;

export const SignupButton = styled(Button)`
  margin-top: 20px;
`;

export const FormButtonAsLink = styled(ButtonStyledAsLink)`
  margin-top: ${spaces.mini};
  display: block;

  ${typographyToCss(typography.smallMedium)}
`;

import React from "react";
import stringFormat from "string-format";
import { getRestockDate } from "./utils";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { useXxlMediaQuery } from "../../../src/hooks/useXxlMediaQuery";
import { FormButton } from "./FormButton";
import { Message } from "./Message";
import { Container, StickyFormContent } from "./styles";

export const ProductFormArchived = ({
  btnRef,
  isDiscontinued,
  isRefInView,
  productName,
}: {
  btnRef: React.MutableRefObject<null>;
  isAddToCartBtnInView: boolean;
  isDiscontinued: boolean;
  isRefInView: boolean;
  productName: string;
}) => {
  const { t } = useTranslations();
  const isLaptopSize = useXxlMediaQuery("LaptopMediaQuery");

  const TKey = isDiscontinued
    ? "product.details.unavailable.product"
    : "product.details.unavailable.product.waiting.for.restock";

  const text = stringFormat(t(TKey), productName, getRestockDate());

  return (
    <>
      <Container ref={btnRef}>
        <FormButton />
        <Message text={text} />
      </Container>
      <StickyFormContent
        shouldShowStickyContent={!isRefInView && !isLaptopSize}
      >
        <FormButton />
        <Message text={text} />
      </StickyFormContent>
    </>
  );
};

const commonProperties = {
  lineHeight: 1.2,
  letterSpacing: -0.1,
};

export default {
  heading: {
    ...commonProperties,
    fontFamily: "var(--font-family-medium)",
    fontSize: 15,
  },
  error: {
    ...commonProperties,
    fontFamily: "var(--font-family-bold)",
    fontSize: 12,
  },
};

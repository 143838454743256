import React from "react";
import type { PrintConfigurationsObject } from "../Api/types";
import {
  EGTeamBundleList,
  TeamBundleListItem,
  EGTeamBundleStrong,
  TeamBundleText,
} from "../Styles/ProductItem.styled";

type SettingsProps = {
  label: string;
  members: string[];
  isLongText: boolean;
};

type TSSettingsListProps = {
  settingsArray: PrintConfigurationsObject[][];
};

export const TSSettingsList: React.FunctionComponent<TSSettingsListProps> = ({
  settingsArray,
}) => {
  const settings: SettingsProps[] = settingsArray.map((setting) => ({
    label: typeof setting[0].label === "string" ? setting[0].label : "",
    members: [],
    isLongText: true,
  }));

  settings.forEach((object) => {
    const labelArray = settingsArray.find(
      (array) => array[0].label === object.label
    );

    labelArray?.forEach((item) => {
      if (typeof item.print === "string") {
        object.members.push(item.print);
        if (item.print.length <= 2) {
          object.isLongText = false;
        }
      }
    });
  });

  return (
    <>
      {settings.map((item, index) => (
        <React.Fragment key={`settings-list-${index}`}>
          <EGTeamBundleStrong data-testid="ts-members-list-title">
            {item.label}:
          </EGTeamBundleStrong>
          <EGTeamBundleList data-testid="ts-members-list">
            {item.isLongText ? (
              <>
                {item.members.map((member, listIndex) => (
                  <TeamBundleListItem
                    key={`members-list-${listIndex}`}
                    data-testid="ts-members-list-row"
                  >
                    <TeamBundleText data-testid="ts-members-list-row-text">
                      {member}
                    </TeamBundleText>
                  </TeamBundleListItem>
                ))}
              </>
            ) : (
              <TeamBundleListItem data-testid="ts-members-list-single-row">
                <TeamBundleText data-testid="ts-members-list-short-texts-list">
                  {item.members.join(", ")}
                </TeamBundleText>
              </TeamBundleListItem>
            )}
          </EGTeamBundleList>
        </React.Fragment>
      ))}
    </>
  );
};

import styled from "@emotion/styled/macro";
import { typographyToCss } from "react-app/src/styles/helpers";

export const Text = styled.p`
  margin: 0;
  line-height: 1;
  ${typographyToCss({
    fontFamily: "var(--font-family-bold)",
    fontSize: 12,
    tablet: {
      fontSize: 15,
    },
  })}
`;

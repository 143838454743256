import * as React from "react";
import { RadiobuttonWrapper } from "../../../styled";
import { InputWrapper, StoreLabel } from "./StoreStockListItemName.styled";

type StoreStockListItemNameProps = {
  checked: boolean;
  id: string;
  name: string;
  isDisabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};
const StoreStockListItemName: React.FunctionComponent<
  StoreStockListItemNameProps
> = ({ checked, id, name, onChange, isDisabled = false }) => {
  return (
    <InputWrapper>
      <RadiobuttonWrapper isDisabled={isDisabled}>
        <input
          type="radio"
          checked={checked}
          id={id}
          disabled={isDisabled}
          onChange={onChange}
          readOnly={onChange === undefined}
        />
        <StoreLabel htmlFor={id}>{name}</StoreLabel>
      </RadiobuttonWrapper>
    </InputWrapper>
  );
};

export { StoreStockListItemName };

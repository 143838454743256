import axios, { type AxiosInstance } from "axios";
import https from "https";
import { PimApi } from "@xxl/pim-api";
import { ContentApi } from "@xxl/content-api";
import {
  getAuthorizationApiConfiguration,
  getContentApiConfiguration,
  getPimApiConfiguration,
  getPricesApiConfiguration,
  getSsmApiConfiguration,
} from "@/utils/environment-variables";
import { SsmApi } from "@xxl/ssm-api";
import { AuthorizationApi } from "@xxl/authorization-api";
import {
  PricesApi,
  Configuration as PricesApiConfiguration,
} from "@xxl/prices-api";
import { log } from "@xxl/logging-utils";
import { isNotNullOrUndefined } from "@xxl/common-utils";

/** please feel free to adjust in this file, the settings here are not followed by any measurements yet,
 * rather set to height values just to externalize usage of api's and have control in one file*/

class Apis {
  pimApi: PimApi;
  contentApi: ContentApi;
  ssmApi: SsmApi;
  authorizationApi: AuthorizationApi;
  pricesApi: PricesApi;
  graphQlApi: AxiosInstance;
  sbApi: AxiosInstance;
  static instance: Apis;
  private constructor() {
    this.pimApi = new PimApi(
      getPimApiConfiguration(),
      undefined,
      axios.create({
        httpsAgent: new https.Agent({
          keepAlive: true,
          keepAliveMsecs: 3000,
          maxSockets: 150,
          maxFreeSockets: 15,
        }),
        timeout: 5000,
      })
    );
    this.contentApi = new ContentApi(
      getContentApiConfiguration(),
      undefined,
      axios.create({
        httpsAgent: new https.Agent({
          keepAlive: true,
          keepAliveMsecs: 3000,
          maxSockets: 200,
          maxFreeSockets: 20,
        }),
        timeout: 5000,
      })
    );
    this.ssmApi = new SsmApi(
      getSsmApiConfiguration(),
      undefined,
      axios.create({
        httpsAgent: new https.Agent({
          keepAlive: true,
          keepAliveMsecs: 3000,
          maxSockets: 70,
          maxFreeSockets: 7,
        }),
        timeout: 5000,
      })
    );
    this.authorizationApi = new AuthorizationApi(
      getAuthorizationApiConfiguration(),
      undefined,
      axios.create({
        httpsAgent: new https.Agent({
          keepAlive: true,
          keepAliveMsecs: 3000,
          maxSockets: 200,
          maxFreeSockets: 20,
        }),
        timeout: 3000,
      })
    );
    this.pricesApi = new PricesApi(
      new PricesApiConfiguration(getPricesApiConfiguration()),
      undefined,
      axios.create({
        httpsAgent: new https.Agent({
          keepAlive: true,
          keepAliveMsecs: 3000,
          maxSockets: 100,
          maxFreeSockets: 10,
        }),
        timeout: 5000,
      })
    );
    this.graphQlApi = axios.create({
      httpsAgent: new https.Agent({
        keepAlive: true,
        keepAliveMsecs: 1000,
        maxSockets: 100,
        maxFreeSockets: 10,
      }),
      timeout: 5000,
    });
    this.sbApi = axios.create({
      httpsAgent: new https.Agent({
        keepAlive: true,
        keepAliveMsecs: 1000,
        maxSockets: 100,
        maxFreeSockets: 10,
      }),
      timeout: 5000,
    });
  }
  static getInstance() {
    if (!isNotNullOrUndefined(Apis.instance)) {
      Apis.instance = new Apis();
      log.info("Apis instance created");
    }
    log.debug("Apis instance returned");
    return Apis.instance;
  }
}

export { Apis };

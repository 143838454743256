import { useEffect, useState } from "react";
import type { CookieInformationEvent, UserConsentData } from "../../global";
import {
  didAnyValueChangeFromTrueToFalse,
  didUserOnlyConsentToNecessaryCookies,
  removeCookies,
} from "./useCookieConsent.helper";

const useCookieConsent = () => {
  const [localConsentData, setLocalConsentData] =
    useState<UserConsentData | null>(null);

  useEffect(() => {
    const callback = ({
      detail: { consents },
    }: {
      detail: CookieInformationEvent;
    }) => {
      if (didUserOnlyConsentToNecessaryCookies(consents)) {
        removeCookies();
      }
      if (localConsentData === null) {
        setLocalConsentData(consents);
        return;
      }
      if (didAnyValueChangeFromTrueToFalse(consents, localConsentData)) {
        removeCookies();
        window.location.reload();
        return;
      }
      setLocalConsentData(consents);
    };

    window.addEventListener("CookieInformationConsentGiven", callback);
    return () =>
      window.removeEventListener("CookieInformationConsentGiven", callback);
  });
};

export { useCookieConsent };

const uuidv4 = (): string =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const BASE = 16;
    const r = (Math.random() * BASE) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(BASE);
  });

if (typeof window !== "undefined") {
  window.Common = {
    uuidv4,
  };
}

export { uuidv4 };

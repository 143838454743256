import type { UserConsentData } from "../../global";
import { isNotUndefined } from "@xxl/common-utils";
import trim from "lodash/trim";
import type { Entries } from "type-fest";
import { necessaryCookies } from "../../constants";
import Cookie from "js-cookie";

const didAnyValueChangeFromTrueToFalse = (
  consents: UserConsentData,
  previousUserConsentData: UserConsentData
) =>
  (Object.entries(consents) as Entries<typeof consents>).some(
    ([key, newValue]) =>
      previousUserConsentData[key] === true && newValue === false
  );

const didUserOnlyConsentToNecessaryCookies = (consents: UserConsentData) =>
  (Object.entries(consents) as Entries<typeof consents>)
    .filter(([name]) => name !== "cookie_cat_necessary")
    .every(([, value]) => value === false);

const removeCookies = () => {
  const { host } = window.location;
  const topLevelDomain = host.split(".").at(-1);
  if (topLevelDomain === undefined) {
    throw Error("Top level domain is undefined");
  }

  const cookies = document.cookie
    .split(";")
    .map((value) => value.split("=").at(0))
    .filter(isNotUndefined)
    .map(trim)
    .filter((name) => !necessaryCookies.includes(name));

  for (const name of cookies) {
    ["", `.xxl.${topLevelDomain}`, `.${host}`].forEach((domain) => {
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=${domain}`;
    });
    [undefined, `.xxl.${topLevelDomain}`, `.${host}`].forEach((domain) =>
      Cookie.remove(name, { domain })
    );
  }
};

export {
  didAnyValueChangeFromTrueToFalse,
  didUserOnlyConsentToNecessaryCookies,
  removeCookies,
};

import React from "react";
import { LinkListItem } from "./LinkListItem";
import type { HeaderLink } from "../../../global";
import { CategoryIcon } from "./CategoryIcon";

const MoreLinks: React.FunctionComponent<{
  links: HeaderLink[];
}> = ({ links }) => (
  <>
    {links.map(({ iconCode = "", displayName, url }) => (
      <LinkListItem href={url} key={displayName} linkType="secondary">
        <CategoryIcon code={iconCode} />
        {displayName}
      </LinkListItem>
    ))}
  </>
);

export { MoreLinks };

import React, { useEffect, useState } from "react";
import {
  Wrapper,
  FormWrapper,
  InputsWrapper,
  RadioWrapper,
  ErrorMessage,
  Description,
} from "../Styles/ShopAsSwitch.styled";
import {
  CART_CONTENT_EDITING_LOCK,
  CART_CONTENT_EDITING_UNLOCK,
  CART_REQUEST_SUCCESS,
  isWalley,
  UPDATE_CUSTOMER_TYPE,
  UPDATE_PAYMENT_VALUES,
  UPDATE_SHIPMENT_COST,
  UPDATE_WALLEY_SNIPPET,
  UPDATE_CHECKOUT_ORDER_DATA,
  useCartContext,
  UPDATE_CART_ID,
} from "../CartState";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { useStateValue } from "cotton-box-react";
import { useSessionSource } from "../../../contexts/Session";
import { RewardBox } from "./RewardBox";
import {
  type ClearCartIdMutation,
  CustomerType,
  PaymentProvider,
} from "../../../generated/graphql-code-generator";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { Info } from "@xxl/icons";
import { setStorageItem } from "../../../utils/LocalStorage/local-storage";
import {
  updateCheckoutSnippet,
  updateGiftCardsSnippet,
  updateReplacementSnippet,
} from "../Services/updateCartQuantity";
import { useSharedData } from "../../../contexts/SharedData";
import {
  getCheckoutConfirmationSnippet,
  PaymentType,
  updateCartLoyaltyContent,
  updateShippingCosts,
} from "../Api/CartAPI";
import { giftCardEntries } from "../../BuyGiftCardForm/buy-giftcard-form-helper";
import { replacementInput } from "../../Orders/ReturnOrExchange/PayForExchangeState";
import { getDefaultCustomerType } from "../cart-page-helper";
import type { CartResponseData } from "../Api/types";
import { WalleyCheckoutEvents } from "../constants";
import { parseCartId } from "../Services/utils";
import { useTracking } from "../../../contexts/Tracking";
import { callGraphQL } from "../../../graphql/graphqlApi";
import { clearCartIdMutation } from "../Api/graphqlQueries";
import { log } from "@xxl/logging-utils";

export const LOCAL_STORAGE_SHOP_AS_KEY = "shopAsOption";

// This options have same values as CustomerType enum, but are sorted in order required by business
const SHOP_AS_OPTIONS: CustomerType[] = ["PERSON", "ORGANIZATION"];

export type LocalStorageShopAsOptionType = {
  shopAs: CustomerType;
  expires: number;
};

type PaymentTypeKeys = keyof typeof PaymentType;

type ShopAsSwitchProps = {
  hideRewardBox?: boolean;
  hideTopSpacing?: boolean;
  paymentType?: (typeof PaymentType)[PaymentTypeKeys];
  isTeamsalesAdmin?: boolean;
};

export const ShopAsSwitch: React.FunctionComponent<ShopAsSwitchProps> = ({
  hideRewardBox = false,
  hideTopSpacing = false,
  paymentType = PaymentType.CHECKOUT,
  isTeamsalesAdmin = false,
}) => {
  const { state, dispatch } = useCartContext();
  const { t } = useTranslations();
  const isLoggedIn = useStateValue(useSessionSource);
  const giftCardValue = useStateValue(giftCardEntries);
  const replacementInputValue = useStateValue(replacementInput);
  const { customerType, customerTypeOptions } = state;
  const [changeOption, setChangeOption] = useState<CustomerType | undefined>();
  const [isShopAsSwitch, setIsShopAsSwitch] = useState(false);
  const trackers = useTracking();
  const {
    configuration: {
      amplifyConfig: { aws_appsync_graphqlEndpoint, aws_appsync_apiKey },
    },
  } = useSharedData().data;
  const defaultCustomerType = getDefaultCustomerType(isTeamsalesAdmin);

  const setLocalStorageOption = (option: CustomerType): void => {
    const date = new Date();

    const storageItem = JSON.stringify({
      shopAs: option,
      expires: date.setDate(date.getDate() + 1),
    });
    setStorageItem(LOCAL_STORAGE_SHOP_AS_KEY, storageItem);
  };

  const checkIsLocalStorageOptionAvailable = (
    option: CustomerType,
    options: CustomerType[]
  ): boolean => options.includes(option);

  const getLocalStorageOption = (): void => {
    const shopAsLocalStorageValue = localStorage.getItem(
      LOCAL_STORAGE_SHOP_AS_KEY
    );
    const dateNow = new Date();
    if (isNotNullOrUndefined(shopAsLocalStorageValue)) {
      const { shopAs, expires } = JSON.parse(
        shopAsLocalStorageValue
      ) as LocalStorageShopAsOptionType;
      const expirationDate = new Date(expires);
      if (
        dateNow > expirationDate ||
        (customerTypeOptions.length > 0 &&
          !checkIsLocalStorageOptionAvailable(shopAs, customerTypeOptions))
      ) {
        localStorage.removeItem(LOCAL_STORAGE_SHOP_AS_KEY);
        setChangeOption(defaultCustomerType);
      } else if (shopAs !== customerType) {
        setChangeOption(shopAs);
      } else {
        setChangeOption(customerType);
      }
    }
  };

  const changeTrigger = (option: CustomerType): void => {
    setIsShopAsSwitch(true);
    setChangeOption(option);
    setLocalStorageOption(option);
  };

  const lockCart = () =>
    dispatch({
      type: CART_CONTENT_EDITING_LOCK,
    });

  const lockCartAndCheckout = () => {
    lockCart();
    window.walley?.checkout.api.suspend();
  };

  const unlockCart = () =>
    dispatch({
      type: CART_CONTENT_EDITING_UNLOCK,
    });

  const unlockCartAndCheckout = () => {
    unlockCart();
    window.walley?.checkout.api.resume();
  };

  const _updateLoyalty = async () => {
    lockCartAndCheckout();
    try {
      const response = await updateCartLoyaltyContent(
        aws_appsync_graphqlEndpoint,
        aws_appsync_apiKey,
        parseCartId(state.cartId)
      );

      if (
        isNotNullOrUndefined(response.data.data) &&
        isNotNullOrUndefined(response.data.data.updateCartLoyalty)
      ) {
        const cartResponseData = {
          data: {
            data: {
              cart: {
                ...response.data.data.updateCartLoyalty,
              },
            },
          } as CartResponseData,
        };
        dispatch({
          type: CART_REQUEST_SUCCESS,
          payload: cartResponseData,
        });
      }
    } catch (error) {
      log.error("Cannot update cart loyalty status", error);
    } finally {
      unlockCartAndCheckout();
    }
  };

  const _updateShipment = async () => {
    lockCartAndCheckout();
    try {
      const response = await updateShippingCosts(
        aws_appsync_graphqlEndpoint,
        aws_appsync_apiKey,
        parseCartId(state.cartId)
      );

      if (response.data.data?.updateCartShipping !== undefined) {
        dispatch({
          type: UPDATE_PAYMENT_VALUES,
          payload: response.data.data.updateCartShipping.totals,
        });
      }
    } catch (error) {
      log.error("Cannot get shipment update", error);
    } finally {
      unlockCartAndCheckout();
    }
  };

  const _getCheckoutOrderData = async () => {
    try {
      const response = await getCheckoutConfirmationSnippet(
        aws_appsync_graphqlEndpoint,
        aws_appsync_apiKey,
        PaymentProvider.WALLEY,
        state.cartId
      );
      dispatch({
        type: UPDATE_CART_ID,
        payload: undefined,
      });
      dispatch({
        type: UPDATE_CHECKOUT_ORDER_DATA,
        payload:
          response.data.data?.confirmationSnippet?.checkoutOrderData ??
          undefined,
      });
    } catch (error) {
      log.error("Cannot get checkout order data", error);
    }
  };

  useEffect(() => {
    if (state.updateShipmentCost && isWalley(state.paymentProvider)) {
      void _updateShipment();
      dispatch({
        type: UPDATE_SHIPMENT_COST,
        payload: false,
      });
    }
  }, [state.updateShipmentCost, state.paymentProvider, _updateShipment]);

  useEffect(() => {
    const cartLoyaltyChanged = () => {
      if (!isLoggedIn) {
        void _updateLoyalty();
      }
    };

    isWalley(state.paymentProvider) &&
      !isLoggedIn &&
      document.addEventListener(
        WalleyCheckoutEvents.crmUpdated,
        cartLoyaltyChanged
      );

    return () => {
      document.removeEventListener(
        WalleyCheckoutEvents.crmUpdated,
        cartLoyaltyChanged
      );
    };
  }, [state.paymentProvider, isLoggedIn, _updateLoyalty]);

  useEffect(() => {
    const shipmentUpdateChanged = () => {
      void _updateShipment();
    };
    const clearCartId = async () => {
      try {
        await callGraphQL<ClearCartIdMutation>(
          {
            query: clearCartIdMutation,
            variables: {
              input: { cart: { id: { id: parseCartId(state.cartId) } } },
            },
          },
          aws_appsync_graphqlEndpoint,
          aws_appsync_apiKey
        );
      } catch (error) {
        log.error("Failed to clear the cartId cookie", state.cartId, error);
      }
    };
    const handleCompletedPurchase = () => {
      void clearCartId();
      void _getCheckoutOrderData();
    };

    if (isWalley(state.paymentProvider)) {
      document.addEventListener(
        WalleyCheckoutEvents.shippingUpdated,
        shipmentUpdateChanged
      );
      document.addEventListener(
        WalleyCheckoutEvents.purchaseCompleted,
        handleCompletedPurchase
      );
    }

    return () => {
      document.removeEventListener(
        WalleyCheckoutEvents.shippingUpdated,
        shipmentUpdateChanged
      );
      document.removeEventListener(
        WalleyCheckoutEvents.purchaseCompleted,
        handleCompletedPurchase
      );
    };
  }, [state.paymentProvider, _updateShipment, _getCheckoutOrderData]);

  useEffect(() => {
    getLocalStorageOption();
    if (isNotNullOrUndefined(changeOption)) {
      if (changeOption !== customerType) {
        dispatch({
          type: UPDATE_WALLEY_SNIPPET,
          payload: true,
        });
        dispatch({
          type: UPDATE_CUSTOMER_TYPE,
          payload: changeOption,
        });
      } else {
        setChangeOption(customerType);
      }
    } else {
      setChangeOption(defaultCustomerType);
    }
  }, [changeOption]);

  useEffect(() => {
    if (
      state.updatePaymentSnippet === true &&
      isNotNullOrUndefined(changeOption) &&
      isShopAsSwitch
    ) {
      lockCartAndCheckout();
      if (paymentType === PaymentType.CHECKOUT) {
        void updateCheckoutSnippet(
          dispatch,
          aws_appsync_graphqlEndpoint,
          aws_appsync_apiKey,
          state.customerTypeOptions,
          changeOption
        );
      }
      if (paymentType === PaymentType.GIFTCARDS) {
        void updateGiftCardsSnippet(
          dispatch,
          aws_appsync_graphqlEndpoint,
          aws_appsync_apiKey,
          giftCardValue,
          changeOption
        );
      }
      if (
        paymentType === PaymentType.REPLACE &&
        isNotNullOrUndefined(replacementInputValue)
      ) {
        void updateReplacementSnippet(
          dispatch,
          aws_appsync_graphqlEndpoint,
          aws_appsync_apiKey,
          replacementInputValue,
          changeOption
        );
      }
      dispatch({
        type: UPDATE_WALLEY_SNIPPET,
        payload: false,
      });
      setIsShopAsSwitch(false);
    }
  }, [state.updatePaymentSnippet, isShopAsSwitch]);

  useEffect(() => {
    if (isNotNullOrUndefined(state.checkoutOrderData)) {
      const shouldSendElevateEvent = paymentType !== PaymentType.GIFTCARDS;
      void trackers.sendOrderConfirmationEvent(
        state.checkoutOrderData,
        shouldSendElevateEvent
      );
    }
  }, [state.checkoutOrderData]);

  return (
    <Wrapper
      data-testid="walley-shop-as-switch"
      hideTopSpacing={hideTopSpacing}
      isLocked={state.isCartContentLocked}
    >
      {!isLoggedIn && !hideRewardBox && <RewardBox />}
      <FormWrapper>
        {customerTypeOptions.length > 0 && (
          <>
            <Description
              hideTopSpacing={hideTopSpacing || hideRewardBox || isLoggedIn}
            >
              {t("checkout.page.shop.as.title")}
            </Description>
            <InputsWrapper>
              {SHOP_AS_OPTIONS.map((option) => {
                const isDisabled = !customerTypeOptions.includes(option);
                const isChecked = option === changeOption;
                return (
                  <RadioWrapper key={option} isDisabled={isDisabled}>
                    <input
                      type="radio"
                      name="shopAsCustomerType"
                      id={`customer_type_${option.toLowerCase()}`}
                      checked={isChecked}
                      value={option}
                      onChange={() => {
                        changeTrigger(option);
                      }}
                      disabled={isDisabled}
                    />
                    <label htmlFor={`customer_type_${option.toLowerCase()}`}>
                      {t(`checkout.page.shop.as.${option}`)}
                    </label>
                  </RadioWrapper>
                );
              })}
            </InputsWrapper>
          </>
        )}
        {customerTypeOptions.length > 0 &&
          customerTypeOptions.find(
            (item) => item === CustomerType.ORGANIZATION
          ) === undefined && (
            <ErrorMessage>
              <Info />
              {t("checkout.page.shop.as.not.available.b2b.error")}
            </ErrorMessage>
          )}
      </FormWrapper>
    </Wrapper>
  );
};

import { Plus } from "@xxl/icons";
import React, { useState } from "react";
import { useSharedData } from "../../../contexts/SharedData";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import type { CartItemIdInput } from "../../../generated/graphql-code-generator";
import { LoadingCircle } from "../../Common/LoadingCircle";
import { Checkmark } from "../../Services/Checkmark";
import { SUCCESS_RESPONSE_STATUS, addServiceToEgCart } from "../Api/CartAPI";
import type { CartItem, GenericGraphQLError } from "../Api/types";

import {
  SERVICES_HAS_ERROR,
  SERVICES_INCREASE_QUANTITY,
  useServicesContext,
} from "../CartServiceProductsState";
import { UPDATE_CART_CONTENT, useCartContext } from "../CartState";
import { AddToCartButton } from "../Styles/CartServiceProduct.styled";
import { log } from "@xxl/logging-utils";

type AddServiceToCartButton = {
  item: CartItem;
  disabled: boolean;
  actionInProgress: boolean;
  onClick: (quantity: number) => void;
  onChange: (value: boolean) => void;
  onAddToCart: () => void;
};

const DEFAULT_QUANTITY_ADDED = 1;
const CHECKMARK_ANIMATION_DELAY = 1000;

export const AddServiceToCartButton: React.FunctionComponent<
  AddServiceToCartButton
> = ({ item, disabled, actionInProgress, onClick, onChange, onAddToCart }) => {
  const { state, dispatch } = useServicesContext();
  const { t } = useTranslations();
  const { dispatch: dispatchCart } = useCartContext();
  const [isLoading, setIsLoading] = useState(false);
  const [showCheckmark, setShowCheckmark] = useState(false);
  const { configuration } = useSharedData().data;

  const handleAddToCartClick = async () => {
    if (actionInProgress) {
      return;
    }

    onChange(true);

    try {
      const ean = item.ean !== undefined ? item.ean[0] : "";
      const parentId =
        state.parentId !== undefined
          ? state.parentId
          : { id: 0, type: "SERVICE" };
      const ApiResponse = await addServiceToEgCart(
        ean,
        "1",
        parentId as CartItemIdInput,
        configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
        configuration.amplifyConfig.aws_appsync_apiKey
      );

      if (ApiResponse.status === SUCCESS_RESPONSE_STATUS) {
        const items =
          ApiResponse.data.data?.addServiceProductsToCartItems.items;
        if (items !== undefined && items.length > 0) {
          setIsLoading(false);
          setShowCheckmark(true);
          setTimeout(() => {
            setShowCheckmark(false);
            onChange(false);
            onClick(DEFAULT_QUANTITY_ADDED);
            dispatch({
              type: SERVICES_INCREASE_QUANTITY,
            });
            dispatchCart({
              type: UPDATE_CART_CONTENT,
            });
          }, CHECKMARK_ANIMATION_DELAY);
          onAddToCart();
        }
      } else if (ApiResponse.data.errors !== undefined) {
        const { errors } = ApiResponse.data;
        dispatch({
          type: SERVICES_HAS_ERROR,
          payload: errors as GenericGraphQLError[],
        });
        setIsLoading(false);
        onChange(false);
      }
    } catch (err) {
      log.error("Cannot add service to cart", err);
    }
  };

  return (
    <AddToCartButton
      onClick={handleAddToCartClick}
      disabled={disabled}
      data-testid="add-to-cart-button"
    >
      <LoadingCircle isLoading={isLoading} />
      {showCheckmark && <Checkmark />}
      <Plus /> {t("product.details.add.to.cart.label")}
    </AddToCartButton>
  );
};

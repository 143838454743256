import styled from "@emotion/styled/macro";
import Tabs from "@mui/material/Tabs";
import React from "react";
import { typographyToCss } from "../../../styles/helpers";
import colors from "./MegaMenu.colors";
import text from "./MegaMenu.typography";

const StyledTabs = styled(Tabs)`
  &&& * {
    ${typographyToCss(text.tab)};
    min-width: auto;
    & .Mui-selected * {
      color: ${colors.activeColor};
      font-family: var(--font-family-bold);
    }

    & .MuiTabs-indicator {
      display: none;
    }

    &:first-of-type {
      padding-left: 0;
    }

    & .MuiSvgIcon-root {
      margin-bottom: 3px;
    }

    & .MuiTab-root {
      padding: 0;
    }
  }
`;

export const TabButtons = (props: any): React.ReactElement => (
  <StyledTabs variant="scrollable" scrollButtons="auto" {...props} />
);

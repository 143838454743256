import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { interactionFeedback, MQ } from "../../styles/helpers";
import { xxlTheme } from "../../styles/xxl-theme";
import {
  DESKTOP_HEIGHT,
  MOBILE_HEIGHT,
  MOBILE_HEIGHT_WITH_HEADER_LINKS,
} from "./constants";

const { colors, spaces } = xxlTheme;

const FlexWrapper = styled.section`
  align-items: center;
  display: flex;
  gap: ${spaces.smallRegular};
  height: ${MOBILE_HEIGHT}px;

  ${MQ("tabletHorizontal")} {
    gap: 60px;
    height: ${DESKTOP_HEIGHT}px;
  }

  a[href="/"] {
    margin-right: auto;
  }
`;

type GridHeaderProps = {
  isLoggedIn: boolean;
};

const GridWrapper = styled.section<GridHeaderProps>(
  ({ isLoggedIn }) => css`
    align-items: center;
    box-sizing: border-box;
    column-gap: 10px;
    display: grid;
    grid-template-columns: auto 1fr auto 40px ${isLoggedIn && " 40px"};
    grid-template-rows: 52px 50px;
    height: ${MOBILE_HEIGHT_WITH_HEADER_LINKS}px;
    row-gap: ${spaces.micro};

    > :last-child {
      grid-column: 1 / span ${isLoggedIn ? 5 : 4};
    }

    ${MQ("tabletHorizontal")} {
      column-gap: ${spaces.regular};
      grid-template-rows: 36px 56px;
      height: ${DESKTOP_HEIGHT}px;
      padding-top: ${spaces.smallLarge};
      row-gap: normal;
      > :last-child {
        grid-column: unset;
      }

      &.container__category-links {
        padding-bottom: ${spaces.small};
        display: flex;

        > :last-child {
          grid-column: 1 / span 4;
        }
      }
    }

    .search-suggestions-wrapper {
      align-self: flex-start;
    }

    .search-input-wrapper {
      height: 42px;
      ${MQ("tabletHorizontal")} {
        height: 34px;
      }
    }

    .mini-cart-button {
      margin-right: ${spaces.mini};
    }

    .mega-menu-wrapper {
      padding-top: 4px;
      margin-top: -1px;
    }

    .mega-menu-overlay {
      margin-top: 4px;
    }
  `
);

const Link = styled.a`
  color: ${colors.xxlWhite} ${interactionFeedback(`
    color: ${colors.xxlGreen};
    font-weight: bold;
  `)};
`;

const Row = styled.div`
  align-items: center;
  display: flex;
  gap: ${spaces.smallRegular};
`;

export { Link, FlexWrapper, GridWrapper, Row };

import styled from "@emotion/styled/macro";
import spaces from "../../styles/theme/spaces";
import { MQ } from "../../styles/helpers";

const Link = styled.a`
  justify-self: flex-start;
`;

const Logo = styled.img`
  display: block;
  height: ${spaces.smallLarge};
  width: auto;

  ${MQ("tabletHorizontal")} {
    height: 34px;
  }
`;

const XxlLogoLink = ({
  logoAlt,
  logoUrl,
}: {
  logoAlt: string;
  logoUrl: string;
}) => (
  <Link href="/">
    <figure>
      <Logo
        alt={logoAlt}
        height="162"
        loading="eager"
        src={logoUrl}
        width="770"
      />
    </figure>
  </Link>
);

export { XxlLogoLink };

import { isNotNullOrUndefined } from "@xxl/common-utils";
import { formatPrice as formatPriceRaw } from "@xxl/price-formatting-utils";
import { windowAccess } from "./Window";

const currency = windowAccess()._sharedData.siteCurrency;

/**
 * A function to format price, uses <code>window._sharedData.siteCurrency</code> to get currency
 *
 * @param priceValue price value to format
 */
export const formatPrice = (
  priceValue: number,
  currencyString?: string
): string => formatPriceRaw(priceValue, currencyString ?? currency);

/**
 * A function to format a price that might be null or undefined, uses <code>window._sharedData.siteCurrency</code> to get currency
 *
 * @param priceValue price value to format
 */
export const formatOptionalPrice = (
  priceValue: number | undefined | null,
  currencyString?: string
): string | null =>
  isNotNullOrUndefined(priceValue)
    ? formatPrice(priceValue, currencyString)
    : null;

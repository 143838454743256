import { callGraphQL } from "../../../graphql/graphqlApi";
import type {
  AddConfigurableProductToCartResponse,
  AddCouponResponse,
  AddGiftCardsToCartResponse,
  AddServiceToCartResponse,
  AddToCartResponse,
  CartLoyaltyResponse,
  CartResponse,
  CheckoutConfirmationSnippetResponse,
  CheckoutSnippetResponse,
  CreateRequisitionResponse,
  GenericGraphQLError,
  GetTeamAdminAccountInfo,
  GiftCardsCheckoutSnippetResponse,
  InvalidProductsInCartError,
  ModifyCartItemQuantityResponse,
  OnSiteMessagingConfigurationForProductsResponse,
  PersonalOffer,
  ReadOnlyError,
  RemoveCouponResponse,
  RemoveGiftCardsFromCartResponse,
  ReplacementCheckoutSnippetResponse,
  ResponseStatus,
  ShippingCostsResponse,
  ToggleOfferInCartResponse,
} from "./types";
import {
  type AddConfigurableProductsToCartMutation,
  type AddConfigurableProductsToCartMutationVariables,
  type AddCouponToCartMutation,
  type AddProductsToCartMutation,
  type AddServiceToCartMutation,
  type CartItemIdInput,
  type CartQuery,
  type CheckoutSnippetQuery,
  type ModifyCartProductsMutation,
  type RemoveCouponFromCartMutation,
  type AddCollectableProductsToCartMutation,
  type ToggleOfferInCartMutation,
  type CartQueryVariables,
  type OnSiteMessagingConfigurationForProductsQuery,
  type TeamAdminAccountInfoQuery,
  type CreateRequisitionInput,
  type CreateRequisitionMutation,
  CustomerType,
  type CheckoutSnippetQueryVariables,
  type AddGiftCardsToCartGiftCardDataInput,
  type AddGiftCardsToCartMutation,
  type RemoveGiftCardsFromCartMutation,
  type PaymentProvider,
  type ConfirmationSnippetQuery,
  type InitiateGiftCardPurchaseMutation,
  type GiftCardEntry,
  type GetReplacementQuery,
  type ReplacementEntry,
  type UpdateCartShippingMutation,
  type UpdateCartLoyaltyMutation,
  type CartInput,
} from "../../../generated/graphql-code-generator";
import {
  addConfigurableProductsToCartMutation,
  addCouponToCartMutation,
  addServiceToEgCartMutation,
  addToCartMutation,
  cartQuery,
  checkoutSnippetQuery,
  modifyCartProductsQuantity,
  removeCouponFromCartMutation,
  addCollectableProductsToCartMutation,
  toggleOfferInCartMutation,
  getOnSiteMessagingConfigurationForProductsQuery,
  getAccountInfo,
  createRequisitionMutation,
  addGiftCardsToCartMutation,
  removeGiftCardsFromCartMutation,
  checkoutConfirmationQuery,
  checkoutShipmentUpdateMutation,
  updateCartLoyaltyMutation,
} from "./graphqlQueries";
import type { AddConfigurableProductToCartEventData } from "../../../global";
import { isKlarnaCheckoutSnippetLoaded } from "../CartState";
import { isCartPage } from "../../../utils/xxl-page-type";
import { PIECE_UNIT } from "../../Product/product-helper";
import { PageType } from "../../../constants";
import { isEmpty, isNotNullOrUndefined } from "@xxl/common-utils";
import { initiateGiftCardPurchaseMutation } from "../../Orders/Api/OrdersAPI";
import { getReplacement } from "../../InstantShopping/InstantShopping";

export type ExchangeInputProps = {
  email: string;
  entries: ReplacementEntry[];
  orderCode: string;
};

export const SUCCESS_RESPONSE_STATUS = "SUCCESS";
export const ERROR_RESPONSE_STATUS = "ERROR";

export const PaymentType = {
  CHECKOUT: "CHECKOUT",
  GIFTCARDS: "GIFTCARDS",
  REPLACE: "REPLACE",
};

const getCartQueryVariables = (
  pageType?: PageType
): CartQueryVariables | undefined => {
  if (
    !isKlarnaCheckoutSnippetLoaded() &&
    (isCartPage() || pageType === PageType.CHECKOUT)
  ) {
    //only force refresh on initial checkout page load, checkout page is actually a redirected cart page, hence isCartPage condition
    return { input: { forceRefresh: true } };
  }
  return;
};

const getCheckoutQueryVariables = (
  customerType?: CustomerType
): CheckoutSnippetQueryVariables | undefined => {
  if (customerType !== undefined) {
    return {
      input: {
        customerType,
      },
    };
  }
  return;
};

const getCartInputVariables = (cartId?: string): CartInput | undefined => {
  if (cartId !== undefined && cartId !== "") {
    return {
      cart: {
        id: {
          id: cartId,
        },
      },
    };
  }
  return;
};

export const getCart = async (
  graphQLEndpointUrl: string,
  graphQLApiKey?: string,
  pageType?: PageType
): Promise<CartResponse> => {
  const cartQueryVariables = getCartQueryVariables(pageType);
  const queryResponse = await callGraphQL<CartQuery>(
    {
      query: cartQuery,
      ...(cartQueryVariables !== undefined
        ? { variables: cartQueryVariables }
        : undefined),
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );
  return {
    status:
      queryResponse.data?.cart !== undefined
        ? SUCCESS_RESPONSE_STATUS
        : ERROR_RESPONSE_STATUS,
    data: queryResponse,
  };
};

const EMPTY_CART_ID_RESPONSE = {
  status: ERROR_RESPONSE_STATUS as ResponseStatus,
  data: {
    data: null,
    errors: [{ message: "Provided empty cartId" }],
  },
};

export const getCheckoutConfirmationSnippet = async (
  graphQLEndpointUrl: string,
  graphQLApiKey: string,
  paymentProvider: PaymentProvider,
  cartId?: string
): Promise<CheckoutConfirmationSnippetResponse> => {
  if (isEmpty(cartId?.trim())) {
    return EMPTY_CART_ID_RESPONSE;
  }
  const queryResponse = await callGraphQL<ConfirmationSnippetQuery>(
    {
      query: checkoutConfirmationQuery,
      variables: {
        input: {
          cartId,
          paymentProvider,
        },
      },
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );
  return {
    status:
      queryResponse.data?.confirmationSnippet !== undefined
        ? SUCCESS_RESPONSE_STATUS
        : ERROR_RESPONSE_STATUS,
    data: queryResponse,
  };
};

export const updateGiftCardsCheckoutSnippet = async (
  graphQLEndpointUrl: string,
  graphQLApiKey: string,
  giftCardEntries: GiftCardEntry[],
  customerType?: CustomerType
): Promise<GiftCardsCheckoutSnippetResponse> => {
  const queryResponse = await callGraphQL<InitiateGiftCardPurchaseMutation>(
    {
      query: initiateGiftCardPurchaseMutation,
      variables: {
        input: {
          customerType: customerType ?? CustomerType.PERSON,
          entries: giftCardEntries,
        },
      },
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

  return {
    status:
      queryResponse.data?.initiateGiftCardPurchase !== undefined
        ? SUCCESS_RESPONSE_STATUS
        : ERROR_RESPONSE_STATUS,
    data: queryResponse,
  };
};

export const updateReplacementCheckoutSnippet = async (
  graphQLEndpointUrl: string,
  graphQLApiKey: string,
  replacementInput: ExchangeInputProps,
  customerType?: CustomerType
): Promise<ReplacementCheckoutSnippetResponse> => {
  const queryResponse = await callGraphQL<GetReplacementQuery>(
    {
      query: getReplacement,
      variables: {
        input: {
          ...replacementInput,
          customerType: customerType ?? CustomerType.PERSON,
        },
      },
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

  return {
    status:
      queryResponse.data?.getReplacement !== undefined
        ? SUCCESS_RESPONSE_STATUS
        : ERROR_RESPONSE_STATUS,
    data: queryResponse,
  };
};

export const updateShippingCosts = async (
  graphQLEndpointUrl: string,
  graphQLApiKey?: string,
  cartId?: string
): Promise<ShippingCostsResponse> => {
  if (isEmpty(cartId?.trim())) {
    return EMPTY_CART_ID_RESPONSE;
  }
  const cartInputVariables = getCartInputVariables(cartId);
  const queryResponse = await callGraphQL<UpdateCartShippingMutation>(
    {
      query: checkoutShipmentUpdateMutation,
      ...(cartInputVariables !== undefined
        ? {
            variables: { input: cartInputVariables },
          }
        : undefined),
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

  return {
    status:
      queryResponse.data?.updateCartShipping !== undefined
        ? SUCCESS_RESPONSE_STATUS
        : ERROR_RESPONSE_STATUS,
    data: queryResponse,
  };
};

export const updateCartLoyaltyContent = async (
  graphQLEndpointUrl: string,
  graphQLApiKey: string,
  cartId?: string
): Promise<CartLoyaltyResponse> => {
  if (isEmpty(cartId?.trim())) {
    return EMPTY_CART_ID_RESPONSE;
  }
  const cartInputVariables = getCartInputVariables(cartId);
  const queryResponse = await callGraphQL<UpdateCartLoyaltyMutation>(
    {
      query: updateCartLoyaltyMutation,
      ...(cartInputVariables !== undefined
        ? {
            variables: { input: cartInputVariables },
          }
        : undefined),
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

  return {
    status:
      queryResponse.data?.updateCartLoyalty !== undefined
        ? SUCCESS_RESPONSE_STATUS
        : ERROR_RESPONSE_STATUS,
    data: queryResponse,
  };
};

export const updateCheckoutSnippet = async (
  graphQLEndpointUrl: string,
  graphQLApiKey?: string,
  customerType?: CustomerType
): Promise<CheckoutSnippetResponse> => {
  const checkoutQueryVariables = getCheckoutQueryVariables(customerType);
  const queryResponse = await callGraphQL<CheckoutSnippetQuery>(
    {
      query: checkoutSnippetQuery,
      ...(checkoutQueryVariables !== undefined
        ? {
            variables: checkoutQueryVariables,
          }
        : undefined),
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );
  const errors = (queryResponse.errors ?? []) as GenericGraphQLError[];
  if (errors.length > 0) {
    const readOnlyError = errors.find(
      (error): error is ReadOnlyError =>
        error.errorType === "READ_ONLY_ORDER_ERROR"
    );
    if (readOnlyError !== undefined) {
      window.location.replace(readOnlyError.errorInfo.confirmationPage);
    }
  }
  const invalidEans: string[] = errors
    .filter(
      (error): error is InvalidProductsInCartError =>
        error.errorType === "INVALID_PRODUCTS_IN_CART_ERROR"
    )
    .flatMap(({ errorInfo }) => errorInfo.invalidEans);
  return {
    status:
      queryResponse.data?.checkoutSnippet?.htmlSnippet !== undefined
        ? SUCCESS_RESPONSE_STATUS
        : ERROR_RESPONSE_STATUS,
    snippet: queryResponse.data?.checkoutSnippet?.htmlSnippet,
    ...(invalidEans.length > 0 ? { invalidEans } : undefined),
    paymentProvider: queryResponse.data?.checkoutSnippet?.paymentProvider,
    customerType: isNotNullOrUndefined(
      queryResponse.data?.checkoutSnippet?.customerType
    )
      ? queryResponse.data.checkoutSnippet.customerType
      : undefined,
    customerTypeOptions:
      queryResponse.data?.checkoutSnippet?.customerTypeOptions ?? [],
    cartId: queryResponse.data?.checkoutSnippet?.cartId,
  };
};

export const addGiftCardsToCart = async (
  giftCard: AddGiftCardsToCartGiftCardDataInput,
  graphQLEndpointUrl: string,
  graphQLApiKey: string
): Promise<AddGiftCardsToCartResponse> => {
  const queryResponse = await callGraphQL<AddGiftCardsToCartMutation>(
    {
      query: addGiftCardsToCartMutation,
      variables: {
        input: {
          cart: {
            totals: {
              paymentTotals: {
                giftCards: [
                  {
                    ...giftCard,
                  },
                ],
              },
            },
          },
        },
      },
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

  return {
    status:
      queryResponse.data?.addGiftCardsToCart !== undefined
        ? SUCCESS_RESPONSE_STATUS
        : ERROR_RESPONSE_STATUS,
    data: queryResponse,
  };
};

export const removeGiftCardsFromCart = async (
  number: string,
  graphQLEndpointUrl: string,
  graphQLApiKey: string
): Promise<RemoveGiftCardsFromCartResponse> => {
  const queryResponse = await callGraphQL<RemoveGiftCardsFromCartMutation>(
    {
      query: removeGiftCardsFromCartMutation,
      variables: {
        input: {
          cart: {
            totals: {
              paymentTotals: {
                giftCards: [
                  {
                    number,
                  },
                ],
              },
            },
          },
        },
      },
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

  return {
    status:
      queryResponse.data?.removeGiftCardsFromCart !== undefined
        ? SUCCESS_RESPONSE_STATUS
        : ERROR_RESPONSE_STATUS,
    data: queryResponse,
  };
};

export const addToCart = async (
  items: { ean: string; quantity: number; unit?: string }[],
  graphQLEndpointUrl: string,
  graphQLApiKey: string
): Promise<AddToCartResponse> => {
  const queryResponse = await callGraphQL<AddProductsToCartMutation>(
    {
      query: addToCartMutation,
      variables: {
        input: {
          cart: {
            items: items.map(({ ean, quantity, unit }) => ({
              ean,
              quantity: { quantity },
              unit: unit ?? PIECE_UNIT,
            })),
          },
        },
      },
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

  return {
    status:
      queryResponse.data?.addProductsToCart !== undefined
        ? SUCCESS_RESPONSE_STATUS
        : ERROR_RESPONSE_STATUS,
    data: queryResponse,
  };
};

export const addCollectableProductsToCart = async (
  ean: string,
  quantity: number,
  storeId: string,
  graphQLEndpointUrl: string,
  graphQLApiKey: string
): Promise<AddToCartResponse> => {
  const queryResponse = await callGraphQL<AddCollectableProductsToCartMutation>(
    {
      query: addCollectableProductsToCartMutation,
      variables: {
        input: {
          cart: {
            items: [
              {
                ean,
                quantity: {
                  quantity,
                },
              },
            ],
            collectStore: {
              store: { id: storeId },
            },
          },
        },
      },
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

  return {
    status:
      queryResponse.data?.addCollectableProductsToCart !== undefined
        ? SUCCESS_RESPONSE_STATUS
        : ERROR_RESPONSE_STATUS,
    data: queryResponse,
  };
};

export const addConfigurableProductToCart = async ({
  parentEan,
  configurations,
  graphQLEndpointUrl,
  graphQLApiKey,
}: AddConfigurableProductToCartEventData): Promise<AddConfigurableProductToCartResponse> => {
  const variables: AddConfigurableProductsToCartMutationVariables = {
    input: {
      cart: {
        items: [
          {
            ean: parentEan,
            configurations,
          },
        ],
      },
    },
  };
  const queryResponse =
    await callGraphQL<AddConfigurableProductsToCartMutation>(
      {
        query: addConfigurableProductsToCartMutation,
        variables,
      },
      graphQLEndpointUrl,
      graphQLApiKey
    );

  return {
    status:
      queryResponse.data?.addConfigurableProductsToCart !== undefined
        ? SUCCESS_RESPONSE_STATUS
        : ERROR_RESPONSE_STATUS,
    data: queryResponse,
  };
};

export const addServiceToEgCart = async (
  ean: string,
  quantity: string,
  parentId: CartItemIdInput,
  graphQLEndpointUrl: string,
  graphQLApiKey: string
): Promise<AddServiceToCartResponse> => {
  const queryResponse = await callGraphQL<AddServiceToCartMutation>(
    {
      query: addServiceToEgCartMutation,
      variables: {
        input: {
          cart: {
            items: [
              {
                ean,
                parentId,
                quantity: {
                  quantity: parseFloat(quantity),
                },
              },
            ],
          },
        },
      },
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

  return {
    status:
      queryResponse.data?.addServiceProductsToCartItems !== undefined
        ? SUCCESS_RESPONSE_STATUS
        : ERROR_RESPONSE_STATUS,
    data: queryResponse,
  };
};

export const submitCouponsToEGCart = async (
  coupon: string,
  graphQLEndpointUrl: string,
  graphQLApiKey: string
): Promise<AddCouponResponse> => {
  const queryResponse = await callGraphQL<AddCouponToCartMutation>(
    {
      query: addCouponToCartMutation,
      variables: {
        input: {
          cart: {
            coupons: {
              couponCode: coupon,
            },
          },
        },
      },
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

  return {
    status:
      queryResponse.data?.addCouponToCart !== undefined
        ? SUCCESS_RESPONSE_STATUS
        : ERROR_RESPONSE_STATUS,
    data: queryResponse,
  };
};

export const toggleOfferInCart = async (
  offer: PersonalOffer,
  graphQLEndpointUrl: string,
  graphQLApiKey: string
): Promise<ToggleOfferInCartResponse> => {
  const queryResponse = await callGraphQL<ToggleOfferInCartMutation>(
    {
      query: toggleOfferInCartMutation,
      variables: {
        input: {
          cart: {
            offer: {
              active: !offer.active,
              id: offer.id,
            },
          },
        },
      },
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

  return {
    status:
      queryResponse.data?.toggleOfferInCart !== undefined
        ? SUCCESS_RESPONSE_STATUS
        : ERROR_RESPONSE_STATUS,
    data: queryResponse,
  };
};

export const removeCouponsFromEGCart = async (
  coupons: string,
  graphQLEndpointUrl: string,
  graphQLApiKey: string
): Promise<RemoveCouponResponse> => {
  const queryResponse = await callGraphQL<RemoveCouponFromCartMutation>(
    {
      query: removeCouponFromCartMutation,
      variables: {
        input: {
          cart: {
            coupons: {
              cartItemIdentificator: parseInt(coupons),
            },
          },
        },
      },
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

  return {
    status:
      queryResponse.data?.removeCouponFromCart !== undefined
        ? SUCCESS_RESPONSE_STATUS
        : ERROR_RESPONSE_STATUS,
    data: queryResponse,
  };
};

export const updateProductsQuantity = async (
  quantity: string,
  itemId: CartItemIdInput,
  graphQLEndpointUrl: string,
  graphQLApiKey: string,
  ean?: string,
  unit?: string
): Promise<ModifyCartItemQuantityResponse> => {
  const queryResponse = await callGraphQL<ModifyCartProductsMutation>(
    {
      query: modifyCartProductsQuantity,
      variables: {
        input: {
          cart: {
            item: {
              itemId,
              quantity: {
                quantity: parseFloat(quantity),
              },
              ean,
              unit,
            },
          },
        },
      },
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

  return {
    status:
      queryResponse.data?.modifyCartItemsQuantity !== undefined
        ? SUCCESS_RESPONSE_STATUS
        : ERROR_RESPONSE_STATUS,
    data: queryResponse,
  };
};

export const getOnSiteMessagingConfigurationForProducts = async (
  articleNumbers: string[],
  site: string,
  graphQLEndpointUrl: string,
  graphQLApiKey: string
): Promise<OnSiteMessagingConfigurationForProductsResponse> => {
  const queryResponse =
    await callGraphQL<OnSiteMessagingConfigurationForProductsQuery>(
      {
        query: getOnSiteMessagingConfigurationForProductsQuery,
        variables: {
          input: {
            articleNumbers,
            site,
          },
        },
      },
      graphQLEndpointUrl,
      graphQLApiKey
    );

  return {
    status:
      queryResponse.data?.onSiteMessagingConfigurationForProducts !== undefined
        ? SUCCESS_RESPONSE_STATUS
        : ERROR_RESPONSE_STATUS,
    data: queryResponse,
  };
};

export const getTeamAdminAccountInfo = async (
  graphQLEndpointUrl: string,
  graphQLApiKey: string
): Promise<GetTeamAdminAccountInfo> => {
  const queryResponse = await callGraphQL<TeamAdminAccountInfoQuery>(
    {
      query: getAccountInfo,
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

  return {
    status:
      queryResponse.data?.account !== undefined
        ? SUCCESS_RESPONSE_STATUS
        : ERROR_RESPONSE_STATUS,
    data: queryResponse,
  };
};

export const createRequisitionFunction = async (
  input: CreateRequisitionInput,
  graphQLEndpointUrl: string,
  graphQLApiKey: string
): Promise<CreateRequisitionResponse> => {
  const queryResponse = await callGraphQL<CreateRequisitionMutation>(
    {
      query: createRequisitionMutation,
      variables: {
        input,
      },
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

  return {
    status:
      queryResponse.data?.createRequisition !== undefined
        ? SUCCESS_RESPONSE_STATUS
        : ERROR_RESPONSE_STATUS,
    data: queryResponse,
  };
};

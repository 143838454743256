export const makeScriptsExecutable = (containerRefCurrent: HTMLElement) => {
  const scriptsTags = containerRefCurrent.querySelectorAll("script");
  for (const element of scriptsTags) {
    const parentNode = element.parentNode;
    const newScriptTag = document.createElement("script");

    const attributes = Array.from(element.attributes);

    attributes.forEach(({ name, value }) => {
      newScriptTag.setAttribute(name, value);
    });
    newScriptTag.text = element.text;
    if (parentNode !== null) {
      parentNode.removeChild(element);
      parentNode.appendChild(newScriptTag);
    }
  }
};

export const setInnerHtml = (
  element: HTMLElement,
  htmlString: string,
  shouldMakeScriptsExecutable?: boolean
): void => {
  element.innerHTML = htmlString;

  if (shouldMakeScriptsExecutable === true) {
    makeScriptsExecutable(element);
  }
};

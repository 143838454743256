import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { RefObject } from "react";
import { useEffect, useState } from "react";

const useListShadowOnScroll = ({
  listRef,
  listViewportRef,
  scrollPosition,
}: {
  listRef: RefObject<HTMLDivElement>;
  listViewportRef: RefObject<HTMLDivElement>;
  scrollPosition: number;
}) => {
  const [listHeight, setListHeight] = useState(0);
  const [listViewportHeight, setListViewportHeight] = useState(0);
  const [isBottomShadowVisible, setIsBottomShadowVisible] = useState(false);
  const [isTopShadowVisible, setIsTopShadowVisible] = useState(false);

  useEffect(() => {
    const setListScroll = () => {
      const listElement = listRef.current;
      const listViewportElement = listViewportRef.current;
      const hasNoListHeight =
        listHeight === 0 &&
        isNotNullOrUndefined(listElement) &&
        listElement.offsetHeight !== 0;
      const hasNoViewPortHeight =
        listViewportHeight === 0 &&
        isNotNullOrUndefined(listViewportElement) &&
        listViewportElement.offsetHeight !== 0;

      if (hasNoListHeight) {
        setListHeight(listElement.offsetHeight);
      }
      if (hasNoViewPortHeight) {
        setListViewportHeight(listViewportElement.offsetHeight);
      }
    };
    setTimeout(setListScroll, 0);
  }, [listRef, listHeight, listViewportHeight, listViewportRef]);

  useEffect(() => {
    if (scrollPosition + listViewportHeight === listHeight) {
      setIsBottomShadowVisible(false);
    } else if (
      listHeight > listViewportHeight &&
      scrollPosition + listViewportHeight !== listHeight
    ) {
      setIsBottomShadowVisible(true);
    }
    setIsTopShadowVisible(scrollPosition > 0);
  }, [scrollPosition, listViewportHeight, listHeight]);

  return { isBottomShadowVisible, isTopShadowVisible };
};

export { useListShadowOnScroll };

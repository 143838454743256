import type { FunctionComponent } from "react";
import React from "react";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import * as XxlEvent from "../../utils/xxl-event";
import type { ISnackBarMessage, IMessageEvent } from "./index";
import { SnackBar } from "./SnackBar";
import type { SnackBarProps } from "./SnackBar";
import noop from "lodash/noop";
import OutsideClickHandler from "react-outside-click-handler";

/**
 * SnackBarWrapper is a component to display temporary, dismissable notifications to users on mobile devices or smaller screens.
 * The wrapper is mounted automatically and is purely event driven. No global `window` object API's are exposed.
 *
 * @example
 * const messages = [
      { key: "cart.notification.item.added", icon: "checkmark" },
      { key: "cart.go.to", url: "/checkout", background: "var(--color-xxl-dark-fuchsia)" }
    ];
    // Optional `onEntered` callback from React, returning the HTMLElement, once messages are rendered
    const onEntered = element => { // logic };
    XxlEvent.dispatchEvent(XxlEvent.type.XXL_MESSAGE, { msgs, onEntered });
 *
 * @returns `React.FunctionComponent`
 */
const SnackBarWrapper: FunctionComponent = () => {
  const { t } = useTranslations();
  const [messages, setMessages] = React.useState<ISnackBarMessage[]>([]);
  const [onEntered, setOnEntered] =
    React.useState<SnackBarProps["onEntered"]>();

  const setupEventListener = () => {
    XxlEvent.addEventListener(XxlEvent.type.XXL_MESSAGE, (evt) => {
      const {
        detail: { msgs, onEntered = noop },
      } = evt as CustomEvent<{
        msgs: IMessageEvent[];
        onEntered: (el: HTMLElement) => void;
      }>;
      setOnEntered(() => (el: HTMLElement) => onEntered(el));
      setMessages(
        msgs.map(
          (msg: IMessageEvent) =>
            ({
              open: true,
              text: t(msg.key),
              url: msg.url,
              icon: msg.icon,
              background: msg.background,
              textColor: msg.textColor,
            }) as ISnackBarMessage
        )
      );
    });
  };
  React.useEffect(setupEventListener, []);

  return (
    <div data-testid="snackbar">
      {messages.length > 0 ? (
        <OutsideClickHandler onOutsideClick={() => setMessages([])}>
          <SnackBar messages={messages} onEntered={onEntered} />
        </OutsideClickHandler>
      ) : null}
    </div>
  );
};

export { SnackBarWrapper };
